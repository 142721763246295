import create from "zustand";
import createContext from "zustand/context";
import { v4 as uuidv4 } from "uuid";
import produce from "immer";
import { devtools } from 'zustand/middleware'

export const { Provider, useStore } = createContext();
const toValues = (blocks, text = '') => {
    const valuesArray = blocks.map(item => item.attributes.value);
    // const 
    return valuesArray.join(" ") + text;
}
export const createStore = ({ blocks = [], value = '' }) => () => create(devtools((set, get) => ({
    menuItems: [
        {
            value: "Current Action", children: [{

                value: "Collection",

            }]
        },
        {
            value: "Product",
            children: [
                {
                    value: "Product Name"
                },
                {
                    value: "Product Price"
                }
            ]
        }
    ],
    blocks,
    value,
    insertBlock: block => {
        set(
            produce(state => {
                state.blocks.push({ clientId: uuidv4(), ...block });
                console.log(toValues(state.blocks));
                state.value = toValues(state.blocks);
            })
        );
    },
    removeLastBlock: () => {
        set(
            produce(state => {
                state.blocks.pop();
                state.value = toValues(state.blocks);

            })
        );
    },
    deleteBlock: clientId => {
        set(
            produce(state => {
                state.blocks = state.blocks.filter(
                    item => item.clientId !== clientId
                );
                state.value = toValues(state.blocks);

            })
        );
    },
    getLastBlock: () => {
        return get().blocks[get().blocks.length - 1];
    },

    // _currentValues: ""
    currentInputValue: "",
    setCurrentInputValue: text => {
        set(
            produce(state => {
                state.currentInputValue = text;
                state.value = toValues(state.blocks, text);

            })
        );
    }
})));
