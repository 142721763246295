import { ArrowRightIcon } from '@heroicons/react/outline';

const AndroidHome = ({ alreadyHaveAccount = () => { } }) => {
	return (
		<div className="p-4">
			<h4 className="text-gray-900 mb-2 text-xl font-bold">
				Google Developer Account
			</h4>
			<p className="text-gray-600 mb-4 text-base">
				To publish Android apps on Google Play, you'll need to create a Google
				Play Developer account. Google Play requires a one time $25 registration
				fee charged for a Google Play Developer Console account.
			</p>
			<span className="relative z-0 inline-flex gap-4 rounded-md">
				<a
					href='https://appmaker.xyz/en/docs/setting-up-your-google-play-developer-account/'
					className="relative inline-flex items-center px-4 py-2 rounded-md bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"

				>
					Get a Google Developer Account
				</a>
				<button
					type="button"
					className="relative group inline-flex gap-1 items-center px-4 py-2 rounded-r-md text-base font-medium text-blue-500 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
					onClick={() => {
						alreadyHaveAccount();
					}}
				>
					I already have an account{' '}
					<ArrowRightIcon className="h-3 w-3 group-hover:translate-x-1 transform transition-all" />
				</button>
			</span>
		</div>
	);
};

export default AndroidHome;
