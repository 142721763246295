import { UserIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import React from 'react';

const DrawerLogin = ({ attributes, setAttributes }) => {
	return (
		<div className="p-2 bg-white flex items-center space-x-2">
			<UserIcon className="h-5 w-5 text-gray-800" aria-hidden="true" />
			<h1 className="text-md text-gray-800 flex-auto">Login/Register</h1>
			<XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
		</div>
	);
};

export default DrawerLogin;
