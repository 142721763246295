import { lazy, Suspense } from 'react';
import Loader from '@appmaker/components/Loading';

const AllDataSourceList = lazy(() => import('./AllDataSourceList'));
const InstalledDataSourceList = lazy(() => import('./InstalledDataSourceList'));


const DataSourceHome = () => {
    return (<div>
        <Suspense fallback={<Loader />}>
            <AllDataSourceList />
        </Suspense>
        <Suspense fallback={<Loader />}>
            <InstalledDataSourceList />
        </Suspense>
    </div>)
}

export default DataSourceHome;