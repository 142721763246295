
import Modal from './Modal'
import MediaLibrary from './MediaLibrary';
import {
    MultiSelectContextProvider,
} from "use-multiselect";
import { MediaStoreProvider, useMediaStore ,createStore} from '../../store/media'
import { MediaLibraryContextProvider } from '../../context/MediaLibrary'

function MyMediaUploaderComp({ render, ...props }) {
    const toggleModal = useMediaStore(store => store.toggleModal);
    return <MultiSelectContextProvider>

        {render({ open: toggleModal })}
        <MediaLibraryContextProvider initialState={props}>
            <Modal
                // open={modalStatus}
                // setOpen={setModalStatus}
                title="Media Library"
                {...props}>
                <MediaLibrary />
            </Modal>
            </MediaLibraryContextProvider>

    </MultiSelectContextProvider>

}

export default function CoreMediaUpload(props)
{
    return <MediaStoreProvider createStore={createStore}>
     <MyMediaUploaderComp {...props}/>
     </MediaStoreProvider>
}