import axios from 'axios';
import { V3_BASE_URL } from '@appmaker/core/api/core';
const BASE_URL = V3_BASE_URL + '/projects'

const api = {
	getList: async ({ projectId }) => {
		try {
			const response = await axios.get(`${BASE_URL}/${projectId}/themes`, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	themeSettings: async ({ projectId, metaValue, metaKey, projectThemeId }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/themes/settings`,
				{
					projectThemeId,
					metaValue,
					metaKey
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getValues: async ({ projectId, projectThemeId, metaKey }) => {
		try {
			let url = `${BASE_URL}/${projectId}/themes/settings?projectThemeId=${projectThemeId}`;
			if (metaKey) {
				url += `&metaKey=${metaKey}`;
			}
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	installTheme: async ({ projectId, themeId }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/themes`,
				{
					themeId,
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	deactivateTheme: async ({ projectId, themeId }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/themes`,
				{
					themeId,
					status: 'inactive'
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	activateTheme: async ({ projectId, data }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/themes/activate`,
				data,
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	}
}

export default api;