import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { CubeIcon } from '@heroicons/react/outline';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import ActiveThemeCard from './ActiveThemeCard';
import ThemeCard from './ThemeCard';
import Header from './Header';
import AvailableTheme from './AvailableTheme';
import useThemeStore from '../store/list';
import { size } from 'lodash';
const RenderActiveThemeCard = ({ themes = [], getRouterPath }) => {
	return themes.map(activeTheme => (
		<ActiveThemeCard
			theme={{
				image:
					activeTheme?.theme?.icon ??
					'https://maxcdn.icons8.com/style-preview/bbf47db7-8a8b-41b6-b218-6c2697e637f0.jpeg',
				name: activeTheme?.theme?.name,
				version: activeTheme?.theme?.packageVersion,
				isDefault: activeTheme?.isDefault
			}}
			getRouterPath={getRouterPath}
		/>
	))
}
const List = () => {
	const activeTheme = useThemeStore((data) => data.activeTheme);
	const installTheme = useThemeStore((data) => data.installList);
	const allThemes = useThemeStore((data) => data.themes);
	const installedThemes = installTheme
		?.map((item) => {
			const currentThemeHandle = item?.theme?.handle;
			// return allThemes?.find((theme) => theme?.handle === currentThemeHandle);
			return item?.theme
		})
		?.filter((item) => item !== undefined);
	const getRouterPath = useRouterPath();
	const isAdmin = checkIsAdmin();
	const checkActive = (handle) => {
		const installData = installedThemes?.find(
			(item) => item?.theme?.handle === handle
		);
		return installData?.status === 'active';
	};
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<Header />
			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex items-center justify-between space-x-4 ">
					<h2 className="text-lg font-medium text-gray-900">Active Theme</h2>
				</div>
				<div className="mt-4 grid grid-cols-1">
					{activeTheme && size(activeTheme) > 0 ? (
						<RenderActiveThemeCard themes={activeTheme} getRouterPath={getRouterPath} />
					) : (
						<div className="p-4 group rounded-xl bg-white flex flex-col justify-center items-center border border-dashed border-gray-200">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-lg font-medium text-gray-900">
								No Active Theme
							</h3>
							<p className="text-sm text-gray-500">
								Please install a theme to activate
							</p>
						</div>
					)}
				</div>
			</div>
			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex items-center justify-between space-x-4 ">
					<h2 className="text-lg font-medium text-gray-900">
						Installed Themes
					</h2>
				</div>
				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
					{installedThemes?.length === 0 ? (
						<div className="p-4 group rounded-xl bg-white flex flex-col justify-center items-center border border-dashed border-gray-200">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-md font-medium text-gray-400 mt-3">
								Installed themes not found
							</h3>
						</div>
					) : null}
					{installedThemes?.map((theme) => (
						<ThemeCard
							key={theme.id}
							getRouterPath={getRouterPath}
							theme={theme}
							checkActive={checkActive}
						/>
					))}
				</div>
			</div>
			{isAdmin && (
				<div className="max-w-2xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
					<AvailableTheme />
				</div>
			)}
		</div>
	);
};

export default List;
