
import {
	ChevronRightIcon,
} from '@heroicons/react/outline';

import { Dialog, Transition, RadioGroup } from '@headlessui/react';
import { useEffect, useState } from 'react';
import create from 'zustand';
import { useParams, useModal } from '../hooks';
import appmaker from '@appmaker/core/index';
import { getProjectId, setPublicMeta } from '@appmaker/core/store/project';

const useStore = create((set) => ({
	selectedStep: undefined,
	setSelectedStep: (value) => set({ selectedStep: value }),
}))


function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const methods = [
	{
		id: 'do-it-yourself',
		name: 'Do it yourself',
		description:
			'We highly recommend that you go ahead and try Appmaker yourself! We have help docs and other supporting resources to assist you along the way.',
		image: '/images/misc/placeholder-1.png',
	},
	{
		id: 'appmaker-expert',
		name: 'Appmaker Expert',
		description:
			'Book a free demo call and explore the opportunity to work with our inhouse team of mobile experts, who will design and setup your app.',
		image: '/images/misc/placeholder-2.png',
	},
	{
		id: 'hire-agency',
		name: 'Hire an agency',
		description:
			'Hire and work closely with experienced agencies specialized in building with Appmaker.',
		image: '/images/misc/placeholder-3.png',
	},
];


function MethodListing() {
	const [selected, setSelected] = useState(methods[0]);
	const setSelectedStep = useStore(state => state.setSelectedStep)
	useEffect(() => {
		if (selected && selected.id) {
			setSelectedStep(selected.id);
		}
	}, [selected])
	return (
		<RadioGroup value={selected} onChange={setSelected}>
			<RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
			<div className="space-y-4">
				{methods.map((method) => (
					<RadioGroup.Option
						key={method.name}
						value={method}
						className={({ checked, active }) =>
							classNames(
								checked ? 'border-transparent' : 'border-gray-300',
								active ? 'border-blue-500 ring-2 ring-blue-500' : '',
								'relative block bg-white border rounded-lg px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
							)
						}
					>
						{({ active, checked }) => (
							<>
								<span className="flex items-center">
									<img
										src={method.image}
										className="h-16 w-16 mr-4"
										alt="icon"
									/>
									<span className="flex flex-col">
										<RadioGroup.Label
											as="h4"
											className="text-2xl font-medium text-gray-900 mb-1"
										>
											{method.name}
										</RadioGroup.Label>
										<RadioGroup.Description
											as="p"
											className="text-gray-500 text-sm"
										>
											{method.description}
										</RadioGroup.Description>
									</span>
								</span>
								<span
									className={classNames(
										active ? 'border' : 'border-2',
										checked ? 'border-blue-500' : 'border-transparent',
										'absolute -inset-px rounded-lg pointer-events-none'
									)}
									aria-hidden="true"
								/>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
}

const InitialPage = () => {
	const selectedStep = useStore(state => state.selectedStep);
	const [isLoading, setLoading] = useState(false)
	const [, setStep] = useParams();
	const projectId = getProjectId();
	const [, setModal] = useModal();

	const diyProcess = async () => {
		await setPublicMeta({ initial_form_setup_completed: true })
	}
	const onClickContinue = async () => {
		if (selectedStep === 'do-it-yourself') {
			appmaker.doAction('appmaker-analytics-event', {
				event: `getting_started_form_dashboard_diy_submitted`,
				params: {
					projectId,
					insideShopify: !!window.shopifyApp
				}
			});
			setLoading(true);
			await diyProcess()
			setLoading(false)
			setModal(false);
		} else {
			setStep(selectedStep)
		}

	}
	return (
		<div className="grid grid-cols-5">
			<div className="bg-purple-50 hidden sm:col-span-2 sm:flex sm:justify-end sm:items-end">
				<img src="/images/misc/welcome-image.png" alt="info" />
			</div>
			<div className="bg-white p-6 flex flex-col col-span-full sm:col-span-3">
				<h3 className="text-2xl font-medium mb-10">Get started</h3>
				<MethodListing />
				<button
					disabled={isLoading}
					type="button"
					className="inline-flex items-center mt-10 self-end px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					onClick={onClickContinue}
				>
					{isLoading ? 'Loading ...' : 'Continue'}
					<ChevronRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
				</button>
			</div>
		</div>
	);
}
export default InitialPage;