export default function ProductVariation({ attributes }) {
	return (
		<div className="px-3 py-2 border-gray-100 border-b">
			<p className="text-md font-medium">Product Variation</p>
			<p
				className="text-lg font-bold"
				dangerouslySetInnerHTML={{ __html: attributes.price_html }}
			></p>
		</div>
	);
}
ProductVariation.attributesSchema = {
	type: 'object',
	properties: {
		// blockTitle: '{{blockItem.name}}',
		// title: '{{blockItem.name}}',
		// variations: '{{blockItem.options}}',
		// variationKey: '{{blockItem.key}}',
		blockTitle: {
			type: 'string',
			label: 'Block Title',
			default: '',
		},
		title: {
			type: 'string',
			label: 'Title',
			default: '',
		},
		variations: {
			type: 'string',
			label: 'Variation',
			default: '',
		},
		variationType: {
			type: 'string',
			label: 'Variation Type',
			default: '',
		},
		variationKey: {
			type: 'string',
			label: 'Variation key',
			default: '',
		},
		defaultOptionValue: {
			type: 'string',
			label: 'Default OptionValue',
			default: '',
		},
	},
};
