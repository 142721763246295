import Loader from '@appmaker/components/Loading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getTemplateVersion } from '../api/template-version';

const GITLAB_PROJECT_URL =
	'https://gitlab.com/appilder-core/templates/WooCommerce';
const TemplateVersion = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingFilter, setIsLoadingFilter] = useState(false);
	const [isResetting, setIsResetting] = useState(false);
	const [data, setData] = useState([]);
	const [tag, setTag] = useState('');
	const fetchData = async (tag) => {
		try {
			const response = await getTemplateVersion({ tag });
			if (response?.templatePublishVersions) {
				return response?.templatePublishVersions;
			}
		} catch (error) {
			console.log(error);
		}
		return [];
	};

	const filterTag = async () => {
		if (tag?.length > 0) {
			setIsLoadingFilter(true);
			const response = await fetchData(tag?.trim());
			setData(response);
			setIsLoadingFilter(false);
		}
	};

	const resetTag = async () => {
		setIsResetting(true);
		const response = await fetchData();
		setData(response);
		setIsResetting(false);
	};
	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const response = await fetchData();
			setData(response);
			setIsLoading(false);
		})();
	}, []);
	if (isLoading) {
		return <Loader />;
	}
	return (
		<div className="m-4 mt-10">
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Published Versions
						</h1>
						<p className="mt-2 text-sm text-gray-700"></p>
					</div>
					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<input
							type="text"
							className="border border-gray-300 rounded-md px-2 py-1"
							placeholder="Enter tag to filter"
							onChange={(e) => setTag(e.target.value)}
						/>
						<button
							type="button"
							className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
							disabled={isLoadingFilter || isResetting || isLoading}
							onClick={() => {
								filterTag();
							}}
						>
							{!isLoadingFilter ? 'Filter' : 'Filtering...'}
						</button>
						<button
							type="button"
							disabled={isLoadingFilter || isResetting || isLoading}
							className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
							onClick={() => {
								resetTag();
							}}
						>
							{!isResetting ? 'Reset' : 'Resetting...'}
						</button>
					</div>
				</div>
				<div className="mt-8 flex flex-col">
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
											>
												Id
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Template Id
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Branch
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Tag
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Created At
											</th>
											<th
												scope="col"
												className="relative py-3.5 pl-3 pr-4 sm:pr-6"
											>
												{/* <span className="sr-only">Edit</span> */}
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{data.map((item) => (
											<tr key={item.id}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													{item.id}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{item.templateId}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<a
														target="_blank"
														href={GITLAB_PROJECT_URL + '/tree/' + item.branch}
														className="text-indigo-600 hover:text-indigo-900"
														rel="noreferrer"
													>
														{item.branch}
													</a>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<a
														target="_blank"
														href={GITLAB_PROJECT_URL + '/commit/' + item.tag}
														className="text-indigo-600 hover:text-indigo-900"
														rel="noreferrer"
													>
														{item.tag}
													</a>
													{/* https://gitlab.com/appilder-core/templates/WooCommerce/commit/bf8e411e */}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{item.createdAt}
												</td>
												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													{/* <a
														href="#"
														className="text-indigo-600 hover:text-indigo-900"
													>
														Edit<span className="sr-only">, {item.name}</span>
													</a> */}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplateVersion;
