import React from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody, RangeControl } from '@wordpress/components';
import ColorPicker from '@appmaker/core/components/AppmakerForm/components/ColorPicker';
import PaddingRangeControl from './Row/components/PaddingRangeControl';
import MarginRangeControl from './Row/components/MarginRangeControl';
import RadioItem from './Row/components/RadioItem';
import { MenuAlt2Icon, MenuAlt3Icon, MenuIcon } from '@heroicons/react/outline';
import {
	BoldIcon,
	ItalicIcon,
	StrikethroughIcon,
	UnderlineIcon,
} from './Row/assets';
import clsx from 'clsx';
import BorderRadiusControl from './Row/components/BorderRadiusControl';

function TextAlignment({ attributes, setAttributes }) {
	return (
		<div className="mb-4">
			<h4 className="text-sm text-md mb-2">Text Align</h4>
			<div className="flex items-center space-x-4">
				<RadioItem
					name="alignment"
					value="left"
					checked={attributes.alignment === 'left'}
					onChange={() => setAttributes({ alignment: 'left' })}
				>
					<MenuAlt2Icon className="h-6 w-6" />
					<span className="sr-only">Left</span>
				</RadioItem>
				<RadioItem
					name="alignment"
					value="center"
					checked={attributes.alignment === 'center'}
					onChange={() => setAttributes({ alignment: 'center' })}
				>
					<MenuIcon className="h-6 w-6" />
					<span className="sr-only">Center</span>
				</RadioItem>
				<RadioItem
					name="alignment"
					value="right"
					checked={attributes.alignment === 'right'}
					onChange={() => setAttributes({ alignment: 'right' })}
				>
					<MenuAlt3Icon className="h-6 w-6" />
					<span className="sr-only">Left</span>
				</RadioItem>
			</div>
		</div>
	);
}

function TextDecorationCheckBox({ attributes, setAttributes }) {
	return (
		<div className="mb-4">
			<h4 className="text-sm text-md mb-2">Text Formatting</h4>
			<div className="flex items-center space-x-4">
				<label
					htmlFor="bold"
					className={clsx(
						'p-1 bg-white rounded cursor-pointer shadow-sm border-2',
						attributes.bold ? 'border-gray-700' : null
					)}
				>
					<input
						type="checkbox"
						id="bold"
						checked={attributes.bold}
						onChange={() => setAttributes({ bold: !attributes.bold })}
						className="hidden"
					/>
					<BoldIcon />
				</label>
				<label
					htmlFor="italic"
					className={clsx(
						'p-1 bg-white rounded cursor-pointer shadow-sm border-2',
						attributes.italic ? 'border-gray-700' : null
					)}
				>
					<input
						type="checkbox"
						id="italic"
						checked={attributes.italic}
						onChange={() => setAttributes({ italic: !attributes.italic })}
						className="hidden"
					/>
					<ItalicIcon />
				</label>

				<label
					htmlFor="underline"
					className={clsx(
						'p-1 bg-white rounded cursor-pointer shadow-sm border-2',
						attributes.underline ? 'border-gray-700' : null
					)}
				>
					<input
						type="checkbox"
						id="underline"
						checked={attributes.underline}
						onChange={() => setAttributes({ underline: !attributes.underline })}
						className="hidden"
					/>
					<UnderlineIcon />
				</label>
				<label
					htmlFor="lineThrough"
					className={clsx(
						'p-1 bg-white rounded cursor-pointer shadow-sm border-2',
						attributes.lineThrough ? 'border-gray-700' : null
					)}
				>
					<input
						type="checkbox"
						id="lineThrough"
						checked={attributes.lineThrough}
						onChange={() =>
							setAttributes({ lineThrough: !attributes.lineThrough })
						}
						className="hidden"
					/>
					<StrikethroughIcon />
				</label>
			</div>
		</div>
	);
}

const Heading = ({ attributes, setAttributes }) => {
	const {
		fontSize = 14,
		color = '#000000',
		text = 'Heading',
		bgColor = '#ffffff',
		paddingLeft = 0,
		paddingRight = 0,
		paddingTop = 0,
		paddingBottom = 0,
		marginLeft = 0,
		marginRight = 0,
		marginTop = 0,
		marginBottom = 0,
		alignment = 'left',
		bold,
		italic,
		underline,
		lineThrough,
		borderWidth = 0,
		borderColor = '#000',
		borderTopLeftRadius = 0,
		borderTopRightRadius = 0,
		borderBottomLeftRadius = 0,
		borderBottomRightRadius = 0,
		advancedStyle = {},
	} = attributes;
	return (
		<>
			<div
				style={{
					color: color,
					fontSize: fontSize,
					backgroundColor: bgColor,
					paddingLeft,
					paddingRight,
					paddingTop,
					paddingBottom,
					marginLeft,
					marginRight,
					marginTop,
					marginBottom,
					textAlign: alignment,
					fontWeight: bold ? 'bold' : 'normal',
					fontStyle: italic ? 'italic' : 'normal',
					textDecoration: underline
						? 'underline'
						: lineThrough
						? 'line-through'
						: 'none',
					borderWidth,
					borderColor,
					borderTopLeftRadius,
					borderTopRightRadius,
					borderBottomLeftRadius,
					borderBottomRightRadius,
					...advancedStyle,
				}}
			>
				{text || 'Heading'}
			</div>
			<InspectorControls>
				<div className="mb-4">
					<label htmlFor="text" className="text-sm text-md">
						Heading
					</label>
					<input
						id="text"
						type="text"
						value={text}
						onChange={(e) => setAttributes({ text: e.target.value })}
						className="w-full rounded-sm border border-gray-200"
					/>
				</div>
				<RangeControl
					label="Font Size"
					value={fontSize}
					onChange={(fontSize) => setAttributes({ fontSize })}
					min={0}
					max={100}
					allowReset={true}
				/>
				<TextAlignment attributes={attributes} setAttributes={setAttributes} />
				<TextDecorationCheckBox
					attributes={attributes}
					setAttributes={setAttributes}
				/>
				<ColorPicker
					attributes={{
						label: 'Color',
						defaultColor: color,
						onChangeComplete: (color) => {
							setAttributes({ color: color.hex });
						},
					}}
				/>

				<PanelBody title="Block Style" className="mt-4">
					<ColorPicker
						attributes={{
							label: 'Background Color',
							defaultColor: bgColor,
							onChangeComplete: (color) => {
								setAttributes({ bgColor: color.hex });
							},
						}}
					/>
					<PaddingRangeControl
						attributes={attributes}
						setAttributes={setAttributes}
					/>
					<MarginRangeControl
						attributes={attributes}
						setAttributes={setAttributes}
					/>
				</PanelBody>
				<PanelBody title="Border" className="mt-4">
					<RangeControl
						label="Border Width"
						value={borderWidth}
						onChange={(value) => setAttributes({ borderWidth: value })}
						min={0}
						max={10}
					/>
					{borderWidth > 0 ? (
						<ColorPicker
							attributes={{
								label: 'Border Color',
								defaultColor: borderColor,
								onChangeComplete: (color) => {
									setAttributes({ borderColor: color.hex });
								},
							}}
						/>
					) : null}
					<BorderRadiusControl
						attributes={attributes}
						setAttributes={setAttributes}
					/>
				</PanelBody>
			</InspectorControls>
		</>
	);
};

export default Heading;

Heading.attributesSchema = {
	properties: {
		advancedStyle: {
			type: 'json',
			label: 'Advanced Style',
			description: 'Advanced Styles for the block',
		},
	},
};
