import { useState, useEffect } from "react"
import LabelGenerator from "./LabelGenerator"
import connectStoreApi from '../api/connect-store'
const ValidatePlugin = ({ projectId, afterComplete = () => { }, validateStatus = false }) => {
    const [status, setStatus] = useState("default");
    const fetchData = async () => {
        try {
            if (validateStatus && validateStatus === 'complete') {
                setStatus('loading');
                const response = await connectStoreApi.pluginStatus(projectId);
                if (response && response.installed && response.plugin_configured) {
                    setStatus('complete');
                    afterComplete('complete')
                } else {
                    throw new Error('Failed')
                }
            }
        } catch (error) {
            setStatus('failed');
            afterComplete('failed')
        }


    }
    useEffect(() => {
        fetchData();
    }, [validateStatus])
    return (
        <>
            <LabelGenerator
                name='Install and activate Appmaker Woocommerce plugin'
                status={status}
            />
            <LabelGenerator
                name="App connected"
                status={status}
            />
        </>
    )
}
export default ValidatePlugin;