import { Link, Switch, Route, useRouteMatch } from '@appmaker/core/routes';
import clsx from 'clsx';
import { useFormSchema } from '../store/formSchema';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';

const MenuItem = ({ item, routeName, pathname, itemRoute }) => {
	let { url } = useRouteMatch();
	// console.log(url, 'url', pathname.match(routeName));
	const formSchema = useFormSchema();
	const { template = {} } = formSchema;
	// console.log({ routeName, pathname, itemRoute });
	const isAdmin = checkIsAdmin();
	return (
		<>
			<Link
				key={item.name}
				to={routeName}
				className={clsx(
					routeName === pathname
						? 'bg-gray-100 text-gray-900'
						: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
					'group px-2 py-2 text-sm font-medium rounded-md flex flex-col transition-all'
				)}
			>
				<div className="flex items-center">
					<item.icon
						className={clsx(
							routeName === pathname
								? 'text-blue-600'
								: 'text-gray-400 group-hover:text-gray-500',
							'flex-shrink-0 h-6 w-6'
						)}
						aria-hidden="true"
					/>
					<span className="line-clamp-1 ml-3">{item.name}</span>
				</div>
			</Link>
			{pathname.match(routeName) && (
				<div className="font-medium text-xs flex flex-col gap-1 ml-10 ">
					{Object.keys(template).map((item) => {
						if ((template[item] && !template[item].___isAdminTab) || isAdmin) {
							return (
								<Link
									className={clsx(
										routeName === pathname
											? 'bg-gray-100 text-gray-900'
											: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										'group px-2 py-1 text-sm font-medium rounded-md flex flex-col transition-all'
									)}
									to={`${url}${itemRoute}${template[item].path}`}
								>
									{template[item].title}
								</Link>
							);
						}
						return null;
					})}
				</div>
			)}
		</>
	);
};

export default MenuItem;
