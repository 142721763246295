import { memo, Suspense } from "react";
import AppDownload from '@appmaker/components/AppDownload';
import Loader from '@appmaker/components/Loading';

const HomeScreen = () => {
    return (

        <Suspense fallback={<Loader />}>
            <AppDownload />
        </Suspense>
    )
}

export default memo(HomeScreen);