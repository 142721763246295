import axios from 'axios';
import { DASHBOARD_GRAPHQL_URL } from '@appmaker/core/api/core';
import { useState } from 'react';
import { getProjectId } from '@appmaker/core/store/project';
import { Redirect } from '@shopify/app-bridge/actions';

const api = {
	generateUrl: async (projectId, redirectUrl) => {
		const query = `
        mutation{
            url:googleLoginUrl(redirectUrl:"${redirectUrl}"){
                value:url
            }
        }
        `;
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
			JSON.stringify({
				query: query,
				variables: {},
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response.data?.data?.url?.value;
	},
};
const GoogleLoginOauth = ({
	className,
	disabled = false,
	children,
	label,
	redirectUrl,
}) => {
	const [isLoading, setLoading] = useState(false);
	const projectId = getProjectId();
	const onClickLogin = async () => {
		try {
			setLoading(true);
			const url = await api.generateUrl(projectId, redirectUrl);
			if (url) {
				if (!window.shopifyApp) {
					window.location.href = url;
				} else if (window.shopifyApp) {
					const redirect = Redirect.create(window.shopifyApp);
					redirect.dispatch(Redirect.Action.REMOTE, url);
				}
			}
		} catch (error) {}
		setLoading(false);
	};
	return (
		// <button
		// 	type="button"
		// 	// onClick={() => signInWithProvider(SIGNIN_PROVIDERS.GOOGLE)}
		// 	className={className}
		// onClick={() => {
		// 		onClickLogin();
		// 	}}
		// 	disabled={isLoading || disabled}
		// >
		// 	{children}
		// 	<span>{isLoading ? 'Signing in...' : label}</span>
		// </button>
		<button
			disabled={isLoading || disabled}
			onClick={() => {
				onClickLogin();
			}}
			type="button"
			style={{ backgroundColor: 'rgba(66, 133, 244, 1)' }}
			className="p-0.5 inline-flex items-center space-x-3 rounded-sm overflow-hidden text-sm font-medium text-gray-500 hover:bg-gray-100"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={38}
				height={39}
				viewBox="0 0 38 39"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1 0.5C0.447716 0.5 0 0.947715 0 1.5V37.5C0 38.0523 0.447715 38.5 1 38.5H37C37.5523 38.5 38 38.0523 38 37.5V1.5C38 0.947716 37.5523 0.5 37 0.5H1Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M27.64 19.7045C27.64 19.0664 27.5827 18.4527 27.4764 17.8636H19V21.345H23.8436C23.635 22.47 23.0009 23.4232 22.0477 24.0614V26.3195H24.9564C26.6582 24.7527 27.64 22.4455 27.64 19.7045V19.7045Z"
					fill="#4285F4"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19 28.5C21.43 28.5 23.4673 27.6941 24.9564 26.3196L22.0477 24.0614C21.2418 24.6014 20.2109 24.9205 19 24.9205C16.6559 24.9205 14.6718 23.3373 13.9641 21.21H10.9573V23.5418C12.4382 26.4832 15.4818 28.5 19 28.5V28.5Z"
					fill="#34A853"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.9641 21.21C13.7841 20.67 13.6818 20.0932 13.6818 19.5C13.6818 18.9068 13.7841 18.33 13.9641 17.79V15.4582H10.9573C10.3477 16.6732 10 18.0477 10 19.5C10 20.9523 10.3477 22.3268 10.9573 23.5418L13.9641 21.21V21.21Z"
					fill="#FBBC05"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19 14.0795C20.3214 14.0795 21.5077 14.5336 22.4405 15.4255L25.0218 12.8441C23.4632 11.3918 21.4259 10.5 19 10.5C15.4818 10.5 12.4382 12.5168 10.9573 15.4582L13.9641 17.79C14.6718 15.6627 16.6559 14.0795 19 14.0795V14.0795Z"
					fill="#EA4335"
				/>
			</svg>

			<span
				style={{
					color: 'rgba(255, 255, 255, 1)',
					fontWeight: 500,
					fontFamily: 'Roboto',
				}}
				className="pr-3"
			>
				{isLoading ? 'Logging in...' : 'Sign in with Google'}
			</span>
		</button>
	);
};

export default GoogleLoginOauth;
