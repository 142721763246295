import { getProjectId } from '../store/project';
import {DASHBOARD_GRAPHQL_URL} from './core';
import axios from 'axios';


const api  = {
    appBuildStatus :async()=>{
        const projectId =getProjectId();
        const query = `
        query{
            project {
              apps {
                id
                version
                platform
                buildInProgress
                token
                jobs {
                  jobId
                  type
                  track
                  status
                  message
                  error {
                    type
                    message
                  }
                }
              }
            }
          }
        `;
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
			JSON.stringify({
				query,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response.data?.data?.project?.apps;
        
    }
}

export default api;