/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDataSource } from '@appmaker/core/dataSource';
import {
  useContextState,
  useContextDispatch,
} from '../blockContext';
export const actions = {
  DATA_LOADED: 'DATA_LOADED',
  LOAD_NEXT_PAGE: 'LOAD_NEXT_PAGE',
  APPLY_FILTERS: 'APPLY_FILTERS',
  REQUEST_ERROR: 'REQUEST_ERROR',
};
export const flattenObject = function (ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object') {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
export function reducer(state, action) {
  switch (action.type) {
    case actions.DATA_LOADED:
      // eslint-disable-next-line no-unused-vars
      const appendOrReplace =
        action.responseType === 'replace' || action.hasPages === false
          ? []
          : state.items;
      const items = Array.isArray(action.payload.data)
        ? [...appendOrReplace, ...action.payload.data]
        : [];
      return {
        ...state,
        loading: false,
        tailLoading: false,
        hasMorePage:
          action.payload.data && action.payload.data.length > 0 ? true : false,
        items,
      };
    case actions.LOAD_NEXT_PAGE:
      return {
        ...state,
        page: Number(state.page) + Number(1),
        loading: true,
        tailLoading: true,
      };
    case actions.APPLY_FILTERS:
      const { sort, filters } = action.payload.data;
      return {
        ...state,
        page: Number(1),
        items: [],
        loading: true,
        tailLoading: false,
        filterParams: { ...sort, ...filters },
      };
    case actions.REQUEST_ERROR:
      // const {  } = action.payload.error;
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
function useBlockListState({
  block,
  onAction,
  metaValues,
  pageState,
  currentAction,
  BlocksView,
  blockData,
}) {
  const state = useContextState();
  const dispatch = useContextDispatch();
  // const [state, dispatch] = useReducer(reducer, {
  //   loading: true,
  //   tailLoading: false,
  //   items: [],
  //   data: {},
  //   page: 1,
  // });
  const { attributes } = block;

  const dataSource = legecyDataStoreMap({ attributes, pageState });

  // // console.log(dataSource, 'dataSource');
  const [{ data, error, responseType }, refetch] = useDataSource({
    dataSource,
    page: state.page,
    currentAction,
    filterParams: state.filterParams,
    blockData,
    stateVars: { blockData, currentAction },
  });

  useEffect(() => {
    if (data) {
      dispatch({
        type: actions.DATA_LOADED,
        responseType,
        payload: { data },
        hasPages: attributes.hasPages,
      });
    }
    if (data === '') {
      dispatch({
        type: actions.DATA_LOADED,
        responseType,
        payload: { data: [] },
        hasPages: attributes.hasPages,
      });
    }
  }, [data]);
  useEffect(() => {
    if (pageState?.state.sort || pageState?.state.filters) {
      dispatch({
        type: actions.APPLY_FILTERS,
        payload: { data: pageState.state },
      });
    }
  }, [pageState?.state]);
  useEffect(() => {
    if (error) {
      dispatch({
        type: actions.REQUEST_ERROR,
        responseType,
        payload: { error },
      });
    }
  }, [error]);
  return [state, { dispatch, refetch }];
}
export const legecyDataStoreMap = ({ attributes, pageState }) => {
  if (
    pageState?.state[attributes?.listAttributes?.contextStateKey] &&
    attributes.listAttributes
  ) {
    return {
      responseType: 'replace',
      source: 'inline',
      attributes: {
        items: pageState,
        mapping: attributes.listAttributes?.contextValues,
      },
    };
  }
  if (attributes.dataSource) {
    return attributes.dataSource;
  }
  if (attributes.listAttributes.dataSource) {
    return attributes.listAttributes.dataSource;
  }
  // return {}
  else if (attributes.dataSourceURL) {
    return {
      source: 'uri',
      attributes: {
        url: attributes.dataSourceURL,
        mapping: attributes.dataSourceMap,
      },
    };
  }
};

export default useBlockListState;
