import { memo, Suspense, lazy } from 'react';
import Loader from '@appmaker/components/Loading';
const Dashboard = lazy(() => import('../components'));

const MainDashboard = () => {
	return (
		<Suspense fallback={Loader}>
			<Dashboard />
		</Suspense>
	);
};

export default memo(MainDashboard);
