import React from 'react';
import { InspectorControls, InnerBlocks } from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { withSelect, useSelect } from '@wordpress/data';
import SliderImage from '../../components/SliderImage';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { dispatch } from '@wordpress/data';
import { createBlock } from '@wordpress/blocks';
import { ViewGridIcon } from '@heroicons/react/outline';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import ColorPicker from '@appmaker/core/components/AppmakerForm/components/ColorPicker';

// import {  } from 'downshift';

const renderImage = (innerBlocks) => {
	const out = innerBlocks.map((block, index) => {
		return (
			<div
				className={`carousel-item ${index === 0 ? 'active' : ''}`}
				key={`slide-${index}`}
			>
				{block.attributes.image !== undefined && (
					<SliderImage imageSrc={block.attributes.image.url} />
				)}
			</div>
		);
	});
	return out;
};

const AppmakerSlider = (props) => {
	const {
		setAttributes,
		className,
		attributes,
		isParentOrChildSelected,
		block,
		clientId,
		metaData
	} = props;
	const {
		autoPlay,
		delay,
		hidePagination,
		customImageHeight,
		activeDotColor = '#224ED8',
		centerPagination,
	} = attributes;
	const sliderItems = useSelect(
		(select) => {
			const block = select('core/block-editor').getBlock(clientId);
			const images = block.innerBlocks.map(
				(item) => item.attributes?.image.url
			);
			return images;
		},
		[clientId]
	) ?? [];
	function addItem(data) {
		try {
			const block = createBlock('appmaker/slider-item', {
				image: {
					url: data.url,
					fileName: data.filename,
					id: data.id,
					title: data.title,
				},
				imageContainerStyle: {
					height: data.meta !== undefined ? data.meta.height : '100',
					width: data.meta !== undefined ? data.meta.width : '100',
				},
				thumbnail_meta: {
					height: data.meta !== undefined ? data.meta.height : undefined,
					width: data.meta !== undefined ? data.meta.width : undefined,
				},
				text: '',
				showText: false,
				appmakerAction: {},
				hasAnalytics: false,
			});
			dispatch('core/block-editor').insertBlocks(block, 0, clientId);
		} catch (error) {
			console.log(error);
		}
	}
	if (props.isParentOrChildSelected || sliderItems.length === 0)
		return (
			<div>
				<MediaPlaceholder
					attributes={{
						maxHeight: metaData?.image?.maxHeight,
						maxWidth: metaData?.image?.maxWidth,
						disableImageResize: attributes?.disableImageResize,
						icon: ViewGridIcon,
						title: 'Images Slider',
						description:
							'Draw your customer’s attention to your best-selling products or new arrivals using a slider. You can set custom actions for each image.',
					}}
					onSelect={(images) => {
						// condition in meta because each api having different response of the meta
						// setState({
						// 	images
						// });
						images.map(addItem);

						// images.map(({ url }) => addItem({ url }))
					}}
					allowedTypes={['image']}
					multiple={true}
					labels={{ title: 'Images' }}
					// value={{ id: image && image.id, src: image && image.url }}
				/>
				<div className={className}>
					<InnerBlocks allowedBlocks={['appmaker/slider-item']} />
					<InspectorControls>
						<PanelBody>
							{/* <input
								type="number"
								placeholder="delay"
								onChange={(data) => {
									setAttributes({ delay: data.target.value });
								}}
							/> */}
							{/* <ToggleControl
              label={__('Autoplay', 'newspack-blocks')}
              checked={autoPlay}
              onChange={() => setAttributes({ autoPlay: !autoPlay })}
            />
            <RangeControl
              help={__('Set the delay for the slider', 'newspack-blocks')}
              value={delay}
              onChange={value => setAttributes({ delay: value })}
              min={0}
              max={5}
              required
			  
            /> */}
							<ToggleControl
								label={__('Hide pagination')}
								help={
									"Indication dots will be hidden if you don't want to show them"
								}
								checked={hidePagination}
								value={hidePagination}
								onChange={() =>
									setAttributes({ hidePagination: !hidePagination })
								}
							/>
							{/* <div className="mt-2">
								<label
									htmlFor="custom_image_height"
									className="mb-1 text-gray-800 text-sm"
								>
									Custom Image Height
								</label>
								<input
									id="custom_image_height"
									type="number"
									placeholder="Custom Height"
									className="w-full rounded-md border border-gray-300 text-sm"
									value={customImageHeight}
									onChange={(data) => {
										setAttributes({ customImageHeight: data.target.value });
									}}
								/>
							</div> */}

							{!hidePagination ? (
								<>
									{/* <div>
										<label
											htmlFor="active_dot_color"
											className="mb-1 text-gray-800 text-sm"
										>
											Active Dot Color
										</label>
										<input
											id="active_dot_color"
											type="text"
											placeholder="Active Dot Color"
											className="w-full rounded-md border border-gray-300 text-sm"
											value={activeDotColor}
											onChange={(data) => {
												setAttributes({ activeDotColor: data.target.value });
											}}
										/>
									</div> */}
									<ColorPicker
										attributes={{
											label: 'Active Dot Color',
											defaultColor: activeDotColor,
											onChangeComplete: (color) => {
												setAttributes({ activeDotColor: color.hex });
											},
										}}
									/>
									<div className="mt-4">
										<ToggleControl
											label={__('Center Pagination')}
											checked={centerPagination}
											value={centerPagination}
											onChange={() =>
												setAttributes({ centerPagination: !centerPagination })
											}
										/>
									</div>
								</>
							) : null}
						</PanelBody>
					</InspectorControls>
				</div>
			</div>
		);
	return (
		<>
			<Carousel
				autoPlay={true}
				showArrows={false}
				showStatus={false}
				showThumbs={false}
				infiniteLoop={true}
				showIndicators={!hidePagination}
				renderIndicator={(clickHandler, isSelected, index, label) => {
					if (isSelected) {
						return (
							<li
								className="h-2 w-4 inline-block mr-2 rounded-full bg-blue-700"
								style={{
									backgroundColor: activeDotColor ? activeDotColor : null,
								}}
							/>
						);
					}
					return (
						<li className="h-2 w-2 inline-block mr-2 rounded-full bg-gray-200" />
					);
				}}
			>
				{/* <InnerBlocks allowedBlocks={['appmaker/slider-item']} /> */}

				{sliderItems.map((item, key) => (
					<img key={`slider-${key}`} src={item} />
				))}
			</Carousel>
		</>
		// <div>
		//   <div id="appmakerSlider" className="carousel slide" data-ride="carousel">
		//     <InnerBlocks allowedBlocks={['appmaker/slider-item']} />

		//     {/* <div className="carousel-inner">{renderImage(block.innerBlocks)}</div> */}
		//   </div>
		// </div>
	);
};

export default withSelect((select, ownProps) => {
	const {
		isBlockSelected,
		hasSelectedInnerBlock,
		getBlock,
		getBlockListSettings,
		getBlockRootClientId,
		getTemplateLock,
		isNavigationMode,
	} = select('core/block-editor');
	const { clientId, isSmallScreen } = ownProps;
	const block = getBlock(clientId);
	const rootClientId = getBlockRootClientId(clientId);

	return {
		block,
		blockListSettings: getBlockListSettings(clientId),
		isParentOrChildSelected:
			isBlockSelected(clientId) || hasSelectedInnerBlock(clientId, true),
		parentLock: getTemplateLock(rootClientId),
		enableClickThrough: isNavigationMode() || isSmallScreen,
	};
})(AppmakerSlider);
