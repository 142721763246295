import ThemeCard from './ThemeCard';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from '@appmaker/core/routes';
import { THEME_LIST } from '../api/graphql';
import Loader from '@appmaker/components/Loading';
import { useEffect } from 'react';
import useThemeStore from '../store/list';
const AvailableTheme = () => {
	const themeList = useThemeStore(store=>store?.themes);
	const installedTheme = useThemeStore(store=> store?.installList);

	const getRouterPath = useRouterPath();
	const { handle, projectId } = useParams();
	const checkInstalled = (handle) => {
		const installData = installedTheme?.find(item=> item?.theme?.handle === handle);
		return installData !== undefined
	};
	const checkActive = (handle)=>{
		const installData = installedTheme?.find(item=> item?.theme?.handle === handle);
		return installData?.status === 'active'
	}
	return (
		<>
			<div className="flex items-center justify-between space-x-4">
				<h2 className="text-lg font-medium text-gray-900">Available Themes</h2>
			</div>
			<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
				{themeList?.map((theme) => (
					<ThemeCard
						key={theme.id}
						getRouterPath={getRouterPath}
						theme={theme}
						checkInstalled={checkInstalled}
						checkActive={checkActive}
					/>
				))}
			</div>
		</>
	);
};

export default AvailableTheme;
