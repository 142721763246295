// @flow strict

import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';

import classNames from 'classnames';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project';

function MenuLink({ item, pathname, getRoutePath }) {
	const projectId = getProjectId();
	const child = (
		<item.icon
			className={classNames(
				getRoutePath(item.routeName) === pathname
					? 'text-gray-500'
					: 'text-gray-400 group-hover:text-gray-500',
				'flex-shrink-0 h-6 w-6'
			)}
			aria-hidden="true"
		/>
	);
	const className = classNames(
		getRoutePath(item.routeName) === pathname
			? 'bg-gray-100 text-gray-900'
			: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
		'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
	);
	return item.href ? (
		<a href={item.href} className={className}>
			{child}
			<span className="line-clamp-1 ml-3">{item.name}</span>
			{/* Hide when small = "true" */}
		</a>
	) : (
		<Link to={getRoutePath(item.routeName)} className={className}
			onClick={() => {
				if (item && item.addTracker) {
					appmaker.doAction('appmaker-analytics-event',
						{
							event: item.addTracker.eventName,
							params: {
								project_id: projectId
							}
						})
				}
			}}
		>
			{child}
			<span className="line-clamp-1 ml-3">{item.name}</span>
			{/* Hide when small = "true" */}
		</Link>
	);
}
function MenuItemRender({ item, pathname, getRoutePath }) {
	return item.MenuItemComponent ? (
		<item.MenuItemComponent
			item={item}
			pathname={pathname}
			itemRoute={item.routeName}
			routeName={getRoutePath(item.routeName)}
		/>
	) : (
		<MenuLink
			item={item}
			pathname={pathname}
			getRoutePath={getRoutePath}
		/>
	)
}
function SidebarNavigation({ navigation, basePath, small = false }) {
	const routerPath = useRouterPath();
	let { pathname } = useLocation();


	const getRoutePath = (routeName) =>
		routerPath(`${basePath ? basePath : ''}${routeName}`);

	return (
		<nav
			className={classNames(
				'mt-5 flex-1 px-2 bg-white space-y-1',
				small && 'flex flex-col items-center'
			)}
		>
			{navigation.map((item, id) => {
				console.log(`${item.name}-${id}`);
				if (item.adminOnly && !checkIsAdmin()) {
					return null
				}
				return <MenuItemRender key={`${item.name}-${id}`} item={item} pathname={pathname} getRoutePath={getRoutePath} />
			}

			)}
		</nav>
	);
}

export const SideNavFooter = ({ small = false }) => (
	<Link to="/" className="flex-shrink-0 w-full group block">
		<div className="flex items-center">
			<ArrowLeftIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" />
			{!small && (
				<div className="ml-3">
					<p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
						My Apps
					</p>
				</div>
			)}
		</div>
	</Link>
);

export default SidebarNavigation;
