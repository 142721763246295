import React from 'react';
import {
	BlockCard,
	ProductsScrollerItem,
	GridItem,
	ImageScrollerItem,
	MenuItem,
	BannerItem,
} from '../../../new-blocks/components';

export default function PlaygroundBlock() {
	return (
		<>
			<Banner />
			<Menu />
			<ImageScroller />
			<ProductScroller />
			<Grid />
		</>
	);
}

const Banner = () => <BannerItem attributes={{ image: sampleList[1].src }} />;

const Menu = () => (
	<BlockCard
		attributes={{
			title: 'Menu',
			accessButton: 'View more',
		}}
	>
		<div className="p-4 flex flex-col gap-4">
			{sampleList.map((menu) => (
				<MenuItem attributes={{ thumb: menu.src, title: menu.title }} />
			))}
		</div>
	</BlockCard>
);

const ImageScroller = () => (
	<BlockCard
		attributes={{
			title: 'Image Scroller',
			accessButton: 'View more',
		}}
	>
		<div className="p-4 flex gap-4 whitespace-nowrap overflow-x-auto">
			{sampleList.map((scroller) => (
				<ImageScrollerItem attributes={{ image: scroller.src }} />
			))}
		</div>
	</BlockCard>
);

const Grid = () => (
	<BlockCard
		attributes={{
			title: 'Grid',
			accessButton: 'View more',
		}}
	>
		<div className="p-4 grid grid-cols-3 gap-2">
			{sampleList.map((grid) => (
				<GridItem attributes={{ image: grid.src }}>
					<div className="text-center text-sm font-medium mt-0.5">
						{grid.title}
					</div>
				</GridItem>
			))}
		</div>
	</BlockCard>
);

const ProductScroller = () => (
	<BlockCard
		attributes={{
			title: 'Prebuild product scroller',
			accessButton: 'View more',
		}}
	>
		<div className="px-4 pb-4 flex gap-4 whitespace-nowrap overflow-x-auto">
			{sampleList.map((product) => (
				<ProductsScrollerItem
					attributes={{
						product: {
							title: product.title,
							src: product.src,
							price_display: product.price_display,
						},
						groceryMode: true, //grocery mode add button
						quickAdd: false, //quick add button
					}}
				/>
			))}
		</div>
	</BlockCard>
);

function getRandomInt() {
	return Math.floor(Math.random() * (300 - 150) + 150);
}

const sampleList = [
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
	{
		title: 'Sample title',
		src: `https://picsum.photos/${getRandomInt()}`,
		price_display: '$20.12',
	},
];
