// const { default: appmaker } = require("..");
import appmaker from '../'
const namespace = "appmaker-app-extensions";

const register= (plugin) => {
appmaker.addFilter(namespace, 'extensions-add', (plugins) => {
		plugins[plugin.id] = plugin;
		return plugins;
	});
};

const listAll = () => appmaker.applyFilters(namespace, {});
const activateAll = () => {
	const allPlugins = listAll();
	const pluginsArray = Object.values(allPlugins);
	console.log(allPlugins, pluginsArray)
	pluginsArray.map((plugin) => {
		return plugin.activate();
	});
};

const activate = (pluginList) => {
	const allPlugins = listAll();
	pluginList.map((plugin) => {
		try {
			allPlugins[plugin.id].activate();
			console.log(`${plugin.id}=>Active`);
		} catch (error) {
			console.log(error);
		}
	});
};
const extensions={
    register,
    listAll,
    activateAll,
    activate
}
export default extensions;