import React, { useEffect, useState } from 'react';
import appmaker from '@appmaker/core/index';
import { useParams, Link } from '@appmaker/core/routes';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import {
	createStore,
	AppmakerFormProvider,
	useAppmakerFormStoreApi,
	useAppmakerFormStore,
} from '@appmaker/core/components/AppmakerForm/store';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import { useProject } from '@appmaker/core/hooks';
import { useAsync } from 'react-use';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import AppmakerSwitch from '@appmaker/components/Form/fields/AppmakerSwitch';
import api from '../api';
import {
	useExtensionList,
	setExtensionList,
	showUnSavedChanges,
} from '../store';
import hooks from '../hooks';
import Loader from '@appmaker/components/Loading';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { addNewPlugin } from '@appmaker/core/store/project';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import AdminEditExtension from '../components/AdminEditExtension';
import ExtensionBlocksView from '../components/ExtensionBlocksView';
import useFeatureList from '../hooks/enableFeature';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import DropDownMenu from '@appmaker/components/DropDownMenu';
import DetailHeader from '../components/DetailHeader';

const MIN_VERSION_SCHEMA = {
	properties: {
		android: {
			type: 'string',
			label: 'Android version',
		},
		ios: {
			type: 'string',
			label: 'IOS version',
		},
	},
};

const MAX_VERSION_SCHEMA = {
	properties: {
		android: {
			type: 'string',
			label: 'Android version',
		},
		ios: {
			type: 'string',
			label: 'IOS version',
		},
	},
};
const FormSchemaWrapper = ({ extensionId, schema, metaKey }) => {
	const { projectId } = useProject();
	// const metaKey = `apm_extension_${extensionId}`;
	const listData = useExtensionList((data) => data.list);
	const extensionData = listData.find(
		(extension) => extension.id === extensionId
	);
	const { installedMeta: { projectExtensionId } = {} } = extensionData || {};
	const { value, error, loading } = useAsync(async () => {
		const result = api.getValues({ projectId, projectExtensionId, metaKey });
		return result;
	}, []);
	// console.log(state,error,loading,111);
	return loading ? (
		<div className="flex-1 overflow-hidden animate-pulse gap-4 flex flex-col">
			<div className="h-16 w-full bg-gray-200 rounded-lg" />
			<div className="h-16 w-full bg-gray-100 rounded-lg" />
			<div className="h-16 w-full bg-gray-50 rounded-lg" />
		</div>
	) : error ? (
		<div className="text-red-500">Error: {error.message}</div>
	) : (
		<AppmakerFormProvider
			createStore={createStore({
				formData: value ? value : {},
				initialFormDaa: value ? value : {},
			})}
		>
			{/* <button onClick={() => doFetch()}>Start loading</button> */}
			<FormSchema schema={schema} projectId={projectId} metaKey={metaKey} />
		</AppmakerFormProvider>
	);
};

function SaveButton({ onClickSave, projectId, metaKey }) {
	const [isSaving, setIsSaving] = React.useState(false);
	const extensionList = useExtensionList((data) => data.list);
	let { extensionId } = useParams();
	const extension =
		extensionList.find((extension) => extension.id === extensionId) || {};
	const storeApi = useAppmakerFormStoreApi();
	const storeOnSave = useAppmakerFormStore((selector) => selector?.setOnSave);
	const beforeSaveButton = appmaker.applyFilters(
		`${extensionId}-${metaKey}-before-save-button`,
		null,
		{
			extension: extension || {},
			projectId,
			metaKey,
			isSaving,
		}
	);

	async function onClickSave() {
		const { formData } = storeApi.getState();
		//    console.log(data);
		setIsSaving(true);
		const { installedMeta: { projectExtensionId } = {} } = extension;
		// const resp = await apiFunction.setMeta(projectId, metaKey, formData);
		try {
			const resp = await api.extensionSettings({
				projectId,
				metaKey,
				metaValue: formData,
				projectExtensionId: projectExtensionId,
			});
			storeOnSave();
			setIsSaving(false);
		} catch (error) {}
		// const data = await create('extension_config', body);
	}
	return (
		<>
			{beforeSaveButton}
			<button
				disabled={isSaving}
				type="button"
				onClick={onClickSave}
				className="my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
			>
				{isSaving ? (
					<>
						<LoadingIcon className="-ml-1 mr-2 h-6 w-6 animate-spin" />
						Saving...
					</>
				) : (
					'Save'
				)}
			</button>
		</>
	);
}

function UnSavedChange() {
	const showUnsaved = useExtensionList((data) => data.showUnsavedChanges);
	if (!showUnsaved) return null;
	return (
		<p className="ml-3 font-medium  truncate flex gap-1 items-center text-yellow-500">
			<span className="flex h-3 w-3 relative items-center ml-2">
				<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
				<ExclamationCircleIcon className="h-4 w-4" />
			</span>
			<span className=" md:inline">Unsaved Changes</span>
		</p>
	);
}

function FormSchema({ schema, projectId, metaKey }) {
	// useAsync
	// const [res] = useAsync(async () => {
	const initialFormData = useAppmakerFormStore(
		(selector) => selector?.initialFormData
	);
	const formData = useAppmakerFormStore((selector) => selector?.formData);
	useEffect(() => {
		if (formData && initialFormData) {
			showUnSavedChanges(
				JSON.stringify(formData) !== JSON.stringify(initialFormData)
			);
		}
	}, [initialFormData, formData]);
	return (
		<div className="max-w-3xl">
			<AppmakerForm
				formVersion={2}
				// value={{ formData: flatten(formData) }}
				schema={schema}
			/>
			<SaveButton projectId={projectId} metaKey={metaKey} />
		</div>
	);
}

const tabs = [
	// { id: 'details', name: 'Details', current: true },
	{
		id: 'settings',
		name: 'Settings',
		current: false,
		restrictOnDeactivation: true,
		metaKey: 'settings',
	},
	// {
	// 	id: 'admin',
	// 	name: 'Admin Settings',
	// 	current: false,
	// 	restrictOnDeactivation: true,
	// 	metaKey: 'admin_settings',
	// },
	// {
	// 	id: 'min_version',
	// 	name: 'Min Version',
	// 	current: false,
	// 	restrictOnDeactivation: true,
	// 	metaKey: 'min_version',
	// },
	// {
	// 	id: 'max_version',
	// 	name: 'Max Version',
	// 	current: false,
	// 	restrictOnDeactivation: true,
	// 	metaKey: 'max_version',
	// },
	// {
	// 	id: 'new_settings',
	// 	name: 'New Settings',
	// 	current: false,
	// 	restrictOnDeactivation: true,
	// 	metaKey: 'settings',
	// },
];
function ExtensionContainer({ children, extension }) {
	const { status } = extension || {};
	const installed = status === 'active' || status === 'installed';
	if (children) {
		return children;
	}
	return (
		<TabSchemaWrapper
			tabId="settings"
			extension={extension}
			className="m-4 bg-white max-w-3xl p-4 shadow-sm rounded-lg"
		/>
	);
}
const TabSchemaWrapper = ({ tabId, extension = {}, className }) => {
	useEffect(() => {
		// when switching the tab unsaved should be removed from header
		showUnSavedChanges(false);
	}, [tabId]);
	const tab = tabs.find((item) => item.id === tabId) || {};
	if (tabId === 'details') {
		return null;
	}
	if (tabId === 'settings') {
		return (
			<div className={extension ? className : null}>
				{extension &&
				extension.formSchema &&
				Object.keys(extension.formSchema).length > 0 ? (
					<FormSchemaWrapper
						extensionId={extension.id}
						schema={extension.formSchema}
						metaKey={tab.metaKey ? tab.metaKey : undefined}
					/>
				) : null}
			</div>
		);
	}
	if (tabId === 'admin') {
		return (
			<div className={className}>
				{extension &&
				extension.backendSchema &&
				Object.keys(extension.backendSchema).length > 0 ? (
					<FormSchemaWrapper
						extensionId={extension.id}
						schema={extension.backendSchema}
						metaKey={tab.metaKey ? tab.metaKey : undefined}
					/>
				) : null}
			</div>
		);
	}
	if (tabId === 'min_version' && extension?.id && tab?.metaKey) {
		return (
			<div className={className}>
				<span className="font-bold">
					Note: Extension will be removed upto this version code
				</span>
				<FormSchemaWrapper
					extensionId={extension.id}
					schema={MIN_VERSION_SCHEMA}
					metaKey={tab.metaKey ? tab.metaKey : undefined}
				/>
			</div>
		);
	}
	if (tabId === 'max_version' && extension?.id && tab?.metaKey) {
		return (
			<div className={className}>
				<span className="font-bold">
					Note: Extension will be removed above this version code
				</span>
				<FormSchemaWrapper
					extensionId={extension.id}
					schema={MAX_VERSION_SCHEMA}
					metaKey={tab.metaKey ? tab.metaKey : undefined}
				/>
			</div>
		);
	}
	return null;
};
function PluginRoute() {
	const extensionData = useExtensionList();
	const extensionList = extensionData.list;
	// const extensions = appmaker.extensions.listAll();
	let { extensionId } = useParams();
	// const extension = extensions[extensionId];
	const extension = extensionList.find(
		(extension) => extension.id === extensionId
	);
	const { Component, formSchema, status, backendSchema } = extension || {};
	const { isLoading } = hooks.useExtensionList({ refetch: false });
	if (isLoading || !extension) {
		return <Loader />;
	}
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<div className="w-full mx-auto relative">
				<DetailHeader extension={extension} />
				<ExtensionContainer
					extension={extension}
					extensionId={extensionId}
					formSchema={formSchema}
					backendSchema={backendSchema}
				>
					{Component ? <Component /> : null}
				</ExtensionContainer>
			</div>
		</div>
	);
}
export default PluginRoute;
