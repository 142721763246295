import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks =[{
	"clientId": "3a397f4d-bbd7-4580-b393-b2cb38329f17",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_SEARCH"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_SEARCH",
				"label": "Open search page"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_ae454fb1-ba98-4504-b4b3-667f642f3962.png?v=1656679017",
			"id": "gid://shopify/MediaImage/30766594228481",
			"title": "a.png"
		},
		"imageContainerStyle": {
			"height": 157,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 157,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "f539bd05-44e3-48e3-a739-0b2455b59f3c",
	"name": "appmaker/slider",
	"isValid": true,
	"attributes": {
		"autoPlay": false,
		"delay": 1,
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"customImageHeight": ""
	},
	"innerBlocks": [{
		"clientId": "69734672-d82d-4976-9177-9b2b1be53643",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_fcce3633-56cd-4ae8-aafa-68e2cebee0f3.png?v=1656679033",
				"id": "gid://shopify/MediaImage/30766597046529",
				"title": "b.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 656,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "528dc1ac-f05e-458d-8540-17193e86b635",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-1_e1270b42-17e5-47d9-860f-a07f2d17f92a.png?v=1656679033",
				"id": "gid://shopify/MediaImage/30766597013761",
				"title": "b-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 656,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "f161bb93-873e-45b1-a61f-a259a69e3916",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-5_e58f1522-5591-4156-bb5a-d4c82db7d1df.png?v=1656679049",
			"id": "gid://shopify/MediaImage/30766599438593",
			"title": "c-5.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "fc837a2d-2734-4fb6-add2-9523bcc0f164",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "c975213c-0642-42c4-ae3e-1a6323947af2",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_2e0f43a3-55d6-4dd7-8c95-63d363c5c291.png?v=1656679367",
				"id": "gid://shopify/MediaImage/30766637285633",
				"title": "c.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "a4213984-06a8-41d7-a0fe-bb1592ec2f10",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-1_eac118ed-7c77-4832-b885-83512d7b8b90.png?v=1656679366",
				"id": "gid://shopify/MediaImage/30766637220097",
				"title": "c-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "6f30f37a-9e46-488d-8d2d-a1dc85b15d24",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-2_d9e3d8f2-4f7a-4a7c-a5d0-454844c93adf.png?v=1656679363",
				"id": "gid://shopify/MediaImage/30766637121793",
				"title": "c-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "c837d44e-ff79-4574-9586-5fd12c349954",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-3_d77575cc-3d43-4ebb-97e3-065f8296c0bc.png?v=1656679363",
				"id": "gid://shopify/MediaImage/30766637056257",
				"title": "c-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "df5d1dff-4054-4bb3-9e43-684fb9492f7d",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-4_1136d7a8-f5b7-4e1c-8d3d-4306e5743807.png?v=1656679363",
				"id": "gid://shopify/MediaImage/30766637023489",
				"title": "c-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "a5c36b9c-f823-4967-bbd2-4a72d0c900f8",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-1_dd5e25ff-524e-4b45-937c-fc8c874f71d4.png?v=1656679541",
			"id": "gid://shopify/MediaImage/30766663794945",
			"title": "h-1.png"
		},
		"imageContainerStyle": {
			"height": 361,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 361,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "dc41e451-8e85-4420-a9d0-6120e3e5adf6",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-4_95811010-81e3-48e4-9a06-37a15666c0c4.png?v=1656679430",
			"id": "gid://shopify/MediaImage/30766646329601",
			"title": "e-4.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "4ae7ff06-fd02-4fb6-90d8-8cff0e4cb0a4",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "5be41bda-56ff-416b-94fe-8c29f9c60c73",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_e214b5a9-50f5-4a8e-bb4f-31a3c51a1e19.png?v=1656679455",
				"id": "gid://shopify/MediaImage/30766649803009",
				"title": "f.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "7bfbb492-6d18-4e23-ae8a-c403677db424",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1_e3b7f0b5-a37c-4b6c-a32c-f0ac79ebba9c.png?v=1656679453",
				"id": "gid://shopify/MediaImage/30766649606401",
				"title": "f-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "2bbea964-a472-4c82-8a1c-04394e803048",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2_c5cff0ef-971a-4c2c-a32d-96a24bb9fb62.png?v=1656679451",
				"id": "gid://shopify/MediaImage/30766649508097",
				"title": "f-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "e35099e2-1d3b-44de-8ec8-ef9c503d06f4",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-3_0111950a-a48d-4ec8-9171-044dce359d83.png?v=1656679451",
				"id": "gid://shopify/MediaImage/30766649540865",
				"title": "f-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "2796588a-a751-4fb8-ad80-351a431251fb",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "e3225ba8-77e0-447e-b70a-a62a110ade5e",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e_c8a18ebf-9ce2-400d-bd0f-5f2b453b82e5.png?v=1656679408",
				"id": "gid://shopify/MediaImage/30766642954497",
				"title": "e.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "af005240-6be9-4a02-b4c8-7755487b7036",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-1_bcd5e174-3078-473d-bbe1-609803d9d2e7.png?v=1656679405",
				"id": "gid://shopify/MediaImage/30766642430209",
				"title": "e-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "186f479d-f466-401b-b13e-44560c4dd772",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-2_8cef425d-e671-45a6-864d-58c102c82253.png?v=1656679405",
				"id": "gid://shopify/MediaImage/30766642299137",
				"title": "e-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "99417853-b147-4df9-a076-ccb3074aa7b0",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-3_c49da4cf-06ba-489a-b4d0-1a391b31f7eb.png?v=1656679405",
				"id": "gid://shopify/MediaImage/30766642266369",
				"title": "e-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "05f61c89-928c-49c2-9424-067850504ed0",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-3_8f3bfc3a-45ef-4eae-a3bc-e22d62d10e36.png?v=1656679541",
			"id": "gid://shopify/MediaImage/30766663729409",
			"title": "h-3.png"
		},
		"imageContainerStyle": {
			"height": 361,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 361,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "acdeb56e-8d77-42ea-9275-0000a29f0dd8",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h_25ebb1af-f793-4097-bb34-f1a6871a6c28.png?v=1656679545",
			"id": "gid://shopify/MediaImage/30766664515841",
			"title": "h.png"
		},
		"imageContainerStyle": {
			"height": 361,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 361,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "2ce36748-9c70-46e2-b63e-003eee468ee4",
	"name": "appmaker/slider",
	"isValid": true,
	"attributes": {
		"autoPlay": false,
		"delay": 1,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "93569669-c482-4ad6-a504-1a2b007ed01b",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d_a39b95fe-4574-4159-97b1-4a66f0144a2e.png?v=1656679511",
				"id": "gid://shopify/MediaImage/30766657929473",
				"title": "d.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 397,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "f5c81f10-efef-4461-8c48-b9c2bb3381be",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-1_c952161b-9056-4680-b0d1-0a8e59165ea5.png?v=1656679510",
				"id": "gid://shopify/MediaImage/30766657831169",
				"title": "d-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 397,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "6770ada4-84fc-4bf5-bead-cfb81d9b3934",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-3_68ee9bca-dcd1-4dae-bcbb-7271e1f495d4.png?v=1656679509",
				"id": "gid://shopify/MediaImage/30766657077505",
				"title": "d-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 397,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "86678a51-2ea7-4de5-b04e-8b7b18b33909",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-2_088ebc57-bbea-4c4b-97c7-436aaf85130b.png?v=1656679509",
				"id": "gid://shopify/MediaImage/30766657143041",
				"title": "d-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 397,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "37c80188-c328-4932-b365-b4447c6291cb",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-5.png?v=1656679464",
			"id": "gid://shopify/MediaImage/30766651015425",
			"title": "e-5.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "fcdfaee1-9cb7-4416-942b-520ac41d3d4f",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "c5f6099a-7df0-4b40-a30c-abf0abf482e9",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_63b35461-505c-43e1-a36e-89477ded1aae.png?v=1656679482",
				"id": "gid://shopify/MediaImage/30766653833473",
				"title": "g.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "b4da11d7-f7f4-444e-8978-f4fed7146c05",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-1_3fe31e94-b72e-44de-8d9b-8ae2bc38dc71.png?v=1656679480",
				"id": "gid://shopify/MediaImage/30766653636865",
				"title": "g-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "8f864d67-45a6-4643-a428-ea05e7acff89",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-3_9869162d-4c34-4bd2-abea-7f1a89f8c26e.png?v=1656679479",
				"id": "gid://shopify/MediaImage/30766653571329",
				"title": "g-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "42d25703-7218-403a-b16f-5b6589716dd6",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-2_1b8a413a-e4f7-4e77-8933-df9716575cb4.png?v=1656679479",
				"id": "gid://shopify/MediaImage/30766653604097",
				"title": "g-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 372,
				"width": 372
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "5721a45d-2ffb-4393-b9c9-b5789729e618",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-2_6f061516-875f-4d55-b7bf-1ad1294b5520.png?v=1656679550",
			"id": "gid://shopify/MediaImage/30766665236737",
			"title": "h-2.png"
		},
		"imageContainerStyle": {
			"height": 361,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 361,
			"width": 1080
		}
	},
	"innerBlocks": []
}]
// function generate() {

// }
const template = {
	blocks,
	id: 'grocery-1',
	name: 'Grocer',
	category: 'Grocery',
	price: 'Free',
	imageSrc: '/images/themes/grocery.png',
	previewSrc:'/images/preview/templates/grocery.png',
	imageAlt: 'Mobile app grocery theme',
	description:"Our Grocery template uses preselected blocks that will make your app building journey easier than ever before! created after popular grocery apps, this will be a good starting point with lot’s of room to make this your own!"
};
export default template;
