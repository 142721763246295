import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import createContext from 'zustand/context';

const createPageStateStore =
  ({ pageId,formData = {} }) =>
    () =>
      create(
        devtools(
          (set) => ({
            blockDataSourceLoading: true,
            blockData: {},
            formData,
            pageId,
            setFormDataValue: (key, value) => {
              set(
                produce((state) => {
                  state.formData[key] = value;
                }))
            },
            setFormData: (_formData) => {
              set(
                produce((state) => {
                  state.formData = _formData;
                }))
            },

            setPageStateVar: (name, value) =>
              set(
                produce((state) => {
                  state[name] = value;
                }),
              ),
            setPageState: (_newState = {}) =>
              set(
                produce((state) => {
                  Object.keys(_newState).forEach((key) => {
                    state[key] = _newState[key];
                  });
                }),
              ),

          }),
          { name: `pageState-${pageId}` },
        ),
      );

const {
  Provider: PageStateProvider,
  useStore: usePageState,
  useStoreApi: usePageStateApi,
} = createContext();
// PageStateProvider, createStore
export function useFormData() {
  const formData = usePageState((state) => state.formData);
  const setFormData = usePageState((state) => state.setFormData);
  
  return [formData, setFormData];
}
export {
  createPageStateStore,
  PageStateProvider,
  usePageState,
  usePageStateApi,
};
