/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import appmaker from '@appmaker/core/';
import { MenuIcon } from '@heroicons/react/outline';
import {
	Switch,
	Route,
	Link,
	useRouteMatch,
	useLocation,
} from 'react-router-dom';
import { TemplateIcon } from '@heroicons/react/outline';
import ActionPlayground from './routes/ActionPlayground';
import DataSourcePlayground from './routes/DataSourcePlayground';
import JSONVIEW from './routes/JSONVIEW';
import CustomBlockEditor from './routes/CustomBlockEditor';
import FeatherIcons from './routes/FeatherIcons';
import FormBlocks from '@appmaker/core/components/AppmakerForm/blocks';
import AppBuildTrigger from './routes/AppBuildTrigger';

const routes = [
	{
		routeName: '/formBlocks',
		component: FormBlocks,
		name: 'Form Blocks',
		icon: TemplateIcon,
	},
	{
		routeName: '/action',
		component: ActionPlayground,
		name: 'Action',
		icon: TemplateIcon,
	},
	{
		routeName: '/data-source',
		component: DataSourcePlayground,
		name: 'Data Source',
		icon: TemplateIcon,
	},
	{
		routeName: '/JSONVIEW',
		component: JSONVIEW,
		name: 'JSON VIEW',
		icon: TemplateIcon,
	},
	{
		routeName: '/CustomBlockEditor',
		component: CustomBlockEditor,
		name: 'CustomBlockEditor',
		icon: TemplateIcon,
	},
	{
		routeName: '/feather-icons',
		component: FeatherIcons,
		name: 'Feather Icons',
		icon: TemplateIcon,
	},
	{
		routeName: '/app-build-trigger',
		component: AppBuildTrigger,
		name: 'App Build Trigger',
		icon: TemplateIcon,
	},
];
const navigation = routes;

const SideNav = () => {
	let { pathname } = useLocation();
	let { params } = useRouteMatch();
	const { projectId } = params;
	const getRoutePath = (routeName) => `/${projectId}/playground${routeName}`;
	return (
		<nav className="mt-5 flex-1 px-2 bg-white space-y-1">
			{navigation.map((item) => (
				<Link
					key={item.name}
					to={getRoutePath(item.routeName)}
					className={classNames(
						getRoutePath(item.routeName) === pathname
							? 'bg-gray-100 text-gray-900'
							: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
						'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
					)}
				>
					<item.icon
						className={classNames(
							getRoutePath(item.routeName) === pathname
								? 'text-gray-500'
								: 'text-gray-400 group-hover:text-gray-500',
							'mr-3 flex-shrink-0 h-6 w-6'
						)}
						aria-hidden="true"
					/>
					{item.name}
				</Link>
			))}
		</nav>
	);
};

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Example() {
	let { path } = useRouteMatch();
	return (
		<div className=" flex overflow-hidden bg-gray-100">
			{/* Static sidebar for desktop */}
			<div className="hidden md:flex md:flex-shrink-0">
				<div className="flex flex-col w-64">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
						<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
							<SideNav />
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-0 flex-1 overflow-hidden">
				<div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
					<button className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
						<span className="sr-only">Open sidebar</span>
						<MenuIcon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<main className="">
					<div className="">
						<div className="">
							{/* Replace with your content */}
							<Switch>
								{routes.map((item) => {
									// console.log(`${path}${item.routeName}`, item);
									return (
										<Route
											key={item.routeName}
											path={`${path}${item.routeName}`}
										>
											<item.component />
										</Route>
									);
								})}
							</Switch>
							{/* /End replace */}
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
