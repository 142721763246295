import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import FormItemWrapper from './FormItemWrapper';
import { createStore, Provider, useStore } from './store';
const StateView = () => {
	const state = useStore((store) => store);
	return (
		<pre className="mt-2 border-2 p-2 bg-white">
			{JSON.stringify({ ...state, schema: {} }, null, 2)}
		</pre>
	);
};
const AppmakerForm = ({
	value,
	setValue,
	options,
	optionKey = 'option',
	debug = false,
	formVersion = 1,
}) => {
	const actionOptions = Object.keys(options).map((key) => {
		return { value: key, label: options[key].label };
	});
	const state = useStore((store) => store);
	const setState = useStore((store) => store.setState);
	const defaultValue = useStore(
		(store) => store?._internal && store?._internal[optionKey]
	);
	// console.log(value);
	useEffect(() => {
		setValue(state);
	}, [state]);
	return (
		<>
			<div>
				<Select
					options={actionOptions}
					defaultValue={defaultValue}
					onChange={(option) => {
						setState((state) => {
							state.formData[optionKey] = option.value;
							state._internal[optionKey] = option;
						});
					}}
				/>
			</div>
			<FormItemWrapper
				parentKey={optionKey}
				schema={value.schema[defaultValue?.value]}
				formVersion={formVersion}
			/>
			{debug && <StateView />}
		</>
	);
};
const AppmakerFormWrapper = (props) => (
	<Provider createStore={createStore(props.value)}>
		<AppmakerForm {...props} />
	</Provider>
);

export default AppmakerFormWrapper;
