import { getProjectId } from '@appmaker/core/store/project';
import { V3_BASE_URL, BASE_URL } from '@appmaker/core/api/core';
const getShopifyData = (urlKey) => {
	const projectId = getProjectId();
	return {
		url: `${BASE_URL}/projects/${projectId}/api-proxy/${urlKey}`,
		projectId,
	};
};

export { getShopifyData };
