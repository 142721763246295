import { useState } from 'react';
import ValidateURL from '../../components/ValidateUrl';
import { useProject } from '@appmaker/core/hooks';
import ValidatePlugin from '../../components/ValidatePlugin';
import ContactSupport from '../../components/ContactSupport';
import coreApi from '@appmaker/core/api/core';
import appmaker from '@appmaker/core/index'
import { setPublicMeta } from '@appmaker/core/store/project';

const PluginStatus = () => {
	const { projectId } = useProject();
	const [isValidateCompleted, setValidateUrl] = useState(false);
	const [showHelpWidget, setShowHelp] = useState(false);
	// console.log();
	return (
		<div>
			<div className="py-12 px-4 sm:px-6 lg:px-8">
				<nav className="flex justify-start" aria-label="Progress">
					<ol role="list" className="space-y-6">
						<ValidateURL
							projectId={projectId}
							afterComplete={(status) => {
								setValidateUrl(status);
								if (status === 'failed')
									setShowHelp(true)
							}}
						/>
						<ValidatePlugin
							projectId={projectId}
							validateStatus={isValidateCompleted}
							afterComplete={async (status) => {
								console.log(status);
								// console.log();
								if (status === 'complete') {
									appmaker.doAction('url_verification_completed', { projectId })
									setPublicMeta({ url_verification_completed: true })
									await coreApi.notifyPluginConfigChanged(projectId);
									let redirectLocation = `https://manage-v2.appmaker.xyz/apps/${projectId}`;
									if (window.shopifyHost) {
										redirectLocation += `?host=${window.shopifyHost}&shopifySessionToken=${window.shopifySessionToken}`;
									}
									window.location = redirectLocation;
								}
								else {
									appmaker.doAction('url_step_verification_failed', { projectId })
									setPublicMeta({ url_step_verification_failed: true })

									setShowHelp(true)
								}

							}}
						/>
					</ol>
				</nav>
			</div>
			{showHelpWidget && <ContactSupport />}
		</div>
	);
};

export default PluginStatus;
