import { InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import React from 'react';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import ActionSelector from 'packages/blockEditor/components/BlockEditor/components/BlockInspector/tabs/components/ActionSelector';
// import ActionSelector from 'packages/blockEditor/components/BlockEditor/components/BlockInspector/ActionSelector';

export function ToolBarIcon({ attributes, setAttributes, isSelected }) {
	const { toolBarImage, activeImage } = attributes;
	return (
		<div className="bg-gray-50 m-1 rounded overflow-hidden flex items-center space-x-2">
			{isSelected && <MediaPlaceholder
				attributes={
					{
						// icon: ViewGridIcon,
						title: 'ToolBar Image',
						description: ''
					}
				}
				onSelect={(images) => {
					let image = Array.isArray(images) ? images[0] : images
					const data = image;
					setAttributes({
						toolBarImage: {
							url: data.url,
							fileName: data.filename,
							id: data.id,
							title: data.title,
							height: data.meta !== undefined ? data.meta.height : '100',
							width: data.meta !== undefined ? data.meta.width : '100',
						}
					});
				}}
				allowedTypes={['image']}
				multiple={false}
				labels={{ title: 'Image' }}
				value={toolBarImage}
			/>}

			<img src={toolBarImage?.url} className="h-6 w-6" />
			<div className="bg-gray-100 px-2 py-1 text-sm font-medium">
				{attributes.label}
			</div>
			<InspectorControls>
				<ActionSelector
					action={{
						formData: attributes?.appmakerAction,
						_internal: attributes?.___internal_appmakerAction,
					}}
					setAction={({ formData, _internal }) => {
						setAttributes({
							appmakerAction: formData,
							___internal_appmakerAction: _internal,
						});
					}}
				/>
			</InspectorControls>
			{/* <InnerBlocks allowedBlocks={['appmaker/badge', 'appmaker/text']} /> */}
		</div>
	);
}
ToolBarIcon.attributesSchema = {
	properties: {

	},
};

export default function ToolBarIcons() {
	return (
		<div>
			Icons
			<InnerBlocks
				// template={BLOCKS_TEMPLATE}
				allowedBlocks={['appmaker/toolbar-icon']}
			// templateLock="all"
			// allowedBlocks={['appmaker/badge']}
			/>
		</div>
	);
}
// TabBlock.
