import { Link } from '@appmaker/core/routes';
import { useState, useEffect } from 'react';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import Search from '../components/search';
import ToggleAdmin from './ToggleAdmin';
import FormConverter from './FormConverter';
const projectList = [
	// { name: 'Extension Create', tag: 'create', bgColor: 'bg-pink-600', description: 'create new extension', href: '/extension-create' },
	// { name: 'Extension Update', tag: 'update', bgColor: 'bg-purple-600', description: 'Update extensions', href: '/extension-update' },
	{
		name: 'Form Preview',
		tag: 'preview',
		bgColor: 'bg-blue-600',
		description: 'Preview form',
		href: '/form-preview',
	},
	{
		name: 'Toggle Admin',
		tag: 'toggle',
		bgColor: 'bg-green-600',
		description: 'Toggle admin',
		href: '/toggle-admin',
	},
	{
		name: 'Twig Playground',
		tag: 'toggle',
		bgColor: 'bg-green-600',
		description: 'Toggle admin',
		href: '/twig-playground',
	},
	{
		name: 'Svg To Image Editor',
		tag: 'toggle',
		bgColor: 'bg-green-600',
		description: 'Toggle admin',
		href: '/svg-to-image-editor',
	},
	{
		name: 'Ejs Playground',
		tag: 'Ejs',
		bgColor: 'bg-red-600',
		description: 'Toggle admin',
		href: '/ejs-playground',
	},
	{
		name: 'Shopify collection Export',
		tag: 'Ejs',
		bgColor: 'bg-red-600',
		description: '',
		href: '/shopify-collection-export',
	},
	{
		name: 'Cloudfare Purge Cache',
		tag: 'CF',
		bgColor: 'bg-red-600',
		description: '',
		href: '/cloudfare-cache-purge',
	},
	{
		name: 'App Tag Update',
		tag: 'AT',
		bgColor: 'bg-green-600',
		description: '',
		href: '/app-tag',
	},
	{
		name: 'create Staging projects',
		tag: 'clone',
		bgColor: 'bg-red-600',
		description: '',
		href: '/staging',
	},
	{
		name: 'Regenerate IOS certificate',
		tag: 'IOS-CERT',
		bgColor: 'bg-green-600',
		description: '',
		href: '/regenerate-ios-cert',
	},
	{
		name: 'Graphql explorer',
		tag: 'graphql',
		bgColor: 'bg-green-600',
		description: '',
		href: '/graphql-explorer',
	},
	{
		name: 'Graphql explorer online',
		tag: 'graphql',
		bgColor: 'bg-blue-600',
		description: '',
		href: '/graphql-explorer-online',
	},
	{
		name: 'Shopify storefront graphql',
		tag: 'graphql',
		bgColor: 'bg-blue-600',
		description: '',
		href: '/shopify-storefront-graphql',
	},
	{
		name: 'Shopify admin graphql',
		tag: 'graphql',
		bgColor: 'bg-blue-600',
		description: '',
		href: '/shopify-admin-graphql',
	},
	{
		name: 'Legacy Admin',
		tag: 'admin',
		bgColor: 'bg-red-600',
		description: '',
		href: '/admin-legacy',
	},

	{
		name: 'Form Converter',
		tag: 'form',
		bgColor: 'bg-green-600',
		description: '',
		href: '/form-converter',
	},
	{
		name: 'Appmaker GQL Keystone',
		tag: 'gql',
		bgColor: 'bg-green-600',
		description: '',
		href: '/appmaker-gql',
	},
	{
		name: 'Shopify Metafield',
		tag: 'sm',
		bgColor: 'bg-blue-600',
		description: '',
		href: '/meta-filed',
	},
	// {
	//     name: 'Template Version', tag: 'version', bgColor: 'bg-red-600', description: '', href: '/template-version'
	// }
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const AdminTools = () => {
	const [projects, setProjects] = useState(projectList);
	const [search, setSearch] = useState('');
	const getRouterPath = useRouterPath();

	useEffect(() => {
		if (search === '') {
			setProjects(projectList);
		} else {
			const filteredProjects = projectList.filter((project) =>
				project.name.toLowerCase().includes(search.toLowerCase())
			);
			setProjects(filteredProjects);
		}
	}, [search]);
	return (
		<div>
			<div className="flex flex-row justify-between items-center px-6">
				<h2 className="text-gray-900 text-xl mt-4 font-bold uppercase tracking-wide">
					Admin Tools
				</h2>
				<ToggleAdmin />
			</div>

			<Search
				title="Search"
				description="Search for something"
				onChange={(value) => setSearch(value)}
			/>
			<ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 px-6">
				{projects.map((project) => (
					<Link
						to={getRouterPath('/admin-tools' + project.href)}
						key={project.name}
						className="col-span-1 flex shadow-sm rounded-md bg-gray-100"
						activeClassName="border-blue-500 bg-blue-50 text-gray-900"
					>
						<div
							className={classNames(
								project.bgColor,
								'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md text-center'
							)}
						>
							{project.tag.toLocaleUpperCase()}
						</div>
						<div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
							<div className="flex-1 px-4 py-2 text-sm truncate">
								<a
									href={project.href}
									className="text-gray-900 font-medium hover:text-gray-600"
								>
									{project.name}
								</a>
								<p className="text-gray-500">{project.description}</p>
							</div>
						</div>
					</Link>
				))}
			</ul>
		</div>
	);
};

export default AdminTools;
