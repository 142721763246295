import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [{
	"clientId": "40053c02-a407-4780-9b9e-eb1ac43bb73c",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 3,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "0b20a079-3b10-4b38-bff1-5322286e04d1",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a.png?v=1656667374",
				"id": "gid://shopify/MediaImage/30765218300161",
				"title": "a.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "e7a3aaf2-185e-4341-9635-fea856f8d6df",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-2.png?v=1656667373",
				"id": "gid://shopify/MediaImage/30765218201857",
				"title": "a-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {
				"action": "OPEN_COLLECTION",
				"collectionId": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI4NzcxMzI2Mzg3Mw==",
				"title": "Home page"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_COLLECTION",
					"label": "Open Collection"
				},
				"collectionId": {
					"label": "Home page",
					"value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI4NzcxMzI2Mzg3Mw=="
				},
				"actionParams": {
					"label": "Home page",
					"value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI4NzcxMzI2Mzg3Mw=="
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "8b35391e-bf09-465a-bebb-843a5d1a3e75",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-1.png?v=1656667373",
				"id": "gid://shopify/MediaImage/30765218234625",
				"title": "a-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {
				"action": "NO_ACTION"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "NO_ACTION",
					"label": "No action"
				}
			}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "04cfa4d2-ea7e-4e46-a7a3-f3777c060a27",
	"name": "appmaker/slider",
	"isValid": true,
	"attributes": {
		"autoPlay": false,
		"delay": 1,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "95a3f29c-4e95-4c34-bfde-b1024757186b",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b.png?v=1656667397",
				"id": "gid://shopify/MediaImage/30765221904641",
				"title": "b.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 546,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "2402a7ab-8d79-47ac-8a83-794ee00c5514",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-1.png?v=1656667396",
				"id": "gid://shopify/MediaImage/30765221871873",
				"title": "b-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 546,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "3f20ed5a-534e-492c-8151-11e8224f8e0e",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-2.png?v=1656667395",
				"id": "gid://shopify/MediaImage/30765221249281",
				"title": "b-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 546,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "be4791b2-23a4-4232-8dcf-e9a672dfee5e",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-5.png?v=1656667412",
			"id": "gid://shopify/MediaImage/30765223674113",
			"title": "c-5.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "8f87e1bb-1867-4fe2-b237-957bcd3f8774",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "0bb7870c-c7e4-4448-9dc5-af8e20c75938",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c.png?v=1656667429",
				"id": "gid://shopify/MediaImage/30765225115905",
				"title": "c.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "1977d399-ba7c-4910-8ba9-48e4d649d0c4",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-1.png?v=1656667427",
				"id": "gid://shopify/MediaImage/30765225017601",
				"title": "c-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "58aa3079-4b3f-40f1-b3c8-bf14c6583d2b",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-2.png?v=1656667425",
				"id": "gid://shopify/MediaImage/30765224657153",
				"title": "c-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "7b8de89d-f68c-47b3-913d-60f906b79631",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-3.png?v=1656667424",
				"id": "gid://shopify/MediaImage/30765224526081",
				"title": "c-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "3be47a91-5653-4a5d-9e8f-9d75f43b9c68",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-4.png?v=1656667422",
				"id": "gid://shopify/MediaImage/30765224460545",
				"title": "c-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 223,
				"width": 675
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "c53d04bd-8f71-42a3-a778-863be534abe2",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-4.png?v=1656667444",
			"id": "gid://shopify/MediaImage/30765226557697",
			"title": "d-4.png"
		},
		"imageContainerStyle": {
			"height": 229,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 229,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "18e0ad4c-1938-498d-a983-210a2618b5fa",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "0922124c-d32a-49c6-a882-280c38429345",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d.png?v=1656667456",
				"id": "gid://shopify/MediaImage/30765227737345",
				"title": "d.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "5946045a-1ade-41e4-83ae-76f898761aaa",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-1.png?v=1656667454",
				"id": "gid://shopify/MediaImage/30765227671809",
				"title": "d-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "c29d2150-6773-48a3-972a-14982e7870c2",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-2.png?v=1656667452",
				"id": "gid://shopify/MediaImage/30765227606273",
				"title": "d-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "743cfb91-58df-44af-b16e-7d752aec35a1",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-3.png?v=1656667452",
				"id": "gid://shopify/MediaImage/30765227540737",
				"title": "d-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {
				"action": "OPEN_PRODUCT_LIST"
			},
			"___internal_appmakerAction": {
				"action": {
					"value": "OPEN_PRODUCT_LIST",
					"label": "Open all products"
				}
			}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "a8d03488-baaf-4385-8b66-fa1c6dcebb6e",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-1.png?v=1656667699",
			"id": "gid://shopify/MediaImage/30765255393537",
			"title": "j-1.png"
		},
		"imageContainerStyle": {
			"height": 413,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 413,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "169da158-e8c1-4850-8997-cc5742e238bd",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-4.png?v=1656667522",
			"id": "gid://shopify/MediaImage/30765233668353",
			"title": "e-4.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "e6210ff1-5a30-4e7d-996d-22f7c95ccc25",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "573ccc82-557d-40fb-8d55-3e4b78c5b75d",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e.png?v=1656667490",
				"id": "gid://shopify/MediaImage/30765230227713",
				"title": "e.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "75cd7bd3-978b-46c4-abf5-e5e9423fccd6",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-1.png?v=1656667489",
				"id": "gid://shopify/MediaImage/30765230129409",
				"title": "e-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "7936cb7d-9e3b-43b4-bfb8-19d2f8d8f98a",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-2.png?v=1656667487",
				"id": "gid://shopify/MediaImage/30765230096641",
				"title": "e-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "9658f844-9315-4da6-bf4c-5e7bc5ec385a",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-3.png?v=1656667487",
				"id": "gid://shopify/MediaImage/30765230063873",
				"title": "e-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 500,
				"width": 500
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "c2f36f78-119d-4f48-adfa-e8877c5ab5ec",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j.png?v=1656667699",
			"id": "gid://shopify/MediaImage/30765255360769",
			"title": "j.png"
		},
		"imageContainerStyle": {
			"height": 546,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 546,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "ffd36ead-f53d-431e-82d7-114dc0e258f6",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-6.png?v=1656667538",
			"id": "gid://shopify/MediaImage/30765235339521",
			"title": "f-6.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "f61ee7df-d713-4a4c-a989-92653e550f0d",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "b67178d8-044b-4748-91ff-0ace538ece99",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_49e352e0-e285-4cf8-b5ef-36759fe26632.png?v=1656667563",
				"id": "gid://shopify/MediaImage/30765237797121",
				"title": "f.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "9959c9d6-b277-4132-a599-ae6640b59956",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1_f9e6b208-e0ad-45cf-ab6a-a4b0a26a99f0.png?v=1656667562",
				"id": "gid://shopify/MediaImage/30765237764353",
				"title": "f-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "b60afeb3-6b6b-410b-9be1-1085b145df5a",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2_94a3205a-c941-44bd-b03f-5da35dbde98d.png?v=1656667558",
				"id": "gid://shopify/MediaImage/30765237633281",
				"title": "f-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "17a23558-7531-490a-b94f-4c83fd52cab2",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-3_1ffc91a7-090b-43d4-b50d-5c4be22bae26.png?v=1656667556",
				"id": "gid://shopify/MediaImage/30765237600513",
				"title": "f-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "8b3a5f74-65b1-4103-b8e9-0b8a3bfbdad8",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-4.png?v=1656667554",
				"id": "gid://shopify/MediaImage/30765237534977",
				"title": "f-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "b7c23e0e-bc1f-442f-8a6f-0460fac5c01e",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-5.png?v=1656667553",
				"id": "gid://shopify/MediaImage/30765237403905",
				"title": "f-5.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "a4ee6bcd-653e-43f0-9ad9-aa522213ca55",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-6.png?v=1656667575",
			"id": "gid://shopify/MediaImage/30765239206145",
			"title": "g-6.png"
		},
		"imageContainerStyle": {
			"height": 186,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 186,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "0f76bc00-42c3-4aa1-b03e-fc7cc24cda3b",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "0f7b582a-9204-44e4-aec1-0b32ba9ea036",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g.png?v=1656667598",
				"id": "gid://shopify/MediaImage/30765242417409",
				"title": "g.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "e4ad5e35-bcc6-46ab-b192-17bf0be84348",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-1.png?v=1656667595",
				"id": "gid://shopify/MediaImage/30765241958657",
				"title": "g-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "f0ce32a4-7390-49f7-8086-c88dde14db1b",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-2.png?v=1656667594",
				"id": "gid://shopify/MediaImage/30765241499905",
				"title": "g-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "bb2658d2-607f-4480-adf9-e7118cf01947",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-3.png?v=1656667590",
				"id": "gid://shopify/MediaImage/30765241401601",
				"title": "g-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "1cf99d1c-85e3-46a7-9084-0ea8dfb32597",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-5.png?v=1656667588",
				"id": "gid://shopify/MediaImage/30765241204993",
				"title": "g-5.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "a25ffd30-6e03-466a-89c0-8d5c5b0e7f0c",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-4.png?v=1656667588",
				"id": "gid://shopify/MediaImage/30765241237761",
				"title": "g-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 635,
				"width": 635
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "91ed8edb-bce8-4c17-a7c0-3a74bc63db2f",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-2.png?v=1656667709",
			"id": "gid://shopify/MediaImage/30765256442113",
			"title": "j-2.png"
		},
		"imageContainerStyle": {
			"height": 413,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 413,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "19d28d3c-9797-41f1-8e0b-ba26ab693d9f",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-5.png?v=1656667719",
			"id": "gid://shopify/MediaImage/30765257031937",
			"title": "j-5.png"
		},
		"imageContainerStyle": {
			"height": 393,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 393,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "24211867-14f8-4820-a3d3-c01b5af74084",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i_d55d7dea-84b4-4989-8d24-963f71ac56fe.png?v=1656667610",
			"id": "gid://shopify/MediaImage/30765242810625",
			"title": "i.png"
		},
		"imageContainerStyle": {
			"height": 225,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 225,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "a784e554-77cd-4940-af38-1bed64cca924",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-1_bd5aafc7-d156-4412-b324-26043fa4b89e.png?v=1656667632",
			"id": "gid://shopify/MediaImage/30765245661441",
			"title": "i-1.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "4159c26d-d45d-413d-9ffc-e7d6b00d1055",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-2_c8ff7cf8-9079-4b18-b32e-9ef33be74493.png?v=1656667632",
			"id": "gid://shopify/MediaImage/30765245694209",
			"title": "i-2.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "d3af554a-9722-4317-a580-99994ff3adca",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-3_df0cdc3d-447d-41c4-811b-90c7055ffd94.png?v=1656667643",
			"id": "gid://shopify/MediaImage/30765246775553",
			"title": "i-3.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "e72e6d62-f1e4-4982-9afa-53a6be6c8bec",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-4_ae35dd12-a1ea-4090-b765-a7f26d619e42.png?v=1656667647",
			"id": "gid://shopify/MediaImage/30765247791361",
			"title": "i-4.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "6f14fcd4-9f46-43e3-a64f-830034a5c4cb",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-5_db566b1e-97cb-4d05-acdf-0ff0b26ae1d1.png?v=1656667658",
			"id": "gid://shopify/MediaImage/30765248610561",
			"title": "i-5.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "d5649b52-d5b9-4cb9-bac3-cc207f8ddb81",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-6_5ec99637-bf76-43d5-9d6e-3b6c7d56b4d0.png?v=1656667658",
			"id": "gid://shopify/MediaImage/30765248643329",
			"title": "i-6.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "61ce4655-9d10-4475-b0bb-ccf43603bd5d",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-7_3519a059-19e7-401d-87a4-17765e5bac63.png?v=1656667660",
			"id": "gid://shopify/MediaImage/30765249036545",
			"title": "i-7.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "a059d149-24bf-40db-8c15-f7d91dce6e08",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-8_7bee0cfd-2acd-44fd-9f22-be76f7a36548.png?v=1656667669",
			"id": "gid://shopify/MediaImage/30765249724673",
			"title": "i-8.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "53ec74da-48ac-4d1e-8a64-81a4abc23161",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-9_b769f4ec-33e7-444a-a377-6b2c23a6575e.png?v=1656667669",
			"id": "gid://shopify/MediaImage/30765249757441",
			"title": "i-9.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "be3f7991-9407-4790-9d92-f46a17b98d96",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-10_d71debb7-306f-4567-aef6-e9a1ca174ef6.png?v=1656667672",
			"id": "gid://shopify/MediaImage/30765250052353",
			"title": "i-10.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "1828faf2-b716-41c3-8046-009efc05faa2",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_COLLECTION"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_COLLECTION",
				"label": "Open Collection"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-11_9bb3ea20-b001-433d-a7b9-5e91bcba0064.png?v=1656667675",
			"id": "gid://shopify/MediaImage/30765250511105",
			"title": "i-11.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "7558a3e1-4036-4d73-8c95-580495f7ef99",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_PRODUCT_LIST"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_PRODUCT_LIST",
				"label": "Open all products"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-12_56c92f20-b808-40ab-818c-e0d215ad3c1a.png?v=1656667683",
			"id": "gid://shopify/MediaImage/30765252608257",
			"title": "i-12.png"
		},
		"imageContainerStyle": {
			"height": 200,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 200,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "5dd4bb76-d825-4fca-b209-e34c52ec015d",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-3.png?v=1656667708",
			"id": "gid://shopify/MediaImage/30765256409345",
			"title": "j-3.png"
		},
		"imageContainerStyle": {
			"height": 393,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 393,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "7c344793-a916-43cd-ab71-ad7f422cd13d",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-4.png?v=1656667709",
			"id": "gid://shopify/MediaImage/30765256474881",
			"title": "j-4.png"
		},
		"imageContainerStyle": {
			"height": 393,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 393,
			"width": 1080
		}
	},
	"innerBlocks": []
}]

const template = {
    blocks,
    id: 'fashion-1',
    name: 'Vouge',
    category: 'Fashion',
    price: 'Free',
    imageSrc: '/images/themes/fashion.png',
    previewSrc: '/images/preview/templates/fashion.png',
    imageAlt: 'Mobile app Fashion theme',
    description: "Looking to build the next best Fashion app? Look no further! check out our carefully crafted Fashion template that will have you up and running in no time."
};
export default template;
