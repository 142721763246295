// counter.js

import { entity } from 'simpler-state'

export const settings = entity({
    showSideBar: true,
    smallSidebar: false,
})
window.settings = settings
export const useSettings = () => settings.use()
export const useShowSidebar = () => settings.use(data => data.showSideBar)
export const useSmallSidebar = () => settings.use(data => data.smallSidebar)
export const setSideBarStatus = (showSideBar) => settings.set(value => ({ ...value, showSideBar }))
export const setSmallSidebar = (smallSidebar) => settings.set(value => ({ ...value, smallSidebar }))
