export default function ProductData({ attributes }) {
	return (
		<div className="px-3 py-2 border-gray-100 border-b">
			<p className="text-green-500 text-sm">
				{attributes.in_stock ? 'In-Stock' : 'Out of stock'}
			</p>
			<p className="text-md font-medium">{attributes.title}</p>
			<p className="text-sm font-medium">{attributes.salePercentage}</p>
			<p
				className="text-lg font-bold"
				dangerouslySetInnerHTML={{ __html: attributes.price_html }}
			></p>
			<p className="text-sm">
				{attributes.currency_symbol} {attributes.price_value}
			</p>
		</div>
	);
}
ProductData.attributesSchema = {
	type: 'object',
	properties: {
		in_stock: {
			type: 'string',
			label: 'Stock status',
			default: '',
		},
		title: {
			type: 'string',
			label: 'Title',
			default: '',
		},
		salePercentage: {
			type: 'string',
			label: 'Sale percentage',
			default: '',
		},
		onSale: {
			type: 'string',
			label: 'On sale',
			default: '',
		},
		currency_symbol: {
			type: 'string',
			label: 'Currency symbol',
			default: '',
		},
		regularPrice: {
			type: 'string',
			label: 'Regular Price',
			default: '',
		},
		salePrice: {
			type: 'string',
			label: 'Sale Price',
			default: '',
		},
		saved_amount: {
			type: 'string',
			label: 'Saved Amount',
			default: '',
		},
		price_value: {
			type: 'string',
			label: 'Price value',
			default: '',
		},
		customStyles: {
			type: 'json',
			label: 'Custom Styles',
			default: '',
		},

		// attributes: {
		// 	type: 'object',
		// 	properties: {
		// 		inStock: { type: 'string', default: '{{blockItem.in_stock}}' },
		// 		title: { type: 'string', default: '{{blockItem.name}}' },
		// 		salePercentage: { type: 'string' },
		// 		onSale: { type: 'string' },
		// 		regularPrice: { type: 'string' },
		// 		salePrice: { type: 'string' },
		// 	},
		// },
	},
};
