import { entity, persistence } from 'simpler-state';
import toast from 'react-hot-toast';
export const formData = entity({
	isConfigureLoading: false,
	currentStep: 1,
	stepsNeedBackButton: [2, 3, 4, 5, 6],
});

export const useFormData = (args) => formData.use(args);
export const setFormData = (data) => {
	formData.set(data);
};
export const changeStep = (value) => {
	formData.set({ ...formData.get(), currentStep: value });
};

/**
 *
 * @param {String} toastMessage
 * @param {Object} options {appearance}
 * @returns
 */
export const sendNotification = (toastMessage, options = {}) => {
	const toastOption = {
		duration: 5000,
		position: 'top-right',
	};
	switch (options.appearance) {
		case 'error': {
			return toast.error(toastMessage, toastOption);
		}
		case 'success': {
			return toast.success(toastMessage, toastOption);
		}
		default: {
			return toast(toastMessage, toastOption);
		}
	}
};
