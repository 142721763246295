// import { sendAppmakerEvent } from '@appmaker/core/api/events';
// import Tracker from '@openreplay/tracker';
import appmaker from './packages/appmaker-core/index';
import { initializeApp, getApps, getApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import mixpanel from 'mixpanel-browser';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const firebaseConfig = {
    apiKey: "AIzaSyAaEw3Ww9i_cnASBp7GqY5ajFKnXBzvMsY",
    authDomain: "appmaker-core.firebaseapp.com",
    databaseURL: "https://appmaker-core.firebaseio.com",
    projectId: "appmaker-core",
    storageBucket: "appmaker-core.appspot.com",
    messagingSenderId: "746381077870",
    appId: "1:746381077870:web:f32630c1388af42116a57f",
    measurementId: "G-FHS41KX4S6"
};
mixpanel.init('b4f296a32d333c26a62b7c601e22f519');

let app;
const firebaseApps = getApps();
if (firebaseApps.length === 0) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApp();
}

// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
const analytics = getAnalytics(app);
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider('6LdS8vkUAAAAAHB7WH_qRacHtvuhh-kOVC_x9mwQ'), // 7F915BE4-624E-457C-94BA-7DD535AA70E4
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
// });
// const tracker = new Tracker({
//     projectKey: "kEh1U6f8QWK2aQyqpzoO",
//     // __DISABLE_SECURE_MODE: true,
//     onStart: ({ sessionID }) => {
//         // appmakerEve
//         setTimeout(() => {
//             sendAppmakerEvent('open_replay_session_start', {
//                 sessionID
//             })
//         }, 3000);
//     },

// });
// tracker.start();

appmaker.addAction('appmaker-user-loggedin', 'event-track', (userData) => {
    mixpanel.identify(userData.id);
    mixpanel.people.set({ $name: userData.name, $email: userData.email });
    setUserId(analytics, `${userData.id}`);

    if (window.Intercom) {
        window.Intercom('boot', {
            email: userData.email
        });
    }
})


appmaker.addAction('appmaker-analytics-event', 'event-track', ({ event, params }) => {
    if (window.Intercom) {
        mixpanel.track(event, params);
        logEvent(analytics, event, params);
        if (window.Intercom) {
            window.Intercom('trackEvent', event, params);
        }
    }
})

appmaker.addAction('appmaker-analytics-track-page-view', 'event-track', () => {
    let trackUrl = window.location.href;
    let trackReferer = window.document.referrer;
    let trackTitle = window.document.title;
    let trackPath = window.location.pathname;
    logEvent(analytics, 'screen_view', {
        firebase_screen: trackTitle,
    });
    mixpanel.track('page_view', {
        url: trackUrl,
        referer: trackReferer,
        title: trackTitle,
        path: trackPath
    });
})
