import clsx from 'clsx';

const Video = ({ attributes }) => {
	return (
		<div>
			<video
				className={clsx(
					'w-full',
					attributes.resizeMode === 'cover' ? 'object-cover' : 'object-contain'
				)}
				src={attributes?.video?.sourceUrl || attributes?.url}
				poster={attributes.thumbnail_image}
				controls={attributes.controls}
				loop={attributes.repeat}
				paused={attributes.paused}
				muted={attributes.muted || true}
				style={{ height: `${attributes.height}px` }}
				autoPlay={true}
			/>
		</div>
	);
};

export default Video;

Video.attributesSchema = {
	properties: {
		video: {
			type: 'string',
			label: 'Video File',
			uiType: 'appmaker-media-library',
			acceptedFile: 'video/mp4',
			mediaTypes: ['video'],
			dropzoneHelperText: (
				<>
					MP4 files only. <br />
					Reduce file size for better performance.
				</>
			),
		},
		thumbnail_image: {
			type: 'string',
			label: 'Thumbnail',
			uiType: 'appmaker-media-library',
		},
		resizeMode: {
			type: 'string',
			title: 'Resize Mode',
			uiType: 'RadioGroup',
			defaultValue: 'cover',
			options: [
				{ label: 'Cover', value: 'cover' },
				{ label: 'Contain', value: 'contain' },
			],
		},
		controls: {
			type: 'string',
			label: 'Controls',
			uiType: 'switch',
			description: 'Show or hide video controls',
			defaultValue: true,
		},
		repeat: {
			type: 'string',
			label: 'Repeat',
			uiType: 'switch',
			description: 'Loop video when it ends',
		},
		paused: {
			type: 'string',
			label: 'Paused',
			uiType: 'switch',
			description: 'Pause video when it loads',
		},
		playOnVisibility: {
			type: 'string',
			label: 'Play on Visibility',
			uiType: 'switch',
			description: 'Play video only when it is visible',
		},
		muted: {
			type: 'string',
			label: 'Muted',
			uiType: 'switch',
			description: 'Mute video when it loads',
		},
		url: {
			type: 'string',
			label: 'Video URL (Use if video is not uploaded)',
		},
		height: {
			type: 'string',
			label: 'Height',
		},
	},
};
