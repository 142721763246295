/* eslint-disable no-unused-vars */
import React from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody } from '@wordpress/components';
import Form from '@rjsf/core';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useAppmakerFormStore } from '@appmaker/core/components/AppmakerForm/store';

import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { Menu, MenuButton, MenuItem, SubMenu } from '@szhsin/react-menu';
import { LightningBoltIcon, XIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';

function AutoCompleteFieldTemplate(props) {
	// const paths = state.dsPaths.map((path) => `blockItem.${path}`);
	const paths = ['Hello'];
	return (
		<TextInput
			regexp={/^[A-Za-z0-9\\-_.]+$/}
			value={props.value}
			type="text"
			trigger="{{"
			options={paths}
			onChange={(value) => props.onChange(value)}
			Component="input"
		/>
	);
}

const customFields = {
	// StringField: AutoCompleteFieldTemplate
};
const customWidgets = { TextWidget: AutoCompleteFieldTemplate };

function CustomForm(props) {
	return <Form fields={customFields} widgets={customWidgets} {...props} />;
}

function VariableSelector(params) {
	return (
		<div className="bg-gray-100 border border-gray-200 rounded-md overflow-hidden">
			<div className="flex items-center gap-1 flex-wrap p-2">
				<p>$</p>
				<button className="rounded-full overflow-hidden bg-blue-700 pl-2 text-white flex items-center space-x-2">
					<span>Product Price</span>
					<XIcon className="h-6 w-6 bg-blue-800 hover:bg-blue-900 rounded-full p-1" />
				</button>
				{/* <button className="rounded-full overflow-hidden bg-blue-700 pl-2 text-white flex items-center space-x-2">
					<span>Offer</span>
					<XIcon className="h-6 w-6 bg-blue-800 hover:bg-blue-900 rounded-full p-1" />
				</button> */}
				<p>% OFF</p>
				<input
					type="text"
					className="outline-none p-0 bg-gray-100 border-none focus:border-transparent focus:outline-none focus:ring-0"
				/>
			</div>
			<div className="bg-white p-2 border-t border-gray-200">
				<Menu
					menuButton={
						<MenuButton className={'block'}>
							<div className="text-blue-500 bg-gray-200 p-2 rounded-md">
								<LightningBoltIcon className="h-5 w-5 text-blue-500" />
							</div>
						</MenuButton>
					}
				>
					<MenuItem>New File</MenuItem>
					<SubMenu label="Open">
						<MenuItem>index.html</MenuItem>
						<MenuItem>example.js</MenuItem>
						<SubMenu label="Styles">
							<MenuItem>about.css</MenuItem>
							<MenuItem>home.css</MenuItem>
							<MenuItem>index.css</MenuItem>
						</SubMenu>
					</SubMenu>
					<MenuItem>Save</MenuItem>
				</Menu>
			</div>
		</div>
	);
}
const AttributesForm = ({ Block, onChange, values, customAttributeSchema }) => {
	const formData = useAppmakerFormStore(state => state.formData);
	useEffect(() => {
		onChange(formData);
	}, [formData])
	return (
		<InspectorControls>

			<AppmakerForm
				formVersion={2}
				// value={{ formData: flatten(formData) }}
				schema={Block?.attributesSchema ?? customAttributeSchema}
			// onFileUpload={onFileUpload}
			/>
			{/* </AppmakerFormProvider> */}
			{/* <VariableSelector />
			<PanelBody title="Attributes">
        <div>
          <CustomForm
            formData={{ attributes: values }}
            schema={Block?.attributesSchema}
            onSubmit={({ formData }) => {
              // setFormData(formData);
              onChange(formData);
            }}
          />
        </div>
      </PanelBody> */}
		</InspectorControls>
	);
};
export default AttributesForm;
