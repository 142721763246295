import { useExtensionDetail } from '../store/details';
import {
	createStore,
	AppmakerFormProvider,
	useAppmakerFormStore,
} from '@appmaker/core/components/AppmakerForm/store';
import { useParams } from '@appmaker/core/routes';
import { useMutation } from '@apollo/client';
import { UPDATE_SETTINGS } from '../api/graphql';
import { isEqual, size } from 'lodash';
import { useEffect } from 'react';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import { SaveIcon } from '@heroicons/react/solid';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import {
	sendNotification,
	toast,
} from '@appmaker/components/ReactHotToastIntegrator';
import appmaker from '@appmaker/core/index';
const ExtensionAddOnButton = ({ loading, metaKey }) => {
	const { handle, projectId } = useParams();
	const extensionAddOnButton = appmaker.applyFilters(
		`${handle}-backend-settings-before-save-button`,
		null,
		{
			projectId,
			isSaving: loading,
		}
	);
	if (extensionAddOnButton) return <>{extensionAddOnButton}</>;
	return null;
};

const SaveButton = ({ loading, onSaveSchema = () => {} }) => {
	return (
		<button
			type="button"
			disabled={loading}
			className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
			onClick={() => {
				onSaveSchema();
			}}
		>
			{loading ? (
				<>
					<LoadingIcon
						className="-ml-0.5 h-6 w-6 animate-spin"
						aria-hidden="true"
					/>
					Saving...
				</>
			) : (
				<>
					<SaveIcon className="-ml-0.5 h-6 w-6" aria-hidden="true" />
					Save Changes
				</>
			)}
		</button>
	);
};

const Form = () => {
	const { handle, projectId } = useParams();
	const extension = useExtensionDetail();
	const syncSettings = useExtensionDetail((state) => state.syncSettings);
	const unsaved = useExtensionDetail((state) => state.unSaved);
	const setUnsaved = useExtensionDetail((state) => state.setUnsaved);
	const { formData } = useAppmakerFormStore();
	const details = extension?.extensionInstalls?.find(
		(details) => details?.extension?.id === extension?.extension?.id
	);
	const [updateSettings, { data, error, loading }] = useMutation(
		UPDATE_SETTINGS,
		{
			context: {
				headers: {
					'x-appmaker-project-id': projectId,
				},
			},
		}
	);
	const onSaveSchema = () => {
		try {
			const data = JSON.parse(JSON.stringify(formData));
			toast.promise(
				(async () => {
					await updateSettings({
						variables: {
							where: {
								id: details?.id,
							},
							data: {
								backendSettings: data,
							},
						},
					});
					syncSettings(details?.id, data);
				})(),
				{
					loading: 'Saving...',
					success: <b>Settings saved!</b>,
					error: <b>Unable to save</b>,
				}
			);
		} catch (error) {
			sendNotification('Unable to save', 'error');
		}
	};
	useEffect(() => {
		if (
			formData &&
			details?.backendSettings &&
			!isEqual(JSON.parse(JSON.stringify(formData)), details?.backendSettings)
		) {
			setUnsaved(true);
		} else {
			setUnsaved(false);
		}
	}, [formData, details, setUnsaved]);
	if (!details) return null;
	return (
		<div className="m-4 bg-white max-w-3xl p-4 shadow-sm rounded-lg mx-auto">
			<div className="mb-4 pb-4 border-b flex items-end justify-between">
				<div>
					<h1 className="text-xl font-semibold">Backend Settings</h1>
					<p className="text-gray-500 text-sm">
						Configure{' '}
						<span className="lowercase">{details?.extension?.name}</span>{' '}
						extension backend settings.
					</p>
				</div>
				{unsaved && (
					<SaveButton loading={loading} onSaveSchema={onSaveSchema} />
				)}
			</div>
			{extension?.extension?.backendSchema &&
				size(extension?.extension?.backendSchema) > 0 && (
					<>
						<AppmakerForm
							formVersion={2}
							// value={{ formData: flatten(formData) }}
							schema={extension?.extension?.backendSchema ?? {}}
						/>
						<div className="pt-3 flex items-center flex-wrap">
							<ExtensionAddOnButton />
							<SaveButton loading={loading} onSaveSchema={onSaveSchema} />
						</div>
					</>
				)}
		</div>
	);
};
const FormWrapper = (props) => {
	const extension = useExtensionDetail();
	const details = extension?.extensionInstalls?.find(
		(details) => details?.extension?.id === extension?.extension?.id
	);
	if (extension?.isLoading) {
		return null;
	}

	return (
		<div>
			<AppmakerFormProvider
				createStore={createStore({
					formData: {
						...details?.backendSettings,
					},
				})}
			>
				<Form {...props} />
			</AppmakerFormProvider>
		</div>
	);
};

export default FormWrapper;
