import axios from '@appmaker/core/axios';
import {
	BASE_URL,
	V3_BASE_URL,
	DASHBOARD_GRAPHQL_URL,
} from '@appmaker/core/api/core';
import apiFunction from 'packages/pushNotification/api';

const api = {
	firebaseList: apiFunction.getFirebaseProjectList,
	analyticsConfig: async ({ projectId }) => {
		let url = `${V3_BASE_URL}/projects/${projectId}/analytics`;
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	analytics: async ({
		projectId,
		accessToken,
		firebaseId,
		startDate,
		endDate,
	}) => {
		let url = `${V3_BASE_URL}/projects/${projectId}/analytics/aggregates?accessToken=${accessToken}&firebaseId=${firebaseId}`;
		if (startDate && endDate) {
			url += `&startDate=${startDate}&endDate=${endDate}`;
		}
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	report: async ({
		projectId,
		accessToken,
		firebaseId,
		startDate,
		endDate,
	}) => {
		let url = `${V3_BASE_URL}/projects/${projectId}/analytics/reports?accessToken=${accessToken}&firebaseId=${firebaseId}`;
		if (startDate && endDate) {
			url += `&startDate=${startDate}&endDate=${endDate}`;
		}
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	checkLogin: async (projectId) => {
		const query = `
        query{
			project{
			  oauthProviders{
				name
			  }
			}
		  }
        `;
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
			JSON.stringify({
				query,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response.data?.data;
	},
	runGAReport: async (projectId) => {
		const query = `
        query{
			runGAReport(
			  metrics: [{name: "activeUsers"}, {name: "addToCarts"}, {name: "totalPurchasers"}, {name: "totalUsers"}]
			  dateRanges: [{startDate: "30daysAgo", endDate: "today"}]
			) {
			  dimensionHeaders {
				name
			  }
			  metricHeaders {
				name
			  }
			  rows {
				dimensionValues {
				  value
				}
				metricValues {
				  value
				}
			  }
			}
		  }
        `;
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
			JSON.stringify({
				query,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response.data?.data?.runGAReport;
	},
};

export default api;
