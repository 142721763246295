import React from 'react';

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clipPath="url(#clip0_259:5)">
			<path
				d="M17 8C17 6.34315 15.6569 5 14 5H5C3.34315 5 2 6.34315 2 8V17C2 18.6569 3.34315 20 5 20H14C15.6569 20 17 18.6569 17 17V8Z"
				stroke="black"
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M17 8C17 6.34315 15.6569 5 14 5H5C3.34315 5 2 6.34315 2 8V17C2 18.6569 3.34315 20 5 20H14C15.6569 20 17 18.6569 17 17V8Z"
				stroke="black"
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M36 8C36 6.34315 34.6569 5 33 5H24C22.3431 5 21 6.34315 21 8V17C21 18.6569 22.3431 20 24 20H33C34.6569 20 36 18.6569 36 17V8Z"
				fill="black"
				stroke="black"
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx={20} cy={12} r={4} fill="white" />
			<path
				d="M19 14L21 12L19 10"
				stroke="black"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</g>
		<defs>
			<clipPath id="clip0_259:5">
				<rect width={24} height={24} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
