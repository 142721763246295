import { ClockIcon, CogIcon } from '@heroicons/react/outline';
const BuildingApp = () => {
    return (
        <div className="py-16 flex flex-col justify-center items-center gap-6">
            <CogIcon className="h-24 w-24 p-4 bg-blue-100 rounded-full animate-spin-slow text-blue-600" />
            <div className="mt-2 text-center text-lg">IPA Building</div>
            <div className="text-sm text-gray-500 flex items-center justify-center gap-2">
                <ClockIcon className="h-4 w-4" />
                ETA 5 Min
            </div>
        </div>
    )
}

export default BuildingApp;