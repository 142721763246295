// @flow strict

import * as React from 'react';

import '../actions';
import appmaker from '@appmaker/core/index';

// import ReactJson from 'react-json-view';

function ActionPlayground() {
	// const actions = applyFilters("appmaker-actions-testing");
	const actions = appmaker.actions.getAllActions();

	return (
		<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			<div className="py-6">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
				</div>
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					{/* Replace with your content */}
					<div className="py-4">
						{/* <ReactJson
							src={actions}
							onSelect={console.log}
							displayDataTypes={false}
							displayObjectSize={false}
						/> */}

						{/* <AppmakerForm options={actions} debug={true} optionKey={'action'} /> */}
						{/* <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" /> */}
					</div>
					{/* /End replace */}
				</div>
			</div>
		</main>
	);
}

export default ActionPlayground;
