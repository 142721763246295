export default function LoadingIndicator({ fullDash }) {
	return (
		<div className="h-screen overflow-hidden flex bg-white">
			{fullDash && (
				<div className="sm:flex sm:flex-col sm:gap-4 sm:w-64 p-4 h-screen overflow-hidden bg-gray-50 animate-pulse hidden">
					<div className="flex gap-4 items-center">
						<div className="h-16 w-16 rounded-2xl bg-gray-300" />
						<div className="inline-flex w-24 h-6 rounded-lg bg-gray-300" />
					</div>
					<div className="h-6 w-full rounded-md bg-gray-300" />
					<div className="h-6 w-full rounded-md bg-gray-300" />
					<div className="h-6 w-full rounded-md bg-gray-300" />
					<div className="h-6 w-full rounded-md bg-gray-200" />
					<div className="h-6 w-full rounded-md bg-gray-200" />
					<div className="h-6 w-full rounded-md bg-gray-200" />
					<div className="h-6 w-full rounded-md bg-gray-100" />
					<div className="h-6 w-full rounded-md bg-gray-100" />
					<div className="h-6 w-full rounded-md bg-gray-100" />
				</div>
			)}
			<div className="flex-1 flex flex-col gap-4 overflow-hidden animate-pulse m-8">
				<div className="h-48 w-full bg-gray-300 rounded-lg" />
				<div className="h-48 w-full bg-gray-200 rounded-lg" />
				<div className="h-48 w-full bg-gray-100 rounded-lg" />
			</div>
		</div>
	);
}
