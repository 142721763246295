import { useFormDataValue } from "@appmaker/core";
const CheckBox = (props) => {
	const { attributes } = props;
	const { id, label, description, name } = attributes;
	const [value, setValue] = useFormDataValue({
		clientId: props.clientId,
		name,
	});
	return (
		<div className="relative flex items-start mb-5">
			<div className="flex h-5 items-center">
				<input
					id={id}
					aria-describedby={`${id}-description`}
					name={name}
					checked={value}
					onChange={(e) => setValue(e.target.checked)}
					type="checkbox"
					className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
				/>
			</div>
			<div className="ml-3 text-sm">
				<label htmlFor={name} className="font-medium text-gray-700">
					{label}
				</label>
				<p id={`${id}-description`} className="text-gray-500 text-sm">
					{description}
				</p>
			</div>
		</div>
	);
};

export default CheckBox;
