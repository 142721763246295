import { useParams } from '@appmaker/core/routes';
import ExtensionDetail from './ExtensionDetails';
import Version from '../components/Version';
import BackendSettings from '../components/BackendSettings';
const ExtensionDetailRoute = () => {
    const { route } = useParams();
    if (route === 'version') {
        return <Version />
    }
    if(route === 'backend-settings'){
        return <BackendSettings />
    }
    return <ExtensionDetail />

}

export default ExtensionDetailRoute;