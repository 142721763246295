import { useState, useEffect } from 'react';
import AppmakerSwitch from '@appmaker/components/Form/fields/AppmakerSwitch';
import * as paymentPlans from '../plans';
import { useProject } from '@appmaker/core/hooks';
import useProjectData from '@appmaker/core/hooks/useProjectData';
import api from '../api';
import { Redirect } from '@shopify/app-bridge/actions';
import CurrentPlan from './CurrentPlan';
import Loader from '@appmaker/components/Loading';
import clsx from 'clsx';
import SimpleAlert from '@appmaker/components/templates/SimpleAlert';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/outline';
import CustomPlan from './CustomPlan';
import { getProjectData } from '@appmaker/core/store/project';
import couponList from '../plans/coupons';
import appmaker from '@appmaker/core/index';

export const PricingWidget = () => {
	const [yearly, setYearly] = useState(false);
	const [country, setCountry] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isPaymentProceeding, setPaymentProceeding] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState(false);
	const [planAlert, setPlanAlert] = useState(false);
	const [showFeatures, setShowFeatures] = useState(false);
	const { projectId } = useProject();
	const { loading, projectData = {} } = getProjectData({ projectId });
	const [couponCode, setCouponCode] = useState(undefined);
	const [showInvalidCouponCode, setShowInvalidCouponCode] = useState(false);
	const [isCouponApplying, setCouponApplying] = useState(false);
	const [couponAppliedMessage, setCouponAppliedMessage] = useState(false);
	const [plans, setPlan] = useState({});
	const { template, paymentStatus, endDate, publicMeta } = projectData;
	// console.log(projectData);
	const tiers = plans ? Object.values(plans) : [];
	useEffect(() => {
		const countryCode = async () => {
			try {
				setLoading(true);
				const response = await api.getCountryCode();
				setCountry(response.country ? response.country : false);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		countryCode();
	}, []);
	useEffect(() => {
		const plans = paymentPlans[template];
		setPlan(plans);
	}, [template]);
	const getPlanRegion = (country) => {
		const categoryOneCountries = [
			'AU',
			'BE',
			'CA',
			'CL',
			'FR',
			'DE',
			'IS',
			'IT',
			'KW',
			'MY',
			'NL',
			'PT',
			'QA',
			'RO',
			'SA',
			'AE',
			'GB',
			'US',
		];
		if (country === 'IN') {
			return 'india';
		}
		// if (categoryOneCountries.indexOf(country) > -1) {
		// 	return 'region_1';
		// }
		return 'default';
	};
	const getRegionalPlan = (plan, yearly) => {
		const { skipMonthly = false, pricing = false } = plan;
		const planRegion = getPlanRegion(country);
		const planIntervel =
			!skipMonthly && yearly === false ? 'monthly' : 'yearly';
		if (
			pricing &&
			Object.hasOwnProperty.call(pricing, planIntervel) &&
			Object.hasOwnProperty.call(pricing[planIntervel], planRegion)
		) {
			return pricing[planIntervel][planRegion];
		}
		if (
			pricing &&
			Object.hasOwnProperty.call(pricing, planIntervel) &&
			!Object.hasOwnProperty.call(pricing[planIntervel], planRegion) &&
			Object.hasOwnProperty.call(pricing[planIntervel], 'default')
		) {
			return pricing[planIntervel].default;
		}
		return false;
	};
	// payment
	const proceedToPayment = async (plan, availOffer = false) => {
		appmaker.doAction('appmaker-analytics-event', {
			event: 'make_payment_click',
			params: {
				project_id: projectId,
				plan: plan?.name,
				yearly,
			},
		});
		setSelectedPlan(plan);
		setPaymentProceeding(true);
		try {
			if (template === 'shopify') {
				const shopifyPlanId =
					yearly || availOffer ? plan.shopifyId.yearly : plan.shopifyId.monthly;
				const planResponse = await api.getPaymentUrl(
					projectId,
					shopifyPlanId.usd
				);
				if (!window.shopifyApp) {
					window.location.href = planResponse.confirmationUrl;
				} else {
					const redirect = Redirect.create(window.shopifyApp);
					redirect.dispatch(
						Redirect.Action.REMOTE,
						planResponse.confirmationUrl
					);
				}
				setPaymentProceeding(false);
			} else {
				let chargebeePlanId;

				const regionalPlan = getRegionalPlan(plan, yearly || availOffer);
				if (regionalPlan) {
					chargebeePlanId = regionalPlan.chargebeePlanId;
				} else {
					chargebeePlanId =
						yearly || availOffer
							? plans[plan.id].chargebeePlanId.yearly
							: plans[plan.id].chargebeePlanId.monthly;
				}
				const cbInstance = window.Chargebee.init({
					site:
						process.env.NODE_ENV === 'production'
							? 'appmaker'
							: 'appmaker-test',
				});
				cbInstance.openCheckout({
					hostedPage: async () => {
						try {
							const response = await api.chargebeeHostedPage(projectId, {
								plan: chargebeePlanId,
								availOffer: yearly || availOffer,
								country: country,
							});
							setPaymentProceeding(false);
							if (response.status && response.hosted_page) {
								return response.hosted_page;
							}
							if (response.pending_invoices) {
								window.location.href = api.chargebeeUpaid(projectId);
								cbInstance.closeAll();
							} else {
								cbInstance.closeAll();
								// eslint-disable-next-line no-alert
								alert('Unable to process payment, please contact support');
							}
						} catch (error) {
							cbInstance.closeAll();
							console.error(error);
							// eslint-disable-next-line no-alert
							alert('Unable to process payment, please contact support');
						}
					},
					error: () => {
						alert('Unable to process payment, please contact support');
					},
					close: () => {
						// console.log('checkout closed');
					},
					success: async (hostedPageId) => {
						try {
							const response = await api.chargebeeSuccess(
								projectId,
								hostedPageId
							);
							window.location.reload();
						} catch (error) {}
					},
					step: (value) => {},
				});
			}
		} catch (error) {
		} finally {
			setPaymentProceeding(false);
		}
	};

	const paymentButtonOnClick = (plan) => {
		let showAlertBox = false;
		const regionalPlan = getRegionalPlan(plan, yearly);
		if (regionalPlan !== false && regionalPlan.price) {
			showAlertBox = regionalPlan.showAlertBox;
		} else {
			showAlertBox = false;
		}
		if (plan && showAlertBox) {
			setSelectedPlan(plan);
			setPlanAlert(true);
		} else {
			proceedToPayment(plan);
		}
	};
	const availOfferButtonClick = (selectedPlan) => {
		proceedToPayment(selectedPlan, true);
	};
	const applyCoupon = async () => {
		const availableCoupons = couponList[template] || {};
		const list = Object.keys(availableCoupons).filter(
			(item) => item.toLowerCase
		);
		if (couponCode && list.includes(couponCode.toLowerCase())) {
			setYearly(true);
			setShowInvalidCouponCode(false);
			setCouponAppliedMessage(true);
			const { plan: couponPlan } = availableCoupons[couponCode] || {};
			setPlan(couponPlan);
		} else {
			const plans = paymentPlans[template];
			setPlan(plans);
			setShowInvalidCouponCode(true);
			setCouponAppliedMessage(false);
		}
	};
	if (loading || isLoading) {
		return <Loader />;
	}
	if (publicMeta.custom_plan)
		return (
			<div className="bg-gray-50 p-4 sm:p-12 h-full">
				<CurrentPlan paymentStatus={paymentStatus} endDate={endDate} />
				<CustomPlan
					publicMeta={publicMeta}
					paymentButtonOnClick={paymentButtonOnClick}
					isPaymentProceeding={isPaymentProceeding}
				/>
			</div>
		);
	return (
		<div className="bg-gray-50 p-4 sm:p-12 h-full">
			<CurrentPlan paymentStatus={paymentStatus} endDate={endDate} />
			<div className="flex justify-between sm:flex-row sm:items-center mb-2 flex-col gap-2 items-start">
				<div>
					<h2 className="text-xl font-semibold text-gray-800">
						Choose/upgrade plan for your store
					</h2>
					<div className="flex flex-col">
						<div className="flex flex-row space-x-2 mt-2 mb-2">
							<input
								type="text"
								className="border rounded-md"
								placeholder="Enter coupon code"
								value={couponCode}
								onChange={(e) => {
									setCouponCode(e.target.value);
									if (e.target.value.length === 0) {
										setShowInvalidCouponCode(false);
									}
								}}
							/>
							<button
								className="p-2 block w-full bg-blue-600 border border-blue-600 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-blue-900 cursor-pointer"
								disabled={isCouponApplying}
								onClick={() => {
									applyCoupon();
								}}
							>
								Apply Coupon
							</button>
						</div>
						{showInvalidCouponCode && (
							<span className="text-red-600">Invalid coupon code</span>
						)}
						{couponAppliedMessage && (
							<span className="text-green-600">Coupon applied</span>
						)}
					</div>
				</div>
				<AppmakerSwitch
					onChange={(data) => {
						setYearly(data ? data : false);
					}}
					formData={yearly}
					schema={{
						label:
							template === 'shopify'
								? 'Annual Billing (25% off)'
								: 'Annual Billing (20% off)',
						className: ' ',
					}}
				/>
			</div>
			<div className="max-w-8xl mx-auto bg-white py-6 sm:pt-6 sm:pb-0 rounded-lg border border-gray-200 relative">
				{/* xs to lg */}
				<div className="max-w-2xl mx-auto lg:hidden divide-y divide-gray-200">
					{tiers.map((tier, idx) => {
						const {
							name,
							offerPriceYearly: offerYear,
							priceYearly,
							priceMonthly,
							description,
							extraDesc,
							skipMonthly,
							specialPrice: offerPrice,
							included,
						} = tier;
						let price;
						let currency = '$';
						let offerPriceYearly = offerYear;
						let perLabel = yearly ? '/y' : '/mo';
						let specialPrice = offerPrice;
						let showMonthlyMode = false;
						if (skipMonthly) {
							perLabel = '/y';
						}
						const regionalPlan = getRegionalPlan(tier, yearly);
						if (regionalPlan !== false && regionalPlan.price) {
							price = regionalPlan.price;
							currency = regionalPlan.currencySymbol;
							offerPriceYearly = regionalPlan.offerPriceYearly;
							specialPrice = regionalPlan.specialPrice;
							if (regionalPlan.showMonthlyMode && regionalPlan.monthlyPrice) {
								showMonthlyMode = true;
								price = regionalPlan.monthlyPrice;
								perLabel = '/mo';
							}
						} else {
							price = yearly ? priceYearly : priceMonthly;
						}
						return (
							<section key={name}>
								<div className="px-4 pb-4">
									<h2 className="text-lg leading-6 font-medium text-gray-900 capitalize pt-2">
										{name}
									</h2>
									<p>
										<span
											className={clsx(
												'font-extrabold',
												yearly && offerPriceYearly
													? 'line-through text-gray-500 text-2xl'
													: 'text-gray-900 text-4xl'
											)}
										>
											{currency}
											{price}
										</span>{' '}
										{yearly && offerPriceYearly && (
											<span className="font-extrabold text-gray-900 text-4xl">
												{currency}
												{offerPriceYearly}
											</span>
										)}
										<span className="text-base font-medium text-gray-500">
											{perLabel}
										</span>
									</p>
									{showMonthlyMode && (
										<span className="text-sm font-medium text-blue-700">
											(When paid annually)
										</span>
									)}
									{specialPrice && (
										<>
											<div className="bg-green-500 bg-opacity-20 border border-green-300 p-3 rounded-md text-center mt-2">
												<h5 className="text-xl font-bold text-green-900">
													{specialPrice.currencySymbol}
													{specialPrice.price}
													{specialPrice.pricePerTag}
												</h5>
												<p className="text-sm font-medium text-gray-700">
													{specialPrice.offerLabel}
												</p>
											</div>
											{yearly && (
												<p className="text-xs mt-2 text-gray-600">
													Annual changes of {currency}
													{price} will be applicable from the second year
													onwards
												</p>
											)}
										</>
									)}
									<p className="my-4 text-sm text-gray-500">{description}</p>
									<button
										key={idx}
										className="block w-full bg-blue-600 border border-blue-600 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-blue-900 cursor-pointer"
										onClick={() => {
											proceedToPayment(tier);
										}}
										disabled={isPaymentProceeding}
									>
										{isPaymentProceeding && selectedPlan === tier ? (
											`Processing..`
										) : (
											<span>
												Upgrade to{' '}
												<span className="capitalize">{tier.name}</span>
											</span>
										)}
									</button>
									<p className="mt-4 text-xs text-gray-500">{extraDesc}</p>
									{included ? (
										<div className="mt-4">
											<button
												className="flex items-center justify-between space-x-1 text-gray-900 border border-gray-300 hover:bg-gray-200 w-full px-2 py-1 rounded-md mb-4"
												onClick={() => setShowFeatures(!showFeatures)}
											>
												<h3 className="text-base font-medium">
													Compare features
												</h3>
												<ChevronDownIcon
													className={clsx(
														'h-4 w-4 transform transition-transform',
														showFeatures ? 'rotate-180' : null
													)}
												/>
											</button>
											{showFeatures ? (
												<ul
													className={clsx(
														'text-sm list-inside mt-2 transform transition-transform',
														showFeatures ? 'scale-1' : 'scale-0'
													)}
												>
													{included.map((item, idx) => (
														<li className="flex items-start space-x-2 mb-2">
															<CheckIcon className="h-4 w-4 flex-shrink-0 text-green-600 mt-0.5" />
															<span key={idx} className="block">
																{item}
															</span>
														</li>
													))}
												</ul>
											) : null}
										</div>
									) : null}
								</div>
							</section>
						);
					})}
				</div>

				{/* lg+ */}
				<div className="hidden lg:block">
					<table className="w-full h-px table-fixed">
						<caption className="sr-only">Pricing plan comparison</caption>
						<thead>
							<tr>
								{/* <th
									className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
									scope="col"
								>
									<span>{template === 'shopify' ? null : null}</span>
								</th> */}
								{tiers.map((tier) => (
									<th
										key={tier.name}
										className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left capitalize"
										scope="col"
									>
										{tier.name}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="border-t border-gray-200 divide-y divide-gray-200">
							<tr>
								{/* <th
									className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top "
									scope="row"
								>
									{template === 'shopify' ? null : null}
								</th> */}
								{tiers.map((tier, idx) => {
									const {
										name,
										offerPriceYearly: offerYear,
										priceYearly,
										priceMonthly,
										description,
										extraDesc,
										skipMonthly,
										specialPrice: offerPrice,
										included,
									} = tier;
									let price;
									let currency = '$';
									let offerPriceYearly = offerYear;
									let perLabel = yearly ? '/y' : '/mo';
									let specialPrice = offerPrice;
									let showMonthlyMode = false;
									if (skipMonthly) {
										perLabel = '/y';
									}
									const regionalPlan = getRegionalPlan(tier, yearly);
									if (regionalPlan !== false && regionalPlan.price) {
										price = regionalPlan.price;
										currency = regionalPlan.currencySymbol;
										offerPriceYearly = regionalPlan.offerPriceYearly;
										specialPrice = regionalPlan.specialPrice;
										if (
											regionalPlan.showMonthlyMode &&
											regionalPlan.monthlyPrice
										) {
											showMonthlyMode = true;
											price = regionalPlan.monthlyPrice;
											perLabel = '/mo';
										}
									} else {
										price = yearly ? priceYearly : priceMonthly;
									}
									return (
										<td key={name} className="h-full py-6 px-6 align-top">
											<div className="relative h-full flex flex-col">
												<p>
													<span
														className={clsx(
															'font-extrabold',
															yearly && offerPriceYearly
																? 'line-through text-gray-500 text-2xl'
																: 'text-gray-900 text-4xl'
														)}
													>
														{currency}
														{price}
													</span>{' '}
													{yearly && offerPriceYearly && (
														<span className="font-extrabold text-gray-900 text-4xl">
															{currency}
															{offerPriceYearly}
														</span>
													)}
													<span className="text-base font-medium text-gray-500">
														{perLabel}
													</span>
												</p>
												{showMonthlyMode && (
													<span className="text-sm font-medium text-blue-700">
														(When paid annually)
													</span>
												)}
												{specialPrice && (
													<>
														<div className="bg-green-500 bg-opacity-20 border border-green-300 p-3 rounded-md text-center mt-2">
															<h5 className="text-xl font-bold text-green-900">
																{specialPrice.currencySymbol}
																{specialPrice.price}
																{specialPrice.pricePerTag}
															</h5>
															<p className="text-sm font-medium text-gray-700">
																{specialPrice.offerLabel}
															</p>
														</div>
														{yearly && (
															<p className="text-xs mt-2 text-gray-600">
																Annual changes of {currency}
																{price} will be applicable from the second year
																onwards
															</p>
														)}
													</>
												)}

												<p
													className={clsx(
														'mt-4 mb-8 text-sm text-gray-500',
														included ? null : 'flex-1'
													)}
												>
													{description}
												</p>
												<button
													key={idx}
													className="block w-full bg-blue-600 border border-blue-600 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-blue-900 cursor-pointer"
													onClick={() => {
														// proceedToPayment(tier);
														paymentButtonOnClick(tier);
													}}
													disabled={isPaymentProceeding}
												>
													{isPaymentProceeding && selectedPlan === tier ? (
														`Processing..`
													) : (
														<span>
															Upgrade to{' '}
															<span className="capitalize">{tier.name}</span>
														</span>
													)}
												</button>
												{extraDesc && (
													<p className="mt-2 text-xs text-gray-500">
														{extraDesc}
													</p>
												)}
												{included ? (
													<div className="mt-4">
														<button
															className="flex items-center justify-between space-x-1 text-gray-900 border border-gray-300 hover:bg-gray-200 w-full px-2 py-1 rounded-md mb-4"
															onClick={() => setShowFeatures(!showFeatures)}
														>
															<h3 className="text-base font-medium">
																Compare features
															</h3>
															<ChevronDownIcon
																className={clsx(
																	'h-4 w-4 transform transition-transform',
																	showFeatures ? 'rotate-180' : null
																)}
															/>
														</button>
														{showFeatures ? (
															<ul
																className={clsx(
																	'text-sm list-inside mt-2 transform transition-transform',
																	showFeatures ? 'scale-1' : 'scale-0'
																)}
															>
																{included.map((item, idx) => (
																	<li className="flex items-start space-x-2 mb-2">
																		<CheckIcon className="h-4 w-4 flex-shrink-0 text-green-600 mt-0.5" />
																		<span key={idx} className="block">
																			{item}
																		</span>
																	</li>
																))}
															</ul>
														) : null}
													</div>
												) : null}
											</div>
										</td>
									);
								})}
							</tr>
						</tbody>
					</table>
				</div>
				{planAlert && (
					<SimpleAlert
						openModal={planAlert}
						onClose={setPlanAlert}
						attributes={{
							title: 'Essential plan for only $150/year!',
							message:
								'Hey, there is an extra discount for the first year. You can get the Essential plan for only $150/year. Do you still want to go for $49/month?',
							ctaMain: {
								action: () => {
									availOfferButtonClick(selectedPlan);
								},
								label: 'Avail Offer',
							},
							ctaSecondary: {
								action: () => proceedToPayment(selectedPlan),
								label: 'Continue without offer',
							},
						}}
					/>
				)}
			</div>
		</div>
	);
};
