import React from 'react';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import clsx from 'clsx';

export default function MediaCard({ attributes, isSelected, setAttributes }) {
	const { featureImg } = attributes;
	console.log('keepAspectRatio', attributes.keepAspectRatio);
	return (
		<div
			className={clsx(
				'border border-gray-100 overflow-hidden',
				attributes.boxed ? ' m-2 rounded-md shadow-md' : null
			)}
		>
			{isSelected && (
				<MediaPlaceholder
					attributes={{
						title: 'Image',
						description: 'Upload feature image',
					}}
					onSelect={(images) => {
						let image = Array.isArray(images) ? images[0] : images;
						const data = image;
						setAttributes({
							featureImg: {
								url: data.url,
								fileName: data.filename,
								id: data.id,
								title: data.title,
								height: data.meta !== undefined ? data.meta.height : '100',
								width: data.meta !== undefined ? data.meta.width : '100',
							},
						});
					}}
					allowedTypes={['image']}
					multiple={false}
					labels={{ title: 'Image' }}
					value={featureImg}
				/>
			)}
			<img
				src={featureImg?.url || 'https://placehold.jp/720x300.png'}
				className={clsx(
					'w-full object-cover',
					attributes.keepAspectRatio ? 'h-auto' : 'h-40',
					attributes.boxed ? ' rounded-md' : null
				)}
				alt=""
			/>
			<div className="flex items-end justify-between px-4 py-2">
				<div className="flex-1">
					<h2>{attributes.title || 'Title'}</h2>
					<p className="text-sm text-gray-500">
						{attributes.content || 'Content'}
					</p>
				</div>
				<button className="border border-gray-400 px-2 py-1 rounded-md">
					{attributes.buttonText || 'Button'}
				</button>
			</div>
		</div>
	);
}

MediaCard.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
		content: {
			type: 'string',
			label: 'Content',
		},
		buttonText: {
			type: 'string',
			label: 'Button Text',
		},
		boxed: {
			type: 'string',
			label: 'Boxed',
			uiType: 'switch',
		},
		keepAspectRatio: {
			type: 'string',
			label: 'Keep image aspect Ratio',
			uiType: 'switch',
		},
	},
};
