import { V3_BASE_URL } from '@appmaker/core/api/core';
import { useProject } from '@appmaker/core/hooks';
import { useState } from 'react';
import axios from 'axios';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import { getProjectData } from '@appmaker/core/store/project';

const DeepLinkingHeader = (props) => {
    // Send request to /extension-functions/meta-field-v2/make-meta-fields-public
    const { projectId } = useProject()
    const { projectData } = getProjectData();
    const [isSaving, setIsSaving] = useState(false);

    function addAssetsFilesToShopify() {
        setIsSaving(true);
        console.log('makeMetaFieldsPublic', props);
        axios.post(`${V3_BASE_URL}/projects/${projectId}/extension-functions/deep-linking/enable-mobileplatformapplication`, {}, {
            credentials: 'include',
            withCredentials: true,
        }).then((response) => {
            setIsSaving(false);
            const { android = false, ios = false } = response.data;
            alert(`${android ? 'Android' : ''}${android && ios ? ' and ' : ''}${ios ? 'iOS' : ''} platform applications enabled`);
            console.log(response);
        }).catch((error) => {
            setIsSaving(false);
            console.log(error);
        })
    }

    return (
        <>
            <div className="block w-full mb-4">
                <b>Android assetlinks    :</b> <a  target="_blank" href={`${projectData.meta.PRIMARY_DOMAIN}/.well-known/assetlinks.json`}>{projectData.meta.PRIMARY_DOMAIN}/.well-known/assetlinks.json</a> <br />
                <b>iOS site association :</b> <a target="_blank" href={`${projectData.meta.PRIMARY_DOMAIN}/apple-app-site-association`}>{projectData.meta.PRIMARY_DOMAIN}/apple-app-site-association</a>
            </div>
            <button
                onClick={addAssetsFilesToShopify}
                type="button"
                disabled={isSaving}
                className="mr-3 inline-flex justify-self-end bg-green-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
            >
                {isSaving ? <LoadingIcon className="animate-spin mr-4 h-8 w-20" /> : 'Add site association files to Shopify'}
            </button>
        </>
    )
}

export default DeepLinkingHeader;