import appmaker from '@appmaker/core/index';
import Saleeh from './routes/Saleeh';
import WorkingTest from './routes/WorkingTest';

const PlaygroundPlugin = {
	id: '@appmaker/playground',
	name: 'Appmaker Playground',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/saleeh-v2',
			component: Saleeh,
		});
		appmaker.registerRoute({
			routeName: '/working-test',
			component: WorkingTest,
		});
		
		// appmaker.registerSideBarMenu({
		// 	name: 'saleeh App',
		// 	routeName: `/saleeh-v2`,
		// 	// icon: ColorSwatchIcon,
		// 	// MenuItemComponent: SubMenuItem,
		// });
	},
};

export default PlaygroundPlugin;
