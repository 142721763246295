import React from 'react'
import { CheckCircleIcon, ClockIcon } from '@heroicons/react/outline';

export default function StatusStep({ properties = {}, }) {
    const { status, label, helpText, helpeLink, content } = properties
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="divide-y divide-gray-200">
                {
                    /* item */
                }
                <div className="px-4 py-4 sm:px-6 flex justify-between">
                    <div className="flex-1">
                        <div className="flex items-center gap-2">
                            <h4 className="text-lg text-gray-900">{label}</h4>
                            <a href={helpeLink} target='_blank' rel="noopener noreferrer" 
                            className="text-blue-500 text-md">
                                {helpText}
                            </a>
                        </div>
                        <p className="text-sm text-gray-600">
                            {content}
                        </p>
                    </div>
                    <div className="text-md font-medium">
                        {status === 'success' ? <div className="text-green-500 flex gap-1 items-center">
                            <CheckCircleIcon className="h-4 w-4" />
                            Completed
                        </div> : <div className="text-gray-500 flex gap-1 items-center">
                            <ClockIcon className="h-4 w-4" />
                            Pending
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
