import React, { useState, useEffect } from 'react';
import FileUpload from '@appmaker/components/Form/fields/FileUpload';
import Gallery from 'packages/MediaLibrary/components/MediaUpload/Gallery';
import { useMediaStore } from 'packages/MediaLibrary/store/media'
import isEmpty from 'lodash/isEmpty'
import { listMedia } from '../../api/media';

export default function AppmakerMediaLibrary() {

	const searchText = useMediaStore(state => state.searchText)
	const [images, setImages] = useState([]);
	const setFiles = files => {
		const newImages = files.map(file => ({
			url: file.id.url,
			title: file.id.title,
			id: file.id.id,
			imageUrl: file.id.url
		}))
		setImages(newImages)
	}
	useEffect(() => {
		const loadImage = async () => {
			try {
				const search = isEmpty(searchText) ? undefined : searchText
				const resp = await listMedia({ search });
				setFiles(resp);
			} catch (error) {
			}
		};
		loadImage();
	}, [searchText]);

	return (
		<>
			<FileUpload
				schema={{ helperText: 'PNG, JPG, GIF up to 2MB', inner: true }}
			/>
			<Gallery schema={{ files: images }} />
		</>
	);
}
AppmakerMediaLibrary.media = {
	title: 'Media Library',
};
