import { V3_BASE_URL } from './core'
import axios from '../axios';

export const list = async (collectionName) => {
    try {
        const response = await axios.get(
            `${V3_BASE_URL}/collection/${collectionName}`,
            {
                credentials: 'include',
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const read = async (collectionName, documentId) => {
    try {
        const response = await axios.get(
            `${V3_BASE_URL}/collection/${collectionName}/${documentId}`,
            {
                credentials: 'include',
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const create = async (collectionName, data) => {
    try {
        const url = `${V3_BASE_URL}/collection/${collectionName}`;

        const response = await axios.post(url, data, {
            credentials: 'include',
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const update = async (collectionName, documentId, data) => {
    try {
        const url = `${V3_BASE_URL}/collection/${collectionName}/${documentId}`;

        const response = await axios.post(url, data, {
            credentials: 'include',
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}
