
import appmaker from '@appmaker/core/index';
import ConnectWebsite from './routes/ConnectWebsite';
import { LinkIcon } from '@heroicons/react/solid';
import actions from './actions';
import AppmakerMediaLibrary from './components/AppmakerMediaLibrary'
import { getProjectId } from '@appmaker/core/store/project';


const Plugin = {
    id: 'woocommerce',
    name: 'WooCommerce plugin',
    activate: () => {
        /**
         * Menu Items
         */
        // appmaker.registerSideBarMenu({
        //     name: 'Connect website',
        //     routeName: '/woocommerce',
        //     icon: LinkIcon,
        // });
        appmaker.addFilter('onboarding-menu-item', 'mobile-app-onboarding', (menuItems) => {
            menuItems.push({
                name: 'Connect website',
                routeName: '/woocommerce',
                icon: LinkIcon,
            })
            return menuItems
        })
        appmaker.addFilter('onboarding-routes', 'mobile-app-onboarding', (routes = []) => {
            routes.push({
                routeName: '/woocommerce',
                component: ConnectWebsite,
            })
            return routes
        })
        Object.keys(actions).map((action) =>
            appmaker.actions.registerAction(action, actions[action])
        );
        appmaker.addFilter('appmaker-media-library-factory', 'appmakerMedia', (items) => {
            // items.push(AppmakerMediaLibrary)
            items.push(AppmakerMediaLibrary)
            return items
        }, 1);
        appmaker.addFilter('onboarding-path', 'woocommerce-onboarding-path', () => '/onboarding/woocommerce')
        appmaker.addFilter('appdesign-next', 'woocommerce-appdesign-next-path', () => [`/old-dashboard/${getProjectId()}/`, true])
        appmaker.doAction('register-onboarding-steps', {
            title: 'Connect website',
            id: 'connect-woocommerce',
            route: '/onboarding/woocommerce',
            order: 4
        })
    },
};

export default Plugin;
