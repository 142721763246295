/**
 * WordPress dependencies
 */
import { useSelect } from '@wordpress/data';

/**
 * Internal dependencies
 */
import { store as blockEditorStore } from '@wordpress/block-editor/build/store';

export function MediaUploadCheck({ fallback = null, children, forceRender=false }) {
    const hasUploadPermissions = useSelect((select) => {
        const { getSettings } = select(blockEditorStore);
        return !!getSettings().mediaUpload;
    }, []);

    const component = hasUploadPermissions ? children : fallback;
    return forceRender ? children : component;
}

/**
 * @see https://github.com/WordPress/gutenberg/blob/HEAD/packages/block-editor/src/components/media-upload/README.md
 */
export default MediaUploadCheck;