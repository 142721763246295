import appmaker from '@appmaker/core/index';
import MetaFieldHeader from './MetaFieldHeader';

const MetaField = {
    id: 'meta-fields-v2',
    name: 'Meta Field V2',
    activate: () => {
        appmaker.addFilter('meta-fields-v2-override-header-component-installed', 'extension-override', (override, meta) => (<MetaFieldHeader override={override} meta={meta} />))
    }
}

export default MetaField;