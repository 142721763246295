import { RangeControl } from '@wordpress/components';

const RangeSlider = ({ properties = {}, defaultValue, value, onChange }) => {
	const {
		name,
		label,
		default: defaultNumber,
		placeholder,
		required,
		error,
		description,
		className,
		min = 0,
		max = 100,
		marks = [],
	} = properties;

	return (
		<RangeControl
			marks={marks}
			label={label}
			name={name}
			value={defaultValue ?? value ?? defaultNumber}
			// default={0}
			defaultValue={defaultNumber ?? 0}
			onChange={onChange}
			help={description}
			className={className}
			onError={error}
			min={min}
			max={max}
			allowReset={true}
		/>
	);
};

export default RangeSlider;
