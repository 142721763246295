import api from '../../../api';
import { useFormData, setFormData, sendNotification } from '../../../store';
import { CogIcon } from '@heroicons/react/solid';
import { useProject } from '@appmaker/core/hooks';
import { useState, useEffect } from 'react';
import create from './create';

const { uploadAppToCloud } = api;

const CreateApp = () => {
	const formData = useFormData();
	const { accessToken, firebaseProjectId, currentStep } = formData;
	const { projectId } = useProject();
	const [isLoading, setLoading] = useState(false);
	const [isAndroidHasApp, setIsAndroidHasApp] = useState(false);
	const [isIosHasApp, setIsIosHasApp] = useState(false);

	const addToast = (toastMessage, options) => {
		sendNotification(toastMessage, options);
	};
	const fetchProjectData = async (projectName) => {
		try {
			const response = await api.getFirebaseProjectData(
				projectId,
				accessToken,
				projectName
			);
			const {
				androidResponse = {},
				iosResponse = {},
				packageName,
				packageNameiOS = false,
				packageNameAndroid = false,
			} = response;
			/**
			 * Check Android ,if android app is available -> gets in 'if'
			 */
			try {
				const androidPackageName = packageNameAndroid
					? packageNameAndroid
					: packageName;
				if (Object.keys(androidResponse).length > 0) {
					const { apps = [] } = androidResponse;
					// Check the package name in firebase app and in appmaker db
					const filterAndroidApp = apps.filter(
						(item) => item.packageName === androidPackageName
					);
					if (filterAndroidApp.length === 0) {
						addToast(
							`Android package name mismatch in firebase project (${projectName}), it should be ${androidPackageName}`,
							{
								appearance: 'error',
								autoDismiss: false,
							}
						);
					} else {
						const uploadResponse = await uploadAppToCloud({
							accessToken,
							projectName,
							projectId: projectId,
							platform: 'android',
						});

						addToast(
							`Android app with same package name exist in firebase project (${projectName})`,
							{
								appearance: 'info',
								autoDismiss: false,
							}
						);
						setIsAndroidHasApp(true);
					}
				} else {
					// create new android app
					addToast('Android app create started', {
						appearance: 'success',
						autoDismiss: true,
					});
					create({
						accessToken,
						platform: 'android',
						addToast: addToast,
						projectId: projectName,
						id: projectId,
						afterComplete: setIsAndroidHasApp,
						setLoading: setLoading,
					});
				}
			} catch (error) {
				addToast('Something went wrong with android', {
					appearance: 'error',
					autoDismiss: false,
				});
			}

			/**
			 * Check IOS ,if IOS app is available -> gets in 'if'
			 */
			try {
				const iosPackageName = packageNameiOS ? packageNameiOS : packageName;
				if (Object.keys(iosResponse).length > 0) {
					const { apps = [] } = iosResponse;
					// Check the package name in firebase app and in appmaker db
					const filterIosApp = apps.filter(
						(item) => item.bundleId === iosPackageName
					);
					if (filterIosApp.length === 0) {
						addToast(
							`IOS bundle-id mismatch in firebase project (${projectName}), it should be ${iosPackageName}`,
							{
								appearance: 'error',
								autoDismiss: false,
							}
						);
					} else {
						const uploadResponse = await uploadAppToCloud({
							accessToken,
							projectName,
							projectId: projectId,
							platform: 'ios',
						});

						addToast(
							`IOS app with bundle-id exist in firebase project (${projectName})`,
							{
								appearance: 'info',
								autoDismiss: false,
							}
						);
						setIsIosHasApp(true);
					}
				} else {
					// create new ios app
					addToast('IOS app create started', {
						appearance: 'success',
						autoDismiss: true,
					});
					create({
						accessToken,
						platform: 'ios',
						addToast,
						projectId: projectName,
						id: projectId,
						afterComplete: setIsIosHasApp,
						setLoading: setLoading,
					});
				}
			} catch (error) {
				addToast('Something went wrong with ios', {
					appearance: 'error',
					autoDismiss: false,
				});
			}
		} catch (error) {
			console.log(error);
			addToast('Something went wrong', {
				appearance: 'error',
				autoDismiss: false,
			});
		}
	};
	useEffect(() => {
		fetchProjectData(firebaseProjectId);
	}, []);
	useEffect(() => {
		if (isAndroidHasApp && isIosHasApp) {
			setFormData({ ...formData, currentStep: currentStep + 1 });
		}
	}, [isAndroidHasApp, isIosHasApp]);
	return (
		<div className="flex flex-col flex-auto items-end justify-center">
			<CogIcon className="h-20 w-20 animate-spin-slow text-blue-900" />
		</div>
	);
};

export default CreateApp;
