import { GraphiQL } from 'graphiql';
import 'graphiql/graphiql.css';
import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { DASHBOARD_GRAPHQL_URL } from '@appmaker/core/api/core';

const fetcher = async (graphQLParams) => {
	const projectId = getProjectId();
	try {
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}`,
			JSON.stringify(graphQLParams),
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response.data;
	} catch (error) {}
	return null;
};
const GraphqlExplorer = () => {
	return (
		<div className="h-full">
			<GraphiQL fetcher={fetcher} />
		</div>
	);
};

export default GraphqlExplorer;
