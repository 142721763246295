 import { LockOpenIcon } from '@heroicons/react/solid';


export default function UnlockBadge({ attributes = {} }) {
	const { title = 'Pro Feature' } = attributes;
	return (
		<span className="text-blue-700 gap-1 bg-blue-100 px-2 py-0.5 rounded-full text-xs ml-2 inline-flex items-center justify-center">
			<LockOpenIcon className="h-3 w-3" />
			{title}
		</span>
	);
}
