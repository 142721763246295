import { InnerBlocks } from '@wordpress/block-editor';
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { BlockCard } from '@appmaker/e-commerce-blocks/components';
import { extractTextFromHTML } from '../helper/index';
import { RichText } from '@wordpress/block-editor';
import './style.css';
import ColorPicker from '@appmaker/core/components/AppmakerForm/components/ColorPicker';

const Edit = ({ attributes, setAttributes }) => {
	const {
		config = {},
		___internal,
		showViewMoreButton,
		showTitle,
		isInsideCartPage = false,
	} = attributes;
	return (
		<>
			<InspectorControls>
				<PanelBody title="Product Scroller">
					<ToggleControl
						label={'Show View more button'}
						checked={showViewMoreButton}
						onChange={() => {
							setAttributes({ showViewMoreButton: !showViewMoreButton });
						}}
					/>
					<ToggleControl
						label={'Show Title'}
						checked={showTitle}
						onChange={() => {
							setAttributes({ showTitle: !showTitle });
						}}
					/>
					<ColorPicker
						attributes={{
							label: 'Background Color',
							defaultColor: '#ffffff',
							onChangeComplete: (color) => {
								setAttributes({ backgroundColor: color.hex });
							},
						}}
					/>
				</PanelBody>
				<PanelBody title="Advanced" initialOpen={false}>
					<ToggleControl
						label={'Inside cart page'}
						checked={isInsideCartPage}
						onChange={() => {
							setAttributes({ isInsideCartPage: !isInsideCartPage });
						}}
					/>
				</PanelBody>
			</InspectorControls>
			<BlockCard
				titleEditor={
					<RichText
						allowedFormats={[]}
						value={attributes.title}
						withoutInteractiveFormatting
						onChange={(title) => {
							setAttributes({ title: extractTextFromHTML(title) });
						}}
						placeholder="Product Scroller Title"
					/>
				}
				attributes={{
					showTitle: showTitle,
					accessButton: showViewMoreButton && (
						<RichText
							allowedFormats={[]}
							value={attributes.ctaText}
							withoutInteractiveFormatting
							onChange={(ctaText) => {
								setAttributes({ ctaText: extractTextFromHTML(ctaText) });
							}}
							placeholder="View More Button"
						/>
					),
					backgroundColor: attributes?.backgroundColor,
				}}
			>
				<div class="block-card-inner-block">
					<InnerBlocks allowedBlocks={['appmaker/productscroller-item']} />
				</div>
			</BlockCard>
		</>
	);
};

export default Edit;
