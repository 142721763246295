import { entity } from 'simpler-state';

const dataSource = entity({
    list: [],
    installedList: [],
    setInstalledValue: false,
    setListValue: false
})

const useDataSource = (args) => dataSource.use(args);
const setDataSource = (data) => dataSource.set(value => ({ ...value, ...data }));


export {
    useDataSource,
    setDataSource
}

