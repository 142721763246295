// import firebase from 'firebase';
const firebase = {}
const collection = (collectionName) =>
  firebase.firestore().collection(collectionName);
const read = (collectionName) => async (id) => {
  const snapshot = await collection(collectionName).doc(id).get();
  const data = snapshot.data();
  return {
    _id: snapshot.id,
    ...data,
  };
};
const list =
  (collectionName) =>
    async (where = []) => {
      const user = firebase.auth().currentUser;
      let snapshot = collection(collectionName).where('uid', '==', user.uid);
      if (where) {
        where.map((condition) => {
          console.log(condition, ...condition);
          return snapshot = snapshot.where(...condition);
        });
      }
      snapshot = await snapshot.get();
      const items = [];
      snapshot.forEach((doc) => {
        items.push({ _id: doc.id, ...doc.data() });
      });
      return items;
    };

const create =
  (collectionName) =>
    async (record = {}) => {
      const user = firebase.auth().currentUser;
      const doc = await collection(collectionName).add({
        uid: user.uid,
        status: 'publish',
        ...record,
      });
      return {
        _id: doc.id,
        ...record,
      };
    };
const createOrUpdate =
  (collectionName) =>
    (record = {}) => {
      if (record._id) {
        return update(collectionName)(record);
      } else {
        return create(collectionName)(record);
      }
    };
const update =
  (collectionName) =>
    async ({ _id, ...data }) => {
      try {
        await collection(collectionName)
          .doc(_id)
          .update({
            ...data,
          });
      } catch (error) {
        const user = firebase.auth().currentUser;
        await collection(collectionName)
          .doc(_id)
          .set({
            uid: user.uid,
            ...data,
          });
      }
      const record = await read(collectionName)(_id);
      return record;
    };

const collectionApi = (collectionName, projectId) => ({
  list: list(`apps/${projectId}/${collectionName}`),
  create: create(`apps/${projectId}/${collectionName}`),
  read: read(`apps/${projectId}/${collectionName}`),
  update: update(`apps/${projectId}/${collectionName}`),
  createOrUpdate: createOrUpdate(`apps/${projectId}/${collectionName}`),
});
const appCollectionApi = {
  list: list(`apps`),
  create: create(`apps`),
  read: read(`apps`),
  update: update(`apps`),
  createOrUpdate: createOrUpdate(`apps`),
};
// const dataSourceApi = collectionApi("datasource");
export { collectionApi, appCollectionApi };
// export { createPage, savePage, getPage, getPages };
