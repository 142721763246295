import { useState, Fragment } from 'react';
import { useModal, useData } from '../hooks';
import { Dialog, Transition } from '@headlessui/react';
import TemplateListing from './TemplateListing';
import TemplatePreview from './TemplatePreview';

const TemplateChooserModal = () => {
    let [isOpen, setIsOpen] = useModal();
    const {
        previewTemplateId,
        setPreviewTemplate = () => { }
    } = useData();
    const [theme, setTheme] = useState({});
    function closeModal() {
        setIsOpen(false);
        setPreviewTemplate(undefined)
    }
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                    <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-6xl h-screen py-8 overflow-hidden text-left align-middle transition-all transform">
                                <div className="grid grid-cols-5 bg-white shadow-xl rounded-xl overflow-hidden h-full">
                                    {!previewTemplateId && <TemplateListing theme={theme} setTheme={setTheme} />}
                                    {previewTemplateId && <TemplatePreview />}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export default TemplateChooserModal