import SimplePopover from '@appmaker/components/templates/SimplePopover';
import {
	ArrowDownIcon,
	ArrowUpIcon,
	SwitchVerticalIcon,
} from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const DataBlock = ({ attributes = {} }) => {
	const {
		title,
		data,
		dataChange,
		status,
		date,
		helperText,
		helperPlacement,
		currency = undefined,
	} = attributes;
	function badgeState() {
		switch (dataChange) {
			case dataChange > 0:
				return 'text-green-800 bg-green-100';

			case dataChange < 0:
				return 'text-red-800 bg-red-100';

			default:
				return 'text-blue-800 bg-blue-100';
		}
	}
	return (
		<div className="flex flex-col space-y-1">
			<div className="flex items-center space-x-2">
				<h3 className="text-base font-medium text-gray-600 flex space-x-1">
					<span>{title}</span>
					{helperText ? (
						<SimplePopover
							attributes={{
								placement: helperPlacement || 'center',
								contentContainerClassName: 'w-48',
								button: (
									<InformationCircleIcon className="h-4 w-4 text-gray-500 mt-1" />
								),
							}}
						>
							<div className="text-sm bg-gray-900 text-white p-4">
								{helperText}
							</div>
						</SimplePopover>
					) : null}
				</h3>
				{dataChange ? (
					<span
						className={clsx(
							'text-sm rounded-full px-2 flex items-center space-x-1',
							badgeState()
						)}
					>
						{{
							increase: <ArrowUpIcon className="h-3 w-3" />,
							decrease: <ArrowDownIcon className="h-3 w-3" />,
						}[status] || <SwitchVerticalIcon className="h-3 w-3" />}
						<span>{dataChange}</span>
					</span>
				) : null}
			</div>

			<div className="flex space-x-1">
				{currency ? <p className="text-lg">{currency}</p> : null}
				<p className="text-3xl font-bold">{data}</p>
			</div>
			<small className="text-md font-medium text-gray-500">{date}</small>
		</div>
	);
};

export default DataBlock;
