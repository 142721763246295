const shopifyCoupons = {
    'map50': {
        plan: {
            free: {
                id: 'core',
                name: 'Core plan',
                href: '#',
                offerPriceYearly: 1800,
                priceMonthly: 299,
                priceYearly: 3599,
                // description: '2% fee on app orders',
                extraDesc: 'Business in starting stage.',
                shopifyId: {
                    monthly: { usd: 136 }, // BillingPackageCost id
                    yearly: {
                        // usd: 91,
                        usd: 142,
                    },
                },
                included: [
                    'Native Android & iOS App',
                    'Visual no-code drag and drop app builder',
                    'Access to free themes and templates',
                    'Unlimited custom pages',
                    'Limited Push notification',
                    'Firebase integration',
                    'Comprehensive CMS and Real-time sync with website content',
                    'Guided - Hassle-free upload to Appstore and Playstore',
                    'Support for all payment gateways',
                    'Social Login support',
                    'Product Review Integration',
                    'Unlimited push notifications',
                    'Email Support',
                ],
            },
            pro: {
                id: 'pro',
                name: 'Pro plan',
                href: '#',
                offerPriceYearly: 4194,
                priceMonthly: 699,
                priceYearly: 8388,
                description: '',
                extraDesc: 'For business in the growth stage',
                shopifyId: {
                    monthly: { usd: 134 }, // BillingPackageCost id
                    yearly: {
                        usd: 141,
                    },
                },
                included: [
                    <b>Everything in Core+</b>,
                    'Access to new product updates',
                    'Advanced Analytics',
                    'Multi-Language support',
                    'OTP Login Support',
                    'Multi-Currency support',
                    'RTL support',
                    'Scheduling push notifications',
                    'Publishing Support',
                    'Chat support',
                ],
            },
            ultimate: {
                id: 'ultimate',
                name: 'Ultimate plan',
                offerPriceYearly: 7194,
                priceMonthly: 1199,
                priceYearly: 14388,
                revenueDisc: '',
                description: '',
                extraDesc: 'For business in the scale stage',
                shopifyId: {
                    monthly: { usd: 132 },
                    yearly: {
                        usd: 140,
                    },
                },
                included: [
                    <b>Everything in Pro+</b>,
                    'Access to Premium Appmaker extensions',
                    'Push notifications with images',
                    'Access to Premium themes and templates',
                    'Design support',
                    'Priority chat + Account manager',
                ],
            },
            // scale: {
            // 	id: 'scale',
            // 	name: 'Scale plan',
            // 	offerPriceYearly: 9990,
            // 	priceMonthly: 999,
            // 	priceYearly: 11988,
            // 	revenueDisc: '',
            // 	description: '',
            // 	extraDesc: 'For business in the scale stage.',
            // 	shopifyId: {
            // 		monthly: { usd: 96 },
            // 		yearly: {
            // 			usd: 95,
            // 		},
            // 	},
            // },
        },
        name:'MAP Coupon',
    }
}

const coupons =  {
    shopify: shopifyCoupons
};

export default coupons;