import { Link } from "@appmaker/core/routes";
import clsx from 'clsx';


const MenuItem = ({ item, routeName, pathname, itemRoute })=>{
    return (<>
    <Link
				key={item.name}
				to={routeName}
				className={clsx(
					routeName === pathname
						? 'bg-gray-100 text-gray-900'
						: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
					'group px-2 py-2 text-sm font-medium rounded-md flex flex-col transition-all'
				)}
			>
				<div className="flex items-center">
					<item.icon
						className={clsx(
							routeName === pathname
								? 'text-blue-600'
								: 'text-gray-400 group-hover:text-gray-500',
							'flex-shrink-0 h-6 w-6'
						)}
						aria-hidden="true"
					/>
					<span className="line-clamp-1 ml-3">{item.name}</span>
				</div>
			</Link>
    </>)

}

export default MenuItem;