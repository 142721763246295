import { entity } from 'simpler-state';
import remove from 'lodash/remove';
import api from '../api';
import { filterValidBlocks } from '../lib/filterValidBlocks';
import { select } from '@wordpress/data';
import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project';

export const inAppPages = entity({
	isLoading: false,
	isInAppPageLoading: false,
	inAppPageList: [],
	pageId: 'home',
	saving: false,
	creating: false,
	deleting: false,
	pageSettingsVisible: false,
	adminSettingsVisible: false,

});
export const useInAppPage = () => inAppPages.use();

export const getInAppPage = () => inAppPages.get();

export const setInAppPageList = (inAppPageList) =>
	inAppPages.set((value) => ({ ...value, inAppPageList, isLoading: false }));

export const addPageToInAppPageList = (page) =>
	inAppPages.set((value) => ({
		...value,
		inAppPageList: [...value.inAppPageList, page],
		creating: false,
	}));

// export const removePageFrom = (page) => inAppPages.set(value => ({ ...value, inAppPageList: [...value.inAppPageList, page], creating: false }));

// to set in-app page list loading
export const setLoading = (isLoading) =>
	inAppPages.set((value) => ({ ...value, isLoading }));
// to set in-app page loading
export const setInAppPageLoading = (isInAppPageLoading) =>
	inAppPages.set((value) => ({ ...value, isInAppPageLoading }));
export const loadInAppPages = async (pageType = '') => {
	setLoading(true);
	const pageList = await api.getInAppPageList(pageType);
	setInAppPageList(pageList);
};

export const setSaving = (saving) =>
	inAppPages.set((value) => ({ ...value, saving }));

export const setDeleting = (deleting) =>
	inAppPages.set((value) => ({ ...value, deleting }));

export const setCreating = (creating) =>
	inAppPages.set((value) => ({ ...value, creating }));

export const setPageId = (pageId) =>
	inAppPages.set((value) => ({ ...value, pageId }));

export const togglePageSettingsVisible = () =>
	inAppPages.set((value) => ({
		...value,
		pageSettingsVisible: !value.pageSettingsVisible,
	}));


export const toggleAdminSettingsVisible = () =>
	inAppPages.set((value) => ({
		...value,
		adminSettingsVisible: !value.adminSettingsVisible,
	}));

export const isSaving = () => inAppPages.use((value) => value.saving);

export const isInAppPageLoading = () =>
	inAppPages.use((value) => value.isInAppPageLoading);

export const isCreating = () => inAppPages.use((value) => value.creating);

export const isDeleting = () => inAppPages.use((value) => value.deleting);

export const usePageId = () => inAppPages.use((value) => value.pageId);

export const usePageSettingsVisible = () =>
	inAppPages.use((value) => value.pageSettingsVisible);

export const useAdminSettingsVisible = () =>
	inAppPages.use((value) => value.adminSettingsVisible);

/**
 * Page Data Store
 */

export const inAppPage = entity({ blocks: [], title: '', attributes: {} });

export const useInAppPageData = (args) => inAppPage.use(args);

export const usePageTitle = () => inAppPage.use((store) => store.title);

export const setPageTitle = (title) => {
	const { id } = inAppPage.get();
	const { inAppPageList } = inAppPages.get();
	setInAppPageList(
		inAppPageList.map((item) => {
			if (item.id === id) {
				return {
					...item,
					label: title,
				};
			}
			return item;
		})
	);
	return inAppPage.set((store) => ({ ...store, title }));
};

export const usePageAttributes = () =>
	inAppPage.use((store) => store.attributes);

export const setPageAttributes = (attributes) =>
	inAppPage.set((store) => ({
		...store,
		attributes: { ...store.attributes, ...attributes },
	}));

export const usePageToolbarIcons = () =>
	inAppPage.use((store) => store.toolBarItems);

export const setPageToolbarIcons = (toolBarItems) =>
	inAppPage.set((store) => ({ ...store, toolBarItems }));

export const usePageMetaDataSource = () =>
	inAppPage.use((store) => store.metaDataSource);

export const setPageMetaDataSource = (metaDataSource) =>
	inAppPage.set((store) => ({ ...store, metaDataSource }));

export const setHash = (hash)=> inAppPage.set(store => ({ ...store, hash }))
export const setInAppPageData = (_pageData) => {
	inAppPage.set((data) => ({ ...data, ..._pageData }));
};

export const loadInAppPage = async (pageId) => {
	inAppPages.set((value) => ({ ...value, pageId, isInAppPageLoading: true }));
	const { blocks, ...pageDataResponse } = await api.getInAppPage(pageId);
	setInAppPageData({ ...pageDataResponse, blocks: filterValidBlocks(blocks) });
	setInAppPageLoading(false);
};

export const createPage = async (
	pageType,
	blocks,
	pageKey,
	title,
	others = {}
) => {
	const { creating, inAppPageList } = inAppPages.get();
	if (creating) return;
	setCreating(true);

	const resp = await api.create(pageType, blocks, pageKey, title, others);
	// setPageId(resp.id)
	addPageToInAppPageList({ id: resp.id, label: `${resp.title}` });
	setCreating(false);
	return resp;
};
export const deletePage = async () => {
	const { deleting, pageId } = inAppPages.get();
	if (deleting) return;
	setDeleting(true);
	try {
		await api.delete(pageId);
		await inAppPages.set((oldValue) => {
			const newPageslist = remove(
				oldValue.inAppPageList,
				({ id }) => id !== pageId
			);
			return { ...oldValue, inAppPageList: newPageslist, deleting: false };
		});
	} catch (error) {
		throw error
	}
	finally{
		setDeleting(false);
	}
	return Promise.resolve();
};
const pageSaveEventTracking = (pageId) => {
    try {
        const projectId = getProjectId();
        const blockType = select('core/block-editor').getSettings().editorType;
        if (blockType === 'normal') {
            if (pageId.includes('mainmenu')) {
                appmaker.doAction('appmaker-analytics-event', {
                    event: 'menu_saved',
                    params: {
                        project_id: projectId
                    }
                })
            } else {
                appmaker.doAction('appmaker-analytics-event', {
                    event: 'in_app_page_saved',
                    params: {
                        project_id: projectId
                    }
                })
            }
        }
    } catch (error) {

    }
}
export const syncPageData = async ({ overWrite = false } = {}) => {
    const { pageId, saving } = inAppPages.get();
    if (saving)
        return
    setSaving(true)
    // pageData.set(data)
    /*
    Async code
    */
    pageSaveEventTracking(pageId)
    const inAppPageData = inAppPage.get();
    try {
        let data = inAppPageData;
        if(overWrite){
            data = {
                ...data,
                overwrite: overWrite
            }
        }
        const response = await api.save(pageId, data);
        if(response.hash){
            setHash(response.hash);
        }
        // set new hash
        publishToApp({ track: 'production' })
    } catch (error) {
        if (error?.response?.data?.code === 'INVALID_HASH') {
            const confirm = window.confirm('Someone edited on current page,Do you want to overwrite the current page');
            if (confirm) {
                setSaving(false);
                await syncPageData({overWrite: true});
            }
        }
    }
    setSaving(false)
}

export const useParentId = () => inAppPage.use(store => store.parentID)

/**
 * Publish in-app pag to app
 */

const publishInAppPageToApp = entity({
	isPublishingAppConfig: false,
	isPublishingInAppPage: false,
});

export const isPublishingAppConfig = () =>
	publishInAppPageToApp.use((value) => value.isPublishingAppConfig);
export const isPublishingInAppPage = () =>
	publishInAppPageToApp.use((value) => value.isPublishingInAppPage);
export const setPublishingAppConfig = (saving) =>
	publishInAppPageToApp.set((value) => ({
		...value,
		isPublishingAppConfig: saving,
	}));
export const setPublishingInAppPage = (saving) =>
	publishInAppPageToApp.set((value) => ({
		...value,
		isPublishingInAppPage: saving,
	}));
export const publishInAppPage = async ({ track }) => {
	try {
		setPublishingInAppPage(true);
		const { pageId, saving } = inAppPages.get();
		await api.publishInAppPage(pageId, track, false);
		setPublishingInAppPage(false);
	} catch (error) { }
};
export const publishAppConfig = async ({ track }) => {
	try {
		setPublishingAppConfig(true);
		await api.publishAppConfig(track);
		setPublishingAppConfig(false);
	} catch (error) { }
};

export const publishToApp = async ({ track }) => {
	publishInAppPage({ track });
	publishAppConfig({ track });
};
/**
 * View JSON of page
 */
const viewJson = entity(false);
export const useViewJson = () => viewJson.use();
export const setViewJson = (value) => viewJson.set(value);

const focusMode = entity(false);
export const useFocusMode = () => focusMode.use();
export const setFocusMode = (value) => focusMode.set(value);
export const toggleFocusMode = () => {
	const focus = focusMode.get();
	focusMode.set(!focus);
};



/**
 * Version history
 */

const versionHistory = entity({
	versionHistoryVisible: false,
	previewId: false,
	currentInAppPageId: false,
	isRevering: false
})

export const useVersionHistory = () => versionHistory.use();
export const useVersionHistoryVisible = () =>
	versionHistory.use((value) => value.versionHistoryVisible);

export const toggleVersionHistoryVisible = () =>
	versionHistory.set((value) => ({
		...value,
		versionHistoryVisible: !value.versionHistoryVisible,
	}));

export const setPreviewId = (previewId, pageId) => versionHistory.set((value) => ({
	...value,
	previewId,
	currentInAppPageId: pageId ? pageId : false
}))

export const getPreviewId = () => versionHistory.get().previewId;
export const getCurrentVersionPageId = () => versionHistory.get().currentInAppPageId;
export const getReveringVersionHistory = () => versionHistory.get().isRevering;
export const setIsRevertingVersionHistory = (newValue) => versionHistory.set((value) => ({
	...value,
	isRevering: newValue
}
))