import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DownloadIcon, XIcon } from '@heroicons/react/outline';
import { useMutation } from '@apollo/client';
import { useParams } from '@appmaker/core/routes';
import { IMPORT_THEME } from '../api/graphql';
import Notification, { toast,sendNotification } from '@appmaker/components/ReactHotToastIntegrator';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { useHistory } from '@appmaker/core/routes';
import { extractIdFromLink } from 'packages/extensions/util'

export default function AddTheme() {
	let { handle, projectId } = useParams();
	const [importThemId, setImportThemId] = useState('')
	const [open, setOpen] = useState(false);
	const getRouterPath = useRouterPath();
	const history = useHistory();
	const [importTheme, { loading }] = useMutation(IMPORT_THEME, {
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});

	const onClickImport = async () => {
		if(importThemId === null || !importThemId || importThemId?.length === 0){
			sendNotification('Invalid ID','error')
		}else{
			let id;
			try {
				id = extractIdFromLink(importThemId);
			} catch (error) {

				return sendNotification('Invalid URL', 'error')
			}
			try {
				const response = await importTheme({
					variables: {
						importThemeId: id,
						data: {
							importThemeId: id,
						},
					},
				});
				if(response?.data?.importTheme?.theme?.handle){
					history.push(getRouterPath('/themes/' + response?.data?.importTheme?.theme?.handle))
				}else{
					sendNotification('Unable to import','error')
				}
			} catch (error) {
				console.log(error)
				sendNotification('Unable to import','error')
			}
		}
		
	}
	return (
		<Notification>
			<div className="flex justify-end">
				<button
					type="button"
					className="rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					onClick={() => setOpen(true)}
				>
					Add Custom Theme
				</button>
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
										<button
											type="button"
											className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() => setOpen(false)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
											<DownloadIcon
												className="h-6 w-6 text-indigo-600"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-1">
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-gray-900"
											>
												Import Theme
											</Dialog.Title>
											<div className="mt-2">
												<label
													htmlFor="link"
													className="block text-sm font-medium leading-6 text-gray-900"
												>
													Paste the partners theme link here
												</label>
												<div className="mt-2">
													<input
														type="url"
														name="link"
														id="link"
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														placeholder="https://partners.appmaker.xyz/themes/<theme_id>"
														onChange={(event) => {
															setImportThemId(event?.target?.value)
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
											disabled={loading}
											onClick={() => {
												onClickImport();
											}}
										>
											{loading ? 'Loading...':'Import'}
										</button>
										<button
											disabled={loading}
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setOpen(false)}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</Notification>
	);
}
