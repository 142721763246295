import {
	DatabaseIcon,
	AcademicCapIcon,
	PhotographIcon,
	HeartIcon,
	ShareIcon,
} from '@heroicons/react/outline';
import CartCardItem from '../components/CartCard';
import CheckoutButtonComponent from '../components/CheckoutButton';
import CartCouponComponent from '../components/CartCoupon';

function DummyBlock({ name }) {
	return (
		<div className="relative border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				<p className="text-sm font-medium text-gray-900">{name}</p>
			</div>
		</div>
	);
}
export function ExpandableTextBlock({ attributes }) {
	return <DummyBlock name="ExpandableTextBlock" />;
}

ExpandableTextBlock.blockData = {
	id: 'appmaker/expandable-text-block',
	title: 'expandable-text-block',
	icon: <DatabaseIcon />,
};
ExpandableTextBlock.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				blockTitle: { type: 'string' },
				content: { type: 'string' },
				accessButton: { type: 'boolean' },
				expanded: { type: 'boolean' },
				expandable: { type: 'boolean' },
			},
		},
	},
};

export function ShopifyVariationListner({ attributes }) {
	return <DummyBlock name="ShopifyVariationListner" />;
}
ShopifyVariationListner.blockData = {
	id: 'appmaker/shopify-variation-listner',
	title: 'shopify-variation-listner',
	icon: <DatabaseIcon />,
};
ShopifyVariationListner.attributesSchema = {
	type: 'object',
	properties: {
		filter: {
			label: 'filter',
			type: 'string',
		},
		product: {
			label: 'product',
			type: 'string',
		},
	},
};
/** 
 *   product: '{{blockItem.node}}',
		salePrice:
		  '{{blockItem.node.variant.priceV2.currencyCode}} {{blockItem.node.variant.priceV2.amount}}',
		title: '{{blockItem.node.title }}',
		count: '{{parseFloat(blockItem.node.quantity)}}',
		featureImg: '{{blockItem.node.variant.image.src}}',
		variation:
		  '{{blockItem.node.variant.title == "Default Title" ? "" : blockItem.node.variant.title }}',
	  
 */

export function CartCard({ attributes }) {
	return <CartCardItem attributes={{ title: 'product', price: '$256.00' }} />;
}
CartCard.blockData = {
	id: 'appmaker/cart-card',
	title: 'CartCard',
	icon: <DatabaseIcon />,
};
CartCard.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				salePrice: { type: 'string' },
				title: { type: 'string' },
				product: { type: 'string' },
				count: { type: 'string' },
				featureImg: { type: 'string' },
				variation: { type: 'string' },
				hasPages: { type: 'boolean' },
			},
		},
		product: {
			type: 'string',
			label: 'Product',
			default: '',
		},
		regularPrice: {
			type: 'string',
			label: 'Regular Price',
			default: '',
		},
		salePrice: {
			type: 'string',
			label: 'Sale price',
			default: '',
		},
		title: {
			type: 'string',
			label: 'title',
			default: '',
		},
		count: {
			type: 'string',
			label: 'count',
			default: '',
		},
		featureImg: {
			type: 'string',
			label: 'featureImg',
			default: '',
		},
		variation: {
			type: 'string',
			label: 'variation',
			default: '',
		},
		hasPages: {
			type: 'string',
			label: 'hasPages',
			default: '',
		},
		onSale: {
			type: 'string',
			label: 'onSale',
			default: '',
		},
		priceSale: {
			type: 'string',
			label: 'priceSale',
			default: '',
		},
		currency: {
			type: 'string',
			label: 'currency',
			default: '',
		},
		discountTotal: {
			type: 'string',
			label: 'discountTotal',
			default: '',
		},
	},
};

export function CheckoutButton({ attributes }) {
	return (
		<CheckoutButtonComponent
			attributes={{ numberOfItems: 4, price: '$256.00' }}
		/>
	);
}
CheckoutButton.blockData = {
	id: 'appmaker/checkout-button',
	title: 'CheckoutButton',
	icon: <DatabaseIcon />,
};
CheckoutButton.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				show: { type: 'boolean' },
				viewCartText: { type: 'string' },
				itemCount: { type: 'string' },
				totalPrice: { type: 'string' },
			},
		},
		show: {
			type: 'string',
			label: 'show',
			default: '',
		},
		viewCartText: {
			type: 'string',
			label: 'viewCartText',
			default: '',
		},
		itemCount: {
			type: 'string',
			label: 'itemCount',
			default: '',
		},
		totalPrice: {
			type: 'string',
			label: 'totalPrice',
			default: '',
		},
		showSubTotalAmount: {
			type: 'string',
			label: 'showSubTotalAmount',
			default: '',
		},
		subtotalAmount: {
			type: 'string',
			label: 'subtotalAmount',
			default: '',
		},
	},
};

export function AppImage({ attributes }) {
	return <DummyBlock name="appImage" />;
}
AppImage.blockData = {
	id: 'appmaker/app-image',
	title: 'appImage',
	icon: <DatabaseIcon />,
};
AppImage.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				show: { type: 'boolean' },
				viewCartText: { type: 'string' },
				itemCount: { type: 'string' },
				totalPrice: { type: 'string' },
			},
		},
	},
};

export function Text({ attributes }) {
	return <div className="text-sm">{attributes.content || 'TEXT'}</div>;
}
Text.blockData = {
	id: 'appmaker/text',
	title: 'Text',
	icon: <DatabaseIcon />,
};
Text.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				show: { type: 'boolean' },
				viewCartText: { type: 'string' },
				itemCount: { type: 'string' },
				totalPrice: { type: 'string' },
			},
		},
		content: {
			type: 'string',
			label: 'Content',
			default: '',
		},
		category: {
			type: 'string',
			label: 'Category',
			default: '',
		},
		status: {
			type: 'string',
			label: 'Status',
			default: '',
		},
		numberOfLine: {
			type: 'string',
			label: 'Number of Line',
			default: '',
		},
		fontColor: {
			type: 'string',
			label: 'Font Color',
			default: '',
		},
		__display: {
			type: 'string',
			label: '__display',
			default: '',
		},
	},
};

export function ActionButton({ attributes }) {
	return <DummyBlock name="Text" />;
}
ActionButton.blockData = {
	id: 'appmaker/action-button',
	title: 'Action Button',
	icon: <DatabaseIcon />,
};
ActionButton.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				show: { type: 'boolean' },
				viewCartText: { type: 'string' },
				itemCount: { type: 'string' },
				totalPrice: { type: 'string' },
			},
		},
	},
};

export function CartCoupon({ attributes }) {
	return <CartCouponComponent />;
}
CartCoupon.blockData = {
	id: 'appmaker/cart-coupon',
	title: 'Cart Coupon',
	icon: <DatabaseIcon />,
};
CartCoupon.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				// show: { type: 'boolean' },
			},
		},
		couponDiscounted: {
			type: 'string',
			label: 'coupon discounted',
			default: '',
		},
	},
};

// export function ShopifyVariationListner({ attributes }) {
// 	return <DummyBlock name="CartCoupon" />;
// }
// ShopifyVariationListner.blockData = {
// 	id: 'appmaker/shopify-variation-listner',
// 	title: 'Cart Coupon',
// 	icon: <DatabaseIcon />,
// };
// ShopifyVariationListner.attributesSchema = {
// 	type: 'object',
// 	properties: {
// 		attributes: {
// 			type: 'object',
// 			properties: {
// 				// show: { type: 'boolean' },
// 			},
// 		},
// 	},
// };
