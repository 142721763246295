import axios from 'axios';
import { BASE_URL } from './core';
export async function setAppmakerMeta(projectId, meta_key, body) {
    const url = `${BASE_URL}/projects/${projectId}/meta/apm_${meta_key}`;
    try {
        const response = await axios.post(url, body, {
            credentials: 'include',
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getAppmakerMeta(projectId, meta_key) {
    const url = `${BASE_URL}/projects/${projectId}/meta/apm_${meta_key}`;
    try {
        const response = await axios.get(url, {
            credentials: 'include',
            withCredentials: true,
        });
        return response.data.body;
    } catch (error) {
        return Promise.reject(error);
    }
}