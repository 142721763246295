import { useFormData } from '@appmaker/core/index';
import { InnerBlocks } from '@wordpress/block-editor';
import loadash from 'lodash';

const ConditionBlock = () => {
	return (
		<div>
			<p className="text-center bg-gray-100">Condition Block</p>
			<InnerBlocks allowedBlocks={['appmaker/condition-block-item']} />
		</div>
	);
};

const ConditionBlockItem = () => {
	return (
		<div className="m-1">
			<p className="text-center bg-gray-100">Condition Block Item</p>
			<InnerBlocks />
		</div>
	);
};

ConditionBlock.attributesSchema = {
	properties: {
		condition: {
			type: 'string',
			label: 'Condition',
		},
		asAdditionalComponent: {
			type: 'switch',
			label: 'As additional component',
			description: 'Show this component as additional component',
		},
	},
};

ConditionBlockItem.attributesSchema = {
	properties: {
		conditions: {
			type: 'array',
			uiType: 'infinite-form',
			label: 'Conditions',
			properties: {
				condition_item: {
					type: 'string',
					label: 'Condition item',
				},
				condition_type: {
					type: 'string',
					title: 'Condition Type',
					uiType: 'RadioGroup',
					options: [
						{
							label: 'Equal',
							value: 'equal',
						},
						{
							label: 'Not Equal',
							value: 'not_equal',
						},
					],
				},
				value: {
					type: 'string',
					label: 'Value',
				},
			},
		},
		condition_join_type: {
			type: 'string',
			title: 'Condition Join Type',
			uiType: 'RadioGroup',
			options: [
				{
					label: 'And',
					value: 'and',
				},
				{
					label: 'Or',
					value: 'or',
				},
			],
		},
	},
};
export const ConditionBlockView = (props) => {
	const { block, children } = props;
	return children;
	// return (
	// 	<div>
	// 		<p className="text-center bg-gray-100">Condition Block View</p>
	// 		{/* <pre>{JSON.stringify(innerBlocks,null,2)}</pre> */}
	// 		{children}
	// 	</div>
	// );
};
const sampleConditionAttr = {
	condition: 'condition',
	conditions: [
		{
			condition_type: 'equal',
			condition_item: 'giftType',
			value: 'cart_value',
		},
	],
	condition_join_type: 'or',
};
function isMatchConditionItem(condition, formData) {
	const { condition_type, condition_item, value } = condition;
	const formValue = loadash.get(formData, condition_item);
	if (condition_type === 'equal') {
		return formValue === value;
	}
	return formValue !== value;
}
function isMatchCondition(condition, formData) {
	const { condition_join_type, conditions } = condition;
	if (condition_join_type === 'or') {
		return conditions.some((condition) =>
			isMatchConditionItem(condition, formData)
		);
	}
	return conditions.every((condition) =>
		isMatchConditionItem(condition, formData)
	);
}
export const ConditionBlockItemView = (props) => {
	const { block, children, attributes } = props;
	const formData = useFormData();
	const isMatch = isMatchCondition(attributes, formData);
	if (!isMatch) {
		return null;
	}
	return children;
};
// ConditionBlockItemView.canRenderInnerBlocks = true;
export { ConditionBlockItem };
export default ConditionBlock;
