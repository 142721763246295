import { CheckIcon } from '@heroicons/react/outline';

const UpgradePlaceholder = ({ attributes = {} }) => {
	const { title, description, features, cta } = attributes;
	return (
		<div className="bg-gray-100 h-full w-full">
			<div className="relative max-w-4xl sm:mx-auto mx-4 lg:px-8 lg:grid lg:grid-cols-2 gap-4 bg-white shadow-xl px-4 py-8 rounded-lg sm:mt-16 mt-4">
				<div className="my-auto">
					<h3 className="text-2xl font-bold text-gray-800 mb-2">{title}</h3>
					<p className="text-gray-600 font-normal text-base mb-3">
						{description}
					</p>
					<ul className="text-base font-medium text-gray-600 ">
						{features?.map((item) => (
							<li className="flex items-center gap-1 mt-1">
								<CheckIcon className="h-5 w-5 text-green-500" />
								{item}
							</li>
						))}
					</ul>
					<button
						onClick={cta}
						type="button"
						className="inline-flex mt-6 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					>
						Upgrade Plan
					</button>
				</div>
				<div>
					<img src="/images/misc/upgrade-illustration.png" alt="upgrade plan" />
				</div>
			</div>
		</div>
	);
};

export default UpgradePlaceholder;
