import {
	DetailPageProvider,
	createStore,
	useExtensionDetail,
} from '../store/details';
import { useParams } from '@appmaker/core/routes';
import { useQuery } from '@apollo/client';
import { GET_EXTENSION_INSTALLS } from '../api/graphql';
import Loader from '@appmaker/components/Loading';
import DetailHeader from '../components/DetailHeader';
import Form from '../components/BackendSchemaForm';
import Notification from '@appmaker/components/ReactHotToastIntegrator';
import { useEffect } from 'react';

const BackendSettings = () => {
	const { handle, projectId } = useParams();
	const setExtension = useExtensionDetail((state) => state.setDetails);
	const setLoading = useExtensionDetail((state) => state.setLoading);
	const { data, loading, error } = useQuery(GET_EXTENSION_INSTALLS, {
		fetchPolicy: 'no-cache',
		variables: {
			extensionWhere: {
				handle: handle,
			},
			installWhere: {
				project: {
					remoteProjectId: {
						equals: parseInt(projectId),
					},
				},
				extension: {
					handle: {
						equals: handle,
					},
				},
			},
		},
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	useEffect(() => {
		setLoading(loading);
	}, [loading]);
	useEffect(() => {
		if (data?.extension?.id) {
			setExtension(data);
		}
	}, [data?.extension]);
	if (loading) return <Loader />;
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<div className="w-full mx-auto relative">
				<DetailHeader />
				<Form schema={data?.extension?.backendSchema} />
			</div>
		</div>
	);
};

const BackendSettingsWrapper = () => {
	return (
		<Notification>
			<DetailPageProvider createStore={createStore}>
				<BackendSettings />
			</DetailPageProvider>
		</Notification>
	);
};

export default BackendSettingsWrapper;
