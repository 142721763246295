import Select from 'react-select';
import Creatable from 'react-select/creatable';
import './style.css';
import { useFormDataValue } from '@appmaker/core';

function SelectMenu(props) {
	const { attributes } = props;
	const { options, multi = false, label, name, creatable = false, clearable= false } = attributes;
	const [value, setValue] = useFormDataValue({
		clientId: props.clientId,
		name,
	});

	return (
		<div className="mb-4 w-full">
			{label && (
				<label className="block text-sm font-base text-gray-700 mb-1">
					{label}
				</label>
			)}
			{creatable ? (
				<Creatable
					options={options}
					isMulti={multi}
					styles={{
						borderRadius: '.375rem',
						borderColor: 'black',
						borderWidth: 0.5,
					}}
					onChange={(e) => setValue(e)}
					value={value}
					isClearable={clearable}
				/>
			) : (
				<Select
					options={options}
					isMulti={multi}
					styles={{
						borderRadius: '.375rem',
						borderColor: 'black',
						borderWidth: 0.5,
					}}
					onChange={(e) => setValue(e)}
					value={value}
				/>
			)}
		</div>
	);
}

SelectMenu.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		options: {
			type: 'string',
			default: '[]',
			label: 'Options',
			uiType: 'infinite-form',
			properties: {
				label: {
					type: 'string',
					default: 'Label',
					label: 'Label',
				},
				value: {
					type: 'string',
					default: 'Value',
					label: 'Value',
				},
			},
		},
		multi: {
			type: 'switch',
			default: false,
			label: 'Multi',
		},
		creatable: {
			type: 'switch',
			default: false,
			label: 'Creatable',
		},
	},
};

export default SelectMenu;
