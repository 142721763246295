import React from 'react';
import clsx from 'clsx';
import { Tooltip } from '@wordpress/components';
import loadash from 'lodash';

export default function RadioItem({ children, ...props }) {
	return (
		<Tooltip text={loadash.startCase(props.value)}>
			<label
				className={clsx(
					'p-1 bg-white rounded cursor-pointer shadow-sm border-2',
					props.checked ? 'border-gray-700' : null
				)}
			>
				<input type="radio" className="hidden" {...props} />
				{children}
			</label>
		</Tooltip>
	);
}
