// import { uploadMedia } from '../api/media'
import { listMedia, generateStagedUpload, uploadFileToS3, createFiles } from '../api/media';

const uploadToServer = async ({ filesList, onFileChange, onError, ...other }) => {
    try {
        const stagedTargetsResponse = await generateStagedUpload({
            files: filesList,
        })
        const { stagedTargets } = stagedTargetsResponse;

        console.log(stagedTargets, filesList);
        const s3Responses = [];
        await Promise.all(Array.from(filesList).map(async (file, index) => {
            // console.log(stagedTargets[index], "stagedTargets[index]", index)
            const s3Response = await uploadFileToS3({ stagedTarget: stagedTargets[index], file: file });
            s3Responses.push(s3Response);
        }));

        const filesUplaodedResponse = await createFiles({ files: s3Responses })
        // const filesUplaodedIds = filesUplaodedResponse.files.map((file) => {
        //     return file.id.match(/\d+/)[0];
        // });
        const filesUplaodedIds = filesUplaodedResponse.files
        // const searchString = "id:" + filesUplaodedIds.join(' OR id:');

        let allImagesReady = false;
        let imageList = [];
        let readyImage = []
        while (!allImagesReady) {
            const resp = await listMedia({ });

            // const imagesReady = resp.filter((image) => {
            //     return image.fileStatus !== '"READY"';
            // });
            // if (imagesReady.length === filesUplaodedIds.length) {
            //     allImagesReady = true;
            // }
            const imageIds = resp.map((image) => image.id);
            for (const fileUploadedId of filesUplaodedIds){
                const presentImage = imageIds.find(item=>item.includes(fileUploadedId.id))
                if(presentImage){
                    readyImage.push(presentImage)
                }
            }
            if(readyImage.length === filesUplaodedIds.length){
                allImagesReady = true;
            }
            if (allImagesReady) {
                imageList = resp.map((file) => ({
                    url: file.url,
                    id: file.id,
                    imageUrl: file.url,
                    title: file.alt,
                    fileName: file.alt,
                    meta: {
                        height: file.height,
                        width: file.width,
                    }
                }));
            } else {
                await new Promise(r => setTimeout(r, 500));
            }
        }
        console.log("allImagesReady")
        onFileChange(imageList)
    } catch (error) {
        console.error(error)
        // onError(error.message)
    }
};

export { uploadToServer }