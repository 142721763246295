const PinCheck = ({ attributes = {} }) => {
	return (
		<div className="py-2 pl-3 pr-6">
			<p>Pin Check</p>
			<input
				type="text"
				className="border border-gray-800 rounded w-full overflow-hidden mt-2"
				placeholder="Enter pin code"
				disabled
			/>
		</div>
	);
};

PinCheck.attributesSchema = {
	type: 'object',
	properties: {
		accessButtonColor: {
			type: 'string',
			label: 'Access Button Color',
			default: '',
		},
		__appmakerStylesClassName: {
			type: 'string',
			label: 'Appmaker Styles Class Name',
			default: '',
		},
	},
};

export default PinCheck;
