import plans from '../plans/payment-status';
import moment from 'moment';

const CurrentPlan = ({paymentStatus,endDate}) => {
    let plan;
    if(!paymentStatus || paymentStatus === null  ){
        plan = plans[9].label
    }
    if(plans && plans[parseInt(paymentStatus)]){
        plan = plans[parseInt(paymentStatus)].label
    }
    let endData = endDate ? moment(endDate).format('LL') : ''
    return (
        <div className="mb-12">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Current subscription details
            </h2>
            <div className="bg-purple-100 border border-purple-200 rounded-lg p-4">
                <h5 className="font-bold text-gray-900 text-lg">Plan: {plan}</h5>
                <p className="text-sm font-normal text-gray-600">
                    Next payment on:{' '}
                    <span className="text-gray-800 font-medium">{endData}</span>
                </p>
                {/* <div className="flex items-center gap-4 mt-3">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Upgrade plan
                    </button>
                    <a href="#" className="text-blue-600">
                        Manage billing
                    </a>
                </div> */}
            </div>
        </div>
    )
};

export default CurrentPlan;