import api from '../../../api';
const { createApp, operations, uploadAppToCloud } = api;
/**
 *
 * @param {Object} param
 *
 * @returns
 */
const createNewApp = async ({
	accessToken,
	platform,
	addToast = () => {},
	projectId,
	id,
	afterComplete = () => {},
	setLoading = () => {},
}) => {
	try {
		setLoading(true);
		const response = await createApp({
			accessToken,
			projectName: projectId,
			projectId: id,
			platform,
		});

		const operationName = response.app.name;
		const operationResponse = await operations({
			accessToken,
			operationId: operationName,
			projectId: id,
		});
		if (operationResponse.done) {
			addToast(
				`${platform === 'android' ? 'Android' : 'IOS'} app create success`,
				{
					appearance: 'success',
					autoDismiss: true,
				}
			);
			setLoading(false);
		} else {
			addToast(
				`${platform === 'android' ? 'Android' : 'IOS'} app create in-progress`,
				{
					appearance: 'info',
					autoDismiss: true,
				}
			);
		}
		// Upload to google cloud storage
		try {
			const uploadResponse = await uploadAppToCloud({
				accessToken,
				projectName: projectId,
				projectId: id,
				platform,
			});
			if (uploadResponse) {
				addToast(
					`${
						platform === 'android' ? 'Android' : 'IOS'
					} App configuration success`,
					{
						appearance: 'success',
						autoDismiss: true,
					}
				);
				setLoading(false);
				afterComplete(true);
			} else {
				addToast(
					`${
						platform === 'android' ? 'Android' : 'IOS'
					} App configuration failed`,
					{
						appearance: 'error',
						autoDismiss: true,
					}
				);
				setLoading(false);
			}
		} catch (error) {
			addToast(
				`${
					platform === 'android' ? 'Android' : 'IOS'
				} App configuration failed`,
				{
					appearance: 'error',
					autoDismiss: true,
				}
			);
		}
		setLoading(false);
		return;
	} catch (error) {
		addToast(
			`${platform === 'android' ? 'Android' : 'IOS'} App configuration failed`,
			{
				appearance: 'error',
				autoDismiss: true,
			}
		);
		setLoading(false);
	}
};
export default createNewApp;
