import React from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';

class Notification extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
        title: PropTypes.node,
        message: PropTypes.node,
        timeOut: PropTypes.number,
        onClick: PropTypes.func,
        onRequestHide: PropTypes.func
    };

    static defaultProps = {
        type: 'info',
        title: null,
        message: null,
        timeOut: 5000,
        onClick: () => {
        },
        onRequestHide: () => {
        }
    };

    componentDidMount = () => {
        const { timeOut } = this.props;
        if (timeOut !== 0) {
            this.timer = setTimeout(this.requestHide, timeOut);
        }
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    };

    handleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
        this.requestHide();
    };

    requestHide = () => {
        const { onRequestHide } = this.props;
        if (onRequestHide) {
            onRequestHide();
        }
    };

    render() {
        const { type, message } = this.props;
        let { title } = this.props;
        title = title ? (<h4 className="title">{title}</h4>) : null;
        return (
            <NotificationItem type={type} title={title} message={message} />
        );
    }
}

export default Notification;