import axios from "axios";

const url = "https://crm.appmaker.xyz/form/submit";

const api = {
    submitEnquiryForm: async (data) => {
        const form = {
            mauticform: {
                ...data,
                formId: 48
            }
        };
        try {
            await axios.post(url, form, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            })
        } catch (error) {

        }
    }
}

export default api;