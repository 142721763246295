import create from 'zustand';
import produce from 'immer';

const useThemeStore = create((set) => ({
	themes: [],
	installList: [],
	activeTheme: [],
	originalData: {},
	setThemeData: (theme, installList) =>
		set(
			produce((state) => {
				state.themes = theme;
				state.installList = installList;
				if (installList) {
					let tempActiveTheme = installList?.filter((item) => {
						return item?.status === 'active';
					});
					// state.activeTheme = tempActiveTheme
					// 	? {
					// 			...theme?.find(
					// 				(item) => item?.handle === tempActiveTheme?.theme?.handle
					// 			),
					// 			installData: tempActiveTheme,
					// 	  }
					// 	: {};
					state.activeTheme = tempActiveTheme ?? {}
				}
				state.originalData = {
					themes: theme,
					installList: installList,
				};
			})
		),
	searchTheme: (search) => {
		set(
			produce((state) => {
				if (!search || search?.length === 0) {
					state.themes = state.originalData?.themes ?? [];
				} else {
					state.themes = state?.themes?.filter((item) =>
						item?.name?.toLowerCase()?.includes(search?.toLowerCase())
					);
				}
			})
		);
	},
}));

export default useThemeStore;
