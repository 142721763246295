
import * as React from 'react';
import ExtensionList from '../components/ExtensionList';
import hooks from '../hooks';
import Loader from '@appmaker/components/Loading';
import RedirectConsole from './RedirectToConsole';
function PluginRoute() {
    const { isLoading } = hooks.useExtensionList();
    if (isLoading) {
        return (<Loader />)
    }
    return (
        <div>
            <RedirectConsole />
            <ExtensionList title={'Available Extension'} />
            {/* <ExtensionList title={'Most Popular'}/>
            <ExtensionList title={'Most Popular'}/> */}
        </div>
    );
};

export default PluginRoute;