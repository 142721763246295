// @flow strict

import React, { useState } from 'react';
import { useLocalStorage } from 'react-use';
import appmaker from '@appmaker/core/index';

import AppmakerForm from '@appmaker/core/components/AppmakerForm';
function Saleeh() {
	const [action, setAction] = useLocalStorage('saleeh-app', {});
	const actions = appmaker.actions.getAllActions();

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<div className="max-w-3xl mx-auto mt-6">
				{/* <pre>{JSON.stringify(actions, null, 2)}</pre> */}
				<AppmakerForm
					options={actions}
					value={action}
					debug={true}
					optionKey={'action'}
					setValue={setAction}
				/>
			</div>
		</div>
	);
}

export default Saleeh;
