import { Tab } from '@headlessui/react';
import clsx from 'clsx';


const tabs = [
    { name: 'Details', current: true, },
    { name: 'Settings', current: false, restrictOnDeactivation: true },
];


const TabGroupContainer = ({ children, extension }) => {
    const {
        status = 'delete',
    } = extension || {};
    const installed = status === 'active';
    // const installed  = true
    return (
        <div className="grid grid-cols-3 gap-4">
            <div className="px-8 pb-8 pt-4 col-span-2">
                <Tab.Group>
                    <Tab.List className="mb-8 flex space-x-4 items-center">
                        {tabs.map((tab) => {
                            if (tab.restrictOnDeactivation && !installed) {
                                return null
                            }
                            return (
                                <Tab
                                    className={({ selected }) =>
                                        clsx(
                                            selected
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm'
                                        )
                                    }
                                >
                                    {tab.name}
                                </Tab>
                            )
                        })}
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel className="mb-4">Details</Tab.Panel>
                        <Tab.Panel className="mb-4">{children}</Tab.Panel>

                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )

}

export default TabGroupContainer;