import React, { useEffect, useState } from 'react';
import ContactSupport from '../../components/ContactSupport';
import { PencilIcon } from '@heroicons/react/solid'
import clsx from 'clsx';
import { InstallSteps } from '../../components/InstallSteps';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import { useStore } from '../../store/pluginCheck'
import { StatusStep } from './StatusStep';
export function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}
export function ContentItem({ steps }) {
	return <ul className="list-inside list-disc flex flex-col gap-3">
		{steps.map((step) => (
			<li key={step.step}>
				<span className="text-xl font-semibold text-gray-900">
					Step {step.step}
				</span>
				<div className="ml-6">
					<p className="font-normal text-base text-gray-700 mb-2">
						{step.instruction}
					</p>
					{step.screenshots.map((screenshot) => (
						<figure className="mt-1 mb-2 sm:w-1/2 mx-auto" key={screenshot.alt}>
							<img
								className="w-full h-auto rounded-lg border border-gray-300"
								src={screenshot.src}
								alt={screenshot.alt}
							/>
							<figcaption className="text-gray-500 text-sm">
								{screenshot.caption}
							</figcaption>
						</figure>
					))}
				</div>
			</li>
		))}
	</ul>
}


export default function InstallPlugin() {
	// const showSupportCard = false;
	const showSupportCard = useStore(store => store.showSupportCard)
	const showInstallSteps = useStore(store => store.showInstallSteps)
	const supportMessageText = useStore(store => store.supportMessageText)

	return (
		<div className="pt-4">

			<div className="">
				<div className="mb-4">
					<EditUrl />
					<StatusStep />
				</div>
				{showInstallSteps && <InstallSteps />}
			</div >
			{showSupportCard && <ContactSupport
				title={supportMessageText.heading}
				content={supportMessageText.content}

			/>}
		</div >
	)
}

const EditUrl = () => {
	const [edit, setEdit] = useState(false)
	const url = useStore(store => store.url)
	const setUrl = useStore(store => store.setUrl)
	const urlUpdating = useStore(store => store.urlUpdating)
	const updateUrl = useStore(store => store.updateUrl)
	return (
		<div className="bg-white shadow sm:rounded-lg">
			<div className="px-4 py-5 sm:p-6">
				<div className="max-w-xl"
				// style={{ minWidth: '400px' }}
				>
					<label htmlFor="url" className="block text-sm font-medium text-gray-700">
						Website URL
					</label>
					<div className="mt-1 flex rounded-md shadow-sm">
						<div className="relative flex items-stretch flex-grow focus-within:z-10">
							<input
								type="url"
								name="url"
								id="url"
								onChange={e => { setUrl(e.target.value) }}
								className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
								value={url}
								disabled={edit ? false : true}
							/>
						</div>
						<button
							type="button"
							className={clsx("-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500",
								{
									"bg-gray-100 text-gray-700 hover:bg-gray-100": !edit,
									"bg-blue-600 text-white hover:bg-blue-800": edit
								})
							}
							onClick={async () => {
								if (edit) {
									console.log(updateUrl);
									await updateUrl()
									setEdit(false)

								} else
									setEdit(true)

							}}
						>
							{!edit && <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
							{/* <span>{edit ? "Submit" : 'Edit'}</span> */}
							{urlUpdating ? <LoadingIcon className="-ml-1 mr-2 h-6 w-6 animate-spin" /> : edit ? "Submit" : ' Change URL'}

						</button>
					</div>
				</div>


			</div>
		</div>
	)
}

const VerificationFailedMessage = () => {
	return (
		<div className="mt-3 bg-yellow-100 rounded-md p-4">
			<h4 className="text-lg font-medium text-yellow-900">
				Verification failed
			</h4>
			<small className="mb-4 block">You may have one of the problem listed</small>
			<ul className="list-inside list-decimal text-base mb-2">
				<li>URL entered may wrong</li>
				<li>Website is not a wordpress site</li>
				<li>Etc...</li>
			</ul>

			<button
				type="button"
				className="inline-flex mt-2 items-center px-4 py-1.5 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			>
				Check again
			</button>
		</div>
	)
}