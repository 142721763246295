import ColorsGroup, { ColorPickerField } from './ColorsGroup';
import FileUpload from './FileUpload';
import FieldGroup from './FieldGroup';
import AppmakerSwitch from './AppmakerSwitch';
import AppmakerRadioGroup from './RadioGroup';
import Input from './TextInput';
import CheckBox from './CheckBox';
import RangeSlider from './RangeSlider';
import DataSourceSelector from './CustomForms/DataSourceSelector';
import LayoutSelector from './CustomForms/LayoutSelector';
import DataSelector from './CustomForms/DataSelector';
import AppmakerActionFormItem from './AppmakerActions';
import Media from './Media';
// import ColorPicker from './components/ColorPicker';
import ImageRadio from './ImageRadio';
import TextStyles from './Styling/TextStyles';
import LayoutStyles from './Styling/LayoutStyles';
import ImageStyles from './Styling/ImageStyles';
import GroupWrapper from './Group';
import Title from './Title';
import Collections from './Collections';
import InAppPage from './InappPage';
const fields = {
	ColorsGroup,
	LaunchScreen: FieldGroup,
	FieldGroup,
	FileUpload,
	ColorPicker: ColorPickerField,
	Input,
	Switch: AppmakerSwitch,
	RadioGroup: AppmakerRadioGroup,
	CheckBox,
	DataSourceSelector,
	LayoutSelector,
	DataSelector,
	RangeSlider,
	'appmaker-action': AppmakerActionFormItem,
	'appmaker-media-library': Media,
	'image-radio': ImageRadio,
	'text-styles': TextStyles,
	'layout-styles': LayoutStyles,
	'image-styles': ImageStyles,
	group: GroupWrapper,
	title: Title,
	collections: Collections,
	'in-app-page': InAppPage,
};
export { fields };
