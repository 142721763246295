import { useParams } from '@appmaker/core/routes';
import ThemePreview from './ThemePreview';
import Version from '../components/Version';


const ThemeDetailRoute = () => {
    const { route } = useParams();
    if (route === 'version') {
        return <Version />
    }
    return <ThemePreview />

}

export default ThemeDetailRoute;