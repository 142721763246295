import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { BASE_URL } from '@appmaker/core/api/core';


export const collection = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/collections?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};
export const products = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/prebuildProductScroller?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};


// export  api;

