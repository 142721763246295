import React from 'react';

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M10.2653 15.3984H5.66472L4.79009 17.8374H2L6.74052 6H9.17201L13.9388 17.8374H11.1487L10.2653 15.3984ZM6.37318 13.4228H9.55685L7.95627 8.99187L6.37318 13.4228Z"
			fill="black"
		/>
		<path
			d="M20.4461 17.8374C20.3294 17.626 20.2449 17.3631 20.1924 17.0488C19.5802 17.6829 18.7843 18 17.8047 18C16.8776 18 16.1079 17.7507 15.4956 17.252C14.8892 16.7534 14.586 16.1247 14.586 15.3659C14.586 14.4336 14.9563 13.7182 15.6968 13.2195C16.4431 12.7209 17.5189 12.4688 18.9242 12.4634H20.0875V11.9593C20.0875 11.5528 19.9738 11.2276 19.7464 10.9837C19.5248 10.7398 19.172 10.6179 18.688 10.6179C18.2624 10.6179 17.9271 10.7127 17.6822 10.9024C17.4431 11.0921 17.3236 11.3523 17.3236 11.6829H14.7959C14.7959 11.1734 14.965 10.7019 15.3032 10.2683C15.6414 9.83469 16.1195 9.49593 16.7376 9.25203C17.3557 9.00271 18.0496 8.87805 18.8192 8.87805C19.9854 8.87805 20.9096 9.15176 21.5918 9.69919C22.2799 10.2412 22.6239 11.0054 22.6239 11.9919V15.8049C22.6297 16.6396 22.7551 17.271 23 17.6992V17.8374H20.4461ZM18.3557 16.2033C18.7289 16.2033 19.0729 16.1274 19.3878 15.9756C19.7026 15.8184 19.9359 15.6098 20.0875 15.3496V13.8374H19.1429C17.8776 13.8374 17.2041 14.2439 17.1224 15.0569L17.1137 15.1951C17.1137 15.4878 17.2245 15.729 17.4461 15.9187C17.6676 16.1084 17.9708 16.2033 18.3557 16.2033Z"
			fill="black"
		/>
	</svg>
);
