import React, { useState, useEffect } from 'react';
import {
	InnerBlocks,

	RichText,
} from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { useSelect } from '@wordpress/data';
import PanelBodyComponent from './panel';
import { extractTextFromHTML } from '../../helper/index';
import BlockCard from '../../../../new-blocks/components/BlockCard';
import { dispatch } from '@wordpress/data'
import { createBlock } from '@wordpress/blocks'
import { name } from 'ejs';
import { PhotographIcon, ViewGridIcon } from '@heroicons/react/outline';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';

// const ButtonBlockAppender = (props) => {
//   console.log(props, 'loooooo');
//   return <div>
//     Append

//   </div>
// }

const GridEdit = (props) => {
	const { attributes, className, setAttributes, clientId, isSelected, metaData } = props;
	const { itemsPerLine, showViewMoreButton, showTitle, image } = attributes;
	// const [selectedImages,s]
	const [state, setState] = useState({})
	const blockSelected = useSelect((select) => {
		// const block = select('core/block-editor').getBlock(clientId);
		const items = select('core/block-editor')
			.getBlock(clientId)
			.innerBlocks
			.map(item => item.attributes?.image.url)
		const childSelected = select('core/block-editor').hasSelectedInnerBlock(clientId)
		return childSelected || isSelected || items.length === 0
	})

	function addGridItem(data) {
		try {
			const block = createBlock('appmaker/grid-item', {
				image: {
					url: data.url,
					fileName: data.filename,
					id: data.id,
					title: data.title
				},
				imageContainerStyle: {
					height: data.meta !== undefined ? data.meta.height : '100',
					width: data.meta !== undefined ? data.meta.width : '100'
				},
				thumbnail_meta: {
					height: data.meta !== undefined ? data.meta.height : undefined,
					width: data.meta !== undefined ? data.meta.width : undefined
				},
				text: '',
				showText: false,
				appmakerAction: {},
				hasAnalytics: false,
			});
			dispatch('core/block-editor').insertBlocks(block, 0, clientId)

		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div className={className}>
			<PanelBodyComponent
				{...props}
				onChangeAttribute={(value) => {
					setAttributes(value);
				}}
			/>

			<BlockCard
				titleEditor={(
					<RichText
						allowedFormats={[]}
						value={attributes.title}
						withoutInteractiveFormatting
						onChange={(title) => {
							setAttributes({ title: extractTextFromHTML(title) });
						}}
						placeholder="Grid title"
					/>
				)}
				attributes={{
					showTitle,
					accessButton: showViewMoreButton && (
						<RichText
							allowedFormats={[]}
							value={attributes.ctaText}
							withoutInteractiveFormatting
							onChange={(ctaText) => {
								setAttributes({ ctaText: extractTextFromHTML(ctaText) });
							}}
							placeholder="View More Button"
						/>
					),
				}}
			>
				<div
					className={`blocks-gallery-grid has-${itemsPerLine}-item px-2 mt-2`}
				>	{blockSelected && <MediaPlaceholder
					attributes={
						{
							icon: ViewGridIcon,
							title: 'Grid',
							description: 'Add Images and showcase your new collections or highlight different categories using a grid block. You can add up to 5 columns per block.',
							maxHeight: metaData?.image?.maxHeight,
							maxWidth: metaData?.image?.maxWidth,
							disableImageResize: attributes?.disableImageResize
						}
					}
					onSelect={(images) => {
						// condition in meta because each api having different response of the meta
						setState({
							images
						});
						images.map(addGridItem)
					}}
					allowedTypes={['image']}
					multiple={true}
					labels={{ title: 'Images' }}
					value={{ id: image && image.id, src: image && image.url }}
				/>}
					<InnerBlocks
						allowedBlocks={['appmaker/grid-item']}
						renderAppender={null}
					/>
				</div>
			</BlockCard>
		</div>
	);
};

export default GridEdit;
