import axios from 'axios';
import { V3_BASE_URL } from '@appmaker/core/api/core';
const BASE_URL = V3_BASE_URL + '/projects';

const api = {
	getTransformList: async ({ projectId }) => {
		try {
			const response = await axios
				.get(`${BASE_URL}/${projectId}/transforms`, {
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	updateTransform: async ({ projectId, formData }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/transforms`,
				formData,
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	}
}

export default api;