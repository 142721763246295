import { ChevronRightIcon } from '@heroicons/react/solid';

const CheckoutButtonComponent = ({ attributes = {} }) => {
	const { numberOfItems = 1, price = '$25.00' } = attributes;
	return (
		<div className="bg-white px-4 py-3 border-t border-gray-100">
			<div className="bg-blue-600 text-white flex items-center justify-between py-2 px-3 rounded-lg">
				<div className="flex flex-col">
					<p className="text-sm">{numberOfItems} Item(s)</p>
					<p className="text-lg font-bold">{price}</p>
				</div>
				<p className="text-lg font-medium flex items-center">
					<span>Checkout</span>
					<ChevronRightIcon className="h-4 w-4" />
				</p>
			</div>
		</div>
	);
};

export default CheckoutButtonComponent;
