const plan = {
	// free: {
	// 	id: 'free',
	// 	name: 'Free plan',
	// 	href: '#',
	// 	offerPriceYearly: 0,
	// 	priceMonthly: 0,
	// 	priceYearly: 0,
	// 	description: '2% fee on app orders',
	// 	extraDesc: 'Business in early stage.',
	// 	shopifyId: {
	// 		monthly: { usd: 101 }, // BillingPackageCost id
	// 		yearly: {
	// 			// usd: 91,
	// 			usd: 101,
	// 		},
	// 	},
	// 	included: [
	// 		'Native Android & iOS App',
	// 		'Visual no-code drag and drop app builder',
	// 		'Access to free themes and templates',
	// 		'Comprehensive CMS',
	// 		'Real-Time Sync with Website Content',
	// 		'5 custom pages',
	// 		'Limited Push Notifications',
	// 		'Doc support only',
	// 	],
	// },
	// core: {
	// 	id: 'core',
	// 	name: 'Core plan',
	// 	href: '#',
	// 	offerPriceYearly: 4990,
	// 	priceMonthly: 499,
	// 	priceYearly: 5988,
	// 	// description: '2% fee on app orders',
	// 	extraDesc: 'Business in starting stage.',
	// 	shopifyId: {
	// 		monthly: { usd: 136 }, // BillingPackageCost id
	// 		yearly: {
	// 			// usd: 91,
	// 			usd: 137,
	// 		},
	// 	},
	// 	included: [
	// 		'Native Android & iOS App',
	// 		'Visual no-code drag and drop app builder',
	// 		'Access to free themes and templates',
	// 		'Unlimited custom pages',
	// 		'Limited Push notification',
	// 		'Firebase integration',
	// 		'Comprehensive CMS and Real-time sync with website content',
	// 		'Guided - Hassle-free upload to Appstore and Playstore',
	// 		'Support for all payment gateways',
	// 		'Social Login support',
	// 		'Product Review Integration',
	// 		'Unlimited push notifications',
	// 		'Email Support',
	// 	],
	// },
	pro: {
		id: 'pro',
		name: 'Pro',
		href: '#',
		offerPriceYearly: 9000,
		priceMonthly: 1000,
		priceYearly: 12000,
		revenueDisc: '3 months free with annual subscription',
		description: 'Best suited for brands',
		// extraDesc: '',
		shopifyId: {
			monthly: { usd: 134 }, // BillingPackageCost id
			yearly: {
				usd: 135,
			},
		},
		included: [
			<b>All core features</b>,
			'Platform access for app personalisation',
			'Solutioning engineer & Publishing expert',
			'App Integrations & Plugin Library',
			'Personalized app migration and / or',
			'app launch assistance [initial 3 months]',
			'Enterprise support',
		],
	},
	ultimate: {
		id: 'ultimate',
		name: 'Plus',
		offerPriceYearly: 18000,
		priceMonthly: 2000,
		priceYearly: 24000,
		revenueDisc: '+ 1% success fee',
		description: 'Recommended for category leaders',
		// extraDesc: '',
		shopifyId: {
			monthly: { usd: 132 },
			yearly: {
				usd: 133,
			},
		},
		included: [
			<b>Everything in Pro+</b>,
			'Dedicated customer success manager',
			'Priority support',
		],
	},
	// scale: {
	// 	id: 'scale',
	// 	name: 'Scale plan',
	// 	offerPriceYearly: 9990,
	// 	priceMonthly: 999,
	// 	priceYearly: 11988,
	// 	revenueDisc: '',
	// 	description: '',
	// 	extraDesc: 'For business in the scale stage.',
	// 	shopifyId: {
	// 		monthly: { usd: 96 },
	// 		yearly: {
	// 			usd: 95,
	// 		},
	// 	},
	// },
};

export default plan;
