
import * as React from 'react';

function PluginRoute() {
    return (
        <div>
            Hello PluginRoute
        </div>
    );
};

export default PluginRoute;