import React from 'react'

export default function Heading({ properties = {} }) {
    const { label } = properties
    return (
        <div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">{label}</h2>
        </div>
    )
}
