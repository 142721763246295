import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [{
    "clientId": "cc941fee-7dbf-4e8e-8124-2e314777671f",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_89a330f4-a435-4c69-ac06-0302abbb5c7b.png?v=1657198119",
            "id": "gid://shopify/MediaImage/30813704028417",
            "title": "a.png"
        },
        "imageContainerStyle": {
            "height": 157,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 157,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "0359da35-d2e5-4691-87d5-46ac5f1c5be0",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "customImageWidth": true,
        "parentWidth": "78"
    },
    "innerBlocks": [{
        "clientId": "4a6c4b53-fa00-4d61-99e0-7f77eaa26019",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/y_d4cdf511-735d-4223-aa9d-94f9ae86c49e.png?v=1657281937",
                "id": "gid://shopify/MediaImage/30822621708545",
                "title": "y.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_COLLECTION",
                "collectionId": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ==",
                "title": "Makeyup"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                },
                "collectionId": {
                    "label": "Makeyup",
                    "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
                },
                "actionParams": {
                    "label": "Makeyup",
                    "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "bd8f30af-5832-4a31-8001-f352105b315e",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/y-1_99ead4f3-5ee5-41f4-b0ee-7ce7c88c82f2.png?v=1657281933",
                "id": "gid://shopify/MediaImage/30822620954881",
                "title": "y-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "b1e2f7e8-aee1-47ee-af53-73ff1586e437",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/y-2_50f0424a-fff2-4f5b-a28d-9e8e6c90db57.png?v=1657281931",
                "id": "gid://shopify/MediaImage/30822620889345",
                "title": "y-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "e7a39060-e4ee-43ec-a173-d44c2eddb923",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/y-4_d0eea47e-8090-498a-83df-706c8a2d2127.png?v=1657281930",
                "id": "gid://shopify/MediaImage/30822620528897",
                "title": "y-4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "3868a819-1264-4843-ae50-fc2cbd7267d8",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/y-3_aa99fbc0-893c-4f2e-bf15-ffa6afa078bc.png?v=1657281930",
                "id": "gid://shopify/MediaImage/30822620561665",
                "title": "y-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "8c96074b-2a97-4fc7-82d2-219f2ca4ab48",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": []
}, {
    "clientId": "396eaff8-ec88-41ff-8343-6932cdbf35e6",
    "name": "appmaker/slider",
    "isValid": true,
    "attributes": {
        "autoPlay": false,
        "delay": 1,
        "appmakerAction": {
            "action": "OPEN_URL",
            "url": "https://docs.google.com/document/d/1fs9wtY94r1dJBZb23uAGiqZOYkPwkfgjJ_EPTx0P14M/edit#heading=h.uwm6bowizi21"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_URL",
                "label": "Open URL"
            }
        }
    },
    "innerBlocks": [{
        "clientId": "86475995-5f79-4e01-8fcc-8e9c11881799",
        "name": "appmaker/slider-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-2_1d3df812-95c7-4620-9d21-524560423e72.png?v=1657198159",
                "id": "gid://shopify/MediaImage/30813712941313",
                "title": "b-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 546,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "a511a95c-6092-47d6-8dd9-f3a5c70041bb",
        "name": "appmaker/slider-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_19c9d28e-92e0-4400-8b6f-b07ee2cc5a27.png?v=1657198156",
                "id": "gid://shopify/MediaImage/30813712417025",
                "title": "b.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 546,
                "width": 1080
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "6991b036-8451-4da8-a021-8431430d3107",
        "name": "appmaker/slider-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-1_44dfb855-7933-4538-bf38-391d06c74e62.png?v=1657198135",
                "id": "gid://shopify/MediaImage/30813707600129",
                "title": "b-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 546,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "a88982a6-c599-4407-b04c-784fdb77437a",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "customImageWidth": true,
        "parentWidth": "125"
    },
    "innerBlocks": [{
        "clientId": "c8fd37c8-57a5-436e-97ab-ec43e3bdcc27",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_4763ce44-d323-472b-9c7d-2efdbf23ed60.png?v=1657198194",
                "id": "gid://shopify/MediaImage/30813720019201",
                "title": "c.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 253,
                "width": 587
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "cfb5cb5a-2cab-4478-8652-468f26c726d1",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-1_4e1e721b-be07-4423-a8a8-098e5abea8c0.png?v=1657198189",
                "id": "gid://shopify/MediaImage/30813719331073",
                "title": "c-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 253,
                "width": 587
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "aefd43ed-6cc3-403e-8823-908a87f67f34",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-2_c1061ee1-7207-4df9-bc5e-b15456874eab.png?v=1657198186",
                "id": "gid://shopify/MediaImage/30813719036161",
                "title": "c-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 253,
                "width": 587
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "33a4e8e2-2f94-4ecc-b0ea-6bb3e9a036bf",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-3_7c256745-149e-4b15-9391-23f2dfe3cb7b.png?v=1657198186",
                "id": "gid://shopify/MediaImage/30813718905089",
                "title": "c-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 253,
                "width": 587
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "7dd0d4ae-8b72-48ce-88cb-9efbda2db329",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-4_89fb4961-f0e5-4cac-aaec-1586a84d8f7f.png?v=1657198185",
                "id": "gid://shopify/MediaImage/30813718282497",
                "title": "c-4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 253,
                "width": 587
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "c5008814-a2bc-429d-9e40-07535e6bcf70",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-5_9346d8cb-0ae8-4480-967a-e0d029057206.png?v=1657198184",
                "id": "gid://shopify/MediaImage/30813718216961",
                "title": "c-5.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 253,
                "width": 587
            },
            "appmakerAction": {
                "action": "OPEN_COLLECTION"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                }
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "28fc1b91-7df6-47a9-8844-ba3f3459a35c",
    "name": "appmaker/shopify-prebuild-product-scroller",
    "isValid": true,
    "attributes": {
        "title": "",
        "ctaText": "View more",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "config": {
            "type": "PRODUCTS_BY_COLLECTION",
            "collectionId": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ==",
            "title": "Makeyup"
        },
        "___internal": {
            "type": {
                "value": "PRODUCTS_BY_COLLECTION",
                "label": "Products by collection"
            },
            "collectionId": {
                "label": "Makeyup",
                "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
            },
            "actionParams": {
                "label": "Makeyup",
                "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
            }
        }
    },
    "innerBlocks": []
}, {
    "clientId": "b5b275b8-5e00-4cac-9088-33891e55304b",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z.png?v=1657198513",
            "id": "gid://shopify/MediaImage/30813796303105",
            "title": "z.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "f000d3d3-67f0-41bd-9eb4-3b0136671bcb",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 2,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "d3749037-34a1-49d8-b26b-ce8a12e6f2aa",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_dfbf7d8a-85ce-4c9a-8948-15997ab6a348.png?v=1657198615",
                "id": "gid://shopify/MediaImage/30813822255361",
                "title": "f.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "showText": false,
            "appmakerAction": {
                "action": "OPEN_COLLECTION",
                "collectionId": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ==",
                "title": "Makeyup"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                },
                "collectionId": {
                    "label": "Makeyup",
                    "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
                },
                "actionParams": {
                    "label": "Makeyup",
                    "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "2b9c12c0-97a4-4d7d-81bb-c94a0df40353",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2_4a643697-ab17-418e-8b30-8630d31f2ee6.png?v=1657198616",
                "id": "gid://shopify/MediaImage/30813822451969",
                "title": "f-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "showText": false,
            "appmakerAction": {
                "action": "OPEN_URL"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_URL",
                    "label": "Open URL"
                }
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "b41ffe13-4726-4cd9-8959-e38734c2d016",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_URL"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_URL",
                "label": "Open URL"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/k.png?v=1657198481",
            "id": "gid://shopify/MediaImage/30813788373249",
            "title": "k.png"
        },
        "imageContainerStyle": {
            "height": 504,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 504,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "8ac6f7bd-8671-4dd1-8a3e-27728cb19355",
    "name": "appmaker/shopify-prebuild-product-scroller",
    "isValid": true,
    "attributes": {
        "title": "",
        "ctaText": "View more",
        "showViewMoreButton": false,
        "showTitle": false,
        "config": {
            "type": "PRODUCTS_BY_COLLECTION",
            "collectionId": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ==",
            "title": "Makeyup"
        },
        "___internal": {
            "type": {
                "value": "PRODUCTS_BY_COLLECTION",
                "label": "Products by collection"
            },
            "collectionId": {
                "label": "Makeyup",
                "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
            },
            "actionParams": {
                "label": "Makeyup",
                "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
            }
        },
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": []
}, {
    "clientId": "96d15fb7-5e3b-4ab9-b0f4-fece2a80875e",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "3e282b0a-eac1-4bca-b83c-522055f7fbed",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e_f97026cd-944f-4d5e-b9e3-013487917525.png?v=1657198435",
                "id": "gid://shopify/MediaImage/30813774643457",
                "title": "e.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 222,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT",
                    "label": "Open single Product"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "76dfa8f0-b843-433f-90fe-9f593fa7ed1f",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-1_42d24904-e102-4c41-8a93-8c226468ae8e.png?v=1657198434",
                "id": "gid://shopify/MediaImage/30813774479617",
                "title": "e-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 222,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_COLLECTION"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "42d2c494-5cdd-452a-bbda-920c6b24d1a2",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-2_6593cc0e-104b-45cc-8132-a3774c45c262.png?v=1657198432",
                "id": "gid://shopify/MediaImage/30813773824257",
                "title": "e-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 222,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_SEARCH"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_SEARCH",
                    "label": "Open search page"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "6cc9a645-2db4-4e24-b017-81ab738fdcdf",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-4_345dfcec-252d-4157-9833-62762eab4eff.png?v=1657198431",
                "id": "gid://shopify/MediaImage/30813773725953",
                "title": "e-4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 222,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_COLLECTION"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "11cc73cf-49df-40c4-be44-53d33d3c95ba",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-3_cf858c08-7e70-422d-a6e1-b023eb2e2979.png?v=1657198432",
                "id": "gid://shopify/MediaImage/30813773922561",
                "title": "e-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 222,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "e378c5fe-81f4-4f9e-8efe-ab6790af2605",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z-1.png?v=1657198514",
            "id": "gid://shopify/MediaImage/30813796401409",
            "title": "z-1.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "7c02f00c-2cf1-4e0c-8325-6ed0003772d4",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "customImageWidth": true,
        "parentWidth": "195"
    },
    "innerBlocks": [{
        "clientId": "8205903c-f07b-4192-a56f-afa708b4bff8",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j_e427c64a-3827-4f18-bb6e-4ad869e21301.png?v=1657281544",
                "id": "gid://shopify/MediaImage/30822576652545",
                "title": "j.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 860,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_COLLECTION",
                "collectionId": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ==",
                "title": "Makeyup"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                },
                "collectionId": {
                    "label": "Makeyup",
                    "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
                },
                "actionParams": {
                    "label": "Makeyup",
                    "value": "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzM5MjA3NzI0NjcyMQ=="
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "aa7abdbb-4d3d-4dca-ba19-d5fb3283f64a",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-1_42cb453f-ba45-4bdb-8ae3-b9a30b0c26de.png?v=1657281543",
                "id": "gid://shopify/MediaImage/30822576619777",
                "title": "j-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 860,
                "width": 540
            },
            "appmakerAction": {
                "action": "OPEN_PRODUCT_LIST"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT_LIST",
                    "label": "Open all products"
                }
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "c47d4a92-f5b9-40f6-9da6-67dd2dd58615",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_PRODUCT"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_PRODUCT",
                "label": "Open single Product"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/l.png?v=1657198480",
            "id": "gid://shopify/MediaImage/30813788045569",
            "title": "l.png"
        },
        "imageContainerStyle": {
            "height": 504,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 504,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "5b47de22-122a-42f1-b9a2-47b74d3b0328",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_SEARCH"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_SEARCH",
                "label": "Open search page"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/n.png?v=1657198415",
            "id": "gid://shopify/MediaImage/30813770416385",
            "title": "n.png"
        },
        "imageContainerStyle": {
            "height": 407,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 407,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "db8b5efa-1225-44c3-b53d-9491010cd9dc",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 2,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "fb39c3f3-42ff-4892-94ea-047565a177eb",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-3_afeff69b-4c9a-4df2-9a72-4e2254288a96.png?v=1657198308",
                "id": "gid://shopify/MediaImage/30813742989569",
                "title": "f-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "showText": false,
            "appmakerAction": {
                "action": "OPEN_COLLECTION"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_COLLECTION",
                    "label": "Open Collection"
                }
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "b3036f38-a2f1-4c4a-9474-42ad0f93e938",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1_6ae05b6a-21ea-4477-b495-c39cac1805b1.png?v=1657198308",
                "id": "gid://shopify/MediaImage/30813743350017",
                "title": "f-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 540,
                "width": 540
            },
            "showText": false,
            "appmakerAction": {
                "action": "OPEN_PRODUCT"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_PRODUCT",
                    "label": "Open single Product"
                }
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "76f2fe61-1581-410b-ae51-734befa2f575",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_PRODUCT"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_PRODUCT",
                "label": "Open single Product"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_2f26c6cb-3b91-4715-bff1-4f3939b305b5.png?v=1657198456",
            "id": "gid://shopify/MediaImage/30813780607233",
            "title": "g.png"
        },
        "imageContainerStyle": {
            "height": 368,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 368,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "5ba4c82f-6b97-4800-8b11-6e77c1290253",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_PRODUCT"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_PRODUCT",
                "label": "Open single Product"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z-2.png?v=1657198517",
            "id": "gid://shopify/MediaImage/30813797318913",
            "title": "z-2.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "5ad18c3b-f132-493c-99cf-a12929c07552",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_PRODUCT"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_PRODUCT",
                "label": "Open single Product"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h_d3033625-3b38-4fee-9cbb-248730936076.png?v=1657198456",
            "id": "gid://shopify/MediaImage/30813780640001",
            "title": "h.png"
        },
        "imageContainerStyle": {
            "height": 522,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 522,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "519fb749-c4a4-474e-9c21-bb018283a739",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_PRODUCT"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_PRODUCT",
                "label": "Open single Product"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_827c495f-1751-4c04-be20-c842bd537ea2.png?v=1657198759",
            "id": "gid://shopify/MediaImage/30813854597377",
            "title": "g.png"
        },
        "imageContainerStyle": {
            "height": 368,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 368,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "827458c6-d405-4548-9597-3d7b1aacc581",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_SEARCH"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_SEARCH",
                "label": "Open search page"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i_6c455361-776e-4950-847a-fcd1c294c102.png?v=1657198459",
            "id": "gid://shopify/MediaImage/30813781852417",
            "title": "i.png"
        },
        "imageContainerStyle": {
            "height": 1549,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 1549,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "ab64cdb2-5b8a-45e9-b108-e45d229b0540",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {
            "action": "OPEN_COLLECTION"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_COLLECTION",
                "label": "Open Collection"
            }
        },
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/m.png?v=1657198482",
            "id": "gid://shopify/MediaImage/30813789159681",
            "title": "m.png"
        },
        "imageContainerStyle": {
            "height": 504,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 504,
            "width": 1080
        }
    },
    "innerBlocks": []
}]
// function generate() {

// }
const template = {
    blocks,
    id: 'cosmetics-1',
    name: 'Glamour',
    category: 'Cosmetics',
    price: 'Free',
    imageSrc: '/images/themes/cosmetics.png',
    previewSrc: '/images/preview/templates/cosmetics.png',
    imageAlt: 'Mobile app cosmetics theme',
    description: "Our Cosmetics template is a great step when you are starting out to build your own app! Everything you need for your app to look like any other leading cosmetics app out there! just replace the images and banners and you are already on your way!"
};
export default template;
