import appmaker from '@appmaker/core/index';
import ProductQualityBSC from './product-quality-bsc';
import ProductQualityCardBsc from './product-quality-bsc/product-quality-card';
import ProductQualityIcon from './product-quality-bsc/Icon';

const getBlocks = (name) => {
    switch (name) {
        case 'appmaker/bsc-product-quality-block': {
            appmaker.registerAppmakerBlockType('appmaker/bsc-product-quality-block', {
                title: 'Product Quality',
                Block: ProductQualityBSC,
                icon: ProductQualityIcon
            });
            appmaker.registerAppmakerBlockType('appmaker/bsc-product-quality-card', {
                title: 'Product quality card',
                Block: ProductQualityCardBsc,
                parent: ['appmaker/bsc-product-quality-block']
            })
            break;
        }
        default:
            break;
    }

}

export {
    getBlocks
}
