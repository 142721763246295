/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import debounce from 'lodash/debounce';
import TempFixDeSelector from 'packages/MediaLibrary/components/MediaUpload/TempFixDeSelector';
import { useMediaStore } from './store/context';

export default function Modal({ title, children, onSelect, multiple }) {
	const open = useMediaStore((store) => store.modalVisible);
	const setOpen = useMediaStore((store) => store.setModalVisible);
	const searchText = useMediaStore((store) => store.searchText);
	const setSearchText = useMediaStore((store) => store.setSearchText);

	const selectedFile = useMediaStore((store) => store.selectedFile);
	function selectFile() {
		onSelect(selectedFile);
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				onClose={setOpen}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:m-16 sm:w-1/2 w-full">
							<div className="bg-white">
								<div className="mt-3 text-center sm:mt-0 sm:text-left bg-white">
									<div className="flex sm:items-center justify-between bg-gray-50 p-4 flex-col sm:flex-row items-start gap-2">
										<div>
											<Dialog.Title
												as="h3"
												className="text-lg leading-6 font-medium text-gray-900 "
											>
												{title}
											</Dialog.Title>
											<Dialog.Description
												as="small"
												className="text-sm text-gray-500"
											>
												Select or upload media - {searchText}
											</Dialog.Description>
										</div>
										<div className="max-w-lg w-full lg:max-w-xs">
											<label htmlFor="search" className="sr-only">
												Search
											</label>
											<div className="relative text-gray-400 focus-within:text-gray-600">
												<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
													<SearchIcon className="h-5 w-5" aria-hidden="true" />
												</div>
												<input
													id="search"
													className="block w-full bg-white py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
													placeholder="Search"
													type="search"
													name="search"
													value={searchText}
													onChange={debounce(function (e) {
														setSearchText(e.target.value);
														// console.log('Debounced Event:', e);
													}, 1000)}
													// onChange={e => setSearchText(e.target.value)}
												/>
											</div>
										</div>
									</div>
									<div className="px-4 py-4">
										<TempFixDeSelector />

										{children}
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => {
										selectFile();
										setOpen(false);
									}}
								>
									Select
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => setOpen(false)}
								>
									Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
