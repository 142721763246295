
import { setLanguageValue,setLoaderForFetchLanguage } from '../store/formData';
import coreApi from '@appmaker/core/api/core';
/**
 * 
 * @param {*} projectId 
 * @param {*} languageId 
 * @param {*} setLoading 
 */
export const setLanguageToDefault = async(projectId,languageId,setLoading=()=>{})=>{
    try {
        setLoading(true);
        setLoaderForFetchLanguage(true);
        const response = await coreApi.getDefaultLanguageValues(projectId, languageId);
        if (response.status) {
            setLanguageValue(languageId, response.body || {});
        }
    } catch (error) {
    } finally {
        setLoading(false);
        setLoaderForFetchLanguage(false);
    }
}

export const saveLanguage = async (save=()=>{},setLoading=()=>{})=>{
    try {
        setLoading(true);
        await save();
    } catch (error) {
        
    }finally {
        setLoading(false);
    }
}
