import { ArrowRightIcon } from '@heroicons/react/outline';
import { BASE_URL } from '@appmaker/core/api/core'
import { Redirect } from '@shopify/app-bridge/actions';
import { getProjectData } from '@appmaker/core/store/project';

const AdditionalPermission = () => {

	function handleAcceptPermission() {
		const { projectData: { shopifyReauthRequired = false, meta: { URL = '' }, id } } = getProjectData();
		const redirectUrl = `${BASE_URL}/user/shopify/login?shop=${URL.replace('https://', '')}`;
		if (window.shopifyApp) {
			const redirect = Redirect.create(window.shopifyApp);
			redirect.dispatch(
				Redirect.Action.REMOTE,
				redirectUrl
			);
		} else {
			window.location.href = redirectUrl;
		}
	}

	return (
		<div className="h-screen bg-gray-50 flex flex-col items-center justify-center">
			<div className="mx-auto max-w-2xl bg-white border border-gray-100 rounded-lg shadow-lg overflow-hidden px-12 py-4">
				<div className="flex flex-col items-center p-4 text-center">
					<img
						height={128}
						width={128}
						src="/images/misc/permission-icon.png"
						alt="icon"
					/>
					<div className="mb-6">
						<h3 className="text-xl font-medium text-gray-900 mb-2">
							Additional Permission Required
						</h3>
						<p className="font-base text-base text-gray-500">
							{/* Text to ask user accept Additional Permissions from shopify  */}
							We need some extra permissions from Shopify to keep the mobile app and Appmaker dashboard running smoothly.
							Please accept these permissions from Shopify to continue.
						</p>
					</div>
					<button
						type="button"
						className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						onClick={handleAcceptPermission}
					>
						Accept Permissions
						<ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default AdditionalPermission;
