/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid';
import StepOne from './step-one';
import StepTwo from './step-two';
import { useState, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

const steps = [
	{ name: 'Step 1', id: 1, stepName: 'generate-access-key' },
	{ name: 'Step 2', id: 2, stepName: 'upload-p8' },
];

const StepCount = ({ currentStep, setQueryStep = () => { } }) => {
	return (
		<div className="py-8 px-4 sticky top-0">
			<nav className="flex justify-start" aria-label="Progress">
				<ol role="list" className="space-y-6">
					{steps.map((step) => (
						<li key={step.name}>
							{step.id < currentStep && (
								<button
									className="group"
									onClick={() => {
										setQueryStep(step.stepName)
									}}
								>
									<span className="flex items-start">
										<span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
											<CheckCircleIcon
												className="h-full w-full text-blue-600 group-hover:text-blue-800"
												aria-hidden="true"
											/>
										</span>
										<span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
											{step.name}
										</span>
									</span>
								</button>
							)}
							{step.id === currentStep && (
								<button
									className="flex items-start"
									aria-current="step"
									onClick={() => {
										setQueryStep(step.stepName)
									}}
								>
									<span
										className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
										aria-hidden="true"
									>
										<span className="absolute h-4 w-4 rounded-full bg-blue-200" />
										<span className="relative block w-2 h-2 bg-blue-600 rounded-full" />
									</span>
									<span className="ml-3 text-sm font-medium text-blue-600">
										{step.name}
									</span>
								</button>
							)}
							{step.id > currentStep && (
								<button
									onClick={() => {
										setQueryStep(step.stepName)
									}}
									className="group"
								>
									<div className="flex items-start">
										<div
											className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
											aria-hidden="true"
										>
											<div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
										</div>
										<p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
											{step.name}
										</p>
									</div>
								</button>
							)}
						</li>
					))}
				</ol>
			</nav>
		</div>
	);
}

const Steps = ({ step, otherProps = {} }) => {
	switch (step) {
		case 1: {
			return <StepOne />
		}
		case 2: {
			return <StepTwo {...otherProps} />
		}
		default: {
			return null
		}
	}

}
const AuthKeyUpload = ({ showIpaUpload }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [queryStep, setQuerySteps] = useQueryParam('step', StringParam);
	const nextStep = () => {
		const nextStep = steps.find(item => item.id === currentStep + 1);
		if (nextStep) {
			setQuerySteps(nextStep.stepName);
		} else {
			setQuerySteps(steps[steps.length - 1].stepName);
		}
	}
	const previousStep = () => {
		const previousStep = steps.find(item => item.id === currentStep - 1);
		if (previousStep) {
			setQuerySteps(previousStep.stepName)
		} else {
			setQuerySteps(steps[0].stepName);
		}
	}
	useEffect(() => {
		if (queryStep === undefined) {
			setQuerySteps(steps[0].stepName);
		} else {
			const currentStepInfo = steps.find(item => item.stepName === queryStep);
			if (currentStepInfo) {
				setCurrentStep(currentStepInfo.id)
			} else {
				setQuerySteps(steps[0].stepName);
			}
		}
	}, [queryStep])
	return (
		<div className="grid grid-cols-6 h-full">
			<div className="bg-gray-50 relative">
				<StepCount
					setQueryStep={setQuerySteps}
					currentStep={currentStep}
				/>
			</div>
			<div className="col-span-5">
				<div className="p-4">
					<Steps step={currentStep} otherProps={{ showIpaUpload }} />
				</div>
				<div className="bg-white border-t py-2 px-4 border-gray-100 flex items-center justify-end gap-4">
					{(currentStep > 1) && <button
						type="button"
						onClick={() => { previousStep() }}
						className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium text-blue-600 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					>
						Back
					</button>}
					{(currentStep < steps.length) && <button
						type="button"
						className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						onClick={() => { nextStep() }}
					>
						Next
					</button>}
				</div>
			</div>
		</div>
	);
}

export default AuthKeyUpload;


