import { HeartIcon, ShareIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

export default function ProductImage({ attributes }) {
	// console.log();
	let imagePath =
		'https://images.unsplash.com/photo-1581235720704-06d3acfcb36f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80';
	try {
		imagePath = attributes.imageList.node.images.edges[0].node.originalSrc;
	} catch (error) {}
	return (
		<div className="relative">
			<div
				className={clsx(
					'flex gap-2 absolute bottom-4',
					attributes.centerPagination == 1 ? 'justify-center w-full' : 'left-4'
				)}
			>
				<span
					className="h-2 w-4 bg-blue-500 block rounded-full"
					style={{
						backgroundColor: attributes.customDotColor
							? attributes.customDotColor
							: null,
					}}
				/>
				<span className="h-2 w-2 bg-gray-400 block rounded-full" />
				<span className="h-2 w-2 bg-gray-400 block rounded-full" />
				<span className="h-2 w-2 bg-gray-400 block rounded-full" />
			</div>
			<img
				src={imagePath}
				alt="product"
				className="w-full h-full object-contain"
			/>
			<div className="absolute right-4 bottom-4 flex flex-col gap-2">
				<HeartIcon className="h-10 w-10 bg-white p-2 rounded-full text-gray-600" />
				<ShareIcon className="h-10 w-10 bg-white p-2 rounded-full text-gray-600" />
			</div>
		</div>
	);
}

// ProductImage.blockData = {
// 	id: 'appmaker/product-image',
// 	title: 'product-image',
// 	icon: <DatabaseIcon />,
// };
ProductImage.attributesSchema = {
	type: 'object',
	properties: {
		imageList: {
			type: 'string',
			label: 'Image list',
			default: '',
		},
		index: {
			type: 'string',
			label: 'Index',
			default: '',
		},
		indexImage: {
			type: 'string',
			label: 'Index image',
			default: '',
		},
		title: {
			type: 'string',
			label: 'Title',
			default: '',
		},
		uri: {
			type: 'string',
			label: 'URI',
			default: '',
		},
		displayWishlist: {
			type: 'string',
			label: 'Display Wishlist',
			default: '',
		},
		savedItemIds: {
			type: 'string',
			label: 'Saved Items Id',
			default: '',
		},
		id: {
			type: 'string',
			label: 'Id',
			default: '',
		},
		customDotColor: {
			type: 'string',
			label: 'Custom Dot Color',
			default: '',
		},
		centerPagination: {
			type: 'string',
			label: 'Center Pagination',
			default: '',
			uiType: 'Switch',
		},
		// attributes: {
		// 	type: 'object',
		// 	properties: {
		// 		imageList: { type: 'string' },
		// 		indexImage: { type: 'string' },
		// 		title: { type: 'string' },
		// 		uri: { type: 'string' },
		// 		displayWishlist: { type: 'boolean' },
		// 	},
		// },
		// indexImage: '',
		// title: '',
		// uri: '',
		// displayWishlist: true,
	},
};
