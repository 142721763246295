const StepTwo = () => {
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			<li>
				Choose Default language and fill up the Title of your app.
				<img
					className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
					src="/images/publish/android/new/step-2-1.png"
					alt="step one"
				/>
			</li>
			<li>
			Complete the Content guidelines and US export laws declarations and click Create app
			<img
					className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
					src="/images/publish/android/new/step-2-2.png"
					alt="step one"
				/>
			</li>
		</ul>
	);
};

export default StepTwo;
