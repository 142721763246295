import { LinkIcon } from '@heroicons/react/outline';
import { RangeControl } from '@wordpress/components';
import clsx from 'clsx';
import React from 'react';

export default function BorderRadiusControl({ attributes, setAttributes }) {
	const [linked, setLinked] = React.useState(true);
	const {
		borderTopLeftRadius = 0,
		borderTopRightRadius = 0,
		borderBottomLeftRadius = 0,
		borderBottomRightRadius = 0,
	} = attributes;
	return (
		<div className="mt-4">
			<div className="flex items-center justify-between">
				<h4 className="text-sm mb-2 text-gray-800">Border Radius</h4>
				<button
					className={clsx(
						'p-1 border rounded',
						linked
							? 'text-blue-600 border-blue-600'
							: 'border-gray-400 text-gray-400'
					)}
					onClick={() => setLinked(!linked)}
				>
					<LinkIcon className="h-5 w-5 " />
				</button>
			</div>
			<div className="flex flex-col ml-2">
				{!linked ? (
					<>
						<RangeControl
							label="Top Left"
							value={borderTopLeftRadius}
							onChange={(borderTopLeftRadius) =>
								setAttributes({ borderTopLeftRadius })
							}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Top Right"
							value={borderTopRightRadius}
							onChange={(borderTopRightRadius) =>
								setAttributes({ borderTopRightRadius })
							}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Bottom Left"
							value={borderBottomLeftRadius}
							onChange={(borderBottomLeftRadius) =>
								setAttributes({ borderBottomLeftRadius })
							}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Bottom Right"
							value={borderBottomRightRadius}
							onChange={(borderBottomRightRadius) =>
								setAttributes({ borderBottomRightRadius })
							}
							min={0}
							max={100}
						/>
					</>
				) : (
					<RangeControl
						className="mt-3"
						value={borderBottomRightRadius}
						onChange={(borderTopLeftRadius) =>
							setAttributes({
								borderTopLeftRadius,
								borderTopRightRadius: borderTopLeftRadius,
								borderBottomLeftRadius: borderTopLeftRadius,
								borderBottomRightRadius: borderTopLeftRadius,
							})
						}
						min={0}
						max={100}
					/>
				)}
			</div>
		</div>
	);
}
