const LoadingIcon = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="currentColor"
			className={className}
		>
			<path
				d="M12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
				fillOpacity="0.2"
			/>
			<path d="M19.0081 12C19.5559 12 20.0064 12.4458 19.9386 12.9894C19.765 14.3826 19.2268 15.7118 18.3706 16.839C17.3134 18.2308 15.8296 19.238 14.146 19.7068C12.4623 20.1756 10.6715 20.0802 9.04719 19.4351C7.4229 18.79 6.05454 17.6308 5.15129 16.1346C4.24803 14.6384 3.85958 12.8876 4.04531 11.1498C4.23104 9.41204 4.98074 7.78287 6.1798 6.51138C7.37885 5.23988 8.9613 4.39602 10.6852 4.10879C12.0815 3.87614 13.5085 4.01904 14.8222 4.51431C15.3347 4.70756 15.5194 5.31378 15.2642 5.79853C15.0091 6.28328 14.4105 6.46065 13.8905 6.28852C12.9677 5.9831 11.9797 5.90424 11.0113 6.06559C9.71481 6.2816 8.52477 6.91621 7.62305 7.8724C6.72132 8.8286 6.15753 10.0538 6.01786 11.3607C5.87818 12.6675 6.17031 13.9842 6.84958 15.1094C7.52885 16.2345 8.5579 17.1063 9.77941 17.5914C11.0009 18.0765 12.3477 18.1483 13.6138 17.7957C14.88 17.4432 15.9958 16.6857 16.7908 15.6391C17.3847 14.8573 17.7751 13.9462 17.9347 12.9874C18.0246 12.4471 18.4603 12 19.0081 12Z" />
		</svg>
	);
};

export default LoadingIcon;
