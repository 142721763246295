const Select = ({ label, options, defaultValue, onChange, id }) => {
	return (
		<div className="my-2">
			<label className="block text-sm font-base text-gray-700" htmlFor={id}>
				{label}
			</label>
			<select
				id={id}
				className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
				value={defaultValue}
				onChange={onChange}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

export default Select;
