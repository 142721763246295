import { useStore } from '@appmaker/core/components/AppmakerForm/store';
import { getProjectData } from '@appmaker/core/store/project';

const PushPreview = ({ image = 'https://picsum.photos/200' }) => {
	const { loading, projectData } = getProjectData();
	const {
		icon = 'https://appilder_cdn.storage.googleapis.com/app_files/8880de6ab31130ef850f3377a4f2d4f2/1630999479181-appicon.png_150x150.png',
	} = projectData || {};
	const formData = useStore((store) => store.formData);
	return (
		<div className="relative flex-1 p-6 bg-gradient-to-br from-gray-700 via-gray-900 to-black rounded-md flex flex-col justify-center w-full mt-4 h-96">
			<div className="bg-white bg-opacity-80 rounded-2xl backdrop-filter backdrop-blur-xl flex items-start px-2 ">
				{/* app icon */}
				<img className="h-16 w-16 mt-3" src={icon} alt="" />
				<div className="px-3 py-2 flex-auto">
					<div className="flex justify-between items-center mb-1">
						<div className="text-md font-medium">
							{formData.title ? formData.title : 'Awesome Deal is here!!'}
						</div>
						<div className="text-xs text-gray-600">Now</div>
					</div>
					<div className="flex items-start flex-auto gap-2">
						<p className="text-sm line-clamp-4 break-all flex-auto">
							{formData.message
								? formData.message
								: `Lorem ipsum dolor sit, amet consectetur adipisicing elit.
						Sint nostrum tempore earum? Odio distinctio porro libero
						mollitia iusto repellendus, officia praesentium et. Optio
						tenetur eaque alias explicabo, ipsum dolorum porro?`}
						</p>
						{/* push notification image */}
						{image && (
							<img
								className="object-cover pointer-events-none h-12 w-12 rounded-lg"
								src={image}
								alt=""
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PushPreview;
