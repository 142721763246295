import { useState, useCallback, useEffect } from 'react'
import { productWithVariant, productWithId } from '@appmaker/shopify/api';
import Select from './Select';
import Loader from '@appmaker/components/Loading';
import debounce from 'lodash/debounce';
import {
    ClipboardCopyIcon,
} from '@heroicons/react/outline';

const getOptions = async (productQuery, variantQuery) => {
    try {
        const response = await productWithVariant({ search: productQuery, variant: variantQuery });
        return Promise.resolve(response);
    } catch (error) {
        return Promise.resolve([])
    }
}
const getProduct = async (productId) => {
    try {
        const response = await productWithId({ productId });
        if (response && response.variants) {
            return Promise.resolve(response.variants)
        }
        return Promise.resolve([]);
    } catch (error) {
        return Promise.resolve([])
    }
}
const ProductList = (props) => {
    const {
        onChange = () => { },
        defaultValue
    } = props || {};
    const [isLoading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const searchProduct = useCallback(debounce(async (query) => {
        const response = await getOptions(query && query.length !== 0 ? query : undefined);
        setOptions(response);
    }, 500),[])
    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await getOptions();
            if (response && defaultValue && !response.find(item => defaultValue && item.id === defaultValue)) {
                try {
                    const productData = await productWithId({ productId: defaultValue });
                    if (productData) {
                        setOptions([...response, { ...productData, label: productData.title }]);
                    }
                } catch (error) {

                }
            } else {
                setOptions(response);
            }

            setLoading(false);
        })()
    }, []);

    return (
        <div>
            {!isLoading ? (
                <Select
                    label='Select the product'
                    value={options.find(item => defaultValue && item.id === defaultValue)}
                    onChange={(value) => {
                        if (value && value.id) {
                            onChange(value.id)
                        }

                    }}
                    onSearch={searchProduct}
                    options={options}
                />
            ) : (
                <Loader single small fullWidth />
            )}
        </div>
    )
}

const VariantList = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [original, setOriginal] = useState([])
    const [options, setOptions] = useState([]);
    const {
        store: {
            formData = {}
        } = {},
        properties = {},
        defaultValue,
        onChange = () => { }
    } = props;
    const { dependentErrorMessage = "Please choose the value" } = properties
    const parentKey = properties.parentKey

    useEffect(() => {
        if (formData[parentKey]) {
            (async () => {
                setLoading(true);
                try {
                    const response = await getProduct(formData[parentKey]);
                    setOptions(response || []);
                    setOriginal(response || []);
                    setLoading(false)
                } catch (error) {

                }
            })()
        }
    }, [formData[parentKey]])
    if (!formData[parentKey]) {
        return <div className='px-4 py-2 bg-purple-50 text-purple-700 text-center rounded-lg'>{dependentErrorMessage}</div>
    }
    return <div>
        {!isLoading ? (
            <Select
                label='Choose product variant'
                value={options.find(item => defaultValue && item.id === defaultValue)}
                onChange={(value) => {
                    if (value && value.id) {
                        onChange(value.id)
                    }
                }}
                onSearch={() => { }}
                options={options}
            />
        ) : (
            <Loader single small fullWidth />
        )}
    </div>
}

const CustomProductVariant = (orgProps) => {
    const { onChange, defaultValue, properties = {} } = orgProps;
    const { showProduct = true } = properties;
    const [isCopyingProduct, setCopyingProduct] = useState(false);
    const [isCopyingVariant, setCopyingVariant] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [productId, setProductId] = useState(undefined);
    const [productVariant, setProductVariant] = useState(undefined);
    const [variantId, setVariantId] = useState(undefined);
    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await getOptions();
            setOptions(response);
            setLoading(false);
        })()
    }, []);

    useEffect(() => {
        if (isCopyingProduct) {
            setTimeout(() => {
                setCopyingProduct(false);
            }, 3000)
        }
        if (isCopyingVariant) {
            setTimeout(() => {
                setCopyingVariant(false);
            }, 3000)
        }
    }, [
        isCopyingProduct,
        isCopyingVariant,
    ])

    const searchProduct = useCallback(debounce(async (query, callback) => {
        const response = await getOptions(query && query.length !== 0 ? query : undefined);
        setOptions(response);
    }, 500), [])
    return (
        <div className='mt-2 mb-2'>
            <label>Search product and variant here</label>
            {!isLoading ? (<>
                <Select
                    label='Select the product'
                    value={options.find(item => defaultValue && item.id === defaultValue.product_gid)}
                    onChange={(value) => {
                        setProductId(value.id);
                        setProductVariant(value.variants);
                        // onChange({
                        //     product_gid: value.id,
                        //     variant_gid: undefined
                        // })
                    }}
                    onSearch={searchProduct}
                    options={options}
                />
                {productId && (<div className='flex flex-row'>
                    <div className='mt-2 font-bold'>
                        <pre>{productId}</pre>
                    </div>
                    <button
                        onClick={() => {
                            setCopyingProduct(true);
                            navigator.clipboard.writeText(productId);
                        }}
                        className="px-4 py-2 rounded bg-slate-800 bg-slate-700 hover:bg-opacity-90 text-black  inset-y-0 right-0 rounded-r-md inline-flex items-center justify-center space-x-2">
                        <ClipboardCopyIcon className="h-5 w-5" />
                        <span>{isCopyingProduct ? 'Copied to clipboard' : 'Copy to clipboard'}</span>
                    </button>
                </div>)}
                {productId && productVariant ?
                    <>
                        <Select
                            value={productVariant.find(item => defaultValue && item.id === defaultValue.variant_gid)}
                            label='Select the variant'
                            options={productVariant.map(item => {
                                return {
                                    label: item.title,
                                    id: item.id,
                                    value: item.id,
                                    imageUrl: item.imageUrl
                                }
                            })}
                            onChange={(value) => {
                                setVariantId(value.id);
                                // onChange({
                                //     product_gid: productId,
                                //     variant_gid: value.id
                                // })
                            }}
                        />
                        {variantId && (<div className='flex flex-row'>

                            <div className='mt-2 font-bold'><pre>{variantId}</pre></div>
                            <button
                                onClick={() => {
                                    setCopyingVariant(true);
                                    navigator.clipboard.writeText(variantId);
                                }}
                                className="px-4 py-2 rounded bg-slate-800 bg-slate-700 hover:bg-opacity-90 text-black  inset-y-0 right-0 rounded-r-md inline-flex items-center justify-center space-x-2">
                                <ClipboardCopyIcon className="h-5 w-5" />
                                <span>{isCopyingVariant ? 'Copied to clipboard' : 'Copy to clipboard'}</span>
                            </button>
                        </div>)}
                    </>
                    : null}
            </>) : (<Loader single />)
            }
        </div>
    )
}

const CustomViewer = (orgProps) => {
    const { onChange, defaultValue, properties = {} } = orgProps;
    const { showProductOnly = false, search: searchProduct = false, showVariantOnly = false } = properties;
    if (showProductOnly) return <ProductList {...orgProps} />
    if (showVariantOnly) return <VariantList {...orgProps} />
    if (searchProduct)
        return <CustomProductVariant {...orgProps} />
    return null
}
export default CustomViewer;