
import appmaker from '@appmaker/core/index';
import Subscription from './routes/Subscription';
import { CreditCardIcon } from '@heroicons/react/solid';
import Banner from './components/Banner'

const Plugin = {
    id: '@appmaker/subscription',
    name: 'Subscription',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/subscription',
            component: Subscription,
        });
        /**
         * Menu Items
         */
        appmaker.registerSideBarMenu({
            name: 'Subscription',
            routeName: '/subscription',
            icon: CreditCardIcon,
        });
        appmaker.addFilter('dashboard-header-menu', 'shopify-templates', (item) => <>{item} <Banner /></>, 1)

    },
};

export default Plugin;
