import React, { useEffect, useState } from 'react';
import {
	useSimpleBlockStore,
	SimpleBlockEditorProvider,
	createStore,
} from './store';
import {
	PlusCircleIcon,
	DatabaseIcon,
	PlusIcon,
	ViewGridAddIcon,
} from '@heroicons/react/outline';

const InsertBlock = ({
	newText = 'Add Block',
	defaultBlock = {
		name: 'appmaker/toolbar-icon',
		attributes: {},
	},
}) => {
	const insertBlock = useSimpleBlockStore((state) => state.insertBlock);

	return (
		<button
			className="flex items-center space-x-1 p-2 text-gray-900 bg-gray-50 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100"
			onClick={(e) => {
				insertBlock(defaultBlock);
			}}
		>
			<PlusCircleIcon className="h-4 w-4" />
			<span>{newText}</span>
		</button>
	);
};

const EmptyBlock = ({
	title = 'No Icons',
	description = 'Add tool bar icons',
	defaultBlock = {
		name: 'appmaker/toolbar-icon',
		attributes: {},
	},
	buttonText = 'Add',
	IconName = ViewGridAddIcon,
}) => {
	const insertBlock = useSimpleBlockStore((state) => state.insertBlock);
	return (
		<div className="text-center px-2 py-6 bg-gray-100 rounded-2xl shadow-inner overflow-hidden">
			<IconName className="mx-auto h-12 w-12 text-gray-400 stroke-1" />
			<h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
			<p className="mt-1 text-sm text-gray-500">{description}</p>
			<div className="mt-6">
				<button
					type="button"
					className="inline-flex items-center px-4 py-2 border border-transparent shadow-2xl text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					onClick={(e) => {
						insertBlock(defaultBlock);
					}}
				>
					<PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
					{buttonText}
				</button>
			</div>
		</div>
	);
};

const BlockEditor = ({
	components = {},
	insertBlockAttributes,
	emptyBlockAttributes,
}) => {
	const blocks = useSimpleBlockStore((state) => state.blocks);
	const setBlockAttributes = useSimpleBlockStore(
		(state) => state.setBlockAttributes
	);
	return (
		<div>
			{blocks.map((item) => {
				const { attributes, clientId } = item;
				const Component = components[item.name] || 'div';
				// console.log(Component,item.name);
				const setAttributes = (attributes) =>
					setBlockAttributes(clientId, attributes);
				return (
					<Component
						clientId={clientId}
						key={clientId}
						attributes={attributes}
						setAttributes={setAttributes}
					/>
				);
			})}
			{/* <InsertBlock {...insertBlockAttributes} /> */}
			{blocks.length === 0 ? (
				<EmptyBlock {...emptyBlockAttributes} />
			) : (
				<InsertBlock {...insertBlockAttributes} />
			)}
		</div>
	);
};
function JSONState() {
	const store = useSimpleBlockStore();
	return <pre>{JSON.stringify(store.blocks, null, 2)}</pre>;
}
function SyncBlocks({ onChange }) {
	const state = useSimpleBlockStore((state) => state);
	useEffect(() => {
		onChange && onChange(state)
	}, [state])
	return null;
}
function SimpleBlockEditor({ onChange, blocks= [ ], ...props }) {
	return (
		<SimpleBlockEditorProvider createStore={createStore({ blocks })}>
			<BlockEditor {...props} />
			<SyncBlocks onChange={onChange} />
			{props.debug && <JSONState />}
		</SimpleBlockEditorProvider>
	);
}
export default SimpleBlockEditor;
