import { InformationCircleIcon } from '@heroicons/react/outline';

const ExtraPaymentInfo = ({ attributes = {} }) => {
	const { text, imageSrc, extraText } = attributes;
	return (
		<div className="bg-white px-3">
			<div className="flex items-center flex-wrap space-x-1">
				<p className="text-sm text-gray-800">{text}</p>
				{imageSrc && <img src={imageSrc} className="h-4" alt="" />}
				<InformationCircleIcon className="h-3 w-3" />
			</div>
			{extraText && <p className="text-xs text-gray-500 mt-1">{extraText}</p>}
		</div>
	);
};

ExtraPaymentInfo.attributesSchema = {
	type: 'object',
	properties: {
		text: { type: 'string', label: 'Text', default: '' },
		imageSrc: { type: 'string', label: 'Image Source', default: '' },
		extraText: { type: 'string', label: 'Extra Text', default: '' },
	},
};

export default ExtraPaymentInfo;
