import { useEffect, useState } from 'react';
import PluginInstallInstructions from '../../components/InstallPlugin/PluginInstallInstructions';
import PluginConfigureForm from '../../components/InstallPlugin/PluginConfigureForm';
import ContactSupport from '../../components/ContactSupport';

const delay = 60;

const ConfigurePlugin = ({ basePath }) => {
	const [show, setShow] = useState(false);

	useEffect(
		() => {
			let timer1 = setTimeout(() => setShow(true), delay * 1000);

			// this will clear Timeout
			// when component unmount like in willComponentUnmount
			// and show will not change to true
			return () => {
				clearTimeout(timer1);
			};
		},
		// useEffect will run only one time with empty []
		// if you pass a value to array,
		// like this - [data]
		// than clearTimeout will run every time
		// this value changes (useEffect re-run)
		[]
	);
	return (
		<div className="pt-4">
			<nav className="mb-4 block" aria-label="Progress">
				<ol
					role="status"
					className="flex flex-1 space-x-4 w-full bg-gray-50 divide-x divide-dashed divide-gray-200 rounded-lg p-2"
				>
					{/* <li className="p-2 flex-1">
						<PluginInstallInstructions />
					</li> */}
					<li className="p-2">
						<PluginConfigureForm />
					</li>
				</ol>
			</nav>
			{show && <ContactSupport />}
		</div>
	);
};

export default ConfigurePlugin;
