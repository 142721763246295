
import appmaker from '@appmaker/core/index';
import PluginRoute from './routes/PluginRoute';
import { LinkIcon } from '@heroicons/react/solid';
import OnBoardingProgress from './components/OnBoardingProgress'
const Plugin = {
    id: '@appmaker/onboarding',
    name: 'Onboarding Flow',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/onboarding',
            component: PluginRoute,
        });
        // /**
        //  * Menu Items
        //  */
        // appmaker.registerSideBarMenu({
        //     name: 'Onboarding Flow',
        //     routeName: '/onboarding',
        //     icon: LinkIcon,
        // });
        // appmaker.addFilter('dashboard-header-content', 'onboarding-flow', (item) => <>{item} <OnBoardingProgress /></>)

    },
};

export default Plugin;
