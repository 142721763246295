import {
	Switch,
	Route,
	useRouteMatch,
	generatePath,
	useLocation,
} from '@appmaker/core/routes';
import TabHeader, { StepItem } from '../components/RouterTab/TabHeader';
import InstallPlugin from './tabs/InstallPlugin';
import PluginStatus from './tabs/PluginStatus';
import ContentBlock from '../components/ContentBlock';
import { Notify } from '@appmaker/core/';
import React from 'react';
import ConfigurePlugin from './tabs/ConfigurePlugin';
import { Provider, createStore } from '../store/pluginCheck'
import { useProject } from '@appmaker/core/store/project';

window.Notify = Notify;
const ConnectWebsite = () => {
	const { projectData, projectId } = useProject()
	const { meta: { URL } } = projectData;
	const { pathname } = useLocation();
	const { path, params } = useRouteMatch();
	function generateLink(pathitem) {
		return `${generatePath(path, params)}/${pathitem}`;
	}
	const stepsOrder = ['', 'connect', 'verification'];
	function stepStatus(step) {
		if (pathname === generateLink(step)) return 'current';
		else if (stepsOrder.indexOf(step) === 0) return 'complete';
		return '';
	}
	React.useEffect(() => {
		// Notify.success('DataSource Created');
	}, []);
	return (
		<Provider createStore={createStore({ attempts: 0, url: URL, projectId })}>
			<ContentBlock>
				<TabHeader>
					<StepItem
						step={{ name: 'Install Plugin', id: 1 }}
						path={generateLink('')}
						finalStep={false}
						status={stepStatus('')}
					/>
					<StepItem
						step={{ name: 'Connect Website', id: 2 }}
						path={generateLink('connect')}
						finalStep={false}
						status={stepStatus('connect')}
					/>
					<StepItem
						step={{ name: 'Verification', id: 3 }}
						path={generateLink('verification')}
						finalStep={true}
						status={stepStatus('verification')}
					/>
				</TabHeader>
				<div>
					<div className="tabs">
						<Switch>
							<Route path={`${path}`} exact component={InstallPlugin} />
							<Route path={`${path}/connect`} component={ConfigurePlugin} />
							<Route path={`${path}/verification`} component={PluginStatus} />
						</Switch>
					</div>
				</div>
			</ContentBlock>
		</Provider>
	);
};

export default ConnectWebsite;
