import { useQuery } from '@tanstack/react-query';
import { getProjectData } from '@appmaker/core/store/project';

import axios from 'axios';
const fetcher = async (
	{ shopDomain, SHOPIFY_ACCESS_TOKEN },
	graphQLParams,
	header
) => {
	const endPoint = `${shopDomain}/api/2024-07/graphql.json`;
	try {
		const response = await axios.post(endPoint, JSON.stringify(graphQLParams), {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...header?.headers,
				'x-shopify-storefront-access-token': SHOPIFY_ACCESS_TOKEN,
			},
		});
		return response.data;
	} catch (error) {}
	return null;
};
export function useStorefrontApi({ query, variables }) {
	const { loading, projectData } = getProjectData();

	const { SHOPIFY_ACCESS_TOKEN, URL } = projectData.meta || {};
	const { data, error, isLoading, isError, isSuccess } = useQuery(
		['storefront', query, variables],
		async () => {
			const response = await fetcher(
				{ shopDomain: URL, SHOPIFY_ACCESS_TOKEN },
				{ query, variables }
			);
			return response?.data;
		}
	);
	return {
		data,
		error,
		isLoading,
		isError,
		isSuccess,
	};
}
