import Pages from "./pages";
import appmaker from '@appmaker/core/index';
import Menu from "./pages/menu";
const AppmakerPLayground = {
    id: 'appmaker-playground',
    name: 'Appmaker Playground',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/in-app-pages',
            component: Pages,
        });
        appmaker.registerRoute({
            routeName: '/menu',
            component: Menu ,
        });
    },
};

export default AppmakerPLayground;
