
import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import { useFormData, setFormData } from '../../../store';
const steps = [
	{ name: 'Step 1', href: '#', status: 'complete' },
	{ name: 'Step 2', href: '#', status: 'current' },
	{ name: 'Step 3', href: '#', status: 'upcoming' },
	{ name: 'Step 4', href: '#', status: 'upcoming' },
	{ name: 'Step 5', href: '#', status: 'upcoming' },
];

const StepCounter = () => {
	const formData = useFormData();
	const { stepsNeedBackButton = [], currentStep,isConfigureLoading } = formData;
	const showBackButton = stepsNeedBackButton.includes(currentStep);
	const goBack = () => {
		let newStepCount = currentStep <= 1 ? 1 : currentStep - 1
		setFormData({ ...formData, currentStep: newStepCount });
	}
	return (
		<div className="flex items-center justify-between p-4 bg-gray-50">
			{showBackButton &&
				(<button
					type="button"
					className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
					onClick={goBack}
					disabled={isConfigureLoading}
				>
					<ChevronDoubleLeftIcon
						className="-ml-1 mr-2 h-4 w-4"
						aria-hidden="true"
					/>
					Go Back
				</button>
				)
			}
			{/* {currentStep !== 1 && (
				<nav className="flex items-center justify-end" aria-label="Progress">
					<p className="text-sm font-medium">
						Step {steps.findIndex((step) => step.status === 'current') + 1} of{' '}
						{steps.length}
					</p>
					<ol role="list" className="ml-8 flex items-center space-x-5">
						{steps.map((step) => (
							<li key={step.name}>
								{step.status === 'complete' ? (
									<a
										href={step.href}
										className="block w-2.5 h-2.5 bg-blue-600 rounded-full hover:bg-blue-900"
									>
										<span className="sr-only">{step.name}</span>
									</a>
								) : step.status === 'current' ? (
									<a
										href={step.href}
										className="relative flex items-center justify-center"
										aria-current="step"
									>
										<span
											className="absolute w-5 h-5 p-px flex"
											aria-hidden="true"
										>
											<span className="w-full h-full rounded-full bg-blue-200" />
										</span>
										<span
											className="relative block w-2.5 h-2.5 bg-blue-600 rounded-full"
											aria-hidden="true"
										/>
										<span className="sr-only">{step.name}</span>
									</a>
								) : (
									<a
										href={step.href}
										className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
									>
										<span className="sr-only">{step.name}</span>
									</a>
								)}
							</li>
						))}
					</ol>
				</nav>
			)} */}
		</div>
	);
};

export default StepCounter;