import { useEffect, useState } from 'react';
import { getShopifyData } from '../utils';
import axios from 'axios';

const useCollections = () => {
	const { projectId, url } = getShopifyData('collections');
	const [isLoading, setIsLoading] = useState(false);
	const [collections, setCollections] = useState([]);
	const fetchCollections = async (query) => {
		setIsLoading(true);
		try {
			const response = await axios.get(
				!query ? url : url + '?search=' + query,
				{
					headers: {
						'Content-Type': 'application/json',
					},
					withCredentials: true,
					credentials: 'include',
				}
			);
			setCollections(response?.data?.body);
		} catch (error) {
			console.error(error);
		}
		setIsLoading(false);
	};
	const search = (query) => {
		fetchCollections(query);
	};
	useEffect(() => {
		fetchCollections();
	}, [url, projectId]);
	return { loading: isLoading, collections, onSearch: search };
};

export default useCollections;
