import React from 'react';
import {
	InnerBlocks,
	InspectorControls,
	RichText,
} from '@wordpress/block-editor';
import {
	PanelBody,
	ToggleControl,
	TextControl,
	RangeControl,
} from '@wordpress/components';
import { __ } from '@wordpress/i18n';
/**
 * Panel body
 * @param {Object} props contains the setAttribute,attributes etc..
 */
const Panel = (props) => {
	const { setAttributes, attributes, onChangeAttribute = false } = props;
	const {
		showViewMoreButton,
		showTitle,
		customBlockHeight = 130,
		enableGap = false,
		__display = true,
	} = attributes;
	if (props.attributes !== undefined)
		return (
			<InspectorControls>
				<PanelBody title="Block Settings">
					<ToggleControl
						label={__('Show Title', 'newspack-blocks')}
						checked={showTitle}
						onChange={() => {
							if (onChangeAttribute !== false)
								onChangeAttribute({ showTitle: !showTitle });
						}}
					/>
					<ToggleControl
						label={__('Show View more button', 'newspack-blocks')}
						checked={showViewMoreButton}
						onChange={() => {
							if (onChangeAttribute !== false)
								onChangeAttribute({ showViewMoreButton: !showViewMoreButton });
						}}
					/>
					<RangeControl
						label="Block Height"
						value={customBlockHeight}
						onChange={(value) => {
							if (onChangeAttribute !== false)
								onChangeAttribute({ customBlockHeight: value });
						}}
						min={30}
						max={500}
					/>
					<ToggleControl
						label={'Enable Gap'}
						checked={enableGap}
						onChange={() => {
							if (onChangeAttribute !== false)
								onChangeAttribute({ enableGap: !enableGap });
						}}
					/>
				</PanelBody>
				<PanelBody title="Advanced" initialOpen={false}>
					<TextControl
						label={'Display'}
						value={__display}
						onChange={(val) => {
							onChangeAttribute({ __display: val });
						}}
					/>
				</PanelBody>
			</InspectorControls>
		);
};
export default Panel;
