// import { addFilter, applyFilters } from '@wordpress/hooks';
import appmaker from '@appmaker/core/index';
import { updateStage } from './api';
import axios from 'axios';

const Plugin = {
    id: 'hubspot-sync',
    name: 'Hubspot sync',
    activate: () => {
        appmaker.addAction('app_design_changed_saved', 'save_hubspot', async ({ projectId }) => {
            await updateStage(projectId, 'app_design_changed');
        })
        appmaker.addAction('initial_customize_saved', 'initial_customize', async ({ projectId }) => {
            await updateStage(projectId, 'initial_customize_saved')
        })
        appmaker.addAction('app_created_and_plugin_installed', 'app_created_and_plugin_installed', async ({ projectId }) => {
            await updateStage(projectId, 'app_created_and_plugin_installed');
        })
        appmaker.addAction('plugin_configured', 'plugin_configured', async ({ projectId }) => {
            await updateStage(projectId, 'plugin_configured');
        })
        appmaker.addAction('url_verification_completed', 'url_verification_completed', async ({ projectId }) => {
            await updateStage(projectId, 'url_verification_completed');
        })
        appmaker.addAction('url_step_verification_failed', 'url_step_verification_failed', async ({ projectId }) => {
            await updateStage(projectId, 'url_step_verification_failed');
        })
        appmaker.addAction('app_created_and_plugin_detect_failed', 'app_created_and_plugin_detect_failed', async ({ projectId }) => {
            await updateStage(projectId, 'app_created_and_plugin_detect_failed');
            await axios.post(`https://6e841702e3.to.intercept.rest/${projectId}`, {
                "projectId": projectId,
                "dealstage": 'app_created_and_plugin_detect_failed'
            })
        })
    },
};

export default Plugin;
