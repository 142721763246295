import { useState } from 'react';
import LinkedRangeControl from './components/LinkedRangeControl';
import ColorPicker from '../../components/ColorPicker';
import { RangeControl } from '@wordpress/components';
import SwitchEnabler from './components/SwitchEnabler';
import Select from './components/Select';
import DisplayContainer from './components/DisplayContainer';

const LayoutStyles = ({ properties = {}, onChange, defaultValue = {} }) => {
	const { className, label } = properties;

	const [borderAttributes, setBorderAttributes] = useState(
		defaultValue.borderWidth || {
			borderTopWidth: 0,
			borderRightWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 0,
		}
	);
	const [marginAttributes, setMarginAttributes] = useState(
		defaultValue.margin || {
			marginTop: 0,
			marginRight: 0,
			marginBottom: 0,
			marginLeft: 0,
		}
	);
	const [paddingAttributes, setPaddingAttributes] = useState(
		defaultValue.padding || {
			paddingTop: 0,
			paddingRight: 0,
			paddingBottom: 0,
			paddingLeft: 0,
		}
	);
	const [borderRadiusAttributes, setBorderRadiusAttributes] = useState(
		defaultValue.borderRadius || {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
		}
	);

	const setStyles = (newAttributes) => {
		onChange && onChange({ ...defaultValue, ...newAttributes });
	};

	// Handlers for each style type
	const handleBorderChange = (newAttributes) => {
		setBorderAttributes(newAttributes);
		setStyles(newAttributes);
	};
	const handleMarginChange = (newAttributes) => {
		setMarginAttributes(newAttributes);
		setStyles(newAttributes);
	};
	const handlePaddingChange = (newAttributes) => {
		setPaddingAttributes(newAttributes);
		setStyles(newAttributes);
	};
	const handleBorderRadiusChange = (newAttributes) => {
		setBorderRadiusAttributes(newAttributes);
		setStyles(newAttributes);
	};

	const controlConfig = (label, property, sides, labels) => ({
		label,
		property,
		sides,
		labels,
		min: 0,
		max: 100,
	});

	const formReset = () => {
		const confirm = window.confirm('Are you sure to reset?. ');
		if (confirm) {
			onChange({});
		}
	};

	return (
		<DisplayContainer
			title={label}
			className={className}
			onClickRestToDefault={formReset}
		>
			<>
				<SwitchEnabler label="Flex">
					<RangeControl
						label="Flex"
						name="flex"
						value={defaultValue.flex || 1}
						onChange={(flex) => setStyles({ flex })}
						min={1}
						max={10}
					/>
				</SwitchEnabler>
				{/* flexGrow */}
				<SwitchEnabler label="Flex Grow">
					<RangeControl
						label="Flex Grow"
						name="flex-grow"
						value={defaultValue.flexGrow || 0}
						onChange={(flexGrow) => setStyles({ flexGrow })}
						min={0}
						max={10}
					/>
				</SwitchEnabler>
				{/* flexShrink */}
				<SwitchEnabler label="Flex Shrink">
					<RangeControl
						label="Flex Shrink"
						name="flex-shrink"
						value={defaultValue.flexShrink || 0}
						onChange={(flexShrink) => setStyles({ flexShrink })}
						min={0}
						max={10}
					/>
				</SwitchEnabler>
				{/* flexBasis */}
				<SwitchEnabler label="Flex Basis">
					<RangeControl
						label="Flex Basis"
						name="flex-basis"
						value={defaultValue.flexBasis || 0}
						onChange={(flexBasis) => setStyles({ flexBasis })}
						min={0}
						max={100}
					/>
				</SwitchEnabler>
				{/* flex-direction */}
				<Select
					label="Flex Direction"
					id="flex-direction"
					defaultValue={defaultValue?.flexDirection}
					onChange={(e) => setStyles({ flexDirection: e.target.value })}
					options={[
						{ label: 'Row', value: 'row' },
						{ label: 'Row Reverse', value: 'row-reverse' },
						{ label: 'Column', value: 'column' },
						{ label: 'Column Reverse', value: 'column-reverse' },
					]}
				/>
				{/* flexWrap */}
				<Select
					label="Flex Wrap"
					id="flex-wrap"
					defaultValue={defaultValue?.flexWrap}
					onChange={(e) => setStyles({ flexWrap: e.target.value })}
					options={[
						{ label: 'No Wrap', value: 'nowrap' },
						{ label: 'Wrap', value: 'wrap' },
						{ label: 'Wrap Reverse', value: 'wrap-reverse' },
					]}
				/>

				{/* Background Color */}
				<ColorPicker
					attributes={{
						label: 'Background Color',
						defaultColor: defaultValue?.backgroundColor,
						onChangeComplete: (color) => {
							setStyles({ backgroundColor: color.hex });
						},
					}}
				/>
				{/* alignContent */}
				<Select
					label="Align Content"
					id="align-content"
					defaultValue={defaultValue?.alignContent}
					onChange={(e) => setStyles({ alignContent: e.target.value })}
					options={[
						{ label: 'Flex Start', value: 'flex-start' },
						{ label: 'Flex End', value: 'flex-end' },
						{ label: 'Center', value: 'center' },
						{ label: 'Space Between', value: 'space-between' },
						{ label: 'Space Around', value: 'space-around' },
						{ label: 'Stretch', value: 'stretch' },
					]}
				/>
				{/* alignItems */}
				<Select
					label="Align Items"
					id="align-items"
					defaultValue={defaultValue?.alignItems}
					onChange={(e) => setStyles({ alignItems: e.target.value })}
					options={[
						{ label: 'Flex Start', value: 'flex-start' },
						{ label: 'Flex End', value: 'flex-end' },
						{ label: 'Center', value: 'center' },
						{ label: 'Baseline', value: 'baseline' },
						{ label: 'Stretch', value: 'stretch' },
					]}
				/>
				{/* alignSelf */}
				<Select
					label="Align Self"
					id="align-self"
					defaultValue={defaultValue?.alignSelf}
					onChange={(e) => setStyles({ alignSelf: e.target.value })}
					options={[
						{ label: 'Auto', value: 'auto' },
						{ label: 'Flex Start', value: 'flex-start' },
						{ label: 'Flex End', value: 'flex-end' },
						{ label: 'Center', value: 'center' },
						{ label: 'Baseline', value: 'baseline' },
						{ label: 'Stretch', value: 'stretch' },
					]}
				/>
				{/* justifyContent */}
				<Select
					label="Justify Content"
					id="justify-content"
					defaultValue={defaultValue?.justifyContent}
					onChange={(e) => setStyles({ justifyContent: e.target.value })}
					options={[
						{ label: 'Flex Start', value: 'flex-start' },
						{ label: 'Flex End', value: 'flex-end' },
						{ label: 'Center', value: 'center' },
						{ label: 'Space Between', value: 'space-between' },
						{ label: 'Space Around', value: 'space-around' },
						{ label: 'Space Evenly', value: 'space-evenly' },
					]}
				/>

				{/* Border Color */}
				<ColorPicker
					attributes={{
						label: 'Border Color',
						defaultColor: defaultValue?.borderColor,
						onChangeComplete: (color) => {
							setStyles({ borderColor: color.hex });
						},
					}}
				/>
				<LinkedRangeControl
					attributes={borderAttributes}
					setAttributes={handleBorderChange}
					defaultValue={defaultValue}
					config={controlConfig(
						'Border Width',
						'borderWidth',
						[
							'borderTopWidth',
							'borderRightWidth',
							'borderBottomWidth',
							'borderLeftWidth',
						],
						['Top', 'Right', 'Bottom', 'Left']
					)}
				/>
				{/* Border Radius */}
				<LinkedRangeControl
					attributes={borderRadiusAttributes}
					setAttributes={handleBorderRadiusChange}
					defaultValue={defaultValue}
					config={controlConfig(
						'Border Radius',
						'borderRadius',
						[
							'borderTopLeftRadius',
							'borderTopRightRadius',
							'borderBottomRightRadius',
							'borderBottomLeftRadius',
						],
						['Top Left', 'Top Right', 'Bottom Right', 'Bottom Left']
					)}
				/>
				{/* borderStyle */}
				<div className="mt-2">
					<label
						className="block text-sm font-base text-gray-700"
						htmlFor="border-style"
					>
						Border Style
					</label>
					<select
						id="border-style"
						className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
						value={defaultValue?.borderStyle}
						onChange={(e) => setStyles({ borderStyle: e.target.value })}
					>
						<option value="solid">Solid</option>
						<option value="dashed">Dashed</option>
						<option value="dotted">Dotted</option>
					</select>
				</div>
				<LinkedRangeControl
					defaultValue={defaultValue}
					attributes={marginAttributes}
					setAttributes={handleMarginChange}
					config={controlConfig(
						'Margin',
						'margin',
						['marginTop', 'marginRight', 'marginBottom', 'marginLeft'],
						['Top', 'Right', 'Bottom', 'Left']
					)}
				/>
				<LinkedRangeControl
					attributes={paddingAttributes}
					setAttributes={handlePaddingChange}
					defaultValue={defaultValue}
					config={controlConfig(
						'Padding',
						'padding',
						['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'],
						['Top', 'Right', 'Bottom', 'Left']
					)}
				/>
				{/* height */}
				<SwitchEnabler label={'Height'}>
					<RangeControl
						label="Height"
						name="height"
						value={defaultValue.height}
						onChange={(height) => setStyles({ height })}
						min={1}
						max={1920}
					/>
				</SwitchEnabler>
				{/* width */}
				<SwitchEnabler label={'Width'}>
					<RangeControl
						label="Width"
						name="width"
						value={defaultValue.width}
						onChange={(width) => setStyles({ width })}
						min={1}
						max={800}
					/>
				</SwitchEnabler>
				{/* minWidth */}
				<SwitchEnabler label={'Min Width'}>
					<RangeControl
						label="Min Width"
						name="min-width"
						value={defaultValue.minWidth}
						onChange={(minWidth) => setStyles({ minWidth })}
						min={1}
						max={800}
					/>
				</SwitchEnabler>
				{/* minHeight */}
				<SwitchEnabler label={'Min Height'}>
					<RangeControl
						label="Min Height"
						name="min-height"
						value={defaultValue.minHeight}
						onChange={(minHeight) => setStyles({ minHeight })}
						min={1}
						max={1920}
					/>
				</SwitchEnabler>
				{/* maxWidth */}
				<SwitchEnabler label={'Max Width'}>
					<RangeControl
						label="Max Width"
						name="max-width"
						value={defaultValue.maxWidth}
						onChange={(maxWidth) => setStyles({ maxWidth })}
						min={1}
						max={800}
					/>
				</SwitchEnabler>
				{/* maxHeight */}
				<SwitchEnabler label={'Max Height'}>
					<RangeControl
						label="Max Height"
						name="max-height"
						value={defaultValue.maxHeight}
						onChange={(maxHeight) => setStyles({ maxHeight })}
						min={1}
						max={1920}
					/>
				</SwitchEnabler>

				{/* overflow */}
				<Select
					label="Overflow"
					id="overflow"
					defaultValue={defaultValue?.overflow}
					onChange={(e) => setStyles({ overflow: e.target.value })}
					options={[
						{ label: 'Visible', value: 'visible' },
						{ label: 'Hidden', value: 'hidden' },
					]}
				/>
				{/* opacity */}
				<RangeControl
					label="Opacity"
					id="opacity"
					name="opacity"
					value={defaultValue?.opacity || 100}
					onChange={(newValue) => setStyles({ opacity: newValue })}
					min={0}
					max={100}
				/>
				{/* position */}
				<Select
					label="Position"
					id="position"
					defaultValue={defaultValue?.position}
					onChange={(e) => setStyles({ position: e.target.value })}
					options={[
						{ label: 'Relative', value: 'relative' },
						{ label: 'Absolute', value: 'absolute' },
					]}
				/>
				{/* if position is absolute show top,left,right,bottom adjustments */}
				{defaultValue?.position === 'absolute' && (
					<>
						<RangeControl
							label="Top"
							name="top"
							value={defaultValue.top || 0}
							onChange={(top) => setStyles({ top })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Left"
							name="left"
							value={defaultValue.left || 0}
							onChange={(left) => setStyles({ left })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Right"
							name="right"
							value={defaultValue.right || 0}
							onChange={(right) => setStyles({ right })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Bottom"
							name="bottom"
							value={defaultValue.bottom || 0}
							onChange={(bottom) => setStyles({ bottom })}
							min={0}
							max={100}
						/>
					</>
				)}
				{/* zIndex */}
				<RangeControl
					label="Z Index"
					name="z-index"
					value={defaultValue.zIndex || 0}
					onChange={(zIndex) => setStyles({ zIndex })}
					min={0}
					max={100}
				/>
			</>
		</DisplayContainer>
	);
};

export default LayoutStyles;
