import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { BASE_URL } from '@appmaker/core/api/core';

export const uploadMedia = async (files) => {
  const projectId = getProjectId()
  const url = `https://app.appmaker.xyz/remote_store/${projectId}/media/uploadMedia`;
  const data = new FormData();
  data.append('file', files);
  try {
    const option = {
      url,
      withCredentials: true,
      credentials: 'include',
      method: 'POST',
      data
    };
    const response = await axios(option);
    return response.data;
  } catch (error) {
    return Promise.reject(new Error('Error when calling upload media'));
  }
};

export const listMedia = async ({ search } = {}) => {
  const projectId = getProjectId()
  const url = `${BASE_URL}/projects/${projectId}/api-proxy/uploadMedia?per_page=12${search ? `search=${search}` : ''}`;
  try {
    const option = {
      url,
      withCredentials: true,
      credentials: 'include',
    };
    const response = await axios(option);
    return response.data.body;
  } catch (error) {
    return Promise.reject(new Error('Error when loading upload media'));
  }
};


// export  api;

