const CheckBox = ({ schema={} }) => {
	const { list, title } = schema;
	return (
		<fieldset>
			{title && (
				<legend className="text-lg font-medium text-gray-900 mb-4">
					{title}
				</legend>
			)}
			<div className="border-t border-b border-gray-200 divide-y divide-gray-200">
				{list?.map((item, itemIdx) => (
					<div key={itemIdx} className="relative flex items-center p-2">
						<div className="ml-3 flex items-center h-5">
							<input
								id={`item-${item.id}`}
								name={`item-${item.id}`}
								type="checkbox"
								className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
							/>
						</div>
						<label
							htmlFor={`item-${item.id}`}
							className="font-medium text-gray-700 select-none min-w-0 flex-1 text-sm cursor-pointer p-2"
						>
							{item.label}
						</label>
						{item.badge && (
							<span className="text-xs px-2 py-1 bg-gray-200 rounded-full">
								{item.badge}
							</span>
						)}
					</div>
				))}
			</div>
		</fieldset>
	);
};

export default CheckBox;
