import appmaker from '@appmaker/core/index'
import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { BASE_URL } from '@appmaker/core/api/core';

const api = {
    proxy: async ({ search, tag } = {}) => {
        const projectId = getProjectId();
        const url = `${BASE_URL}/projects/${projectId}/api-proxy/${tag}?${search ? `search=${search}` : ''}`;
        try {
            const option = {
                url,
                withCredentials: true,
                credentials: 'include',
            };
            const response = await axios(option);
            return response.data.body;
        } catch (error) {
            return Promise.reject(new Error('Error when loading upload media'));
        }
    }
}
const registerAction = (action, params) => {
    appmaker.actions.registerAction(action, params)
}

const actionConverter = (actions) => {
    return Object.keys(actions).map(action => {
        if (actions[action]) {
            // to check for custom tag
            if (actions[action].properties) {
                let actionParams = actions[action];
                Object.keys(actionParams.properties).forEach(item => {
                    let properties = actionParams.properties[item] || {}
                    
                    if (properties.type === 'custom') {
                        let tag = properties.tag;
                        properties = {
                            ...properties,
                            type: 'promise-select',
                            getOptions: async (inputValue, { projectId }) => {
                                try {
                                    const resp = await api.proxy({ search: inputValue, tag });
                                    return resp.map((item) => ({
                                        label: item.label,
                                        value: item.id,
                                    }));
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                        }
                        actionParams.properties[item] = properties;
                    }
                })
                return {
                    action,
                    params: actionParams
                }
            } else {
                return {
                    action,
                    params: actions[action]
                }
            }
        }
        return undefined
    }).filter(item=> item !== undefined)

}


const registerCustomAction = (actions) => {
    const convertedAction = actionConverter(actions);
    convertedAction.forEach(action => {
        registerAction(action.action, action.params)
    })
}

export default registerCustomAction;

export {
    actionConverter
}