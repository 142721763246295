import { InnerBlocks } from '@wordpress/block-editor';
import React from 'react';

export function TabItem({ attributes, setAttributes }) {
	return (
		<div className="bg-gray-50 m-1 rounded overflow-hidden">
			<div className="bg-gray-100 px-2 py-1 text-sm font-medium">
				{attributes.label}
			</div>
			{/* <InnerBlocks allowedBlocks={['appmaker/badge', 'appmaker/text']} /> */}
		</div>
	);
}
TabItem.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			label: 'Label',
			source: 'attribute',
		},
		pageId: {
			type: 'string',
			label: 'Page Id',
			source: 'attribute',
		},
		openSeperatePage: {
			type: 'string',
			label: 'Open as separate page',
			uiType: 'switch',
		},
	},
};

TabBlock.attributesSchema = {
	properties: {
		themeColor: {
			type: 'string',
			label: 'Theme Color',
			source: 'attribute',
		},
		bgColor: {
			type: 'string',
			label: 'Background Color',
			source: 'attribute',
		},
		buttonTab: {
			type: 'string',
			label: 'Button Tab',
			uiType: 'switch',
		},
		scrollable: {
			type: 'string',
			label: 'Scrollable Tab bar',
			uiType: 'switch',
		},
	},
};

export default function TabBlock() {
	return (
		<div className="p-2">
			<InnerBlocks
				// template={BLOCKS_TEMPLATE}
				allowedBlocks={['appmaker/tab-item']}
				// templateLock="all"
				// allowedBlocks={['appmaker/badge']}
			/>
		</div>
	);
}
// TabBlock.
