import { useMultiSelectWithProvider } from "use-multiselect";
import { useEffect } from 'react';

export default function TempFixDeSelector() {
    const { deSelectAll } = useMultiSelectWithProvider();
    /**
     * bug:- Add images that already previously selected in the context hence deselecting on
     */
    useEffect(() => {
        deSelectAll();

        return () => {
            deSelectAll();
        };
    }, []);
    return null;
}
