import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';

/**
 * @param toastMessage message
 * @pram appearance (eg: success,error)
 * @param options
 *
 *
 */
export const sendNotification = (toastMessage, appearance, options = {}) => {
	const toastOption = {
		duration: 5000,
		position: 'top-right',
		...options,
	};
	switch (appearance) {
		case 'error': {
			return toast.error(toastMessage, toastOption);
		}
		case 'success': {
			return toast.success(toastMessage, toastOption);
		}
		default: {
			return toast(toastMessage, toastOption);
		}
	}
};

const ReactHotToast1Integrator = ({
	children,
	position = 'top-right',
	reverseOrder = true,
}) => {
	return (
		<div>
			<Toaster position={position} reverseOrder={reverseOrder} />
			{children}
		</div>
	);
};

export { toast };
export default ReactHotToast1Integrator;
