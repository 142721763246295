import { products } from '../api/proxy';

const actions = {
	OPEN_PRODUCT_NEW: {
		label: 'Open Product Woocommerce',
		properties: {
			productId: {
				type: 'promise-select',
				label: 'Product Id',
				getOptions: async (inputValue) => {
					try {
						const resp = await products({ search: inputValue });
						const finalList = resp.body.map(
							(item) => ({ label: item.label, value: item.id })
						);
						return finalList;
					} catch (error) {
						console.log(error);
					}
				},
			},
		},
	},
};

export default actions;
