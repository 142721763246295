import { InnerBlocks } from '@wordpress/block-editor'
import React from 'react'

export default function ToolbarPage() {
  return (
    <div>
        Page Toolbar 
        <InnerBlocks
				// template={BLOCKS_TEMPLATE}
				allowedBlocks={['appmaker/toolbar-icon']}
			// templateLock="all"
			// allowedBlocks={['appmaker/badge']}
			/>
    </div>
  )
}
ToolbarPage.attributesSchema = {
    properties: {
        pageId: {
			type: 'string',
			label: 'Page Id',
			source: 'attribute',
		}
    }
}