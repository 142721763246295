/* eslint-disable react-hooks/exhaustive-deps */
// @flow strict
import React, { useState, useEffect } from 'react';
import BlockCardView from '../uikit/components/BlockCard';
import classnames from 'classnames';
import { MinusSmIcon, PlusIcon } from '@heroicons/react/outline';
function DummyBlock({ name }) {
	return (
		<div className="relative border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				<p className="text-sm font-medium text-gray-900">{name}</p>
			</div>
		</div>
	);
}
function ProductImage({ attributes }) {
	// const { thumbnail } = attributes;
	// const person = {}
	return <DummyBlock name="Product Image" />;
}
ProductImage.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				imageList: { type: 'string' },
				thumbnail: { type: 'string', default: '{{blockItem.thumbnail}}' },
			},
		},
	},
};

function ProductData({ attributes }) {
	return (
		<div className="px-3 py-2 border-gray-100 border-b">
			<p className="text-green-500 text-sm">
				{attributes.in_stock ? 'In-Stock' : 'Out of stock'}
			</p>
			<p className="text-md font-medium">{attributes.title}</p>
			<p
				className="text-lg font-bold"
				dangerouslySetInnerHTML={{ __html: attributes.price_html }}
			></p>
		</div>
	);
}
ProductData.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				inStock: { type: 'string', default: '{{blockItem.in_stock}}' },
				title: { type: 'string', default: '{{blockItem.name}}' },
				salePercentage: { type: 'string' },
				onSale: { type: 'string' },
				regularPrice: { type: 'string' },
				salePrice: { type: 'string' },
			},
		},
	},
};

const ProductVariationBlock = ({ attributes = {}, coreDispatch }) => {
	const [selected, setSelected] = useState('');
	// useEffect(() => {
	//   coreDispatch({
	//     type: "SET_PAGE_VAR",
	//     name: attributes.variationKey,
	//     value: selected,
	//     parent: "attributes",
	//   });
	// }, [selected]);
	return (
		<div className="p-6 mt-2 bg-white shadow-sm">
			<p className="pb-1 text-lg">
				{attributes.name}: {selected} - Variation blockssss
			</p>
			<div className="flex space-x-3 whitespace-nowrap overflow-x-auto">
				Variation blocks
				{/* {attributes?.variations?.map((item) => (
          <button
            className={classnames("bg-gray-100 rounded-sm p-3", {
              "border border-black": selected === item.value,
            })}
            key={item.value}
            onClick={() => setSelected(item.value)}
          >
            {item.name}
          </button>
        ))} */}
			</div>
		</div>
	);
};
ProductVariationBlock.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				blockTitle: { type: 'string', default: '{{blockItem.name}}' },
				title: { type: 'string' },
				variationKey: {
					type: 'string',
				},
				variations: {
					type: 'string',
				},
			},
		},
	},
};

const BlockList = ({ attributes, BlockItemRender }) => {
	return (
		<div className="p-6 mt-2 bg-white shadow-sm">
			{attributes?.blocks?.map &&
				attributes?.blocks?.map((block, key) => (
					<BlockItemRender
						key={key}
						BlockItemRender={BlockItemRender}
						block={block}
					/>
				))}
		</div>
	);
};
const BlockCard = ({ attributes, innerBlocks, BlockItemRender }) => {
	return (
		<BlockCardView attributes={attributes}>
			{innerBlocks?.map &&
				innerBlocks?.map((block, key) => (
					<BlockItemRender
						key={key}
						BlockItemRender={BlockItemRender}
						block={block}
					/>
				))}
		</BlockCardView>
	);
};
BlockList.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				blocks: { type: 'string', default: '{{blockItem.extra_blocks}}' },
			},
		},
	},
};
const ExpandableTextBlock = ({ attributes }) => {
	return (
		<div className="p-6 mt-2 bg-white shadow-sm">
			<p className="pb-1 text-lg">{attributes.blockTitle}</p>
			<div
				dangerouslySetInnerHTML={{ __html: attributes.content }}
				className="flex space-x-3 whitespace-nowrap overflow-x-auto"
			></div>
		</div>
	);
};
const InputItem = ({ attributes, coreDispatch }) => {
	return (
		<div className="px-2 py-1">
			<label>{attributes.label}</label>
			<input
				className="form-input mt-1 block w-full rounded-md border border-gray-200"
				type="text"
				disabled={true}
				value={attributes.value}
				placeholder={attributes.placeholder}
				onChange={(e) => {
					const value = e.target.value;
					coreDispatch({
						type: 'SET_PAGE_VAR',
						name: attributes.key,
						value,
					});
				}}
			/>
		</div>
	);
};
InputItem.attributesSchema = {
	type: 'object',
	properties: {
		label: { type: 'string', label: 'label' },
		name: { type: 'string', label: 'Field Name' },
		key: { type: 'string', label: 'key' },
		value: { type: 'string', label: 'value' },
		parentName: { type: 'string', label: 'parentName' },
	},
};
const Button = ({ attributes = {}, onAction = () => { } }) => {
	const { content } = attributes;
	return (
		<button
			className="text-white py-3 text-center cursor-pointer w-full bg-yellow-500"
			onClick={() => {
				onAction({
					action: 'ADD_TO_CART',
				});
			}}
		>
			{content}
		</button>
	);
};
Button.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				content: { type: 'string', default: ' Add to cart' },
			},
		},
	},
};

const LoadingSpinner = ({ attributes, onAction }) => {
	const { loading } = attributes;
	return (
		<button
			className={classnames(
				'text-white py-3 text-center cursor-pointer w-full',
				{
					'bg-blue-500': loading === 'true',
					'bg-red-500': loading !== 'true',
				}
			)}
		>
			{loading}
		</button>
	);
};
LoadingSpinner.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				loading: { type: 'string', default: '{{pageState.addingToCart}}' },
			},
		},
	},
};

const AppmakerJSON = ({ attributes, onAction }) => {
	return <pre>{JSON.stringify(attributes, null, 2)}</pre>;
};
AppmakerJSON.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				pageState: { type: 'string', default: '{{pageState}}' },
				value1: { type: 'string', default: '' },
				value2: { type: 'string', default: '' },
			},
		},
	},
};
export {
	ProductVariationBlock,
	ProductImage,
	ProductData,
	// ProductCounter,
	BlockList,
	ExpandableTextBlock,
	BlockCard,
	InputItem,
	Button,
	LoadingSpinner,
	AppmakerJSON,
};
