import { appmakerBlocks } from '@appmaker/core/blockType'
import React from 'react'

export default function BlockItem(
  {
    block,
    parentAttributes
  }
) {
  const { name, attributes, clientId ,innerBlocks} = block
  const BlockItemView = appmakerBlocks[name] && (appmakerBlocks[name].View || appmakerBlocks[name].Edit);
  const defaultProps = {
    innerBlocks,
    attributes,
    clientId,
    block,
    parentAttributes
  }
  if (BlockItemView && (BlockItemView.canRenderInnerBlocks === true)) {
    return <BlockItemView {...defaultProps} ></BlockItemView>
  }
  const innerChildren = block.innerBlocks.map((block) => <BlockItem key={block.clientId} block={block} parentAttributes={attributes} />)
  return (BlockItemView ?
    <BlockItemView {...defaultProps}>
      {innerChildren}
    </BlockItemView>
    : <div>No block for {name} </div>)
}
