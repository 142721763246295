import { TrashIcon } from '@heroicons/react/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';

const CartCardItem = ({ attributes = {} }) => {
	const {
		title = 'Name',
		price = '$20.00',
		imgSrc = '/logo512.png',
	} = attributes;
	return (
		<div className="flex p-4 space-x-2 bg-white">
			<img src={imgSrc} alt="" className="h-24 w-24" />
			<div className="flex flex-auto flex-col justify-between">
				<div className="flex flex-auto justify-between">
					<h3 className="text-gray-600">{title}</h3>
					<p className="text-lg font-medium">{price}</p>
				</div>
				<div className="flex justify-between">
					<div className="flex items-center justify-center space-x-2 p-0.5 border border-green-600 rounded-lg overflow-hidden">
						<MinusIcon className="h-8 w-8 bg-gray-100 p-2 rounded-md" />
						<span>1</span>
						<PlusIcon className="h-8 w-8 bg-gray-100 p-2 rounded-md" />
					</div>
					<div className="flex items-center justify-center space-x-2 p-0.5 border border-red-600 text-red-600 rounded-lg overflow-hidden">
						<TrashIcon className="h-8 w-8 p-1.5 rounded-md" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartCardItem;
