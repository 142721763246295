import clsx from 'clsx';
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';

const LAYOUT = [
	{
		id: 1,
		name: 'grid',
		icon: (
			<svg
				width="101"
				height="91"
				viewBox="0 0 101 91"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="101" height="91" fill="#E0E7FF" />
				<rect x="8" y="11" width="40" height="55" rx="3" fill="#A5B4FC" />
				<path
					d="M8 75C8 73.3431 9.34315 72 11 72H45C46.6569 72 48 73.3431 48 75V91H8V75Z"
					fill="#A5B4FC"
				/>
				<rect x="53" y="11" width="40" height="55" rx="3" fill="#A5B4FC" />
				<path
					d="M53 75C53 73.3431 54.3431 72 56 72H90C91.6569 72 93 73.3431 93 75V91H53V75Z"
					fill="#A5B4FC"
				/>
			</svg>
		),
	},
	{
		id: 2,
		name: 'list',
		icon: (
			<svg
				width="101"
				height="91"
				viewBox="0 0 101 91"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="101" height="91" fill="#E0E7FF" />
				<rect x="9" y="9" width="48" height="73" rx="3" fill="#A5B4FC" />
				<path
					d="M63 12C63 10.3431 64.3431 9 66 9H101V82H66C64.3431 82 63 80.6569 63 79V12Z"
					fill="#A5B4FC"
				/>
			</svg>
		),
	},
];

const LayoutSelector = ({ properties }) => {
	let [layout, setLayout] = useState(LAYOUT[0]);
	return (
		<RadioGroup value={layout} onChange={setLayout} className="mb-4">
			<RadioGroup.Label className="block text-sm font-base text-gray-700 mb-1">
				Layout
			</RadioGroup.Label>
			<div className="flex items-center space-x-2">
				{LAYOUT.map((item) => (
					<RadioGroup.Option value={item.id}>
						{({ checked }) => (
							<span
								className={clsx(
									'border-2 rounded-md overflow-hidden inline-flex cursor-pointer',
									checked ? 'border-indigo-600' : 'border-gray-100'
								)}
							>
								{item.icon}
							</span>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
};

export default LayoutSelector;
