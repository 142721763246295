import { getProjectId } from '@appmaker/core/store/project';
import { useEffect } from 'react';

const Menu = () => {
	const projectId = getProjectId();
	useEffect(() => {
		window.location.href = `https://manage-v2.appmaker.xyz/apps/${projectId}/menu`;
	}, [projectId]);
	return null;
};

export default Menu;
