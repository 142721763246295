import { useParams } from '@appmaker/core/routes';
import { useState } from 'react';
import {
	createStore,
	AppmakerFormProvider,
	useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useDataSource } from '../store';
import api from '../api';
import { useProject } from '@appmaker/core/hooks';
import { useAsync } from 'react-use';

const SaveButton = ({ projectId }) => {
	const { dataSourceId } = useParams();
	const [isLoading, setLoading] = useState(false);
	const storeData = useDataSource(data => data.installedList);
	const dataSource = storeData.find(dataSource =>
		dataSource.installedMeta
		&& dataSource.installedMeta.projectDataSourceId === parseInt(dataSourceId)) || {};
	const storeApi = useAppmakerFormStoreApi();
	const onClickSave = async () => {
		const { formData } = storeApi.getState();
		setLoading(true);
		try {
			const response = await api.saveSettings({
				projectId, dataSourceId, settings: formData
			})
			setLoading(false);
		} catch (error) {

		}
	}
	return (
		<button
			disabled={isLoading}
			onClick={onClickSave}
			className='my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
		>
			{isLoading ? 'Saving ...' : 'Save'}
		</button>
	)
}

const FormSchema = ({ schema, projectId, metaKey }) => {
	// useAsync
	// const [res] = useAsync(async () => {

	return (
		<div className="bg-gray-50 h-full p-6">
			<div className="max-w-3xl">
				<AppmakerForm
					formVersion={2}
					// value={{ formData: flatten(formData) }}
					schema={schema}
				/>
				<SaveButton projectId={projectId} metaKey={metaKey} />
			</div>
		</div>
	);
}

const FormSchemaWrapper = ({ schema }) => {
	const { projectId } = useProject();
	const { dataSourceId } = useParams();
	const { value, error, loading } = useAsync(async () => {
		const result = api.getValues({
			projectId,
			projectDataSourceId: dataSourceId
		});
		return result;
	}, []);
	if (loading) return (
		<div className="flex-1 overflow-hidden animate-pulse m-6 gap-4 flex flex-col">
			<div className="h-16 w-full bg-gray-200 rounded-lg" />
			<div className="h-16 w-full bg-gray-100 rounded-lg" />
			<div className="h-16 w-full bg-gray-50 rounded-lg" />
		</div>
	)
	if (error) {
		return null
	}
	return (
		<AppmakerFormProvider
			createStore={createStore({
				formData: value ? value : {},
			})}
		>
			<FormSchema schema={schema} projectId={projectId} />
		</AppmakerFormProvider>
	)
}
export default FormSchemaWrapper