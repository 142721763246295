const plan = {
	// essential: {
	// 	name: 'essential',
	// 	// description: '$150 for the first year',
	// 	// extraDesc: 'Small business in early stage.',
	// 	// skipMonthly: true,
	// 	// specialPrice: {
	// 	// 	default: { price: 150, currencySymbol: '$' },
	// 	// 	india: { price: 3499, currencySymbol: '₹' },
	// 	// },
	// 	pricing: {
	// 		yearly: {
	// 			default: {
	// 				price: 490,
	// 				currencySymbol: '$',
	// 				offerPriceYearly: '150',
	// 				chargebeePlanId: {
	// 					usd: 'woocommerce-essential-yearly-usd',
	// 				},
	// 				specialPrice: {
	// 					price: 150,
	// 					offerLabel: 'For first year only',
	// 					currencySymbol: '$',
	// 					pricePerTag: '/Yr',
	// 				},
	// 			},
	// 			// region_1: {
	// 			// 	price: 199,
	// 			// 	currencySymbol: '$',
	// 			// 	chargebeePlanId: {
	// 			// 		usd: 'woocommerce-essential-region-1',
	// 			// 	},
	// 			// },
	// 			india: {
	// 				price: 36999,
	// 				currencySymbol: '₹',
	// 				offerPriceYearly: '11299',
	// 				chargebeePlanId: {
	// 					inr: 'woocommerce-essential-yearly-india',
	// 				},
	// 				specialPrice: {
	// 					price: 11299,
	// 					offerLabel: 'For first year only',
	// 					currencySymbol: '₹',
	// 					pricePerTag: '/Yr',
	// 				},
	// 			},
	// 		},
	// 		monthly: {
	// 			default: {
	// 				price: 49,
	// 				currencySymbol: '$',
	// 				chargebeePlanId: {
	// 					usd: 'woocommerce-essential-usd',
	// 				},
	// 				specialPrice: {
	// 					price: 150,
	// 					offerLabel: 'For first year only',
	// 					currencySymbol: '$',
	// 					pricePerTag: '/Yr',
	// 				},
	// 				showAlertBox: true,
	// 			},
	// 			india: {
	// 				price: 3699,
	// 				currencySymbol: '₹',
	// 				chargebeePlanId: {
	// 					inr: 'woocommerce-essential-india',
	// 				},
	// 				specialPrice: {
	// 					price: 11299,
	// 					offerLabel: 'For first year only',
	// 					currencySymbol: '₹',
	// 					pricePerTag: '/Yr',
	// 				},
	// 				showAlertBox: true,
	// 			},
	// 		},
	// 	},
	// },
	core: {
		name: 'core',
		description:
			'Includes all features in Essential + comprehensive analytics & automatic updates',
		// extraDesc: 'Small business in growth stage.',
		pricing: {
			yearly: {
				default: {
					price: 4990,
					currencySymbol: '$',
					chargebeePlanId: {
						usd: 'new-core-plan-woocommerce-yearly-usd',
					},
					showMonthlyMode: true,
					monthlyPrice: 499,
				},
				india: {
					price: 403799,
					currencySymbol: '₹',
					chargebeePlanId: {
						inr: 'new-core-plan-woocommerce-yearly-inr',
					},
					showMonthlyMode: true,
					monthlyPrice: 33649,
				},
			},
			monthly: {
				default: {
					price: 499,
					currencySymbol: '$',
					chargebeePlanId: {
						usd: 'new-core-plan-woocommerce-monthly-usd',
					},
				},
				india: {
					price: 40499,
					currencySymbol: '₹',
					chargebeePlanId: {
						inr: 'new-core-plan-woocommerce-monthly-inr',
					},
				},
			},
		},
	},
	pro: {
		id: 'pro',
		name: 'pro',
		description:
			'Includes all features in Core + SEO Support & Quality business insights (report)',
		// extraDesc: 'Business in the growth stage.',
		pricing: {
			yearly: {
				default: {
					price: 9990,
					currencySymbol: '$',
					chargebeePlanId: {
						usd: 'new-pro-plan-woocommerce-yearly-usd',
					},
					showMonthlyMode: true,
					monthlyPrice: 749,
				},
				india: {
					price: 812099,
					currencySymbol: '₹',
					chargebeePlanId: {
						inr: 'new-pro-plan-woocommerce-yearly-inr',
					},
					showMonthlyMode: true,
					monthlyPrice: 67675,
				},
			},
			monthly: {
				default: {
					price: 999,
					currencySymbol: '$',
					chargebeePlanId: {
						usd: 'new-pro-plan-woocommerce-monthly-usd',
					},
				},
				india: {
					price: 81199,
					currencySymbol: '₹',
					chargebeePlanId: {
						inr: 'new-pro-plan-woocommerce-monthly-inr',
					},
				},
			},
		},
		yearlyPriceInMonth: true,
	},
	ultimate: {
		id: 'ultimate',
		name: 'ultimate',
		description: 'Includes all features in Pro + Custom Plugin Support.',
		// extraDesc: 'For business in the scale stage.',
		pricing: {
			yearly: {
				default: {
					price: 19990,
					currencySymbol: '$',
					chargebeePlanId: {
						usd: 'new-ultimate-plan-woocommerce-yearly-usd',
					},
					showMonthlyMode: true,
					monthlyPrice: 1665,
				},
				india: {
					price: 1624932,
					currencySymbol: '₹',
					chargebeePlanId: {
						inr: 'new-ultimate-plan-woocommerce-yearly-inr',
					},
					showMonthlyMode: true,
					monthlyPrice: 135411,
				},
			},
			monthly: {
				default: {
					price: 1999,
					currencySymbol: '$',
					chargebeePlanId: {
						usd: 'new-ultimate-plan-woocommerce-monthly-usd',
					},
				},
				india: {
					price: 162499,
					currencySymbol: '₹',
					chargebeePlanId: {
						inr: 'new-ultimate-plan-woocommerce-monthly-inr',
					},
				},
			},
		},
		yearlyPriceInMonth: true,
	},
};

export default plan;
