import { useParams } from '@appmaker/core/routes';
import { useDataSource } from '../store'
import AppHeader from '../components/AppHeader';
import TabGroupContainer from '../components/TabContainer';
import FormSchemaWrapper from '../components/FormSchemaWrapper';
import hooks from '../hooks';
import Loader from '@appmaker/components/Loading';

const EditDataSource = () => {
    const { dataSourceId } = useParams();
    const storeData = useDataSource(data => data.installedList);
    const {isLoading} = hooks.useInstalledDataSourceList({refetch: false});

    const dataSource = storeData.find(dataSource =>
        dataSource.installedMeta
        && dataSource.installedMeta.projectDataSourceId === parseInt(dataSourceId)) || {};
    const { Component, formSchema } = dataSource || {};
    if(isLoading){
        return (
            <Loader />
        )
    }
    return (
        <div className="bg-gray-50 h-screen overflow-y-auto">
            <div className="w-full mx-auto bg-white ">
                <AppHeader extension={dataSource} />
                <TabGroupContainer extension={dataSource}>
                    <FormSchemaWrapper projectDataSourceId={dataSourceId} schema={formSchema} />
                </TabGroupContainer>
            </div>
        </div>
    )
}

export default EditDataSource;