import create from 'zustand'
import createContext from 'zustand/context'

const createStore =()=> create(set => ({
    modalVisible: false,
    searchText: null,
    toggleModal: () => set(state => ({ modalVisible: !state.modalVisible })),
    setModalVisible: (modalVisible) => set({ modalVisible }),
    setSearchText: (searchText) => set({ searchText }),
    setOnSelect: (onSelect) => set({ onSelect }),
}))
const { Provider, useStore, useStoreApi } = createContext()

// export { useMediaStore }
export { createStore,Provider as MediaStoreProvider, useStore as useMediaStore, useStoreApi }
