/* eslint-disable react-hooks/rules-of-hooks */

import React from 'react';

import { parse } from '@appmaker/core/utils/parser';
import BlocksListView from './BlocksListView';
import { usePageState } from '@appmaker/core/store/pageState';
// import useStateVars from '../../lib/hooks/useStateVars';
// import useCoreDispatch from '../../lib/hooks/useCoreDispatch';

/**
 * Render Block or BlockListView or InnerBlocks
 * @param {*} config
 */
function useStateVars(customStateVars) {
	// return { name: 'saleeh' }
	const pageState = usePageState(state => state)

	return {...customStateVars,pageState};
}
// const allBlocks = applyFilters("appmaker-blocks", {});
const BlockItem = ({
	block,
	// blockData,
	blockListItem,
	clientId,
	Block,
	onAction,
	BlockItemRender = BlockItem,
	BlocksView,
	currentAction,
}) => {
	const blockData = usePageState(state => state.blockData)

	// const allBlocks = getAllBlocks();
	// console.log(
	//   '=================>BlockItem',
	//   block,
	//   // allBlocks,
	//   block.name,
	//   block.attributes?.name,
	//   // allBlocks,
	// );
	const { clientId: templateclientId, innerBlocks } = block;
	// const Block = argBlock
	const coreDispatch = () => { };
	// useCoreDispatch();

	const defaultProps = {
		Block,
		clientId: clientId ? clientId : templateclientId,
		innerBlocks,
		onAction,
		BlockItemRender,
		blockData,
		coreDispatch,
		BlockItem,
		BlocksView,
		currentAction,
		/**
		 * Temp fix for UI kit blocks
		 */
		pageDispatch: coreDispatch,
	};
	if (
		block.attributes &&
		block.attributes.dataSource &&
		block.attributes.dataSource.repeatable &&
		(block.attributes.dataSource.repeatable === true ||
			block.attributes.dataSource.repeatable === 'Yes') &&
		!blockListItem
	) {
		// return <pre>{JSON.stringify(defaultProps, null, 2)}</pre>
		return (
			<div>
				List
				<BlocksListView block={block} {...defaultProps} />
			</div>
		);
	}

	let customStateVars = {};
	if (blockData) {
		customStateVars.blockItem = blockData;
	}
	const stateVars = useStateVars(customStateVars, block?.dependencies);
	let attributes = parse({
		template: block.attributes,
		data: stateVars,
	});
	console.log({
		template: block.attributes,
		data: stateVars,
	});
	if (Block !== undefined) {
		if (attributes?.__display === false) return null;

		return Block.View ? (
			<Block.View
				{...defaultProps}
				attributes={attributes}
				innerBlocks={innerBlocks}
			/>
		) : (
			<div>null</div>
		);
	} else if (process.env.NODE_ENV === 'development') {
		return (
			<div>
				No block:--
				{JSON.stringify({ ...block, attributes }, null, 2)}
			</div>
		);
	} else {
		return 'Nil';
	}
};

export default React.memo(BlockItem);
