import { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { LockClosedIcon } from '@heroicons/react/solid';
import PricingModal from 'packages/Subscription/components/PricingModal';
import { LockOpenIcon } from '@heroicons/react/outline';
import UnlockBadge from '../components/UnlockBadge';
const AppmakerSwitch = ({ properties, defaultValue, onChange }) => {
	const {
		title,
		label,
		className,
		disable = false,
		addOnTag = 'Add-On',
		upgradePlanAction = () => {},
		uiOption = {},
	} = properties;
	const {
		upgradeRequired = false,
		showPaymentPage = false,
		upgradeTag = false,
		paidFeature = false,
		featureTag = false
	} = uiOption;

	// const { title = 'switch title', label = 'label' } = attributes;
	const tempDefaultValue =
		defaultValue === true || defaultValue === '1' ? true : false;
	const tempLock = disable ? disable : upgradeRequired;
	const [enabled, setEnabled] = useState(tempLock ? false : tempDefaultValue);
	const [lock, setLock] = useState(tempLock);
	const [pricingModal, setPricingModal] = useState(false);
	const onClickUpgrade = () => {
		if (showPaymentPage) {
			// show payment page here
			setPricingModal(true);
		} else {
			// callback function
			upgradePlanAction();
		}
	};

	useEffect(() => {
		onChange(enabled ? '1' : '0');
	}, [enabled, onChange]);
	return (
		<div
			className={clsx(
				'relative',
				className ? className : 'mt-4',
				lock &&
					'px-2 pt-2 bg-gray-100 rounded-md border border-gray-200 border-dashed'
			)}
		>
			<Switch.Group>
				<span
					className={clsx(
						'font-regular text-sm mb-1 flex items-center space-x-1',
						lock && 'text-gray-500'
					)}
				>
					{title}
					{lock && (
						<span className="text-green-700 gap-1 bg-green-100 px-2 py-0.5 rounded-full text-xs ml-2 inline-flex items-center justify-center">
							<LockClosedIcon className="h-3 w-3" />
							{upgradeTag ? upgradeTag : addOnTag}
						</span>
					)}
					{upgradeRequired ===false && paidFeature && <UnlockBadge attributes={{ title: !featureTag ? 'Pro Feature' : featureTag }} />}
				</span>
				<div className="flex items-center gap-2 mb-2">
					<Switch
						disabled={disable || upgradeRequired}
						checked={enabled}
						onChange={setEnabled}
						className={clsx(
							enabled ? 'bg-green-600' : 'bg-gray-200',
							'relative inline-flex items-center h-6 rounded-full w-12 transition-colors ease-in-out duration-200'
						)}
					>
						<span
							className={clsx(
								enabled ? 'left-1 text-green-500' : 'right-1 text-gray-400',
								'absolute text-xs font-medium transition ease-in-out duration-200'
							)}
						>
							{enabled ? 'ON' : 'OFF'}
						</span>
						<span
							className={clsx(
								enabled ? 'translate-x-7' : 'translate-x-1',
								'inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200'
							)}
						/>
					</Switch>
					<Switch.Label
						className={clsx(
							'text-base font-medium cursor-pointer',
							lock && 'text-gray-500'
						)}
					>
						{label}
					</Switch.Label>
					{lock && (
						<button
							type="button"
							onClick={() => {
								onClickUpgrade();
							}}
							className="absolute -right-2 bottom-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						>
							Upgrade Plan
						</button>
					)}
				</div>
			</Switch.Group>
			<PricingModal isOpen={pricingModal} setIsOpen={setPricingModal} />
		</div>
	);
};

export default AppmakerSwitch;
