// import appmaker from '@appmaker/core/index';
// appmaker.actions

const actions = {
	NO_ACTION: {
		label: 'No action',
	},
	OPEN_IN_WEBVIEW: {
		label: 'Open webview',
		properties: {
			url: {
				label: 'Choose URL',
				type: 'string',
			},
			title: {
				type: 'string',
				label: 'Title',
			},
			extraStyle: {
				label: 'Extra Style',
				type: 'string',
			},
			openInModal: {
				type: 'switch',
				label: 'Open in Modal',
			},
			permissionsRequired: {
				type: 'string',
				uiType: 'select',
				label: 'Permissions',
				multi: true,
				options: [
					{
						label: 'Location',
						value: 'location',
					},
					{
						label: 'Camera',
						value: 'camera',
					},
					{
						label: 'Microphone',
						value: 'microphone',
					},
				],
			},
		},
	},

	OPEN_URL: {
		label: 'Open URL',
		properties: {
			url: {
				label: 'Choose URL',
				type: 'string',
			},
		},
	},

	OPEN_DEEPLINK_URL: {
		label: 'Open deeplink url',
		properties: {
			url: {
				type: 'string',
				label: 'URL',
			},
		},
	},
};

export default actions;
