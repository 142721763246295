import React from 'react';
import { useBlockProps } from '@wordpress/block-editor';
import AttributesForm from './AttributesForm';
import BlockItem from './components/BlockItem';
import { BlockContextProvider } from './blockContext';
import { reducer } from './hooks/useBlockListState';
import {
	AppmakerFormProvider,
	createAppmakerFormStore,
} from '../components/AppmakerForm/store';

const Edit = (props) => {
	// console.log({ Block, attributes, setAttributes, clientId,...props });
	const { Block, attributes, setAttributes, clientId, customProps = { } } = props;
	const blockProps = useBlockProps();
	// console.log("hello world");
	return (
		<div {...blockProps}>
			<BlockContextProvider
				initialState={{
					loading: true,
					tailLoading: false,
					items: [],
					data: {},
					page: 1,
					dsPaths: [],
				}}
				reducer={reducer}
			>
				{/* <Block attributes={attributes || {}}
                    coreDispatch={coreDispatch} /> */}
				{/* <BlockItem
                    block={{ attributes }}
                    // blockData={dataSourceData}
                    Block={{ View: Block }}
                    BlockItemRender={BlockItem}
                /> */}
				<Block {...props} {...customProps}/>
				{(Block?.attributesSchema || customProps?.attributesSchema)  && (
					<AppmakerFormProvider
						createStore={createAppmakerFormStore({
							formData: attributes,
						})}
					>
						<AttributesForm
							Block={Block}
							values={attributes}
							onChange={(formData) =>
								setAttributes({
									...formData,
								})
							}
							customAttributeSchema={customProps?.attributesSchema}
						/>
					</AppmakerFormProvider>
				)}
			</BlockContextProvider>
		</div>
	);
};

export default Edit;
