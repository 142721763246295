import { doAction, addAction } from '@wordpress/hooks';

const emitEvent = (name, ...params) => {
	doAction(name, ...params);
};
const onEvent = (name, handler) => {
	addAction(name, 'name-space', handler);
};

export { emitEvent, onEvent };
