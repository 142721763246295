// @flow strict

import React, { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import coreApi from '@appmaker/core/api/core';
import LoadingIndicator from '../components/LoadingIndicator';
import FormTabs from '../components/FormTabs'
import { setformSchema } from '../store/formSchema';
import { useProject } from '@appmaker/core/store/project';
import { setFormData } from '../store/formData';
import { createStore, Provider } from '@appmaker/core/components/AppmakerForm/store'
import { Toolbar } from '../components/Toolbar';


function AppDesign() {
    const { projectId } = useProject();

    const [{ value, loading }, doFetch] = useAsyncFn(
        async () => await coreApi.getCustomizeForm(projectId,'update'),
        []
    );
    useEffect(() => {
        doFetch();
    }, []);
    useEffect(() => {
        if (value) setformSchema(value.body);
        setFormData({ ...value?.body?.formData, ___isUpdateForm: true });
    }, [value]);
    return value === undefined || loading ? (
        <LoadingIndicator />
    ) : (
        <Provider createStore={createStore({
            formData: value?.body?.formData

        })}>
            <Toolbar />
            <FormTabs template={value?.body?.template} />
        </Provider>
    );
}

export default AppDesign;
