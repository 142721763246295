import { useState, useEffect } from 'react';
import { useProject } from '@appmaker/core/hooks';
import api from '../api';
import { setDataSource, useDataSource } from '../store'

const useDataSourceList = ({ refetch = true } = {}) => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    const setValue = useDataSource(data => data.setListValue);
    useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            try {
                const response = await api.getList({ projectId });
                const { dataSources = [] } = response;
                const data = dataSources.map(dataSource => {
                    return {
                        id: dataSource.id,
                        dataSourceId: dataSource.dataSourceId,
                        name: dataSource.name,
                        category: 'UI Kit',
                        href: '#',
                        price: dataSource.price === 0 ? 'Free' : `$ ${dataSource.price}`,
                        formSchema: dataSource.schema,
                        imageSrc: dataSource.image,
                        imageAlt: dataSource.description
                    }
                })
                setDataSource({ list: data, setListValue: true });
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
        if (!setValue || refetch) {
            fetchList()
        }

    }, [])

    return {
        isLoading
    }
}


export {
    useDataSourceList
}