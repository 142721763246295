import create from 'zustand'
import produce from 'immer'
// const unsub1 = createStore.subscribe(console.log)
import createContext from 'zustand/context'
import { devtools } from 'zustand/middleware'
import connectStoreApi from '../api/connect-store'
import { setPublicMeta } from '@appmaker/core/store/project'

function fix_url(url) {
    const newUrl = url.trim().toLowerCase()
    if (newUrl.substr(0, 7) === 'http://') { return newUrl; }
    if (newUrl.substr(0, 8) === 'https://') { return newUrl; }
    return 'http://' + newUrl;
}
export const MAX_PLUGIN_CHECK = 10;
const createStore = (initialState) => () => create(
    devtools((set, get) => ({
        ...initialState,
        url: initialState.url,
        pluginCheckingStatus: '',
        showInstallSteps: false,
        shouldCheckAgain: true,
        showSupportCard: false,
        urlUpdating: false,

        pluginStatusLoading: false,
        pluginStatus: {},
        helperText: '',
        errorText: '',
        validateWooCommerceSiteStatus: '',
        supportMessageText: {
            heading: '',
            content: ''
        },
        validateWooCommerceSiteTexts: {
            state: 'initial',
            heading: 'Validating website URL',
            content: 'Hold on while we validate your website URL.'
        },
        pluginInstallTexts: {
            title: 'Verifying Appmaker Plugin Installation',
            content: '.'
        },
        websiteCheckText: {},
        setUrl: (url) => set({ url }),
        setShowSupportCard: (showSupportCard) => set({ showSupportCard }),
        setPluginStatusLoading: (pluginStatusLoading) => set({ pluginStatusLoading }),
        setShouldCheckAgain: (shouldCheckAgain) => set({ shouldCheckAgain }),
        setPluginCheckingStatus: (pluginCheckingStatus) => set({ pluginCheckingStatus }),
        setFormData: (key, value) => set(produce(state => {
            state.formData[key] = value
        })),
        addAttempt: () => set(produce(state => {
            state.attempts = state.attempts + 1
        })),
        setData: (data) => set(data),
        setPluginStatus: (key, value) => set(produce(state => {
            state.pluginStatus[key] = value
        })),
        setState: (cb) => set(produce(cb)),
        isDefaultCheckComplete: () => {
            const attempts = get().attempts
            return attempts >= MAX_PLUGIN_CHECK
        },
        setStatus: (status) => {

        },
        setStatusFromRespons: (response) => set(produce(state => {
            // state.pluginStatus[key] = value
            // if () {
            if (response) {
                // state.pluginChecking = response
                state.pluginStatus.pluginInstalledVerfiedByAPI = response?.hasAppmakerWcPlugin
                state.pluginStatus.pluginInstalledinDb = response?.pluginInDb
                state.pluginStatus.validWordpressDetectedFromWpJSON = response?.validWp
                state.pluginStatus.statusCode = response.statusCode
                state.pluginStatus.response = response
                if (response.hasAppmakerWcPlugin) {
                    state.helperText = "It appears that the Appmaker plugin is not installed on your WordPress website. This step is mandatory to sync your website with the mobile app. Please follow the steps below and click Try again."
                }
            }
        })),
        shouldCheckPluginAgain() {
            return !get().isDefaultCheckComplete()
        },
        validateWooCommerceSite: async () => {

            set(produce(state => {
                state.validateWooCommerceSiteTexts.heading = 'Validating website URL'
                state.validateWooCommerceSiteTexts.content = 'Hold on while we validate your website URL.'
            }))

            set({ validateWooCommerceSiteStatus: 'loading' })
            // await new Promise(r => setTimeout(r, 2500));
            const validateUrlResponse = await connectStoreApi.validateUrl(get().projectId)
            await new Promise(r => setTimeout(r, 2000));

            // get().setStatusFromRespons(validateUrlResponse)
            if (validateUrlResponse.hasWooCommerce)
                // set({ validateUrlResponse, validateWooCommerceSiteStatus: 'success', showInstallSteps: true })
                set(produce(state => {
                    state.showInstallSteps = true
                    state.showSupportCard = false
                    state.validateUrlResponse = validateUrlResponse
                    state.validateWooCommerceSiteStatus = 'success';
                    state.validateWooCommerceSiteTexts.heading = 'URL Validation Successfull.'
                    state.validateWooCommerceSiteTexts.content = ''
                }))
            else {
                // set({ validateUrlResponse, validateWooCommerceSiteStatus: 'failed' })
                set(produce(state => {
                    state.showSupportCard = true
                    state.validateUrlResponse = validateUrlResponse
                    state.supportMessageText.heading = 'Are you sure your website is a Woocommerce store? '
                    state.supportMessageText.content = 'Security plugins present on your website could hinder the validation process done by our system. Our support team can get this sorted for you in no time.'
                    state.validateWooCommerceSiteStatus = 'failed';
                    state.validateWooCommerceSiteTexts.heading = 'URL Validation Unsuccessful.'
                    state.validateWooCommerceSiteTexts.content = 'Uh-oh! It appears that your website is not a woocommerce store. If there was an error in the URL entered, please use the edit button to correct the URL'
                }))
            }

        },
        checkPluginInstallStatus: async () => {
            get().setPluginStatusLoading(true)
            // get().setPluginCheckingStatus('loading')

            const pluginInstalledResponse = await connectStoreApi.validateUrl(get().projectId)
            get().setStatusFromRespons(pluginInstalledResponse)
            get().setPluginStatusLoading(false)

            if (pluginInstalledResponse.hasAppmakerWcPlugin) {

                set(produce(state => {
                    state.showSupportCard = false
                    state.pluginCheckingStatus = 'success'
                    // state.validateUrlResponse = validateUrlResponse
                    state.pluginInstallTexts.title = 'Appmaker Plugin Detected.'
                    state.pluginInstallTexts.content = 'You are just one step away from connecting your website to your App. Click on “Next” to enter your API Key.'

                }))
            }
            else {//
                set(produce(state => {
                    state.showSupportCard = true
                    state.pluginCheckingStatus = 'failed'
                    // state.validateUrlResponse = validateUrlResponse
                    state.pluginInstallTexts.title = 'Appmaker plugin not detected.'
                    state.pluginInstallTexts.content = 'It appears that the Appmaker plugin is not installed on your Woocommerce website. This step is mandatory to sync your website with the mobile app. Please follow the steps below and click “Verify Plugin Installation”'

                }))
            }
            set({ pluginInstalledResponse })
            get().addAttempt();
        },
        checkPluginInstallUntilLimit: async () => {
            const store = get()
            await store.checkPluginInstallStatus();

            if (store.shouldCheckPluginAgain() && !store.pluginStatus.pluginInstalledVerfiedByAPI)
                store.checkPluginInstallUntilLimit()
            else
                store.setShouldCheckAgain(false)

        },
        updateUrl: async () => {
            setPublicMeta({
                urlUpdated: true
            })
            set({ urlUpdating: true })
            set({ url: fix_url(get().url) })
            await connectStoreApi.updateStoreUrl(get().projectId, get().url)
            // await new Promise(r => setTimeout(r, 2500));
            set({ urlUpdating: false })
            get().validateWooCommerceSite()
            get().checkPluginInstallUntilLimit()
        },
        // setData:
    })
    ))

const { Provider, useStore, useStoreApi } = createContext()

export { createStore, Provider, useStore, useStoreApi }
