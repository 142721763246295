import create from 'zustand';
import { dispatch, select } from '@wordpress/data';
import { filterValidBlocks } from 'packages/blockEditor/lib/filterValidBlocks';
import { setPublicMeta } from '@appmaker/core/store/project';

const modal = create((set) => ({
    isOpen: false,
    setOpen: (value) => set({ isOpen: value }),
}))

const useModal = () => {
    const set = modal(state => state.setOpen);
    const get = modal(state => state.isOpen)
    return [get, set]
}

const useData = create((set) => ({
    originalTemplate: [],
    templates: [],
    isLoading: true,
    setTemplate: (data) => set((state) => ({ ...state, templates: data })),
    setOriginalTemplate: (data) => set((state) => ({ ...state, originalTemplate: data })),
    selectedThemeCategory: undefined,
    previewTemplateId: undefined,
    setPreviewTemplate: (templateId) => set((state) => ({ ...state, previewTemplateId: templateId })),
    setSelectedThemeCategory: (category) => set((state) => ({ ...state, selectedThemeCategory: category })),
    resetTemplate: () => set((state) => ({ ...state, templates: state.originalTemplate, selectedThemeCategory: undefined })),
    isTemplateSetting: false,
    setIsTemplateSetting: (value) => set((state) => ({ ...state, isTemplateSetting: value }))
}))

const setInAppPage = (blocks) => dispatch('core/block-editor').resetBlocks(
    filterValidBlocks(blocks)
);
const getBlockCount = () => select('core/block-editor').getBlockCount();

const setBlankProject = () => {
    setInAppPage([]);
    setPublicMeta({ template_chooser_popup_completed: true })
}
const setInAppPageToTemplate = async (blocks) => {
    useData.setState({ isTemplateSetting: true })
    setInAppPage(blocks);
    setPublicMeta({ template_chooser_popup_completed: true })
    useData.setState({ isTemplateSetting: false })
}
export {
    useModal,
    useData,
    setBlankProject,
    setInAppPageToTemplate,
    getBlockCount
}