import React, { useState, useEffect } from 'react';
import FileUpload from '@appmaker/components/Form/fields/FileUpload';
import Gallery from './Gallery';
import isEmpty from 'lodash/isEmpty';
import { listMedia } from 'packages/shopify/api/media';
import { uploadToServer } from 'packages/shopify/helper/upload';
import { useMediaStore } from './store/context';

const generateAcceptedFile = (mediaTypes = []) => {
	let acceptedFile = '';
	mediaTypes?.forEach((media) => {
		if (acceptedFile?.length === 0) {
			acceptedFile += media ? `${media}/*` : '';
		} else {
			acceptedFile += media ? `,${media}/*` : '';
		}
	});
	if (acceptedFile?.length === 0) {
		acceptedFile = '*';
	}
	return acceptedFile;
};

export default function ShopifyMediaLibrary({
	onSelect = () => {},
	mediaTypes = [],
	dropzoneHelperText,
	acceptedFile: acceptedFileFromParams
}) {
	const searchText = useMediaStore((state) => state.searchText);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);
	let acceptedFiles = generateAcceptedFile(mediaTypes);

	const loadImages = async ({ updateImages = true, doSearch = true }) => {
		try {
			if (updateImages) {
				setLoading(true);
			}
			const search = !doSearch || isEmpty(searchText) ? undefined : searchText;
			const resp = await listMedia({ search, mediaTypes });

			const newImages = resp.map((file) => ({
				...file,
				url: file.url,
				id: file.id,
				imageUrl: file.url,
				title: file.alt,
				fileName: file.alt,
				meta: {
					height: file.height,
					width: file.width,
				},
				contentType: file?.mediaContentType,
			}));
			if (updateImages) {
				setImages(newImages);
				setLoading(false);
			}
			return newImages;
		} catch (error) {}
	};
	useEffect(() => {
		loadImages({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);
	// console.log("isMultiSelectActive", isMultiSelectActive)
	return (
		<>
			<FileUpload
				name={'icon'}
				onChange={console.log}
				onError={console.log}
				uiSchema={{
					'ui:options': {
						accept: acceptedFileFromParams ?? acceptedFiles ,
					},
				}}
				formContext={{
					onFileUpload: async (item, file) => {
						await uploadToServer({
							onError: (data) => {
								console.log(data);
							},
							filesList: file,
							onFileChange: (filesUplaoded) => {
								if (filesUplaoded.length > 0) {
									loadImages({});
									onSelect(filesUplaoded[0]);
									// onSelect(isMultiSelectActive ? filesUplaoded : filesUplaoded[0]);
								} else {
									loadImages({});
								}
							},
						});
					},
				}}
				schema={{ helperText: dropzoneHelperText ?? 'PNG, JPG, GIF up to 2MB', inner: true }}
			/>
			{loading ? (
				<GalleryLoading n={10} />
			) : (
				<Gallery schema={{ files: images }} />
			)}
		</>
	);
}
ShopifyMediaLibrary.media = {
	title: 'Media Library',
};

const GalleryLoading = ({ n = 8 }) => {
	return (
		<ul className="grid grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 animate-pulse my-4">
			{[...Array(n)].map((e, i) => (
				<li key={i} className="bg-gray-200 w-full h-28 rounded-md" />
			))}
		</ul>
	);
};
