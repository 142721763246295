import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	useLocation,
	useParams,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Dashboard from './routes/Dashboard';
import Playground from './routes/Playground';
import MyApps from './routes/MyApps';
import { NotificationContainer } from 'components/Notification';
import PrivateRoute from 'components/PrivateRoute';
import appmaker from '@appmaker/core/index';
import { QueryParamProvider } from 'use-query-params';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function RedirectToNewPath() {
	const location = useLocation();
	const params = useParams();
	const { projectId } = params;
	const routerPath = useRouterPath();
	const newPath = location.pathname.replace(`/${projectId}/dashboard`, '');
	return <Redirect from={location.pathname} to={routerPath(newPath)} />;
}

function RedirectToOldDashboard() {
	const location = useLocation();
	const { pathname } = location;
	let redirectLocation = `https://manage-v2.appmaker.xyz${pathname.replace(
		'old-dashboard',
		'apps'
	)}`;
	if (window.shopifyHost) {
		redirectLocation += `?host=${window.shopifyHost}&shopifySessionToken=${window.shopifySessionToken}`;
	}
	window.location = redirectLocation;
	return null;
}
const queryClient = new QueryClient()
window.queryClient = queryClient;
function App() {
  const history = createBrowserHistory();

  useEffect(() => {
    appmaker.doAction('appmaker-analytics-track-page-view');
    history.listen(() => {
      appmaker.doAction('appmaker-analytics-track-page-view');
    });
  }, [history]);


  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>

        <NotificationContainer />
        <Switch>
          <PrivateRoute path="/playground">
            <Playground />
          </PrivateRoute>
          <PrivateRoute path="/:projectId/playground">
            <Playground />
          </PrivateRoute>
          <PrivateRoute path="/apps/:projectId">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/:projectId/dashboard">
            <RedirectToNewPath />
          </PrivateRoute>
          <PrivateRoute path="/old-dashboard/:projectId">
            <RedirectToOldDashboard />
          </PrivateRoute>
          <PrivateRoute path="/" exact>
            <MyApps />
          </PrivateRoute>
          {appmaker.allRootRoutesArray().map((item) => {
            const Component = item.component;
            return (
              item.isPrivate ?
                (<PrivateRoute key={item.routeName} path={`${item.routeName}`} exact>
                  <Component />
                </PrivateRoute>) :
                (<Route key={item.routeName} path={item.routeName} exact>
                  <Component />
                </Route>)
            );
          })}
        </Switch>
      </QueryParamProvider>
    </Router>
    </QueryClientProvider>
  );
}

export default App;
