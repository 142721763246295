import ColorsGroup, { ColorPickerField } from './ColorsGroup';
import FileUpload from './FileUpload';
import FieldGroup from './FieldGroup';
import AppmakerSwitch from './AppmakerSwitch';
import AppmakerRadioGroup from './RadioGroup';
import Input from './TextInput';
import LanguageEditor from './LanguageEditor/index';
import CheckBox from './CheckBox';
import HomeScreenChooser from './HomeScreenChooser';

// import ColorPicker from './components/ColorPicker';

const fields = {
	ColorsGroup,
	LaunchScreen: FieldGroup,
	FieldGroup,
	FileUpload,
	ColorPicker: ColorPickerField,
	Input,
	Switch: AppmakerSwitch,
	RadioGroup: AppmakerRadioGroup,
	LanguageEditor,
	CheckBox,
	HomeScreenChooser,
};
export { fields };
