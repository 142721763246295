import Editor from "@monaco-editor/react";
import { useEffect, useRef, useState } from "react";
import dayjs from 'dayjs';
import { useLocalStorage } from "react-use";
import ejs from 'ejs'
const parseTemplate = ({ template, data: _data }) => {
    const outPutData = { ...template };
    const data = { ..._data, dayjs };
    const attributeKeys = Object.keys(outPutData);
    attributeKeys.map((item) => {
        let currentItem = outPutData[item];
        if (typeof currentItem === 'string') {
            currentItem = currentItem.replace(new RegExp('{{', 'g'), '<%-');
            currentItem = currentItem.replace(new RegExp('}}', 'g'), '%>');
            outPutData[item] = ejs.render(currentItem, data, { outputFunctionName: 'echo' });
            /**
             * Bad code; Need a lot of optimization
             */
            if (outPutData[item].match(/object Object/)) {
                currentItem = currentItem.replace('<%-', '<%- JSON.stringify(');
                currentItem = currentItem.replace('%>', ')%>');
                outPutData[item] = ejs.render(currentItem, data);
            }
            if (currentItem.match('JSON.stringify')) {
                outPutData[item] = JSON.parse(outPutData[item]);
            }
        } else if (typeof currentItem === 'object') {
            const out = parseTemplate({ template: currentItem, data });
            outPutData[item] = out;
        }

        return null;
    });
    return outPutData;
};

export default function VSCodeEditor() {
  const [fileContent, setFileContent] = useLocalStorage("ejs-file-content");
  const [jsonConfig, setJsonConfig] = useLocalStorage("ejs-json-config");
  const [output, setOutput] = useState("");
  const [language, setLanguage] = useState("ejs");

  const fileContentEditor = useRef(null);
  const jsonConfigEditor = useRef(null);
  const outputEditor = useRef(null);
  useEffect(() => {
    try {
        const templateOutput = parseTemplate({template:{fileContent}, data: JSON.parse(jsonConfig)});
        setOutput(JSON.stringify(templateOutput.fileContent));
    } catch (error) {
        setOutput(JSON.stringify(error.message));

    }
  }, [fileContent, jsonConfig]);
  useEffect(() => {
    if (outputEditor && outputEditor.current)
      outputEditor.current.setValue(output);
  }, [output]);

  return (
    <div className="grid h-screen grid-cols-2 overflow-hidden p-2">
      <div className="flex flex-col ">
        <div className="flex-1 border-2 overflow-auto ">
          Template 
          <Editor
            onMount={(editor) => {
              fileContentEditor.current = editor;
            }}
            height="49vh"
            defaultLanguage="javascript"
            defaultValue={fileContent}
            onChange={setFileContent}
          />
        </div>
        <div className="flex-1 overflow-auto ">
          <div className="flex-1 overflow-auto">
            <div className="flex items-center justify-between p-2">
              <p>JSON</p>
              <button
                onClick={() => {
                  jsonConfigEditor.current
                    .getAction("editor.action.formatDocument")
                    .run();
                }}
                className="rounded bg-blue-600 px-3 py-1 text-white"
              >
                Format
              </button>
            </div>
          </div>
          <Editor
            onMount={(editor) => {
              jsonConfigEditor.current = editor;
            }}
            height="49vh"
            defaultLanguage="json"
            defaultValue={jsonConfig}
            onChange={setJsonConfig}
          />
        </div>
      </div>
      <div className="overflow-auto bg-gray-200">
        {output}
        {/* <Editor
          onMount={(editor) => {
            outputEditor.current = editor;
          }}
          height="100vh"
          defaultLanguage="text"
          defaultValue={output}
        /> */}
      </div>
    </div>
  );
}
