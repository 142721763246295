
import coreApi from '@appmaker/core/api/core';

/**
 * To build project app
 * @param {String/Number} projectId 
 * @param {Function} setLoading 
 */
export const buildProject = async (projectId,setLoading=()=>{})=>{
    try {
        setLoading(true);
    const buildProject = await coreApi.buildProject(projectId);
    if(buildProject.status){
        setLoading(false);
        // let redirectLocation =  `https://manage-v2.appmaker.xyz/apps/${projectId}`
        let redirectLocation =  `https://v3.appmaker.xyz/apps/${projectId}`
        if (window.shopifyHost) {
            redirectLocation += `?host=${window.shopifyHost}&shopifySessionToken=${window.shopifySessionToken}`;
        }
        window.location = redirectLocation;
    }
    } catch (error) {
        
    }
}