import React from 'react'
const Webview = ({ attributes = {} }) => {
	return (
		<div className="py-2 pl-3 pr-6">
			<p>Web View</p>
			
		</div>
	);
};

Webview.attributesSchema = {
	type: 'object',
	properties: {
		source: {
			type: 'json',
			label: 'Source',
			default: '',
		},
		height: {
			type: 'string',
			label: 'height',
			default: '',
		},
		__display: {
			type: 'string',
			label: 'Display',
			default: 'true',
		}
	},
};

export default Webview;
