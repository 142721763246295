// @flow strict

import * as React from 'react';


function DashboardLoading() {
    return <div className="h-screen overflow-hidden flex bg-white">
        {/* <div className="sm:flex sm:flex-col sm:gap-4 sm:w-64 p-4 h-screen overflow-hidden bg-gray-50 animate-pulse hidden">
            <div className="flex gap-4 items-center">
                <div className="h-16 w-16 rounded-2xl bg-gray-200" />
                <div className="inline-flex w-24 h-6 rounded-lg bg-gray-200" />
            </div>
            <div className="h-6 w-full rounded-md bg-gray-200" />
            <div className="h-6 w-full rounded-md bg-gray-200" />
            <div className="h-6 w-full rounded-md bg-gray-200" />
            <div className="h-6 w-full rounded-md bg-gray-100" />
            <div className="h-6 w-full rounded-md bg-gray-100" />
            <div className="h-6 w-full rounded-md bg-gray-100" />
            <div className="h-6 w-full rounded-md bg-gray-50" />
            <div className="h-6 w-full rounded-md bg-gray-50" />
            <div className="h-6 w-full rounded-md bg-gray-50" />
        </div> */}
        <div className="flex-1 overflow-hidden animate-pulse m-6 gap-4 flex flex-col">
            <div className="h-48 w-full bg-gray-200 rounded-lg" />
            <div className="h-48 w-full bg-gray-100 rounded-lg" />
            <div className="h-48 w-full bg-gray-50 rounded-lg" />
        </div>
    </div>

}
export default DashboardLoading;