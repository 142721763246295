/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow strict

import * as React from 'react';

function ContactSupport({ title = <>
    <span className="block">Feeling lost?</span>
    <span className="block">We're here to help.</span>
</>,
    content = `Don't worry though, everything is STILL AWESOME! Our Support team will have you sorted in no time.
"Contact support button"`
}) {
    return (
        <div className="bg-blue-50 mx-auto p-8 shadow-xl rounded-lg overflow-hidden">
            <div className="lg:grid lg:grid-cols-1 lg:gap-4">
                <div className="">
                    <div className="lg:self-center">
                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-800 sm:text-4xl">

                            {title}                        </h2>

                        <p className="mt-4 text-lg leading-6 text-blue-900">
                            {content}
                        </p>
                        <div className="mt-8">
                            <div className="inline-flex rounded-md shadow">
                                <a
                                    onClick={() => {
                                        try {
                                            window.Intercom('showNewMessage', "Help me with URL verification")
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }}

                                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                >
                                    Contact Support
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                    <img
                        className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                        src="/images/help.png"
                        draggable={false}
                        alt="App screenshot"
                    />
                </div> */}
            </div>
        </div>
    );
};

export default ContactSupport;