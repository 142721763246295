import { TemplateIcon } from '@heroicons/react/solid';
import { useModal } from '../hooks'
import { getProjectId } from '@appmaker/core/store/project';
import appmaker from '@appmaker/core/index';

const TemplateChooserButton = () => {
    const [, setModal] = useModal();
    const projectId = getProjectId();

    return <button
        onClick={() => {
            appmaker.doAction('appmaker-analytics-event', {
                event: `template_chooser_button_clicked`,
                params: {
                    projectId,
                    insideShopify: !!window.shopifyApp
                }
            });
            setModal(true)
        }}
        className=
        "justify-center p-2 text-sm font-medium text-gray-800 bg-blue-200 rounded-md hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >

        <TemplateIcon
            className="w-5 h-5 text-blue-700 group-hover:text-blue-900"
            aria-hidden="true"
        />
    </button>
}

export default TemplateChooserButton