import {
	createStore,
	AppmakerFormProvider,
	useStore,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useEffect } from 'react';

const FormSchema = ({ schema, onChange = () => {} }) => {
	// const storeData = useStore();

	// useEffect(() => {
	// 	onChange(storeData.formData);
	// }, [storeData.formData]);

	return (
		<div className="p-4 border-t-0 border border-gray-200 rounded-b-md">
			<div className="max-w-3xl -mt-4">
				<AppmakerForm
					formVersion={2}
					// value={{ formData: flatten(formData) }}
					schema={schema}
				/>
			</div>
		</div>
	);
};

const EditForm = ({ schema = {}, onChange = () => {} }) => {
	return <FormSchema schema={schema} onChange={onChange} />;
};

export default EditForm;
