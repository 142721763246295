import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [
	{
		attributes: {
			appmakerAction: {},
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-15.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 575,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53220',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-14.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 575,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53221',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-9.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 575,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53222',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-8.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 575,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53223',
				isValid: true,
			},
		],
		clientId: 'widget_key_5322',
		name: 'appmaker/slider',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Categories',
			itemsPerLine: '3',
			showViewMoreButton: false,
			showTitle: true,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-4.png',
					},
					imageContainerStyle: {
						width: 356,
						height: 356,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: '79OhFo',
							id: '79OhFo',
							label: 'Cactus',
						},
						params: {
							value: '79OhFo',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130020',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-5.png',
					},
					imageContainerStyle: {
						width: 356,
						height: 356,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'W8eN2Y',
							id: 'W8eN2Y',
							label: 'Bonsai',
						},
						params: {
							value: 'W8eN2Y',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130021',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-6.png',
					},
					imageContainerStyle: {
						width: 356,
						height: 356,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'g4Kxoh',
							id: 'g4Kxoh',
							label: 'Medicinal Plants',
						},
						params: {
							value: 'g4Kxoh',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130022',
				isValid: true,
			},
		],
		clientId: 'widget_key_13002',
		isValid: true,
		name: 'appmaker/grid',
	},
	{
		attributes: {
			image: {
				url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-6-2.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 428,
			},
			appmakerAction: {
				action: 'OPEN_URL',
				OPEN_URL_url:
					'https://www.bhg.com/gardening/yard/garden-care/ten-steps-to-beginning-a-garden/',
				params: {
					value:
						'https://www.bhg.com/gardening/yard/garden-care/ten-steps-to-beginning-a-garden/',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: '579c084a-c9a2-484b-88d1-e18b45ea96fa',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Best Selling Products',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			id: 'best_selling',
			labelId: 'Best Selling Products',
			hasChild: false,
		},
		innerBlocks: [],
		clientId: 'widget_key_32982',
		isValid: true,
		name: 'appmaker/prebuildproductscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-12-1.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 429,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: 18,
					id: 18,
					label: 'Plants',
				},
				params: {
					value: 18,
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: 'widget_key_29792',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {
				action: 'OPEN_BRANDS',
			},
			title: 'Recent',
			showTitle: true,
			showViewMoreButton: true,
			ctaText: 'View more',
			id: 'recent',
			labelId: 'Recent Products',
			hasChild: false,
		},
		innerBlocks: [],
		clientId: '1f5d4b35-d015-4df3-8fbb-0430523e0e26',
		isValid: true,
		name: 'appmaker/prebuildproductscroller',
	},
	{
		attributes: {
			appmakerAction: {
				action: 'NO_ACTION',
				params: {},
			},
			title: 'Featured Products',
			showTitle: true,
			showViewMoreButton: true,
			ctaText: 'View more',
			id: 'best_selling',
			labelId: 'Best Selling Products',
			hasChild: false,
		},
		innerBlocks: [],
		clientId: '04ae2e3e-854f-4be8-8063-39c6877c6bbc',
		isValid: true,
		name: 'appmaker/prebuildproductscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Menu-drawe-header-–-7-1.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 428,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: 18,
					id: 18,
					label: 'Plants',
				},
				params: {
					value: 18,
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: 'c1a78928-36ac-457a-be1f-abeb5eaeb384',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {
				action: 'OPEN_BRANDS',
			},
			title: 'Top Deals',
			showTitle: true,
			showViewMoreButton: true,
			ctaText: 'View more',
			id: 'category',
			labelId: 'Open Products in a category',
			hasChild: true,
			childId: {
				id: 16,
				label: 'Cactus',
			},
		},
		innerBlocks: [],
		clientId: '4ae444e0-5f1b-471f-925c-80a7f6d0a6d1',
		isValid: true,
		name: 'appmaker/prebuildproductscroller',
	},
	{
		attributes: {
			appmakerAction: {},
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
			title: 'Offers for you ',
			showTitle: true,
		},
		innerBlocks: [
			{
				attributes: {
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-1-4.png',
					},
					imageContainerStyle: {
						width: 395,
						height: 176,
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/imagescroller-item',
				clientId: 'dd5f3ba7-3575-41be-a96f-23dac250fcf90',
				isValid: true,
			},
			{
				attributes: {
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-2-2.png',
					},
					imageContainerStyle: {
						width: 400,
						height: 179,
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/imagescroller-item',
				clientId: 'dd5f3ba7-3575-41be-a96f-23dac250fcf91',
				isValid: true,
			},
			{
				attributes: {
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					image: {
						url: 'https://akshay.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-3-2.png',
					},
					imageContainerStyle: {
						width: 394,
						height: 175,
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/imagescroller-item',
				clientId: 'dd5f3ba7-3575-41be-a96f-23dac250fcf92',
				isValid: true,
			},
		],
		clientId: 'dd5f3ba7-3575-41be-a96f-23dac250fcf9',
		isValid: true,
		name: 'appmaker/imagescroller',
	},
];
// function generate() {

// }
const template = {
	blocks,
	id: 1,
	name: 'Plant',
	category: 'Theme',
	price: 'Free',
	imageSrc: '/images/themes/plant.png',
	imageAlt: 'Mobile app plant theme',
};
export default template;
