const GridItem = ({ attributes, children }) => {
	const { image } = attributes;
	return (
		<div>
			<div className="group block w-full h-full rounded bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
				<img
					src={image}
					alt=""
					className="object-cover w-full pointer-events-none group-hover:opacity-75"
				/>
			</div>
			{children}
		</div>
	);
};

export default GridItem;
