import {
	ChevronRightIcon,
	ThumbUpIcon,
} from '@heroicons/react/outline';
import { useModal } from '../hooks';
import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project';
import { setPublicMeta } from '@appmaker/core/store/project';
import { useState } from 'react';
const FormSuccess = ({ selfServe = false }) => {
	const [isLoading, setLoading] = useState(false);
	const [, setModal] = useModal();
	const projectId = getProjectId();
	const onClickExit = async () => {
		setLoading(true);

		appmaker.doAction('appmaker-analytics-event', {
			event: `getting_started_form_dashboard_final_button_click`,
			params: {
				projectId,
				insideShopify: !!window.shopifyApp
			}
		});

		try {
			await setPublicMeta({ initial_form_setup_completed: true })
		} catch (error) {

		}
		setLoading(false);
		setModal(false)
	}
	return (
		<div className="grid grid-cols-5">
			<div className="bg-green-50 hidden sm:col-span-2 sm:flex sm:justify-end sm:items-end">
				<img src="/images/misc/welcome-image.png" alt="info" />
			</div>
			<div className="bg-white p-6 flex flex-col col-span-full sm:col-span-3 justify-center">
				<ThumbUpIcon className="h-16 w-16 text-green-600 p-4 bg-green-100 rounded-full mb-8" />

				{!selfServe ?
					(<div className="flex-1 text-gray-700"> <h3 className="text-2xl font-medium mb-2 text-gray-900">
						Thank you for your interest.
					</h3>
						<p className="mb-2">
							We will review your submission and get back to you as soon as we
							can.
						</p>
						<p>
							Meanwhile, feel free to continue exploring Appmaker. We are happy to
							guide you along the way.
						</p> </div>) :
					<div className="flex-1 text-gray-700"><h3 className="text-2xl font-medium mb-2 text-gray-900"> Feel free to continue exploring Appmaker. We are happy to
						guide you along the way. </h3> </div>}
			<button
				type="button"
				disabled={isLoading}
				className="inline-flex items-center mt-10 self-end px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				onClick={onClickExit}
			>
				{isLoading ? 'Loading...' : 'Explore Appmaker'}
				<ChevronRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
			</button>
		</div>
		</div >
	);
}
export default FormSuccess;