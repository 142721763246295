// import { FullscreenBar, Button, DisplayText } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import { LogoutIcon, ArrowsExpandIcon } from '@heroicons/react/solid';
import { Fullscreen } from '@shopify/app-bridge/actions';

export default function SideBarTop({ fullscreen }) {
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    if (window.fullscreen) {
      const unsubscribeEnter = fullscreen.subscribe(Fullscreen.Action.ENTER, () => {
        console.log("Enter Fullscreen");
        setFullscreen(true);
      });
      const unsubscribeExit = fullscreen.subscribe(Fullscreen.Action.EXIT, () => {
        console.log("Exit Fullscreen");
        setFullscreen(false);
      });
      return () => {
        unsubscribeEnter();
        unsubscribeExit();
      }
    }
  }, []);

  const enterFullScreen = useCallback(() => {
    if (window.fullscreen) {
      fullscreen.dispatch(Fullscreen.Action.ENTER);
    }
    setFullscreen(true);
  }, []);

  const exitFullScreen = useCallback(() => {
    if (window.fullscreen) {
      fullscreen.dispatch(Fullscreen.Action.EXIT);
    }
    setFullscreen(false);
  }, []);

  // <FullscreenBar onAction={handleActionClick} />;
  return (
    isFullscreen ?
      (<button onClick={exitFullScreen} className="flex items-center space-x-2 bg-gray-100 p-2.5 text-gray-500 hover:bg-gray-200 group">
        <LogoutIcon className="h-8 w-8 p-1 bg-gray-50 rounded transform rotate-180 border border-gray-200 group-hover:bg-gray-100" />
        <span className="text-sm font-medium">Back to Shopify</span>
      </button>) :
      (<button onClick={enterFullScreen} className="flex items-center space-x-2 bg-gray-100 p-2.5 text-gray-500 hover:bg-gray-200 group">
        <ArrowsExpandIcon className="h-8 w-8 p-1 bg-gray-50 rounded transform rotate-180 border border-gray-200 group-hover:bg-gray-100" />
        <span className="text-sm font-medium">Fullscreen</span>
      </button>)
  )
}