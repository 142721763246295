import { PlusCircleIcon } from '@heroicons/react/solid';

const ProductsScrollerItem = ({ attributes }) => {
	const { product, groceryMode, quickAdd, width = 'w-32' } = attributes;
	return (
		<div
			key={product.id}
			className={`group relative bg-white rounded-md border border-gray-100 mt-2 ${width}`}
		>
			{quickAdd && (
				<PlusCircleIcon className="h-8 w-8 text-red-600 absolute bg-white rounded-full -right-3 -top-2" />
			)}
			<div className={`rounded-md h-auto overflow-hidden bg-gray-200 ${width}`}>
				<img
					src={product.src}
					alt={product.title}
					className="w-full h-full object-center object-cover"
				/>
			</div>
			<div className="px-2 pt-2 w-full">
				<h3 className="text-sm font-medium text-gray-900 line-clamp-1 overflow-hidden">
					{product.title}
				</h3>
				<span className="mt-0.5 flex items-center gap-1">
					<p className="text-base font-bold text-gray-900">
						{product.price_display}
					</p>
					{/* <p className="text-xs font-medium text-green-500 line-through">
						{product.price_display}
					</p> */}
				</span>
			</div>
			{/* {groceryMode && (
				<div className="flex justify-center mt-2 p-2 border-t border-gray-200">
					<div className="font-medium text-sm px-2 py-1 border border-gray-200 text-center rounded text-gray-500">
						+ ADD
					</div>
				</div>
			)} */}
		</div>
	);
};

export default ProductsScrollerItem;
