import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks =[
    {
        "clientId": "b89d1668-322a-417b-b6d3-bc19a5aa4b73",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_98f206b9-55d2-4203-8c6e-193e45e2edd9.png?v=1659692813",
                "id": "gid://shopify/MediaImage/31105744634113",
                "title": "a.png"
            },
            "imageContainerStyle": {
                "height": 120,
                "width": 1242
            },
            "thumbnail_meta": {
                "height": 120,
                "width": 1242
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "8487c2bd-67ef-4d83-82fc-62f3fda92b82",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "97"
        },
        "innerBlocks": [
            {
                "clientId": "7e65abad-deda-4812-9f7c-6887e866ccf7",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_d65fffe7-2f82-4182-8839-0b0be328f7dd.png?v=1659693147",
                        "id": "gid://shopify/MediaImage/31105775534337",
                        "title": "b.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 309
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "db440840-8aba-45d4-bb85-00227f652673",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-1_c430e9b5-8c70-4667-b1c9-719004d1cf67.png?v=1659693146",
                        "id": "gid://shopify/MediaImage/31105775337729",
                        "title": "b-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "6c410dbb-3c3a-4573-b303-e4268b8d3404",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-2_a4e32cc8-df9d-494e-831a-4894effe1a14.png?v=1659693145",
                        "id": "gid://shopify/MediaImage/31105775108353",
                        "title": "b-2.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "ee914d00-5df8-4857-9ae0-1b5eacb6403a",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-5_7ba4b830-2ba9-4f6f-bd61-8228b1f29783.png?v=1659693142",
                        "id": "gid://shopify/MediaImage/31105774354689",
                        "title": "b-5.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "eb61ed57-49b2-4a6a-b6a8-652fd56e5863",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-3_8a1b79aa-f540-4ed7-b7c9-e53fb7b7dcbe.png?v=1659693143",
                        "id": "gid://shopify/MediaImage/31105774813441",
                        "title": "b-3.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "77351425-1063-47d1-94da-330a920b4110",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-6_b46119c4-27dc-49d5-9d87-00d5cceb5893.png?v=1659693142",
                        "id": "gid://shopify/MediaImage/31105774289153",
                        "title": "b-6.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "4ff29ce1-a9ee-473f-8ab8-f14f77f71996",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-4_bd306565-ca10-4a0a-ba26-e89b14a428de.png?v=1659693142",
                        "id": "gid://shopify/MediaImage/31105774321921",
                        "title": "b-4.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 417,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "282fd1b2-cb86-4ff3-a2ac-70396ca17c44",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-13.png?v=1659693169",
                "id": "gid://shopify/MediaImage/31105778090241",
                "title": "c-13.png"
            },
            "imageContainerStyle": {
                "height": 1560,
                "width": 1242
            },
            "thumbnail_meta": {
                "height": 1560,
                "width": 1242
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "3f2e6044-4a7b-4103-bf7c-f9fd46b8c7b4",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "197"
        },
        "innerBlocks": [
            {
                "clientId": "279684a6-2c0b-4e5f-951e-9e3454983fb1",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-1_2ad03093-6b51-41e1-8050-6a4930482496.png?v=1659693194",
                        "id": "gid://shopify/MediaImage/31105780646145",
                        "title": "d-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 831,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "7d7ec086-50ee-493f-8cfe-20649eeb3d69",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d_ed311f73-920e-4d5d-9a7a-e13858cc7db6.png?v=1659693194",
                        "id": "gid://shopify/MediaImage/31105780613377",
                        "title": "d.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 831,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "6059abd4-ec4e-4d59-8e39-94160a2e66b6",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "197"
        },
        "innerBlocks": [
            {
                "clientId": "b68101e8-ea05-4d3f-baff-c649f59f104a",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-3_bd911e86-e946-4d8f-998c-9427c7cb025d.png?v=1659693212",
                        "id": "gid://shopify/MediaImage/31105782841601",
                        "title": "d-3.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 831,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "df2dce47-f40e-4ec3-bee1-c5516326fb37",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-2_b6a6ec9d-fdb2-4087-b68f-cc2ffc25b61b.png?v=1659693212",
                        "id": "gid://shopify/MediaImage/31105782808833",
                        "title": "d-2.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 831,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "dcf06c7f-ad63-4d61-ba2a-b0e02a3aeca7",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_339047dc-d45a-4028-bf63-60c1f4c1e88e.png?v=1659693284",
                "id": "gid://shopify/MediaImage/31105790116097",
                "title": "f.png"
            },
            "imageContainerStyle": {
                "height": 120,
                "width": 1242
            },
            "thumbnail_meta": {
                "height": 120,
                "width": 1242
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "14ef249f-6e29-4f65-822d-5d56f892c33d",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_9076f357-7896-42e1-b13a-5ce9d3047c5a.png?v=1659693300",
                "id": "gid://shopify/MediaImage/31105791295745",
                "title": "g.png"
            },
            "imageContainerStyle": {
                "height": 1875,
                "width": 1242
            },
            "thumbnail_meta": {
                "height": 1875,
                "width": 1242
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "f22650e0-7340-4f9b-befb-bc07d8e9be47",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e_5e194628-9801-483b-963c-761b38906520.png?v=1659693271",
                "id": "gid://shopify/MediaImage/31105788543233",
                "title": "e.png"
            },
            "imageContainerStyle": {
                "height": 120,
                "width": 1242
            },
            "thumbnail_meta": {
                "height": 120,
                "width": 1242
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "ee0a3a37-d1ab-4291-b9c2-6174aee0ee3e",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "100"
        },
        "innerBlocks": [
            {
                "clientId": "af632ef7-f8d4-4a52-9c27-847242e36db0",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-1_14cd7758-0636-44d2-973b-5acca5173295.png?v=1659693317",
                        "id": "gid://shopify/MediaImage/31105792540929",
                        "title": "c-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 321,
                        "width": 309
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "7c5094bd-5fed-448c-8bc6-536c3d3bc360",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-2_b0c9330f-43ff-4105-bad0-ffa0b48abfa7.png?v=1659693317",
                        "id": "gid://shopify/MediaImage/31105792606465",
                        "title": "c-2.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 321,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "52233b30-ca20-4347-9b7d-4ee684ee5648",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-3_95873592-05f8-4213-b015-1b22eabbd494.png?v=1659693317",
                        "id": "gid://shopify/MediaImage/31105792639233",
                        "title": "c-3.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 321,
                        "width": 309
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "7e0b2521-95d9-4f1b-8ae5-9c4ceaf0ebb6",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-4_5870f807-82b4-4d9a-87da-6dab4b4f4c09.png?v=1659693322",
                        "id": "gid://shopify/MediaImage/31105792803073",
                        "title": "c-4.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 321,
                        "width": 312
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "725a1af8-8d9e-4e48-93ce-a1a41c6e3859",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "197"
        },
        "innerBlocks": [
            {
                "clientId": "2dae2dd9-394c-40fd-8e09-e06a49f9158a",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_58279352-f0a1-4be5-9796-73f78e36dd1a.png?v=1659693424",
                        "id": "gid://shopify/MediaImage/31105803124993",
                        "title": "c.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 804,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "fb1a4037-0d5b-4296-937a-ec05620b069e",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-12.png?v=1659693424",
                        "id": "gid://shopify/MediaImage/31105803092225",
                        "title": "c-12.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 804,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "f079eb28-e377-4502-b88a-19a5a920ca3a",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "197"
        },
        "innerBlocks": [
            {
                "clientId": "33dc4dc9-e92e-4bc5-9722-3a79d7f37971",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-8.png?v=1659693441",
                        "id": "gid://shopify/MediaImage/31105804337409",
                        "title": "c-8.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 759,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "e48dafd7-c17e-486e-b4d6-6a8922ab26d8",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-9.png?v=1659693442",
                        "id": "gid://shopify/MediaImage/31105804370177",
                        "title": "c-9.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 759,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "f63e6a3c-1273-4a9d-99e8-fd2d2b2bbe3d",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "130"
        },
        "innerBlocks": [
            {
                "clientId": "52e3decf-3287-43a2-a7e0-64b6c667b52a",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-5_478839da-b3c4-4bb6-bf36-a8c24e797050.png?v=1659693525",
                        "id": "gid://shopify/MediaImage/31105812431105",
                        "title": "c-5.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 135,
                        "width": 414
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "65bd8983-7264-4611-8696-8fdbcfe48249",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-6.png?v=1659693524",
                        "id": "gid://shopify/MediaImage/31105812398337",
                        "title": "c-6.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 135,
                        "width": 414
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "1239ef70-6118-4873-9e8c-b5cd86a359e3",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-7.png?v=1659693524",
                        "id": "gid://shopify/MediaImage/31105812365569",
                        "title": "c-7.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 135,
                        "width": 414
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "e71ce044-20a7-4580-b5a7-a6bbf4035f90",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "197"
        },
        "innerBlocks": [
            {
                "clientId": "041dd402-a791-454e-a381-afa5601b19b0",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-10.png?v=1659693557",
                        "id": "gid://shopify/MediaImage/31105816101121",
                        "title": "c-10.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 804,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "765acb20-353b-4bb5-a471-75ebc8b1c926",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-11.png?v=1659693556",
                        "id": "gid://shopify/MediaImage/31105816068353",
                        "title": "c-11.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 804,
                        "width": 621
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    }
]
const template = {
    blocks,
    id: 'fashion-3',
    name: 'Magnolia',
    category: 'Fashion',
    price: 'Free',
    imageSrc: '/images/themes/fashion-2.png',
    previewSrc: '/images/preview/templates/fashion-2.png',
    imageAlt: 'Mobile app Fashion theme',
    description: "Looking to build the next best Fashion app? Look no further! check out our carefully crafted Fashion template that will have you up and running in no time."
};
export default template;
