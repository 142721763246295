import LanguageEditor from '../components';
import { useProject } from '@appmaker/core/store/project';
import coreApi from '@appmaker/core/api/core';
import { useAsyncFn } from 'react-use';
import React, { useEffect } from 'react';
import { setFormData, useFormData, syncLanguageFormData } from '../store';
import LoadingIndicator from '@appmaker/components/skeltonLoading/Dashboard';
import useProjectData from '@appmaker/core/hooks/useProjectData';
const Language = () => {
    const { projectId } = useProject();
    const { loading: projectLoading, projectData } = useProjectData({ projectId });
    const [{ value, loading }, doFetch] = useAsyncFn(
        async () => await coreApi.getLanguageValues(projectId),
        []
    );
    useEffect(() => {
        doFetch();
    }, []);

    useEffect(() => {
        if (value && value.status && value.body) {
            setFormData({ ...value?.body?.formData });
        }

    }, [value, loading]);

    const formData = useFormData();
    const onChangeFormData = (data) => {
        setFormData({ ...formData, ...data });
    }
    const { feature: {
        enableMultiLanguage = false
    } = {} } = projectData || {}
    if (loading || projectLoading) {
        return <LoadingIndicator />
    }
    const onSaveFormData = async () => {
        await syncLanguageFormData(projectId);
    }
    return (
        <div>
            <LanguageEditor
                options={value?.body?.options}
                save={onSaveFormData}
                formData={value?.body?.formData}
                onChange={(data) => onChangeFormData(data)}
                enableMultiLanguage={enableMultiLanguage}
            />
        </div>
    )

}

export default Language;