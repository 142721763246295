import { useEffect, useState } from 'react';
import { setLanguageEditorData, useLanguageFormData } from './store/formData';
import LanguageSidebar from './LanguageSidebar';
import Content from './Content';
import isEmpty from 'lodash/isEmpty';
// import Content from './Content';
import LanguageContent from './LanguageContent';
import { CheckIcon } from '@heroicons/react/solid';
import UpgradePlaceholder from '@appmaker/components/templates/UpgradePlaceholder';
import PricingModal from 'packages/Subscription/components/PricingModal';

const LanguageEditor = ({ options, formData, onChange, save, enableMultiLanguage }) => {
	const [pricingModal, setPricingModal] = useState(false);

	const localFormData = useLanguageFormData();
	useEffect(() => {
		if (!isEmpty(formData)) setLanguageEditorData(formData);
	}, [formData]);
	useEffect(() => {
		if (!isEmpty(localFormData.selectedLanguages))
			onChange({
				selectedLanguages: localFormData.selectedLanguages,
				defaultLanguage: localFormData.defaultLanguage,
				languageValues: localFormData.languageValues,
			});
	}, [localFormData]);

	if (enableMultiLanguage) {
		return (
			<div className="flex h-screen">
				<LanguageSidebar availableLanguages={options} />
				<Content onSave={save}>
					<LanguageContent availableLanguages={options} />
				</Content>
			</div>
		)
	}
	return (
		<div className="flex h-screen">
			<UpgradePlaceholder
				attributes={{
					title: 'Multi Language',
					description:
						"The multi-language feature of Appmaker enhances the adaptive feature of your mobile app. It gives your customer the opportunity to choose a language that makes it simpler to learn and understand the app. The Appmaker Multi-language feature helps you:",
					features: [
						"to extend your online business globally",
						"to go deeper into the local markets",
						"to access an easy language switcher",
						"to modify the way a particular word is translated"
					],
					cta: () => { setPricingModal(true) },
				}}
			/>
			<PricingModal isOpen={pricingModal} setIsOpen={setPricingModal} />
		</div>
	);
};

export default LanguageEditor;
