import AsyncSelect from 'react-select/async';
import hooks from '../hooks';


const Select = ({ defaultValue,onChange=()=>{} }) => {
    const { isLoading, fetchProducts } = hooks();

    const filterOptions = (inputValue, options) => {
        return options.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    const promiseOptions = (inputValue) =>
       
        new Promise(async (resolve) => {
            const response = await fetchProducts(inputValue);
            resolve(filterOptions(inputValue, response || []));
        });


    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            defaultValue={defaultValue}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            onChange={(value)=>{
                onChange(value);
            }}
        />
    );
}
export default Select


