import Form from './forms';

const Create = ({
    schema = {},
    onSave = () => { },
    buttonLabel = 'Create',
    buttonLoading = false,
}) => {
    return <div>
        <Form
            buttonLabel={buttonLabel}
            key='create'
            onClickButton={onSave}
            schema={schema}
            buttonLoading={buttonLoading}
        />
    </div>
}
const Update = ({
    schema = {},
    onSave = () => { },
    buttonLabel = 'Update',
    buttonLoading = false,
    formData = {}
}) => {
    return <div>
        <Form
            formValue={formData}
            buttonLabel={buttonLabel}
            key='update'
            onClickButton={onSave}
            schema={schema}
            buttonLoading={buttonLoading}
        />
    </div>
}
const CRUD = ({
    schema = {},
    type = 'create',
    properties = {
        onSave: () => { },
        buttonLoading: false,
        buttonLabel: 'Save',
        formData: {}
    },

}) => {
    switch (type) {
        case 'create':
            return <Create
                schema={schema}
                {...properties}
            />
        case 'update': {
            return <Update
                schema={schema}
                {...properties}
            />
        }
        default:
            return null
    }

}

const Wrapper = (props) => {
    return (
        <CRUD {...props} />
    )
}
export default Wrapper;