import { useProject } from "@appmaker/core/hooks";
import { getProjectData } from '@appmaker/core/store/project';
import SendPush from './sendPush';
import Configure from './Configure';
import { Toaster } from 'react-hot-toast';
import Loader from '@appmaker/components/Loading';

const PushNotification = () => {
    const { projectId } = useProject();
    const { projectData, loading } = getProjectData({ projectId });
    const { configure = {} } = projectData || {};
    const { pushConfigured } = configure;
    if (loading) {
        return (
            <Loader />
        )
    }
    return (
        <div>
            <Toaster
                position="top-right"
                reverseOrder={true}
            />
            {pushConfigured && (<SendPush />)}
            {!pushConfigured && <Configure />}
        </div>
    )
}

export default PushNotification;