import { CheckIcon, DownloadIcon, InformationCircleIcon } from '@heroicons/react/solid'

const IPASuccess = ({ downloadIpaLink = "", uploadIpa = () => { } }) => {

    return <>
        <div className="inline-flex items-center gap-2 text-xl font-medium">
            <CheckIcon className="h-10 w-10 p-2 bg-green-100 text-green-700 rounded-full" />
            IPA Build Success
        </div>
        <div className="flex flex-col items-center gap-4">

            <div>
                <a
                    href={downloadIpaLink}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    <DownloadIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                    />
                    Download IPA File
                </a>
            </div>
            <div className='text-center'>
                <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                    onClick={() => {
                        uploadIpa();
                    }}
                >
                    Upload IPA to iTunes Connect

                </button>
                <p className='flex items-center space-x-1 text-xs text-blue-600 p-2 rounded mt-2 border border-blue-300  bg-blue-50'>
                    <InformationCircleIcon className='h-5 w-5' />
                    <span>
                        Please create an app in App Store Connect before uploading IPA
                    </span>
                </p>
            </div>

        </div>
    </>
}

export default IPASuccess