/**
 * WordPress Dependencies
 */

// import  from '@wordpress/i18n';
import React from 'react';
import { addFilter } from '@wordpress/hooks';
import { Fragment } from '@wordpress/element';
import { createHigherOrderComponent } from '@wordpress/compose';
import { DataSourceTabFill } from 'packages/blockEditor/components/SlotFills';
import DataSourceSelector from '../components/DataSourceForm';


const withAdvancedControls = createHigherOrderComponent((BlockEdit) => {
    return (props) => {
        const { attributes, setAttributes, isSelected } = props;
        console.log(attributes);
        return (
            <Fragment>
                {isSelected && (
                    <>

                        <DataSourceTabFill>
                            <DataSourceSelector
                                formData={{ formData: attributes?.dataSource, _internal: attributes?.___internal_datasource }}
                                setFormData={({ formData, _internal }) => {
                                    setAttributes({ dataSource: formData, ___internal_datasource: _internal });
                                }}
                            />
                        </DataSourceTabFill>
                    </>
                )}

                <BlockEdit {...props} />
            </Fragment>
        );
    };
}, 'withAdvancedControls');

export function activate() {
    addFilter(
        'editor.BlockEdit',
        'editorskit/custom-advanced-control',
        withAdvancedControls
    );
}
