import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { LightningBoltIcon } from '@heroicons/react/solid';

// imp

import '@szhsin/react-menu/dist/index.css';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { useStore, Provider, createStore } from './store';

export const TriggerMenu = () => {
	const insertBlock = useStore((state) => state.insertBlock);
	const ToggleButton = () => (
		<div className="text-blue-500 bg-gray-200 p-2 rounded-md mt-2">
			<LightningBoltIcon className="h-5 w-5 text-blue-500" />
		</div>
	);
	const menuItems = useStore((state) => state.menuItems);
	function onClick(item) {
		return () => {
			insertBlock({
				name: 'variable',
				attributes: { value: item.value },
			});
			console.log(item);
		};
	}
	return (
		// <ControlledMenu state={true}>
		<Menu
			menuButton={
				<MenuButton className={'block'}>
					<ToggleButton />
				</MenuButton>
			}
		>
			{/* <FirstItem /> */}
			{menuItems.map((item) =>
				item.children ? (
					<SubMenu label={item.value}>
						{item.children.map((childItem) => (
							<MenuItem onClick={onClick(childItem)}>
								{childItem.value}
							</MenuItem>
						))}
					</SubMenu>
				) : (
					<MenuItem onClick={onClick(item)}> {item.value} </MenuItem>
				)
			)}
		</Menu>
	);
};
const components = {
	text: ({ attributes }) => <p>{attributes.value}</p>,
	variable: ({ attributes }) => (
		<p>
			<button className="rounded-full overflow-hidden bg-blue-700 pl-2 text-white flex items-center space-x-2">
				<p>{attributes.value}</p>
				<XIcon className="h-6 w-6 bg-blue-800 hover:bg-blue-900 rounded-full p-1" />
			</button>
		</p>
	),
	code: ({ attributes }) => (
		<code className="overflow-hidden bg-green-700 px-2 text-white flex items-center space-x-2">
			<span>{attributes.value}</span>
		</code>
	),
};
const TemplateInput = () => {
	const blocks = useStore((state) => state.blocks);
	const insertBlock = useStore((state) => state.insertBlock);
	const removeLastBlock = useStore((state) => state.removeLastBlock);
	const currentInputValue = useStore((state) => state.currentInputValue);
	const setCurrentInputValue = useStore((state) => state.setCurrentInputValue);
	const getLastBlock = useStore((state) => state.getLastBlock);

	// const [inputValue, setInputValue] = useState("");
	const handleKeyDown = (event) => {
		if (event.keyCode === 8 && currentInputValue === '' && getLastBlock()) {
			setCurrentInputValue(getLastBlock().attributes.value);
			removeLastBlock();
			// setInputItems(names => names.filter((_, i) => i !== names.length - 1));
		}
		if (event.key === 'Enter') {
			console.log('do validate');
			if (currentInputValue.trim() !== '') {
				insertBlock({
					name: 'text',
					attributes: { value: currentInputValue },
				});

				setCurrentInputValue('');
			}
		}
	};
	return (
		<div className="flex items-center gap-1 flex-wrap">
			{blocks.map((item) => {
				const Component = components[item.name] || 'div';
				return <Component attributes={item.attributes} />;
			})}
			<input
				value={currentInputValue}
				onChange={(e) => setCurrentInputValue(e.target.value)}
				type="text"
				onKeyDown={handleKeyDown}
				placeholder="Type here"
				className="outline-none bg-gray-100 flex-auto border-none focus:border-transparent focus:outline-none focus:ring-0 p-1 placeholder-gray-400 placeholder-opacity-50"
			/>
		</div>
	);
};
function JSONState() {
	const store = useStore();
	return <pre>{JSON.stringify(store.blocks, null, 2)}</pre>;
}
function TemplateInputTags() {
	// const menuItems = ;
	return (
		<Provider createStore={createStore}>
			<label className="mt-4 block text-sm font-medium">Params</label>
			<div className="bg-gray-100 border border-gray-200 p-2 rounded-md mt-1 mb-2">
				<TemplateInput />
				<TriggerMenu />
				{/* <JSONState /> */}
			</div>
		</Provider>
	);
}
export default TemplateInputTags;
