import { useEffect, useState } from 'react';
import useInAppPageList from 'packages/appmaker-core/hooks/useInappPages';
import Loader from '@appmaker/components/Loading';
import Select from 'react-select';

const InAppPage = (props) => {
	const { properties = {}, onChange, defaultValue, value } = props;
	const { isMulti = false, className, label } = properties;
	const { loading, pages } = useInAppPageList();
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (pages && pages?.length > 0) {
			setOptions(
				pages?.map((item) => {
					return {
						pageId: item?.id ?? item?.key,
						title: item?.label ?? item?.id ?? item?.key,
					};
				})
			);
		}
	}, [pages]);
	if (loading) {
		return <Loader single small />;
	}
	return (
		<div className={label && 'mt-4'}>
			{label && (
				<label className="block text-sm font-base text-gray-700 mb-1">
					{label}
				</label>
			)}
			<Select
				className={className}
				defaultValue={defaultValue ?? value}
				isMulti={isMulti}
				options={options}
				styles={{
					borderRadius: '.375rem',
					borderColor: 'black',
					borderWidth: 0.5,
				}}
				getOptionLabel={(option) => option.title}
				getOptionValue={(option) => option.pageId}
				onChange={(value) => {
					onChange(value);
				}}
			/>
		</div>
	);
};

export default InAppPage;
