import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks =[
    {
        "clientId": "b029e80d-c0f8-4fd7-937a-c74a301ac67a",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {
                "action": "OPEN_SEARCH"
            },
            "___internal_appmakerAction": {
                "action": {
                    "value": "OPEN_SEARCH",
                    "label": "Open search page"
                }
            },
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_9ee4ab52-70a3-44b8-9cb8-bbc6b94ce01b.png?v=1661947826",
                "id": "gid://shopify/MediaImage/31321760334081",
                "title": "a.png"
            },
            "imageContainerStyle": {
                "height": 200,
                "width": 1420
            },
            "thumbnail_meta": {
                "height": 200,
                "width": 1420
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "b3456dca-2460-4f77-a301-b893f98c6654",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_0c0584c5-9d48-4780-9c49-3a4a00d635af.png?v=1661947835",
                "id": "gid://shopify/MediaImage/31321760661761",
                "title": "b.png"
            },
            "imageContainerStyle": {
                "height": 844,
                "width": 1420
            },
            "thumbnail_meta": {
                "height": 844,
                "width": 1420
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "e7389d84-6cc2-4199-919f-96cdfb79b89b",
        "name": "appmaker/grid",
        "isValid": true,
        "attributes": {
            "title": "sample title",
            "itemsPerLine": 2,
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "enableSplit": false,
            "enableGap": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": [
            {
                "clientId": "e111d6f5-eb46-40a0-8e2e-3ed04b453efd",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_c32765a2-feca-4e6c-abc1-5f85fd0f13dd.png?v=1661947846",
                        "id": "gid://shopify/MediaImage/31321761743105",
                        "title": "c.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 364,
                        "width": 712
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {},
                    "thumbnail_meta": {
                        "height": 364,
                        "width": 712
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "80e695ee-b561-499c-b530-9097dc6782e1",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-1_834005c2-988d-4579-b94b-a14c0fb2f85b.png?v=1661947844",
                        "id": "gid://shopify/MediaImage/31321761480961",
                        "title": "c-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 364,
                        "width": 708
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {},
                    "thumbnail_meta": {
                        "height": 364,
                        "width": 708
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "4f50ad62-6347-4b93-bc2e-9dab7fb932cb",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-2_3e2c79fa-40c0-4e35-a06c-a31686f9fca6.png?v=1661947860",
                        "id": "gid://shopify/MediaImage/31321762889985",
                        "title": "c-2.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 364,
                        "width": 712
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {},
                    "thumbnail_meta": {
                        "height": 364,
                        "width": 712
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "3dca3444-5115-48f1-80cc-da23465f93f2",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-3_03140f92-c9f2-49f9-adab-62f265f54b01.png?v=1661947838",
                        "id": "gid://shopify/MediaImage/31321761186049",
                        "title": "c-3.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 364,
                        "width": 708
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {},
                    "thumbnail_meta": {
                        "height": 364,
                        "width": 708
                    }
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "23446470-2eb5-427a-bcb7-3c64eee53a07",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d_b1e30cfc-2263-48a8-a3d5-61d5ed06e8fc.png?v=1661947886",
                "id": "gid://shopify/MediaImage/31321765511425",
                "title": "d.png"
            },
            "imageContainerStyle": {
                "height": 844,
                "width": 1420
            },
            "thumbnail_meta": {
                "height": 844,
                "width": 1420
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "cb93297f-205d-4915-8b4e-6be19f3d9e00",
        "name": "appmaker/grid",
        "isValid": true,
        "attributes": {
            "title": "sample title",
            "itemsPerLine": 2,
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "enableSplit": false,
            "enableGap": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": [
            {
                "clientId": "1d10c894-79c7-4d8c-af94-7e918e566557",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_545dcf3a-9c34-4487-98c5-d60a03b8371d.png?v=1661947899",
                        "id": "gid://shopify/MediaImage/31321766822145",
                        "title": "f.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 712,
                        "width": 712
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "e2d6b8ea-be4f-4609-9c33-7926f41e8853",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1_e39272b6-7d7f-4874-868b-a6731da42cdd.png?v=1661947897",
                        "id": "gid://shopify/MediaImage/31321766461697",
                        "title": "f-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 712,
                        "width": 712
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "e00969be-9557-4161-b249-67925cc9e77e",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2_305ad051-d48b-4f06-a319-54fb89f443d3.png?v=1661947894",
                        "id": "gid://shopify/MediaImage/31321766199553",
                        "title": "f-2.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 712,
                        "width": 712
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "2938d918-d46b-47eb-8ec2-bffd657c7809",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-3_cddff4a2-f4ed-4644-9aad-1dbb06cd3423.png?v=1661947893",
                        "id": "gid://shopify/MediaImage/31321766134017",
                        "title": "f-3.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 712,
                        "width": 712
                    },
                    "showText": false,
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "88eba3b5-9a8f-4aad-b103-17ef2a18b22a",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_ee89a085-c3b9-48ce-9970-cc8a1c66e185.png?v=1661947918",
                "id": "gid://shopify/MediaImage/31321768198401",
                "title": "g.png"
            },
            "imageContainerStyle": {
                "height": 844,
                "width": 1420
            },
            "thumbnail_meta": {
                "height": 844,
                "width": 1420
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "09383a8c-5572-4522-a824-bde8b94e1a1b",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "customImageWidth": true,
            "parentWidth": "230"
        },
        "innerBlocks": [
            {
                "clientId": "53822d7c-f224-4564-a97c-66ac490aee08",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h_dc39dc97-a0d5-4dc8-8882-e9f61effe51f.png?v=1661947931",
                        "id": "gid://shopify/MediaImage/31321769509121",
                        "title": "h.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 1396,
                        "width": 984
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            },
            {
                "clientId": "321d86c6-3d14-45e7-bff2-a15a1dc73321",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-1_1a560d06-b57e-473d-9498-8f7b0c1f923c.png?v=1661947931",
                        "id": "gid://shopify/MediaImage/31321769410817",
                        "title": "h-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 1396,
                        "width": 984
                    },
                    "appmakerAction": {},
                    "___internal_appmakerAction": {}
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "9bff5a59-487a-4c70-a574-f2ec2f81196b",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "appmakerAction": {},
            "___internal_appmakerAction": {},
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i_67b41c64-c006-4b5d-90cd-429bedef0e0e.png?v=1661947942",
                "id": "gid://shopify/MediaImage/31321770590465",
                "title": "i.png"
            },
            "imageContainerStyle": {
                "height": 844,
                "width": 1420
            },
            "thumbnail_meta": {
                "height": 844,
                "width": 1420
            }
        },
        "innerBlocks": []
    }
]
const template = {
    blocks,
    id: 'pet-food',
    name: 'Petfy',
    category: 'Pets',
    price: 'Free',
    imageSrc: '/images/themes/petfy.png',
    previewSrc: '/images/preview/templates/petfy.png',
    imageAlt: 'Mobile app theme',
    description: "Our Pet food template uses preselected blocks that will make your app building journey easier than ever before! created after popular pet food apps, this will be a good starting point with lot’s of room to make this your own!"
};
export default template;
