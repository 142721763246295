import axios from "@appmaker/core/axios";
import { BASE_URL } from "@appmaker/core/api/core";

const apiFunction = {
	getPaymentUrl: async (projectId, planId) => {
		try {
			const response = await axios.get(
				`${BASE_URL}/payment/${projectId}/shopify?planId=${planId}`,
				{
					credentials: 'include',
					withCredentials: true,
				}
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getCountryCode: async () => {
		try {
			const response = await axios.get(
				`https://country-code.mobgap.workers.dev/}`,

			);
			return response.data
		} catch (error) {
			throw error;
		}
	},
	chargebeeHostedPage: async (projectId, data) => {
		try {
			const url = `${BASE_URL}/payment/${projectId}/chargebee-hosted-page`;

			const response = await axios.post(url, JSON.stringify(data), {
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	chargebeeUpaid: (projectId) => `${BASE_URL}/payment/${projectId}/collect-unpaid`,
	chargebeeSuccess: async (projectId, hostedPageId) => {
		try {
			const response = await axios.get(
				`${BASE_URL}/payment/${projectId}/chargebee-success?hostedPageId=${hostedPageId}`,
				{
					credentials: 'include',
					withCredentials: true,
				}
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	}
}

export default apiFunction;