const StepEight = () => {
    return (
        <div>

            <ul className="list-outside ml-4 list-disc flex flex-col gap-3">
                <li>
                    Got to Dashboard again and click on Create New Release

                    <img
                        className="w-full rounded-lg border border-gray-100 mt-1"
                        src="/images/publish/android/new/step-8-1.png"
                        alt="step one"
                    />
                </li>
                <li>
                    If you want Google to protect your app’s app signing key, opt-in for Play App signing and click Continue
                    <img
                        className="w-full rounded-lg border border-gray-100 mt-1"
                        src="/images/publish/android/new/step-8-2.png"
                        alt="step one"
                    />
                </li>

            </ul>
        </div>
    )
}

export default StepEight