const RichText = ({ attributes }) => {
	return (
		<div style={attributes?.containerStyles}>
			<p className="text-left" style={attributes?.textStyles}>
				{attributes?.text || 'Rich Text'}
			</p>
		</div>
	);
};

RichText.attributesSchema = {
	properties: {
		text: {
			type: 'string',
			label: 'Block Title',
		},
		containerStyles: {
			type: 'string',
			uiType: 'layout-styles',
			label: 'Container Styles',
		},
		textStyles: {
			type: 'string',
			uiType: 'text-styles',
			label: 'Text Styles',
		},
		html: {
			type: 'string',
			uiType: 'switch',
			label: 'HTML',
			className: 'mb-2 mt-3',
		},
		themeFontFamily: {
			type: 'string',
			label: 'Theme Font Family',
			title: 'Theme Font Family',
			uiType: 'RadioGroup',
			options: [
				{ label: 'Regular', value: 'regular' },
				{ label: 'Medium', value: 'medium' },
				{ label: 'Bold', value: 'bold' },
			],
		},
		tagStyles: {
			type: 'string',
			uiType: 'json',
			label: 'Html Tag Styles',
			description: 'Add the styles for the html tags',
		},
	},
};

export default RichText;
