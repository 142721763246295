import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [
    {
        "clientId": "50b66fc7-5dc7-4064-879d-1f82fab6642e",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-11_077d34cb-6c6e-44c0-97ce-491900688ba6.png?v=1661519099",
                "id": "gid://shopify/MediaImage/31280465248513",
                "title": "a-11.png"
            },
            "imageContainerStyle": {
                "height": 619,
                "width": 1080
            },
            "thumbnail_meta": {
                "height": 619,
                "width": 1080
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "87f60d95-2886-49ee-83a4-1ce5cb72771c",
        "name": "appmaker/grid",
        "isValid": true,
        "attributes": {
            "title": "sample title",
            "itemsPerLine": 3,
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "enableSplit": true,
            "enableGap": true
        },
        "innerBlocks": [
            {
                "clientId": "12e2392f-2e05-4862-bac5-e52d3899f14e",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_0a729c53-2075-4120-8361-eaafa2bf6796.png?v=1661519140",
                        "id": "gid://shopify/MediaImage/31280469901569",
                        "title": "a.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 352,
                        "width": 360
                    },
                    "showText": false
                },
                "innerBlocks": []
            },
            {
                "clientId": "96b8abc9-3e2d-4b07-8d1a-feef1107e2c1",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-1_0e7c7efe-a352-4ee4-8bca-da5f24a6a38d.png?v=1661519139",
                        "id": "gid://shopify/MediaImage/31280469868801",
                        "title": "a-1.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 352,
                        "width": 360
                    },
                    "showText": false
                },
                "innerBlocks": []
            },
            {
                "clientId": "1640eebb-8cfa-4810-b5f7-eb56f26c37f1",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-2_f516fc20-f50f-46c5-a610-195767579eef.png?v=1661519131",
                        "id": "gid://shopify/MediaImage/31280468689153",
                        "title": "a-2.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 352,
                        "width": 360
                    },
                    "showText": false
                },
                "innerBlocks": []
            },
            {
                "clientId": "54afd239-65bc-4608-b3c4-6e26b260304a",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-3_84ad24bc-a5dd-472d-9626-9dd564b56949.png?v=1661519110",
                        "id": "gid://shopify/MediaImage/31280466100481",
                        "title": "a-3.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 352,
                        "width": 360
                    },
                    "showText": false
                },
                "innerBlocks": []
            },
            {
                "clientId": "ff7590d6-07e0-4259-af3e-0d6bcb129bae",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-9_6543f936-1121-4f2d-959d-a87664ce5a48.png?v=1661519108",
                        "id": "gid://shopify/MediaImage/31280466067713",
                        "title": "a-9.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 352,
                        "width": 360
                    },
                    "showText": false
                },
                "innerBlocks": []
            },
            {
                "clientId": "2cf02db0-432a-47a6-b19d-b7488cad9990",
                "name": "appmaker/grid-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-10_11dd6b63-76c8-4168-815e-9e81f3a73a7c.png?v=1661519131",
                        "id": "gid://shopify/MediaImage/31280468656385",
                        "title": "a-10.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 352,
                        "width": 360
                    },
                    "showText": false
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "48b7ce0d-7c4d-4033-b986-0b22553efbb9",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-14_58992d31-3eb4-48fd-98df-98cb6bcee413.png?v=1661519252",
                "id": "gid://shopify/MediaImage/31280477929729",
                "title": "a-14.png"
            },
            "imageContainerStyle": {
                "height": 359,
                "width": 1080
            },
            "thumbnail_meta": {
                "height": 359,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    },
    {
        "clientId": "7c605cdc-5102-44c5-bc64-1738d7f2822b",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-16_a91c9c53-77d2-47a4-ba80-870515b3a757.png?v=1661519186",
                "id": "gid://shopify/MediaImage/31280472883457",
                "title": "a-16.png"
            },
            "imageContainerStyle": {
                "height": 136,
                "width": 1080
            },
            "thumbnail_meta": {
                "height": 136,
                "width": 1080
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "7f4389d2-a5eb-42f3-817a-309eb71dcba3",
        "name": "appmaker/imagescroller",
        "isValid": true,
        "attributes": {
            "ctaText": "View more button",
            "showViewMoreButton": false,
            "showTitle": false,
            "customImageWidth": true,
            "parentWidth": "120"
        },
        "innerBlocks": [
            {
                "clientId": "4dae5bb9-f31a-4d15-b893-68712d6fb467",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-5_6cb9cebe-44a5-4a42-bc50-d464380cadaa.png?v=1661519213",
                        "id": "gid://shopify/MediaImage/31280474292481",
                        "title": "a-5.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 172,
                        "width": 318
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "0067245b-3668-4b4c-ad36-835f4c9c405c",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-4_d6d134ec-1f11-43a4-a6fd-45b7e0697660.png?v=1661519212",
                        "id": "gid://shopify/MediaImage/31280474259713",
                        "title": "a-4.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 172,
                        "width": 318
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "24f35710-716e-43f8-98d7-0cb0f3aed3ab",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-6_1c69f474-d774-4d0a-8427-d467154c1159.png?v=1661519208",
                        "id": "gid://shopify/MediaImage/31280474128641",
                        "title": "a-6.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 172,
                        "width": 318
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "d8e78335-8ffa-478a-b954-eb3ed09cf041",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-8_b2a34c05-e980-4078-b60a-6c751435e1fc.png?v=1661519207",
                        "id": "gid://shopify/MediaImage/31280474063105",
                        "title": "a-8.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 172,
                        "width": 318
                    }
                },
                "innerBlocks": []
            },
            {
                "clientId": "9d37f569-2ff7-4416-b2f8-56126e59a355",
                "name": "appmaker/imagescroller-item",
                "isValid": true,
                "attributes": {
                    "image": {
                        "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-7_ef49c9cc-73c7-447a-9543-95164da7aab3.png?v=1661519207",
                        "id": "gid://shopify/MediaImage/31280474095873",
                        "title": "a-7.png"
                    },
                    "text": "",
                    "imageContainerStyle": {
                        "height": 172,
                        "width": 318
                    }
                },
                "innerBlocks": []
            }
        ]
    },
    {
        "clientId": "79117c11-f186-4e3a-9460-d6c16eeee5d6",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-12_bf9e8d52-2a14-4b0d-901f-0d847a2bad07.png?v=1661519251",
                "id": "gid://shopify/MediaImage/31280477438209",
                "title": "a-12.png"
            },
            "imageContainerStyle": {
                "height": 619,
                "width": 1080
            },
            "thumbnail_meta": {
                "height": 619,
                "width": 1080
            }
        },
        "innerBlocks": []
    },
    {
        "clientId": "4c028a54-d8ec-4acb-8174-45ad5728a203",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-15_9e6155df-1e84-47db-a35f-a99ec11d8ecb.png?v=1661519174",
                "id": "gid://shopify/MediaImage/31280472195329",
                "title": "a-15.png"
            },
            "imageContainerStyle": {
                "height": 235,
                "width": 1080
            },
            "thumbnail_meta": {
                "height": 235,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    },
    {
        "clientId": "060ead61-750e-4f95-bc25-2134fe450772",
        "name": "appmaker/banner",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-13_960c5d0b-557b-4a94-ae84-7fa49dd9ddd8.png?v=1661519256",
                "id": "gid://shopify/MediaImage/31280478093569",
                "title": "a-13.png"
            },
            "imageContainerStyle": {
                "height": 619,
                "width": 1080
            },
            "thumbnail_meta": {
                "height": 619,
                "width": 1080
            }
        },
        "innerBlocks": []
    }
]
const template = {
    blocks,
    id: 'pamper',
    name: 'Pamper',
    category: 'Baby products',
    price: 'Free',
    imageSrc: '/images/themes/pamper.png',
    previewSrc: '/images/preview/templates/pamper.png',
    imageAlt: 'Mobile app theme',
    description: "Have a baby store and need a head start? Our baby store template uses preselected blocks that will make your app building journey easier than ever before! Each block is curated after popular apps in the segment and will be a good starting point for your app building journey!"
};
export default template;
