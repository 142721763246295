import React from 'react';

const BannerImage = ({ imageSrc, children }) => {
	return (
		<div className="w-full h-auto">
			<img src={imageSrc} width="100%" className="image-uploaded" />
			{children && <span className="image-title">{children}</span>}
		</div>
	);
};
export default BannerImage;
