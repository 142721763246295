import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [{
    "clientId": "af2a7b04-7fff-4050-8ad3-5ba3a783d682",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 3,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "0415e20a-113f-4ede-bbfc-d9db4b500367",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/men.png?v=1656587662",
                "id": "gid://shopify/MediaImage/30756806295809",
                "title": "men.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 407,
                "width": 407
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "1cc4f68e-774f-4d0b-ba8a-0e5a4728d635",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/kids.png?v=1656587664",
                "id": "gid://shopify/MediaImage/30756806361345",
                "title": "kids.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 407,
                "width": 407
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "20b947f1-0bb5-439a-bbbb-f9cabfb0b6ff",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/women.png?v=1656587661",
                "id": "gid://shopify/MediaImage/30756806263041",
                "title": "women.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 407,
                "width": 407
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "803b65bb-0183-4f1f-abdf-e366307fe307",
    "name": "appmaker/slider",
    "isValid": true,
    "attributes": {
        "autoPlay": false,
        "delay": 1,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "c7869dc4-2371-4dfb-98ab-6622500ea472",
        "name": "appmaker/slider-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/5.png?v=1656587701",
                "id": "gid://shopify/MediaImage/30756810195201",
                "title": "5.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 546,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "8715e180-f7f5-4984-af1c-20bdfb461317",
        "name": "appmaker/slider-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/7.png?v=1656587693",
                "id": "gid://shopify/MediaImage/30756809376001",
                "title": "7.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 546,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "d58ad809-e968-4bce-98be-d4b346d195b8",
        "name": "appmaker/slider-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/6.png?v=1656587692",
                "id": "gid://shopify/MediaImage/30756809605377",
                "title": "6.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 546,
                "width": 1080
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "c79a8ea8-7a8a-4b53-b463-b8a5a5518b30",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/47.png?v=1656587727",
            "id": "gid://shopify/MediaImage/30756812849409",
            "title": "47.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "8425feb7-4716-4efe-890e-143d1bd1cbda",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "aee74fdc-25be-44e8-82c1-935e437ecad5",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/all_products.png?v=1656587748",
                "id": "gid://shopify/MediaImage/30756815077633",
                "title": "all products.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 223,
                "width": 675
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "f52bb57e-6889-46b6-a188-d1d25d694411",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/best_selling.png?v=1656587746",
                "id": "gid://shopify/MediaImage/30756814553345",
                "title": "best selling.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 223,
                "width": 675
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "b2437d50-baba-41f7-83fe-5b868188a87d",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/featured.png?v=1656587745",
                "id": "gid://shopify/MediaImage/30756814520577",
                "title": "featured.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 223,
                "width": 675
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "754a2ee6-4254-4829-8547-f2b57d86ba94",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/new_arrival.png?v=1656587739",
                "id": "gid://shopify/MediaImage/30756813930753",
                "title": "new arrival.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 223,
                "width": 675
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "e0773455-d720-4691-8dab-3f4e803abf28",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/trending.png?v=1656587738",
                "id": "gid://shopify/MediaImage/30756813897985",
                "title": "trending.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 223,
                "width": 675
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "a024c8d0-3aaf-4205-afdd-9dec9db26e99",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/4.png?v=1656589553",
            "id": "gid://shopify/MediaImage/30756921901313",
            "title": "4.png"
        },
        "imageContainerStyle": {
            "height": 546,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 546,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "da04948a-ec15-4afe-9118-aefb5f7964f1",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/48.png?v=1656587784",
            "id": "gid://shopify/MediaImage/30756818714881",
            "title": "48.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "af27ac2c-f380-4943-aa41-b4d34aa64529",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "b7aa3994-e0ac-4b81-b212-0a916d80cd85",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f.png?v=1656587886",
                "id": "gid://shopify/MediaImage/30756828348673",
                "title": "f.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "49ff25f0-e53c-4012-9de1-1cdf6eaf62de",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1.png?v=1656587869",
                "id": "gid://shopify/MediaImage/30756826874113",
                "title": "f-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "682785af-4272-4740-b8e2-77f39bd06f52",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2.png?v=1656587868",
                "id": "gid://shopify/MediaImage/30756826513665",
                "title": "f-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "f0a3cf03-1f35-471f-8ed5-88ad7880ac38",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-3.png?v=1656587867",
                "id": "gid://shopify/MediaImage/30756826480897",
                "title": "f-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "08606f69-ee9a-44f2-a623-5a80f35b5660",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/y.png?v=1656587854",
            "id": "gid://shopify/MediaImage/30756825399553",
            "title": "y.png"
        },
        "imageContainerStyle": {
            "height": 229,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 229,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "e5c535a8-502e-47c9-9d69-d33add871335",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 2,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "5556060d-418e-49be-8151-cc2d3f18708a",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/solids.png?v=1656587814",
                "id": "gid://shopify/MediaImage/30756822155521",
                "title": "solids.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "1410860c-ce8e-47f3-883c-f1c8c79c9caa",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/stripes.png?v=1656587815",
                "id": "gid://shopify/MediaImage/30756822221057",
                "title": "stripes.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "92ac8d2d-9d49-4755-a099-aee2355b47a9",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/checks.png?v=1656587806",
                "id": "gid://shopify/MediaImage/30756820943105",
                "title": "checks.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "fc326460-a66c-489f-8bb1-6532088e2fc2",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/prints.png?v=1656587813",
                "id": "gid://shopify/MediaImage/30756822122753",
                "title": "prints.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 500,
                "width": 500
            },
            "showText": false,
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "07695d0a-8406-4e5e-9ccf-aac62df8b7b0",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/24.png?v=1656589464",
            "id": "gid://shopify/MediaImage/30756915020033",
            "title": "24.png"
        },
        "imageContainerStyle": {
            "height": 393,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 393,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "a4b30898-e210-4dff-b5d6-0464c1bd132f",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/49.png?v=1656588016",
            "id": "gid://shopify/MediaImage/30756836802817",
            "title": "49.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "38ba8964-403d-435e-8abf-eaf7c4ce43ab",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "customImageWidth": true,
        "parentWidth": "131"
    },
    "innerBlocks": [{
        "clientId": "2eae0279-20ae-4ec7-aeaf-abf19b098f7b",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h_e3b0ae6a-d15e-46eb-bfbb-8f42deb0bcd6.png?v=1656588078",
                "id": "gid://shopify/MediaImage/30756842209537",
                "title": "h.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "78421960-04d8-4625-a855-2413bcab255a",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-1_c9f56d37-703c-45d2-85fe-eca0f1259c43.png?v=1656588076",
                "id": "gid://shopify/MediaImage/30756841914625",
                "title": "h-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "20078982-3e3f-4e3d-80b5-9c71539a6a97",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-2_1276730b-0fba-40c4-b152-5e5b8b44ff7d.png?v=1656588076",
                "id": "gid://shopify/MediaImage/30756841881857",
                "title": "h-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "44addcea-f1b7-4768-9abf-e5c9c9c0252c",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-3_8bd6243b-a6c2-4e81-b88f-198a871b6acc.png?v=1656588072",
                "id": "gid://shopify/MediaImage/30756841652481",
                "title": "h-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "91138d88-ef94-4fc6-b2db-2f9793bab875",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-4_a0ebf2ed-7236-4f0f-a8ad-c0a1d3ab1925.png?v=1656588071",
                "id": "gid://shopify/MediaImage/30756841586945",
                "title": "h-4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "530f9dc9-a80f-4647-8988-196837effd6d",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-5_5ddf3127-9463-43b2-8359-5403fbc9dd32.png?v=1656588071",
                "id": "gid://shopify/MediaImage/30756841324801",
                "title": "h-5.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "32418a43-4d10-4c92-b271-e8da4c953340",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/21.png?v=1656589451",
            "id": "gid://shopify/MediaImage/30756914200833",
            "title": "21.png"
        },
        "imageContainerStyle": {
            "height": 413,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 413,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "ca465583-2fe7-4ed6-8b3a-ff9e8322a70d",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/50.png?v=1656588016",
            "id": "gid://shopify/MediaImage/30756836835585",
            "title": "50.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "90eba6ca-eb48-4adb-9e7a-ade106034379",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": [{
        "clientId": "553eeeee-069d-418e-8458-baa29e7c40e9",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-6.png?v=1656588107",
                "id": "gid://shopify/MediaImage/30756844142849",
                "title": "h-6.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "1d8bfbe7-7bd2-4338-8e32-68d36c190603",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-8.png?v=1656588103",
                "id": "gid://shopify/MediaImage/30756843815169",
                "title": "h-8.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "9590bcba-c7ca-4d97-9eec-1d4954250689",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-7.png?v=1656588103",
                "id": "gid://shopify/MediaImage/30756843847937",
                "title": "h-7.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "6dd6d6e7-125f-4ad6-a804-da78d2e15aa3",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-9.png?v=1656588099",
                "id": "gid://shopify/MediaImage/30756843684097",
                "title": "h-9.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "9266acd8-8374-41b2-b5db-625eb0469531",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-10.png?v=1656588099",
                "id": "gid://shopify/MediaImage/30756843651329",
                "title": "h-10.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }, {
        "clientId": "bca37010-4ccc-4137-ae41-4b0d679ef4a6",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-11.png?v=1656588098",
                "id": "gid://shopify/MediaImage/30756843553025",
                "title": "h-11.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 635,
                "width": 635
            },
            "appmakerAction": {},
            "___internal_appmakerAction": {}
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "423a56f6-5589-4107-bb0b-b0c78fa2ad75",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/25.png?v=1656589463",
            "id": "gid://shopify/MediaImage/30756915118337",
            "title": "25.png"
        },
        "imageContainerStyle": {
            "height": 393,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 393,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "a572181c-d80b-416e-bb06-97af46bb26cb",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i.png?v=1656589356",
            "id": "gid://shopify/MediaImage/30756908237057",
            "title": "i.png"
        },
        "imageContainerStyle": {
            "height": 225,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 225,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "aafccd69-5003-4159-8a4f-528701651f63",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-1.png?v=1656589372",
            "id": "gid://shopify/MediaImage/30756908794113",
            "title": "i-1.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "4bd5da39-cd2b-4fdd-a1d8-f4f095a11494",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-2.png?v=1656589372",
            "id": "gid://shopify/MediaImage/30756908826881",
            "title": "i-2.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "26c4a301-b73b-47ea-9832-f62c973929dc",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-3.png?v=1656589383",
            "id": "gid://shopify/MediaImage/30756909023489",
            "title": "i-3.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "a223fba7-7758-472e-81e6-e137f2661235",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-4.png?v=1656589383",
            "id": "gid://shopify/MediaImage/30756909187329",
            "title": "i-4.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "2c11ca6a-a849-4b9d-81e3-26f0f0ba2ec2",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-5.png?v=1656589386",
            "id": "gid://shopify/MediaImage/30756909220097",
            "title": "i-5.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "c55b1ef1-863b-46ae-81b8-82ff424134e4",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-6.png?v=1656589403",
            "id": "gid://shopify/MediaImage/30756910039297",
            "title": "i-6.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "18c99f87-ce13-48d8-8f5b-dd2146904ab0",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-7.png?v=1656589404",
            "id": "gid://shopify/MediaImage/30756910104833",
            "title": "i-7.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "fab9edb9-e190-4f35-becd-8bc41017b5bf",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-8.png?v=1656589407",
            "id": "gid://shopify/MediaImage/30756910170369",
            "title": "i-8.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "abc28fb9-a6d3-4970-90cb-5ea9eb6456d6",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-9.png?v=1656589422",
            "id": "gid://shopify/MediaImage/30756910465281",
            "title": "i-9.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "0bd322e8-4869-438d-bf83-aea105c9f832",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-10.png?v=1656589421",
            "id": "gid://shopify/MediaImage/30756910498049",
            "title": "i-10.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "b6c1e218-a862-4ce5-b326-e6bdf8e9f0b5",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-11.png?v=1656589423",
            "id": "gid://shopify/MediaImage/30756910596353",
            "title": "i-11.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "9d6f4412-e350-421f-a6d7-a62e490ac87a",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i-12.png?v=1656589429",
            "id": "gid://shopify/MediaImage/30756912595201",
            "title": "i-12.png"
        },
        "imageContainerStyle": {
            "height": 200,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 200,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "3732b72a-e7f5-411c-b57e-ec20ec30d060",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/23.png?v=1656589452",
            "id": "gid://shopify/MediaImage/30756914299137",
            "title": "23.png"
        },
        "imageContainerStyle": {
            "height": 393,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 393,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "efee3f12-fc0e-4ebe-b6aa-e1dc47a6efe0",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/22.png?v=1656589451",
            "id": "gid://shopify/MediaImage/30756914233601",
            "title": "22.png"
        },
        "imageContainerStyle": {
            "height": 413,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 413,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "7ee170b7-f3b4-42de-819b-d9894a3fd555",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {}
    },
    "innerBlocks": []
}]

const template = {
    blocks,
    id: 'fashion-2',
    name: 'Runway',
    category: 'Fashion',
    price: 'Free',
    imageSrc: '/images/themes/fashion-1.png',
    previewSrc: '/images/preview/templates/fashion-1.png',
    imageAlt: 'Mobile app Fashion theme',
    description: "Looking to build the next best Fashion app? Look no further! check out our carefully crafted Fashion template that will have you up and running in no time."
};
export default template;
