import { entity } from 'simpler-state';

const actions = entity({
    list: [],
    setList: false
})
 


const useActionsList = (args) => actions.use(args);
const setActionsList = (data) => actions.set(value => ({ ...value, ...data }));

export {
    useActionsList,
    setActionsList
}