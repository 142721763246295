import React from 'react';
import ConfigurePush from '../components/configuration/index';

export default function Configure() {
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto py-8">
			<ConfigurePush />
		</div>
	);
}
