import grocery from './grocery';
import cosmetics from './cosmetics';
import fashion from './fashion';
import fashion1 from './fashion-1';
import kicks from './kicks';
import fashion2 from './fashion-2';
import pamper from './pamper';
import petfood from './petfood';
import zaira from './fashion-3';
import electronics from './electronics';
import classyStore from './classy-store';

const templates = [
    classyStore,
    electronics,
    zaira,
    petfood,
    pamper,
    grocery,
    cosmetics,
    fashion,
    fashion1,
    kicks,
    fashion2
];
export default templates