import React from 'react';
import EditorTable from './EditorTable';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect, generatePath
} from '@appmaker/core/routes';

export const ContentTabEditor = ({ tabsArray }) => {
    let { path, params } = useRouteMatch();
    // return "Working..."
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect
                    to={`${generatePath(path, params)}/${(tabsArray)[0]}`} />
            </Route>
            <Route path={`${path}/:languageTab`}>
                <EditorTable />
            </Route>
        </Switch>
    );
};
