import React from 'react';
import { InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import { PanelBody, RangeControl } from '@wordpress/components';
import { useSelect } from '@wordpress/data';
import {
	AlignCenterIcon,
	JustifyStartIcon,
	JustifyEndIcon,
	JustifyBetweenIcon,
	JustifyCenterIcon,
	JustifyEvenlyIcon,
	AlignEndIcon,
	AlignStartIcon,
	RowIcon,
	ColumnIcon,
	NoWrapIcon,
	WrapIcon,
	ScrollableIcon,
} from './assets';
import ColorPicker from '@appmaker/core/components/AppmakerForm/components/ColorPicker';
import RadioItem from './components/RadioItem';
import PaddingRangeControl from './components/PaddingRangeControl';
import MarginRangeControl from './components/MarginRangeControl';
import './style.css';
import clsx from 'clsx';
import BorderRadiusControl from './components/BorderRadiusControl';

function RowAlignmentRadioGroup({ attributes, setAttributes }) {
	const { alignment = 'center', direction = 'row' } = attributes;
	return (
		<div className="mb-4">
			<h4 className="text-sm text-md mb-2">Align</h4>
			<div className="flex items-center space-x-4">
				<RadioItem
					name="alignment"
					value="flex-start"
					checked={alignment === 'flex-start'}
					onChange={() => setAttributes({ alignment: 'flex-start' })}
				>
					<AlignStartIcon
						className={clsx({ 'transform -rotate-90': direction === 'column' })}
					/>
					<span className="sr-only">Left</span>
				</RadioItem>
				<RadioItem
					name="alignment"
					value="center"
					checked={alignment === 'center'}
					onChange={() => setAttributes({ alignment: 'center' })}
				>
					<AlignCenterIcon
						className={clsx({ 'transform -rotate-90': direction === 'column' })}
					/>
					<span className="sr-only">Center</span>
				</RadioItem>
				<RadioItem
					name="alignment"
					value="flex-end"
					checked={alignment === 'flex-end'}
					onChange={() => setAttributes({ alignment: 'flex-end' })}
				>
					<AlignEndIcon
						className={clsx({ 'transform -rotate-90': direction === 'column' })}
					/>
					<span className="sr-only">Left</span>
				</RadioItem>
			</div>
		</div>
	);
}

function RowJustifyRadioGroup({ attributes, setAttributes }) {
	const { justify = 'center' } = attributes;
	return (
		<div className="mb-4">
			<h4 className="text-sm text-md mb-2">Justify</h4>
			<div className="flex items-center space-x-4">
				<RadioItem
					name="justify"
					value="flex-start"
					checked={justify === 'flex-start'}
					onChange={() => setAttributes({ justify: 'flex-start' })}
				>
					<JustifyStartIcon />
					<span className="sr-only">Flex Start</span>
				</RadioItem>
				<RadioItem
					name="justify"
					value="space-between"
					checked={justify === 'space-between'}
					onChange={() => setAttributes({ justify: 'space-between' })}
				>
					<JustifyBetweenIcon />
					<span className="sr-only">Space Between</span>
				</RadioItem>
				<RadioItem
					name="justify"
					value="center"
					checked={justify === 'center'}
					onChange={() => setAttributes({ justify: 'center' })}
				>
					<JustifyCenterIcon />
					<span className="sr-only">Center</span>
				</RadioItem>
				<RadioItem
					name="justify"
					value="space-evenly"
					checked={justify === 'space-evenly'}
					onChange={() => setAttributes({ justify: 'space-evenly' })}
				>
					<JustifyEvenlyIcon />
					<span className="sr-only">Space Evenly</span>
				</RadioItem>
				<RadioItem
					name="justify"
					value="flex-end"
					checked={justify === 'flex-end'}
					onChange={() => setAttributes({ justify: 'flex-end' })}
				>
					<JustifyEndIcon />
					<span className="sr-only">Flex End</span>
				</RadioItem>
			</div>
		</div>
	);
}

function Direction({ attributes, setAttributes }) {
	const { direction = 'row' } = attributes;
	return (
		<div className="mb-4">
			<h4 className="text-sm text-md mb-2">Direction</h4>
			<div className="flex items-center space-x-4">
				<RadioItem
					name="direction"
					value="row"
					checked={direction === 'row'}
					onChange={() => setAttributes({ direction: 'row' })}
				>
					<RowIcon />
					<span className="sr-only">Row</span>
				</RadioItem>
				<RadioItem
					name="direction"
					value="column"
					checked={direction === 'column'}
					onChange={() => setAttributes({ direction: 'column' })}
				>
					<ColumnIcon />
					<span className="sr-only">Column</span>
				</RadioItem>
			</div>
		</div>
	);
}

function RowWrap({ attributes, setAttributes }) {
	const {
		flexWrap = 'nowrap',
		scrollable = false,
		direction = 'row',
	} = attributes;
	return (
		<div className="mb-4">
			<h4 className="text-sm text-md mb-2">Wrap</h4>
			<div className="flex items-center space-x-4">
				<RadioItem
					name="flexWrap"
					value="nowrap"
					checked={flexWrap === 'nowrap'}
					onChange={() => setAttributes({ flexWrap: 'nowrap' })}
				>
					<NoWrapIcon />
					<span className="sr-only">No Wrap</span>
				</RadioItem>
				<RadioItem
					name="flexWrap"
					value="wrap"
					checked={flexWrap === 'wrap'}
					onChange={() => setAttributes({ flexWrap: 'wrap' })}
				>
					<WrapIcon />
					<span className="sr-only">Wrap</span>
				</RadioItem>
				{direction === 'row' && flexWrap === 'nowrap' ? (
					<button
						title="Scrollable"
						className={clsx(
							'p-1 bg-white rounded cursor-pointer shadow-sm border-2',
							scrollable ? 'border-gray-700' : null
						)}
						onClick={() => setAttributes({ scrollable: !scrollable })}
					>
						<ScrollableIcon />
						<span className="sr-only">Scrollable</span>
					</button>
				) : null}
			</div>
		</div>
	);
}
export function AppmakerRow({ attributes, children, isEdit = false }) {
	const {
		alignment = 'center',
		justify = 'center',
		bgColor = '#fff',
		flexWrap = 'nowrap',
		paddingLeft = 0,
		paddingRight = 0,
		paddingTop = 0,
		paddingBottom = 0,
		marginLeft = 0,
		marginRight = 0,
		marginTop = 0,
		marginBottom = 0,
		direction = 'row',
		borderWidth = 0,
		borderColor = '#000',
		borderTopLeftRadius = 0,
		borderTopRightRadius = 0,
		borderBottomLeftRadius = 0,
		borderBottomRightRadius = 0,
		flexAuto,
		advancedStyle,
	} = attributes;
	return (
		<div
			className={clsx(
				isEdit
					? {
							'align-flex-start': alignment === 'flex-start',
							'align-flex-center': alignment === 'center',
							'align-flex-end': alignment === 'flex-end',
							'justify-flex-start': justify === 'flex-start',
							'justify-flex-space-between': justify === 'space-between',
							'justify-flex-center': justify === 'center',
							'justify-flex-space-evenly': justify === 'space-evenly',
							'justify-flex-end': justify === 'flex-end',
							'flex-direction-column': direction === 'column',
							'flex-direction-row': direction === 'row',
							'flex-wrap-wrap': flexWrap === 'wrap',
							'flex-wrap-nowrap': flexWrap === 'nowrap',
					  }
					: {
							'items-start': alignment === 'flex-start',
							'items-center': alignment === 'center',
							'items-end': alignment === 'flex-end',
							'justify-start': justify === 'flex-start',
							'justify-between': justify === 'space-between',
							'justify-center': justify === 'center',
							'justify-evenly': justify === 'space-evenly',
							'justify-end': justify === 'flex-end',
							'flex flex-col': direction === 'column',
							'flex flex-row': direction === 'row',
							'flex-wrap': flexWrap === 'wrap',
							'flex-nowrap': flexWrap === 'nowrap',
							'flex-1': flexAuto === true,
					  }
			)}
			style={{
				backgroundColor: bgColor,
				flexWrap,
				paddingLeft,
				paddingRight,
				paddingTop,
				paddingBottom,
				marginLeft,
				marginRight,
				marginTop,
				marginBottom,
				borderWidth,
				borderColor,
				borderTopLeftRadius,
				borderTopRightRadius,
				borderBottomLeftRadius,
				borderBottomRightRadius,
				...advancedStyle,
			}}
		>
			{children}
		</div>
	);
}
export const AppmakerRowEdit = ({
	setAttributes,
	attributes,
	isSelected,
	clientId,
}) => {
	const {
		bgColor = '#fff',
		borderWidth = 0,
		borderColor = '#000',
		direction,
	} = attributes;
	const blockSelected = useSelect((select) => {
		const childSelected =
			select('core/block-editor').hasSelectedInnerBlock(clientId);
		return childSelected || isSelected;
	});
	const hasInnerBlocks = useSelect(
		(select) =>
			select('core/block-editor').getBlock(clientId).innerBlocks.length
	);
	return (
		<div className={hasInnerBlocks ? '' : 'p-2 border border-gray-300'}>
			<AppmakerRow attributes={attributes} clientId={clientId} isEdit={true}>
				{blockSelected ? (
					<div className="bg-gray-200 px-1 font-medium text-sm text-gray-400 uppercase flex-w">
						{direction}
					</div>
				) : null}
				<InnerBlocks
					// template={BLOCKS_TEMPLATE}
					// allowedBlocks={['appmaker/layout-icon', 'appmaker/heading']}
					// templateLock="all"
					className={clsx('flex space-x-2')}
				/>
				<InspectorControls>
					<Direction attributes={attributes} setAttributes={setAttributes} />
					<RowAlignmentRadioGroup
						attributes={attributes}
						setAttributes={setAttributes}
					/>
					<RowJustifyRadioGroup
						attributes={attributes}
						setAttributes={setAttributes}
					/>
					<RowWrap attributes={attributes} setAttributes={setAttributes} />
					<ColorPicker
						attributes={{
							label: 'Background Color',
							defaultColor: bgColor,
							onChangeComplete: (color) => {
								setAttributes({ bgColor: color.hex });
							},
						}}
					/>
					<PanelBody title="Spacing" className="mt-4">
						<PaddingRangeControl
							attributes={attributes}
							setAttributes={setAttributes}
						/>
						<MarginRangeControl
							attributes={attributes}
							setAttributes={setAttributes}
						/>
					</PanelBody>
					<PanelBody title="Border" className="mt-4">
						<RangeControl
							label="Border Width"
							value={borderWidth}
							onChange={(value) => setAttributes({ borderWidth: value })}
							min={0}
							max={10}
						/>
						{borderWidth > 0 ? (
							<ColorPicker
								attributes={{
									label: 'Border Color',
									defaultColor: borderColor,
									onChangeComplete: (color) => {
										setAttributes({ borderColor: color.hex });
									},
								}}
							/>
						) : null}
						<BorderRadiusControl
							attributes={attributes}
							setAttributes={setAttributes}
						/>
					</PanelBody>
				</InspectorControls>
			</AppmakerRow>
		</div>
	);
};
AppmakerRowEdit.attributesSchema = {
	properties: {
		flexAuto: {
			type: 'string',
			uiType: 'switch',
			label: 'Flex Auto',
			description: 'Grow flex box into available space',
		},
		advancedStyle: {
			type: 'json',
			label: 'Advanced Style',
			description: 'Advanced Styles for the block',
		},
	},
};
