import React, { useEffect, useState } from 'react';
import coreApi from '@appmaker/core/api/core';
import { getProjectId } from '@appmaker/core/store/project';
import { useStoreApi, useStore } from '@appmaker/core/components/AppmakerForm/store'
import { useAsyncFn } from 'react-use';
import KeyboardShortcuts from './keyboard-shortcuts';
import shallow from 'zustand/shallow'
import appmaker from '@appmaker/core/';
import { useHistory } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { SaveIcon } from '@heroicons/react/outline';

function Message({ isSaved }) {
    const [count, setcount] = useState(0);
    const data = useStore(state => ({ formData: state.formData, _internal: state._internal }), shallow);
    
    useEffect(() => {
            setcount(count + 1);
    }, [data]);
    useEffect(()=>{
        if(isSaved){
            setcount(1)
        }
    },[isSaved])
    return count > 1 ? (
        <p className="ml-3 font-medium  truncate flex gap-1 items-center text-yellow-500">
            <span className="flex h-3 w-3 relative items-center ml-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                <ExclamationCircleIcon className="h-4 w-4" />
            </span>
            <span className=" md:inline">Unsaved Changes</span>
        </p>
    ) : null
}
export function Toolbar() {
    const storeApi = useStoreApi()
    const history = useHistory();
    const routerPath = useRouterPath()
    const projectId = getProjectId()
    const [isSaved, setSaved] = useState(false);
    const data = useStore(state => ({ formData: state.formData, _internal: state._internal }), shallow);
    useEffect(()=>{
        setSaved(false);
    },[data])
    const [{ loading: saving }, save] = useAsyncFn(async (skipBuild = true) => {
        const { formData } = storeApi.getState()
        const result = await coreApi.saveAppFormData(projectId, formData, skipBuild);
        setSaved(true);
        return result
    }, []);
    const [{ loading: appBuildsaving }, buildApp] = useAsyncFn(async (skipBuild = true) => {

        const { formData } = storeApi.getState()
        const result = await coreApi.saveAppFormData(projectId, formData, skipBuild);
        setSaved(true);
        const [appDesignNextPath, rootRedirect] = appmaker.applyFilters('appdesign-next');
        history.push(rootRedirect ? appDesignNextPath : routerPath(appDesignNextPath));

        return result
    }, []);
    return (
        <>
            <KeyboardShortcuts.Register />
            <KeyboardShortcuts
                undo={() => { }}
                redo={() => { }}
                save={save}
            />
            <div className="md:flex md:items-center md:justify-between md:space-x-5 bg-white p-4 border-b-2">
                <div className="flex items-start space-x-5">
                    <div className="flex-shrink-0">
                        <Message isSaved={isSaved} />
                    </div>

                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    <button
                        onClick={() => save(true)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <SaveIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        {saving ? 'Saving...' : 'Save Changes'}
                    </button>
                    <button
                        onClick={() => buildApp(false)}
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    >
                        {appBuildsaving ? 'Building App...' : 'Build App'}
                    </button>
                </div>
            </div>
        </>
    );
}
