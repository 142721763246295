import create from 'zustand';
import produce from 'immer';

const useExtensionStore = create((set) => ({
	installList: [],
	originalData: {},
	setExtensionData: (installList) =>
		set(
			produce((state) => {
				state.installList = installList;
				state.originalData = {
					installList: installList,
				};
			})
		),
	searchExtension: (search) => {
		set(
			produce((state) => {
				if (!search || search?.length === 0) {
					state.installList = state.originalData?.installList ?? [];
				} else {
					state.installList = state?.installList?.filter((item) =>
						item?.extension?.name
							?.toLowerCase()
							?.includes(search?.toLowerCase())
					);
				}
			})
		);
	},
}));

export default useExtensionStore;
