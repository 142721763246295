import clsx from 'clsx';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import { useCountdown } from './hooks/useCountdown';
import { InspectorControls } from '@wordpress/block-editor';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import { CalendarIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';

const DateTimeDisplay = ({ value, type, isDanger, attributes }) => {
	const {
		bgColor = '#212121',
		textColor = '#F8FAFC',
		size,
		stacked,
	} = attributes;
	return (
		<div
			className={clsx(
				'text-lg py-2 rounded-lg flex items-center space-x-1',
				stacked ? 'flex-col px-4' : 'flex-row px-2'
			)}
			style={{
				backgroundColor: bgColor,
				color: textColor,
			}}
		>
			<span>{value ? value : '00'}</span>
			<span className="text-xs">{type}</span>
		</div>
	);
};

const ShowCounter = ({ days, hours, minutes, seconds, attributes = {} }) => {
	return (
		<div className="flex items-center space-x-1">
			{days !== 0 ? (
				<>
					<DateTimeDisplay
						value={days}
						type={attributes.size === 'lg' ? 'DAYS' : 'D'}
						isDanger={days <= 1}
						attributes={attributes}
					/>
					<span>:</span>
				</>
			) : null}
			<DateTimeDisplay
				value={hours}
				type={attributes.size === 'lg' ? 'HOURS' : 'H'}
				isDanger={days <= 1}
				attributes={attributes}
			/>
			<span>:</span>
			<DateTimeDisplay
				value={minutes}
				type={attributes.size === 'lg' ? 'MINUTES' : 'M'}
				isDanger={days <= 1}
				attributes={attributes}
			/>
			<span>:</span>
			<DateTimeDisplay
				value={seconds}
				type={attributes.size === 'lg' ? 'SECONDS' : 'S'}
				isDanger={days <= 1}
				attributes={attributes}
			/>
		</div>
	);
};

const CountdownTimer = ({ attributes }) => {
	const {
		targetDate,
		expiredText,
		dangerBgColor,
		dangerTextColor,
		bgColor,
		textColor,
		size,
		stacked,
	} = attributes;
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return <div>{expiredText}</div>;
	} else {
		return (
			<ShowCounter
				days={days}
				hours={hours}
				minutes={minutes}
				seconds={seconds}
				attributes={{
					dangerBgColor: dangerBgColor,
					dangerTextColor: dangerTextColor,
					bgColor: bgColor,
					textColor: textColor,
					size: size,
					stacked: stacked,
				}}
			/>
		);
	}
};

function CountDownWidget({ attributes, isSelected, setAttributes }) {
	const { featureImg, show, date } = attributes;
	const ratio = featureImg?.height / featureImg?.width;
	return (
		<>
			<InspectorControls>
				<div className="mb-4">
					<label className="block text-sm font-base text-gray-700 mb-1">
						Date and Time
					</label>
					<DateTimePicker
						minDate={new Date()}
						maxDate={new Date(moment().add(30, 'days'))}
						label="DateTimePicker"
						className="p-1 border border-gray-300 rounded-md w-full bg-white"
						calendarClassName="bg-white rounded-lg overflow-hidden"
						disableClock={true}
						value={date ? new Date(date) : new Date()}
						dayPlaceholder="dd"
						monthPlaceholder="mm"
						yearPlaceholder="yyyy"
						secondPlaceholder="ss"
						minutePlaceholder="mm"
						hourPlaceholder="hh"
						calendarIcon={
							<CalendarIcon className="h-8 w-8 bg-gray-200 p-1 rounded-md" />
						}
						onChange={(newValue) => {
							setAttributes({ date: newValue });
						}}
					/>
				</div>
				{featureImg ? (
					<div className="mb-4">
						<p className="mb-1 text-gray-700 font-base text-sm">
							Background Image
						</p>
						<div className="flex items-center gap-3 flex-col p-4 border border-blue-400 border-dashed rounded-md">
							<img
								src={featureImg?.url}
								alt="thumbnail"
								className="w-56 border border-gray-200 object-contain rounded-lg overflow-hidden"
							/>
							<button
								onClick={() =>
									setAttributes({
										featureImg: undefined,
									})
								}
								type="button"
								className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
							>
								<TrashIcon className="h-5 w-5 mr-2" />
								Remove Image
							</button>
						</div>
					</div>
				) : null}
			</InspectorControls>
			<div
				className="flex flex-col overflow-hidden  items-center justify-center bg-no-repeat bg-cover py-8"
				style={{
					backgroundColor: attributes.backgroundColor || '#f5f5f5',
					backgroundImage: featureImg ? `url(${featureImg?.url})` : null,
					minHeight: featureImg ? `${28 * ratio}rem` : null,
				}}
			>
				{isSelected && featureImg === undefined && (
					<MediaPlaceholder
						attributes={{
							title: 'Background Image',
							description: 'Upload/Select block background image',
						}}
						onSelect={(images) => {
							let image = Array.isArray(images) ? images[0] : images;
							const data = image;
							setAttributes({
								featureImg: {
									url: data.url,
									fileName: data.filename,
									id: data.id,
									title: data.title,
									height: data.meta !== undefined ? data.meta.height : '100',
									width: data.meta !== undefined ? data.meta.width : '100',
								},
							});
						}}
						allowedTypes={['image']}
						multiple={false}
						labels={{ title: 'Image' }}
						value={featureImg}
					/>
				)}
				<span
					className="font-medium text-center p-1 select-none text-lg"
					style={{ color: attributes.titleColor }}
				>
					{attributes.title ? attributes.title : null}
				</span>
				<CountdownTimer
					attributes={{
						targetDate: attributes.date,
						showExpiredNotice: true,
						size: 'lg',
						...attributes,
					}}
				/>
			</div>
		</>
	);
}
CountDownWidget.attributesSchema = {
	properties: {
		backgroundColor: {
			type: 'string',
			label: 'Block Background Color',
			default: '#f5f5f5',
			uiType: 'ColorPicker',
		},
		title: {
			type: 'string',
			label: 'Headline',
			default: '',
		},
		titleColor: {
			type: 'string',
			label: 'Headline Text Color',
			default: '#212121',
			uiType: 'ColorPicker',
			panelItem: true,
		},
		expiredText: {
			type: 'string',
			label: 'Headline Upon Timer Expiry',
			default: 'Expired',
		},
		stacked: {
			type: 'string',
			label: 'Stacked Style',
			defaultValue: true,
			uiType: 'switch',
		},
		bgColor: {
			type: 'string',
			label: 'Timer Background Color',
			default: '#334155',
			uiType: 'ColorPicker',
		},
		textColor: {
			type: 'string',
			label: 'Timer Text Color',
			default: '#F8FAFC',
			uiType: 'ColorPicker',
		},
		// dangerBgColor: {
		// 	type: 'string',
		// 	label: 'Counter Item Background Color for last few days',
		// 	default: '#B91C1C',
		// 	uiType: 'ColorPicker',
		// },
		// dangerTextColor: {
		// 	type: 'string',
		// 	label: 'Counter Item Text Color for last few days',
		// 	default: '#111827',
		// 	uiType: 'ColorPicker',
		// },
	},
};

export default CountDownWidget;
