
import { useRouteMatch } from '@appmaker/core/routes';
import api from '../api';
import { useEffect } from 'react';
import { setInAppPageLoading, setInAppPageData, useInAppPage } from '../store';
const InAppPageEditor = () => {
    const { params } = useRouteMatch();
    const { isLoadingInAppPage: isLoading, inAppPageData } = useInAppPage();
    const { projectId, inAppPageId = 'home' } = params || {}
    const getInAppPageData = async () => {
        try {
            setInAppPageLoading(true);
            const response = await api.getInAppPage(projectId, inAppPageId);
            setInAppPageData(response);
        } catch (error) {
            setInAppPageLoading(false);
        }
    }
    useEffect(() => {
        getInAppPageData();
    }, [inAppPageId])
    if (isLoading) {
        return <div>Loading...</div>
    }
    return <div>
        <pre>{JSON.stringify(inAppPageData, null, 2)}</pre>
    </div>
}

export default InAppPageEditor;