import { APPMAKER_GQL_URL } from '@appmaker/core/api/core';
import axios from 'axios';

const getAppBuild = (projectId, id) => {
	if (!projectId || !id) return null;
	let query = `
    query ($where: AppBuildWhereUniqueInput!) {
        appBuild(where: $where) {
          buildStatus
          config
          legacyId
          appId {
            platform
            id
            remoteAppId
          }
          project {
            remoteProjectId
          }
          id
          name
          platform
          version
          buildType
          triggerType
          triggerBy {
            name
          }
          version
          createdAt
          updatedAt
          downloadUrl
          qaStatus
          releaseStatus
          packages
          templateVersion {
            legacyId
            name
            tag
            version
          }
        }
      }
      
    `;
	return axios.post(
		APPMAKER_GQL_URL,
		JSON.stringify({
			query,
			variables: {
				where: {
					id: id,
				},
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
				'x-appmaker-project-id': projectId,
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

const getAppBuildByVersion = (projectId, version, platform) => {
	const query = `
  		query($where: AppBuildWhereInput!){
  		  appBuilds(where: $where) {
			buildStatus
			config
			legacyId
			appId {
			  platform
			  id
			  remoteAppId
			}
			project {
			  remoteProjectId
			}
			id
			name
			platform
			version
			buildType
			triggerType
			triggerBy {
			  name
			}
			version
			createdAt
			updatedAt
			downloadUrl
			qaStatus
			releaseStatus
			packages
			templateVersion {
			  legacyId
			  name
			  tag
			  version
			}
  		  }
  		}
  `;
	return axios.post(
		APPMAKER_GQL_URL,
		JSON.stringify({
			query,
			variables: {
				where: {
					project: {
						remoteProjectId: {
							equals: parseInt(projectId, 10),
						},
					},
					version: {
						equals: typeof version === 'string' ? version : version?.toString(),
					},
					platform: {
						equals: platform,
					},
				},
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
				'x-appmaker-project-id': projectId,
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

const getVersion = async (projectId, platform) => {
	const query = `
	query (
		$where: AppBuildWhereInput!
		$take: Int
		$orderBy: [AppBuildOrderByInput!]!
	  ) {
		appBuilds(where: $where, take: $take, orderBy: $orderBy) {
		  version
		  name
		}
	  }
	  
	`;
	return axios.post(
		APPMAKER_GQL_URL,
		JSON.stringify({
			query,
			variables: {
				where: {
					project: {
						remoteProjectId: {
							equals: parseInt(projectId, 10),
						},
					},
					platform: {
						equals: platform,
					},
				},
				take: 20,
				orderBy: {
					createdAt: 'desc',
				},
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
				'x-appmaker-project-id': projectId,
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

export { getAppBuild, getAppBuildByVersion, getVersion };
