import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import appmaker from '@appmaker/core/index';
import { Link } from '@appmaker/core/routes';
import { ArrowLeftIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import DropDownMenu from '@appmaker/components/DropDownMenu';
import { useExtensionDetail } from '../store/details';
import UnSavedChange from './UnSaved';
import { UPDATE_SETTINGS, UNINSTALL_EXTENSION } from '../api/graphql';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from '@appmaker/core/routes';
import { toast } from '@appmaker/components/ReactHotToastIntegrator';
import LoadingIcon from '@appmaker/components/LoadingIcon';

const DropDownMenuWrapper = ({
	status,
	onClickDeactivate = () => {},
	onClickActivate = () => {},
	isLoadingDeactivate = false,
	isUnInstalling = false,
	onClickUninstall = () => {},
	onClickVersion = () => {},
	onClickDetails = () => {},
	onClickBackendSettings = () => {},
}) => {
	const { route } = useParams();
	let disabled = isLoadingDeactivate || isUnInstalling;
	const options = [];

	if (status === 'active') {
		options.push({
			label: 'Deactivate',
			onClick: onClickDeactivate,
			loading: isLoadingDeactivate,
			isLoadingText: 'Deactivating....',
			disabled,
		});
	} else {
		options.push({
			label: 'Activate',
			loading: isLoadingDeactivate,
			isLoadingText: 'Activating...',
			onClick: onClickActivate,
			disabled,
		});
	}
	options.push({
		label: <span className="text-red-600">Uninstall</span>,
		onClick: onClickUninstall,
		loading: isUnInstalling,
		isLoadingText: 'Uninstalling....',
		disabled,
	});
	if (route !== 'version') {
		options.push({
			label: 'Version',
			onClick: onClickVersion,
			loading: false,
			isLoadingText: 'Loading...',
			disabled,
		});
	} else {
		options.push({
			label: 'Details',
			onClick: onClickDetails,
			loading: false,
			isLoadingText: 'Loading...',
			disabled,
		});
	}
	if(route !== 'backend-settings'){
		options.push({
			label: 'Backend settings',
			onClick: onClickBackendSettings,
			loading: false,
			isLoadingText: 'Loading...',
			disabled,
		});
	}else{
		options.push({
			label: 'Settings',
			onClick: onClickDetails,
			loading: false,
			isLoadingText: 'Loading...',
			disabled,
		});
	}
	
	return (
		<DropDownMenu
			button={
				<span className="p-1.5 bg-gray-200 hover:bg-gray-100 rounded-md text-gray-600 self-center inline-flex items-center space-x-1">
					<DotsVerticalIcon className="h-6 w-6 transform 2xl:rotate-90" />
					<span className="text-sm hidden 2xl:block font-medium">OPTIONS</span>
				</span>
			}
			options={options}
		/>
	);
};

export default function DetailHeader(props) {
	const { handle, projectId } = useParams();
	const getRouterPath = useRouterPath();
	const history = useHistory();
	const extension = useExtensionDetail((state) => state.extension);
	const syncActiveStatus = useExtensionDetail(
		(state) => state.syncActiveStatus
	);
	const syncUninstall = useExtensionDetail((state) => state.syncUninstall);
	const extensionInstalls = useExtensionDetail(
		(state) => state.extensionInstalls
	);

	const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	const [unInstall, { loading: unInstalling }] = useMutation(
		UNINSTALL_EXTENSION,
		{
			context: {
				headers: {
					'x-appmaker-project-id': projectId,
				},
			},
		}
	);
	const {
		extensionId,
		status,
		imageSrc,
		name: extensionName,
		price,
		id: extensionParamsId,
		icon,
	} = extension || {};
	const currentExtension = extensionInstalls?.find(
		(details) => details?.extension?.id === extension?.id
	);

	/**
	 * Attach to header using filter
	 */
	const overRideHeaderInstalled = appmaker.applyFilters(
		`${extensionParamsId}-override-header-component-installed`,
		null,
		{
			extension: props.extension || {},
		}
	);
	const onClickActivate = async () => {
		try {
			toast.promise(
				(async () => {
					await updateSettings({
						variables: {
							where: {
								id: currentExtension?.id,
							},
							data: {
								status: 'active',
							},
						},
					});
					syncActiveStatus(currentExtension?.id, 'active');
				})(),
				{
					loading: 'Activating...',
					success: <b>Extension Activated</b>,
					error: <b>Unable to activate</b>,
				}
			);
		} catch (error) {
			console.log(error);
		}
	};
	const onClickDeactivate = async () => {
		try {
			toast.promise(
				(async () => {
					await updateSettings({
						variables: {
							where: {
								id: currentExtension?.id,
							},
							data: {
								status: 'in_active',
							},
						},
					});
					syncActiveStatus(currentExtension?.id, 'in_active');
				})(),
				{
					loading: 'Deactivating...',
					success: <b>Extension deactivated</b>,
					error: <b>Unable to deactivate</b>,
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const onClickUninstall = async () => {
		try {
			const confirmed = window.confirm('Are you sure?');
			if (!confirmed) {
				return false;
			}
			toast.promise(
				(async () => {
					await unInstall({
						variables: {
							where: {
								id: currentExtension?.id,
							},
						},
					});
					syncUninstall(currentExtension?.id);
					history.push(getRouterPath(`/extension`));
				})(),
				{
					loading: 'Uninstalling...',
					success: <b>Extension Uninstalled</b>,
					error: <b>Unable to uninstall</b>,
				}
			);
		} catch (error) {}
	};

	const onClickVersion = () => {
		history.push(getRouterPath(`/extension/${handle}/version`));
	};
	const onClickDetails = () => {
		history.push(getRouterPath(`/extension/${handle}/details`));
	};
	const onClickBackendSettings = () => {
		history.push(getRouterPath(`/extension/${handle}/backend-settings`));
	};
	return (
		<div className="flex items-center justify-between space-x-3 bg-white shadow-sm px-4 py-2 sticky top-0 z-50">
			<div className="flex items-center space-x-3">
				<Link to={getRouterPath('/extension/')}>
					<ArrowLeftIcon className="h-8 w-8 p-2 hover:bg-gray-300 rounded-md text-gray-600" />
				</Link>
				<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-10 w-10">
					<img
						src={
							icon ||
							'https://storage.googleapis.com/appilder_cdn/extension-default.png'
						}
						alt={`${extensionName}.`}
						className="object-center object-contain h-10 w-10"
					/>
				</div>
				<div>
					<h4 className="text-lg font-semibold text-gray-900">
						{extensionName}
					</h4>
				</div>
			</div>

			<div className="flex items-center space-x-2">
				<UnSavedChange />
				{currentExtension?.status === 'in_active' && (
					<button
						type="button"
						onClick={onClickActivate}
						disabled={loading}
						className="inline-flex justify-self-end bg-green-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500"
					>
						{loading ? (
							<>
								<LoadingIcon
									className="-ml-0.5 h-6 w-6 animate-spin"
									aria-hidden="true"
								/>
								Activating...
							</>
						) : (
							'Activate Extension'
						)}
					</button>
				)}
				{overRideHeaderInstalled ? overRideHeaderInstalled : null}
				<DropDownMenuWrapper
					isLoadingDeactivate={loading}
					isUnInstalling={unInstalling}
					status={currentExtension?.status}
					onClickDeactivate={onClickDeactivate}
					onClickActivate={onClickActivate}
					onClickUninstall={onClickUninstall}
					onClickVersion={onClickVersion}
					onClickDetails={onClickDetails}
					onClickBackendSettings={onClickBackendSettings}
				/>
			</div>
		</div>
	);
}
