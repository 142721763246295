import React from 'react';
import {
	InnerBlocks,
	InspectorControls,
	RichText,
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { extractTextFromHTML } from '../../helper';
import { BlockCard } from '../../../../new-blocks/components';
import EmptyTemplate from 'packages/blockEditor/components/BlockEditor/components/EmptyTemplate';
import { MenuIcon } from '@heroicons/react/outline';
// from
import { dispatch } from '@wordpress/data';
import { createBlock } from '@wordpress/blocks';
function addSubBlock(clientId) {
	try {
		const block = createBlock('appmaker/menu-item', {});
		dispatch('core/block-editor').insertBlocks(block, 0, clientId);
	} catch (error) {
		console.log(error);
	}
}

const Edit = (props) => {
	const { setAttributes, className, attributes, clientId } = props;
	const { showTitle, title } = attributes;

	return (
		<BlockCard
			attributes={{ showTitle }}
			className={className}
			titleEditor={
				<RichText
					tagName="h4"
					className={'text-md font-medium flex-1'}
					allowedFormats={[]}
					value={title}
					withoutInteractiveFormatting
					onChange={(text) => {
						setAttributes({ title: extractTextFromHTML(text) });
					}}
					placeholder="Menu title"
				/>
			}
		>
			<EmptyTemplate
				attributes={{
					title: 'Menu List',
					icon: MenuIcon,
				}}
			>
				<div className="flex items-center gap-4">
					<button
						onClick={() => addSubBlock(clientId)}
						type="button"
						className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Add Menu Items
					</button>
				</div>
			</EmptyTemplate>
			<InnerBlocks
				allowedBlocks={['appmaker/menu-item']}
				renderAppender={InnerBlocks.ButtonBlockAppender}
			/>

			<InspectorControls>
				<PanelBody>
					<ToggleControl
						label={'Show Title'}
						checked={showTitle}
						onChange={() => {
							setAttributes({ showTitle: !showTitle });
						}}
					/>
				</PanelBody>
			</InspectorControls>
		</BlockCard>
	);
};

export default Edit;
