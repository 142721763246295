import React, { useState, useEffect } from 'react';
import Gallery from './MediaUpload/Gallery';
import isEmpty from 'lodash/isEmpty'
import { useMediaStore } from '../store/media'
const Unsplash = () => {
	const searchText = useMediaStore(state => state.searchText)
	const [images, setImages] = useState([]);
	const setFiles = files => {
		const newImages = files.map(file => ({
			url: file.urls.thumb,
			title: file.alt_description,
			id: file.id,
			imageUrl: file.urls.thumb
		}))
		setImages(newImages)
	}
	useEffect(() => {
		const loadImage = async () => {
			const key =
				`058b6f5e36ed6b7502e24b8f83f8badaf529e88609981f3315d5c751e8d623a7&per_page=12`;
			const url =
				!isEmpty(searchText)
					? 'https://api.unsplash.com/search/photos?client_id=' +
					key +
					'&query=' +
					searchText
					: 'https://api.unsplash.com/photos?client_id=' + key;
			const resp = await fetch(url);
			const data = await resp.json();
			if (!isEmpty(searchText))
				setFiles(data.results);
			else
				setFiles(data);
		};
		loadImage();
	}, [searchText]);

	return (<Gallery schema={{ files: images }} />);
};
Unsplash.media = {
	title: 'Unsplash Images',
};

export default Unsplash;
