import { useEffect, useState } from 'react';
import { BellIcon, ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import LoadingIcon from 'packages/mobile-app/components/LoadingIcon';
import {
	StepOne as Login,
	StepTwo as ListProject,
	StepThree as ServiceUpload,
	StepFour as FcmServer,
	StepFive as UpdateApp,
	StepCreate as AppCreate,
} from './steps';
import StepCounter from './steps/steps';
import { useFormData, changeStep } from '../../store';
import VideoModal from 'packages/mobile-app/components/VideoModal';
import { useQueryParam, BooleanParam } from 'use-query-params';
import useProjectGraphQl from '@appmaker/core/store/project-graphql';

const PushNotification = () => {
	const formData = useFormData();
	const [loginCompleted, setLoginCompleted] = useQueryParam(
		'login_completed',
		BooleanParam
	);
	const { isConfigureLoading, currentStep } = formData;
	const { data = {} } = useProjectGraphQl();
	const { oauthProviders } = data;
	useEffect(() => {
		if (
			loginCompleted ||
			(oauthProviders && oauthProviders.find((item) => item.name === 'google'))
		) {
			changeStep(2);
		}
	}, [loginCompleted, oauthProviders]);

	return (
		<div className="max-w-4xl mx-auto bg-white border border-gray-100 rounded-lg shadow-lg overflow-hidden">
			<div className="flex gap-4 items-center p-4">
				<BellIcon className="h-12 w-12 text-blue-600 p-2 bg-blue-200 rounded-full" />
				<div>
					<span className="flex items-center space-x-2">
						<h3 className="text-xl font-medium text-gray-900">
							Configure push notification
						</h3>
						<VideoModal attributes={{ videoId: '0dkw5r4dTgk' }} />
					</span>
					<p className="font-base text-sm text-gray-500">
						Setup push notification to engage with users
					</p>
				</div>
			</div>
			<div className="p-4 flex flex-col items-center py-16">
				{isConfigureLoading && (
					<div className="py-16">
						<LoadingIcon className="text-blue-600 animate-spin h-16 w-16" />
					</div>
				)}
				{!isConfigureLoading && currentStep === 1 && <Login />}
				{!isConfigureLoading && currentStep === 2 && <ListProject />}
				{!isConfigureLoading && currentStep === 3 && <AppCreate />}
				{!isConfigureLoading && currentStep === 4 && <ServiceUpload />}
				{/* {!isConfigureLoading && currentStep === 5 && <FcmServer />}
				{!isConfigureLoading && currentStep === 6 && <UpdateApp />} */}
				{!isConfigureLoading && currentStep === 5 && <UpdateApp />}
			</div>
			{!isConfigureLoading && currentStep >= 2 && <StepCounter />}
		</div>
	);
};

export default PushNotification;
