import { Tab } from '@headlessui/react';
import { tabs } from '../content/pluginInstall';
import React from 'react';
import { classNames, ContentItem } from '../routes/tabs/InstallPlugin';

export function InstallSteps() {
	return (<Tab.Group>
		<Tab.List className=" relative z-0 rounded-lg shadow  divide-x divide-gray-200 flex">
			{tabs.map((tab, tabIdx) => <Tab key={tab} className={({
				selected
			}) => classNames(selected ? 'text-blue-500 bg-blue-50' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10')}>
				<span className="flex gap-3 items-center justify-center">
					<tab.icon className="h-4 w-4" />
					{tab.name}
				</span>

			</Tab>)}
		</Tab.List>
		{
			/* <VerificationFailedMessage /> */
		}
		<div className="md:flex md:gap-3 py-4">
			<div className="w-full bg-gray-50 p-4 rounded-md">

				<Tab.Panels className="mt-2">
					{tabs.map((tab, idx) => <Tab.Panel key={idx}>
						<ContentItem steps={tab.steps} />
					</Tab.Panel>)}
				</Tab.Panels>
			</div>
		</div>
	</Tab.Group>);
}
