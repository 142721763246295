import { CheckIcon } from '@heroicons/react/solid';
import { useMediaStore } from './store/context';

const GalleryItem = ({ file, selected, onSelect }) => {
	return (
		<li className="relative">
			{/* {file?.contentType} */}
			<div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg border border-gray-100 bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-blue-500 overflow-hidden">
				<img
					src={file.imageUrl}
					alt={file.title}
					className="object-cover pointer-events-none group-hover:opacity-75"
				/>
				{selected && (
					<CheckIcon className="h-5 w-5 bg-blue-500 text-white rounded absolute left-2 top-2" />
				)}
				<button
					type="button"
					className="absolute inset-0 focus:outline-none"
					onClick={() => onSelect(file, !selected)}
				>
					<span className="sr-only">View details for {file.title}</span>
				</button>
			</div>
		</li>
	);
};
const Gallery = ({ schema = {} }) => {
	const { files } = schema;
	const onSelectItem = useMediaStore((store) => store.setSelectedFile);
	const selectItem = (item, selection) => {
		onSelectItem(item);
	};

	return (
		<>
			<ul className="grid grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 my-4">
				{files.map((file) => (
					<>
						<GalleryItem key={file.id} file={file} onSelect={selectItem} />
					</>
				))}
			</ul>
		</>
	);
};

export default Gallery;
