
import { select } from '@wordpress/data';
window.select = select;
export function isValidBlock(block) {
    const validBlocks = select('core/blocks').getBlockTypes()

    return validBlocks.filter(item => {
        return item.name === block.name
    }).length > 0
}
export function filterValidBlocks(blocks) {
    // console.log(validBlocks, 'validBlocks');
    let finalBlocks = blocks.filter(isValidBlock)
    console.log({ finalBlocks, blocks });
    return finalBlocks

}