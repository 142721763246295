import Input from '@appmaker/core/components/AppmakerForm/components/TextField';
import { FileUpload } from './components';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import api from '../../../../api';
import { useFormData } from '../../../../store';
import { useProject } from '@appmaker/core/hooks';
import { CogIcon } from '@heroicons/react/outline';
const sendNotification = (toastMessage, options = {}, otherOption = {}) => {
	const toastOption = {
		duration: 5000,
		position: 'top-right',
	};
	switch (options.appearance) {
		case 'error': {
			return toast.error(toastMessage, { ...toastOption, ...otherOption });
		}
		case 'success': {
			return toast.success(toastMessage, toastOption);
		}
		default: {
			return toast(toastMessage, toastOption);
		}
	}
};

const StepTwo = ({ showIpaUpload = () => { } }) => {
	const [isBuilding, setBuilding] = useState(false);
	const [authKey, setAuthKey] = useState(false);
	const [apiKey, setApiKey] = useState(undefined);
	const [p8File, setP8File] = useState(undefined);
	const [issuerId, setIssuerId] = useState(undefined);
	const { iosAppData = {} } = useFormData();
	const { id: appId } = iosAppData;
	const { projectId } = useProject();
	const startCertBuildStatusCheck = () => {
		const certStatusCheck = setInterval(async () => {
			const response = await api.iosCreateCertStatus({ projectId, appId });
			if (response && response.status === '-1') {
				clearInterval(certStatusCheck);
				setBuilding(false);
				sendNotification(
					'Unable to generate certificate, If this issue continues, contact support',
					{
						appearance: 'error',
					},
					{ duration: 20000 }
				);
			} else {
				if (response.status === '2') {
					clearInterval(certStatusCheck);
					// setBuilding(false);
					sendNotification('Successfully generated certificate', {
						appearance: 'success',
					});
					// finished building
					showIpaUpload();
				}
			}
		}, 2000);
	};
	const buildIpa = async () => {
		if (!authKey || !apiKey || !issuerId) {
			sendNotification('All fields are mandatory', {
				appearance: 'error',
			});
			return false;
		}
		setBuilding(true);
		try {
			const response = await api.uploadIosAuthKey({
				projectId,
				appId,
				apiKey,
				authKey,
				issuerId,
				file: p8File
			});
			if (response && response.status) {
				// status check
				startCertBuildStatusCheck();
			} else {
				sendNotification(
					'Something went wrong',
					{
						appearance: 'error',
					},
					{ duration: 20000 }
				);
			}
		} catch (error) { }
	};
	if (isBuilding) {
		return (
			<div className="flex flex-col flex-auto items-center justify-center">
				<CogIcon className="h-20 w-20 animate-spin-slow text-blue-900" />
			</div>
		);
	}
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			<Toaster position="top-right" reverseOrder={true} />
			<li>
				Upload .p8 File
				<FileUpload
					properties={{
						fileType: '.p8',
					}}
					onDrop={(value, file) => {
						setAuthKey(value);
						setP8File(file);
					}}
				/>
			</li>
			<li>
				<Input
					properties={{ label: 'Enter Key ID' }}
					onChange={(value) => {
						setApiKey(value);
					}}
				/>
			</li>
			<li>
				<Input
					properties={{ label: 'Issurer ID' }}
					onChange={(value) => {
						setIssuerId(value);
					}}
				/>
			</li>
			<li className="list-none mt-4">
				<button
					onClick={() => {
						buildIpa();
					}}
					type="button"
					className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					<CogIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
					Build IPA File
				</button>
			</li>
		</ul>
	);
};

export default StepTwo;
