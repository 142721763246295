import {
    createStore,
    AppmakerFormProvider,
    useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useProject } from '@appmaker/core/hooks';
import { sendNotification } from '@appmaker/components/ReactHotToastIntegrator';
import { useState } from 'react';
import api from '../api';
import { useHistory } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';


const schema = {
    properties: {
        name: {
            type: 'string',
            label: 'Action Name',
            description: 'Choose an action name',
        },
        actionId: {
            type: 'string',
            label: 'Action Id',
            description: 'Choose an action id to identify uniquely',
        },
        value: {
            type: 'string',
            inputType: 'textArea',
            label: 'Action Value',
            description: '',
        },
        schema:{
            type: 'string',
            label: 'Action Schema',
            inputType: 'textArea',
            description: 'Choose an action schema',
        },
        code: {
            type: 'string',
            inputType: 'textArea',
            label: 'Code',
            description: '',
        },
        description: {
            type: 'string',
            inputType: 'textArea',
            label: 'Action Description'
        }
    }
}

const SaveButton = ({ isNew = false }) => {
    const { projectId } = useProject()
    const [isSaving, setSaving] = useState(false);
    const storeApi = useAppmakerFormStoreApi();
    const history = useHistory();
    const getRouterPath = useRouterPath();

    const validateJson = (schema) => {
        try {
            if (schema && schema.length === 0) {
                sendNotification('Action value cannot be empty', 'error');
                return false
            }
            const jsonParse = JSON.parse(schema);
            return true;
        } catch (error) {
            sendNotification('Invalid JSON', 'error');
            return false;
        }
    }
    const validateAction = (action) => {
        if (action && action.length === 0) {
            sendNotification('Action Id cannot be empty', 'error');
            return false;
        }
        const validate = action && action.indexOf(' ') === -1;
        if (!validate) {
            sendNotification('Remove blank characters from Id', 'error');
        }
        return validate;
    }
    const validateCode = (code) => {
        if (typeof code !== 'string' || code === '' || code.length === 0) {
            sendNotification('Code cannot be empty', 'error');
            return false;
        }
        return true;
    }
    const onClick = async () => {
        setSaving(true)
        const { formData } = storeApi.getState();
        const jsonValidate = validateJson(formData.value);
        const actionValidate = validateAction(formData.actionId);
        const codeValidate = validateCode(formData.code);
        if (jsonValidate && actionValidate && codeValidate) {
            try {
                const response = await api.updateAction({
                    projectId,
                    formData
                })
                if (isNew) {
                    sendNotification('Action created', 'success');
                    history.push(getRouterPath('/actions'))
                } else {
                    sendNotification('Action Updated', 'success');
                }

            } catch (error) {
                sendNotification('Something went wrong', 'error');
            }
        }
        setSaving(false)
    }
    return (
        <button
            onClick={onClick}
            disabled={isSaving}
            className='my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        >
            {isSaving ? 'Saving...' : 'Save'}
        </button>
    )
}

const FormSchema = ({ schema, projectId, metaKey, isNew }) => {

    return (
        <div className="bg-gray-50 h-full p-6">
            <div className="max-w-3xl">
                <AppmakerForm
                    formVersion={2}
                    // value={{ formData: flatten(formData) }}
                    schema={schema}
                />
                <SaveButton metaKey={metaKey} isNew={isNew} />
            </div>
        </div>
    );
}

const EditForm = ({
    isNew = true,
    formValue = {}
}) => {
    const { projectId } = useProject()
    return (
        <AppmakerFormProvider
            createStore={createStore({
                formData: formValue,
            })}
        >
            <FormSchema schema={schema} projectId={projectId} isNew={isNew} />
        </AppmakerFormProvider>
    )
}
export default EditForm