import { entity, persistence } from 'simpler-state'
import coreApi from '@appmaker/core/api/core';
import { getProjectId } from '@appmaker/core/store/project';

export const formData = entity({})
export const useFormData = (args) => formData.use(args)

export const setFormData = data => {
    formData.set(value => ({ ...value, ...data }))
}
export const syncFormData = async (skipBuild = true) => {
    // formData.set(data)
    /*
    Async code
    */
    // await new Promise(r => setTimeout(r, 2000));
    const projectId = getProjectId()
    await coreApi.saveAppFormData(projectId, formData.get(), skipBuild);

}