
import appmaker from '@appmaker/core/index';
import PluginRoute from './routes/PluginRoute';
import { LinkIcon } from '@heroicons/react/solid';
// import AppmakerMediaLibrary from './components/AppmakerMediaLibrary'
import Unsplash from './components/Unsplash'
import './filters'
const Plugin = {
    id: '@appmaker/media-library',
    name: 'MediaLibrary',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/MediaLibrary',
            component: PluginRoute,
        });

        /**
         * Registering meida libary
         */
        appmaker.addFilter('appmaker-media-library-factory', 'appmakerMedia', (items) => {
            // items.push(AppmakerMediaLibrary)
            items.push(Unsplash)
            return items
        })
    },
};

export default Plugin;
