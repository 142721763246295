import { useEffect } from 'react'
import { createStore, Provider, useStore } from './store'
import FormItemWrapper from './FormItemWrapper';
function DebugView() {
    const store = useStore(state => state.formData)
    return <pre>
        {JSON.stringify(store, null, 2)}
    </pre>
}
function DataUpdate({ value }) {
    const setData = useStore(state => state.setData)
    useEffect(() => {
        console.log(Object.keys(value?.formData), 'value');
        setData(value)
    }, [value, setData])
    return null
}
const AppmakerFormWrapper = (props) => <> <FormItemWrapper {...props} />
    {/* <DebugView /> */}
    {/* <DataUpdate value={props.value} /> */}
</>
export default AppmakerFormWrapper;
