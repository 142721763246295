import axios from 'axios';
import { V3_BASE_URL } from '@appmaker/core/api/core';
const BASE_URL = V3_BASE_URL + '/projects'
const api = {
	getList: async ({ projectId }) => {
		try {
			const response = await axios.get(`${BASE_URL}/${projectId}/extension`, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	activateExtension: async ({ projectId, extensionId, status }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/extension`,
				{
					extensionId,
					status : status
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	extensionSettings: async ({ projectId, metaValue, metaKey, projectExtensionId }) => {

		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/extension/settings`,
				{
					projectExtensionId,
					metaValue,
					metaKey
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	deactivateExtension: async ({ projectId, extensionId, status }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/extension`,
				{
					extensionId,
					status: status ?? 'inactive'
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getValues: async ({ projectId, projectExtensionId, metaKey }) => {
		try {
			let url = `${BASE_URL}/${projectId}/extension/settings?projectExtensionId=${projectExtensionId}`;
			if (metaKey) {
				url += `&metaKey=${metaKey}`;
			}
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	saveWorkflow: async ({ projectId, workflow }) => {
		let url = `${BASE_URL}/${projectId}/workflow`;
		const response = await axios.post(url, workflow, {
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			withCredentials: true,
		})
		return response.data;
	},
	getWorkflow: async ({ projectId }) => {
		let url = `${BASE_URL}/${projectId}/workflow`;
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			withCredentials: true,
		})
		return response.data;
	}
}

export default api;