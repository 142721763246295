import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import { CalendarIcon } from '@heroicons/react/outline';

const DateTime = (props) => {
    const {
        name,
        onChange,
        properties: {
            title,
            label,
            disableClock = true,
            minDate,
            maxDate,
            dayPlaceholder = "dd",
            monthPlaceholder = "mm",
            yearPlaceholder = "yyyy",
            secondPlaceholder = "ss",
            minutePlaceholder = "mm",
            hourPlaceholder = "hh",
            className
        } = {},
        defaultValue, value,


    } = props;
    //new Date(moment().add(30, 'days'))
    let date = defaultValue ?? value;
    date = date ? new Date(date) : undefined;

    return (
        <div className={className ? className : 'mt-4'}>
            <label htmlFor={name} className="block text-sm font-base text-gray-700">
                {label ?? title}
            </label>
            <div className="mt-1 relative">
                <DateTimePicker
                    label="DateTimePicker"
                    minDate={minDate ? new Date(minDate) : undefined}
                    maxDate={maxDate ? new Date(maxDate) : undefined}
                    className="p-1 border border-gray-300 rounded-md w-full bg-white"
                    calendarClassName="bg-white rounded-lg overflow-hidden"
                    disableClock={disableClock}
                    value={date}
                    dayPlaceholder={dayPlaceholder}
                    monthPlaceholder={monthPlaceholder}
                    yearPlaceholder={yearPlaceholder}
                    secondPlaceholder={secondPlaceholder}
                    minutePlaceholder={minutePlaceholder}
                    hourPlaceholder={hourPlaceholder}
                    calendarIcon={
                        <CalendarIcon className="h-8 w-8 bg-gray-200 p-1 rounded-md" />
                    }
                    onChange={(newValue) => {
                        onChange(newValue)
                    }}

                />
            </div>

        </div>)
}

export default DateTime