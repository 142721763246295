import shopifyPrebuildScroller from "./shopifyPrebuildScroller";
import ProductScroller from './product-scroller';

import { registerBlockType } from '@wordpress/blocks';

// export {}
const blocks = [shopifyPrebuildScroller, ProductScroller]
// const blocks =[]
export function registerBlockTypes() {
    blocks.map(block => registerBlockType(block.name, block.settings))
}

