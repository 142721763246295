import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { getProjectData } from '@appmaker/core/store/project';

const AdminTestPush = ({
    sending=false,
    disabled=false,
    onClick=()=>{}
}) => {
    const { loading, projectData } = getProjectData();
    const { feature } = projectData || {};
    const isAdmin = checkIsAdmin();
    if(!isAdmin){
        if(!feature?.enableTestPush){
            return null;
        }
    }
    return <button 
    disabled={disabled || sending}
    onClick={onClick}
    className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
        {sending ? (
									<LoadingIcon className="animate-spin -ml-1 mr-3 h-5 w-5" />
								) : (
									<PaperAirplaneIcon
										className="-ml-1 mr-3 h-5 w-5 transform rotate-45 -translate-y-0.5"
										aria-hidden="true"
									/>
								)}
        {sending ? 'Sending...' : 'Send Test Push'}
    </button>
}

export default AdminTestPush;