import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from '@appmaker/core/routes';
import { GET_ALL_EXTENSION_LIST } from '../api/graphql';
import debounce from 'lodash/debounce'; // Import the debounce function from Lodash
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { Link } from '@appmaker/core/routes';
import clsx from 'clsx';
import Loader from '@appmaker/components/Loading';
import { CubeIcon, SearchIcon } from '@heroicons/react/outline';
import { isAdmin } from '@appmaker/core/store/user';

const ListItem = (props) => {
	const getRouterPath = useRouterPath();
	const { extension } = props;
	let bgColor = 'bg-white';
	if (extension?.type === 'admin' || extension?.type === 'private') {
		bgColor = 'bg-blue-50';
	}
	if (extension?.type === 'unlisted') {
		bgColor = 'bg-red-50';
	}
	return (
		<Link
			to={getRouterPath('/extension/install/' + extension?.handle)}
			className={clsx(
				'p-2 group rounded-xl border hover:shadow relative border-gray-300',
				bgColor
			)}
		>
			<div className="relative group flex items-center justify-between space-x-3">
				<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-9 w-9">
					<img
						src={
							extension?.icon ||
							'https://storage.googleapis.com/appilder_cdn/extension-default.png'
						}
						alt={extension?.imageAlt}
						className="object-contain object-center h-9 w-9"
					/>
				</div>
				<div className="flex-1">
					<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
						{extension?.name}
					</h3>
				</div>
			</div>
		</Link>
	);
};
const ExtensionList = () => {
	const getRouterPath = useRouterPath();
	const { handle, projectId } = useParams();
	const [search, setSearch] = useState('');
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(30);
	const [selectedType, setSelectedType] = useState('all');
	const history = useHistory();

	const { loading, error, data, refetch } = useQuery(GET_ALL_EXTENSION_LIST, {
		variables: {
			where: {
				name: {
					contains: search,
				},
				type:
					selectedType === 'all'
						? undefined
						: {
							in: [selectedType],
						},
			},
			orderBy: [{ name: 'asc' }],
			skip: search?.length > 0 ? 0 : skip,
			take,
		},
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	useEffect(() => {
		console.log(data);
	}, [data]);
	const extensions = data?.extensions || [];

	const handleSearch = debounce(() => {
		refetch();
	}, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

	const handlePreviousPage = () => {
		if (skip - take >= 0) {
			setSkip(skip - take);
			refetch();
		}
	};

	const handleNextPage = () => {
		if (extensions.length >= take) {
			setSkip(skip + take);
			refetch();
		}
	};

	// const handleBoxClick = (handle) => {
	//     // Handle box click action here
	//     history.push(getRouterPath(`/extension/install/${handle}`))
	// }
	if (loading) {
		<Loader />;
	}
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto relative">
			<div className="bg-white shadow-sm grid grid-cols-2 sm:grid-cols-3 gap-4 px-4 py-2 items-center">
				<h2 className="text-lg font-medium text-gray-900">All Extensions</h2>
				<div className="relative bg-gray-50 border border-gray-100 rounded-md text-gray-400 focus-within:text-gray-600">
					<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
						<SearchIcon className="h-5 w-5" aria-hidden="true" />
					</div>
					<input
						id="search"
						className="block w-full bg-gray-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
						placeholder="Search by name"
						type="text"
						name="search"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>

				{/* <div className="flex justify-end">
					<a
						href="https://appmaker.xyz/extensions"
						target="_blank"
						rel="noreferrer"
						className="rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						Explore Extensions
					</a>
				</div> */}
			</div>

			<div className="max-w-2xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-5xl lg:px-8">
				<div className="flex justify-end items-center space-x-1">
					<label className="text-sm sr-only">Sort</label>
					<div className="relative bg-white border border-gray-100 rounded-md text-gray-400 focus-within:text-gray-600 inline-flex">
						<select
							id="filterType"
							name="filterType"
							value={selectedType}
							onChange={(e) => setSelectedType(e.target.value)}
							className="block bg-white py-2 pl-3 pr-10 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
						>
							<option value="all">All</option>
							<option value="public">Public</option>
							{isAdmin() && (<option value="admin">Admin</option>)}
							<option value="private">Private</option>
							<option value="unlisted">Unlisted</option>
						</select>
					</div>
				</div>
				{loading && <Loader />}
				{error && <p className="mt-4 text-red-500">Error: {error.message}</p>}
				{extensions.length === 0 && !loading ? (
					<div className="px-4 py-8 col-span-2 group rounded-xl bg-white bg-opacity-50 flex flex-col justify-center items-center border border-dashed border-gray-300">
						<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
						<h3 className="text-md font-medium text-gray-500 mt-3">
							Extension not found
						</h3>
					</div>
				) : (
					<ul className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-2">
						{extensions.map((extension) => (
							<ListItem extension={extension} />
						))}
					</ul>
				)}
				<nav
					className="flex items-center justify-between border border-gray-200 bg-white px-4 py-3 sm:px-6 mt-8 rounded-lg"
					aria-label="Pagination"
				>
					<div className="hidden sm:block">
						<p className="text-sm text-gray-700">
							Showing <span className="font-medium">{skip + 1}</span> to{' '}
							<span className="font-medium">{skip + take}</span> of{' '}
							<span className="font-medium">{data?.extensionsCount}</span>{' '}
							extensions
						</p>
					</div>

					<div className="flex flex-1 justify-between sm:justify-end">
						<button
							onClick={handlePreviousPage}
							disabled={skip === 0}
							className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
						>
							Previous
						</button>
						<button
							onClick={handleNextPage}
							disabled={extensions.length < take}
							className="relative ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-gray-100 ring-1 ring-inset ring-blue-600 hover:bg-blue-800 focus-visible:outline-offset-0"
						>
							Next
						</button>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default ExtensionList;
