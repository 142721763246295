import { LinkIcon } from '@heroicons/react/outline';
import { RangeControl } from '@wordpress/components';
import clsx from 'clsx';
import React from 'react';

export default function PaddingRangeControl({ attributes, setAttributes }) {
	const [linked, setLinked] = React.useState(true);
	const {
		paddingLeft = 0,
		paddingRight = 0,
		paddingTop = 0,
		paddingBottom = 0,
	} = attributes;
	return (
		<div className="mt-4">
			<div className="flex items-center justify-between">
				<h4 className="text-sm mb-2 text-gray-800">Padding</h4>
				<button
					className={clsx(
						'p-1 border rounded',
						linked
							? 'text-blue-600 border-blue-600'
							: 'border-gray-400 text-gray-400'
					)}
					onClick={() => setLinked(!linked)}
				>
					<LinkIcon className="h-5 w-5 " />
				</button>
			</div>
			<div className="flex flex-col ml-2">
				{!linked ? (
					<>
						<RangeControl
							label="Top"
							value={paddingTop}
							onChange={(paddingTop) => setAttributes({ paddingTop })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Right"
							value={paddingRight}
							onChange={(paddingRight) => setAttributes({ paddingRight })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Bottom"
							value={paddingBottom}
							onChange={(paddingBottom) => setAttributes({ paddingBottom })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Left"
							value={paddingLeft}
							onChange={(paddingLeft) => setAttributes({ paddingLeft })}
							min={0}
							max={100}
						/>
					</>
				) : (
					<RangeControl
						className="mt-3"
						value={paddingTop}
						onChange={(paddingTop) =>
							setAttributes({
								paddingTop,
								paddingRight: paddingTop,
								paddingBottom: paddingTop,
								paddingLeft: paddingTop,
							})
						}
						min={0}
						max={100}
					/>
				)}
			</div>
		</div>
	);
}
