import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import {
	useData,
	setInAppPageToTemplate,
	useModal,
	getBlockCount,
} from '../hooks';
import SimpleAlert from '@appmaker/components/templates/SimpleAlert';
import { getProjectId } from '@appmaker/core/store/project';
import appmaker from '@appmaker/core/index';

function FigmaIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={18}
			height={18}
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z" />
			<path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z" />
			<path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z" />
			<path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z" />
			<path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z" />
		</svg>
	);
}

const TemplatePreview = () => {
	const {
		previewTemplateId,
		setPreviewTemplate,
		originalTemplate: templates,
		isTemplateSetting,
	} = useData();
	const [, setModal] = useModal();
	const [data, setData] = useState({});
	const [alertModal, setAlertModal] = useState(false);
	const projectId = getProjectId();
	useEffect(() => {
		setData(
			templates
				? templates.find((item) => {
					return item.id === previewTemplateId;
				})
				: {}
		);
		// console.log(templates)
	}, [previewTemplateId, templates]);
	return (
		<>
			{alertModal && (
				<SimpleAlert
					openModal={alertModal}
					onClose={setAlertModal}
					attributes={{
						title: '',
						message:
							'This action will replace your current in-app page. Are you sure you want to continue?',
						ctaMain: {
							action: () => {
								setInAppPageToTemplate(data.blocks || []);
								setModal(false);
								setAlertModal(false);
								setPreviewTemplate(undefined);
							},
							label: 'Continue',
						},
						ctaSecondary: {
							action: () => {
								setAlertModal(false);
							},
							label: 'Cancel',
						},
					}}
				/>
			)}
			<div className="col-span-2 pl-6 pt-6">
				<button
					className="flex items-center space-x-2 text-lg px-2 py-1 hover:bg-gray-100 rounded-md text-gray-700 mb-8"
					onClick={() => {
						setPreviewTemplate(undefined);
					}}
				>
					<ArrowLeftIcon className="h-5 w-5" />
					<span>Back to templates</span>
				</button>
				<h3 className="text-xl text-gray-900">{data.name}</h3>
				<h3 className="mt-2 text-sm text-gray-700 mb-12 mr-2 pr-2">
					{data.description}
				</h3>
				<div className="flex items-center space-x-4">
					<button
						disabled={isTemplateSetting}
						className="shadow-2xl inline-flex bg-blue-600 text-white py-2 px-4 rounded-md text-sm font-medium text-center hover:bg-blue-500"
						onClick={() => {
							appmaker.doAction('appmaker-analytics-event', {
								event: `template_chooser_use_template_button_clicked_${data.id}`,
								params: {
									projectId,
									insideShopify: !!window.shopifyApp,
								},
							});
							if (getBlockCount() !== 0) {
								setAlertModal(true);
							} else {
								setInAppPageToTemplate(data.blocks);
								setModal(false);
							}
						}}
					>
						{isTemplateSetting ? 'Loading...' : 'Use Template'}
					</button>
					{data.figmaSrc ? (<a
						href={data.figmaSrc}
						target="_blank"
						rel="noreferrer"
						className="shadow-2xl inline-flex justify-center space-x-2 bg-gray-800 text-white py-2 px-4 rounded-md text-sm font-medium text-center hover:bg-gray-900"
						onClick={() => {
							appmaker.doAction('appmaker-analytics-event', {
								event: `figma_clicked`,
								params: {
									projectId,
									insideShopify: !!window.shopifyApp,
									templateId: data.id
								},
							});
						}}
					>
						{<FigmaIcon />}
						Get figma kit
					</a>) : null}
				</div>
			</div>
			<div className="col-span-3 bg-gray-300 overflow-y-auto px-4">
				<img
					src={data.previewSrc}
					alt="preview"
					className="w-96 mx-auto py-8"
				/>
			</div>
		</>
	);
};

export default TemplatePreview;
