import api from '../api';
import { useEffect, useState } from 'react';
import { useProject } from '@appmaker/core/hooks';
import { setThemeList } from '../store';
import Loader from '@appmaker/components/Loading';
import List from '../components/List';
import { THEME_LIST } from '../api/graphql';
import { useQuery } from '@apollo/client';
import useThemeStore from '../store/list';
import RedirectV3 from 'packages/extensions/routes/RedirectV3';
const Home = () => {
	const { projectId } = useProject();
	const setThemeData = useThemeStore((store) => store.setThemeData);
	const {
		loading: isLoading,
		data,
		error,
	} = useQuery(THEME_LIST, {
		variables: {
			where: {
				type: {
					equals: 'public',
				},
			},
		},
		fetchPolicy: 'no-cache',
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	useEffect(() => {
		setThemeData(data?.themes, data?.themeInstalls);
	}, [data]);
	if (isLoading) return <Loader />;

	return (
		<div>
			<RedirectV3 id='theme'/>
			<List />
		</div>
	);
};

export default Home;
