import React from 'react';
import {
	InspectorControls,
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl, TextControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';


const Panel = (props) => {
	const { attributes, onChangeAttribute = false } = props;
	const { showTitle, showSubTitle, __display = true } = attributes || {};
	return (
		<InspectorControls>
			<PanelBody>
				<ToggleControl
					label={__('Show Title', 'newspack-blocks')}
					checked={showTitle}
					onChange={() => {
						if (onChangeAttribute !== false)
							onChangeAttribute({ showTitle: !showTitle });
					}}
				/>
				<ToggleControl
					label={__('Show subtitle', 'newspack-blocks')}
					checked={showSubTitle}
					onChange={() => {
						if (onChangeAttribute !== false)
							onChangeAttribute({ showSubTitle: !showSubTitle });
					}}
				/>
			</PanelBody>
			<PanelBody title="Advanced" initialOpen={false}>
				<TextControl
					label={'Display'}
					value={__display}
					onChange={(val) => {
						onChangeAttribute({ __display: val });
					}}
				/>

			</PanelBody>
		</InspectorControls>
	);
};

export default Panel;