import create from 'zustand';
import produce from 'immer';
import createContext from 'zustand/context';
const createStore = () =>
	create((set) => ({
		isLoading: false,
		extension: {},
		extensionInstalls: [],
		unSaved: false,
		setUnsaved: (value) =>
			set(
				produce((state) => {
					state.unSaved = value;
				})
			),
		setLoading: (value) =>
			set(
				produce((state) => {
					state.isLoading = value;
				})
			),
		setDetails: ({ extension, extensionInstalls }) => {
			return set(
				produce((state) => {
					if (extension) state.extension = extension;
					if (extensionInstalls) state.extensionInstalls = extensionInstalls;
				})
			);
		},
		setExtension: (extension) =>
			set(
				produce((state) => {
					state.extension = extension;
				})
			),
		syncSettings: (id, newSettings) => {
			return set(
				produce((state) => {
					state.extensionInstalls = state?.extensionInstalls?.map((item) => {
						if (item.id === id) {
							return {
								...item,
								settings: newSettings,
							};
						} else {
							return item;
						}
					});
					state.unSaved = false;
				})
			);
		},
		syncActiveStatus: (id, status) => {
			return set(
				produce((state) => {
					state.extensionInstalls = state?.extensionInstalls?.map((item) => {
						if (item.id === id) {
							return {
								...item,
								status: status,
							};
						} else {
							return item;
						}
					});
				})
			);
		},
		syncUninstall: (id) => {
			return set(
				produce((state) => {
					state.extensionInstalls = state?.extensionInstalls
						?.map((item) => {
							if (item.id === id) {
								return undefined;
							} else {
								return item;
							}
						})
						?.filter((item) => item !== undefined);
				})
			);
		},
		syncVersion: (id, { androidVersion, iosVersion } = {}) => {
			return set(
				produce((state) => {
					state.extensionInstalls = state?.extensionInstalls?.map((item) => {
						if (item.id === id) {
							return {
								...item,
								androidVersion: androidVersion ?? item?.androidVersion,
								iosVersion: iosVersion ?? item?.iosVersion,
							};
						} else {
							return item;
						}
					});
					state.unSaved = false;
				})
			);
		}
	}));

const { Provider: DetailPageProvider, useStore: useExtensionDetail } =
	createContext();

export { createStore, DetailPageProvider, useExtensionDetail };
