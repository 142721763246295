import React, { Suspense } from 'react';

import appmaker from '@appmaker/core/index';
import PluginRoute from './routes/PluginRoute';

import {
	ViewGridAddIcon,
	MenuAlt2Icon,
	BellIcon,
	PaperClipIcon,
	TemplateIcon,
} from '@heroicons/react/solid';

import actions from './actions';
import { setInAppPageData } from './store';
import './filters';
import './filters/image-compression';
import { getProjectId } from '@appmaker/core/store/project';
// import BlockEditor from './routes/Editor';

const BlockEditor = React.lazy(() => import('./routes/Editor'));
const MenuBlockEditor = React.lazy(() => import('./routes/menu'));
const TemplateEditor = React.lazy(() => import('./routes/TemplateEditor'));
const ReUsableBlocks = React.lazy(() => import('./routes/ReUsableBlocks'));
// const Header = React.lazy(()=>import('./components/Header'));
// const BlockEditor = lazy(() => import('./routes/Editor'));
const Plugin = {
	id: '@appmaker/blockEditor',
	name: 'Block Editor',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/blockEditor',
			component: BlockEditor,
		});

		appmaker.registerRoute({
			routeName: '/menu',
			component: MenuBlockEditor,
		});
		appmaker.registerRoute({
			routeName: '/playground-PluginRoute',
			component: PluginRoute,
		});


		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu(
			{
				name: 'App Builder',
				routeName: '/blockEditor',
				icon: ViewGridAddIcon,
				addTracker: {
					eventName: 'customize_app_clicked'
				}
			},
			3
		);


		let menuUrl = `https://manage-v2.appmaker.xyz/apps/${getProjectId()}/menu/`;
		if (window.shopifyHost) {
			menuUrl += `?host=${window.shopifyHost}&shopifySessionToken=${window.shopifySessionToken}`;
		}
		appmaker.registerSideBarMenu(
			{
				name: 'App Menu',
				routeName: '/menu/mainmenu',
				icon: MenuAlt2Icon,
			},
			4
		);

		// appmaker.registerSideBarMenu(
		// 	{
		// 		name: 'Docs',
		// 		href: `https://appmaker.xyz/en/docs`,
		// 		routeName: '/blockEditor',
		// 		icon: PaperClipIcon,
		// 	},
		// 	6
		// );
		Object.keys(actions).map((action) =>
			appmaker.actions.registerAction(action, actions[action])
		);
		appmaker.doAction('register-onboarding-steps', {
			title: 'App Design',
			id: 'blockEditor',
			route: '/blockEditor',
			order: 5
		})
		// override the in-app page data from other packages shopify
		appmaker.addAction('override-in-app-page-data', 'set-blocks', (data) => {
			setInAppPageData(data);
		})
		// appmaker.addFilter('dashboard-header-menu', 'shopify-templates', (item) => <>{item} <Suspense fallback={<div></div>}><Header/></Suspense></>, 1)
	},
};

const reusablePlugin = {
	id: '@appmaker/blockEditor/reusable-block',
	name: 'Reusable Blocks',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/reusable-blocks',
			component: ReUsableBlocks,
		});

		/**
		 * Menu Items
		 */

		appmaker.registerSideBarMenu(
			{
				name: 'Reusable blocks',
				routeName: '/reusable-blocks',
				icon: TemplateIcon,
			},
			7
		);
	},
}

const templateEditorPlugin = {
	id: '@appmaker/blockEditor/template-editor',
	name: 'Template Editor',
	activate: () => {

		appmaker.registerRoute({
			routeName: '/templateEditor',
			component: TemplateEditor,
		});

		/**
		 * Menu Items
		 */

		appmaker.registerSideBarMenu(
			{
				name: 'Template Editor',
				routeName: '/templateEditor',
				icon: TemplateIcon,
			},
			7
		);
	},
};

export {
	reusablePlugin,
	templateEditorPlugin
}
export default Plugin;
