import { useParams, Link } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import useThemeStore from '../store/theme';
import DropDownMenu from '@appmaker/components/DropDownMenu';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
	CREATE_THEME_INSTALL,
	DELETE_THEME_INSTALL,
	UPDATE_THEME_INSTALL,
} from '../api/graphql';
import { toast } from '@appmaker/components/ReactHotToastIntegrator';
import { useHistory } from '@appmaker/core/routes';

const ThemeHeader = () => {
	const [dropDownItems, setDropDownItems] = useState([]);
	const themeData = useThemeStore((store) => store.theme);
	const installData = useThemeStore((store) => store.installedData);
	const syncInstall = useThemeStore((store) => store.setInstall);
	const syncActiveStatus = useThemeStore((store) => store.syncActiveStatus);
	const syncDefaultTheme = useThemeStore((store) => store.syncDefaultTheme)
	const syncUninstall = useThemeStore((store) => store.syncUninstall);
	const history = useHistory();
	const isThemeInstalled = installData?.length > 0;
	let { handle, projectId,route } = useParams();
	const currentInstallation = installData?.find(
		(item) => item?.theme?.handle === handle
	);
	const getRouterPath = useRouterPath();
	const [installThemeGql, { loading }] = useMutation(CREATE_THEME_INSTALL, {
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	const [updateTheme, { loading: isUpdating }] = useMutation(
		UPDATE_THEME_INSTALL,
		{
			context: {
				headers: {
					'x-appmaker-project-id': projectId,
				},
			},
		}
	);
	const [deleteInstall, { loading: isDeleting }] = useMutation(
		DELETE_THEME_INSTALL,
		{
			context: {
				headers: {
					'x-appmaker-project-id': projectId,
				},
			},
		}
	);
	const deleteTheme = () => {
		toast.promise(
			(async () => {
				await deleteInstall({
					variables: {
						where: {
							id: currentInstallation?.id,
						},
					},
				});
				syncUninstall(currentInstallation?.id);
				// syncInstall()
				// syncActiveStatus(currentExtension?.id, 'active');
			})(),
			{
				loading: 'Uninstalling...',
				success: <b>Theme Uninstalled</b>,
				error: <b>Unable to uninstall</b>,
			}
		);
	};
	const installTheme = () => {
		toast.promise(
			(async () => {
				const response = await installThemeGql({
					variables: {
						data: {
							theme: {
								connect: {
									handle: handle,
								},
							},
							project: {
								connect: {
									remoteProjectId: parseInt(projectId, 10),
								},
							},
						},
					},
				});
				syncInstall(response?.data?.createThemeInstall);
				// syncActiveStatus(currentExtension?.id, 'active');
			})(),
			{
				loading: 'Installing...',
				success: <b>Theme Installed</b>,
				error: <b>Unable to install</b>,
			}
		);
	};
	const activateTheme = () => {
		toast.promise(
			(async () => {
				const response = await updateTheme({
					variables: {
						where: {
							id: currentInstallation?.id,
						},
						data: {
							status: 'active',
						},
					},
				});
				// syncInstall(response?.data?.createThemeInstall);
				syncActiveStatus(currentInstallation?.id, 'active');
			})(),
			{
				loading: 'Activating...',
				success: <b>Theme activated</b>,
				error: <b>Unable to activate</b>,
			}
		);
	};
	const deactivateTheme = () => {
		toast.promise(
			(async () => {
				const response = await updateTheme({
					variables: {
						where: {
							id: currentInstallation?.id,
						},
						data: {
							status: 'in_active',
						},
					},
				});
				syncActiveStatus(currentInstallation?.id, 'in_active');
			})(),
			{
				loading: 'Deactivating...',
				success: <b>Theme deactivated</b>,
				error: <b>Unable to deactivate</b>,
			}
		);
	};
	const makeDefaultTheme = () => {
		toast.promise(
			(async () => {
				const response = await updateTheme({
					variables: {
						where: {
							id: currentInstallation?.id,
						},
						data: {
							isDefault: true,
						},
					},
				});
				// syncInstall(response?.data?.createThemeInstall);
				syncDefaultTheme(currentInstallation?.id, true);
			})(),
			{
				loading: 'Setting...',
				success: <b>Theme set to default</b>,
				error: <b>Unable to activate</b>,
			}
		);
	}
	const onClickVersion = () => {
		history.push(getRouterPath(`/themes/${handle}/version`));
	}
	const onClickDetail = ()=>{
		history.push(getRouterPath(`/themes/${handle}`));
	}
	useEffect(() => {
		const options = [];
		if (currentInstallation?.status === 'active') {
			options.push({
				label: 'Deactivate',
				onClick: () => {
					deactivateTheme();
				},
			});
		} else {
			if (isThemeInstalled) {
				options.push({
					label: 'Activate',
					onClick: () => {
						activateTheme();
					},
				});
			}
		}
		if (installData?.length > 0) {
			options.push({
				label: 'Uninstall',
				onClick: () => {
					deleteTheme();
				},
			});
		}
		if (currentInstallation && route !=='version') {
			options.push({
				label: 'Version',
				onClick: () => {
					onClickVersion()
				}
			})
		}else{
			options.push({
				label: 'Details',
				onClick: () => {
					onClickDetail()
				}
			})
		}
		setDropDownItems(options);
	}, [themeData, installData, currentInstallation]);
	return (
		<div className="flex items-center justify-between space-x-3 bg-white shadow-sm px-4 py-2">
			<div className="flex items-center space-x-3">
				<Link to={getRouterPath('/themes/')}>
					<ArrowLeftIcon className="h-8 w-8 p-2 hover:bg-gray-300 rounded-md text-gray-600" />
				</Link>
				<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-10 w-10">
					<img
						src={themeData?.icon}
						alt={'img'}
						className="object-center object-contain h-10 w-10"
					/>
				</div>
				<div>
					<h4 className="text-lg font-semibold text-gray-900">
						{themeData?.name}
					</h4>
				</div>
			</div>
			<div className="flex items-center space-x-2">
				{currentInstallation?.isDefault && (
					<>
						<span className='className="inline-flex font-medium justify-self-end bg-green-50 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base text-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500"'>
							Default Theme
						</span>
					</>
				)}
				{!currentInstallation?.isDefault && currentInstallation?.status === 'active' && (
					<>
						<button
							disabled={loading}
							type='button'
							onClick={makeDefaultTheme}
							className='inline-flex font-medium justify-self-end bg-blue-100 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500'>
							Make as Default Theme
						</button>
					</>
				)}
				{currentInstallation?.status === 'active' && (
					<>
						<span className='className="inline-flex font-medium justify-self-end bg-green-50 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base text-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500"'>
							Active theme
						</span>
					</>
				)}
				{isThemeInstalled && currentInstallation?.status !== 'active' && (
					<>
						<button
							disabled={loading}
							type="button"
							onClick={activateTheme}
							className="inline-flex font-medium justify-self-end bg-green-50 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base text-green-600 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500"
						>
							{isUpdating ? 'Loading... ' : ` Activate`}
						</button>
					</>
				)}
				{!isThemeInstalled && (
					<>
						<button
							disabled={loading}
							type="button"
							onClick={installTheme}
							className="inline-flex justify-self-end bg-blue-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
						>
							{loading ? 'Loading... ' : `Install `}
						</button>
					</>
				)}
				{!loading && <DropDownMenu options={dropDownItems} />}
			</div>
		</div>
	);
};

export default ThemeHeader;
