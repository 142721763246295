import { getProjectData } from "@appmaker/core/store/project";
import axios from "axios";
export async function getShopifyCollection() {
const query = `
{
    collections(first: 100) {
      edges {
        node {
          id
          title
          image {
            url
          }
          products(first: 4) {
            edges {
              node {
                featuredImage {
                  url
                }
              }
            }
          }
        }
      }
    }
  }`
    const data = await gql({
        query
    })
    console.log(data);
    return data;


}
export async function gql({
    query,
    variables
}) {
    const project = getProjectData();
    const { projectData } = project;
    const { meta } = projectData;
    const { SHOPIFY_ACCESS_TOKEN, URL } = meta;
    const headers = {
        "Content-Type": "application/json",
        "x-shopify-storefront-access-token": SHOPIFY_ACCESS_TOKEN
    };
    const url = `${URL}/api/2023-04/graphql.json`;
    // axios
    const response = await axios.post(url, {
        query,
        variables
    }, {
        headers
    });
    return response.data;
    // console.log(project);
}