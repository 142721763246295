import appmaker from '@appmaker/core/index';
import { LightBulbIcon, BellIcon } from '@heroicons/react/solid';
import PushNotification from './routes';
import ConfigurePushNotification from './routes/Configure';

// const BlockEditor = lazy(() => import('./routes/Editor'));
const Plugin = {
	id: '@appmaker/PushNotification',
	name: 'PushNotification',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/push-notification/',
			component: PushNotification,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu(
			{
				name: 'Push Notification',
				routeName: '/push-notification',
				icon: BellIcon,
			},
			5
		);


		appmaker.doAction('register-onboarding-steps', {
			title: 'Configure Push Notification',
			id: 'pushNotification/configure',
			route: '/push-notification/configure',
			order: 6,
		});
	},
};

export default Plugin;
