import DashboardLoading from 'components/skeltonLoading/Dashboard';
import {
    Route,
} from 'react-router-dom';
import { useUser } from '@appmaker/core/store/user';
import { BASE_URL } from '@appmaker/core/api/core'


export default function PrivateRoute({ children, ...rest }) {
    const user = useUser();
    if (user === undefined) {
        return <DashboardLoading />;
    } else if (!user.logged_in) {
        const ssoLoginUrl = `${BASE_URL}/user/sso-login?return_to=${window.location.href}`;
        window.location = ssoLoginUrl;
        return null;
    }
    return (
        <Route
            {...rest}
            render={({ location }) =>
                children
            }
        />
    );
}