import {
    isAdmin,
    toggleAdmin
} from '@appmaker/core/store/user';
import { useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ToggleAdmin = () => {

    return (
        <div  className='m-4 mt-10 flex flex-rows align-center gap-4'>
            <label className="text-gray-500 text-xs font-medium uppercase tracking-wide">Toggle Admin</label>
            <Switch
                checked={isAdmin()}
                onChange={toggleAdmin}
                className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
                <span
                    aria-hidden="true"
                    className={classNames(
                        isAdmin() ? 'bg-indigo-600' : 'bg-gray-200',
                        'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
                    )}
                />
                <span
                    aria-hidden="true"
                    className={classNames(
                        isAdmin() ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
                    )}
                />
            </Switch>
        </div>
    );
}
export default ToggleAdmin;