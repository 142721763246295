import CartBlock, { CardTitle } from './CardBlock';

const ContentBlock = ({ children }) => {
	return (
		<CartBlock>
			<div className="flex-1 flex flex-col">
				{/* main contents of update app */}
				<div>
					<div className="flex justify-between">
						<div className="mb-3">
							<CardTitle
								title="Connect the App to your website."
								description="Follow the steps below to install and activate the Appmaker plugin on your website."
								video={{
									videoLabel: 'Play tutorial',
									videoId: 'MMdfiqXUIPg',
									startTime: '107',
									endTime: '145',
								}}
							/>
						</div>
					</div>
				</div>
				{children}
			</div>
			{/* realtime app preview with tabs */}
			{/* <div className="relative flex items-center bg-gray-200 p-3 rounded-lg">
                <h1 className="text-4xl font-black text-gray-400">
                    Status info-graphic
                </h1>
            </div> */}
			{/* end of realtime app preview with tabs */}
		</CartBlock>
	);
};

export default ContentBlock;
