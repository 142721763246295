import create from 'zustand';
import produce from 'immer';

const useProjectGraphQl = create((set) => ({
	data: {},
	setProject: (data) => {
		return set(
			produce((draftState) => {
				draftState.data = {
					...data,
				};
			})
		);
	},
}));

const projectGraphqlApi = () => {
	return useProjectGraphQl.getState();
};
export { projectGraphqlApi };
export default useProjectGraphQl;
