import { useState } from 'react';
import { GraphiQL } from 'graphiql';
import 'graphiql/graphiql.css';
import axios from 'axios';


const GraphqlExplorer = () => {
    const [endPoint, setEndPoint] = useState(
		'https://countries.trevorblades.com/'
	);
    const [newValue,setNewValue] = useState('https://countries.trevorblades.com/')
    const fetcher = async (graphQLParams, header) => {
		try {
			const response = await axios.post(
				endPoint,
				JSON.stringify(graphQLParams),
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						...header?.headers,
					},
				}
			);
			return response.data;
		} catch (error) {}
		return null;
	};

    
	return (
		<>
        <div className='m-4 flex space-x-4'>
            <input className='border border-gray-300 rounded-md px-2 py-1' value={newValue} placeholder="enter graphql url" onChange={(event)=>{
                setNewValue(event.target.value)
            }}/>
            <button className='px-2 py-1 bg-blue-700 text-white rounded-md border border-blue-600' onClick={()=>{
                setEndPoint(newValue);
            }}>Update</button>
        </div>
        <div className="h-full">
			<GraphiQL fetcher={fetcher} />
		</div>
        </>
	);
};

export default GraphqlExplorer;
