import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import {
	createStore,
	Provider,
} from '@appmaker/core/components/AppmakerForm/store';
const appmakerSchema = {
	properties: {
		title: {
			label: 'Notification title',
			uiType: 'Heading',
		},
		iosStatus: {
			label: 'Notification title',
			uiType: 'StatusStep',
			helpText: '(Steps to follow)',
			helpeLink: '',
			content: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam,ipsa!'
			// status:''
		},

	},
};
const PublishApp = () => {
	return (
		<div className="bg-gray-50 h-full p-6">
			<Provider
				createStore={createStore({
					formData: {},
				})}
			>
				<AppmakerForm formVersion={2}
					// value={{ formData: flatten(formData) }}
					schema={appmakerSchema}
				/>
			</Provider>

		</div>
	);
};

export default PublishApp;
