import FormBlockCard from './components/FormBlockCard';
import RadioItem from './formItems/RadioItem';
import TextInput from './formItems/TextInput';
import CheckBox from './formItems/CheckBox';
import FormSwitch from './formItems/FormSwitch';
import { ProductChooser, CollectionChooser } from './formItems/ProductChooser';
import SelectableInput from './formItems/SelectableInput';

const FormBlocks = () => {
	return (
		<div className="grid grid-cols-2 gap-4 m-4">
			<FormBlockCard
				attributes={{
					title: 'Form Block Card',
					optionComponent: (
						<div className="flex flex-col space-y-2">
							<ProductChooser />
							<CollectionChooser />
						</div>
					),
					errorMessage: 'This a error message',
				}}
			>
				<TextInput
					attributes={{
						label: 'Email',
						placeholder: 'Enter your email',
						required: true,
						type: 'email',
						name: 'email',
						value: '',
						// error: {
						// 	message: 'Please enter',
						// },
						// info: {
						// 	message: 'Please enter something',
						// },
					}}
				/>
				<TextInput
					attributes={{
						label: 'Password',
						placeholder: 'Enter your password',
						required: true,
						type: 'password',
						name: 'password',
						value: '',
						error: {
							message: 'Enter a valid password',
						},
						// info: {
						// 	message: 'Please enter something',
						// },
					}}
				/>
				<SelectableInput />
			</FormBlockCard>
			<FormBlockCard
				attributes={{
					title: 'Form Block Card',
				}}
			>
				<RadioItem
					attributes={{
						item: {
							id: '1',
							name: 'radio',
							label: 'Radio Label 1',
							description: 'Radio Description',
						},
					}}
				/>
				<RadioItem
					attributes={{
						item: {
							id: '2',
							name: 'radio',
							label: 'Radio Label 2',
							description: 'Radio Description',
						},
					}}
				/>
				<CheckBox
					attributes={{
						id: 'checkbox',
						name: 'checkbox',
						label: 'Checkbox Label',
						description: 'Checkbox Description',
					}}
				/>
				<FormSwitch
					attributes={{
						id: 'switch',
						name: 'switch',
						label: 'Switch Label',
						description: 'Switch Description',
					}}
				/>
			</FormBlockCard>
		</div>
	);
};

export default FormBlocks;
