import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = 	[{
    "clientId": "a45d7a62-5625-4ac8-89cd-3eb12d1696c0",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Banner-1.png?v=1679464160",
            "id": "gid://shopify/MediaImage/32733348954369",
            "title": "Banner-1.png"
        },
        "imageContainerStyle": {
            "height": 1800,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 1800,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "8ad826bf-dec3-4354-8817-0bae381f85a9",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Title_Banner.png?v=1679464197",
            "id": "gid://shopify/MediaImage/32733352919297",
            "title": "Title Banner.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "ff6e47ae-d708-4a11-8078-9eae70fbc60e",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 5,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "enableSplit": true,
        "enableGap": true
    },
    "innerBlocks": [{
        "clientId": "1cf03122-53e3-4ed9-b3c3-b14290ce18d6",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider_button_-_1_53041dcd-ffd8-49a4-b353-fe4a4cc15e8f.png?v=1679464288",
                "id": "gid://shopify/MediaImage/32733359833345",
                "title": "Slider button - 1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 111,
                "width": 225
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "5eefbc00-70b1-44d7-8fca-89964e8ba350",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider_button_-_2_3670f38a-2775-409a-a568-e411f628e644.png?v=1679464288",
                "id": "gid://shopify/MediaImage/32733359767809",
                "title": "Slider button - 2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 111,
                "width": 225
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "2c9ac19b-f778-4175-9d5a-b10b7124a5f2",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider_button_-_3_c96eec1b-3393-41a0-b7b6-acaf0f7be9ac.png?v=1679464288",
                "id": "gid://shopify/MediaImage/32733359669505",
                "title": "Slider button - 3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 111,
                "width": 225
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "c1df0568-cb00-494c-abac-171385f6d710",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider_button_-_4_8e5e7ca2-9f6d-490e-9c69-667323811e4a.png?v=1679464288",
                "id": "gid://shopify/MediaImage/32733359702273",
                "title": "Slider button - 4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 111,
                "width": 225
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "e030d054-6bce-48a7-9e42-df19741d3861",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider_button_-_5_b5534fc2-2a41-4a93-b066-1eecd6be0f56.png?v=1679464288",
                "id": "gid://shopify/MediaImage/32733359735041",
                "title": "Slider button - 5.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 111,
                "width": 225
            },
            "showText": false
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "f0ef796d-3343-4e1c-bf52-e1776c1851b5",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 2,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "enableSplit": false,
        "enableGap": false
    },
    "innerBlocks": [{
        "clientId": "22d47927-119d-4fad-9652-4c6bc565ef00",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Product_Grid_-_1.png?v=1679464330",
                "id": "gid://shopify/MediaImage/32733363831041",
                "title": "Product Grid - 1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 843,
                "width": 563
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "d4ccf308-99cd-4e26-8c92-f8dbba3bb4ec",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Product_Grid_-_2.png?v=1679464330",
                "id": "gid://shopify/MediaImage/32733363863809",
                "title": "Product Grid - 2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 843,
                "width": 563
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "edcb70f6-5bee-4af0-89d0-da7dd1cc6dad",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Product_Grid_-_3.png?v=1679464330",
                "id": "gid://shopify/MediaImage/32733363798273",
                "title": "Product Grid - 3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 843,
                "width": 563
            },
            "showText": false
        },
        "innerBlocks": []
    }, {
        "clientId": "d3facc48-85e1-468a-9fbb-857781e5957f",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Product_Grid_-_4.png?v=1679464330",
                "id": "gid://shopify/MediaImage/32733363896577",
                "title": "Product Grid - 4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 843,
                "width": 563
            },
            "showText": false
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "e4d05c95-a09f-4e5f-8e3e-197391659f18",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Button_-_banner.png?v=1679464188",
            "id": "gid://shopify/MediaImage/32733352100097",
            "title": "Button - banner.png"
        },
        "imageContainerStyle": {
            "height": 144,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 144,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "146f488b-7426-4ec9-8e3d-5999cb4054d0",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Brand_-_banner.png?v=1679464177",
            "id": "gid://shopify/MediaImage/32733350101249",
            "title": "Brand - banner.png"
        },
        "imageContainerStyle": {
            "height": 540,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 540,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "23890257-673e-48f1-a62c-5914d525c612",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Title_-_Banner-1.png?v=1679464191",
            "id": "gid://shopify/MediaImage/32733352296705",
            "title": "Title - Banner-1.png"
        },
        "imageContainerStyle": {
            "height": 186,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 186,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "3978e482-09bc-4c78-ad63-45eecf2c9163",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/banner-2.png?v=1679464161",
            "id": "gid://shopify/MediaImage/32733349052673",
            "title": "banner-2.png"
        },
        "imageContainerStyle": {
            "height": 720,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 720,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "0847f466-76f3-48c9-af0f-f92e83f87d4c",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/banner-3.png?v=1679464163",
            "id": "gid://shopify/MediaImage/32733349183745",
            "title": "banner-3.png"
        },
        "imageContainerStyle": {
            "height": 720,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 720,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "22046045-301a-44a0-bad5-1bb1968d667f",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Titile_-_banner-2.png?v=1679464189",
            "id": "gid://shopify/MediaImage/32733352198401",
            "title": "Titile - banner-2.png"
        },
        "imageContainerStyle": {
            "height": 222,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 222,
            "width": 1125
        }
    },
    "innerBlocks": []
}, {
    "clientId": "bc1e6c3e-bafd-4b30-8f1f-9aafcdcd8daf",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "customBlockHeight": 440
    },
    "innerBlocks": [{
        "clientId": "0c43c504-0ab6-453f-bdec-3aab3cdad3bf",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider-1.png?v=1679464441",
                "id": "gid://shopify/MediaImage/32733371957505",
                "title": "Slider-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 1197,
                "width": 756
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "53298c42-fc16-49e5-93d1-e7ede1c2bbba",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider-2.png?v=1679464441",
                "id": "gid://shopify/MediaImage/32733371924737",
                "title": "Slider-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 1197,
                "width": 756
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "ad65984b-25ff-40d1-8a4e-e03822732434",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider-3.png?v=1679464441",
                "id": "gid://shopify/MediaImage/32733371891969",
                "title": "Slider-3.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 1197,
                "width": 756
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "692faceb-eafc-452d-9f24-8a820790f81e",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Slider-4.png?v=1679464441",
                "id": "gid://shopify/MediaImage/32733371859201",
                "title": "Slider-4.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 1197,
                "width": 765
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "6604d490-ba99-4581-b9fa-16e78b03a6a5",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Banner-4.png?v=1679464170",
            "id": "gid://shopify/MediaImage/32733349773569",
            "title": "Banner-4.png"
        },
        "imageContainerStyle": {
            "height": 446,
            "width": 1125
        },
        "thumbnail_meta": {
            "height": 446,
            "width": 1125
        }
    },
    "innerBlocks": []
}]
const template = {
	blocks,
	id: 'classy-store',
	name: ' Classy Store ',
	category: 'Fashion',
	price: 'Free',
	imageSrc: '/images/themes/classy-store.png',
	previewSrc: '/images/preview/templates/classy-store.png',
	imageAlt: 'Mobile app Fashion theme',
	description: "Are you looking for a sleek, modern look for your fashion store mobile app? Our template has got you covered. With a minimal design that emphasizes your products, you'll be sure to impress your customers",
    figmaSrc: "https://www.figma.com/community/file/1220272343814314436/Classy-Store-Design-Work-File"
};
export default template;
