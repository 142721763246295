import { entity } from 'simpler-state';

const extensionList = entity({
	originalList: [],
	list: [],
	setValue: false,
	showUnsavedChanges: false,
});

const useExtensionList = (args) => extensionList.use(args);
const setExtensionList = (data) =>
	extensionList.set((value) => ({ ...value, ...data }));

const searchExtension = (searchKey) => {
	const currentValues = extensionList.get();
	if (searchKey && searchKey.length === 0) {
		extensionList.set((value) => ({
			...value,
			list: currentValues.originalList,
		}));
	} else {
		const mappedValue = currentValues.originalList.filter(
			(item) =>
				item.name && item.name.toLowerCase().includes(searchKey.toLowerCase())
		);
		extensionList.set((value) => ({ ...value, list: mappedValue }));
	}
};

const showUnSavedChanges = (value) => {
	extensionList.set((original) => ({ ...original, showUnsavedChanges: value }));
};

export { useExtensionList, setExtensionList, searchExtension, showUnSavedChanges };
