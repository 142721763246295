import { getProjectId } from '@appmaker/core/store/project';
import axios from 'axios';
import { DASHBOARD_GRAPHQL_URL } from '@appmaker/core/api/core';

const getTemplateVersion = async ({ templateId, tag, limit, offset } = {}) => {
	const projectId = getProjectId();
	const query = `
        query ($templateId: Int, $tag: String, $limit: Int, $offset: Int) {
            templatePublishVersions(
              templateId: $templateId
              tag: $tag
              limit: $limit
              offset: $offset
            ) {
              id
              templateId
              branch
              tag
              meta
              createdAt
            }
          }
        `;

	const response = await axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				templateId,
				tag,
				limit,
				offset,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
	return response?.data?.data;
};

export { getTemplateVersion };
