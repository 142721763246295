import { useReducer, useEffect } from 'react';
import { getCustomBlockFromPartners } from '../api'

const initialState = {
	loading: true,
	error: '',
	blocks: [],
};
const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_START':
			return initialState;
		case 'FETCH_SUCCESS':
			return {
				loading: false,
				blocks: action.payload,
				error: '',
			};
		case 'FETCH_ERROR':
			return {
				loading: false,
				blocks: [],
				error: action.error,
			};
		default:
			return state;
	}
};

function useCustomBlocks({ projectId }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	useEffect(() => {
		(async () => {
			dispatch({
				type: 'FETCH_START',
			});
			const response = await getCustomBlockFromPartners({projectId});
			dispatch({
				type: 'FETCH_SUCCESS',
				payload: response?.data?.customBlocksFromPartners ?? [],
			});
		})();
	}, []);
	return state;
}

export default useCustomBlocks;
