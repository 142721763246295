// import { addFilter, applyFilters } from '@wordpress/hooks';
import appmaker from '@appmaker/core/index';
import actions from './actions';
import createApp from "@shopify/app-bridge/development";
import TemplateBuilder from './components/TemplateBuilder/index';
import ShopifyMediaLibrary from './components/AppmakerMediaLibrary/shopify';
import qs from 'qs';
import { uploadToServer } from './helper/upload';
import { registerBlockTypes } from './blocks'
import { getProjectData } from '@appmaker/core/store/project';
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { Fullscreen } from '@shopify/app-bridge/actions';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import AdditionalPermission from './components/AdditionalPermiossion'
import SideBarTop from './components/SideBarTop';
import InitialFormModal from '@appmaker/components/templates/getting-started-forms';
import MenuImport from './components/MenuImport';

const queryParams = qs.parse(window.location.search);

if (queryParams.host && window.self !== window.top && !window.shopifyApp) {
	const shopifyConfig = {
		apiKey: 'd16097e6c4d94b41c3dde379ce2490fb',
		host: queryParams.host,
	};
	if (process.env.NODE_ENV === 'development') {
		shopifyConfig['apiKey'] = '45b133425d617958c21b795c3ed903fd';
	}
	console.log("Creating shopify bridge", shopifyConfig)
	window.shopifyApp = createApp(shopifyConfig);
	window.shopifyHost = queryParams.host;
	getSessionToken(window.shopifyApp) // requires an App Bridge instance
		.then(token => {
			console.log("Got shopify token")
			window.shopifySessionToken = token;
		}).catch(err => {
			console.log("Unable to get shopify token", err)
		});
}

const ShopifyPlugin = {
	id: '@appmaker/shopify',
	name: 'Shopify',
	activate: () => {

		if (window.shopifyApp) {
			window.fullscreen = Fullscreen.create(window.shopifyApp);
		}

		appmaker.registerRoute({
			routeName: '/additional-permission-required',
			component: AdditionalPermission,
		});

		const { projectData: { shopifyReauthRequired = false, meta: { URL = '' }, id } } = getProjectData();
		let doReauth = (shopifyReauthRequired && !checkIsAdmin()) || (shopifyReauthRequired && window.confirm("You need to re-authenticate with shopify for additional scopes."));
		appmaker.addFilter(
			'additional-override-component-full-screen',
			'appmaker-core',
			(content, args) => {
				if (doReauth) {
					return <AdditionalPermission />;
				} else {
					return null
				}
			}
		);
		// if (!window.location.href.match('additional-permission-required') && doReauth) {
		// 	// redirect to react route additional-permission-required
		// 	const redirectUrl = `/apps/${id}/additional-permission-required`;
		// 	if (window.shopifyApp) {
		// 		console.log("Redirecting to inside shopify", redirectUrl)
		// 		const redirect = Redirect.create(window.shopifyApp);
		// 		redirect.dispatch(
		// 			Redirect.Action.APP,
		// 			redirectUrl
		// 		);
		// 	} else {
		// 		console.log("Redirecting to ", redirectUrl)
		// 		window.location.href = redirectUrl;
		// 	}
		// }

		Object.keys(actions).map((action) =>
			appmaker.actions.registerAction(action, actions[action])
		);
		appmaker.addFilter('block-editor-header-menu', 'shopify-templates', (item) => <>{item}</>, 1)
		appmaker.addFilter('getting-started-form', 'shopify-templates', () => <InitialFormModal />)
		appmaker.addFilter('template-chooser', 'shopify-templates', () => <TemplateBuilder />)
		appmaker.addFilter('appmaker-media-library-factory', 'shopify-media', (items) => {
			// items.push(AppmakerMediaLibrary)
			items.push(ShopifyMediaLibrary)
			return items
		}, 2)
		appmaker.addFilter(
			'blockEditorMediaUpload',
			'apppmakerUpload',
			() => uploadToServer
		);
		registerBlockTypes();
		appmaker.addFilter('onboarding-path', 'woocommerce-onboarding-path', () => '/blockEditor/home?showTemplate=shopify')
		appmaker.addFilter('appdesign-next', 'woocommerce-appdesign-next-path', () => [`/blockEditor/home?showAppDownload=true`,])

		if (window.shopifyApp) {
			appmaker.addFilter('appmaker-dashboard-sidebar-top', 'shopify-dashboard-sidebar-top', () => {
				return (<SideBarTop fullscreen={window.fullscreen} />)
			});
		}

		appmaker.addAction('dashboard-block-editor-load', 'shopify-dashboard-block-editor-load', () => {
			if (window.fullscreen) {
				window.fullscreen.dispatch(Fullscreen.Action.ENTER);
			}
		});
		/**
		 * SHOPIFY menu import from website
		 */
		appmaker.addFilter('shopify-menu-import', 'shopify-template', (item, args = {}) => <MenuImport {...args} />)

	},
};

export default ShopifyPlugin;
