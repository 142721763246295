import { useState, useEffect } from 'react';
import { getProjectId } from '@appmaker/core/store/project';
import CRUD from '../components/CRUD';
import Notification, { sendNotification } from '@appmaker/components/ReactHotToastIntegrator';
import api from '../api/project-meta';
import Loader from '@appmaker/components/Loading';

const updateForm = {
    "properties": {
        heading: {
            name: "string",
            label: 'Project meta',
            type: "Heading",
        },
        template_tag: {
            type: "string",
            label: 'App tag',
            description: "App build tag",
            required: true
        }
    }
}

const Update = ({
    form,
    onClickSave,
    isSaving,
    formData
}) => {
    console.log({ formData }, 'xxx')
    if (!formData || Object.keys(formData).length === 0) {
        return null;
    }
    return (
        <CRUD
            schema={form}
            type="update"
            properties={
                {
                    onSave: onClickSave,
                    buttonLoading: isSaving,
                    buttonLabel: 'Update project meta',
                    formData: formData
                }
            }
        />
    )
}


const TagUpdate = () => {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const projectId = getProjectId();
    const loadData = async () => {
        setLoading(true);
        try {
            const response = await api.getMeta(projectId);
            setData(response);
        } catch (error) {
            sendNotification('Api call failed', 'error')
        }
        setLoading(false);
    }
    const saveData = async(formData)=>{
        setLoading(true);
        try {
            await api.updateMeta(projectId,formData);
            sendNotification('Updated','success')
        } catch (error) {
            sendNotification('Update failed', 'error')
        }
        setLoading(false)
    }
    useEffect(() => {
        loadData()
    }, [])
    if (isLoading) {
        return <Loader />
    }
    const onClickSave = (data) => {
        setData(data);
        saveData(data);
    }

    return <div>
        <Notification>
            <Update
                form={updateForm}
                onClickSave={(data) => { onClickSave(data) }}
                isSaving={isLoading}
                formData={data}
            />
        </Notification>
    </div>
}
export default TagUpdate;