import { __ } from '@wordpress/i18n';
import metadata from './block.json';
import Edit from './edit';
import './menuItem';
import MenuIcon from './Icon';
import { InnerBlocks } from '@wordpress/block-editor';

/**
 * Register: aa Gutenberg Block.
 *
 * Registers a new block provided a unique name and an object defining its
 * behavior. Once registered, the block is made editor as an option to any
 * editor interface where blocks are implemented.
 *
 * @link https://wordpress.org/gutenberg/handbook/block-api/
 * @param  {string}   name     Block name.
 * @param  {Object}   settings Block settings.
 * @return {?WPBlock}          The block, if it has been successfully
 *                             registered; otherwise `undefined`.
 */
const { attributes, name, category, metaData } = metadata;
export default {
  name,
  settings: {
    // Block name. Block names must be string that contains a namespace prefix. Example: my-plugin/my-custom-block.
    title: __('Menu'), // Block title.
    icon: MenuIcon, // Block icon from Dashicons → https://developer.wordpress.org/resource/dashicons/.
    category, // Block category — Group blocks together based on common traits E.g. common, formatting, layout widgets, embed.
    keywords: [__('appmaker'), __('menu')],
    supports: {
      customClassName: false,
      html: false
    },
    attributes,
    /**
     * The edit function describes the structure of your block in the context of the editor.
     * This represents what the editor will render when the block is used.
     *
     * The "edit" property must be a valid function.
     *
     * @link https://wordpress.org/gutenberg/handbook/block-api/block-edit-save/
     *
     * @param {Object} props Props.
     * @returns {Mixed} JSX Component.
     */
    edit: (props)=><Edit {...props} metaData={metaData}/>,

    /**
     * The save function defines the way in which the different attributes should be combined
     * into the final markup, which is then serialized by Gutenberg into post_content.
     *
     * The "save" property must be specified and must be a valid function.
     *
     * @link https://wordpress.org/gutenberg/handbook/block-api/block-edit-save/
     *
     * @param {Object} props Props.
     * @returns {Mixed} JSX Frontend HTML.
     */
    save: props => {
      return <InnerBlocks.Content />;
    }
  }
};
