import React from 'react';
import clsx from 'clsx';

const Title = ({ properties = {} }) => {
	const { title, label, description, size } = properties;
	return (
		<div
			className={clsx('flex flex-col items-start w-full mt-2', {
				'pb-1 border-b border-gray-200': size === 'h1' || size === 'h2',
			})}
		>
			<h3
				className={clsx('font-medium', {
					'text-lg': size === 'h1',
					'text-base': size === 'h2',
					'text-sm': size === 'h3',
				})}
			>
				{title || label}
			</h3>
			<p className="text-gray-500 text-xs">{description}</p>
		</div>
	);
};

export default Title;
