const imageFragment = `
fragment Image on MediaImage {
    id
    alt
    image {
        url
    }
}
`;

// Simplifies handling of image references and other field values
const handleFieldReference = (field) => {
	if (field.reference?.__typename === 'MediaImage') {
		return `reference { ...Image }`;
	} else if (field.references?.nodes[0]?.__typename === 'MediaImage') {
		return `
            references(first: 100) {
                nodes {
                    ...Image
                }
            }
        `;
	}
	return `value`;
};

// Generates GraphQL fields for metaobjects
const generateFields = (fields) =>
	fields
		?.map(
			(field) => `
    ${field.key}: field(key: "${field.key}") {
        ${handleFieldReference(field)}
    }
`
		)
		.join('');

// Handles references within metafields including inline fragments
const handleReferences = (metafield) => {
	if (metafield.references) {
		return `
            references(first: 100) {
                nodes {
                    __typename
                    ... on Metaobject {
                        ${generateFields(metafield.references.nodes[0].fields)}
                    }
                }
            }
        `;
	} else if (metafield.reference) {
		return `
            reference {
                __typename
                ... on Metaobject {
                    ${generateFields(metafield.reference.fields)}
                }
            }
        `;
	}
	return 'value';
};

// Builds the complete GraphQL query dynamically
const gqlQuery = (type, data = {}) => {
	const queryHeader = `
        ${imageFragment}
        query GetProductDetails($handle: String) {
            ${type}(handle: $handle) {
    `;

	const queryBody = data?.metafields
		.map(
			(metafield) => `
        ${metafield.namespace}_${metafield.key}: metafield(key: "${
				metafield.key
			}", namespace: "${metafield.namespace}") {
            __typename
            ${handleReferences(metafield)}
        }
    `
		)
		.join('');

	const queryFooter = `
    }
}
`;

	return queryHeader + queryBody + queryFooter;
};

const generateQuery = (type, data) => {
	const query = gqlQuery(type, data);
	return query;
};

export { generateQuery };
