import React from 'react';
import { registerBlockType } from '@wordpress/blocks';
import { useBlockProps } from '@wordpress/block-editor';
import Edit from './Edit';
const appmakerBlocks = {};
const registerAppmakerBlockType = (
	type,
	{
		title,
		attributes: _attributes,
		Block,
		icon,
		category = 'design',
		parent,
		View,
		customProps = {},
	}
) => {
	appmakerBlocks[type] = { title, Edit: Block, View };
	registerBlockType(type, {
		apiVersion: 2,
		title: title,
		icon: icon ? (
			icon
		) : (
			<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path fill="none" d="M0 0h24v24H0V0z" />
				<path d="M19 13H5v-2h14v2z" />
			</svg>
		),
		category,
		parent,
		attributes: {
			mapValues: {
				type: 'object',
			},
			..._attributes,
		},
		edit: (props) => {
			return (
				<Edit {...props} Block={Block} type={type} customProps={customProps} />
			);
		},
		save: () => {
			const blockProps = useBlockProps.save();
			return <div {...blockProps}></div>;
		},
	});
};
export function registerAppmakerFrontEndBlock(type, { title, Block }) {
	appmakerBlocks[type] = { title, View: Block };
}
export { registerAppmakerBlockType, appmakerBlocks };
