import { addFilter, addAction } from '@wordpress/hooks';
import MediaUpload from '../components/MediaUpload';

/**
 * Temp fix
 */
window.__editorAssets = { styles: [], scripts: [] }
window.wp = {}
const replaceMediaUpload = () => MediaUpload

addFilter(
    'editor.MediaUpload',
    'core/edit-post/components/media-upload/replace-media-upload',
    replaceMediaUpload
);
