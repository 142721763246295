import { useState } from "react";
import { useProject } from "@appmaker/core/hooks";
import { getProjectData } from '@appmaker/core/store/project';
import Loader from '@appmaker/components/Loading';
import Notification, {
    sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';
import api from '../api';

const RegenerateIosCert = () => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    const { projectData = {}, loading } = getProjectData({ projectId });
    const { apps = [] } = projectData;
    const iosApp = apps.find(item => item && item.platformId && parseInt(item.platformId) === 3)
    const regenerate = async () => {

        const confirmed = window.confirm('Are you sure?');
        if (!confirmed) {
            return false
        }
        const appId = iosApp.id;
        setLoading(true);
        try {
            await api.regenerateIosCert({
                projectId,
                appId
            })
            sendNotification('IOS cert regenerate triggered !!', 'success')
        } catch (error) {
            sendNotification('Something went wrong !!', 'error')
        }
        setLoading(false);
    }
    if (loading || !iosApp) {
        return (
            <Loader />
        )
    }
    return (
        <Notification>
            <div className="m-4 mt-10 flex flex-rows align-center gap-4 ">
                <div className="flex flex-col items-center">
                    <label className="text-gray-500 text-xs font-medium uppercase tracking-wide">Regenerate IOS certificate</label>
                    <button
                        className="mt-5 justify-center inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        disabled={isLoading}
                        onClick={() => {
                            regenerate()
                        }}>
                        {isLoading ? 'Loading.....' : 'Regenerate'}
                    </button>
                </div>
            </div>
        </Notification>
    )
}

export default RegenerateIosCert