import React, { useState } from 'react'
import { setCurrentAsDefaultLanguage, deleteLanguage, useLanguageFormData } from './store/formData';
import { useRouteMatch } from '@appmaker/core/routes';
import { setLanguageToDefault, saveLanguage,buildProject } from './helper';

export default function Content({ children, onSave = () => { } }) {
    let { params } = useRouteMatch();
    const { projectId } = params;
    const currentLanguage = useLanguageFormData(data => data.currentLanguage)
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isBuilding,setBuilding] = useState(false);

    const resetToDefault = async () => {
        await setLanguageToDefault(projectId, currentLanguage, setLoading);
    }
    const onSaveLanguage = async () => {
        await saveLanguage(onSave, setSaving);
    }
    const onBuild = async()=>{
        setBuilding(true);
        await saveLanguage(onSave,setSaving);
        await buildProject(projectId,setBuilding);
    }
    return (
        <div className="flex-1 rounded-r-lg bg-white border border-gray-300 flex flex-col h-full">
            <div className="p-3 bg-gray-50 flex items-center justify-between">
                <h3 className="text-xl font-medium">Edit English Translations</h3>
                <div className="flex items-center gap-2">
                    <button
                        disabled={isSaving}
                        type="button"
                        onClick={onSaveLanguage}
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </button>
                    <button
                        type="button"
                        onClick={setCurrentAsDefaultLanguage}
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Make default
                    </button>
                    <button
                        type="button"
                        onClick={resetToDefault}
                        disabled={isLoading}
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        {isLoading ? 'Loading ...' : 'Reset to default'}
                    </button>
                    <button
                        type="button"
                        onClick={deleteLanguage}
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-600 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        Delete this language
                    </button>
                </div>
            </div>
            <div className="flex-1 relative overflow-y-auto overflow-x-hidden">
                {children}
            </div>
            <div className="p-3 bg-gray-50 flex">
                <div className="flex items-center gap-2">
                    {/* <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button> */}
                    <button
                        disabled={isSaving}
                        type="button"
                        onClick={onSaveLanguage}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded text-white bg-blue-600 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </button>
                    <button
                        disabled={isBuilding || isSaving}
                        type="button"
                        onClick={onBuild}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded text-white bg-blue-600 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        {isBuilding ? 'Building...' : 'Build App'}
                    </button>
                </div>
            </div>
        </div>
    )
}
