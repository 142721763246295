import React, { useState } from 'react';
import Select from 'react-select'
import { useBlockProps } from '@wordpress/block-editor';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import appmaker from '@appmaker/core/index';
import {
  Placeholder,
  Tip,
  ToolbarGroup
} from '@wordpress/components';
import {
  BlockControls,
  InnerBlocks,
  InspectorControls,
} from '@wordpress/block-editor';
import { PencilIcon } from '@heroicons/react/solid'
const options = [
  { label: 'Sale Products', value: 'sale' },
  { label: 'Featured Products', value: 'featured' },
  { label: 'Recent Products', value: 'recent' },
  { label: 'Best Selling Products', value: 'best_selling' },
  { label: 'Top Rated Products', value: 'top_rated' },
  {
    label: 'Open Products in a category',
    value: 'category',
    link: 'product_category',
    hasChild: true
  },
  {
    label: 'Open Products By Tag',
    value: 'tag',
    hasChild: true,
    link: 'product_tag'
  }
];
function ActionSelector(
  { action, setAction }
) {
  const actions = appmaker.actions.getAllActions();
  const schema = actions
  return (
    <div>

      {/* <StateView json={action} /> */}
      <AppmakerForm
        options={actions}
        value={{ ...action, schema }}
        optionKey={'action'}
        setValue={setAction}
        debug={false}
      />
    </div>
  )
}
export default function ProductScroller({ attributes, setAttributes }) {
  const { config, ___internal } = attributes
  const [isEditing, setIsEditing] = useState(false)
  const getBlockControls = () => {
    return (
      <BlockControls>
        <ToolbarGroup
          controls={[
            {
              icon: <PencilIcon />,
              title: 'Edit',
              onClick: () => setIsEditing(!isEditing),
              isActive: isEditing,
            },
          ]}
        />
      </BlockControls>
    );
  };
  return (
    <div  {...useBlockProps()}>
      {getBlockControls()}
      <div className="p-6">
        {isEditing && <Placeholder label={'Hello world'}>
          'Display all products from your store as a grid.',
          'woo-gutenberg-products-block'
          <ActionSelector
            action={{ formData: config, _internal: ___internal }}
            setAction={({ formData, _internal }) => {
              setAttributes({ config: formData, ___internal: _internal });
            }}
          />
        </Placeholder>}

      </div>
    </div>
  )
}
