import moment from 'moment';
import ListItem from '../components/ListItem';
import RouteTitle from '../components/RouteTitle';
import Loader from '@appmaker/components/Loading';
import { useQuery } from '@tanstack/react-query';
import { getPendingRequest, getActiveRequest } from '../api';

const data = [
	{
		createdAt: '2023-07-07T09:21:04.108Z',
		id: 'cljsd9zw60004ccqnvqrw8m9v',
		partnerOrganization: {
			name: 'Test Org',
			id: 'clf9dzbgg0002440jz63d3x1g',
			handle: 'test-org',
		},
		project: {
			id: 'cljsc5c890002ccqnyj5q1bz7',
			name: 'newabhistore',
			remoteProjectId: '155956',
		},
	},
	{
		createdAt: '2023-07-07T09:21:04.108Z',
		id: 'cljsd9zw60004ccqnvqrw8m9v',
		partnerOrganization: {
			name: 'Test Org',
			id: 'clf9dzbgg0002440jz63d3x1g',
			handle: 'test-org',
		},
		project: {
			id: 'cljsc5c890002ccqnyj5q1bz7',
			name: 'newabhistore',
			remoteProjectId: '155956',
		},
	},
];

function Listing({ type, title, data, approved = false, isLoading = false }) {
	return (
		<div className="mx-auto max-w-4xl">
			<div className="border-b border-gray-200 pb-3">
				<h3 className="text-base font-semibold leading-6 text-gray-900">
					{title}
				</h3>
			</div>
			{isLoading && (
				<div className="divide-y divide-gray-100">
					<Loader single />
				</div>
			)}
			{!isLoading && (
				<div className="divide-y divide-gray-100">
					{data &&
						data.map((item) => (
							<ListItem
								key={item.id}
								name={item.partnerOrganization.name}
								date={moment(item?.createdAt).fromNow()}
								approved={approved}
								data={{
									...item,
									type,
								}}
							/>
						))}
				</div>
			)}
		</div>
	);
}

const Users = () => {
	const pendingRequestQuery = useQuery({
		queryKey: ['user', 'pending-request-partners'],
		queryFn: () => getPendingRequest(),
		// staleTime: 60 * 1000
	});
	const activeRequestQuery = useQuery({
		queryKey: ['user', 'active-request-partners'],
		queryFn: () => getActiveRequest(),
		// staleTime: 60 * 1000
	});
	return (
		<main>
			<RouteTitle title="User Roles" />
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 space-y-16">
				<Listing
					title="Pending Requests"
					type="pending"
					data={
						pendingRequestQuery?.data?.data?.data?.partnerProjectRequestAccess
					}
					isLoading={pendingRequestQuery?.isLoading || pendingRequestQuery?.isFetching}
				/>
				<Listing
					type="active"
					title="Partners"
					data={activeRequestQuery?.data?.data?.data?.partnerProjectAccess}
					approved={true}
					isLoading={activeRequestQuery?.isLoading || activeRequestQuery?.isFetching}
				/>
			</div>
		</main>
	);
};

export default Users;
