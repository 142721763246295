import VideoModal from './VideoModal';

export default function CardBlock({ children }) {
	return (
		<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-50 h-screen">
			<div className="py-6">
				<div className="mx-auto px-4 sm:px-6 md:px-8">
					<div className="p-6 shadow-lg rounded-lg bg-white">
						<div className="flex gap-8">{children}</div>
					</div>
				</div>
			</div>
		</main>
	);
}
export const CardTitle = ({ title, description, video = {} }) => (
	<>
		<div className="flex items-center space-x-2">
			<h2 className="text-xl font-semibold text-gray-900">{title}</h2>
			{video && video.videoId ? (
				<VideoModal
					attributes={{
						label: video.videoLabel,
						videoId: video.videoId,
						startTime: video.startTime,
						endTime: video.endTime,
					}}
				/>
			) : null}
		</div>
		<p className="text-gray-500 text-sm font-normal mt-1">{description}</p>
	</>
);
