// counter.js
import api from '../api/core';
import { entity } from 'simpler-state';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import registerCustomAction from '../plugins/core/actions/registerCustomAction';

const project = entity({
	loading: true,
	projectId: null,
	projectData: {},
	publicMeta: {},
	plugins: [],
	packageNames: {}
});
export const getProjectId = () => project.get().projectId;
export const getProjectData = () => project.get();
export const setPublicMeta = async (meta) => {
	// project.set(value => ({ ...value, projectId: _projectId }))
	await api.setPublicMeata(getProjectId(), meta);
};
export const getPublicMeta = () => project.get().publicMeta;
export const useProject = () => project.use();
export const usePlugins = () => project.use((store) => store.plugins);
export const getPlugin = (pluginId) => {
	const plugins = project.get().plugins || [];
	return plugins.find((plugin) => plugin.id === pluginId);
};
export const setProjectId = (_projectId) =>
	project.set((value) => ({ ...value, projectId: _projectId }));
export const setProject = (projectData) => {
	const {
		plugins = [], packageNames = {}, actions = {} } = projectData || {}
	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		// plugins.push()
		plugins.push({
			id: '@appmaker/publishapp',
		})

	}

	// Custom actions
	// const customActions = {
	// 	"TEST_ACTION": {
	// 		"label": "Test action label",
	// 		"properties": {
	// 			"dependent2": {
	// 				"type": "custom",
	// 				"label": "Dependent field 2",
	// 				"tag": "pages"
	// 			},
	// 			"dependent3":{
	// 				"type": "custom",
	// 				"label": "Dependent field 3",
	// 				"tag": "collections"
	// 			}
	// 		}
	// 	}
	// }
	registerCustomAction(actions);
	project.set((value) => ({
		...value,
		projectData,
		loading: false,
		plugins,
		packageNames,
		publicMeta: projectData.publicMeta || {}
	}));
	// project.set(({ projectData, loading: false }))
};

export const getProjectTemplate = () => {
	const { projectData } = project.get();
	return projectData.template;
}

export const getPackageName = () => {
	return project.get().packageNames
}

export const addNewPlugin = (newPlugin) => {
	const { plugins = [] } = project.get();
	if (plugins.find(item => item.id === newPlugin.id) === undefined) {
		project.set((value) => ({ ...value, plugins: plugins.concat(newPlugin) }))
	}
}

export const getProjectTheme = () => {
	const { projectData = {} } = project.get();
	return projectData.theme;
}

export const getProjectFeature = ()=>{
	const { projectData = {} } = project.get();
	return projectData?.feature;
}