import appmaker from '@appmaker/core/index';
import { CollectionIcon } from '@heroicons/react/solid';
import DataSourceHome from './routes/DataSourceHome';
import DataSourceDetails from './routes/DataSourceDetails';
import EditDataSource from './routes/EditDataSource';
import { activate } from './filters';
const plugin = {
	id: '@appmaker/data-source',
	name: 'Data Source',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/data-source/edit/:dataSourceId',
			component: EditDataSource,
		});

		appmaker.registerRoute({
			routeName: '/data-source/:dataSourceId',
			component: DataSourceDetails,
		});

		appmaker.registerRoute({
			routeName: '/data-source',
			component: DataSourceHome,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Data Source',
			routeName: '/data-source',
			adminOnly: true,
			icon: CollectionIcon,
		});
		/**
		 * Activating Filters
		 */
		activate();
	},
};

export default plugin;
