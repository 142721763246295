import Form from 'packages/admin-tools/components/CRUD/forms';
import { useState, useEffect } from 'react';
import api from '../../api';
import { useProject } from '@appmaker/core/hooks';
import * as yup from 'yup';
import Notification, { sendNotification } from '@appmaker/components/ReactHotToastIntegrator';
import Loader from '@appmaker/components/Loading';

const WorkflowTrigger = () => {
	const [isSaving, setSaving] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [formValue, setFormValue] = useState({});
	const { projectId } = useProject();
	const schema = {
		properties: {
			workflow: {
				type: "json",
				inputType: "textArea",
				rows:20,
				label: 'Workflow',
			}
		}
	}
	const onClickSave = async (data) => {
		setSaving(true);
		try {
			let schema = yup.object().shape({
				workflow: yup.object().required('Workflow is required'),
			});
			const validate = await schema.validate(data, {
				abortEarly: false,
			});
			await api.saveWorkflow({
				projectId,
				workflow: validate.workflow,
			})
			sendNotification('Saved successfully', 'success');
		} catch (error) {
			console.log(error.errors);
			if (error && error.errors) {
				try {
					error.errors.map(item => {
						return sendNotification(item, 'error');
					})
				} catch (error) {
					console.log(error);
				}
			} else {
				sendNotification('Something went wrong', 'error');
			}
		}
		setSaving(false)
	}
	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const response = await api.getWorkflow({ projectId });
				setFormValue({
					workflow: response
				});
			} catch (error) {
				sendNotification('Something went wrong', 'error');
			}
			setLoading(false);
		})();
	}, [])
	if (isLoading) {
		return <Loader />
	}

	return (
		<Notification>
			<Form
				formValue={formValue}
				schema={schema}
				type="create"
				onClickButton={onClickSave}
				buttonLoading={isSaving}
				buttonLabel='Save'
			/>
		</Notification>
	)
}

export default WorkflowTrigger;