import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [
	{
		attributes: {
			appmakerAction: {},
			title: '',
			itemsPerLine: '4',
			showViewMoreButton: false,
			showTitle: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-23.png',
					},
					imageContainerStyle: {
						width: 225,
						height: 225,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'A6Jtiz',
							id: 'A6Jtiz',
							label: 'Men',
						},
						params: {
							value: 'A6Jtiz',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130020',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-24-1.png',
					},
					imageContainerStyle: {
						width: 225,
						height: 225,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'v2a8Oi',
							id: 'v2a8Oi',
							label: 'Women',
						},
						params: {
							value: 'v2a8Oi',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130021',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-22-2.png',
					},
					imageContainerStyle: {
						width: 225,
						height: 225,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'gGNa96',
							id: 'gGNa96',
							label: 'Kids',
						},
						params: {
							value: 'gGNa96',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130022',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-25-1.png',
					},
					imageContainerStyle: {
						width: 225,
						height: 225,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'UMnc_9',
							id: 'UMnc_9',
							label: 'Offers',
						},
						params: {
							value: 'UMnc_9',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_130023',
				isValid: true,
			},
		],
		clientId: 'widget_key_13002',
		isValid: true,
		name: 'appmaker/grid',
	},
	{
		attributes: {
			appmakerAction: {},
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-8.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 630,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53220',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-6.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 630,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53221',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-7.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 630,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53222',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-9.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 630,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53223',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-10.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 630,
					},
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53224',
				isValid: true,
			},
		],
		clientId: 'widget_key_5322',
		name: 'appmaker/slider',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: '',
			itemsPerLine: '2',
			showViewMoreButton: false,
			showTitle: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-1-1.png',
					},
					imageContainerStyle: {
						width: 711,
						height: 711,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: 16,
							id: 16,
							label: 'Accessories',
						},
						params: {
							value: 16,
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '036f583b-dda9-476c-b7a4-5319692793ca0',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-54-1.png',
					},
					imageContainerStyle: {
						width: 711,
						height: 711,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: 27,
							id: 27,
							label: 'Jeans',
						},
						params: {
							value: 27,
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '036f583b-dda9-476c-b7a4-5319692793ca1',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-55-1.png',
					},
					imageContainerStyle: {
						width: 711,
						height: 711,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: 28,
							id: 28,
							label: 'Jeans',
						},
						params: {
							value: 28,
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '036f583b-dda9-476c-b7a4-5319692793ca2',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-56.png',
					},
					imageContainerStyle: {
						width: 711,
						height: 711,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: 17,
							id: 17,
							label: 'Men',
						},
						params: {
							value: 17,
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '036f583b-dda9-476c-b7a4-5319692793ca3',
				isValid: true,
			},
		],
		clientId: '036f583b-dda9-476c-b7a4-5319692793ca',
		isValid: true,
		name: 'appmaker/grid',
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Best Selling Products',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					id: 709,
					labelId: 'Denim Blue Jeans',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f0',
				isValid: true,
			},
			{
				attributes: {
					id: 713,
					labelId: 'DNK Orange Sports Shoes',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f1',
				isValid: true,
			},
			{
				attributes: {
					id: 708,
					labelId: 'Faint Blue Jeans',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f2',
				isValid: true,
			},
			{
				attributes: {
					id: 715,
					labelId: 'DNK Black Shoes',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f3',
				isValid: true,
			},
			{
				attributes: {
					id: 693,
					labelId: 'Black Over-the-shoulder Handbag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f4',
				isValid: true,
			},
			{
				attributes: {
					id: 703,
					labelId: 'Grey Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f5',
				isValid: true,
			},
			{
				attributes: {
					id: 694,
					labelId: 'Light Brown Bag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f6',
				isValid: true,
			},
		],
		clientId: '228089a7-f5da-476f-813f-dfd0f3b4ac4f',
		isValid: true,
		name: 'appmaker/productscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-4.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 345,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: '',
					id: '',
					label: 'All',
				},
				params: {
					value: '',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: 'widget_key_29792',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'For You',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					id: 715,
					labelId: 'DNK Black Shoes',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '5cc87863-52cd-470d-85c6-f442a5d89ebb0',
				isValid: true,
			},
			{
				attributes: {
					id: 694,
					labelId: 'Light Brown Bag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '5cc87863-52cd-470d-85c6-f442a5d89ebb1',
				isValid: true,
			},
			{
				attributes: {
					id: 704,
					labelId: 'Lemon Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '5cc87863-52cd-470d-85c6-f442a5d89ebb2',
				isValid: true,
			},
			{
				attributes: {
					id: 703,
					labelId: 'Grey Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '5cc87863-52cd-470d-85c6-f442a5d89ebb3',
				isValid: true,
			},
		],
		clientId: '5cc87863-52cd-470d-85c6-f442a5d89ebb',
		isValid: true,
		name: 'appmaker/productscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-3.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 345,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: '',
					id: '',
					label: 'All',
				},
				params: {
					value: '',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: '849de204-a325-4cbc-adbe-6c3347781b7b',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Sale Products',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					id: 704,
					labelId: 'Lemon Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '68aff321-c16f-42c2-9d48-516396ba9aeb0',
				isValid: true,
			},
			{
				attributes: {
					id: 693,
					labelId: 'Black Over-the-shoulder Handbag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '68aff321-c16f-42c2-9d48-516396ba9aeb1',
				isValid: true,
			},
			{
				attributes: {
					id: 703,
					labelId: 'Grey Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '68aff321-c16f-42c2-9d48-516396ba9aeb2',
				isValid: true,
			},
			{
				attributes: {
					id: 715,
					labelId: 'DNK Black Shoes',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '68aff321-c16f-42c2-9d48-516396ba9aeb3',
				isValid: true,
			},
			{
				attributes: {
					id: 694,
					labelId: 'Light Brown Bag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '68aff321-c16f-42c2-9d48-516396ba9aeb4',
				isValid: true,
			},
		],
		clientId: '68aff321-c16f-42c2-9d48-516396ba9aeb',
		isValid: true,
		name: 'appmaker/productscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/12/Artboard-–-5.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 345,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: '',
					id: '',
					label: 'All',
				},
				params: {
					value: '',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: 'be4d0d66-9057-4435-9aeb-4816daaacff5',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'On Demand',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					id: 704,
					labelId: 'Lemon Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'd46135fd-d603-41e6-8055-ecf32d86d1b00',
				isValid: true,
			},
			{
				attributes: {
					id: 703,
					labelId: 'Grey Pattern Tshirt',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'd46135fd-d603-41e6-8055-ecf32d86d1b01',
				isValid: true,
			},
			{
				attributes: {
					id: 715,
					labelId: 'DNK Black Shoes',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'd46135fd-d603-41e6-8055-ecf32d86d1b02',
				isValid: true,
			},
			{
				attributes: {
					id: 693,
					labelId: 'Black Over-the-shoulder Handbag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'd46135fd-d603-41e6-8055-ecf32d86d1b03',
				isValid: true,
			},
			{
				attributes: {
					id: 694,
					labelId: 'Light Brown Bag',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'd46135fd-d603-41e6-8055-ecf32d86d1b04',
				isValid: true,
			},
		],
		clientId: 'd46135fd-d603-41e6-8055-ecf32d86d1b0',
		isValid: true,
		name: 'appmaker/productscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://nc-kart.site.shopilder.com/wp-content/uploads/2020/01/banner-01.jpg',
			},
			imageContainerStyle: {
				width: 1920,
				height: 1100,
			},
			appmakerAction: {
				action: 'OPEN_INAPP_PAGE',
				OPEN_INAPP_PAGE_id: {
					value: 'dF6Y1j',
					id: 'dF6Y1j',
					label: 'Bonus page',
				},
				params: {
					value: 'dF6Y1j',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: 'fab8842f-41c9-4a0d-9dc2-c637798570ba',
		name: 'appmaker/banner',
		isValid: true,
	},
];
// function generate() {

// }
const template = {
	blocks,
	id: 2,
	name: 'Fashion',
	category: 'Theme',
	price: 'Free',
	imageSrc: '/images/themes/fashion.png',
	imageAlt: 'Mobile app fashion theme',
};
export default template;
