import create from 'zustand';
import produce from 'immer';

const useThemeStore = create((set) => ({
	isLoading: false,
	theme: {},
	installedData: [],
	unSaved: false,
	setLoading: (value) =>
		set(
			produce((state) => {
				state.isLoading = value;
			})
		),
	setUnsaved: (value) =>
		set(
			produce((state) => {
				state.unSaved = value;
			})
		),
	setThemeData: (theme, installedData) =>
		set(
			produce((state) => {
				state.theme = theme;
				state.installedData = installedData;
			})
		),
	setInstall: (installData) => {
		set(
			produce((state) => {
				state.installedData?.push(installData);
			})
		);
	},
	syncSettings: (id, newSettings) => {
		return set(
			produce((state) => {
				state.installedData = state?.installedData?.map((item) => {
					if (item.id === id) {
						return {
							...item,
							settings: newSettings,
						};
					} else {
						return item;
					}
				});
				state.unSaved = false;
			})
		);
	},
	syncActiveStatus: (id, status) => {
		return set(
			produce((state) => {
				state.installedData = state?.installedData?.map((item) => {
					if (item.id === id) {
						return {
							...item,
							status: status,
						};
					} else {
						return item;
					}
				});
			})
		);
	},
	syncDefaultTheme: (id, isDefault) => {
		return set(
			produce((state) => {
				state.installedData = state?.installedData?.map((item) => {
					if (item.id === id) {
						return {
							...item,
							isDefault: isDefault,
						};
					} else {
						return {
							...item,
							isDefault: false
						};
					}
				});
			})
		);
	},
	syncUninstall: (id) => {
		return set(
			produce((state) => {
				state.installedData = state?.installedData
					?.map((item) => {
						if (item.id === id) {
							return undefined;
						} else {
							return item;
						}
					})
					?.filter((item) => item !== undefined);
			})
		);
	},
	syncPlatformVersion: (id, { androidVersion, iosVersion }={}) => {
		return set(
			produce((state) => {
				state.installedData = state?.installedData?.map((item) => {
					if (item.id === id) {
						return {
							...item,
							androidVersion,
							iosVersion
						};
					} else {
						return item;
					}
				});
				state.unSaved = false;
			})
		);
	}
}));

export default useThemeStore;
