/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from 'react';
import LabelGenerator from 'packages/woocommerce/components/LabelGenerator';
import { CheckIcon, XCircleIcon } from '@heroicons/react/solid';
import { useStore } from '../../store/pluginCheck'
import clsx from 'clsx';
import {
    useHistory
} from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import appmaker from '@appmaker/core/';
import { setPublicMeta, useProject } from '@appmaker/core/store/project';
function StatusText({ children, status }) {
    let className = ''
    switch (status) {
        case 'success':
            className = 'text-green-600'
            break;
        case 'failed':
            className = 'text-red-600'
            break;

        default:
            className = 'text-indigo-600'

            break;
    }
    return <span className={clsx("text-m font-semibold tracking-wide ",
        className
    )}>{children}</span>

}
function WooCommerceSiteCheck() {
    const validateWooCommerceSite = useStore(store => store.validateWooCommerceSite)
    const validateWooCommerceSiteStatus = useStore(store => store.validateWooCommerceSiteStatus)
    const validateWooCommerceSiteTexts = useStore(store => store.validateWooCommerceSiteTexts)
    const step = { name: 'Validating website URL', description: 'Hold on while we validate your website URL.', href: '#', status: 'complete' }
    useEffect(() => {
        validateWooCommerceSite()
    }, [])
    useEffect(() => {
        setPublicMeta({
            validateWooCommerceSiteStatus,
            validateWooCommerceSiteTextsTitle: validateWooCommerceSiteTexts?.heading
        })
    }, [validateWooCommerceSiteStatus])
    return <li className="relative">
        {validateWooCommerceSiteStatus === 'success' && <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-green-600" aria-hidden="true" />
        }        <div href={step.href} className="relative flex items-start group" >
            <StepIcon state={
                validateWooCommerceSiteStatus
            } loading={validateWooCommerceSiteStatus === 'loading'} />

            <span className="ml-4 min-w-0 flex flex-col">
                <StatusText status={validateWooCommerceSiteStatus}>{validateWooCommerceSiteTexts?.heading}</StatusText>
                <span className="text-sm text-gray-500 mt-4 py-2">{validateWooCommerceSiteTexts?.content}
                    {/* {validateWooCommerceSiteStatus === 'failed' && <div
                        onClick={validateWooCommerceSite}
                        type="button"
                        className=" ml-6  inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >

                    </div>} */}

                </span>
            </span>

        </div>
    </li>
}
function Icon({ state, loading }) {
    switch (state) {
        case 'loading':
            return <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                <span className={clsx(" h-2.5 w-2.5 bg-indigo-600 rounded-full", { 'animate-ping': loading })} />
            </span>
        case 'success':
            return <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-green-600 rounded-full group-green:bg-indigo-800">
                <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
            </span>
        case 'failed':
            return <span className={clsx({ "relative z-10 w-8 h-8 flex items-center justify-center bg-red-600 rounded-full group-hover:bg-red-800": !loading, "relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full": loading })}>
                {loading ? <span className={clsx(" h-2.5 w-2.5 bg-indigo-600 rounded-full", { 'animate-ping': loading })} /> : <XCircleIcon className="w-5 h-5 text-white" aria-hidden="true" />}
            </span>

        default:
            return <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                <span className={clsx(" h-2.5 w-2.5 bg-indigo-600 rounded-full", { 'animate-ping': state === 'loading' })} />
            </span>
    }
}
function StepIcon({ state, loading }) {

    return (<span className="h-9 flex items-center " aria-hidden="true">
        <Icon state={state} loading={loading} />
    </span>);
}



function PluginCheckCount() {
    const attempts = useStore(store => store.attempts);
    const pluginCheckingStatus = useStore(store => store.pluginCheckingStatus);
    const pluginStatusLoading = useStore(store => store.pluginStatusLoading);
    const pluginInstallTexts = useStore(store => store.pluginInstallTexts);

    const content = <span> Appmaker plugin checking <span className="font-bold ">{attempts} </span>attempts</span>
    useEffect(() => {
        setPublicMeta({
            pluginCheckAttempts: attempts,
            pluginInstallTextsTitle: pluginInstallTexts.title
        })
    }, [attempts])
    return (<LabelGenerator name={content} status={pluginCheckingStatus} loading={pluginStatusLoading} />);
}


function PluginInstallCheck() {
    const history = useHistory()
    const generatePath = useRouterPath();

    const checkPluginInstallUntilLimit = useStore(store => store.checkPluginInstallUntilLimit);
    const pluginCheckingStatus = useStore(store => store.pluginCheckingStatus);
    const pluginInstallTexts = useStore(store => store.pluginInstallTexts);
    const pluginStatus = useStore(store => store.pluginStatus);
    const pluginStatusLoading = useStore(store => store.pluginStatusLoading);

    const { projectId } = useProject()

    React.useEffect(() => {
        // doFetch();
        if (pluginStatus?.pluginInstalledVerfiedByAPI) {
            appmaker.doAction('app_created_and_plugin_installed', { projectId });

            setTimeout(() => {
                history.push(generatePath('/onboarding/woocommerce/connect'))
            }, 2000);
        }
    }, [pluginStatus]);

    React.useEffect(() => {
        // doFetch();
        checkPluginInstallUntilLimit()
    }, []);
    return <li className="relative mt-4">
        {/* <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" /> */}

        <div className="relative flex items-start group " aria-current="step">
            <StepIcon state={pluginCheckingStatus} loading={pluginStatusLoading} />
            <span className="ml-4 min-w-0 flex flex-col">
                <StatusText status={pluginCheckingStatus}>{pluginInstallTexts?.title}</StatusText>
                <div className="mt-4"><PluginCheckCount /></div>
                {/* <span className="text-m font-semibold tracking-wide  text-indigo-600">{pluginInstallTexts.title}</span> */}
                <span className="text-sm text-gray-500 mt-4 py-2">{pluginInstallTexts.content}
                    {(!pluginStatusLoading) && <button
                        disabled={pluginStatusLoading}
                        onClick={() => {
                            checkPluginInstallUntilLimit()
                            setPublicMeta({
                                VerifyPluginInstallationClick: true
                            })
                        }}
                        type="button"
                        className=" ml-6 mt-4  inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                        Verify Plugin Installation
                    </button>}
                </span>
            </span>
        </div>
    </li>
}
export function StatusStep() {
    const validateWooCommerceSiteStatus = useStore(store => store.validateWooCommerceSiteStatus)

    return (
        <div className="p-4">
            <nav aria-label="Progress">
                <ol role="list" className="overflow-hidden">
                    <WooCommerceSiteCheck />
                    {validateWooCommerceSiteStatus === 'success' && <PluginInstallCheck />}
                </ol>
            </nav></div>
    );
}
