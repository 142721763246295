/* This example requires Tailwind CSS v2.0+ */
export default function CustomPlan({ publicMeta, paymentButtonOnClick,isPaymentProceeding }) {
    // const [loading, setLoading] = useState(false);
    const { custom_plan } = publicMeta;
    const planObject = {
        shopifyId: {
			monthly: { usd: custom_plan.id },
			yearly: {usd: custom_plan.id, },
		}
    }
    return (
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{custom_plan.title}</h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500">
                            <p>
                                {custom_plan.price}
                            </p>
                        </div>
                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                            <button
                                disabled={isPaymentProceeding}
                                onClick={()=>paymentButtonOnClick(planObject)}
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            >
                                {isPaymentProceeding?'Loading':'Subscribe'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    )
}
