import { HeartIcon } from '@heroicons/react/outline';

export default function ProductGridItem({ attributes }) {
	const {
		productImageUrl = 'https://picsum.photos/400/300/',
		title = 'Product Title',
		price = '$20.15',
		wishList,
		saleBadge,
	} = attributes;
	return (
		<div className="grid grid-cols-2 gap-2 p-2">
			<div className="relative flex flex-col space-y-2 border border-gray-100 rounded-md overflow-hidden">
				<img
					src={productImageUrl}
					alt="product"
					className="w-full max-h-40 object-cover"
				/>
				<div className="px-2">
					<p className="font-medium text-sm truncate">{title}</p>
					<p className="font-bold text-base">{price}</p>
				</div>
				<div className="absolute inset-x-0 flex items-center justify-between mx-2">
					<div>
						{saleBadge && (
							<span className="text-xs text-white bg-green-500 px-1 py-0.5 rounded">
								sale percentage
							</span>
						)}
					</div>
					{wishList && <HeartIcon className="h-6 w-6 text-gray-600" />}
				</div>
			</div>
			{/* <div className="flex flex-col space-y-2 border border-gray-100 rounded-md overflow-hidden">
				<img
					src={productImageUrl}
					alt="product"
					className="w-full max-h-40 object-cover"
				/>
				<div className="px-2">
					<p className="font-medium text-sm truncate">{title}</p>
					<p className="font-bold text-base">{price}</p>
				</div>
			</div>
			<div className="flex flex-col space-y-2 border border-gray-100 rounded-md overflow-hidden">
				<img
					src={productImageUrl}
					alt="product"
					className="w-full max-h-40 object-cover"
				/>
				<div className="px-2">
					<p className="font-medium text-sm truncate">{title}</p>
					<p className="font-bold text-base">{price}</p>
				</div>
			</div> */}
		</div>
	);
}
ProductGridItem.attributesSchema = {
	properties: {
		// savedItemIds: '{{(pageState.metaData.savedItem.data)}}',
		// : '',
		// : true,
		savedItemIds: {
			type: 'string',
			label: 'Saved Items',
			default: '',
		},
		id: {
			type: 'string',
			label: 'Id',
			default: '',
		},
		hasPages: {
			type: 'string',
			label: 'Has pages',
			default: '',
		},
		wishList: {
			type: 'string',
			label: 'Wish List',
			default: '',
		},
		numColumns: {
			type: 'string',
			label: 'Number Columns',
			default: '',
		},
		gridViewListing: {
			type: 'string',
			label: 'grid view listing',
			default: '',
		},
		productType: {
			type: 'string',
			label: 'Product type',
			default: '',
		},
		cartQuantity: {
			type: 'string',
			label: 'Cart quantity',
			default: '',
		},
		blockItem: {
			type: 'string',
			label: 'Block item',
			default: '',
		},
		in_stock: {
			type: 'string',
			label: 'In Stock',
			default: '',
		},
		availableForSale: {
			type: 'string',
			label: 'Available for sale',
			default: '',
		},
		uri: {
			type: 'string',
			label: 'Image URL',
			default: '',
		},
		title: {
			type: 'string',
			label: 'Title',
			default: 'Title',
		},
		price: {
			type: 'string',
			label: 'Price',
			default: '$20.0',
			// uiType: 'TemplateInput',
		},
		regularPrice: {
			type: 'string',
			label: 'regular price',
			default: 'regular price',
		},
		salePrice: {
			type: 'string',
			label: 'salePrice',
			default: 'salePrice',
		},
		salePercentage: {
			type: 'string',
			label: 'Sale Percentage',
			default: 'salePercentage',
		},
		onSale: {
			type: 'string',
			label: 'On sale',
			default: 'onSale',
		},
	},
};
