import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

const ExpandableTextBlock = ({ attributes }) => {
	return (
		<div className="py-2 px-4">
			<div className="flex items-center justify-between">
				<div className="text-base font-medium">{attributes.blockTitle}</div>
				<div className="text-xs font-medium text-blue-700">
					{/* {attributes.expandable
						? `${attributes?.hiddenText} ${attributes.expandedText}`
						: attributes.accessButton} */}
				</div>
			</div>
			{attributes.expanded && (
				<div className="text-gray-500 text-sm py-2">{attributes.content}</div>
			)}
		</div>
	);
};

ExpandableTextBlock.attributesSchema = {
	type: 'object',
	properties: {
		blockTitle: {
			type: 'string',
			label: 'Block Title',
			default: '',
		},
		content: {
			type: 'string',
			label: 'Content',
			default: '',
		},
		accessButton: {
			type: 'string',
			label: 'Access Button',
			default: '',
		},
		expandable: {
			type: 'string',
			label: 'Expandable',
			default: '',
			uiType: 'Switch',
		},
		hiddenText: {
			type: 'string',
			label: 'Hidden Text',
			default: '',
		},
		expandedText: {
			type: 'string',
			label: 'Expanded Text',
			default: '',
		},

		expanded: {
			type: 'string',
			label: 'Expanded',
			default: '',
			uiType: 'Switch',
		},
		__display: {
			type: 'string',
			label: '_Display',
			default: '',
		},
		// blockTitle: 'Description',
		// content: '{{blockItem.node.descriptionHtml}}',
		// accessButton: false,
		// expanded: true,
		// expandable: false,
	},
};

export default ExpandableTextBlock;
