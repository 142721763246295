import appmaker from '@appmaker/core/index';
import coreActions from './actions';

const AppmakerCorePlugin = {
	id: 'appmaker-core',
	name: 'AppmakerCorePlugin',
	activate: () => {
		Object.keys(coreActions).map((action) =>
			appmaker.actions.registerAction(action, coreActions[action])
		);
	},
};

export default AppmakerCorePlugin;
