import { CloudDownloadIcon } from '@heroicons/react/solid';

const StepFive = () => {
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			<li>
				Under Store settings, update the Store listing contact details such as email address, phone number, and website. You can also update the External marketing details. Providing the contact details will support your app’s users

				<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-5-1.png"
					alt="step one"
				/>

			</li>
			<li>
				Fill in the App details such as App name, Short Description and Full description in the respective fields under Main Store listing
				<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-5-2.png"
					alt="step one"
				/>
			</li>
		</ul>
	);
};

export default StepFive;
