import { getProjectId } from '@appmaker/core/store/project';
import axios from 'axios';
import { DASHBOARD_GRAPHQL_URL } from '@appmaker/core/api/core';

const getExtension = async ({
	extensionId,
	limit,
	offset,
	orderBy,
	search,
}) => {
	const projectId = getProjectId();
	const query = `
    query ($extensionId: String!, $limit: Int, $offset: Int, $orderBy: String, $search: String) {
        extension(
          id: $extensionId
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          search: $search
        ) {
          id
          tag
          projects {
            id
            name
          }
        }
      }
        `;

	const response = await axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				extensionId,
				limit,
				offset,
				orderBy,
				search,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
	return response?.data?.data;
};


export {
    getExtension
}