const AlreadyUpload = ({alreadyUploaded=true})=>{

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h4>
          <i className="fas fa-check-circle text-success mr-2" />
          {alreadyUploaded ? 'Current IPA is already uploaded' : 'IPA Uploaded Successfully'}
        </h4>
        <a
          target="_blank"
          href="https://appmaker.xyz/docs/version-release-of-ios-app/"
        >
          <small className="mb-3">Documentation to release uploaded ipa</small>
        </a>
      </div>
    )
}

export default AlreadyUpload