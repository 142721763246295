import {
    createStore,
    AppmakerFormProvider,
    useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useEffect, useState } from 'react';

const FormSchema = ({
    schema,
    onClickButton = () => { },
    buttonLabel = 'Save',
    buttonLoading = false,
}) => {
    const storeApi = useAppmakerFormStoreApi();

    const onClickSave = () => {
        const { formData } = storeApi.getState();
        console.log(formData)
        onClickButton(formData);
    }
    return (
        <div className="bg-gray-50 h-full p-6">
            <div className="max-w-3xl">
                <AppmakerForm
                    formVersion={2}
                    // value={{ formData: flatten(formData) }}
                    schema={schema}
                />
                <button
                    disabled={buttonLoading}
                    className='my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    onClick={() => { onClickSave() }}
                >
                    {buttonLoading ? 'Loading ...' : buttonLabel}
                </button>
            </div>
        </div>
    );
}

const Test = ({ formValue }) => {
    const storeApi = useAppmakerFormStoreApi();
    useEffect(()=>{
        // storeApi.setState({})
    },[formValue])
    return null;
}
const EditForm = ({
    formValue = {},
    schema = {},
    onClickButton = () => { },
    buttonLabel = 'Save',
    buttonLoading = false,
}) => {

    //    console.log(formValue)
    const store = createStore({
        formData: formValue,
    })
    console.log(store);
    return (
        <AppmakerFormProvider
            createStore={store}
        >
            {/* <Test formValue={formValue} /> */}
            <FormSchema
                schema={schema}
                onClickButton={onClickButton}
                buttonLabel={buttonLabel}
                buttonLoading={buttonLoading}
            />
        </AppmakerFormProvider>
    )
}

export default EditForm;