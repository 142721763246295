import React from 'react';
import { createHigherOrderComponent } from '@wordpress/compose';
import { addFilter } from '@wordpress/hooks';
import { Fragment } from '@wordpress/element';
import {
	InspectorControls,
	InspectorAdvancedControls,
} from '@wordpress/block-editor';
import { PanelBody } from '@wordpress/components';
import { ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';

const withAdvancedControls = createHigherOrderComponent((BlockEdit) => {
	return (props) => {
		const { attributes, setAttributes, isSelected } = props;
		return (
			<Fragment>
				<BlockEdit {...props} />
				{isSelected && (
					<>
						<InspectorControls>
							<PanelBody title="Image compression" initialOpen={false}>
								<ToggleControl
									label={__('Disable Image Resize', 'newspack-blocks')}
									help="Image should be re-selected to disable"
									checked={attributes?.disableImageResize}
									onChange={() =>
										setAttributes({
											disableImageResize:
												!attributes?.disableImageResize,
										})
									}
								/>
							</PanelBody>
						</InspectorControls>
					</>
				)}
			</Fragment>
		);
	};
}, 'withInspectorControl');

addFilter(
	'editor.BlockEdit',
	'appmaker/with-inspector-controls',
	withAdvancedControls
);
