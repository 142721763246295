
import { useEffect, useState } from 'react';
import themes from '../../../templates';
import data from '../data';
import { useData, setBlankProject, useModal, getBlockCount } from '../hooks';
import CategoryListing from './CategoryListing';
import SideBar from './Sidebar';
import { useQueryParam, StringParam } from 'use-query-params';
import { getPublicMeta } from '@appmaker/core/store/project';
import SimpleAlert from '@appmaker/components/templates/SimpleAlert';
import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project';

const TemplateListing = ({ theme, setTheme }) => {
	const {
		templates = [],
		isLoading = false,
		setTemplate = () => { },
		setOriginalTemplate = () => { }
	} = useData();

	const projectId = getProjectId();

	const [alertModal, setAlertModal] = useState(false);
	const [, setModal] = useModal();
	const [showTemplate] = useQueryParam('showTemplate', StringParam);
	const publicMeta = getPublicMeta();
	const { template_chooser_popup_completed = false } = publicMeta || {}

	useEffect(() => {
		setTemplate(data);
		setOriginalTemplate(data);
	}, [])

	const setBlank = () => {
		appmaker.doAction('appmaker-analytics-event', {
			event: `template_chooser_blank_project_button_clicked`,
			params: {
				projectId,
				insideShopify: !!window.shopifyApp
			}
		});
		const blockCount = getBlockCount();
		if (blockCount === 0) {
			return setModal(false);
		} else {
			setAlertModal(true);
		}
	}
	return (<>
		{alertModal && (
			<SimpleAlert
				openModal={alertModal}
				onClose={setAlertModal}
				attributes={{
					title: '',
					message:
						'This action will replace the in-app page with a blank page. Are you sure you want to continue?',
					ctaMain: {
						action: () => {
							setBlankProject();
							setModal(false);
						},
						label: 'Continue',
					},
					ctaSecondary: {
						action: () => {

							setAlertModal(false);
						},
						label: 'Cancel',
					},
				}}
			/>
		)}
		<div className="col-auto pl-6 py-6 h-full overflow-y-auto bg-gray-100">
			<SideBar
				data={{
					title: 'Categories',
				}}
			/>
		</div>
		<div className="col-span-4 h-full overflow-y-auto p-2">
			<div className="m-6">
				<h3 className="text-left flex-1 line-clamp-1 text-2xl mb-2">
					Blank Project
				</h3>
				<button
					className="p-2 bg-gray-50 hover:bg-opacity-80 text-blue-900 w-full rounded-lg border border-blue-400 border-dashed flex items-center space-x-4 group"
					onClick={setBlank}
				>
					<img
						src="/images/misc/blank template.png"
						alt={themes[0].imageAlt}
						className="object-center object-cover h-24 w-auto rounded-md"
					/>
					<div>
						<h3 className="text-2xl flex items-center space-x-2">
							<span>Start a blank project</span>
							{/* <ArrowRightIcon className="h-5 w-5 transform group-hover:translate-x-2 transition-transform" /> */}
						</h3>
						<p className="text-sm text-gray-700">
							Unleash your creativity, design your app from scratch
						</p>
					</div>
				</button>
			</div>

			<div className="mt-4 relative px-6">
				<CategoryListing />
			</div>
		</div>
	</>)

}



export default TemplateListing;