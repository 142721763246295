import { InnerBlocks } from '@wordpress/block-editor';
import clsx from 'clsx';
import { useBlockParentAttributes } from '@appmaker/core/index';
import { Transition } from '@headlessui/react';

export const FormBlockCard = ({ attributes = {}, children }) => {
	return <div className="relative mb-6 w-full">{children}</div>;
};

export const FormBlockCardEdit = ({ attributes = {}, children }) => {
	const ALLOWED_BLOCKS = [
		'appmaker/web-block-card-content',
		'appmaker/web-block-card-footer',
	];
	const TEMPLATE = [
		['appmaker/web-block-card-content', {}, []],
		['appmaker/web-block-card-footer', {}, []],
	];
	return (
		<FormBlockCard attributes={attributes}>
			<InnerBlocks
				template={TEMPLATE}
				allowedBlocks={ALLOWED_BLOCKS}
				templateLock={'all'}
			/>
		</FormBlockCard>
	);
};
FormBlockCardEdit.attributesSchema = {
	properties: {
		// title: {
		// 	type: 'string',
		// 	label: 'Title',
		// },
		optionComponent: {
			type: 'switch',
			label: 'Show optional component',
		},
		className: {
			type: 'string',
			default: 'mb-4',
			label: 'Class Name',
		},
	},
};

export function FormBlockCardContent({ attributes, children }) {
	const { title } = attributes;
	return (
		<div
			className={clsx(
				'flex flex-col bg-white px-4 py-5 rounded-lg shadow-sm relative z-20'
			)}
		>
			{title ? <h3 className="text-md font-medium mb-2">{title}</h3> : null}
			<div className="-mb-4">{children}</div>
		</div>
	);
}
export function FormBlockCardContentEdit({ attributes }) {
	return (
		<FormBlockCardContent attributes={attributes}>
			<InnerBlocks templateLock={false} />
		</FormBlockCardContent>
	);
}
FormBlockCardContentEdit.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
	},
};

export function FormBlockCardFooter({ attributes, children }) {
	return children && children.length > 0 ? (
		<Transition
			show={true}
			enter="transition ease-out duration-150"
			enterFrom="opacity-0 transform -translate-y-4"
			enterTo="opacity-100 transform translate-y-0"
			leave="transition ease-in duration-150"
			leaveFrom="opacity-100 transform translate-y-0"
			leaveTo="opacity-0 transform -translate-y-4"
		>
			<div
				className={clsx(
					'bg-indigo-100 px-4 rounded-b-lg border pt-8 relative -top-4 z-10',
					'border-gray-200 -pb-4'
				)}
			>
				{children}
			</div>
		</Transition>
	) : null;
}

export function FormBlockCardFooterEdit({ attributes, clientId }) {
	const [parentAttributes] = useBlockParentAttributes(clientId);
	return parentAttributes?.optionComponent === true ? (
		<FormBlockCardFooter attributes={attributes}>
			<InnerBlocks templateLock={false} />
		</FormBlockCardFooter>
	) : null;
}
