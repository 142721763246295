import { useState } from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import Modal from '../../components/Modal';
import Form from '../InfiniteForm/AppmakerForm';
import { isEmpty } from 'lodash';

const defaultValueConverter = (value = []) => {
	// value = [{type:'tag'}]
	// required format = {tag:[{value:'',label:''}]} or {collection:[]}
	let finalValue = {};
	value.forEach((item) => {
		if (item && item.type && item.value) {
			// finalValue[item.type] = []
			if (finalValue[item.type]) {
				finalValue[item.type] = [
					...finalValue[item.type],
					{ value: item.value, label: item.value },
				];
			} else {
				finalValue[item.type] = [{ value: item.value, label: item.value }];
			}
		}
	});
	return finalValue;
};
const DataSourceSelector = ({ properties, onChange = () => {}, ...props }) => {
	const {
		label = 'Choose a data source',
		title = 'Choose',
		properties: options,
		modalButtonSaveLabel = 'Save data source',
		showSelectMessage = true,
		showSearch = true,
	} = properties || {};
	const { defaultValue } = props || {};
	const [sourceSelected, setSourceSelected] = useState(!isEmpty(defaultValue));
	let [isOpen, setIsOpen] = useState(false);
	// let [selectedDataSource, setSelectedDataSource] = useState(
	// 	DATA_SOURCE_TYPES[0]
	// );
	let [DATA_SOURCE_TYPES] = useState(options ?? {});
	if (sourceSelected && !isEmpty(defaultValue)) {
		return (
			<div className="mb-4">
				{showSelectMessage && (
					<p className="block text-sm font-base text-gray-700 mb-1">
						Data source: Selected
					</p>
				)}
				<div className="flex items-center justify-between bg-white p-2 rounded-lg shadow-sm">
					<p className="text-gray-900 line-clamp-1 ml-2">{title}</p>
					<button
						type="button"
						className="inline-flex items-center rounded-md border border-gray-300 p-1.5 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
						onClick={() => {
							setSourceSelected(false);
							setIsOpen(true);
						}}
					>
						<PencilIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
					</button>
				</div>
				<div className="mt-3 mb-1">
					{showSearch && (
						<input
							type="text"
							className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
							placeholder="Search data source name"
						/>
					)}
				</div>
			</div>
		);
	}
	return (
		<div className="mb-4">
			<p className="block text-sm font-base text-gray-700 mb-1">
				{title ? title : 'title'}
			</p>
			<button
				type="button"
				className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
				onClick={() => setIsOpen(true)}
			>
				{label ? label : 'Choose a data source'}
			</button>
			<Modal
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
					setSourceSelected(!isEmpty(defaultValue));
				}}
				size="2xl"
				className="h-96 p-2"
			>
				<Tab.Group as={'div'} className="grid grid-cols-8 min-h-full">
					<Tab.List className="flex flex-col space-y-1 rounded-lg bg-gray-100 p-1 col-span-2">
						{DATA_SOURCE_TYPES &&
							Object.keys(DATA_SOURCE_TYPES).map((source) => (
								<Tab
									key={source}
									className={({ selected }) =>
										clsx(
											'w-full rounded-lg p-2.5 text-sm font-medium leading-5 text-gray-900 text-left',
											selected
												? 'bg-white'
												: 'text-gray-800 hover:bg-gray-200 hover:text-gray-900'
										)
									}
								>
									{source}
								</Tab>
							))}
					</Tab.List>
					<Tab.Panels className="mt-2 ml-4 col-span-6">
						{Object.keys(DATA_SOURCE_TYPES).map((item, idx) => (
							<Tab.Panel key={idx} className="bg-white flex flex-col h-full">
								<div className="flex-auto flex flex-col space-y-2">
									{/* {item?.options?.map((subItem, idx) => (
										<div key={idx} className="flex items-center">
											<input
												id={subItem.id}
												name="notification-method"
												type="radio"
												className="h-4 w-4 border-gray-300 text-indigo-600 "
											/>
											<label
												htmlFor={subItem.id}
												className="ml-3 block text-sm font-medium text-gray-700"
											>
												{subItem.title}
											</label>
										</div>
									))} */}
									{/* 
									filters:[
											{
												type: 'tag',
												value: 'Antique',
											},
										]
									 */}

									{DATA_SOURCE_TYPES[item] && (
										<Form
											onChange={(value) => {
												const { isConverted = false } =
													DATA_SOURCE_TYPES[item] || {};
												if (value && Object.keys(value).length > 0) {
													if (!isConverted) {
														onChange({ [item]: value[item] });
													} else {
														onChange(
															value[item]?.map((val) => {
																return {
																	type: item,
																	value: val?.value,
																};
															})
														);
													}
												}
											}}
											schema={{
												properties: {
													[item]: {
														...(DATA_SOURCE_TYPES[item] ?? {}),
													},
												},
											}}
											formValue={
												DATA_SOURCE_TYPES[item]?.isConverted
													? defaultValueConverter(defaultValue)
													: defaultValue
											}
										/>
									)}
								</div>
								<div className="text-right mt-6">
									<button
										type="button"
										className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() => {
											setIsOpen(!isOpen);
											setSourceSelected(true);
										}}
									>
										{modalButtonSaveLabel}
									</button>
								</div>
							</Tab.Panel>
						))}
					</Tab.Panels>
				</Tab.Group>
			</Modal>
		</div>
	);
};

export default DataSourceSelector;
