import React from 'react';
import { InnerBlocks, RichText } from '@wordpress/block-editor';
import PanelBodyComponent from './panel';
import { extractTextFromHTML } from 'packages/oldBlocks/blocks/blocks/helper';

const onChangeAttributes = (value, setAttributes) => {
	setAttributes(value);
};
const ProductQuality = (props) => {
	const { setAttributes, className, attributes } = props;
	const { showTitle, showSubTitle, title, subTitle } = attributes;
	return (
		<div className={className}>
			<PanelBodyComponent
				{...props}
				onChangeAttribute={(value) => {
					onChangeAttributes(value, setAttributes);
				}}
			/>
			<div className="px-2 pt-2">
				{showTitle ? (
					<RichText
						allowedFormats={[]}
						value={title}
						withoutInteractiveFormatting
						onChange={(text) => {
							setAttributes({ title: extractTextFromHTML(text) });
						}}
						placeholder="Product scroller title"
					/>
				) : null}
				{showSubTitle ? (
					<RichText
						withoutInteractiveFormatting
						className="text-sm text-gray-600"
						allowedFormats={[]}
						value={subTitle}
						onChange={(subTitles) => {
							setAttributes({ subTitle: extractTextFromHTML(subTitles) });
						}}
						placeholder="Enter subtitle"
					/>
				) : null}
			</div>
			<InnerBlocks allowedBlocks={['appmaker/bsc-product-quality-card']} />
		</div>
	);
};

export default ProductQuality;
