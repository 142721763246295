import React from 'react';
import FormItem from './FormItem';
import { useStore } from './store';
import { isArray } from 'lodash';
const mapProperties = (properties) => {
	if (!properties) {
		return [];
	}
	// array compatible
	// when properties is passed as an array (To keep the order) 
	if (isArray(properties)) {
		return properties?.map((property) => {
			return {
				propertyKey: property?.id || property?.key,
				property,
			};
		});
	}
	return Object.keys(properties).map((key) => {
		const property = properties[key];
		return { propertyKey: key, property };
	});
};
const FormItemWrapper = ({
	schema,
	onFileUpload,
	formVersion = 1,
	onDeleteFile,
}) => {
	const setState = useStore((store) => store.setState);
	const store = useStore((store) => store);

	const properties = mapProperties(schema?.properties);
	const onItemChange = (propertyKey, value, _internal, others = {}) =>
		setState((state) => {
			state.formData[propertyKey] = value;
			// disableInternalValue is used disable setting internal value. if title in in form when setting internal value title changes.
			if (_internal && !others?.disableInternalValue) {
				state._internal[propertyKey] = _internal;
				state.formData.title = _internal?.label;
				state._internal.actionParams = _internal;
			}
		});

	return properties
		? properties.map(({ propertyKey, property }) => {
				const { type } = properties;

				// if (type === 'object') {
				// 	return (
				// 		<FormItemWrapper
				// 			onFileUpload={onFileUpload}
				// 			properties={property}
				// 			onChange={(value, _internal, _newName) => {
				// 				onItemChange(_newName || propertyKey, value, _internal);
				// 			}}
				// 			defaultValue={store.formData[propertyKey]}
				// 			_internalValue={store._internal[propertyKey]}
				// 			store={store}
				// 		/>
				// 	);
				// }
				if (formVersion === 2)
					return (
						<FormItem
							key={propertyKey}
							name={propertyKey}
							onFileUpload={onFileUpload}
							onDeleteFile={onDeleteFile}
							properties={property}
							onChange={(value, _internal, _newName, others = {}) => {
								onItemChange(_newName || propertyKey, value, _internal, others);
							}}
							defaultValue={store.formData[propertyKey]}
							_internalValue={store._internal[propertyKey]}
							store={store}
						/>
					);
				return (
					<div className="mt-2" key={propertyKey}>
						{/* <pre>{JSON.stringify({ property, properties }, null, 2)} </pre> */}
						{/* {property.label && (
							<label
								// htmlFor={propertyKey}
								className="block text-sm font-medium text-gray-700"
							>
								{property.label}
							</label>
						)} */}
						<div className="mt-1">
							<FormItem
								name={propertyKey}
								onFileUpload={onFileUpload}
								onDeleteFile={onDeleteFile}
								properties={property}
								onChange={(value, _internal, _newName) => {
									onItemChange(_newName || propertyKey, value, _internal);
								}}
								defaultValue={store.formData[propertyKey]}
								_internalValue={store._internal[propertyKey]}
								store={store}
							/>
						</div>
						{property.helpText && (
							<p className="mt-2 text-sm text-gray-500">{property.helpText}</p>
						)}
					</div>
				);
		  })
		: null;
};
export default FormItemWrapper;
