import React, { useState } from 'react';
import { useParams, useHistory } from '@appmaker/core/routes';
import EditBlock from '../EditBlock';
// import AppmakerForm from '@appmaker/components/Form';
import coreApi from '@appmaker/core/api/core';
import { useProject } from '@appmaker/core/hooks';
import appmaker from '@appmaker/core/index';
import { useLocalStorage } from 'react-use';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';

function lastTabItem({ allTabs, tabId, ___isUpdateForm }) {
	return !(
		allTabs[allTabs.indexOf(tabId) + 1] === undefined ||
		(allTabs.indexOf(tabId) === allTabs.length - 2 && ___isUpdateForm)
	);
}

const flatten = (obj) => {
	let res = {};
	for (const [key, value] of Object.entries(obj)) {
		if (typeof value === 'object') {
			res = { ...res, ...flatten(value) };
		} else {
			res[key] = value;
		}
	}
	return res;
};

function migrateSchema({ schema, uiSchema }) {
	const newSchema = {};
	const { properties } = schema;
	const propertiesArray = Object.keys(properties);
	propertiesArray.map((item) => {
		newSchema[item] = { ...properties[item] };
		if (uiSchema[item] && uiSchema[item]['ui:field'])
			newSchema[item].uiType = uiSchema[item]['ui:field'];
		/**
		 * ui:schema:{
		 * 	"GROCERY_MODE": {
					"ui:field": "Switch",
					"ui:options": {
						upgradeRequired: true,
						showPaymentPage: true
					}
				}
			}
		 */
		if (uiSchema[item] && uiSchema[item]['ui:options']) {
			newSchema[item].uiOption = uiSchema[item]['ui:options'];
		}
		if (newSchema[item].properties) {
			newSchema[item] = {
				...newSchema[item],
				...migrateSchema({
					schema: properties[item],
					uiSchema: uiSchema[item],
				}),
			};
		}
	});
	return { properties: newSchema };
}
const Builder = ({ wholeSchema, basePath }) => {
	const [initialStepCompleted, setInitialStepCompleted] = useLocalStorage(
		'appmaker-initial-step-completed',
		false
	);

	let { tabId } = useParams();
	const allTabs = Object.keys(wholeSchema);
	const history = useHistory();
	// const formData = useFormData();
	const { projectId } = useProject();
	const template = wholeSchema[tabId] || {};
	const { schema = {}, uiSchema = {} } = template;
	const ___isUpdateForm = false;
	window.debugItem = { allTabs, tabId };

	const onClickSave = async () => {
		// console.log(lastTabItem({ allTabs, tabId, ___isUpdateForm: formData.___isUpdateForm }), { allTabs, tabId, ___isUpdateForm: formData.___isUpdateForm });
		// return
		// setInitialStepCompleted(true)
		// try {
		// 	setSaving(true);
		if (allTabs[allTabs.indexOf(tabId) + 1] !== undefined) {
			history.push(`${basePath}/${allTabs[allTabs.indexOf(tabId) + 1]}`);
		} else {
		}
		try {
			if (___isUpdateForm) {
				appmaker.doAction('initial_customize_saved', { projectId });
			} else {
				appmaker.doAction('app_design_changed_saved', { projectId });
			}
		} catch (error) {}
		// history.push(`${basePath}/${allTabs[allTabs.indexOf(tabId) + 1]}`);

		// } catch (error) {
		// } finally {
		// 	setSaving(false);
		// }
	};
	async function onFileUpload(name, files) {
		const data = new FormData();
		data.append('file', files[0]);
		const fileUploadResponse = await coreApi.uploadFile(projectId, data, name);
		if (fileUploadResponse.status && fileUploadResponse.body) {
			const { fileName } = fileUploadResponse.body;
			// setFormData({ ...formData, [name]: fileName });
		}
		return {};
	}
	async function onDeleteFile(tagName) {
		if (tagName) {
			const deleteResponse = await coreApi.deleteFile(projectId, tagName);
		}
	}
	const appmakerSchema = migrateSchema({
		schema,
		uiSchema,
	});
	const uiOptions = template['ui:options'] || {};
	const { video } = uiOptions;
	return (
		<EditBlock
			title={template.title}
			description={template.description}
			onClickSave={onClickSave}
			template={template}
			isSaving={false}
			video={video}
		>
			<AppmakerForm
				formVersion={2}
				// value={{ formData: flatten(formData) }}
				schema={appmakerSchema}
				onDeleteFile={onDeleteFile}
				onFileUpload={onFileUpload}
			/>
		</EditBlock>
	);
};

export default Builder;
