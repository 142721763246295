import appmaker from '@appmaker/core/index';
import { ChipIcon } from '@heroicons/react/solid';
import Transforms from './routes/Transforms';
import EditTransforms from './routes/EditTransforms';
const plugin = {
	id: '@appmaker/transforms',
	name: 'Transforms',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/transforms/edit/:transformId?',
			component: EditTransforms,
		});
		appmaker.registerRoute({
			routeName: '/transforms',
			component: Transforms,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Transforms',
			routeName: '/transforms',
			icon: ChipIcon,
			adminOnly: true,
		});
	},
};

export default plugin;
