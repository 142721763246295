import LoadingIcon from '@appmaker/components/LoadingIcon';
import {
	ChatIcon,
	CloudDownloadIcon,
	ExclamationIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import {
	AndroidLogo,
	IosLogo,
} from 'packages/mobile-app/components/DownloadAppCards';
import coreApi from '@appmaker/core/api/core';
import QrCode from 'packages/mobile-app/components/QrCode';
import appmaker from '@appmaker/core/index';
import { downloadApp } from 'packages/app-builds/api';

const AppCard = ({ attributes = {} }) => {
	const {
		jobId,
		key,
		appName,
		icon,
		buildStatus,
		platform,
		version,
		type,
		projectId,
		platformId,
		token,
		status,
	} = attributes;
	const getDownloadUrl = (buildFileType) => {
		const downloadAppLink = downloadApp({
			buildLogId: jobId ,
			token:token,
			platform: platform,
			buildFileType,
		});
		return downloadAppLink;
	};
	return (
		<div key={key} className="py-2 rounded-lg">
			<div className="flex items-start justify-start flex-col space-y-4 sm:flex-row sm:space-y-0 sm:justify-between">
				<div className="flex items-center space-x-2">
					{icon ? (
						<img src={icon} alt="dp" className="h-14 w-14 rounded-md" />
					) : (
						<img
							src="https://picsum.photos/128"
							alt="dp"
							className="h-14 w-14 rounded-md"
						/>
					)}

					<div>
						<span className="flex items-center space-x-1">
							<h4 className="text-xl line-clamp-1">{appName}</h4>
							<p
								className={clsx(
									'px-2 text-xs rounded-full uppercase',
									{
										COMPLETED: 'text-green-700 bg-green-100 ',
										published: 'text-green-700 bg-green-100 ',
										PENDING: 'text-yellow-700 bg-yellow-100',
										FAILED: 'text-red-700 bg-red-100 ',
										PROCESSING: 'text-purple-700 bg-purple-100',
									}[status] || 'text-blue-700 bg-blue-100'
								)}
							>
								{status}
							</p>
						</span>
						<span className="flex items-center divide-x space-x-2 text-sm text-gray-600 flex-wrap">
							<p className="flex items-center space-x-1">Version: {version}</p>
							<span className="pl-2 flex items-center space-x-1">
								{platform === 'android' ? (
									<AndroidLogo className="h-5 w-5" />
								) : (
									<IosLogo className="h-5 w-5" />
								)}
								<span className="capitalize">{type}</span>
							</span>
						</span>
					</div>
				</div>
				<div className="flex items-center gap-2 flex-row-reverse sm:flex-row">
					{
						{
							PROCESSING: (
								<button className="p-1 bg-purple-100 rounded-full text-purple-600">
									<LoadingIcon className="text-blue-900 animate-spin h-7 w-7" />
								</button>
							),
							COMPLETED: (
								<>
									<QrCode
										display={platform === 'ios' ? false : true}
										position="-right-16 sm:right-0"
										largeButton={true}
										url={getDownloadUrl()}
									/>
									<a
										target="_blank"
										rel="noreferrer"
										onClick={() => {
											appmaker.doAction('appmaker-analytics-event', {
												event: `dashboard-app-download-clicked`,
												params: {
													projectId,
													insideShopify: !!window.shopifyApp,
													platform,
												},
											});
										}}
										href={getDownloadUrl()}
										className="p-2 bg-blue-100 rounded-full text-blue-600 flex items-center space-x-2"
									>
										<CloudDownloadIcon className="h-6 w-6" />
										<span className="pr-1 font-medium inline-flex sm:hidden 2xl:inline-flex">
											Download
										</span>
									</a>
								</>
							),
							PENDING: (
								<button className="p-2 bg-yellow-100 rounded-full text-yellow-600">
									<ExclamationIcon className="h-6 w-6" />
								</button>
							),
							FAILED:
								// <button
								// 	className="p-2 bg-blue-100 rounded-full text-blue-600"
								// 	onClick={() => {
								// 		if (window && window.Intercom)
								// 			window.Intercom('showNewMessage');
								// 	}}
								// >
								// 	<ChatIcon className="h-6 w-6" />
								// </button>
								null,
						}[status]
					}
				</div>
			</div>
		</div>
	);
};

export default AppCard;
