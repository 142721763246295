import React from 'react';
import { UserIcon } from '@heroicons/react/outline';

const DrawerHeader = ({ attributes, setAttributes }) => {
	return (
		<div className="px-2 py-8 flex flex-col bg-gray-900 items-center space-y-2">
			<UserIcon
				className="h-12 w-12 text-gray-800 bg-white rounded-full p-2"
				aria-hidden="true"
			/>
			<h1
				className="text-md flex-auto"
				style={{ color: attributes?.textColor || '#fff' }}
			>
				Login/Register
			</h1>
		</div>
	);
};

DrawerHeader.attributesSchema = {
	type: 'object',
	properties: {
		textColor: {
			label: 'Text Color',
			type: 'string',
			uiType: 'ColorPicker',
		},
	},
};

export default DrawerHeader;
