
import { useParams } from '@appmaker/core/routes';
import EditForm from '../components/Form';
import Notification from '@appmaker/components/ReactHotToastIntegrator';
import { useActionList } from '../hooks';
import Loader from '@appmaker/components/Loading';
const EditAction = () => {
    const { actionId } = useParams();
    const createNewAction = actionId === undefined;
    const { isLoading, actionList = [] } = useActionList({ refetch: false })
    let formData = {};
    if (!createNewAction) {
        formData = actionList.find(action => parseInt(action.id) === parseInt(actionId)) || {};
        formData = {
            ...formData,
            value: formData.value ? JSON.stringify(formData.value) : '',
        }
    }
    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="bg-gray-50 h-screen overflow-y-auto">
            <div className="w-full mx-auto bg-white ">
                <div className="flex items-center justify-between space-x-3 bg-gray-50 px-8 py-6">
                    <div className="flex items-center space-x-3"></div>
                    <div className="flex justify-end">
                        {/* <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Cancel
                        </button> */}
                    </div>
                </div>
                <div className="bg-gray-50">
                    <Notification>
                        <EditForm formValue={formData} isNew={createNewAction}/>
                    </Notification>
                </div>
            </div>
        </div>
    )

}

export default EditAction;