import {
	ClockIcon,
	ClipboardListIcon,
	DeviceMobileIcon,
} from '@heroicons/react/solid';
import { useState, Suspense, lazy } from 'react';
import Loader from '@appmaker/components/Loading';

const Preview = lazy(() => import('./Preview'));
const Schedule = lazy(() => import('./ScheduleHistory'));
const History = lazy(() => import('./History'));

const tabs = [
	{
		id: 'preview',
		name: 'Preview',
		href: '#',
		icon: DeviceMobileIcon,
		current: true,
	},
	{
		id: 'schedule',
		name: 'Scheduled',
		href: '#',
		icon: ClockIcon,
		current: true,
	},
	{ id: 'history', name: 'History', href: '#', icon: ClipboardListIcon },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function LogTab({ image }) {
	const [current, setCurrent] = useState(tabs[0].id);
	const onChangeTab = (tabId) => {
		setCurrent(tabId);
	};
	return (
		<div className="w-full">
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
				<select
					id="tabs"
					name="tabs"
					className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
					defaultValue={tabs.find((tab) => tab.id === current).name}
					onSelect={(event) => onChangeTab(event.target.value)}
				>
					{tabs.map((tab) => (
						<option key={tab.id} value={tab.id}>
							{tab.name}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex space-x-8" aria-label="Tabs">
						{tabs.map((tab) => (
							<button
								key={tab.name}
								onClick={() => onChangeTab(tab.id)}
								className={classNames(
									tab.id === current
										? 'border-indigo-500 text-indigo-600'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
									'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
								)}
								aria-current={tab.id === current ? 'page' : undefined}
							>
								<tab.icon
									className={classNames(
										tab.id === current
											? 'text-indigo-500'
											: 'text-gray-400 group-hover:text-gray-500',
										'-ml-0.5 mr-2 h-5 w-5'
									)}
									aria-hidden="true"
								/>
								<span>{tab.name}</span>
							</button>
						))}
					</nav>
				</div>
			</div>
			<Suspense fallback={Loader}>
				{current === 'preview' && <Preview image={image} />}
				{current === 'schedule' && <Schedule />}
				{current === 'history' && <History />}
			</Suspense>
		</div>
	);
}
