import TemplateChooserButton from "./components/Button";
import TemplateChooserModal from "./components/Modal";
import { useModal } from './hooks'

const TemplateBuilder = () => {
    return (
        <>
            <TemplateChooserButton />
            <TemplateChooserModal />
        </>
    )
}
export { TemplateChooserModal, useModal }
export default TemplateBuilder