import GridItem from 'packages/oldBlocks/new-blocks/components/GridItem';
import React from 'react';

const style = {
	container: {
		width: '100%',
		height: '100%',
		maxWidth: 198,
	},
};
const GridImage = ({ imageSrc, children }) => {
	return (
		// <div className="w-full h-auto">
		// 	<img
		// 		src={imageSrc}
		// 		alt="gridimage"
		// 		className="h-full w-full object-cover"
		// 	/>
		// 	{children && (
		// 		<div className="text-center font-medium text-sm">{children}</div>
		// 	)}
		// </div>
		<GridItem attributes={{ image: imageSrc }}>
			{children && (
				<div className="text-center font-medium text-sm mt-0.5">{children}</div>
			)}
		</GridItem>
	);
};
export default GridImage;
