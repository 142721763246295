import {
	XCircleIcon,
	CheckCircleIcon,
	ExclamationIcon,
	DeviceMobileIcon,
	CloudUploadIcon,
} from '@heroicons/react/solid';
import { CogIcon, InformationCircleIcon } from '@heroicons/react/solid';
import coreApi from '@appmaker/core/api/core';
import { useProject } from '@appmaker/core/hooks';
import { getPackageName } from '@appmaker/core/store/project'
import CustomMessageBanner from '@appmaker/components/CustomMessageBanner';
import QrCode from './QrCode';
import appmaker from '@appmaker/core/index';
const platformIdMap = {
	1: 'Android',
	3: 'iOS',
};


const DownloadAppCards = ({ apps = [], appBuildStatus = {} }) => {
	return (
		<div>
			<div className="pt-4 flex-col flex md:flex-row gap-4">
				{apps.map((app) => (
					<Card
						app={app}
						key={app.id}
						schema={{ title: platformIdMap[app.platformId] }}
						platformId={app.platformId}
						error={appBuildStatus[app.id] ? appBuildStatus[app.id].error : {}}
						buildStatus={
							appBuildStatus[app.id]
								? appBuildStatus[app.id]
								: { status: { preview: 1 } }
						}
					/>
				))}
			</div>
			<div className="mt-4 p-4 bg-gray-100 rounded-lg">
				<h4 className="font-medium text-lg mb-2 text-gray-700 flex items-center gap-2">
					<InformationCircleIcon className="h-6 w-6" />
					<span>Instructions</span>
				</h4>
				<ul className="list-inside list-disc">
					<li className="mb-1">Download your app file file (Android/iOS)</li>
					<li className="mb-1">
						Go to
						<a
							href="https://appetize.io/upload"
							className="text-blue-700 underline px-1 mx-1 hover:text-white hover:bg-blue-600"
							target="_blank"
							rel="noreferrer"
						>
							https://appetize.io/upload
						</a>
						and upload the app file, follow the steps mentioned in appetize.io
					</li>
				</ul>
				<div className="mt-4 text-blue-900 font-medium">
					<small className="bg-blue-100 px-2 py-0.5 rounded-full mr-2">
						Note
					</small>
					<span>
						If you have an android phone, you can install the app on your phone
						and test.
					</span>
				</div>
			</div>
		</div>
	);
};

export default DownloadAppCards;

const Card = ({ app, schema = {}, buildStatus = {}, error = {}, platformId }) => {
	const { projectId } = useProject();
	const { title } = schema;
	const {
		version,
		status: { preview: previewStatus = 1 },
	} = buildStatus;
	const packageNames = getPackageName() || {};
	const getPackage = (pid) => {
		if (pid === 1) {
			return packageNames.android
		}
		if (pid === 3) {
			return packageNames.ios
		}
		return undefined
	}
	return (
		<div className="p-4 border border-gray-200 flex-1 bg-white rounded-lg">
			<div className="flex items-center gap-3">
				{title === 'Android' ? (
					<AndroidLogo className="text-gray-800 h-12 w-12 p-2 rounded bg-gray-100" />
				) : (
					<IosLogo className="text-gray-800 h-12 w-12 p-2 rounded bg-gray-100" />
				)}
				<div>
					<h4 className="text-lg font-bold text-gray-900">{title}</h4>
					<p className="text-xs font-base text-gray-500">Version: {version}</p>
					<p className="text-xs font-base text-gray-500">Package name: {getPackage(platformId)}</p>
				</div>
			</div>
			{previewStatus === -1 && <Message status="error" />}
			{previewStatus === 0 && <Message status="warning" />}
			{previewStatus === 1 && <Message status="building" />}
			{previewStatus === 2 && (
				<>
					<Message status="success" />
					<div className="mt-4 inline-flex relative">

						{/* <a
							className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							href={`https://appmaker.xyz/dashboard/apps/download/${
								app.id
							}?preview=${app.platformId == 3 ? true : false}&token=${
								app.token
							}`}
						>
							<DeviceMobileIcon
								className="-ml-1 mr-3 h-5 w-5"
								aria-hidden="true"
							/>
							Download
						</a> */}
						<a
							className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							target='_blank'
							rel="noreferrer"
							onClick={() => {
								appmaker.doAction('appmaker-analytics-event', {
									event: 'app_download_button_clicked',
									params: {
										project_id: projectId,
										platform: parseInt(app.platformId) === 3 ? 'ios' : 'android',
									}
								})
							}}
							href={
								coreApi.downloadApp({
									projectId: projectId,
									preview: parseInt(app.platformId) === 3 ? true : false,
									token: app.token,
									platform: parseInt(app.platformId) === 3 ? 'ios' : 'android',
								})
							}
						>
							<DeviceMobileIcon
								className="-ml-1 mr-3 h-5 w-5"
								aria-hidden="true"
							/>
							Download
						</a>
						<span className='absolute -right-3 -top-3'>
							<QrCode url={coreApi.downloadApp({
								projectId: projectId,
								preview: parseInt(app.platformId) === 3 ? true : false,
								token: app.token,
								platform: parseInt(app.platformId) === 3 ? 'ios' : 'android',
							})}
								platform={parseInt(app.platformId) === 3 ? 'ios' : 'android'}
							/>
						</span>

						{/* <button
					type="button"
					className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					<CloudUploadIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
					Publish to store
				</button> */}
					</div>
				</>
			)}
			{(error && (error.type || error.message)) ?
				(
					<CustomMessageBanner
						type='error'
						displayMessage={
							<>
								{error.type ? <div className='text-sm mb-1'>{error.type}</div> : null}
								{error.message ? <div className='text-sm'>{error.message}</div> : null}
							</>
						}
						color='red'
					/>
				)
				: null
			}
		</div>
	);
};

export const IosLogo = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
		<path
			d="M17.8682 12.6879C17.901 16.0172 20.966 17.1251 21 17.1393C20.9741 17.2174 20.5103 18.7181 19.3852 20.2683C18.4126 21.6085 17.4032 22.9438 15.8131 22.9714C14.2507 22.9985 13.7483 22.0979 11.962 22.0979C10.1762 22.0979 9.61803 22.9437 8.13901 22.9985C6.60416 23.0533 5.43539 21.5493 4.45474 20.214C2.4509 17.4827 0.919552 12.4959 2.97577 9.12967C3.99725 7.45801 5.82272 6.39945 7.8041 6.3723C9.31128 6.3452 10.7339 7.3283 11.6552 7.3283C12.576 7.3283 14.3048 6.14603 16.1222 6.31966C16.883 6.34952 19.0186 6.60941 20.39 8.50192C20.2795 8.56651 17.8417 9.9045 17.8682 12.6879V12.6879ZM14.9318 4.51263C15.7467 3.58268 16.2951 2.2881 16.1455 1C14.9709 1.04451 13.5506 1.73794 12.7081 2.66738C11.9531 3.49044 11.2919 4.8078 11.4703 6.07041C12.7795 6.16591 14.1169 5.44317 14.9318 4.51263"
			fill="currentColor"
		/>
	</svg>
);
export const AndroidLogo = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
		<path
			d="M17.0974 15.3202C16.5953 15.3202 16.1867 14.9114 16.1867 14.4092C16.1867 13.907 16.5953 13.4985 17.0974 13.4985C17.5997 13.4985 18.0081 13.907 18.0081 14.4092C18.0081 14.9114 17.5997 15.3202 17.0974 15.3202ZM7.03098 15.3202C6.52876 15.3202 6.12028 14.9114 6.12028 14.4092C6.12028 13.907 6.52876 13.4985 7.03098 13.4985C7.53317 13.4985 7.94167 13.907 7.94167 14.4092C7.94167 14.9114 7.53317 15.3202 7.03098 15.3202ZM17.4241 9.83411L19.2443 6.68169C19.3486 6.50049 19.2866 6.26905 19.1057 6.16441C18.9248 6.06007 18.693 6.12214 18.5884 6.30304L16.7454 9.49537C15.3361 8.85217 13.7532 8.49392 12.0642 8.49392C10.3752 8.49392 8.79237 8.85217 7.38301 9.49537L5.54004 6.30304C5.4354 6.12214 5.20366 6.06007 5.02276 6.16441C4.84186 6.26905 4.77949 6.50049 4.88413 6.68169L6.70434 9.83411C3.57882 11.534 1.44114 14.6983 1.12842 18.4366H23C22.687 14.6983 20.5493 11.534 17.4241 9.83411Z"
			fill="currentColor"
		/>
	</svg>
);
const Message = ({ status, issues = [] }) => {
	let color;
	let Icon;
	let displayMessage;
	switch (status) {
		case 'error':
			color = 'red';
			Icon = XCircleIcon;
			displayMessage = 'App build failed';
			break;
		case 'success':
			color = 'green';
			Icon = CheckCircleIcon;
			displayMessage = 'Your app is ready.';
			break;
		case 'warning':
			color = 'yellow';
			Icon = ExclamationIcon;
			displayMessage = 'App building not started';
			break;
		case 'building':
			color = 'blue';
			Icon = CogIcon;
			displayMessage = 'Your app is Building. ETA 5min';
			break;
		default:
			color = 'blue';
			Icon = InformationCircleIcon;
			displayMessage = 'Your app is Building...';
			break;
	}
	return (
		<div className={`rounded-md bg-${color}-50 p-4 mt-4`}>
			<div className="flex items-center gap-3">
				<Icon
					className={`h-8 w-8 text-${color}-400 ${status === 'building' && 'animate-spin-slow text-blue-900'
						}`}
					aria-hidden="true"
				/>
				<h3 className={`text-base font-medium text-${color}-800`}>
					{displayMessage}
				</h3>
			</div>
			{Array.isArray(issues) && issues.length > 0 && (
				<div className="ml-10">
					<div className={`mt-2 text-sm text-${color}-700`}>
						<ul className="list-disc pl-5 space-y-1">
							{issues.map((item) => (
								<li>{item}</li>
							))}
						</ul>
					</div>
					<div className="mt-4">
						<div className="-mx-2 -my-1.5 flex gap-3">
							{/* <button
								type="button"
								className={`bg-${color}-50 px-2 py-1.5 rounded-md text-sm font-medium text-${color}-800 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
							>
								Fix issues
							</button> */}
							<button
								type="button"
								className={`bg-${color}-50 px-2 py-1.5 rounded-md text-sm font-medium text-${color}-800 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
							>
								Contact Support
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};