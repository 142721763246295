import React, { useEffect } from 'react';
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'clsx';

export function SwitchItem({ onChange, properties = {}, value, defaultValue }) {
	const {
		name,
		label,
		className,
		description,
		defaultValue: initial,
	} = properties;
	
	const [enabled, setEnabled] = useState(false)
	useEffect(() => {
		onChange(enabled);
	}, [enabled]);
	useEffect(() => {
		let temp=true;
		if (defaultValue || value) {
			temp = false;
			setEnabled(defaultValue || value);
		}
		if(defaultValue === undefined && temp){
			setEnabled(initial ?? false)
		}
	}, []);
	
	return (
		<div className={className ? className : 'mt-4'}>
			<Switch.Group as="div" className="flex items-center mt-1 space-x-3">
				<Switch
					checked={enabled}
					onChange={setEnabled}
					className={classNames(
						enabled ? 'bg-indigo-600' : 'bg-gray-200',
						'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					)}
				>
					<span
						aria-hidden="true"
						className={classNames(
							enabled ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
						)}
					/>
				</Switch>
				<span className="flex flex-grow flex-col">
					<Switch.Label
						as="span"
						className="text-sm font-medium text-gray-900"
						passive
					>
						{label}
					</Switch.Label>
					{description ? (
						<Switch.Description
							as="span"
							className="text-xs text-gray-500 line-clamp-2"
						>
							{description}
						</Switch.Description>
					) : null}
				</span>
			</Switch.Group>
		</div>
	);
}
