import { useState, useEffect } from 'react';
import { useProject } from '@appmaker/core/hooks';
import api from '../api';
import { setDataSource, useDataSource } from '../store'

const useInstalledDataSourceList = ({ refetch = true } = {}) => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    const setValue = useDataSource(data => data.setInstalledValue);
    const list = useDataSource(data => data.installedList);
    // useDataSourceList

    useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            try {
                const response = await api.getActiveList({ projectId })
                const { dataSources = [] } = response;
                const data = dataSources.map(dataSource => {
                    return {
                        ...dataSource,
                        id: dataSource.id,
                        dataSourceId: dataSource.dataSourceId,
                        name: dataSource.name,
                        category: 'UI Kit',
                        href: '#',
                        price: dataSource.price === 0 ? 'Free' : `$ ${dataSource.price}`,
                        formSchema: dataSource.schema,
                        imageSrc: dataSource.image,
                        imageAlt: dataSource.description,
                        status: dataSource.status,
                        dataSourceName: dataSource.dataSourceName,
                        installedMeta: dataSource.installedMeta
                    }
                })
                setDataSource({ installedList: data, setInstalledValue: true });
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
        if (!setValue || refetch) {
            fetchList()
        }
    }, [])

    return {
        isLoading,
        list
    }
}


export {
    useInstalledDataSourceList
}