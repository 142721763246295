import create from 'zustand';
import createContext from 'zustand/context';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import { devtools } from 'zustand/middleware';
// import {  } from "zustand/middleware"

export const { Provider: SimpleBlockEditorProvider, useStore: useSimpleBlockStore } = createContext();
const toValues = (blocks, text = '') => {
	const valuesArray = blocks.map((item) => item.attributes.value);
	// const
	return valuesArray.join(' ') + text;
};
export const createStore =
	({ blocks = [], value = '' }) =>
		() =>
			create(

				devtools((set, get) => ({
					blocks,
					value,
					insertBlock: (block) => {
						set(
							produce((state) => {
								state.blocks.push({ clientId: uuidv4(), ...block });
								console.log(toValues(state.blocks));
								state.value = toValues(state.blocks);
							})
						);
					},
					removeLastBlock: () => {
						set(
							produce((state) => {
								state.blocks.pop();
								state.value = toValues(state.blocks);
							})
						);
					},
					deleteBlock: (clientId) => {
						set(
							produce((state) => {
								state.blocks = state.blocks.filter(
									(item) => item.clientId !== clientId
								);
								state.value = toValues(state.blocks);
							})
						);
					},
					getLastBlock: () => {
						return get().blocks[get().blocks.length - 1];
					},
					setBlockAttributes: (clientId, attributes) => {
						set(
							produce((state) => {
								const blockIndex = state.blocks.findIndex(
									(item) => item.clientId === clientId
								);
								state.blocks[blockIndex].attributes = { ...state.blocks[blockIndex].attributes, ...attributes };
								state.value = toValues(state.blocks);
							})
						);
					},
					getBlockAttribute: (clientId, attribute) => {
						const blockIndex = get().blocks.findIndex(
							(item) => item.clientId === clientId
						);
						return get().blocks[blockIndex].attributes[attribute];
					},

					// _currentValues: ""
					currentInputValue: '',
					setCurrentInputValue: (text) => {
						set(
							produce((state) => {
								state.currentInputValue = text;
								state.value = toValues(state.blocks, text);
							})
						);
					},
				})
				),
			);
