import { InnerBlocks } from '@wordpress/block-editor';
import React from 'react';

const BLOCKS_TEMPLATE = [
	[
		'appmaker/shopify-product-list-slot',
		{ slotName: 'Header Right', slotId: 'top-right' },
	],
	[
		'appmaker/shopify-product-list-slot',
		{ slotName: 'Header Left', slotId: 'top-left' },
	],
	[
		'appmaker/shopify-product-list-slot',
		{ slotName: 'Footer Right', slotId: 'bottom-right' },
	],
	[
		'appmaker/shopify-product-list-slot',
		{ slotName: 'Footer left', slotId: 'bottom-left' },
	],
	[
		'appmaker/shopify-product-list-slot',
		{ slotName: 'Below title', slotId: 'above-title' },
	],
	[
		'appmaker/shopify-product-list-slot',
		{ slotName: 'Below title', slotId: 'below-title' },
	],
];
export function ProductListSlots({ attributes, setAttributes }) {
	return (
		<div className="bg-gray-50 m-1 rounded overflow-hidden">
			<div className="bg-gray-100 px-2 py-1 text-sm font-medium">
				Slot : {attributes.slotName}
			</div>
			<InnerBlocks allowedBlocks={['appmaker/badge', 'appmaker/text']} />
		</div>
	);
}
ProductListSlots.attributesSchema = {
	properties: {
		slotName: {
			type: 'string',
			label: 'Slot Name',
			source: 'attribute',
		},
		slotId: {
			type: 'string',
			label: 'Slot Id',
			source: 'attribute',
		},
	},
};
export default function ShopifyProductList() {
	return (
		<div>
			<InnerBlocks
				template={BLOCKS_TEMPLATE}
				allowedBlocks={['appmaker/shopify-product-list-slot']}
				// templateLock="all"
				// allowedBlocks={['appmaker/badge']}
			/>
		</div>
	);
}
