import {
	ArrowLeftIcon,
} from '@heroicons/react/outline';
import EnquiryForm from './EnquiryForm';
import { useParams } from '../hooks';



const HireAgency = () => {
	const [, setParm] = useParams();
	return (
		<div className="grid grid-cols-5">
			<div className="bg-gray-100 hidden sm:col-span-2 sm:flex sm:justify-end sm:items-end">
				<img src="/images/misc/welcome-image.png" alt="info" />
			</div>
			<div className="bg-white p-6 flex flex-col col-span-full sm:col-span-3">
				<div className="flex items-center space-x-2 mb-10">
					<button className="hover:bg-gray-100 p-2 rounded-lg" onClick={() => setParm('initial')}>
						<ArrowLeftIcon className="h-6 w-6" />
					</button>
					<h3 className="text-2xl font-medium">Hire an agency</h3>
				</div>
				<EnquiryForm enquiryType='agency'/>
			</div>
		</div>
	);
}

export default HireAgency;