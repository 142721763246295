import { useMenu, setMenuData, setLoading } from '../store/menu-store';
import { useEffect } from 'react';
import menuApi from '../api';
import { useProject } from '@appmaker/core/store/project';

const Menu = () => {
    const { isLoading, menuData } = useMenu();
    const { projectId } = useProject();
    const fetchMenuData = async () => {
        try {
            setLoading(true)
            const response = await menuApi.getMenu(projectId);
            setMenuData(response);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        fetchMenuData();
    }, []);
    if (isLoading) {
        return <div>Loading...</div>
    }
    return <div>
        <pre>{JSON.stringify(menuData, null, 2)}</pre>
    </div>
}

export default Menu;