import { InnerBlocks } from '@wordpress/block-editor';

const RadioBlockEdit = ({ attributes = {} }) => {
	return (
		<div className="py-2">
			<InnerBlocks allowedBlocks={['appmaker/form-radio-item']} />
		</div>
	);
};

const RadioBlockView = ({ attributes = {}, children }) => {
	return <div className="py-2">{children}</div>;
};
RadioBlockEdit.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			default: 'name',
			label: 'Name',
		}
	},
};
export { RadioBlockView };
export default RadioBlockEdit;
