/**
 * WordPress Dependencies
 */

// import  from '@wordpress/i18n';
import React from 'react';
import { addFilter } from '@wordpress/hooks';
import { Fragment } from '@wordpress/element';
// import { InspectorControls } from '@wordpress/block-editor';
import { createHigherOrderComponent } from '@wordpress/compose';
// import { createSlotFill, PanelBody } from '@wordpress/components';
// imp1
// import { Form as DsForm } from "components/datasource";
// import ActionSelector from '../components/ActionSelector';
import { ActionFill } from '../components/SlotFills';
import ActionSelector from '../components/BlockEditor/components/BlockInspector/tabs/components/ActionSelector';


const withAdvancedControls = createHigherOrderComponent((BlockEdit) => {
    return (props) => {
        const { attributes, setAttributes, isSelected } = props;
        return (
            <Fragment>
                {isSelected && (
                    <>
                        <ActionFill>
                            <ActionSelector
                                action={{ formData: attributes?.appmakerAction, _internal: attributes?.___internal_appmakerAction }}
                                setAction={({ formData, _internal }) => {
                                    setAttributes({ appmakerAction: formData, ___internal_appmakerAction: _internal });
                                }}
                            />
                        </ActionFill>
                    </>
                )}

                <BlockEdit {...props} />
            </Fragment>
        );
    };
}, 'withAdvancedControls');

addFilter(
    'editor.BlockEdit',
    'editorskit/custom-advanced-control',
    withAdvancedControls
);
