import { ReceiptTaxIcon } from '@heroicons/react/outline';

const CartCouponComponent = () => {
	return (
		<div className="p-4 bg-white flex items-center justify-between">
			<div className="flex items-center space-x-2">
				<ReceiptTaxIcon className="h-10 w-10 p-2 rounded-full bg-blue-100 text-blue-600" />
				<p>Got a coupon?</p>
			</div>
			<p className="text-blue-600 text-sm font-medium">Apply Coupon</p>
		</div>
	);
};

export default CartCouponComponent;
