import {
    createStore,
    AppmakerFormProvider,
    useStore
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useEffect } from 'react';




const FormSchema = ({ schema, onChange = () => { } }) => {
    const storeData = useStore();

    useEffect(() => {
        onChange(storeData.formData)
    }, [storeData.formData])

    return (
        <div className="bg-gray-50 h-full p-6">
            <div className="max-w-3xl">
                <AppmakerForm
                    formVersion={2}
                    // value={{ formData: flatten(formData) }}
                    schema={schema}
                />
            </div>
        </div>
    );
}


const EditForm = ({
    formValue = {},
    schema = {},
    onChange = () => { }
}) => {
    const store = createStore({
        formData: formValue,
    })
    return (
        <AppmakerFormProvider
            createStore={store}
        >
            <FormSchema
                schema={schema}
                onChange={onChange}
            />
        </AppmakerFormProvider>
    )
}

export default EditForm;