import { entity, persistence } from 'simpler-state'
import coreApi from '@appmaker/core/api/core';
export const formSchema = entity({})
export const useLanguageSchema = (args) => formSchema.use(args)
export const setLanguageSchema = data => {
    formSchema.set(data)
}

export const formData = entity({});
export const useFormData = (args) => formData.use(args);
export const setFormData = data => { formData.set(data) };
export const syncLanguageFormData = async (projectId) => {
    await coreApi.saveLanguageValues(projectId, formData.get());
    console.log(formData.get(), projectId);

}
