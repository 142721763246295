import React from 'react';
import { InspectorControls } from '@wordpress/block-editor';

const CoreMenu = ({ attributes, setAttributes }) => {
	const { menuType } = attributes;
	return (
		<div className="p-4 bg-gray-200 text-center">
			<h1 className="font-medium text-md text-gray-800">Core Menu</h1>
			<p className="text-xs capitalize">
				{menuType ? `Selected: ${menuType}` : 'Select a menu'}
			</p>
			<InspectorControls>
				<div className="mb-4">
					<label
						htmlFor="menuType"
						className="text-sm font-medium text-gray-800"
					>
						Menu type
					</label>
					<select
						id="menuType"
						value={menuType}
						className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						onChange={(e) => setAttributes({ menuType: e.target.value })}
					>
						<option value="">----</option>
						<option value="default">Default</option>
						<option value="collapsible">Collapsible</option>
					</select>
				</div>
			</InspectorControls>
		</div>
	);
};

export default CoreMenu;
