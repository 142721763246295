import { InformationCircleIcon } from '@heroicons/react/outline';

const SezzleWidget = ({ attributes = {} }) => {
	const { text, imageSrc, extraText } = attributes;
	return (
		<div className="bg-white px-3">
			<div className="flex items-center flex-wrap space-x-1">
				<p className="text-sm text-gray-800">{text}</p>
				<img src={'https://images.ctfassets.net/6d085vujy22q/7mOLuxyNMRaCcqYYyVn2UI/1738ec8e66515e71fb420c3e5c496f98/Sezzle_Logo_FullColor.png?w=798&h=200&q=50&fm=png'} className="h-4" alt="" />
				<InformationCircleIcon className="h-3 w-3" />
			</div>
			{extraText && <p className="text-xs text-gray-500 mt-1">{extraText}</p>}
		</div>
	);
};

SezzleWidget.attributesSchema = {
	type: 'object',
	properties: {
		totalPrice: { type: 'string', label: 'totalPrice', default: '' },
		merchant_uuid: { type: 'string', label: 'merchant_uuid', default: '' },
	},
};

export default SezzleWidget;
