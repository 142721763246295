const OrderHeader = ({ attributes }) => {
	const {
		orderId,
		numberOfItems,
		appmakerAction,
		canCancelOrder,
		topNotice,
		can_repeat_order = false,
		status,
		orderDate,
		paymentMethod,
		orderFulfillment,
		pageDispatch,
	} = attributes;
	return (
		<div className="bg-green-100 p-2">
			<p className="font-medium uppercase text-green-800">Order Status</p>
			<p className="text-sm">
				Order Id: #{orderId} ({numberOfItems} Items)
			</p>
			<p className="text-xs">Order placed on {orderDate}</p>
			<p>Payment status {paymentMethod}</p>
			<div className="flex flex-col space-y-2 mt-2">
				<button className="bg-gray-900 text-sm text-white w-full rounded p-1">
					Track
				</button>
				<button className="bg-gray-900 text-sm text-white w-full rounded p-1">
					Cancel Order
				</button>
			</div>
		</div>
	);
};

OrderHeader.attributesSchema = {
	type: 'object',
	properties: {
		data: {
			type: 'string',
			label: 'data',
			default: '',
		},
		orderId: {
			type: 'string',
			label: 'orderId',
			default: '',
		},
		orderDate: {
			type: 'string',
			label: 'orderDate',
			default: '',
		},
		topNotice: {
			type: 'string',
			label: 'topNotice',
			default: '',
		},
		can_repeat_order: {
			type: 'string',
			label: 'can_repeat_order',
			default: '',
		},
		status: {
			type: 'string',
			label: 'status',
			default: '',
		},
		paymentMethod: {
			type: 'string',
			label: 'paymentMethod',
			default: '',
		},
		orderFulfillment: {
			type: 'string',
			label: 'orderFulfillment',
			default: '',
		},
		numberOfItems: {
			type: 'string',
			label: 'numberOfItems',
			default: '',
		},
		canCancelOrder: {
			type: 'string',
			label: 'canCancelOrder',
			default: '',
		},
	},
};

export default OrderHeader;
