import { LinkIcon } from '@heroicons/react/outline';
import { RangeControl } from '@wordpress/components';
import clsx from 'clsx';
import React from 'react';

export default function MarginRangeControl({ attributes, setAttributes }) {
	const [linked, setLinked] = React.useState(true);
	const {
		marginLeft = 0,
		marginRight = 0,
		marginTop = 0,
		marginBottom = 0,
	} = attributes;
	return (
		<div className="mt-4">
			<div className="flex items-center justify-between">
				<h4 className="text-sm mb-2 text-gray-800">Margin</h4>
				<button
					className={clsx(
						'p-1 border rounded',
						linked
							? 'text-blue-600 border-blue-600'
							: 'border-gray-400 text-gray-400'
					)}
					onClick={() => setLinked(!linked)}
				>
					<LinkIcon className="h-5 w-5 " />
				</button>
			</div>
			<div className="flex flex-col ml-2">
				{!linked ? (
					<>
						<RangeControl
							label="Top"
							value={marginTop}
							onChange={(marginTop) => setAttributes({ marginTop })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Right"
							value={marginRight}
							onChange={(marginRight) => setAttributes({ marginRight })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Bottom"
							value={marginBottom}
							onChange={(marginBottom) => setAttributes({ marginBottom })}
							min={0}
							max={100}
						/>
						<RangeControl
							label="Left"
							value={marginLeft}
							onChange={(marginLeft) => setAttributes({ marginLeft })}
							min={0}
							max={100}
						/>
					</>
				) : (
					<RangeControl
						className="mt-3"
						value={marginTop}
						onChange={(marginTop) =>
							setAttributes({
								marginTop,
								marginRight: marginTop,
								marginBottom: marginTop,
								marginLeft: marginTop,
							})
						}
						min={0}
						max={100}
					/>
				)}
			</div>
		</div>
	);
}
