import {
    CheckCircleIcon,
    XCircleIcon,
} from '@heroicons/react/solid';
import LoadingIcon from './LoadingIcon';

const LabelGenerator = ({ name, status, loading }) => {
    return <>
        <li key={name}>
            {{
                complete: (
                    <span className="group">
                        <span className="flex items-start">
                            <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                                <CheckCircleIcon
                                    className="h-full w-full text-green-600 group-hover:text-green-800"
                                    aria-hidden="true"
                                />

                            </span>
                            <span className="ml-3 text-sm font-medium text-green-500 group-hover:text-gray-900">
                                {name}
                            </span>
                        </span>
                    </span>
                ),
                success: (
                    <span className="group">
                        <span className="flex items-start">
                            <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                                <CheckCircleIcon
                                    className="h-full w-full text-green-600 group-hover:text-green-800"
                                    aria-hidden="true"
                                />

                            </span>
                            <span className="ml-3 text-sm font-medium text-green-500 group-hover:text-gray-900">
                                {name}
                            </span>
                        </span>
                    </span>
                ),
                current: (
                    <span className="flex items-start" aria-current="step">
                        <span
                            className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                            aria-hidden="true"
                        >
                            <span className="absolute h-4 w-4 rounded-full bg-blue-200" />
                            <span className="relative block w-2 h-2 bg-blue-600 rounded-full" />
                        </span>
                        <span className="ml-3 text-sm font-medium text-blue-600">
                            {name}
                        </span>
                    </span>
                ),
                failed: (
                    <span className="group">
                        <span className="flex items-start">
                            {loading ? <span
                                className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                                aria-hidden="true"
                            >
                                <LoadingIcon className="-ml-1 mr-2 h-10 w-10 animate-spin" />
                            </span> : <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">

                                <XCircleIcon
                                    className="h-full w-full text-red-600 group-hover:text-red-800"
                                    aria-hidden="true"
                                />
                            </span>}
                            <span className="ml-3 text-sm font-medium text-red-500 group-hover:text-red-900 ">
                                {name}
                            </span>
                        </span>
                    </span>
                ),
                loading: (
                    <span className="flex items-start" aria-current="step">
                        <span
                            className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                            aria-hidden="true"
                        >
                            <LoadingIcon className="-ml-1 mr-2 h-10 w-10 animate-spin" />
                        </span>
                        <span className="ml-3 text-sm font-medium text-blue-600">
                            {name}
                        </span>
                    </span>
                ),

            }[status] || (
                    <span className="group">
                        <div className="flex items-start">
                            <div
                                className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                                aria-hidden="true"
                            >
                                <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                            </div>
                            <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                {name}
                            </p>
                        </div>
                    </span>
                )}
        </li>
    </>
}

export default LabelGenerator;