import { useState, useRef } from 'react';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import * as yup from 'yup';
import { getProjectId } from '@appmaker/core/store/project';
import api from '../api';
import { useParams } from '../hooks';
import appmaker from '@appmaker/core/index';

const options = [
    {
        value: 'email',
        name: 'Email',
    },
    {
        value: 'phone',
        name: 'Phone',
        description: ({ onChange = () => { } }) => (
            <input
                type="text"
                className="py-2 px-3 block w-full focus:ring-blue-600 focus:border-blue-600 border border-gray-500 rounded-md mt-2"
                name="phone"
                placeholder="Phone Number"
                onChange={(e) => onChange(e.target.value)}
            />
        ),
    },
    {
        value: 'skype',
        name: 'Skype',
        description: ({ onChange = () => { } }) => (
            <input
                type="text"
                className="py-2 px-3 block w-full focus:ring-blue-600 focus:border-blue-600 border border-gray-500 rounded-md mt-2"
                name="skype"
                placeholder="Skype"
                onChange={(e) => onChange(e.target.value)}
            />
        ),
    },
    {
        value: 'whatsapp',
        name: 'WhatsApp',
        description: ({ onChange = () => { } }) => (
            <input
                type="text"
                className="py-2 px-3 block w-full focus:ring-blue-600 focus:border-blue-600 border border-gray-500 rounded-md mt-2"
                name="whatsapp"
                placeholder="Whatsapp Number"
                onChange={(e) => onChange(e.target.value)}
            />
        ),
    },
];

function Options(props) {
    const { register = () => { } } = props;
    const [selected, setSelected] = useState(options[0].value);
    const [value, setValue] = useState({});


    const onChangeDependent = (changeValue) => {
        setValue({
            ...value,
            [selected]: changeValue,
            currentValue: changeValue
        });
        register({
            preferredContact: selected,
            [selected]: changeValue,
            currentValue: changeValue
        });
    };
    return (
        <RadioGroup
            value={selected}
            onChange={(value) => {
                setSelected(value);
                register({
                    preferredContact: value,
                });
            }}
        >
            <RadioGroup.Label className="sr-only">
                Preferred contact method
            </RadioGroup.Label>
            <div className="bg-white rounded-md -space-y-px">
                {options.map((setting, settingIdx) => (
                    <RadioGroup.Option
                        key={setting.name}
                        value={setting.value}
                        className={({ checked }) =>
                            clsx(
                                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                settingIdx === options.length - 1
                                    ? 'rounded-bl-md rounded-br-md'
                                    : '',
                                checked ? 'bg-blue-50 border-blue-500 z-10' : 'border-gray-200',
                                'relative border p-4 flex cursor-pointer focus:outline-none'
                            )
                        }
                    >
                        {({ active, checked }) => (
                            <>
                                <span
                                    className={clsx(
                                        checked
                                            ? 'bg-blue-600 border-transparent'
                                            : 'bg-white border-gray-300',
                                        active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                                        'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                    )}
                                    aria-hidden="true"
                                >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div className="ml-3 flex flex-col flex-auto">
                                    <RadioGroup.Label
                                        as="span"
                                        className={clsx(
                                            checked ? 'text-blue-600' : 'text-gray-800',
                                            'block text-sm font-medium'
                                        )}
                                    >
                                        {setting.name}
                                    </RadioGroup.Label>
                                    {checked && setting.description ? (
                                        <RadioGroup.Description as="div">
                                            <setting.description onChange={onChangeDependent} />
                                        </RadioGroup.Description>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    );
}

const EnquiryForm = ({ enquiryType = 'diy' }) => {
    const [preferredContact, setPreferredContact] = useState({});
    const [isSaving, setSaving] = useState(false);

    const [, setParm] = useParams();
    const projectId = getProjectId();

    const name = useRef('');
    const email = useRef('');



    const formSubmit = async (event) => {
        setSaving(true);
        event.preventDefault();
        const schema = yup.object().shape({
            project_id1: yup.string(),
            enquiry_type: yup.string(),
            f_name: yup.string().required("Name is required"),
            email1: yup.string().email().required('Email is required'),
            enquiry_type1: yup.string().default('email'),
            contact: yup.string()
        })
        try {
            const validateData = await schema.validate({
                project_id1: projectId.toString(),
                enquiry_type: enquiryType,
                f_name: name.current,
                email1: email.current,
                enquiry_type1: preferredContact.preferredContact,
                contact: preferredContact.currentValue
            }, {
                abortEarly: false
            })
            await api.submitEnquiryForm(validateData)

            appmaker.doAction('appmaker-analytics-event', {
                event: `getting_started_form_dashboard_${validateData.enquiry_type}_submitted`,
                params: {
                    projectId,
                    insideShopify: !!window.shopifyApp
                }
            });

            setParm('completed')
        } catch (error) {
            console.log(error.errors)
        }
        setSaving(false);

    }
    return (
        <form className="flex flex-col space-y-3" onSubmit={formSubmit}>
            <div>
                <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                >
                    Full Name
                </label>
                <div className="mt-1">
                    <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md px-3 py-2"
                        placeholder="John Smith"
                        required
                        onChange={event => { name.current = event.target.value }}
                    />
                </div>
            </div>
            <div>
                <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                >
                    Email
                </label>
                <div className="mt-1">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md px-3 py-2"
                        placeholder="you@example.com"
                        required
                        onChange={event => { email.current = event.target.value }}
                    />
                </div>
            </div>
            <div>
                <span className="block text-sm text-gray-800 font-medium mb-1">
                    Preferred method of contact
                </span>
                <Options register={setPreferredContact} />
            </div>
            <button
                type="submit"
                disabled={isSaving}
                className="inline-flex items-center mt-10 self-end px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                {isSaving ? 'Loading...' : 'Submit'}
            </button>
        </form>
    )
}

export default EnquiryForm