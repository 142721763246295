import { useState } from 'react';
import { RangeControl } from '@wordpress/components';
import { LinkIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

export default function LinkedRangeControl({
	attributes,
	setAttributes,
	config,
	defaultValue,
}) {
	const [linked, setLinked] = useState(true);

	const handleChange = (name, newValue) => {
		if (linked) {
			const newAttributes = { [name]: newValue };
			setAttributes(newAttributes);
		} else {
			setAttributes({ ...attributes, [name]: newValue });
		}
	};

	return (
		<div className="mt-4">
			<div className="flex items-center justify-between">
				<h4 className="text-sm mb-2 text-gray-800">{config.label}</h4>
				<button
					className={clsx(
						'p-0.5 border rounded',
						linked
							? 'text-blue-600 border-blue-600'
							: 'border-gray-400 text-gray-400'
					)}
					onClick={() => setLinked(!linked)}
				>
					<LinkIcon className="h-5 w-5" />
				</button>
			</div>
			<div
				className={clsx(
					'flex flex-col',
					!linked
						? 'pl-3 border-l-2 border-gray-200 rounded-lg pt-1'
						: 'mt-1 pl-2'
				)}
			>
				{linked ? (
					<RangeControl
						name={config.property}
						value={defaultValue?.[config.property] || 0}
						onChange={(newValue) => handleChange(config.property, newValue)}
						min={config.min}
						max={config.max}
					/>
				) : (
					config.sides.map((side, index) => (
						<RangeControl
							key={index}
							name={config.property + '-' + side}
							label={config.labels[index]}
							value={defaultValue?.[side] || 0}
							onChange={(newValue) => handleChange([side], newValue)}
							min={config.min}
							max={config.max}
						/>
					))
				)}
			</div>
		</div>
	);
}
