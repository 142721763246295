import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [{
	"clientId": "06341346-590d-46c3-9559-b92057297d58",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_SEARCH"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_SEARCH",
				"label": "Open search page"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_8e3b30d1-b419-4480-beef-3e6805f1638d.png?v=1662033281",
			"id": "gid://shopify/MediaImage/31330159395073",
			"title": "a.png"
		},
		"imageContainerStyle": {
			"height": 218,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 218,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "93c65e49-a8d8-4ab7-bbbb-3511986ff3eb",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-1_868a5950-8a5d-4d74-873b-9c9f86e2c163.png?v=1662033294",
			"id": "gid://shopify/MediaImage/31330160509185",
			"title": "a-1.png"
		},
		"imageContainerStyle": {
			"height": 1734,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 1734,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "0188b256-de8e-4f0e-8a3e-8c1975a5edd8",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_8d1e3676-c304-44c1-a264-366a87de7042.png?v=1662033326",
			"id": "gid://shopify/MediaImage/31330162671873",
			"title": "b.png"
		},
		"imageContainerStyle": {
			"height": 176,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 176,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "e0777dc2-cfc3-42bd-a5d6-90ce381546ff",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 3,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"enableSplit": false,
		"enableGap": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "de9feb8f-56b2-43d9-a38e-6e19a19550c3",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-1_2cda65c6-fc3d-48c8-92c7-ac63ade56c7c.png?v=1663311900",
				"id": "gid://shopify/MediaImage/31439187542273",
				"title": "b-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 733,
				"width": 360
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {},
			"thumbnail_meta": {
				"height": 733,
				"width": 360
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "8f19926f-335b-40e8-af4c-a744561a7600",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-2_2782b09e-94ec-4901-bf3d-69785ae6e34d.png?v=1663311891",
				"id": "gid://shopify/MediaImage/31439185772801",
				"title": "b-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 733,
				"width": 360
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {},
			"thumbnail_meta": {
				"height": 733,
				"width": 360
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "e22d5bd5-8213-406c-bc33-61f892ad6e91",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-3_908473d5-863f-4d9c-99c2-36312ef24d65.png?v=1663311880",
				"id": "gid://shopify/MediaImage/31439184396545",
				"title": "b-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 733,
				"width": 360
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {},
			"thumbnail_meta": {
				"height": 733,
				"width": 360
			}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "6c4b2961-57ec-4150-a6db-00f2a1da89b6",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_f4e91e9d-c4e1-4759-a9e5-559e25aa5876.png?v=1662033355",
			"id": "gid://shopify/MediaImage/31330165031169",
			"title": "c.png"
		},
		"imageContainerStyle": {
			"height": 1620,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 1620,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "5420c799-2f67-46ce-92a0-158abbc4adc5",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c-1_da955820-5d0f-46cf-9200-0fb8b0487ec2.png?v=1662033356",
			"id": "gid://shopify/MediaImage/31330165063937",
			"title": "c-1.png"
		},
		"imageContainerStyle": {
			"height": 1620,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 1620,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "d96bd4ca-35c6-4e2e-99bf-c27421c192e1",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d_89ddcd99-a67c-407b-bb68-98631332be00.png?v=1662033387",
			"id": "gid://shopify/MediaImage/31330167095553",
			"title": "d.png"
		},
		"imageContainerStyle": {
			"height": 721,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 721,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "2e9d1b28-ab64-4a01-8413-fd79075e3378",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e_6c73e8c2-f7d5-46f2-9f21-a1dd070990e7.png?v=1662033400",
			"id": "gid://shopify/MediaImage/31330167849217",
			"title": "e.png"
		},
		"imageContainerStyle": {
			"height": 176,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 176,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "39a0ee65-33fc-4166-8648-56825a8c7af5",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 3,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"enableSplit": false,
		"enableGap": false
	},
	"innerBlocks": [{
		"clientId": "ea964ff7-7dc2-4b27-8b95-e915982aad0f",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-2_85058c6d-a007-4f95-acab-c84175e644b4.png?v=1663311954",
				"id": "gid://shopify/MediaImage/31439193342209",
				"title": "e-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 733,
				"width": 360
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 733,
				"width": 360
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "f3a8a596-91c4-4c77-b12e-11c6efdeb00e",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-3_f8530d22-bf2e-4519-aebc-0ff1bc676f15.png?v=1663311944",
				"id": "gid://shopify/MediaImage/31439192064257",
				"title": "e-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 733,
				"width": 360
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 733,
				"width": 360
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "6f7e2c96-5705-4174-8496-f65b5e551412",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-4_cfa18b4c-e53d-4ec5-a6aa-01942c138a08.png?v=1663311925",
				"id": "gid://shopify/MediaImage/31439189934337",
				"title": "e-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 733,
				"width": 360
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 733,
				"width": 360
			}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "597db27e-6ff5-41c2-b331-d46da6f14fb7",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e-1_6f7b0785-0041-49cb-84a9-43e7984a9614.png?v=1662033525",
			"id": "gid://shopify/MediaImage/31330175058177",
			"title": "e-1.png"
		},
		"imageContainerStyle": {
			"height": 342,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 342,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "29c36905-00cd-413c-96b0-1b2a7e8943cf",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 4,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"enableSplit": false,
		"enableGap": false
	},
	"innerBlocks": [{
		"clientId": "977726b8-6d62-4da7-a7ad-5660259431bf",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_93b4851d-1043-4ab1-8811-bcbd001ce1b6.png?v=1663312006",
				"id": "gid://shopify/MediaImage/31439199764737",
				"title": "f.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "5994ad5e-ea38-4eb6-a68b-6d4ab70331c0",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1_f0649cc9-5d4b-43a7-8bb0-fd6c82e6fdf7.png?v=1663312014",
				"id": "gid://shopify/MediaImage/31439200387329",
				"title": "f-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "0a9eb350-85f0-41f6-b298-bd218f2d759a",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2_5f0d0e02-7273-4281-8c41-b58675aa9d81.png?v=1663312024",
				"id": "gid://shopify/MediaImage/31439202189569",
				"title": "f-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "3ed76693-9293-461f-9cee-1df318b41db8",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-3_6381d8da-2060-40d3-b59b-95d0f9261a44.png?v=1663312034",
				"id": "gid://shopify/MediaImage/31439203107073",
				"title": "f-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "f34ef188-3abe-4d0c-ae31-bf0c3c26cbfe",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-4_ac6796a5-37d3-4266-b31f-6366120c6137.png?v=1663312044",
				"id": "gid://shopify/MediaImage/31439204548865",
				"title": "f-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "b3ec3a53-441b-4b9a-949f-93cf744631ed",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-5_3fd55fe7-e301-4516-9dbd-f62e1b2232ce.png?v=1663312057",
				"id": "gid://shopify/MediaImage/31439205957889",
				"title": "f-5.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "07a299c5-80ff-46f7-9ad8-d4e14b59caaa",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-6_aca2bd1d-3856-4a15-9003-07458349de00.png?v=1663312077",
				"id": "gid://shopify/MediaImage/31439208546561",
				"title": "f-6.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "9a6b30c4-9a2e-453d-b24f-c7376e424f77",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-7_2d862dc7-ce71-4c53-9b53-fa6979fa1a8b.png?v=1663312089",
				"id": "gid://shopify/MediaImage/31439209398529",
				"title": "f-7.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "9f94dd3c-f7c3-427b-a130-00edeb5b9b8a",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-8_76321e23-a82c-45d5-9283-539c8442629a.png?v=1663312099",
				"id": "gid://shopify/MediaImage/31439211036929",
				"title": "f-8.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "4c2e7a11-ed5a-4158-bf4e-2d03ee8f3f5d",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-9_34634992-8f4c-4da9-b2e0-21f708ad0133.png?v=1663312110",
				"id": "gid://shopify/MediaImage/31439212675329",
				"title": "f-9.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "4446636b-1d0f-41a3-95cb-848da2481876",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-10_e1285af1-8b23-49c2-935d-6198be2cd5f7.png?v=1663312120",
				"id": "gid://shopify/MediaImage/31439213625601",
				"title": "f-10.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "b7f24c9f-5538-4d05-b032-38921fc0dddb",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-11_3eccf621-851c-4eed-8d86-b7893723bcbb.png?v=1663312131",
				"id": "gid://shopify/MediaImage/31439214477569",
				"title": "f-11.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 767,
				"width": 270
			},
			"showText": false,
			"thumbnail_meta": {
				"height": 767,
				"width": 270
			}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "55a71dfd-c8e4-40b8-9c59-4a17e0779f4e",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/F_7.png?v=1663310004",
			"id": "gid://shopify/MediaImage/31438972911873",
			"title": "F (7).png"
		},
		"imageContainerStyle": {
			"height": 342,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 342,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "caeaed9c-7f2f-433a-a101-f340660725e3",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false
	},
	"innerBlocks": [{
		"clientId": "acf8ce55-9dea-4bce-9fdf-0468fa7951cd",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_565ea750-6d9a-4c43-811a-8b8a5c45a238.png?v=1663310131",
				"id": "gid://shopify/MediaImage/31438989000961",
				"title": "g.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 1134,
				"width": 756
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "43fb3938-f58b-45b9-af57-8a64100b69b4",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-1_a994cc97-6813-495a-b864-53e3f6d4cb19.png?v=1663310129",
				"id": "gid://shopify/MediaImage/31438988673281",
				"title": "g-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 1134,
				"width": 756
			}
		},
		"innerBlocks": []
	}, {
		"clientId": "58728c58-39a6-41dc-ba25-cfcf0e0eeab8",
		"name": "appmaker/imagescroller-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g-2_8cd76126-e9c8-4eae-81ea-05b0652b5d42.png?v=1663310128",
				"id": "gid://shopify/MediaImage/31438988312833",
				"title": "g-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 1134,
				"width": 756
			}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "dbc5fc19-b8e0-451e-8eb6-7218d44a59fb",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h_164bfc4a-c69a-4666-b201-84f48707f010.png?v=1663310148",
			"id": "gid://shopify/MediaImage/31438990442753",
			"title": "h.png"
		},
		"imageContainerStyle": {
			"height": 1620,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 1620,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "2b617b30-9210-4c35-b334-0dac5d97f34b",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-1_a314ca81-bd75-4534-84ad-f940e79d4397.png?v=1663310149",
			"id": "gid://shopify/MediaImage/31438991327489",
			"title": "h-1.png"
		},
		"imageContainerStyle": {
			"height": 1620,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 1620,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "e420dab9-7007-4ff9-b8a2-d9ae36c1485c",
	"name": "appmaker/imagescroller",
	"isValid": true,
	"attributes": {
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false
	},
	"innerBlocks": []
}]
const template = {
	blocks,
	id: 'zaira',
	name: 'Zaira',
	category: 'Fashion',
	price: 'Free',
	imageSrc: '/images/themes/zaira.png',
	previewSrc: '/images/preview/templates/zaira.png',
	imageAlt: 'Mobile app Fashion theme',
	description: "Looking to build the next best Fashion app? Look no further! check out our carefully crafted Fashion template that will have you up and running in no time."
};
export default template;
