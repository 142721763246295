import { Link } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import clsx from 'clsx';
import { ChipIcon } from '@heroicons/react/solid';
import { useTransform } from '../hooks';

const List = ({ title }) => {
    const getRouterPath = useRouterPath();
    const { transformList = [] } = useTransform({ refetch: false });
    return (
        <div className="bg-gray-50">
            <div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4 ">
                    <h2 className="text-lg font-medium text-gray-900">{title}</h2>
                    {/* <a
                        href="#"
                        className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                        View all<span aria-hidden="true"> &rarr;</span>
                    </a> */}
                </div>
                <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
                    {transformList.map(transform => (
                        <Link
                            key={transform.id}
                            className={clsx(
                                'p-4 group rounded-xl border bg-white hover:shadow-sm relative border-gray-300 hover:border-blue-600')}
                            to={getRouterPath(`/transforms/edit/${transform.id}`)}
                        >
                            <div className="relative group flex items-center space-x-3">

                                <div className="aspect-square rounded-lg overflow-hidden  h-5 w-5">

                                    <ChipIcon className="object-center object-cover" />
                                </div>
                                <div>
                                    <h3 className="text-md font-medium group-hover:text-blue-600">
                                        {transform.name}
                                    </h3>
                                    <h4 className="text-xs font-bold text-gray-700 ">
                                        {transform.transformId}
                                    </h4>
                                </div>
                            </div>
                            <p className="mt-2 text-gray-500 text-sm line-clamp-3">
                                {transform.description}
                            </p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default List;