import React from 'react'
import { applyFilters } from '@wordpress/hooks';
import MediaTabs from './MediaTabs'

export default function MediaLibrary({ onSelect }) {
    const MediaLibraryFactory = applyFilters('appmaker-media-library-factory', [])
    return (
        <MediaTabs items={MediaLibraryFactory} onSelect={onSelect} />
    )
}
