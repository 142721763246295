import appmaker from '@appmaker/core/index';
import DeepLinkingHeader from './DeepLinkingHeader';
// import DeepLinkingBeforeSave from './DeepLinkingBeforeSave';

const DeepLinking = {
    id: 'deep-linking',
    name: 'Deep Linking',
    activate: () => {
        appmaker.addFilter('deep-linking-backend-settings-before-save-button', 'extension-override', (override, meta) => (<DeepLinkingHeader override={override} meta={meta} />))  
        // appmaker.addFilter('deep-linking-admin_settings-before-save-button', 'extension-override', (override, meta) => (<DeepLinkingBeforeSave override={override} meta={meta} />))
    }
}

export default DeepLinking;