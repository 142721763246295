import appmaker from '@appmaker/core/index';
import { DatabaseIcon } from '@heroicons/react/solid';
import { projectGraphqlApi } from '@appmaker/core/store/project-graphql';
import { InnerBlocks } from '@wordpress/block-editor';
import clsx from 'clsx';

function CustomBlocks(props) {
	const { name, imageUrl, previewImageUrl, config, title } = props;
	const { innerBlockConfig } = config ?? {};
	const { allowedBlocks } = innerBlockConfig ?? {};
	return (
		<div
			key={name}
			className={clsx(
				'relative border border-gray-300 bg-white shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500',
				previewImageUrl ? 'p-2' : 'px-6 py-5'
			)}
		>
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				{previewImageUrl ? (
					<img src={previewImageUrl} alt={name} />
				) : (
					<p className="text-sm font-medium text-gray-900">{title}</p>
				)}
				{innerBlockConfig &&
					Object.keys(innerBlockConfig).length > 0 &&
					allowedBlocks && (
						<>
							{allowedBlocks?.includes('*') ? (
								<InnerBlocks />
							) : (
								<InnerBlocks allowedBlocks={allowedBlocks} />
							)}
						</>
					)}
			</div>
		</div>
	);
}
// register blocks from db (themes and extensions)
const registerDbBlocks = () => {
	try {
		const { data } = projectGraphqlApi();
		const blocks = data?.blocks ?? [];
		blocks?.forEach((block) => {
			if (block.name) {
				appmaker?.registerAppmakerBlockType(`${block.name}`, {
					title: block?.title,
					icon: block?.imageUrl ? (
						<img
							src={block?.imageUrl}
							alt={block?.title}
							style={{ width: 18, height: 18, objectFit: 'contain' }}
						/>
					) : (
						<DatabaseIcon />
					),
					Block: CustomBlocks,
					customProps: {
						...block,
					},
					parent: block?.config?.parent,
				});
			}
		});
	} catch (error) {
		console.log(error);
	}
};

export { registerDbBlocks };
