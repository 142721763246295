import React from 'react';
import { BlockControls } from '@wordpress/block-editor';
import { Toolbar, ToolbarButton } from '@wordpress/components';

export default props => {
  const { action = false } = props;
  return (
    <BlockControls>
      <Toolbar
        // isCollapsed
        label="Delete"
        icon="dismiss"
        controls={[
          {
            label: 'Replace Image',
            title: `Replace Image`,
            icon: 'dismiss',
            isActive: false,
            onClick: () => {
              if (action !== false) action();
            }
          }
        ]}
      />
    </BlockControls>
  );
};
