import { registerBlockTypes } from './blocks';
import { registerShopifyCustomBlockTypes,registerShopifyCustomBlockTypesPublic } from './shopify-custom-blocks';
import { registerWebBlockTypes } from './web-blocks';
import { registerDbBlocks } from './register-db-blocks';
const EcommerceBlocks = {
	id: '@appmaker/e-commerce-blocks',
	name: 'E-commerce UI blocks',
	activate: () => {
		registerBlockTypes();
		registerShopifyCustomBlockTypesPublic();
		registerShopifyCustomBlockTypes();
		registerWebBlockTypes();
		registerDbBlocks();
	},
};

export default EcommerceBlocks;
