import { lazy, memo, useEffect, useState } from 'react';
import { getProjectData } from '@appmaker/core/store/project';
import Loader from '@appmaker/components/Loading';
import coreApi from '@appmaker/core/api/core';

const Card = lazy(() => import('packages/mobile-app/components/DownloadAppCards'));

const AppDownloadCard = () => {
    const [appBuildStatus, setAppBuildStatus] = useState();
    const [isLoading, setLoading] = useState(false);

    const { loading, projectId, projectData = {} } = getProjectData();
    const { apps = [] } = projectData;
    let buildCheckIntervalId = false;

    const getBuildStatus = async () => {
        const status = await coreApi.appBuildStatus(projectData.id);

        setAppBuildStatus(status.apps);
        if (status.buildInProgress < 1) {
            clearInterval(buildCheckIntervalId);
        }
    }

    const startBuildStatusCheck = async () => {
        setLoading(true);
        await getBuildStatus();
        setLoading(false);
        buildCheckIntervalId = setInterval(() => {
            getBuildStatus();
        }, 5000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(buildCheckIntervalId);
    }
    useEffect(() => {
        return startBuildStatusCheck();
    }, [projectId])

    if (loading || isLoading) {
        return <Loader />
    }

    return (
        <div className='m-2'>
            <Card apps={apps} appBuildStatus={appBuildStatus} />
        </div>
    )
}
export default memo(AppDownloadCard);