import { V3_BASE_URL } from '@appmaker/core/api/core';
import { useProject } from '@appmaker/core/hooks';
import axios from 'axios';

const MetaField = (props) => {
    // Send request to /extension-functions/meta-field-v2/make-meta-fields-public
    const { projectId } = useProject()


    function makeMetaFieldPublic() {
        console.log('makeMetaFieldsPublic', props);
        axios.post(`${V3_BASE_URL}/projects/${projectId}/extension-functions/meta-data-v2/make-meta-fields-public`, {}, {
            credentials: 'include',
            withCredentials: true,
        }).then((response) => {
            const { metafieldVisibilityAdded = [] } = response.data;
            alert(`${metafieldVisibilityAdded.length} metafields made public`);
            console.log(response);
        });

    }

    return (
        <button
            onClick={makeMetaFieldPublic}
            type="button"
            className="inline-flex justify-self-end bg-green-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
        >
           Make meta fields public
        </button>
    )
}

export default MetaField;