import axios from 'axios';
import { BASE_URL } from '@appmaker/core/api/core';

export const updateStage = async (projectId, eventName) => {
    try {
        // let url = `http://localhost:3000/v2/hubspot/update-stages`;
        let url = `${BASE_URL}/hubspot/update-stages`
        const response = await axios.post(url, {
            "projectId": projectId,
            "dealstage": eventName
        }, {
            credentials: 'include',
            withCredentials: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        return response.data;
    } catch (error) {
        return false;
    }
}

