import { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';

const AppmakerSwitch = ({ schema, formData, onChange }) => {
	const { title, label, className } = schema;
	// const { title = 'switch title', label = 'label' } = attributes;
	const [enabled, setEnabled] = useState(formData);
	useEffect(() => {
		if (onChange) onChange(enabled);
	}, [enabled, onChange]);
	return (
		<div className={className ? className : 'mt-4'}>
			<Switch.Group>
				<span className="font-regular text-sm mb-1 block">{title}</span>
				<div className="flex items-center gap-2 mb-2">
					<Switch
						checked={enabled}
						onChange={setEnabled}
						className={`${
							enabled ? 'bg-green-600' : 'bg-gray-200'
						} relative inline-flex items-center h-6 rounded-full w-12 transition-colors ease-in-out duration-200`}
					>
						<span
							className={`${
								enabled ? 'left-1 text-green-500' : 'right-1 text-gray-400'
							} absolute text-xs font-medium transition ease-in-out duration-200`}
						>
							{enabled ? 'ON' : 'OFF'}
						</span>
						<span
							className={`${
								enabled ? 'translate-x-7' : 'translate-x-1'
							} inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`}
						/>
					</Switch>
					<Switch.Label className="text-base font-medium cursor-pointer">
						{label}
					</Switch.Label>
				</div>
			</Switch.Group>
		</div>
	);
};

export default AppmakerSwitch;
