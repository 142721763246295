import React, { useEffect } from 'react';
import { useParams, useHistory } from '@appmaker/core/routes';
// import ColorPicker from './helper-component/ColorPicker';
import { useFormData } from '../store/formData';

import EditBlock from '../components/EditBlock';
// import AppmakerForm from '@appmaker/components/Form';
import coreApi from '@appmaker/core/api/core';
import { useProject } from '@appmaker/core/hooks';
import appmaker from '@appmaker/core/index';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { useLocalStorage } from 'react-use';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useStoreApi } from '@appmaker/core/components/AppmakerForm/store';
import { useAsyncFn } from 'react-use';
import { setPublicMeta } from '@appmaker/core/store/project';

function lastTabItem({ allTabs, tabId, ___isUpdateForm }) {
	return !(
		allTabs[allTabs.indexOf(tabId) + 1] === undefined ||
		(allTabs.indexOf(tabId) === allTabs.length - 2 && ___isUpdateForm)
	);
}

function migrateSchema({ schema, uiSchema }) {
	const newSchema = {};
	const { properties } = schema;
	const propertiesArray = Object.keys(properties);
	propertiesArray.map((item) => {
		newSchema[item] = { ...properties[item] };
		if (uiSchema[item] && uiSchema[item]['ui:field'])
			newSchema[item].uiType = uiSchema[item]['ui:field'];
		if (newSchema[item].properties) {
			newSchema[item] = {
				...newSchema[item],
				...migrateSchema({
					schema: properties[item],
					uiSchema: uiSchema[item],
				}),
			};
		}
	});
	return { properties: newSchema };
}
const Builder = ({ wholeSchema, basePath }) => {
	const [initialStepCompleted, setInitialStepCompleted] = useLocalStorage(
		'appmaker-initial-step-completed',
		false
	);
	const storeApi = useStoreApi();
	const routerPath = useRouterPath();
	const { tabId } = useParams();
	const allTabs = Object.keys(wholeSchema);
	const onNext = async ({ id }) => {
		await onClickSave();
	};
	// useEffect(() => {
	// 	if (wholeSchema) {

	// 		const steps = allTabs.map((tab, key) => {
	// 			return { title: wholeSchema[tab].title, id: tab, onNext, route: `/onboarding/initial-customize/${tab}`, order: key }
	// 		})
	// 		// const step = {
	// 		// 	id: 'intial-app-customize',
	// 		// 	title: "Customize App",
	// 		// 	path: '/onboarding/initial-customize/app-icon',
	// 		// 	steps: steps,

	// 		// }
	// 		steps.map(step => appmaker.doAction('register-onboarding-steps', step))
	// 	}
	// }, [wholeSchema])
	console.log(tabId, 'tabId');
	const history = useHistory();
	// const formData = useFormData();
	const { projectId } = useProject();
	const template = wholeSchema[tabId] || {};
	// const [isSaving, setSaving] = useState(false);
	const { schema = {}, uiSchema = {} } = template;
	const [{ loading: isSaving }, syncFormData] = useAsyncFn(
		async (skipBuild = true) => {
			const { formData } = storeApi.getState();
			const result = await coreApi.saveAppFormData(
				projectId,
				formData,
				skipBuild
			);
			return result;
		},
		[]
	);
	const ___isUpdateForm = false;
	const onClickSave = async () => {
		// console.log(lastTabItem({ allTabs, tabId, ___isUpdateForm: formData.___isUpdateForm }), { allTabs, tabId, ___isUpdateForm: formData.___isUpdateForm });
		// return
		setPublicMeta({
			[`step-${tabId}`]: 'complete',
			onboarding_completed: true,
		});
		appmaker.track('initial_next_step', {
			step: tabId,
		});
		// emitEvent('ap_event_track',{})
		setInitialStepCompleted(true);
		try {
			// setSaving(true);
			appmaker.doAction('initial_customize_saved', { projectId });
			console.log(
				lastTabItem({ allTabs, tabId, ___isUpdateForm: ___isUpdateForm }),
				allTabs[allTabs.indexOf(tabId) + 1],
				tabId,
				allTabs,
				'ssssss'
			);
			if (lastTabItem({ allTabs, tabId, ___isUpdateForm: ___isUpdateForm })) {
				await syncFormData();
				history.push(`${basePath}/${allTabs[allTabs.indexOf(tabId) + 1]}`);
			} else {
				await syncFormData(true);
				const onboardingNextPath = appmaker.applyFilters('onboarding-path');
				if (onboardingNextPath) history.push(routerPath(onboardingNextPath));
				else history.push(routerPath('/blockEditor/home?showTemplate=shopify'));
			}
		} catch (error) {
		} finally {
			// setSaving(false);
		}
	};
	async function onFileUpload(name, files) {
		const data = new FormData();
		data.append('file', files[0]);
		const fileUploadResponse = await coreApi.uploadFile(projectId, data, name);
		if (fileUploadResponse.status && fileUploadResponse.body) {
			const { fileName } = fileUploadResponse.body;
			// setFormData({ ...formData, [name]: fileName });
		}
		return {};
	}
	const appmakerSchema = migrateSchema({
		schema,
		uiSchema,
	});
	const uiOptions = template['ui:options'] || {};
	const { video } = uiOptions;
	return (
		<EditBlock
			title={template.title}
			description={template.description}
			onClickSave={onClickSave}
			template={template}
			isSaving={isSaving}
			video={video}
		>
			<AppmakerForm
				formVersion={2}
				// value={{ formData: flatten(formData) }}
				schema={appmakerSchema}
				onFileUpload={onFileUpload}
			/>
		</EditBlock>
	);
};

export default Builder;
