import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import createContext from 'zustand/context';
import { persist } from "zustand/middleware"

const createPageStateStore =
  ({ pageId }) =>
    () =>
      create(
        persist(
          devtools(
            (set,get) => ({
              blockData: {},
              pageId,
              bears: 0,
              getState: () => get(),
              setState: (state) => set(state),
              setBlockData: (blockData) =>
                set(
                  produce((state) => {
                    state.blockData = blockData;
                  }),
                ),
              setCurrentAction: (currentAction) =>
                set(
                  produce((state) => {
                    state.currentAction = currentAction;
                  }),
                ),
              setMetaData: (metaData) =>
                set(
                  produce((state) => {
                    state.metaData = metaData;
                  }),
                ),
              increasePopulation: () =>
                set((state) => ({ bears: state.bears + 1 })),
              removeAllBears: () => set({ bears: 0 }),
            }),
            { name: `pageState-${pageId}` },
          ), { name: `pageState-${pageId}` },
        ),
      );

const {
  Provider: PageStateProvider,
  useStore: usePageState,
  useStoreApi: usePageStateApi,
} = createContext();

export { createPageStateStore, PageStateProvider, usePageState, usePageStateApi };
