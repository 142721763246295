import appmaker from '@appmaker/core/index';
import { CogIcon } from '@heroicons/react/solid';
import Home from './routes/Home';

const Settings = {
	id: '@appmaker/settings',
	name: 'Settings',
	activate: () => {
		// appmaker.registerRoute({
		// 	routeName: '/settings/project',
		// 	component: null,
		// });
		appmaker.registerRoute({
			routeName: '/settings',
			component: Home,
		});
		appmaker.registerSideBarMenu({
			name: 'Settings',
			routeName: '/settings',
			icon: CogIcon,
		});
	},
};

export default Settings;
