// @flow strict

import clsx from 'clsx';
import React from 'react';

function TableCell({ attributes = {} }) {
	const { title, value, iconName, type, subTitle } = attributes;

	return (
		<div className="py-2 px-4 flex justify-between items-center border-gray-100 border-b">
			<div
				className={clsx(
					'flex flex-auto justify-between',
					type === 'stacked' ? 'flex-col' : 'null'
				)}
			>
				<div className="font-medium">
					<p className={type === 'total' ? 'text-base' : 'text-sm'}>{title}</p>
					<p className="text-xs text-gray-400">{subTitle}</p>
				</div>
				<div className={type === 'total' ? 'text-base font-bold' : 'text-sm'}>
					{value}
				</div>
			</div>
			{type === 'stacked' && iconName ? (
				<div className="p-1">{iconName}</div>
			) : null}
		</div>
	);
}

TableCell.attributesSchema = {
	type: 'object',
	properties: {
		title: {
			type: 'string',
			label: 'Title',
			default: '',
		},
		subTitle: {
			type: 'string',
			label: 'subTitle',
			default: '',
		},
		value: {
			type: 'string',
			label: 'Value',
			default: '',
		},
		iconName: {
			type: 'string',
			label: 'Icon Name',
			default: '',
		},
		type: {
			type: 'string',
			label: 'type',
			default: '',
		},
	},
};

export default TableCell;
