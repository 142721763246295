import BuildingApp from "./Building";
import ContactSupport from "./ContactSupport";
import BuildFailed from "./BuildFailed";
import IPASuccess from "./IPASuccess";
import IPAUploadStatus from "./IPAUploadStatus";
import IPAUploadBuild from './IPAUploadBuild';

export {
    BuildingApp,
    ContactSupport
}

const BuildState = ({
    building,
    buildFailed,
    ipaSuccess,
    alreadyUploaded,
    uploading,
    ipaUploadSuccess,
    otherProps={}
}) => {
    if (building) {
        return <BuildingApp />
    }
    if (buildFailed) {
        return <BuildFailed />
    }
    if(ipaSuccess){
        return <IPASuccess {...otherProps}/>
    }
    if(alreadyUploaded){
        return <IPAUploadStatus alreadyUploaded/>
    }
    if(uploading){
        return <IPAUploadBuild />
    }
    if(ipaUploadSuccess){
        return <IPAUploadStatus alreadyUploaded={false}/>
    }
    return null
}
export default BuildState