import * as React from 'react';
const StateContext = React.createContext();
const DispatchContext = React.createContext();

function BlockContextProvider({children, reducer, initialState}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useContextState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error('useContextState must be used within a ContextProvider');
  }
  return context;
}
function useContextDispatch() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error('useContextDispatch must be used within a ContextProvider');
  }
  return context;
}
export {BlockContextProvider, useContextState, useContextDispatch};
