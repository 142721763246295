
import {
    XCircleIcon,
    CheckCircleIcon,
    ExclamationIcon,
    DeviceMobileIcon,
    CloudUploadIcon,
} from '@heroicons/react/solid';
import { CogIcon, InformationCircleIcon } from '@heroicons/react/solid';

const CustomMessageBanner = ({
    color,
    type,
    displayMessage,
    spin,
    IconComponent,
}) => {
    let Icon;

    switch (type) {
        case 'error':
            Icon = XCircleIcon;
            break;
        case 'success':
            Icon = CheckCircleIcon;

            break;
        case 'warning':
            Icon = ExclamationIcon;

            break;
        case 'info':
            Icon = CogIcon;
            break;
        default:
            Icon = InformationCircleIcon;
            break;
    }

    return (
        <div className={`rounded-md bg-${color}-50 p-4 mt-4`}>
            <div className="flex items-center gap-3">
                {IconComponent ? (
                    <IconComponent />
                ) : (
                    <Icon
                        className={`h-8 w-8 text-${color}-400 ${spin && 'animate-spin-slow text-blue-900'
                            }`}
                        aria-hidden="true"
                    />
                )}
                <h3 className={`text-base font-medium text-${color}-800`}>
                    {displayMessage}
                </h3>
            </div>
        </div>
    )
}
export default CustomMessageBanner;