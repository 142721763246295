import { useEffect } from 'react';
import ThemeHeader from './ThemeHeader';
import useThemeStore from '../store/theme';
import { useParams, Link } from '@appmaker/core/routes';
import { useProject } from '@appmaker/core/hooks'
import { useQuery } from '@apollo/client';
import { GET_THEME_INSTALL } from '../api/graphql';
import Loader from '@appmaker/components/Loading';
import Notification from '@appmaker/components/ReactHotToastIntegrator';
import VersionForm from './VersionForm';

const Version = () => {
    const setThemeData = useThemeStore((store) => store.setThemeData);
    const setLoading = useThemeStore(store => store.setLoading)
    let { handle } = useParams();
    const { projectId } = useProject();
    const { loading, data, error } = useQuery(GET_THEME_INSTALL, {
        variables: {
            themeWhere: {
                handle: handle,
            },
            where: {
                theme: {
                    handle: {
                        equals: handle,
                    },
                },
            },
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                'x-appmaker-project-id': projectId,
            },
        },
    });
    useEffect(() => {
        setThemeData(data?.theme, data?.themeInstalls);
    }, [data]);
    useEffect(() => {
        setLoading(loading);
    }, [loading]);
    if (loading) {
        return <Loader />;
    }
    return (
        <Notification>
            <div className="bg-gray-50 h-screen overflow-y-auto">
                <div className="w-full mx-auto relative">
                    <ThemeHeader />
                    <VersionForm />
                </div>
            </div>
        </Notification>
    )
}

export default Version