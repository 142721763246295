import axios from '@appmaker/core/axios';
import {
	DASHBOARD_GRAPHQL_URL,
	BASE_URL,
	APPMAKER_GQL_URL,
} from '@appmaker/core/api/core';
import { getProjectId } from '@appmaker/core/store/project';

const triggerBuild = async ({
	buildName,
	templateTag,
	triggerType,
	buildType,
	platform,
	extensions,
	themes,
	packages,
	appmakerSdkVersion,
	appmakerSdkName,
}) => {
	const projectId = getProjectId();
	const query = ` 
		mutation ($appmakerSdkName:String, $appmakerSdkVersion: String, $buildName: String!, $templateTag: String!, $triggerType: String!, $buildType: String!, $platform: [String]!, $extensions: [AppBuildExtension], $themes: [AppBuildTheme],$packages:[AppBuildPackages]) {
			appBuild(
			buildName: $buildName
			templateTag: $templateTag
			triggerType: $triggerType
			buildType: $buildType
			platform: $platform
			extensions: $extensions
			themes: $themes
			packages: $packages
			appmakerSdkVersion: $appmakerSdkVersion,
			appmakerSdkName: $appmakerSdkName
			) {
			id
			}
		}
    `;
	return axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				buildName,
				templateTag,
				triggerType,
				buildType,
				extensions,
				platform,
				themes,
				packages,
				appmakerSdkVersion,
				appmakerSdkName,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

const getBuildHistory = async ({ limit = 15, offset = 0 }) => {
	const projectId = getProjectId();
	const query = ` 
			query ($limit: Int, $offset: Int) {
				appBuildHistory(limit: $limit, offset: $offset) {
				pageInfo {
					hasNextPage
					hasPreviousPage
					total
				}
				nodes {
					id
					platform {
					type
					status
					}
					createdAt
					endedAt
					buildName
					templateTag
					triggerType
					triggeredBy
					buildType
					appId
					buildFile
					buildConfig
					version
					appmakerSdkName
					appmakerSdkVersion
				}
				}
			}
    `;
	return axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				limit,
				offset,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

const retryBuild = async ({ buildId }) => {
	const projectId = getProjectId();
	const query = ` 
		mutation($id:Int!){
			appBuildRetry(id:$id){
			id,
			}
		}
    `;
	return axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				id: buildId,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

const downloadApp = ({ buildLogId, token, platform, buildFileType }) => {
	const projectId = getProjectId();
	let url = `${BASE_URL}/download-app/${projectId}?token=${token}&platform=${platform}`;
	if (buildLogId && buildLogId !== null) {
		url += `&buildLogId=${buildLogId}`;
	}
	if (buildFileType) {
		url = url + '&type=' + buildFileType;
	}
	return url;
};

const uploadToTestFlight = ({ buildLogId }) => {
	const projectId = getProjectId();
	const query = ` 
	mutation($id:Int!){
		uploadIpa(appBuildId:$id)
	  }
    `;
	return axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				id: buildLogId,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

const triggerBuildGql = async ({
	buildName,
	templateTag,
	triggerType,
	buildType,
	platform,
	extensions,
	themes,
	packages,
	appmakerSdkVersion,
	appmakerSdkName,
}) => {
	const projectId = getProjectId();
	const query = `
		mutation($data: AppBuildCreateInput!){
			createAppBuild(data: $data) {
			  appId,
			  id
			}
		  }

	`;
	return axios.post(
		APPMAKER_GQL_URL,
		JSON.stringify({
			query,
			variables: {
				data: {
					platform,
					name: buildName,
					project: {
						connect: {
							remoteProjectId: parseInt(projectId, 10),
						},
					},
					triggerType,
					buildType,

				},
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
				'x-appmaker-project-id': projectId
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

export {
	triggerBuild,
	getBuildHistory,
	retryBuild,
	downloadApp,
	uploadToTestFlight,
	triggerBuildGql,
};
