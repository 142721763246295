import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { getAppBuildByVersion } from '../../api/app-build';
import Loader from '@appmaker/components/Loading';
import { useProject } from '@appmaker/core/hooks';
import diff from 'deep-diff';
import {
	AndroidLogo,
	IosLogo,
} from 'packages/mobile-app/components/DownloadAppCards';
import { Switch } from '@headlessui/react';
import { isArray } from 'lodash';
import ReactDiffViewer from 'react-diff-viewer';

const PlatformLabelViewer = ({ platform }) => {
	switch (platform) {
		case 'ios':
			return <IosLogo className="text-gray-800 h-6 w-6 flex-shrink-0" />;
		case 'android':
			return <AndroidLogo className="text-gray-800 h-6 w-6 flex-shrink-0" />;
		default: {
			return null;
		}
	}
};

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const removeUnwantedKeysInDifference = (difference) => {
	const UN_RELEVANT_KEYS = ['versionCode', 'gqlId', 'log_id', 'build_id'];
	if (difference && isArray(difference)) {
		return difference
			?.map((item) => {
				if (
					item?.path &&
					isArray(item?.path) &&
					item.path.find((path) => UN_RELEVANT_KEYS.includes(path))
				) {
					return undefined;
				}
				return item;
			})
			?.filter((item) => item !== undefined);
	}
	return difference;
};

const JsonDiffViewer = ({
	json1,
	json2,
	currentVersion,
	selectedVersion,
	platform,
}) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [treeView, setTreeView] = useState(false);
	let differences = diff(json1, json2);
	differences = removeUnwantedKeysInDifference(differences);
	const getKind = (kind) => {
		switch (kind) {
			case 'A':
				return 'Added';
			case 'D':
				return 'Deleted';
			case 'E':
				return 'Edited';
			case 'N':
				return 'New';
			default:
				return 'Unknown';
		}
	};
	const renderDifferences = (differences) => {
		if (!differences) {
			return <p className="text-green-600">No differences found</p>;
		}

		return (
			<ul className="space-y-4">
				{differences
					.filter((difference) =>
						difference?.path
							?.join(' > ')
							?.toLowerCase()
							?.includes(searchQuery?.toLowerCase())
					)
					.map((difference, index) => (
						<li
							key={index}
							className="p-4 border rounded-lg bg-white shadow-md"
						>
							<div className="text-gray-800">
								<strong className="block text-gray-900">Path:</strong>{' '}
								{difference?.path?.join(' > ')}
							</div>
							<div className="text-gray-800">
								<strong className="block text-gray-900">Kind:</strong>{' '}
								{getKind(difference.kind)}
							</div>
							{difference.kind === 'E' && (
								<div className="text-gray-800">
									<strong className="block text-gray-900">Old Value:</strong>{' '}
									{JSON.stringify(difference.lhs)}
									<br />
									<strong className="block text-gray-900">
										New Value:
									</strong>{' '}
									{JSON.stringify(difference.rhs)}
								</div>
							)}
							{difference.kind === 'N' && (
								<div className="text-gray-800">
									<strong className="block text-gray-900">New Value:</strong>{' '}
									{JSON.stringify(difference.rhs)}
								</div>
							)}
							{difference.kind === 'D' && (
								<div className="text-gray-800">
									<strong className="block text-gray-900">
										Deleted Value:
									</strong>{' '}
									{JSON.stringify(difference.lhs)}
								</div>
							)}
						</li>
					))}
			</ul>
		);
	};

	return (
		<>
			<div className="m-4 mt-10 flex flex-rows align-center gap-4">
				<label className="text-gray-500 text-xs font-medium uppercase tracking-wide">
					Show in tree view
				</label>
				<Switch
					checked={treeView}
					onChange={(value) => {
						setTreeView(value);
					}}
					className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					<span
						aria-hidden="true"
						className="pointer-events-none absolute bg-white w-full h-full rounded-md"
					/>
					<span
						aria-hidden="true"
						className={classNames(
							treeView ? 'bg-indigo-600' : 'bg-gray-200',
							'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
						)}
					/>
					<span
						aria-hidden="true"
						className={classNames(
							treeView ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
						)}
					/>
				</Switch>
			</div>

			<div className="mx-auto p-6 bg-gray-50 rounded-lg shadow-md">
				<div className="flex items-center space-x-4 pb-2">
					<PlatformLabelViewer platform={platform} />
					<h1 className="text-2xl font-bold text-gray-900">
						comparing {currentVersion} and {selectedVersion} versions
					</h1>
				</div>
				{!treeView && (
					<>
						<input
							type="text"
							className="mb-4 p-2 w-full border rounded-md"
							placeholder="Search by path"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						{renderDifferences(differences)}
					</>
				)}
				{treeView && json1 && json2 && (
					<div className='overflow-auto'>
						<ReactDiffViewer
							oldValue={JSON.stringify(json1, null, 2)}
							newValue={JSON.stringify(json2, null, 2)}
							splitView={false}
							showDiffOnly={true}
						/>
					</div>
				)}
			</div>
		</>
	);
};

const DiffModal = ({
	isOpen,
	setIsOpen,
	data,
	currentVersion,
	currentConfig,
	selectedCompareVersion,
	platform,
}) => {
	const [isLoading, setLoading] = useState(false);
	const { projectId } = useProject();
	const [selectedVersionData, setSelectedVersionData] = useState({});
	useEffect(() => {
		if (selectedCompareVersion && projectId) {
			(async () => {
				try {
					setLoading(true);
					const response = await getAppBuildByVersion(
						projectId,
						selectedCompareVersion,
						platform
					);
					setSelectedVersionData(response?.data?.data?.appBuilds[0]);
				} catch (error) {
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [selectedCompareVersion, projectId]);

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				open={isOpen}
				onClose={() => setIsOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-60" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-sm transition-all">
								{isLoading && <Loader />}
								{!isLoading && !selectedVersionData?.id && (
									<div>
										<h3 className="text-lg font-semibold px-4 p-4">
											No Data Found
										</h3>
									</div>
								)}

								{!isLoading && selectedVersionData?.id && (
									<JsonDiffViewer
										currentVersion={currentVersion}
										selectedVersion={selectedCompareVersion}
										json1={currentConfig}
										json2={selectedVersionData?.config}
										platform={platform}
									/>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default DiffModal;
