const BlogCard = ({ attributes = {} }) => {
	const {
		imgSrc,
		featureImg,
		titleMeta,
		title,
		excerpt,
		meta,
		type,
		reverse,
		appmakerAction,
		htmlExcerpt,
		stripTagsExcerpt,
		unescape = true,
		postBlock,
		status,
	} = attributes;
	return (
		<div className="bg-white mb-1 p-1 flex">
			<img src={featureImg} alt="product" className="w-24 h-24" />
			<div className="flsex-auto">
				<p>{title}</p>
				<p className="text-xs text-gray-500">{excerpt}</p>
			</div>
		</div>
	);
};

BlogCard.attributesSchema = {
	type: 'object',
	properties: {
		hasPages: {
			type: 'string',
			label: 'hasPages',
			default: '',
		},
		data: {
			type: 'string',
			label: 'data',
			default: '',
		},
		type: {
			type: 'string',
			label: 'type',
			default: '',
		},
		featureImg: {
			type: 'string',
			label: 'featureImg',
			default: '',
		},
		title: {
			type: 'string',
			label: 'title',
			default: '',
		},
		excerpt: {
			type: 'string',
			label: 'excerpt',
			default: '',
		},
	},
};

export default BlogCard;
