import React, { useState } from 'react';
import {
	MediaUpload,
	RichText,
	InspectorControls,
	MediaPlaceholder,
	InnerBlocks,
	ButtonBlockAppender,
} from '@wordpress/block-editor';
import { Button, RangeControl, ToggleControl } from '@wordpress/components';
import MenuImage from '../../../components/MenuImage';
import DeleteActionButton from '../../../components/Toolbar';
import { extractTextFromHTML } from '../../../helper';
import { MenuItem } from '../../../../../new-blocks/components';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useSelect } from '@wordpress/data';
import { createBlock } from '@wordpress/blocks';
import { dispatch } from '@wordpress/data';
import Select from 'react-select';
import clsx from 'clsx';
import ColorPicker from '@appmaker/core/components/AppmakerForm/components/ColorPicker';
// import ColorPicker from '@appmaker/components/Form/components/ColorPicker';

const AddImage = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g transform="translate(-740 -56)">
				<rect
					width="24"
					height="24"
					transform="translate(740 56)"
					fill="none"
				/>
				<g transform="translate(740.899 58.167)">
					<g transform="translate(0 0)">
						<circle
							cx="1.522"
							cy="1.522"
							r="1.522"
							transform="translate(6.83 4.842)"
							fill="#4b6efc"
						/>
						<path
							d="M20.849,35.968a4.688,4.688,0,0,0-2.622-1.248V26.981a3.044,3.044,0,0,0-.867-2.115A2.955,2.955,0,0,0,15.246,24H2.981a3.044,3.044,0,0,0-2.115.867A2.955,2.955,0,0,0,0,26.981V39.605A3.044,3.044,0,0,0,.867,41.72a2.955,2.955,0,0,0,2.115.867H14.844a4.474,4.474,0,0,0,6.005-6.618ZM1.121,26.981a1.877,1.877,0,0,1,1.861-1.861H15.246a1.877,1.877,0,0,1,1.861,1.861v6.6l-3.13-3.108a.568.568,0,0,0-.8,0L8.458,35.207,5.265,31.993a.568.568,0,0,0-.8,0L1.121,35.376ZM2.96,41.487v-.021a1.855,1.855,0,0,1-1.311-.55,1.93,1.93,0,0,1-.529-1.311V36.962L4.885,33.2l3.193,3.193a.588.588,0,0,0,.8,0L13.6,31.655l3.087,3.108-.19.063a1.964,1.964,0,0,0-.275.085c-.085.021-.169.063-.254.085a.633.633,0,0,0-.169.085,1.964,1.964,0,0,0-.211.106l-.317.19c-.063.042-.106.063-.169.106-.042.021-.063.042-.106.063a2.551,2.551,0,0,0-.507.444,4.473,4.473,0,0,0-1.311,3.172,4.649,4.649,0,0,0,.106.93c.021.085.042.148.063.233.063.211.127.423.211.634v.021a3.879,3.879,0,0,0,.275.507Zm17.064.021a3.345,3.345,0,0,1-4.673.042c-.085-.085-.169-.19-.254-.275-.063-.063-.127-.148-.19-.211A2.008,2.008,0,0,1,14.7,40.7c-.042-.085-.085-.148-.127-.233a1.786,1.786,0,0,1-.106-.359c-.021-.085-.063-.19-.085-.275a3.5,3.5,0,0,1-.063-.677,3.394,3.394,0,0,1,.973-2.368,3.305,3.305,0,0,1,2.368-.973,3.394,3.394,0,0,1,2.368.973A3.269,3.269,0,0,1,21,39.161,3.382,3.382,0,0,1,20.025,41.508Z"
							transform="translate(0 -24)"
							fill="#4b6efc"
						/>
						<path
							d="M291.893,266.261H290.6v-1.29a.571.571,0,0,0-1.142,0v1.29h-1.29a.571.571,0,0,0,0,1.142h1.29v1.29a.571.571,0,0,0,1.142,0V267.4h1.29a.571.571,0,0,0,0-1.142Z"
							transform="translate(-272.397 -251.692)"
							fill="#4b6efc"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

const SubMenuAppender = ({ clientId }) => {
	function addMenu() {
		try {
			const block = createBlock('appmaker/sub-menu-item', {});
			dispatch('core/block-editor').insertBlocks(block, 0, clientId);
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<button
			className="rounded-full bg-gray-700 text-white absolute -bottom-0 right-12"
			onClick={addMenu}
			title="Add Submenu"
		>
			<span className="sr-only">Add Submenu</span>
			<PlusCircleIcon className="h-6 w-6 p-0.5" />
		</button>
	);
};
const MenuType = ({ setAttributes, menuType }) => {
	const options = [
		{ value: 'menu_item', label: 'Menu Item' },
		{ value: 'title', label: 'Title' },
	];
	return (
		<>
			<p className="mb-0.5 text-gray-700 text-sm">Menu Type</p>
			<Select
				options={options}
				defaultValue={
					menuType
						? options.find((item) => item.value === menuType)
						: options[1]
				}
				onChange={(option) => {
					setAttributes({
						menuType: option.value,
					});
				}}
			/>
		</>
	);
};
const Edit = (props) => {
	const { attributes, setAttributes, className, isSelected, clientId, metaData } = props;
	const {
		image,
		text,
		menuType,
		expandable,
		fontColor = '#212121',
		badgeColor = '#212121',
		badgeTextColor = '#ffffff',
		badgeText = 'Badge',
		badge,
		dotIndicator,
		dotIndicatorColor = '#BC002D',
		dotSize = 10,
	} = attributes;
	const maxHeight = metaData?.image?.maxHeight
	const maxWidth = metaData?.image?.maxWidth
	const blockSelected = useSelect((select) => {
		// const block = select('core/block-editor').getBlock(clientId);
		const items = select('core/block-editor')
			.getBlock(clientId)
			.innerBlocks.map((item) => item.attributes?.image.url);
		const childSelected =
			select('core/block-editor').hasSelectedInnerBlock(clientId);
		return childSelected || isSelected || items.length === 0;
	});
	const onSelect = (data) => {
		setAttributes({
			image: {
				url: data.url,
				fileName: data.filename,
				id: data.id,
				title: data.title,
			},
			imageContainerStyle: {
				height: data.meta !== undefined ? data.meta.height : '100',
				width: data.meta !== undefined ? data.meta.width : '100',
			},
			thumbnail_meta: {
				height: data.meta !== undefined ? data.meta.height : undefined,
				width: data.meta !== undefined ? data.meta.width : undefined,
			},
		});
	};
	const ImageEditor = () => (
		<div className="col-2">
			<DeleteActionButton
				action={() => {
					setAttributes({ image: undefined });
				}}
			/>
			{image && <MenuImage imageSrc={image.url} />}
		</div>
	);

	return (
		<div className={className}>
			<InspectorControls>
				<>
					<MenuType setAttributes={setAttributes} menuType={menuType} />
					<div className="mt-4">
						<ToggleControl
							label={'Expandable'}
							checked={expandable}
							onChange={() => {
								setAttributes({ expandable: !expandable });
							}}
						/>
					</div>
					<div className="mt-4">
						{/* <label
							htmlFor="font-color"
							className="font-bold mb-2 text-sm text-gray-500"
						>
							Font Color
						</label> */}
						{/* <input
							id="font-color"
							type="text"
							className="rounded-md w-full"
							placeholder="font color"
							value={fontColor}
							onChange={(event) => {
								setAttributes({ fontColor: event.target.value });
							}}
						/> */}
						{/* <ColorPicker
							attributes={{
								label: 'Font Color',
								defaultColor: '#212121',
								onChangeComplete: (color) =>
									setAttributes({ fontColor: color }),
							}}
						/> */}
						<ColorPicker
							attributes={{
								label: 'Font Color',
								defaultColor: fontColor,
								onChangeComplete: (color) => {
									setAttributes({ fontColor: color.hex });
								},
							}}
						/>
					</div>
					{menuType === 'menu_item' && (
						<div className="mt-4">
							<p className="mb-1 text-gray-500 font-semibold text-sm">
								Menu Image
							</p>
							{image === undefined && (
								<MediaPlaceholder
									onSelectURL={(url) => {
										setAttributes({
											image: {
												url,
											},
										});
									}}
									onSelect={(selectedData) => {
										let data = Array.isArray(selectedData)
											? selectedData[0]
											: selectedData;
										let newUrl = new URL(data?.url);
										if(attributes?.disableImageResize){
												if (newUrl?.searchParams?.has('height')) {
													newUrl?.searchParams?.delete('height');
												}
												if (newUrl?.searchParams?.has('width')) {
													newUrl?.searchParams?.delete('width');
												}
										}else{
												if(data?.meta?.width > maxWidth){
													// newUrl += attributes?.maxHeight ? `&height=${attributes?.maxHeight}` : '';
													newUrl?.searchParams?.set('width',maxWidth)
												}else if(data?.meta?.height > maxHeight ){
													// newUrl += attributes?.maxWidth ? `&width=${attributes?.maxWidth}` : '';
													newUrl?.searchParams?.set('height',maxHeight);
												}
										}	
										// condition in meta because each api having different response of the meta
										setAttributes({
											image: {
												url: newUrl,
												fileName: data.filename,
												id: data.id,
												title: data.title,
											},
											imageContainerStyle: {
												height:
													data.meta !== undefined ? data.meta.height : '100',
												width:
													data.meta !== undefined ? data.meta.width : '100',
											},
											thumbnail_meta: {
												height:
													data.meta !== undefined
														? data.meta.height
														: undefined,
												width:
													data.meta !== undefined ? data.meta.width : undefined,
											},
										});
									}}
									allowedTypes={['image']}
									multiple={false}
									labels={{ title: 'Image' }}
									value={{ id: image && image.id, src: image && image.url }}
								/>
							)}
						</div>
					)}

					{image && (
						<div className="flex items-center gap-3">
							<img
								src={image?.url}
								alt="thumbnail"
								className="h-16 w-16 border border-gray-200 object-contain p-0.5"
							/>
							<button
								onClick={() =>
									setAttributes({
										image: undefined,
									})
								}
								type="button"
								className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
							>
								Replace image
							</button>
						</div>
					)}
					<div className="mt-4">
						<ToggleControl
							label={'Badge'}
							checked={badge}
							onChange={() => {
								setAttributes({ badge: !badge });
							}}
						/>
					</div>
					{badge ? (
						<>
							<div className="my-3">
								<label
									htmlFor="font-color"
									className="mb-0.5 text-sm text-gray-700 block"
								>
									Badge Text
								</label>
								<input
									id="font-color"
									type="text"
									className="rounded-md w-full"
									placeholder="font color"
									value={badgeText}
									onChange={(event) => {
										setAttributes({ badgeText: event.target.value });
									}}
								/>
							</div>
							<ColorPicker
								attributes={{
									label: 'Badge Color',
									defaultColor: badgeColor,
									onChangeComplete: (color) => {
										setAttributes({ badgeColor: color.hex });
									},
								}}
							/>
							<ColorPicker
								attributes={{
									label: 'Badge Text Color',
									defaultColor: badgeTextColor,
									onChangeComplete: (color) => {
										setAttributes({ badgeTextColor: color.hex });
									},
								}}
							/>
						</>
					) : null}
					<div className="mt-4">
						<ToggleControl
							label={'Dot Indicator'}
							help="When enabled, a dot indicator will be shown on the right to the text. We don't recommend using this with the badge."
							checked={dotIndicator}
							onChange={() => {
								setAttributes({ dotIndicator: !dotIndicator });
							}}
						/>
					</div>
					{dotIndicator ? (
						<div className="my-3">
							<ColorPicker
								attributes={{
									label: 'Dot Indicator Color',
									defaultColor: dotIndicatorColor,
									onChangeComplete: (color) => {
										setAttributes({ dotIndicatorColor: color.hex });
									},
								}}
							/>
							<div className="mb-4" />
							<RangeControl
								label="Dot Size"
								value={dotSize}
								onChange={(value) => {
									setAttributes({ dotSize: value });
								}}
								min={5}
								max={12}
							/>
						</div>
					) : null}
				</>
			</InspectorControls>
			<div
				className={clsx(
					'px-4 py-2 flex flex-col relative',
					menuType === 'menu_item' && 'ml-4 border-l border-gray-200'
				)}
			>
				<MenuItem
					attributes={{
						thumb: image?.url,
						type: menuType,
						badgeText: badge ? badgeText : null,
						badgeColor: badge ? badgeColor : null,
						badgeTextColor: badge ? badgeTextColor : null,
						dotIndicator: dotIndicator,
						dotIndicatorColor: dotIndicatorColor,
						dotSize: dotSize,
					}}
					imageEditor={<ImageEditor />}
					titleEditor={
						<RichText
							tagName="p"
							style={{ color: fontColor }}
							className={
								menuType === 'menu_item'
									? 'text-base font-semibold'
									: 'text-sm font-normal'
							}
							withoutInteractiveFormatting
							allowedFormats={[]}
							value={text}
							onChange={(text) => {
								setAttributes({ text: extractTextFromHTML(text) });
							}}
							placeholder="Enter the text"
						/>
					}
				/>
				{menuType === 'menu_item' && <SubMenuAppender clientId={clientId} />}
			</div>
			{
				<div className="ml-4">
					<InnerBlocks
						renderAppender={() => null}
						// renderAppender={InnerBlocks.ButtonBlockAppender}

						allowedBlocks={[
							'appmaker/sub-menu-item',
							// 'appmaker/menu'
						]}
					/>
				</div>
			}
		</div>
	);
};
export default Edit;
