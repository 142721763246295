import { LockClosedIcon } from '@heroicons/react/solid';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import { PhotographIcon } from '@heroicons/react/outline';
import AppmakerSwitch from 'packages/appmaker-core/components/AppmakerForm/fields/AppmakerSwitch';
import PricingModal from 'packages/Subscription/components/PricingModal';
import { useState } from 'react';

const PushImage = ({ enablePushImage = false, setImage = () => { }, src }) => {
	const [pricingModal, setPricingModal] = useState(false);

	if (!enablePushImage) {
		return (
			<div>
				<AppmakerSwitch
					onChange={(data) => { }}
					defaultValue={false}
					properties={{
						title: 'Push with image',
						label: '',
						className: 'mt-10',
						disable: true,
						addOnTag: 'Ultimate Plan Feature',
						upgradePlanAction: () => {
							setPricingModal(!pricingModal);
						},
					}}
					schema={{}}
				/>
				<PricingModal isOpen={pricingModal} setIsOpen={setPricingModal} />
			</div>
		);
	}
	return (
		<div className="mt-10">
			<MediaPlaceholder
				onSelect={(selectedData) => {
					let data = Array.isArray(selectedData) ? selectedData[0] : selectedData
					if (data !== undefined && data?.url) {
						setImage(data.url);
					}
				}}
				allowedTypes={['image']}
				multiple={false}
				onSelectURL={(data) => {
					setImage(data);
				}}
				attributes={{
					icon: PhotographIcon,
					title: 'Push Image',
					description:
						'Showcase a product that’s on sale or highlight your best offer by adding an image in push notification ',
				}}
				forceRender={true}
			>
				{src ? (
					<img
						src={src}
						alt="push"
						width={50}
						height={50}
						className="object-cover pointer-events-none h-12 w-12 rounded-lg mt-3"
					/>
				) : null}
			</MediaPlaceholder>
		</div>
	);
};

export default PushImage;
