import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project';
import { Popover, Transition } from '@headlessui/react';
import { QrcodeIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { QRCodeSVG } from 'qrcode.react';

const QrCode = ({ url, platform, display, position, largeButton }) => {
	const projectId = getProjectId();
	if (!url) return null;
	if (display === false) {
		return null;
	}
	return (
		<Popover
			className="relative"
			onClick={() => {
				appmaker.doAction('appmaker-analytics-event', {
					event: 'app_download_qr_code_clicked',
					params: {
						project_id: projectId,
						platform,
					},
				});
			}}
		>
			<Popover.Button
				className={clsx(
					'flex items-center space-x-2 rounded-full',
					largeButton
						? 'bg-gray-200 text-gray-800 p-2'
						: 'bg-gray-900 text-white p-1'
				)}
			>
				<QrcodeIcon className={largeButton ? 'h-6 w-6' : 'h-5 w-5'} />
			</Popover.Button>
			<Transition
				enter="transition duration-100 ease-out"
				enterFrom="transform scale-95 opacity-0"
				enterTo="transform scale-100 opacity-100"
				leave="transition duration-75 ease-out"
				leaveFrom="transform scale-100 opacity-100"
				leaveTo="transform scale-95 opacity-0"
				className={clsx(
					'absolute z-50 border border-gray-100 shadow-xl rounded-lg overflow-hidden bg-white',
					position ? position : '-left-16 sm:left-0'
				)}
			>
				<Popover.Panel>
					<span className="pt-4 block text-center font-medium text-gray-700">
						Scan to download app
					</span>
					<QRCodeSVG value={url} includeMargin={true} size={256} />
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};
export default QrCode;
