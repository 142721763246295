// import 'packages/shopify/index';
import './track';
// npm install  --save
import * as apData from '@wordpress/data';
import appmaker from '@appmaker/core/index';
import AppmakerCore from '@appmaker/core/plugins/core';
import CustomizeApp from 'packages/mobile-app';
import ShopifyPlugin from '@appmaker/shopify';
import CoreApp from '@appmaker/core-app/index';
import Woocommerce from 'packages/woocommerce';
import onboarding from 'packages/onboarding';
import blockEditor from 'packages/blockEditor';
import MediaLibrary from 'packages/MediaLibrary';
import HubspotSync from 'packages/hubspot-sync';
import oldBlocks from 'packages/oldBlocks';
import Language from 'packages/lanugage';
import pushNotification from 'packages/pushNotification';
import PublishApp from 'packages/publishApp';
import Extensions from 'packages/extensions';
import DataSource from 'packages/data-source';
import Actions from 'packages/actions';
import Transforms from 'packages/transforms';
// Testing plugins
import EcommerceBlocks from '@appmaker/e-commerce-blocks';
import PlaygroundPlugin from 'packages/playground-pugin';
import InAppPagePlayground from 'packages/in-app-page-playground';
import AppmakerAdmin from 'packages/AppmakerAdmin';
import Subscription from 'packages/Subscription';
import UploadStore from 'packages/upload-store';
import AdminTools from 'packages/admin-tools';
import DownloadApp from 'packages/download-app';
import Themes from 'packages/themes';
import Analytics from 'packages/analytics';
import Dashboard from 'packages/dashboard';
import WoocommerceRedirect from 'packages/woocommerce-redirects';
import AppBuilds from 'packages/app-builds';
import Permissions from 'packages/permissions';

// import EventTracking from 'packages/event-tracking';
import { reusablePlugin, templateEditorPlugin } from 'packages/blockEditor';
import Settings from 'packages/settings';
window.apData = apData;
// appmaker.activateAllPlugins();

appmaker.registerPlugin(AppmakerCore);
appmaker.registerPlugin(CustomizeApp);
appmaker.registerPlugin(ShopifyPlugin);
appmaker.registerPlugin(CoreApp);
appmaker.registerPlugin(Woocommerce);
appmaker.registerPlugin(onboarding);
appmaker.registerPlugin(blockEditor);
appmaker.registerPlugin(MediaLibrary);
appmaker.registerPlugin(DataSource);
appmaker.registerPlugin(Actions);
appmaker.registerPlugin(Transforms);
appmaker.registerPlugin(DownloadApp);
appmaker.registerPlugin(AppBuilds);
// Testing plugins
appmaker.registerPlugin(PlaygroundPlugin);
appmaker.registerPlugin(oldBlocks);
// appmaker.activateAllPlugins();
appmaker.registerPlugin(EcommerceBlocks);
appmaker.registerPlugin(InAppPagePlayground);
appmaker.registerPlugin(HubspotSync);
appmaker.registerPlugin(Language);
appmaker.registerPlugin(pushNotification);
appmaker.registerPlugin(PublishApp);
appmaker.registerPlugin(AppmakerAdmin);
appmaker.registerPlugin(Subscription);
appmaker.registerPlugin(Extensions);
appmaker.registerPlugin(UploadStore);
appmaker.registerPlugin(AdminTools);
appmaker.registerPlugin(reusablePlugin);
appmaker.registerPlugin(templateEditorPlugin);
appmaker.registerPlugin(Themes);
// appmaker.registerPlugin(Analytics);
appmaker.registerPlugin(Dashboard);
appmaker.registerPlugin(WoocommerceRedirect);
// appmaker.registerPlugin(Permissions);
appmaker.registerPlugin(Settings);
/**
 * DON'T EDIT THIS LINE
 * core-app should be the first plugin to be activated
 */
appmaker.activatePlugins([
	{
		id: '@appmaker/core-app',
	}
]);
// appmaker.activatePlugins([
//     {
//         id: '@appmaker/core-app',
//     },
//     {
//         id: '@appmaker/admin',
//     },
//     {
//         id: '@appmaker/upload-store'
//     }
// ])
