import Header from '../components/Header';
import List from '../components/List';
import Loader from '@appmaker/components/Loading';
import { useTransform } from '../hooks';

const Transforms = () => {
    const { isLoading } = useTransform();
    if (isLoading) {
        return <Loader />
    }
    return (
        <div className="bg-gray-50 h-screen overflow-y-auto">
            <div className="w-full mx-auto bg-white ">
                <Header />
                <List title="Current transforms" />
            </div>
        </div>
    )
}


export default Transforms;