
import React from 'react';

import appmaker from '@appmaker/core/index';
// import PluginRoute from './routes/PluginRoute';
// import AdminTab from './routes/AdminTab';
import { CogIcon } from '@heroicons/react/solid';
const Reports = React.lazy(() => import('./routes/Reports'));
Reports.fullLayout = true;

const Plugin = {
    id: '@appmaker/admin',
    name: 'AppmakerAdmin',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/appmaker-admin/reports/:templateName/:reportId',
            component: Reports,
        });
        // appmaker.registerRoute({
        //     routeName: '/admin-tab',
        //     component: AdminTab
        // })
        // /**
        //  * Menu Items
        //  */
        // appmaker.registerSideBarMenu({
        //     name: 'AppmakerAdmin',
        //     routeName: '/appmaker-admin',
        //     icon: LinkIcon,
        // });
        // appmaker.registerSideBarMenu(
        //     {
        //         name: 'Admin',
        //         routeName: '/admin-tab',
        //         adminOnly: true,
        //         icon:CogIcon
        //     },
        // );

    },
};

export default Plugin;
