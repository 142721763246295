import { TrashIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

const InfiniteText = ({
	properties = {},
	defaultValue = [],
	value,
	onChange,
}) => {
	const { inputType = 'text', props = {}, label } = properties;
	const [valueArray, setValue] = useState(defaultValue || []);

	const addInput = () => {
		setValue((e) => {
			return [...e, ''];
		});
	};
	const removeInput = (index) => {
		setValue((e) => {
			return e.filter((e, i) => i !== index);
		});
	};

	const onChangeInput = (e) => {
		e.preventDefault();
		const index = e.target.id;
		setValue((s) => {
			const newArray = [...s];
			newArray[index] = e.target.value;
			return newArray;
		});
	};
	useEffect(() => {
		onChange(valueArray);
	}, [valueArray]);
	return (
		<div className="flex flex-col w-full mt-3">
			<div className="flex items-center justify-between">
				<label className="text-sm text-gray-600">{label}</label>
				<button
					onClick={addInput}
					className="text-blue-600 bg-blue-100 px-2 py-1 text-sm rounded-lg hover:bg-blue-200"
				>
					+ Add more
				</button>
			</div>
			{valueArray.map((item, index) => {
				return (
					<div className="flex flex-row gap-2 mt-3 relative">
						<input
							type={'text'}
							key={index}
							id={index}
							value={item || undefined}
							onChange={onChangeInput}
							{...props}
							className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
						/>
						<button
							onClick={() => removeInput(index)}
							className="p-2 bg-red-100 text-red-600 rounded-md hover:bg-red-200"
							title="Delete field"
						>
							<TrashIcon className="h-4 w-4" />
						</button>
					</div>
				);
			})}
		</div>
	);
};

export default InfiniteText;
