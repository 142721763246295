import appmaker from '@appmaker/core/index';
import { ViewGridAddIcon, MenuAlt2Icon } from '@heroicons/react/solid';
import BlockEditor from './routes/block-editor';
import Menu from './routes/menu';

const Plugin = {
	id: '@appmaker/woocommerce/redirects',
	name: 'WooCommerce Redirects ',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/woocommerce/block-editor',
			component: BlockEditor,
			exact: true,
		});
		appmaker.registerRoute({
			routeName: '/woocommerce/menu',
			component: Menu,
		});

		appmaker.registerSideBarMenu(
			{
				name: 'App Builder',
				routeName: '/woocommerce/block-editor',
				icon: ViewGridAddIcon,
				addTracker: {
					eventName: 'customize_app_clicked',
				},
			},
			3
		);
		appmaker.registerSideBarMenu(
			{
				name: 'Menu',
				routeName: '/woocommerce/menu',
				icon: MenuAlt2Icon,
			},
			3
		);
	},
};

export default Plugin;
