import PluginInstallInstructions from '../components/InstallPlugin/PluginInstallInstructions';
import { useState } from 'react';

import {
	InformationCircleIcon,
	SparklesIcon,
	AdjustmentsIcon,
	ClipboardCopyIcon,
	ClipboardCheckIcon,
} from '@heroicons/react/solid';
export const Copytoclipboard = ({ text }) => {
	const [copied, setCopied] = useState(false);
	return (
		<button
			onClick={() => {
				navigator.clipboard.writeText(text);
				setCopied(true);
				setTimeout(() => setCopied(false), 60 * 50);
			}}
			className="inline-flex items-center"
		>
			{text}
			{copied ? (
				<ClipboardCheckIcon
					className="ml-1 -mr-0.5 h-4 w-4"
					aria-hidden="true"
				/>
			) : (
				<ClipboardCopyIcon
					className="ml-1 -mr-0.5 h-4 w-4"
					aria-hidden="true"
				/>
			)}
		</button>
	);
};
const tabs = [
	{
		name: 'Automatic configure',
		icon: SparklesIcon,
		href: '#',
		current: true,
		steps: [
			{
				step: '1 - Install and activate the plugin',
				instruction: <PluginInstallInstructions />,
				screenshots: [
					{
						src: '/images/steps/automatic-screen-01.png',
						alt: 'Plugin search screen',
						caption:
							'Click "Connect Automatically" and click install and activate plugin',
					},
					// {
					// 	src: '/images/steps/automatic-screen-02.png',
					// 	alt: 'Appmaker settings',
					// 	caption: 'Go to WooCommerce > Appmaker App Stettings',
					// },
				],
			},
			{
				step: '2 ',
				instruction:
					'  Go to the plugin settings page of Appmaker for the woocommerce plugin and copy the Access key (as shown below) and come back to this page.',
				screenshots: [
					{
						src: '/images/steps/automatic-screen-02.png',
						alt: 'Appmaker settings',
						caption: 'Go to WooCommerce > Appmaker App Stettings',
					},
				],
			},
		],
	},
	{
		name: 'Manual Configuration',
		icon: AdjustmentsIcon,
		href: '#',
		current: false,
		steps: [
			{
				step: '1',
				instruction: (
					<>
						On your WordPress admin dashboard go to “plugins” and click on “Add
						new”
					</>
				),
				screenshots: [
					{
						src: '/images/steps/manual-screen-01.png',
						alt: 'admin dashboard',
						caption: 'Wordpress admin dashboard',
					},
				],
			},
			{
				step: '2',
				instruction: (
					<>
						Search for
						<q className="font-bold text-blue-600">
							<Copytoclipboard text="Appmaker WooCommerce" />
						</q>
						and install the plugin and activate it
					</>
				),
				screenshots: [
					{
						src: '/images/steps/manual-screen-02.png',
						alt: 'Plugin search page',
						caption: 'Plugin search page',
					},
				],
			},
			{
				step: '3',
				instruction: (
					<>
						Once the plugin is installed and activated, you will be redirected
						to the plugin settings page. Copy the Access key and come back to
						this page
					</>
				),
				screenshots: [
					{
						src: '/images/steps/automatic-screen-02.png',
						alt: 'Appmaker settings',
						caption: 'Appmaker app settings',
					},
				],
			},
		],
	},
];

export { tabs };
