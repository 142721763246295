import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

export const SimpleSelect = ({
	onChange,
	properties,
	defaultValue,
	_internalValue,
	...props
}) => {
	const {
		options,
		multi = false,
		mutlti = false,
		label,
		creatable = false,
		disableInternalValue = false
	} = properties;
	const SelectItem = creatable ? Creatable : Select;
	// initially "mtlti" was used : spelling mistake
	const isMultiple = multi || mutlti;
	// isMultiple ? defaultValue : _internalValue
	let newDefaultValue;
	if (isMultiple) {
		newDefaultValue = defaultValue;
	} else {
		newDefaultValue = options?.find((item) => item.value === defaultValue) ?? {
			label: defaultValue,
			value: defaultValue,
		};
	}

	return (
		<div className={label && 'mt-4'}>
			{label && (
				<label className="block text-sm font-base text-gray-700 mb-1">
					{label}
				</label>
			)}
			<SelectItem
				options={options}
				onChange={(selection) => {
					onChange(isMultiple ? selection : selection.value, selection, undefined , {
						disableInternalValue
					});
				}}
				defaultValue={newDefaultValue}
				isMulti={isMultiple}
				styles={{
					borderRadius: '.375rem',
					borderColor: 'black',
					borderWidth: 0.5,
				}}
			/>
		</div>
	);
};
