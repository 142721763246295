import React, { useState } from 'react';
import AppmakerSwitch from '../../AppmakerSwitch';

const SwitchEnabler = ({ children, label, onChange, defaultShow = false }) => {
	const [isEnabled, setIsEnabled] = useState(defaultShow);

	const handleSwitchChange = (value) => {
		setIsEnabled(value === 1 || value === '1');
		onChange && onChange(value === 1 || value === '1');
	};

	return (
		<div>
			<AppmakerSwitch
				properties={{
					label: label,
					className: 'mb-2',
				}}
				defaultValue={defaultShow}
				onChange={handleSwitchChange}
			/>
			{isEnabled && (
				<div className="pl-2 border-l-2 border-gray-200 rounded-lg pt-1">
					{children}
				</div>
			)}
		</div>
	);
};

export default SwitchEnabler;
