import { RangeControl } from '@wordpress/components';
import ColorPicker from '../../components/ColorPicker';
import Select from './components/Select';
import DisplayContainer from './components/DisplayContainer';

const TextStyles = ({ properties = {}, onChange, defaultValue }) => {
	const { className, label } = properties;
	const { fontSize = 14, letterSpacing } = defaultValue || {};

	const setStyles = (styles) => {
		onChange && onChange({ ...defaultValue, ...styles });
	};

	const formReset = () => {
		const confirm = window.confirm('Are you sure to reset?. ');
		if (confirm) {
			onChange({});
		}
	};

	return (
		<DisplayContainer
			className={className}
			title={label}
			onClickRestToDefault={formReset}
		>
			<>
				<RangeControl
					label="Font Size"
					name="font-size"
					value={fontSize}
					onChange={(fontSize) => setStyles({ fontSize })}
					min={1}
					max={100}
				/>
				<RangeControl
					label="Letter Spacing"
					defaultValue={0}
					name="letter-spacing"
					value={letterSpacing}
					onChange={(letterSpacing) => setStyles({ letterSpacing })}
					min={0}
					max={10}
				/>
				<ColorPicker
					attributes={{
						label: 'Color',
						defaultColor: defaultValue?.color,
						singleItem: true,
						onChangeComplete: (color) => {
							setStyles({ color: color.hex });
						},
					}}
				/>
				<Select
					label="Text Align"
					id="text-align"
					defaultValue={defaultValue?.textAlign}
					onChange={(e) => setStyles({ textAlign: e.target.value })}
					options={[
						{ label: 'Left', value: 'left' },
						{ label: 'Center', value: 'center' },
						{ label: 'Right', value: 'right' },
						{ label: 'Justify', value: 'justify' },
					]}
				/>
				<Select
					label="Text Decoration Line"
					id="text-decoration-line"
					defaultValue={defaultValue?.textDecorationLine}
					onChange={(e) => setStyles({ textDecorationLine: e.target.value })}
					options={[
						{ label: 'None', value: 'none' },
						{ label: 'Underline', value: 'underline' },
						{ label: 'Overline', value: 'overline' },
						{ label: 'Line Through', value: 'line-through' },
					]}
				/>
				{/* text decoration color */}
				<ColorPicker
					attributes={{
						label: 'Text Decoration Color',
						defaultColor: defaultValue?.textDecorationColor,
						singleItem: true,
						onChangeComplete: (color) => {
							setStyles({ textDecorationColor: color.hex });
						},
					}}
				/>
				{/* textTransform */}
				<Select
					label="Text Transform"
					id="text-transform"
					defaultValue={defaultValue?.textTransform}
					onChange={(e) => setStyles({ textTransform: e.target.value })}
					options={[
						{ label: 'None', value: 'none' },
						{ label: 'Capitalize', value: 'capitalize' },
						{ label: 'Uppercase', value: 'uppercase' },
						{ label: 'Lowercase', value: 'lowercase' },
					]}
				/>
				{/* fontFamily */}
				<div className="mt-2">
					<label
						className="block text-sm font-base text-gray-700"
						htmlFor="font-family"
					>
						Font Family
					</label>
					<input
						id="font-family"
						className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
						value={defaultValue?.fontFamily}
						onChange={(e) => setStyles({ fontFamily: e.target.value })}
					/>
				</div>
			</>
		</DisplayContainer>
	);
};

export default TextStyles;
