import React from 'react';

export default () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
	>
		<rect width={24} height={24} fill="white" />
		<path
			d="M21 6C21 4.34315 19.6569 3 18 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6Z"
			stroke="black"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<circle cx={20} cy={12} r={4} fill="black" />
		<circle cx={4} cy={12} r={4} fill="black" />
		<path
			d="M19 14L21 12L19 10"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5 10L3 12L5 14"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
