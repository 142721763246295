import React from 'react';

const Label = ({ children }) => <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 uppercase last:mr-0 mr-1">
  {children}
</span>

// Component to render multi-line text fields
const MultiLineTextField = ({ field }) => (
  <>
    <span>{field.value}</span>
    <Label>MultiLineTextField</Label>
  </>
);

// Component to render single-line text fields
const SingleLineTextField = ({ field }) => (
  <>
    <span>{field.value}</span>
    <Label>Text</Label>
  </>
);

// Component to render color fields
const ColorField = ({ field }) => (
  <>
    <div
      className="w-8 h-8 rounded-full"
      style={{ backgroundColor: field.value, border: '2px solid #000' }}
    ></div>
    <Label>Color</Label>
  </>
);

// Component to render file references
const FileReference = ({ field }) => {
  let filePreview = null;
  if (field?.reference?.image) {
    filePreview = (<img src={field?.reference?.image?.url} alt={field.key} className="w-24 h-24" />)
  } else if (field?.reference?.previewImage) {
    filePreview = (<img src={field?.reference?.previewImage?.url} alt={field.key} className="w-24 h-24" />)
  } else {
    filePreview = (<pre>{JSON.stringify(field, null, 2)}</pre>)
  }
  return <>
    {filePreview}
    <Label>File</Label>
  </>
}

// Component to render collection references
const CollectionReference = ({ field }) => (
  <>
    <span>{field?.reference?.title}</span>
    <Label>Collection</Label>
  </>
);

// Component to render product references
const ProductReference = ({ field }) => (
  <>
    <span>{field?.reference?.title}</span>
    <Label>Product</Label>
  </>
);

// Component to render rich text fields
const RichTextField = ({ field }) => {
  const content = JSON.parse(field.value);
  return (
    <>
      <span>
        {content.children?.map((child, index) => (
          <span key={index}>
            {child.children?.map((subChild, subIndex) => (
              <span key={subIndex} style={{ fontWeight: subChild.bold ? 'bold' : 'normal' }}>
                {subChild.value}
              </span>
            ))}
          </span>
        ))}
      </span>
      <Label>RichText</Label>
    </>
  );
};

// Default component for unknown reference types
const DefaultReference = ({ field }) => (
  <>
    <div>{field?.value}</div>
    <pre>{JSON.stringify(field, null, 2)}</pre>
  </>
);

// Mapping of reference types to components
const referenceComponents = {
  file_reference: FileReference,
  collection_reference: CollectionReference,
  product_reference: ProductReference,
  multi_line_text_field: MultiLineTextField,
  single_line_text_field: SingleLineTextField,
  color: ColorField,
  rich_text_field: RichTextField,
  // Add more mappings here
};

// Component to render a single reference field
const MetafieldReferenceField = ({ field }) => {
  const ReferenceComponent = referenceComponents[field.type] || DefaultReference;
  return (
    <td className="border p-2">
      <ReferenceComponent field={field} />
    </td>
  );
};

// Component to render lists of references
const MetafieldReferenceList = ({ field }) => (
  <td className="border p-2">
    {field.references.nodes?.map((node, index) => (
      <table key={index} className="mt-2 w-full border-collapse border">
        <tbody>
          {node.fields ? (
            node.fields?.map((subField, subIndex) => (
              <tr key={subIndex} className="border">
                <MetafieldReferenceField field={subField} />
              </tr>
            ))
          ) : (
            <tr className="border">
              <td className="border p-2">{node.title}</td>
            </tr>
          )}
        </tbody>
      </table>
    ))}
  </td>
);

// Component to render metaobject references
const MetaobjectReference = ({ field }) => (
  <td className="border p-2">
    {field?.reference?.fields?.map((subField, index) => (
      <tr key={index} className="border">
        <MetafieldReferenceField field={subField} />
      </tr>
    ))}
    {field?.reference ? (
      <tr className="border">
        <MetafieldReferenceField field={field} />
      </tr>
    ) : null}
  </td>
);

// Component to render metafield references
const MetafieldReferences = ({ references, type }) => (
  <td className="border p-2">
    {references?.map((reference, refIndex) => (
      <table key={refIndex} className="mt-2 w-full border-collapse border">
        <tbody>
          {reference?.fields ? (
            reference.fields.map((field, fieldIndex) => (
              <tr key={fieldIndex} className="border">
                <MetafieldReferenceField field={field} />
              </tr>
            ))
          ) : (
            <tr className="border">
              <td className="border p-2">{reference.title}</td>
            </tr>
          )}
        </tbody>
      </table>
    ))}
  </td>
);

// Component to render a single metafield
const Metafield = ({ metafield }) => {
  console.log('Metafield:', metafield);
  if(!metafield) {
    return <tr className="border">
      <td className="border p-2">No metafield available</td>
    </tr>
  }
  return (
    <tr className="border">
      <td className="border p-2 font-semibold">{metafield.key}</td>
      {metafield.type.startsWith('list.') ? (
        <MetafieldReferenceList field={metafield} />
      ) : metafield?.references?.nodes?.length > 0 ? (
        <MetafieldReferences references={metafield.references.nodes} type={metafield.type} />
      ) : metafield?.reference ? (
        <MetaobjectReference field={metafield} />
      ) : metafield?.value ? (
        <td className="border p-2">{metafield.value}</td>
      ) : null}
    </tr>
  );
};

// Main product preview component
const ProductPreview = ({ metafields }) => {
  // const product = data?.product;
  // const metafields = product?.metafields ?? [];

  console.log(JSON.stringify(metafields, null, 2));

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Product Preview</h2>
      <div className="mb-4">
        <h3 className="text-xl font-semibold">Metafields:</h3>
        {metafields.length > 0 ? (
          <table className="table-auto w-full border-collapse border">
            <thead>
              <tr className="border">
                <th className="border p-2">Key</th>
                <th className="border p-2">Value</th>
              </tr>
            </thead>
            <tbody>
              {metafields?.map((metafield, index) => (
                <Metafield key={index} metafield={metafield} />
              ))}
            </tbody>
          </table>
        ) : (
          <p>No metafields available.</p>
        )}
      </div>
    </div>
  );
};

export default ProductPreview;
