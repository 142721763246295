import { BASE_URL, MAIN_URL } from '@appmaker/core/api/core';
import axios from "axios";

const api = {
    appBuildStatus: async ({ projectId }) => {
        try {
            const response = await axios.get(`${BASE_URL}/projects/${projectId}/build-status`, {
                credentials: 'include',
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    buildIpa: async ({ projectId, appId }) => {
        try {
            const response = await axios.get(`${BASE_URL}/projects/${projectId}/build-ipa-file/?app_id=${appId}`, {
                credentials: 'include',
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    downloadAppLink: ({ projectId, appId, preview, token }) => {
        let url = `${MAIN_URL}/dashboard/apps/download/${appId}?preview=${preview}`;
        if (token !== undefined) {
            url += `&token=${token}`;
        }
        return url;
    },
    uploadIpa: async ({ projectId, appId }) => {
        try {
            const response = await axios.get(`${BASE_URL}/projects/${projectId}/ios-ipa-upload/?app_id=${appId}`, {
                credentials: 'include',
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    ipaUploadStatus: async ({ projectId, appId }) => {
        try {
            const response = await axios.get(`${BASE_URL}/projects/${projectId}/ios-ipa-upload-status/?app_id=${appId}`, {
                credentials: 'include',
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    uploadIosAuthKey: async ({ projectId,
        appId,
        authKey,
        apiKey,
        issuerId,
        file
    }) => {
        try {

            const data = new FormData();
            data.append('file', file);
            data.append('authKey', authKey);
            data.append('apiKey', apiKey);
            data.append('issuerId', issuerId);
            const url = `${BASE_URL}/projects/${projectId}/upload-ios-auth-key/?app_id=${appId}`;
            const response = await axios.post(url, data, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    iosCreateCertStatus: async ({ appId, projectId }) => {
        try {
            const url = `${BASE_URL}/projects/${projectId}/ios-create-cert-status/?app_id=${appId}`;
            const response = await axios.get(url, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default api;

