// @flow strict

import { ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';

function ActionBar({ attributes = {} }) {
	return (
		<div className="py-2 px-4 flex justify-between items-center border-gray-100 border-b space-x-2">
			<div className="flex items-center justify-center space-x-1">
				{attributes.leftIcon && <div className="bg-blue-800 p-2"></div>}
				{attributes.featureImg && (
					<img
						src={attributes.featureImg}
						alt="action-bar"
						className="h-6 w-6"
					/>
				)}
			</div>
			<div className="text-base font-medium flex-auto">
				<p>{attributes.title}</p>
				<p className="text-xs font-light text-gray-500">
					{attributes.subTitle}
				</p>
			</div>
			<div className="">
				{attributes.rightIcon ? (
					attributes.rightIcon
				) : (
					<ChevronRightIcon className="h-4 w-4" />
				)}
			</div>
		</div>
	);
}

ActionBar.attributesSchema = {
	type: 'object',
	properties: {
		title: {
			type: 'string',
			label: 'Title',
			default: '',
		},
		subTitle: {
			type: 'string',
			label: 'Sub Title',
			default: '',
		},
		featureImg: {
			type: 'string',
			label: 'Feature Image',
			default: '',
		},
		imageResize: {
			type: 'string',
			label: 'Image Resize Mode',
			default: 'cover',
		},
		leftIcon: {
			type: 'string',
			label: 'Left Icon Name',
			default: '',
		},
		rightIcon: {
			type: 'string',
			label: 'Right Icon',
			default: '',
		},
		type: {
			type: 'string',
			label: 'Type',
			default: '',
		},
		image: {
			type: 'string',
			label: 'Image',
			default: '',
		},
		noMargin: {
			type: 'string',
			label: 'Disable Margin Bottom',
			default: '',
		},
	},
};

export default ActionBar;
