import {
    MailIcon
} from '@heroicons/react/solid';
const ContactSupport = () => {
    return (
        <div className="py-16 flex flex-col justify-center items-center gap-6">
            <div className="flex gap-4">
                <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                    onClick={() => {
                        if (window && window.Intercom)
                            window.Intercom('showNewMessage')
                    }}
                >
                    <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Contact Support
                </button>
            </div>
        </div>
    )

}

export default ContactSupport