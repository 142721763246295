import { ChevronDownIcon, TrashIcon } from '@heroicons/react/outline';
import { useFormDataValue } from '@appmaker/core/index';
import {
	createPageStateStore,
	PageStateProvider,
	useFormData,
} from '@appmaker/core/components/BlocksView/state/PageState';
import BlocksView from '@appmaker/core/components/BlocksView';
import { InnerBlocks } from '@wordpress/block-editor';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './style.css';

function setClientId(defaultValue) {
	return defaultValue?.map((item) => {
		if (item?.___clientId) {
			return item;
		}
		return {
			...item,
			___clientId: uuidv4(),
		};
	});
}
function removeClientId(data) {
	return data.map((item) => {
		const { ___clientId, ...rest } = item;
		return rest;
	});
}

const InfiniteForm = ({ attributes = {} }) => {
	const { label = 'Label', addButtonText = 'Add' } = attributes;
	return (
		<div className="w-full border border-gray-200 border-dashed p-1">
			<div className="flex items-center justify-between mb-2">
				<h4 className="text-lg">{label}</h4>
				<button className="bg-indigo-100 hover:bg-indigo-300 text-indigo-700 font-bold py-1 px-4 rounded">
					{addButtonText}
				</button>
			</div>
			<InnerBlocks />
		</div>
	);
};

const InfiniteFormView = (props) => {
	const { attributes = {}, innerBlocks } = props;
	const { label = 'Label', addButtonText = 'Add', name } = attributes;
	const [visible, setVisible] = useState(false);
	const [value, setValueForm] = useFormDataValue({
		clientId: props.clientId,
		name,
	});

	const addInput = () => {
		const newInput = value
			? [
					...value,
					{
						___clientId: uuidv4(),
					},
			  ]
			: [{ ___clientId: uuidv4() }];
		setValueForm(newInput);
	};
	const removeInput = (item) => {
		const index = value.findIndex((e) => e.___clientId === item.___clientId);
		const newArray = [...value];
		// remove the item at index from newArray
		newArray.splice(index, 1);
		setValueForm(newArray);
	};
	const onChangeFormData = (data, index) => {
		const newArray = [...value];
		newArray[index] = data;
		setValueForm(newArray);
	};

	return (
		<div className="w-full p-1">
			<div className="flex items-center justify-between mb-2">
				<h4 className="text-lg">{label}</h4>
				<button
					onClick={addInput}
					className="bg-indigo-100 hover:bg-indigo-300 text-indigo-700 font-bold py-1 px-4 rounded"
				>
					{addButtonText}
				</button>
			</div>
			<button
				className={clsx(
					'p-2 flex items-center justify-between w-full bg-white border',
					{
						'rounded shadow-md border-transparent': !visible,
						'rounded-t  border-gray-200': visible,
					}
				)}
				onClick={() => setVisible(!visible)}
			>
				<span>{label}</span>
				<ChevronDownIcon
					className={clsx('h-5 w-5 transform transition-transform', {
						'rotate-180': visible,
					})}
				/>
			</button>
			{value &&
				setClientId(value).map((item, index) => (
					<div
						key={`${item?.___clientId}-infinite-form`}
						className="px-2 border-l border-b border-r border-gray-200 rounded-t-none rounded py-2 mb-2 border-t-0"
					>
						<InnerBlockTemplateWrapper
							key={`${item?.___clientId}-infinite-form-inner-block`}
							value={item}
							clientId={item.___clientId}
							innerBlocks={innerBlocks}
							setValue={(data) => {
								onChangeFormData(data, index);
							}}
						/>
						<button
							className="bg-red-200 text-red-600 text-sm p-1 rounded inline-flex space-x-1 items-center"
							onClick={() => {
								removeInput(item);
							}}
						>
							<TrashIcon className="h-5 w-5 " />
							<span>Delete</span>
						</button>
					</div>
				))}
		</div>
	);
};

function Debug() {
	const [formData, setFormData] = useFormData();
	return (
		<div className="p-4 bg-gray-100">
			<pre>{JSON.stringify(formData, null, 2)}</pre>
			<button
				onClick={() =>
					setFormData({
						buildName: 'test',
					})
				}
			>
				Clear
			</button>
		</div>
	);
}
const InnerBlockTemplateWrapper = (props) => {
	const { value } = props;
	return (
		<PageStateProvider createStore={createPageStateStore({ formData: value })}>
			<InnerBlockTemplate {...props} />
		</PageStateProvider>
	);
};
const InnerBlockTemplate = ({
	attributes = {},
	clientId,
	innerBlocks = {},
	setValue = () => {},
}) => {
	const [formData] = useFormData();
	useEffect(() => {
		setValue(formData);
	}, [formData]);
	return (
		<>
			<BlocksView
				inAppPage={{
					blocks: innerBlocks,
				}}
			/>
			{/* <Debug /> */}
		</>
	);
};

InfiniteForm.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		addButtonText: {
			type: 'string',
			default: 'Add',
			label: 'Add Button Text',
		},
	},
};

export { InfiniteFormView };
export default InfiniteForm;
