import React, { useEffect, useState } from 'react'
import clsx from 'clsx';

import {
    useRouteMatch,
    Link,
    generatePath,
    useLocation,
    matchPath
} from '@appmaker/core/routes';
import coreApi from '@appmaker/core/api/core';
import { useLanguageFormData, setCurrentLanguage, setTranslationSchema } from './store/formData';
import { ContentTabEditor } from './ContentTabEditor';
import Loading from '@appmaker/components/skeltonLoading/Blocks';

export default function ContentTabs({ onChange = () => { } }) {
    let { path, params } = useRouteMatch();
    const [loading, setLoading] = useState(true)
    let location = useLocation();
    const match = matchPath(location.pathname, {
        path: `${path}/:languageTab`,
    })
    const translationSchema = useLanguageFormData(data => data.translationSchema)
    const { projectId, languageId, } = params;
    const currentTab = match?.params?.languageTab;
    useEffect(() => {
        setCurrentLanguage(languageId)
    }, [languageId])


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await coreApi.getTranslationTab(projectId, languageId);
                if (response.status) {
                    setTranslationSchema(response.schema || {});
                }

            } catch (error) {

            } finally {
                setLoading(false);

            }

        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (loading) {
        return <Loading />
    }
    const { properties = {} } = translationSchema || {};
    const tabsArray = Object.keys(properties);
    return (
        <>
            <div
                className="-mb-px flex space-x-8 bg-gray-50 px-4 sticky top-0 z-50">
                {tabsArray && tabsArray.map((item, key) => (
                    <Link to={`${generatePath(path, { ...params })}/${item}`}
                        className={
                            clsx(
                                'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm',
                                item === currentTab
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            )
                        }
                    >
                        {properties[item].name}
                    </Link>
                ))}
                <div>
                </div>
            </div>
            <ContentTabEditor
                tabsArray={tabsArray}
            />
        </>

    )
}
