const StepNine = ()=>{
    return (
        <div>
        <ul className="list-outside ml-4 list-disc flex flex-col gap-3">
            <li>
            upload the .aab file that you have downloaded from your Appmaker Dashboard

                <img
                    className="w-full rounded-lg border border-gray-100 mt-1"
                    src="/images/publish/android/new/step-9-1.png"
                    alt="step one"
                />
            </li>
            <li>
            While the file is uploading, enter the Release name under Release Details and enter the Release notes and click Review release
                <img
                    className="w-full rounded-lg border border-gray-100 mt-1"
                    src="/images/publish/android/new/step-9-2.png"
                    alt="step one"
                />
            </li>

        </ul>
    </div>
    )
}

export default StepNine