import appmaker from '@appmaker/core/index';
import { lazy } from 'react';
import Home from './routes/Home';
import { CloudDownloadIcon } from '@heroicons/react/solid';

const plugin = {
	id: '@appmaker/download-app',
	name: 'Download App',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/download-app',
			component: Home,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Download App',
			routeName: '/download-app',
			icon: CloudDownloadIcon,
		});
	},
};

export default plugin;
