import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import debounce from 'lodash/debounce';

export const PromiseSelect = ({
	properties,
	promiseOptions = () => {},
	onChange = () => {},
	defaultValue,
	_internalValue,
	...props
}) => {
	const { label, isSearchable = false, isCreatable = false } = properties || {};
	const loadOptions = useCallback(
		debounce((inputValue, callback) => {
			promiseOptions(inputValue).then((options) => {
				if (isSearchable) {
					const newOptions = options.filter((option) =>
						option?.label.toLowerCase().includes(inputValue?.toLowerCase())
					);
					if (isCreatable && newOptions.length === 0) {
						callback([{ label: inputValue, value: inputValue }]);
					} else {
						callback(
							options.filter((option) =>
								option?.label.toLowerCase().includes(inputValue?.toLowerCase())
							)
						);
					}
				} else {
					callback(options);
				}
			});
		}, 500),
		[]
	);
	// debounce(_loadSuggestions, 1000);
	const isMulti = properties.isMulti === true;
	if (isCreatable) {
		return (
			<div className={label && 'mt-4'}>
				{label && (
					<label className="block text-sm font-base text-gray-700 mb-1">
						{label}
					</label>
				)}
				<AsyncCreatableSelect
					cacheOptions
					defaultOptions
					loadOptions={loadOptions}
					isMulti={isMulti}
					onChange={(selection) => {
						onChange(isMulti ? selection : selection.value, selection);
					}}
					defaultValue={_internalValue ?? defaultValue}
					{...props}
				/>
			</div>
		);
	}
	return (
		<div className={label && 'mt-4'}>
			{label && (
				<label className="block text-sm font-base text-gray-700 mb-1">
					{label}
				</label>
			)}
			<AsyncSelect
				cacheOptions
				defaultOptions
				isMulti={isMulti}
				loadOptions={loadOptions}
				onChange={(selection) => {
					onChange(isMulti ? selection : selection.value, selection);
				}}
				defaultValue={_internalValue ?? defaultValue}
				{...props}
			/>
		</div>
	);
};
