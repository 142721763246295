import { useParams } from '@appmaker/core/routes';
import Notification from '@appmaker/components/ReactHotToastIntegrator';
import {useTransform} from '../hooks';
import Loader from '@appmaker/components/Loading';
import EditForm from '../components/Forms';
const EditTransforms = ()=>{
    const { transformId } = useParams();
    const createNewTransform = transformId === undefined;
    const { isLoading, transformList = [] } = useTransform({ refetch: false })
    let formData = {};
    if (!createNewTransform) {
        formData = transformList.find(transforms => parseInt(transforms.id) === parseInt(transformId)) || {};
        formData = {
            ...formData,
            value: formData.value ? JSON.stringify(formData.value) : '',
        }
    }
    if (isLoading) {
        return <Loader />
    }

    return(
        <div className="bg-gray-50 h-screen overflow-y-auto">
        <div className="w-full mx-auto bg-white ">
            <div className="flex items-center justify-between space-x-3 bg-gray-50 px-8 py-6">
                <div className="flex items-center space-x-3"></div>
                <div className="flex justify-end">
                    {/* <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Cancel
                    </button> */}
                </div>
            </div>
            <div className="bg-gray-50">
                <Notification>
                    <EditForm formValue={formData} isNew={createNewTransform}/>
                </Notification>
            </div>
        </div>
    </div>
    )
}

export default EditTransforms;