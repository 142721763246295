import { getProjectData } from "@appmaker/core/store/project"
import { Redirect } from '@shopify/app-bridge/actions';



const RedirectV3 = ({ id = 'extension' }) => {

    const data = getProjectData();
    const { projectData = {}, projectId } = data;
    const { migration = {} } = projectData
    const { isExtensionInstallMigrated = false, isThemeInstallMigrated = false } = migration;

    const redirect = (url)=>{
        if (!window.shopifyApp) {
            window.location.href = url;
        } else if (window.shopifyApp) {
            const redirect = Redirect.create(window.shopifyApp);
            redirect.dispatch(Redirect.Action.REMOTE, url);
        }
    }

    if (id === 'extension' && !isExtensionInstallMigrated) {
        redirect('https://v3.appmaker.xyz/apps/' + projectId + '/extensions')
    }
    if (id === 'theme' && !isThemeInstallMigrated) {
        redirect('https://v3.appmaker.xyz/apps/' + projectId + '/themes')
    }
    return null;
}

export default RedirectV3