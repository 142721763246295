import { PlusCircleIcon } from '@heroicons/react/solid';
import { useHistory } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';

const Header = () => {
    const history = useHistory();
    const getRouterPath = useRouterPath();

    const onClickAdd = () => {
        const url = getRouterPath('/actions/edit');
        history.push(url);
    }
    return (
        <div className="flex items-center justify-between space-x-3 bg-gray-50 px-8 py-6">
            <div className="flex items-center space-x-3">
            </div>
            <button
                className="inline-flex justify-self-end bg-blue-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                onClick={onClickAdd}
            >
                <PlusCircleIcon height={20} width={20} className="" />
                <p className='pl-2'>Add action</p>
            </button>
        </div>
    )
}

export default Header;