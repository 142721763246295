import clsx from 'clsx';

const BlockCard = ({ attributes = {}, children, titleEditor }) => {
	const {
		title = 'Title',
		accessButton,
		showTitle,
		backgroundColor = '#ffffff',
	} = attributes;
	return (
		<div
			className={clsx('w-full mb-1')}
			style={{ backgroundColor: backgroundColor }}
		>
			<div className="flex items-center justify-between px-4 pt-2">
				{showTitle &&
					(titleEditor ? (
						titleEditor
					) : (
						<h4 className="text-md font-medium flex-1">{title}</h4>
					))}
				{accessButton && (
					<p className="text-sm text-blue-600 text-left">{accessButton}</p>
				)}
			</div>
			{children}
		</div>
	);
};

export default BlockCard;
