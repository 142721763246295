import clsx from 'clsx';
import LoadingIcon from 'packages/mobile-app/components/LoadingIcon';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
const FileUploadIcon = () => (
	<svg
		className="mx-auto h-12 w-12 text-gray-400 group-hover:text-blue-400"
		stroke="currentColor"
		fill="none"
		viewBox="0 0 48 48"
		aria-hidden="true"
	>
		<path
			d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

const FileUpload = (props) => {
	const {
		formContext = {},
		name,
		onChange,
		schema: { title, helperText, inner } = {},
		uiSchema = {},
		multiple = false,
	} = props;
	const option = uiSchema['ui:options'];
	const { accept } = option || {};
	const { onFileUpload } = formContext;
	const [isLoading, setLoading] = useState(false);
	const [previewFile, setPreviewFile] = useState(undefined);
	const onDrop = async (files) => {
		try {
			if (files && files[0]) {
				setLoading(true);
				const url = URL.createObjectURL(files[0]);
				onChange(url);
				setPreviewFile(url);
				await onFileUpload(name, files);
			} else {
			}
		} catch (error) {
			console.log('error', error);
		}
		setLoading(false);
	};

	return (
		<div className={clsx('w-full', !inner && 'mt-4')}>
			<span className="font-regular text-sm mb-1 block">{title}</span>
			<Dropzone onDrop={onDrop} multiple={multiple} style={{}} accept={accept}>
				{({ getRootProps, getInputProps }) => (
					<div
						{...getRootProps({
							className: '',
						})}
					>
						<label
							htmlFor="file-upload"
							className="group flex justify-center px-6 pt-5 pb-6 border border-gray-300 bg-gray-50 border-dashed rounded-md cursor-pointer group-hover:border-blue-400"
						>
							<div className="space-y-1 text-center">
								{previewFile ? (
									<img
										src={previewFile}
										alt="uploaded file"
										className="mx-auto"
										height="100"
										width="100"
									/>
								) : (
									<FileUploadIcon />
								)}
								<div className="flex text-sm text-gray-600">
									<span className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
										<span>
											{isLoading ? (
												<span className="flex gap-1 items-center">
													<LoadingIcon className="animate-spin" /> Uploading..
												</span>
											) : (
												'Upload a file'
											)}
										</span>
										<input {...getInputProps()} className="sr-only" />
									</span>
									{!isLoading && <p className="pl-1">or drag and drop</p>}
								</div>
								<p className="text-xs text-gray-500">{helperText}</p>
							</div>
						</label>
					</div>
				)}
			</Dropzone>
		</div>
	);
};

export default FileUpload;
