import LoadingIcon from '@appmaker/components/LoadingIcon';
import {
	CheckCircleIcon,
	ClipboardIcon,
	DocumentTextIcon,
	DownloadIcon,
	RefreshIcon,
	XCircleIcon,
} from '@heroicons/react/solid';
import {
	AndroidLogo,
	IosLogo,
} from 'packages/mobile-app/components/DownloadAppCards';

const head = [
	{
		name: 'Platform',
		className:
			'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
	},
	{
		name: 'Date & Time',
		className:
			'hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell',
	},

	{
		name: 'Build Name',
		className:
			'hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell',
	},
	{
		name: 'Template Name',
		className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
	},
	{
		name: 'Build From',
		className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
	},
	{
		name: 'Build Type',
		className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
	},
	{
		name: 'Triggered By',
		className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
	},
	{
		name: 'Status',
		className: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
	},
];

const builds = [
	{
		platform: 'iOS',
		date: '2021-05-01',
		time: '12:00:00',
		templateName: 'shopify-main-beta',
		buildName: 'New Version release',
		buildFrom: 'Source Code',
		buildType: 'Release',
		triggeredBy: 'user',
		status: 'success',
		version: '1.0.0',
		build: '1',
		download: 'https://www.google.com',
	},
	{
		platform: 'Android',
		date: '2021-05-01',
		time: '12:00:00',
		templateName: 'shopify-main-beta',
		buildName: 'New Version release',
		buildFrom: 'Source Code',
		buildType: 'Beta',
		triggeredBy: 'user',
		status: 'building',
		version: '1.0.0',
		build: '1',
		download: 'https://www.google.com',
	},
	{
		platform: 'Android',
		date: '2021-05-01',
		time: '12:00:00',
		templateName: 'shopify-main-beta',
		buildName: 'New Version release',
		buildFrom: 'Source Code',
		buildType: 'Release',
		triggeredBy: 'user',
		status: 'failed',
		version: '1.0.0',
		build: '1',
		download: 'https://www.google.com',
	},
	{
		platform: 'iOS',
		date: '2021-05-01',
		time: '12:00:00',
		templateName: 'shopify-main-beta',
		buildName: 'New Version release',
		buildFrom: 'Source Code',
		buildType: 'Debug',
		triggeredBy: 'user',
		status: 'success',
		version: '1.0.0',
		build: '1',
		download: 'https://www.google.com',
	},
	{
		platform: 'Android',
		date: '2021-05-01',
		time: '12:00:00',
		templateName: 'shopify-main-beta',
		buildName: 'New Version release',
		buildFrom: 'Source Code',
		buildType: 'Release',
		triggeredBy: 'user',
		status: 'success',
		version: '1.0.0',
		build: '1',
		download: 'https://www.google.com',
	},
];

function Table() {
	return (
		<>
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">App Builds</h1>
					<p className="mt-2 text-sm text-gray-700">
						Manage your app builds and trigger new builds.
					</p>
				</div>
			</div>
			<div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
						<tr>
							{head.map((item) => (
								<th scope="col" className={item.className}>
									{item.name}
								</th>
							))}
							<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
								<span className="sr-only">Actions</span>
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{builds.map((build) => (
							<tr
								key={build.platform}
								onClick={() => console.log('TO BUILD DETAILS')}
								className="cursor-pointer hover:bg-gray-50"
							>
								<td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
									<span className="inline-flex items-center space-x-1 text-xs">
										{build.platform === 'iOS' ? (
											<IosLogo className="text-gray-800 h-5 w-5" />
										) : (
											<AndroidLogo className="text-green-600 h-5 w-5" />
										)}
										<span>{build.platform}</span>
									</span>
								</td>
								<td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell">
									{build.date} {build.time}
								</td>
								<td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
									{build.buildName}
								</td>
								<td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
									{build.templateName}
								</td>
								<td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
									{build.buildFrom}
								</td>
								<td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
									{
										{
											Release: (
												<span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
													Release
												</span>
											),
											Debug: (
												<span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
													Debug
												</span>
											),
											Beta: (
												<span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
													Beta
												</span>
											),
										}[build.buildType]
									}
								</td>
								<td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
									{build.triggeredBy}
								</td>
								<td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
									{
										{
											building: (
												<span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
													<LoadingIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-yellow-500 animate-spin" />
													Building
												</span>
											),
											failed: (
												<span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
													<XCircleIcon
														className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
														aria-hidden="true"
													/>
													Failed
												</span>
											),
											success: (
												<span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
													<CheckCircleIcon
														className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
														aria-hidden="true"
													/>
													Success
												</span>
											),
										}[build.status]
									}
								</td>
								<td className="whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex space-x-2">
									{build.status === 'success' && (
										<button className="text-green-600 hover:text-green-900 p-0.5">
											<DownloadIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									)}
									{build.status !== 'building' && (
										<button className="text-blue-600 hover:text-blue-900 p-0.5">
											<RefreshIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									)}
									{build.status !== 'failed' && (
										<button className="text-indigo-600 hover:text-indigo-900 p-0.5">
											<ClipboardIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									)}
									{build.status === 'failed' && (
										<button className="text-gray-600 hover:text-gray-900 p-0.5">
											<DocumentTextIcon
												className="h-5 w-5"
												aria-hidden="true"
											/>
										</button>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}

export default function AppBuildTrigger() {
	return (
		<div className="px-4 sm:px-6 lg:px-8 pt-6">
			<div className="grid grid-cols-3 gap-4">
				<div>
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<div className="mt-1">
							<input
								type="email"
								name="email"
								id="email"
								className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
								placeholder="you@example.com"
							/>
						</div>
					</div>
				</div>
				<div className="col-span-2">
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<div className="mt-1">
							<input
								type="email"
								name="email"
								id="email"
								className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
								placeholder="you@example.com"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="my-6">
				<Table />
			</div>
		</div>
	);
}
