import clsx from 'clsx';
import { useData } from '../hooks';
import { useEffect, useState } from 'react';
import {
	ChatIcon
} from '@heroicons/react/outline';

const SideBar = ({ data = [], active }) => {
	const {
		originalTemplate: templates = [],
		setTemplate = () => { },
		setSelectedThemeCategory = () => { },
		resetTemplate = () => { },
		selectedThemeCategory
	} = useData();
	const [sideBar, setSideBar] = useState([]);
	useEffect(() => {
		let temp = {}
		templates.map(item => {
			temp[item.category] = item;
		})

		let category = [];
		category.push('All Categories');
		category = [...category, ...Object.keys(temp)]
		setSideBar([...new Set(category)]);
	}, [templates])
	return (
		<div className="flex flex-col justify-between h-full pr-4">
			<div className='flex-1 flex flex-col items-start space-y-2'>
				{data.title ? (
					<h4 className="text-base text-gray-600 uppercase font-medium tracking-widest ml-2">
						{data.title}
					</h4>
				) : null}
				{sideBar && sideBar.map((item, i) => (
					<button
						key={i}
						className={clsx(
							'py-2 px-4 inline-flex rounded-full text-sm font-medium hover:bg-gray-300',
							selectedThemeCategory === item && 'bg-gray-200 '
						)}
						onClick={() => {
							if (item === 'All Categories') {
								resetTemplate(undefined)
							} else {
								setTemplate(templates.filter(temp => temp.category === item))
								setSelectedThemeCategory(item)
							}

						}}
					>
						{item}
					</button>
				))}
			</div>
			<button className="text-xs text-white uppercase font-medium tracking-widest inline-flex items-center justify-center space-x-2 bg-gray-500 bg-opacity-70 px-2 py-1 rounded-md"
				onClick={() => {
					if (window.Intercom) {
						window.Intercom('show');
					}
				}}
			>
				<ChatIcon className="h-6 w-6" />
				<span>Talk to expert</span>
			</button>
		</div>
	);
};
export default SideBar