import { useEffect, useState } from 'react';
import { getShopifyData } from '../utils';
import axios from 'axios';

const gqlQuery = `
query ($query: String) {
    products(first: 150, query: $query) {
      edges {
        node {
          id
          title
          images(first: 10) {
            edges {
              node {
                src
                id
                height
                width
                url
              }
            }
          }
          priceMeta: priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

const useProducts = () => {
	const { projectId, url } = getShopifyData('products');
	const [isLoading, setIsLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const fetchProduct = async (query) => {
		setIsLoading(true);
		try {
			const response = await axios.get(!query ? url : url + '?search=' + query, {
				headers: {
					'Content-Type': 'application/json',
				},
				withCredentials: true,
				credentials: 'include',
			});
			setProducts(response?.data?.body);
		} catch (error) {
			console.error(error);
		}
		setIsLoading(false);
	};
	const search = (query) => {
		fetchProduct(query);
	};
	useEffect(() => {
		fetchProduct();
	}, [url, projectId]);
	return { loading: isLoading, products, onSearch: search };
};

export default useProducts;
