import { useState, useEffect } from 'react';
import { StarIcon } from '@heroicons/react/solid';
import { products } from '../../../../api';
import { useAsyncFn } from 'react-use';
import BlockCard from '../../../../new-blocks/components/BlockCard';
import ProductsScrollerItem from 'packages/oldBlocks/new-blocks/components/ProductsScrollerItem';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function ProductListView({ collectionId }) {
	// const [_products, setProducts] = useState([])
	const [{ loading, value: _products }, doFetch] = useAsyncFn(
		async (collectionId) => {
			try {
				const response = await products({ search: collectionId });
				return response;
			} catch (error) {
				console.log(error);
			}
		},
		[]
	);
	useEffect(() => {
		doFetch(collectionId);
	}, [collectionId]);
	return (
		<BlockCard attributes={{}}>
			{/* Product grid */}
			<div className="overflow-x-auto whitespace-nowrap flex gap-4 px-4 pb-4">
				{!loading &&
					_products &&
					_products.map((product) => (
						<ProductsScrollerItem
							key={product.id}
							attributes={{
								product: product,
								groceryMode: true,
								quickAdd: false,
							}}
						/>
					))}
			</div>
		</BlockCard>
	);
}
