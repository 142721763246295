import { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

function getValue(type, item) {
	if (typeof item === 'string') return item;
	return type === 'string' ? item?.value : item;
}

const ImageRadio = ({ properties = {}, onChange, defaultValue }) => {
	const { title, options = [], type, className } = properties;
	const [selected, setSelected] = useState(defaultValue);

	useEffect(() => {
		onChange && onChange(getValue(type, selected));
	}, [selected]);

	return (
		<RadioGroup
			value={selected}
			onChange={setSelected}
			className={className ? className : 'mt-4'}
		>
			<RadioGroup.Label className="block text-sm font-base text-gray-700">
				{title}
			</RadioGroup.Label>

			<div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
				{options.map((item, itemIdx) => (
					<RadioGroup.Option
						key={item.label}
						value={getValue(type, item)}
						className={({ checked, active }) =>
							clsx(
								checked ? 'border-transparent' : 'border-gray-300',
								active ? 'border-indigo-600 ring-2 ring-indigo-600' : '',
								'relative cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
							)
						}
					>
						{({ checked, active }) => (
							<>
								<span className="flex flex-1">
									<span className="flex flex-1 flex-col">
										<RadioGroup.Label
											as="span"
											className="block text-sm font-medium text-gray-900"
										>
											{item.label}
										</RadioGroup.Label>
										<RadioGroup.Description
											as="span"
											className="mt-1 flex items-center text-sm text-gray-500"
										>
											{item.description}
										</RadioGroup.Description>
									</span>
									<CheckCircleIcon
										className={clsx(
											!checked ? 'invisible' : '',
											'h-5 w-5 text-indigo-600'
										)}
										aria-hidden="true"
									/>
								</span>
								<RadioGroup.Description
									as="div"
									className="mt-2 text-sm font-medium text-gray-900"
								>
									<img
										src={item.imgSrc}
										className="w-full object-contain"
										alt={item.title}
									/>
								</RadioGroup.Description>
								<span
									className={clsx(
										active ? 'border' : 'border-2',
										checked ? 'border-indigo-600' : 'border-transparent',
										'pointer-events-none absolute -inset-px rounded-lg'
									)}
									aria-hidden="true"
								/>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
};

export default ImageRadio;
