import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import Notification, {
    sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';
import { useProject } from '@appmaker/core/hooks';
import api from '../api';

const CloudFareCache = () => {
    const [isCacheClearing, setCacheClearing] = useState(false);
    const { projectId } = useProject();

    const clearCache = async () => {
        setCacheClearing(true);
        try {
            const response = await api.purgeCache({ projectId });
            sendNotification('Cache cleared !!', 'success')
        } catch (error) {
            sendNotification('Something went wrong !!', 'error')
        }
        setCacheClearing(false);
    }
    return (
        <Notification>
            <div className='m-4 mt-10 flex flex-rows align-center gap-4'>
                <div className="flex flex-col items-center" >
                    <label className="text-gray-500 text-xs font-medium uppercase tracking-wide">Clear Cloudfare cache for this project</label>
                    <button
                        disabled={isCacheClearing}
                        className="mt-5 justify-center inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={() => {
                            clearCache();
                        }}
                    >
                        {!isCacheClearing && (<>
                            <TrashIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                            <span>Clear Cache</span>
                        </>)}
                        {isCacheClearing && (<><span>Clearing ....</span></>)}
                    </button>
                </div>
            </div>
        </Notification>
    )
}

export default CloudFareCache