export const PRODUCT_QUERY = `#graphql
fragment MetafieldData on Metafield {
  type
  namespace
  key
  value
  reference {
    ...MetafieldReferenceConnectionData
  }
  references(first: 20) {
    nodes {
      ...MetafieldReferenceConnectionData
    }
  }
}

fragment MetafieldReferenceConnectionData on MetafieldReference {
  ...MetaobjectFields
  ...CollectionRef
  ...MediaData
  ...ProductData
  ...VideoData
  ...ProductVariantData
}

fragment ProductData on Product {
  id
  handle
  title
}

fragment VideoData on Video {
  id
  previewImage {
    url
  }
  sources {
    url
  }
}

fragment ProductVariantData on ProductVariant {
  id
  title
}

fragment MediaData on MediaImage {
  id
  image {
    url(transform: {})
  }
}

fragment CollectionRef on Collection {
  id
  handle
  title
}


fragment MetafieldReferenceData on MetafieldReference {
  ...CollectionRef
  ...MediaData
  ...ProductData
  ...VideoData
  ...ProductVariantData
}


fragment MetaObjectFieldData on MetaobjectField {
  type
  key
  value
  reference {
    ...MetafieldReferenceData
  }
  references(first: 20) {
    nodes {
      ...MetafieldReferenceData
    }
  }
}

fragment MetaobjectFields on Metaobject {
  fields {
    ...MetaObjectFieldData
  }
}

query ViewDataHelper($handle: String, $ids: [HasMetafieldsIdentifier!]!) {
  product(handle: $handle) {
    id
    metafields(identifiers: $ids) {
      ...MetafieldData
    }
  }  
  collection(handle: $handle) {
    id
    metafields(identifiers: $ids) {
      ...MetafieldData
    }
  }
}
`;
