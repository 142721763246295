import { DASHBOARD_GRAPHQL_URL } from '@appmaker/core/api/core';
import axios from 'axios';

export const getCustomBlockFromPartners = async ({ projectId }) => {
	const query = `
	query {
		customBlocksFromPartners {
		  id
		  name
		  description
		  title
		  imageUrl
		  attributesSchema
		  config
		}
	  }
	`;
	try {
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
			JSON.stringify({
				query,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response?.data;
	} catch (error) {
		return [];
	}
};
