import { usePageState } from "../state/PageState";

// export function useBlockItem({
//     attributes,
//     clientId,

// }) {
//     const { name } = attributes;
//     const setFormData = usePageState((state) => state.setFormData);
//     // const BlockView = appmakerBlocks[name] && (appmakerBlocks[name].View || appmakerBlocks[name].Edit);
//     return {
//         BlockView,
//         name,
//         clientId,
//     };   
// }

export function useFormDataValue({
    clientId,
    name,
}) {
    const formFieldName = name || clientId;
    const value = usePageState((state) => state.formData[formFieldName]);
    const _setValue = usePageState((state) => state.setFormDataValue);
    const setValue = (value) => {
        _setValue(formFieldName, value);
    }
    return [
        value,
        setValue,
    ];
}

export function useFormData() {
    const formData = usePageState((state) => state.formData);
    return formData;
}
