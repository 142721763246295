import { Fragment, useEffect, useState } from 'react'
import { Transition, Combobox, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Select({
  label = 'Select',
  options: defaultOptions = [],
  value: defaultValue,
  onChange = () => { },
  onSearch = () => { }
}) {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(defaultValue || {})
  const [query, setQuery] = useState('')

  useEffect(() => {
    onChange(selected)
  }, [selected])
  useEffect(() => {
    onSearch(query)
  }, [query])
  useEffect(() => {
    setOptions(defaultOptions)
  }, [
    defaultOptions
  ])
  return (
    <Combobox value={selected} onChange={setSelected}>
      <span className="text-sm text-gray-600">{label}</span>
      <div className="relative mt-0.5 mb-4">
        <div className="relative flex items-center w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          {selected.imageUrl ? <img src={selected.imageUrl} alt="" className="h-6 w-6 flex-shrink-0 rounded-full ml-3 object-cover" /> : null}
          <Combobox.Input
            className="flex-1 border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            displayValue={(selected) => selected.label}
            placeholder="Search here"
            onChange={(event) => {
              setQuery(event.target.value)
            }}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => { }}
        >
          <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              options.map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 flex items-center ${active ? 'text-black bg-gray-50' : 'text-gray-900'
                    }`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <img src={option.imageUrl} alt="" className="h-6 w-6 flex-shrink-0 rounded-full mr-2 object-cover" />
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-black' : 'text-teal-600'
                            }`}
                        >
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
//   return (
//     <Listbox value={selected} onChange={setSelected}>
//       {({ open }) => (
//         <>
//           <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
//           <div className="relative mt-1">
//             <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
//               <span className="flex items-center">
//                 <img src={selected.imageUrl} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
//                 <span className="ml-3 block truncate">{selected.label}</span>
//               </span>
//               <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
//                 <ArrowDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
//               </span>
//             </Listbox.Button>

//             <Transition
//               show={open}
//               as={Fragment}
//               leave="transition ease-in duration-100"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

//                 {options && options.map((option) => (
//                   <Listbox.Option
//                     key={option.id}
//                     className={({ active }) =>
//                       classNames(
//                         active ? 'text-white bg-indigo-600' : 'text-gray-900',
//                         'relative cursor-default select-none py-2 pl-3 pr-9'
//                       )
//                     }
//                     value={option}
//                   >

//                     {({ selected, active }) => (
//                       <>
//                         <div className="flex items-center">
//                           <img src={option.imageUrl} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
//                           <span
//                             className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
//                           >
//                             {option.label}
//                           </span>
//                         </div>

//                         {selected ? (
//                           <span
//                             className={classNames(
//                               active ? 'text-white' : 'text-indigo-600',
//                               'absolute inset-y-0 right-0 flex items-center pr-4'
//                             )}
//                           >
//                             <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                           </span>
//                         ) : null}
//                       </>
//                     )}
//                   </Listbox.Option>
//                 ))}
//               </Listbox.Options>
//             </Transition>
//           </div>
//         </>
//       )}
//     </Listbox>
//   )
// }
