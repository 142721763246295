import { ClockIcon } from '@heroicons/react/outline';
import { InnerBlocks } from '@wordpress/block-editor';
import { useCountdown } from '../hooks/useCountdown';

const ScheduleBlock = ({ attributes }) => {
	const { name, startTime, endTime } = attributes;
	const [startDays, startHours, startMinutes, startSeconds] =
		useCountdown(startTime);
	const [endDays, endHours, endMinutes, endSeconds] = useCountdown(endTime);
	const startTimeEnd =
		startDays + startHours + startMinutes + startSeconds <= 0;
	const endTimeEnd = endDays + endHours + endMinutes + endSeconds <= 0;

	const formatTimeUnit = (unit) => {
		return unit < 10 ? `0${unit}` : unit;
	};

	return (
		<div className="w-full border border-green-500 relative flex flex-col">
			<p className="text-center bg-green-500 text-white text-sm inline-flex self-start items-center space-x-1 px-2 rounded-br-md">
				<ClockIcon className="h-4 w-4 text-white" />
				<span>{name || 'Scheduled Block'}</span>
			</p>
			{endTime && endTimeEnd && (
				<div className="absolute z-10 bg-gray-900 bg-opacity-40 h-full w-full flex flex-col items-center justify-center text-white">
					<p>Block expired</p>
					<small>Adjust the schedule to see/edit the block</small>
				</div>
			)}
			{!startTimeEnd && (
				<p className="text-xs rounded-bl-md px-2 bg-gray-100 border-gray-600 absolute right-0 top-0">
					{startTime ? (
						<span className="text-green-600">
							{`Shows in ${
								startDays > 0 ? `${formatTimeUnit(startDays)}:` : ''
							}${formatTimeUnit(startHours)}:${formatTimeUnit(
								startMinutes
							)}:${formatTimeUnit(startSeconds)}`}
						</span>
					) : (
						<span className="text-red-600">Not Scheduled</span>
					)}
				</p>
			)}
			{!endTimeEnd && startTimeEnd && (
				<p className="text-xs rounded-bl-md px-2 bg-gray-100 border-gray-600 absolute right-0 top-0">
					{endTime ? (
						<span className="text-blue-600">{`Hides in ${
							endDays > 0 ? `${formatTimeUnit(endDays)}:` : ''
						}${formatTimeUnit(endHours)}:${formatTimeUnit(
							endMinutes
						)}:${formatTimeUnit(endSeconds)}`}</span>
					) : (
						<span className="text-blue-600">End: Not Scheduled</span>
					)}
				</p>
			)}
			<div className="pt-1">
				<InnerBlocks />
			</div>
		</div>
	);
};

ScheduleBlock.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			label: 'Name',
		},
		startTime: {
			type: 'string',
			uiType: 'date-time',
			label: 'Time to show',
			minDate: '2024-01-01',
			maxDate: '2026-01-01',
		},
		endTime: {
			type: 'string',
			uiType: 'date-time',
			label: 'Time to hide',
			minDate: '2024-01-01',
			maxDate: '2026-01-01',
		},
		refreshRequired: {
			type: 'string',
			uiType: 'switch',
			label: 'Show changes immediately (beta)',
			description:
				'Enable this option if you want to see the changes immediately after the scheduled time',
		},
	},
};

export default ScheduleBlock;
