import { entity } from 'simpler-state';

export const formData = entity({
	oathAccessToken: undefined,
	currentFirebaseProjectId: undefined,
});

export const useFormData = (args) => formData.use(args);
export const setFormData = (data) => {
	formData.set(data);
};

export const setAccessToken = (data) =>
	formData.set((value) => ({
		...value,
		oathAccessToken: data,
	}));

export const setCurrentFirebaseProject = (data) =>
	formData.set((value) => ({
		...value,
		currentFirebaseProjectId: data,
	}));

export const data = entity({
	cardData: [],
});

export const useData = (args) => data.use(args);

export const setCardData = (newValue) => {
	data.set((value) => ({
		...value,
		cardData: newValue,
	}));
};
export const getCardData = () => data.get().cardData;
