import Twig from "twig";
var stripTrailingSlash = function (str) {
  str = str || "";
  if(str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};


const config = {
	tags: {
		raw: {
			open: '{% raw %}',
			close: '{% endraw %}',
		},
		variable: {
			open: '{>',
			close: '<}',
		},
		logic: {
			open: '{%>',
			close: '<%}',
		},
		comment: {
			open: '{#>',
			close: '<#}',
		},
	},
	filters: {
		asBool: function (value) {
			if (value == '1' || value == 'true' || value == true || value == 1)
				return 'true';
			else return 'false';
		},
		addSlashes: function (str) {
			str = str + '';
			return str.replace(/"/g, '\\"');
		},
		getProtocol: function (str) {
			var matches = /([a-zA-Z]+):\/\/([\-\w\.\/]+)(?:\:(\d{0,5}))?/.exec(str);
			return matches[1];
		},
		getHost: function (str) {
			var matches = /([a-zA-Z]+):\/\/([\-\w\.\/]+)(?:\:(\d{0,5}))?/.exec(str);
			return matches[2];
		},
		getHost_www: function (str) {
			var matches =
				/([a-zA-Z]+):\/\/(www)?(\.)?([\-\w\.]+)(?:\:(\d{0,5}))?/.exec(str);
			return 'www.' + matches[4];
		},
		getHost_without_www: function (str) {
			var matches =
				/([a-zA-Z]+):\/\/(www)?(\.)?([\-\w\.]+)(?:\:(\d{0,5}))?/.exec(str);
			return matches[4];
		},
		getPathPrefix: function (str) {
			var matches =
				/([a-zA-Z]+):\/\/(www)?(\.)?([\-\w\.]+)(?:\:(\d{0,5}))?(.*)?/.exec(str);
			return stripTrailingSlash(matches[6]);
		},
		getValueFromArrayOfObjects: function (arr, params) {
			if (arr && arr.length > 0) {
				const [objectKey, objectValue, neededValueKey] = params;
				// check if an object is having a key with a specific value and return the object's value for that key
				var result = arr.find(function (obj) {
					return obj[objectKey] == objectValue;
				});
				if (neededValueKey) {
					return result ? result[neededValueKey] : false;
				} else {
					return result ? result[objectKey] : false;
				}
			} else {
				return false;
			}
		},
		colorbrightness: function (hex, lum) {
			// validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '');
			if (hex.length < 6) {
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			}
			lum = lum || 0;

			// convert to decimal and change luminosity
			var rgb = '#',
				c,
				i;
			for (i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16);
				c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
				rgb += ('00' + c).substr(c.length);
			}
			return rgb;
		},
	},
};
Twig.extend((Twig) => {
  Twig.token.definitions = [
    {
      type: Twig.token.type.raw,
      open: config.tags["raw"].open,
      close: config.tags["raw"].close
    },
    {
      type: Twig.token.type.output,
      open: config.tags["variable"].open,
      close: config.tags["variable"].close
    },
    {
      type: Twig.token.type.logic,
      open: config.tags["logic"].open,
      close: config.tags["logic"].close
    },
    {
      type: Twig.token.type.comment,
      open: config.tags["comment"].open,
      close: config.tags["comment"].close
    }
  ];
});
Object.keys(config.filters).map(filter=>{
  Twig.extendFilter(filter, config.filters[filter]);
})
export default Twig;
