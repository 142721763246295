import {
	getProjectData,
	getProjectTemplate,
} from '@appmaker/core/store/project';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useState } from 'react';
import api from '../api/imports';
import { dispatch } from '@wordpress/data';
import SimpleAlert from '@appmaker/components/templates/SimpleAlert';
import { RangeControl } from '@wordpress/components';
import Notification, {
	sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';
import { Redirect } from '@shopify/app-bridge/actions';
import { ExternalLinkIcon } from '@heroicons/react/solid';

const HandleFormAlert = ({
	alert,
	setAlert,
	handle,
	setHandle,
	maxDepth,
	setMaxDepth,
	isLoading,
	onClickImport = () => {},
}) => {
	const { projectData } = getProjectData();
	let url = projectData?.meta?.URL;

	const onClickExternalLink = () => {
		if (!url) return;
		let route = '/admin/menus';
		if (!window?.shopifyApp) {
			// window.location.href = url;
			window?.open(url + route, '_blank');
		} else if (window?.shopifyApp) {
			const redirect = Redirect.create(window.shopifyApp);
			redirect.dispatch(Redirect.Action.REMOTE, {
				url: url + route,
				newContext: true,
			});
		}
	};
	return (
		<SimpleAlert
			openModal={alert}
			onClose={() => setAlert(false)}
			attributes={{
				title: 'Choose the menu handle from shopify store',
				message: (
					<button
						onClick={() => {
							onClickExternalLink();
						}}
						className='flex items-center space-x-1'
					>
						<span className='underline'>Go to navigation</span>
						<ExternalLinkIcon className="h-4 w-4" aria-hidden="true" />
					</button>
				),
				icon: (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="text-blue-600"
					>
						<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
						<polyline points="14 2 14 8 20 8" />
						<line x1="12" y1="18" x2="12" y2="12" />
						<line x1="9" y1="15" x2="15" y2="15" />
					</svg>
				),
				ctaMain: {
					label: isLoading ? 'Importing...' : 'Import from shopify',
					action: () => {
						onClickImport();
					},
					disabled: isLoading,
				},
				ctaSecondary: {
					label: 'Cancel',
					action: () => setAlert(false),
					disabled: isLoading,
				},
			}}
		>
			<div className="my-4">
				<div className="mb-2">
					<label
						htmlFor="handle-name"
						className="block text-sm font-medium text-gray-700"
					>
						Handle Name
					</label>
					<div className="mt-1">
						<input
							required={true}
							type="text"
							name="handle-name"
							id="handle-name"
							className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
							placeholder="main-menu"
							value={handle}
							onChange={(event) => {
								setHandle(event?.target?.value);
							}}
						/>
					</div>
				</div>
				<div className="mb-2">
					<label
						htmlFor="page-name"
						className="block text-sm font-medium text-gray-700"
					>
						Please slide to set the maximum menu depth
					</label>
					<div className="mt-1">
						<RangeControl
							name={'max-menu-depth'}
							value={maxDepth ?? 2}
							defaultValue={2}
							onChange={(newValue) => setMaxDepth(newValue)}
							min={2}
							max={10}
						/>
					</div>
				</div>
			</div>
		</SimpleAlert>
	);
};

const MenuImport = () => {
	const [saving, setSaving] = useState(false);
	const [alert, setAlert] = useState(false);
	const [handle, setHandle] = useState('main-menu');
	const [maxDepth, setMaxDepth] = useState(2);
	const template = getProjectTemplate();
	if (!template || template !== 'shopify') return null;
	const importMenu = async () => {
		const confirm = window.confirm(
			'This Action will replace the current page. Are you sure you want to continue?'
		);
		if (confirm) {
			// setSaving(true);
			// const handler = prompt(
			// 	'Please enter the handler of the menu you want to import',
			// 	'main-menu'
			// );
			// try {
			// 	const response = await api.importShopifyMenu(handler);
			// 	// appmaker.doAction('override-in-app-page-data', { blocks: response });
			// 	dispatch('core/block-editor').resetBlocks(response);
			// } catch (error) {}
			// setSaving(false);
			setAlert(true);
		}
	};
	const onClickImport = async () => {
		try {
			setSaving(true);
			if (handle && maxDepth && handle?.length > 0) {
				const response = await api.importShopifyMenu(handle, maxDepth);
				dispatch('core/block-editor').resetBlocks(response);
				setAlert(false);
			} else {
				sendNotification('All fields are mandatory', 'error');
			}
		} catch (error) {
		} finally {
			setSaving(false);
		}
	};
	return (
		<>
			<Notification>
				<button
					onClick={() => {
						importMenu();
					}}
					type="button"
					className="relative inline-flex items-center px-4 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
				>
					<CloudDownloadIcon
						className={clsx('sm:-ml-1 sm:mr-2 m-0 h-5 w-5', {
							'animate-bounce': saving,
						})}
						aria-hidden="true"
					/>
					<span className="hidden sm:inline-flex">
						{saving ? 'Importing...' : 'Import from shopify'}
					</span>
				</button>
				{alert && (
					<HandleFormAlert
						alert={alert}
						setAlert={setAlert}
						handle={handle}
						setHandle={setHandle}
						maxDepth={maxDepth}
						setMaxDepth={setMaxDepth}
						isLoading={saving}
						onClickImport={onClickImport}
					/>
				)}
			</Notification>
		</>
	);
};

const MenuImportParent = (props = {}) => {
	if (!props.showMenuImports) {
		return null;
	}
	return <MenuImport {...props} />;
};

export default MenuImportParent;
