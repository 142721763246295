/* This example requires Tailwind CSS v2.0+ */
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
// import { Transition } from '/react'
import { useState } from 'react';
import PricingModal from './PricingModal'
import { useProject } from '@appmaker/core/hooks';
import useProjectData from '@appmaker/core/hooks/useProjectData';
import moment from 'moment';

export default function Subscription() {
    const { projectId } = useProject();
    const { loading, projectData = {} } = useProjectData({ projectId });
    const { template, paymentStatus, endDate } = projectData;
    const [ishShown, setShown] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    if (parseInt(paymentStatus) !== 9) return null;
    const daysRemaining = moment(endDate).diff(moment(), 'days');
    return (
        <div>
            <PricingModal isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* <button >Toggle</button> */}
            <Transition
                show={ishShown}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {/* Will fade in and out */}
                <div className="relative bg-indigo-600">
                    <div className="max-w-7xl mx-auto py-1 px-3 sm:px-6 lg:px-8">
                        <div className="pr-16 sm:text-center sm:px-16">
                            <p className=" text-base text-white">
                                <span className="">Trial Started, You have {daysRemaining} days left.</span>
                                <span className="block sm:ml-2 sm:inline-block">
                                    <button onClick={() => setIsOpen(!isOpen)}
                                        className="font-medium text-white font-bold underline">
                                        Choose Plan <span aria-hidden="true">&rarr;</span>
                                    </button>
                                </span>
                            </p>
                        </div>
                        <div className="absolute inset-y-0 right-0 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
                            <button
                                onClick={() => setShown(false)}
                                type="button"
                                className="flex  rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                            >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    )

}
