import appmaker from '@appmaker/core/index';
import {
    SwitchVerticalIcon,
} from '@heroicons/react/solid';
import NostoProductRecommendations from './Block';

const Nosto = {
    id: 'nosto',
    name: 'Nosto',
    activate: () => {
        appmaker.registerAppmakerBlockType('nosto/nosto-product-recommendation', {
            title: 'Nosto',
            icon: <SwitchVerticalIcon icon="screenoptions" />,
            Block: NostoProductRecommendations,
        });
    }
}

export default Nosto;