// @flow strict
import { useState, useEffect } from 'react';
import { useProject } from '@appmaker/core/hooks';
import connectStoreApi from 'packages/woocommerce/api/connect-store';
import { useHistory } from '@appmaker/core/routes';
import LoadingIcon from 'packages/mobile-app/components/LoadingIcon';
import appmaker from '@appmaker/core/index';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { setPublicMeta } from '@appmaker/core/store/project';

const PluginConfigureForm = ({ basePath }) => {
	const { projectId } = useProject();
	const history = useHistory();
	const routerPath = useRouterPath()

	const [isLoading, setLoading] = useState(false);
	const [accessKey, setAccessKey] = useState(undefined);
	const activateApp = async () => {
		try {
			setLoading(true);
			const response = await connectStoreApi.postAccessKey(projectId, {
				accessToken: accessKey,
			});
			if (response.status) {
				setPublicMeta({ plugin_configured: true })
				appmaker.doAction('plugin_configured', { projectId })
				history.push(
					routerPath(`/onboarding/woocommerce/verification`)
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		setPublicMeta({ plugin_configured_page_loaded: true })
	}, [])
	return (
		<div className="pl-3">
			<ol className="list-disc list-inside">
				<li className="text-md text-gray-900 mb-3">
					Go to Your <span className="font-bold">WordPress admin</span> &gt;{' '}
					<span className="font-bold">WooCommerce</span> &gt;{' '}
					<span className="font-bold">Appmaker App Settings</span>
					<p>Paste the Access key copied from the Appmaker plugin settings page on your WordPress dashboard below and click on the “Activate App” button.</p>
					<figure className="mt-1 mb-3 mx-auto sm:w-1/2">
						<img
							className="w-full h-auto rounded-lg border border-gray-300"
							src="/images/steps/configure-plugin-01.png"
							alt="copy"
						/>
					</figure>
				</li>
				<li className="text-md text-gray-900 mb-3">
					Copy the API key from the plugin settings page and paste it below,
					click Activate App
					<div className="mt-2 sm:w-1/2 mx-auto">
						<label htmlFor="accesKey" className="text-sm font-medium hidden">
							Enter Access Key
						</label>
						<div className="mt-1 relative">
							<textarea
								id="accesKey"
								name="accesKey"
								rows={4}
								className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md text-green-400 font-mono focus:border-green-500"
								placeholder="Paste access key here from your plugin settings"
								value={accessKey}
								onChange={(event) => {
									setAccessKey(event.target.value);
								}}
							/>
						</div>
						<button
							type="button"
							disabled={isLoading}
							onClick={activateApp}
							className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
						>
							{isLoading ? (
								<>
									<LoadingIcon className="animate-spin mr-2" />
									Activating...
								</>
							) : (
								'Activate app'
							)}
						</button>
					</div>
				</li>
			</ol>
		</div>
	);
};
export default PluginConfigureForm;
