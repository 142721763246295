// @flow strict

import React from 'react';
function FieldGroup(props) {
    const { onChange, formData, schema, registry, uiSchema, } = props
    const fieldKeys = Object.keys(schema.properties);
    const onChangeLocal = field => (value) => {
        onChange({ ...formData, [field]: value })
    }
    return (
        <div className="mt-4 p-3 bg-blue-50 rounded-lg">
            <div className="flex gap-2">
                {fieldKeys.map(field => {
                    try {
                        const FieldTypeName = uiSchema[field]['ui:field'];
                        const Field = registry.fields[FieldTypeName];
                        return Field ? <Field
                            {...props}
                            schema={schema.properties[field]}
                            name={`${field}`}
                            onChange={onChangeLocal(field)}
                            formData={formData[field]}
                        /> : null
                    } catch (error) {
                        console.log(error);
                    }
                    return null
                })}

            </div>
        </div>
    );
};

export default FieldGroup;