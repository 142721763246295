import { gql } from '@apollo/client';

const THEME_LIST = gql(
	`
    query ($where: ThemeWhereInput!) {
      themes(where: $where) {
        id
        handle
        name
        packageName
        packageVersion
        backendSchema
        config
        createdAt
        description
        icon
        legacyId
        permissions
        settingsSchema
        status
        type
      }
      themeInstalls {
        androidVersion
        backendSettings
        createdAt
        id
        iosVersion
        project {
          remoteProjectId
          id
          name
        }
        settings
        status
        isDefault
        theme {
          id
          handle
          name
          packageName
          packageVersion
          backendSchema
          config
          createdAt
          description
          icon
          legacyId
          permissions
          settingsSchema
          status
          type
        }
        updatedAt
      }
    }
    `
);

const GET_THEME_INSTALL = gql(
	`
  query ($where: ThemeInstallWhereInput!, $themeWhere: ThemeWhereUniqueInput!) {
    theme(where: $themeWhere) {
      id
      handle
      name
      packageName
      packageVersion
      backendSchema
      config
      createdAt
      description
      icon
      legacyId
      permissions
      settingsSchema
      status
      type
    }
    themeInstalls(where: $where) {
      androidVersion
      backendSettings
      createdAt
      id
      iosVersion
      project {
        remoteProjectId
        id
        name
      }
      settings
      status
      isDefault
      theme {
        id
        handle
        name
      }
      updatedAt
    }
  }
  
  
  `
);

const CREATE_THEME_INSTALL = gql(`
mutation($data: ThemeInstallCreateInput!){
  createThemeInstall(data: $data) {
    androidVersion
      backendSettings
      createdAt
      id
      iosVersion
      project {
        remoteProjectId
        id
        name
      }
      settings
      status
      theme {
        id
        handle
        name
      }
      updatedAt
  }
}
`);

const DELETE_THEME_INSTALL = gql(`
mutation($where: ThemeInstallWhereUniqueInput!){
  deleteThemeInstall(where: $where) {
    id
  }
}
`);

const UPDATE_THEME_INSTALL = gql(`
mutation (
  $where: ThemeInstallWhereUniqueInput!
  $data: ThemeInstallUpdateInput!
) {
  updateThemeInstall(where: $where, data: $data) {
    id
  }
}

`);
const IMPORT_THEME = gql(`
mutation ($importThemeId: String!) {
  importTheme(id: $importThemeId) {
    id
    project {
      remoteProjectId
    }
    theme {
      id
      name
      handle
    }
  }
}
`)
export {
	THEME_LIST,
	GET_THEME_INSTALL,
	CREATE_THEME_INSTALL,
	DELETE_THEME_INSTALL,
	UPDATE_THEME_INSTALL,
  IMPORT_THEME
};
