import { useQuery } from '@apollo/client';
import { EXTENSION_INSTALLED_LIST } from '../api/graphql';
import Loader from '@appmaker/components/Loading';
import { useParams } from '@appmaker/core/routes';
import {
	CubeIcon,
	ExternalLinkIcon,
	SearchIcon,
} from '@heroicons/react/outline';
import ListItem from '../components/ListItem';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import useExtensionStore from '../store/list';
import { useEffect } from 'react';
import AddExtension from '../components/AddExtension';
import { Link } from '@appmaker/core/routes';
import RedirectV3 from './RedirectV3';

const Extensions = () => {
	const { handle, projectId } = useParams();
	const setExtensionData = useExtensionStore((state) => state.setExtensionData);
	const installedData = useExtensionStore((state) => state.installList);
	const searchExtension = useExtensionStore((state) => state.searchExtension);
	const { data, loading, error } = useQuery(EXTENSION_INSTALLED_LIST, {
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
		fetchPolicy: 'no-cache',
		variables: {
			where: {
				project: {
					remoteProjectId: {
						equals: parseInt(projectId, 10),
					},
				},
			},
		},
	});
	const getRouterPath = useRouterPath();
	useEffect(() => {
		if (data) {
			setExtensionData(data.extensionInstalls);
		}
	}, [data]);
	if (loading) {
		return <Loader />;
	}
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<RedirectV3 />
			<div className="bg-white shadow-sm grid grid-cols-2 sm:grid-cols-3 gap-4 px-4 py-2 items-center">
				<h2 className="text-lg font-medium text-gray-900">
					Installed Extensions
				</h2>
				<div className="relative bg-gray-50 border border-gray-100 rounded-md text-gray-400 focus-within:text-gray-600">
					<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
						<SearchIcon className="h-5 w-5" aria-hidden="true" />
					</div>
					<input
						id="search"
						className="block w-full bg-gray-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
						placeholder="Search Extensions"
						type="search"
						name="search"
						onChange={(event) => {
							searchExtension(event.target.value);
						}}
					/>
				</div>
				<div className="flex  justify-end items-center">
					{/* <a
						href="https://appmaker.xyz/extensions"
						target="_blank"
						rel="noreferrer"
						className="rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>

						Explore Extensions
					</a> */}
					
					<Link to={getRouterPath('/extension-list')}
						className="rounded-md bg-indigo-500 px-2.5 py-1.5 text-white inline-flex items-center justify-center shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						<p className='inline-block text-sm font-semibold'>Explore Extensions</p>
						<ExternalLinkIcon className="h-4 w-4 inline-block ml-1" />
					</Link>
					<AddExtension />
				</div>
				
			</div>

			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-5xl lg:px-8">
				<div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-2">
					{installedData?.length === 0 ? (
						<div className="px-4 py-8 col-span-2 group rounded-xl bg-white bg-opacity-50 flex flex-col justify-center items-center border border-dashed border-gray-300">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-md font-medium text-gray-500 mt-3">
								No Extensions Installed
							</h3>
							{/* <a
								href="https://appmaker.xyz/extensions"
								target="_blank"
								rel="noreferrer"
								className="flex items-center text-indigo-500 hover:text-indigo-400 mt-2 text-sm font-medium"
							>

							</a> */}
							<Link to={getRouterPath('/extension-list')}
								className="flex items-center text-indigo-500 hover:text-indigo-400 mt-2 text-sm font-medium"
							>
								<span> Explore Extensions</span>{' '}
								<ExternalLinkIcon className="h-4 w-4 inline-block ml-1" />
							</Link>
						</div>
					) : (
						installedData?.map((extension) => (
							<ListItem
								key={extension.id}
								extension={extension.extension}
								getRouterPath={getRouterPath}
							/>
						))
					)}
				</div>
			</div>
		</div>
	);
};

export default Extensions;
