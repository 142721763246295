import { useState, useRef } from 'react';
import { useProject } from '@appmaker/core/hooks';
import api from '../api';
import { useDataSource, setDataSource } from '../store';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { useHistory } from '@appmaker/core/routes';

const AppHeader = (props) => {
	const { projectId } = useProject();
	const [isLoading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const dataSourceName = useRef(undefined);
	const storeData = useDataSource();
	const getRouterPath = useRouterPath();
	const history = useHistory();
	const { name, status, imageSrc, dataSourceId } = props.extension || {};
	const [isExtensionInstalled, setActivationStatus] = useState(
		status === 'active'
	);
	const showFormOnClick = () => {
		setShowForm(!showForm);
	};
	const onClickActivate = async () => {
		setLoading(true);
		try {
			const response = await api.activateDataSource({
				projectId,
				dataSourceId,
				name: dataSourceName.current,
			});
			const storeListData = storeData.list;
			const storeInstalledData = storeData.installedList;

			setDataSource({
				installedList: storeInstalledData.concat({
					...props.extension,
					status: 'active',
					dataSourceName: dataSourceName.current,
					installedMeta: {
						projectDataSourceId: response.id,
					},
				}),
			});
			setLoading(false);
			history.push(getRouterPath('/data-source/edit/' + response.id));
		} catch (error) {}
	};
	const onClickDeactivate = async () => {
		const projectDataSourceId =
			props.extension && props.extension.installedMeta
				? props.extension.installedMeta.projectDataSourceId
				: undefined;
		setLoading(true);
		try {
			const response = await api.deactivateDataSource({
				projectId,
				projectDataSourceId,
				dataSourceId,
			});
			let storeInstalledData = storeData.installedList;
			storeInstalledData = storeInstalledData.map((item) => {
				if (
					item.installedMeta &&
					item.installedMeta.projectDataSourceId === projectDataSourceId
				) {
					return {
						...item,
						status: 'delete',
					};
				} else {
					return item;
				}
			});
			setDataSource({ installedList: storeInstalledData });
			setLoading(false);
			setActivationStatus(false);
			history.push(getRouterPath('/data-source'));
		} catch (error) {}
	};
	return (
		<div className="flex items-center justify-between space-x-3 bg-gray-200 px-8 py-6">
			<div className="flex items-center space-x-3">
				<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-12 w-12">
					<img
						src={imageSrc}
						alt={`${name}.`}
						className="object-center object-contain h-12 w-12"
					/>
				</div>
				<div>
					<h4 className="text-lg font-semibold text-gray-900">{name}</h4>
				</div>
			</div>
			<div className="inline-flex justify-self-end flex-col">
				{!isExtensionInstalled && !showForm && (
					<button
						disabled={isLoading}
						onClick={showFormOnClick}
						className=" bg-blue-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
					>
						Add Data Source
					</button>
				)}
				{isExtensionInstalled && (
					<button
						disabled={isLoading}
						onClick={onClickDeactivate}
						className="inline-flex justify-self-end bg-blue-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
					>
						{isLoading ? 'Loading....' : 'Delete'}
					</button>
				)}
				{showForm && (
					<div className="flex flex-col">
						<div className="pb-3">
							<input
								className="border border-black rounded-md p-2"
								type="name"
								placeholder="data source name"
								onChange={(event) => {
									dataSourceName.current = event.target.value;
								}}
							/>
						</div>
						<button
							onClick={onClickActivate}
							className="inline-flex justify-self-end bg-blue-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
							disabled={isLoading}
						>
							{isLoading ? 'Loading....' : 'Add Data Source'}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default AppHeader;
