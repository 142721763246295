const extractIdFromLink = (input) => {
    if (!input.includes('http') && !input.startsWith('http://') && !input.startsWith('https://')) {
        input = 'http://' + input;
    }
    const urlObject = new URL(input);
    // Extract the last part of the pathname assuming it's the ID
    const pathParts = urlObject.pathname.split('/');
    const id = pathParts[pathParts.length - 1];

    return id
}

export {
    extractIdFromLink
}