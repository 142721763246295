import { useState, useEffect } from "react"
import LabelGenerator from "./LabelGenerator"
import connectStoreApi from '../api/connect-store'
const ValidateURL = ({ projectId, afterComplete = () => { } }) => {
    const [status, setStatus] = useState("loading");
    const fetchData = async () => {
        try {
            const response = await connectStoreApi.validateUrl(projectId);
            if (response && response.validWp) {
                setStatus('complete');
                afterComplete('complete')
            } else {
                throw new Error('Failed')
            }
        } catch (error) {
            setStatus('failed');
            afterComplete('failed')
        }
}
    useEffect(() => {
        fetchData();
    }, [])
    return (<div>
        <LabelGenerator
            name='URL Verification'
            status={status}
        />
    </div>)
}
export default ValidateURL;