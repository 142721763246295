function DummyBlock({ name }) {
    return (
        <div className="relative border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <div className="flex-shrink-0"></div>
            <div className="flex-1 min-w-0 text-center">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{name}</p>
            </div>
        </div>
    );
}
DummyBlock.attributesSchema = {
    properties: {
        fixedFooter: {
            type: 'string',
            label: 'Fix when scrolling',
            default: '',
            uiType: 'Switch',
        },
        title: {
            type: 'string',
            label: 'Title',
        },
    },
};


function NostoProductRecommendations({ attributes }) {
    return <DummyBlock name={'Nosto '} />;
}

export default NostoProductRecommendations;
