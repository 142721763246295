/**
 * External dependencies
 */
import noop from 'lodash/noop';
import classnames from 'classnames';
import { isObject, isArray } from 'lodash'
/**
 * WordPress dependencies
 */
import {
    Button,
    FormFileUpload,
    Placeholder,
    DropZone,
    withFilters,
} from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { useState, useEffect } from '@wordpress/element';
import { useSelect } from '@wordpress/data';
import deprecated from '@wordpress/deprecated';
import { keyboardReturn } from '@wordpress/icons';
import LoadingIcon from 'packages/mobile-app/components/LoadingIcon';

/**
 * Internal dependencies
 */
// import MediaUpload from '@wordpress/block-editor/build/';
import {
    MediaUpload,
    URLPopover
} from '@wordpress/block-editor';
import { store as blockEditorStore } from '@wordpress/block-editor/build/store';
import EmptyTemplate from './EmptyTemplate';
import { PhotographIcon } from '@heroicons/react/outline';
import MediaUploadCheck from './MediaUploadCheck';

const InsertFromURLPopover = ({ src, onChange, onSubmit, onClose }) => (
    <URLPopover onClose={onClose}>
        <form
            className="block-editor-media-placeholder__url-input-form"
            onSubmit={onSubmit}
        >
            <input
                className="block-editor-media-placeholder__url-input-field"
                type="text"
                aria-label={__('URL')}
                placeholder={__('Paste or type URL')}
                onChange={onChange}
                value={src}
            />
            <Button
                className="block-editor-media-placeholder__url-input-submit-button"
                icon={keyboardReturn}
                label={__('Apply')}
                type="submit"
            />
        </form>
    </URLPopover>
);

export function MediaPlaceholder({
    value = {},
    allowedTypes,
    className,
    icon,
    labels = {},
    mediaPreview,
    notices,
    isAppender,
    accept,
    addToGallery,
    multiple = false,
    handleUpload = true,
    dropZoneUIOnly,
    disableDropZone,
    disableMediaButtons,
    onError,
    onSelect:onSelectIncoming,
    onCancel,
    onSelectURL,
    onDoubleClick,
    onFilesPreUpload = noop,
    onHTMLDrop = noop,
    children,
    mediaLibraryButton,
    placeholder,
    style,
    attributes = {
        icon: PhotographIcon,
        title: 'Banner',
        description:
            '',
        // color: 'blue',
        templates: false,
        maxHeight: undefined,
        maxWidth: undefined,
        disableImageResize: false

    },
    forceRender = false
}) {
    const mediaUpload = useSelect((select) => {
        const { getSettings } = select(blockEditorStore);
        return getSettings().mediaUpload;
    }, []);
    const [src, setSrc] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isURLInputVisible, setIsURLInputVisible] = useState(false);
    const urlResolutionAdding = (value)=>{
        let newUrl = new URL(value?.url);
        if(value?.meta?.width > attributes?.maxWidth){
            // newUrl += attributes?.maxHeight ? `&height=${attributes?.maxHeight}` : '';
            newUrl?.searchParams?.set('width',attributes?.maxWidth)
        }else if(value?.meta?.height > attributes?.maxHeight ){
            // newUrl += attributes?.maxWidth ? `&width=${attributes?.maxWidth}` : '';
            newUrl?.searchParams?.set('height',attributes?.maxHeight);
        }
        return newUrl;
    }
    const onSelect = (value)=>{
        if(attributes?.disableImageResize){
            onSelectIncoming(value)
        }else{
            if(isObject(value) && value?.url){
                // newUrl += attributes?.maxWidth ? `&width=${attributes?.maxWidth}` : '';
                onSelectIncoming({
                    ...value,
                    url: urlResolutionAdding(value)
                })
            }else{
                if(isArray(value)){
                    const newData = value?.map(item=>{
                        return{
                            ...item,
                            url: urlResolutionAdding(item)
                        }
                    })
                    onSelectIncoming(newData)
                }else{
                    onSelectIncoming(value)
                }
                
            }
        }
    }
    useEffect(() => {
        setSrc(value?.src ?? '');
    }, [value?.src]);

    const onlyAllowsImages = () => {
        if (!allowedTypes || allowedTypes.length === 0) {
            return false;
        }

        return allowedTypes.every(
            (allowedType) =>
                allowedType === 'image' || allowedType.startsWith('image/')
        );
    };

    const onChangeSrc = (event) => {
        setSrc(event.target.value);
    };

    const openURLInput = () => {
        setIsURLInputVisible(true);
    };
    const closeURLInput = () => {
        setIsURLInputVisible(false);
    };

    const onSubmitSrc = (event) => {
        event.preventDefault();
        if (src && onSelectURL) {
            onSelectURL(src);
            closeURLInput();
        }
    };

    const
        onFilesUpload = async (files) => {
            if (!handleUpload) {
                return onSelect(files);
            }
            setLoading(true);
            onFilesPreUpload(files);
            let setMedia;
            if (multiple) {
                if (addToGallery) {
                    // Since the setMedia function runs multiple times per upload group
                    // and is passed newMedia containing every item in its group each time, we must
                    // filter out whatever this upload group had previously returned to the
                    // gallery before adding and returning the image array with replacement newMedia
                    // values.

                    // Define an array to store urls from newMedia between subsequent function calls.
                    let lastMediaPassed = [];
                    setMedia = (newMedia) => {
                        // Remove any images this upload group is responsible for (lastMediaPassed).
                        // Their replacements are contained in newMedia.
                        const filteredMedia = (value ?? []).filter((item) => {
                            // If Item has id, only remove it if lastMediaPassed has an item with that id.
                            if (item.id) {
                                return !lastMediaPassed.some(
                                    // Be sure to convert to number for comparison.
                                    ({ id }) => Number(id) === Number(item.id)
                                );
                            }
                            // Compare transient images via .includes since gallery may append extra info onto the url.
                            return !lastMediaPassed.some(({ urlSlug }) =>
                                item.url.includes(urlSlug)
                            );
                        });
                        // Return the filtered media array along with newMedia.
                        onSelect(filteredMedia.concat(newMedia));
                        // Reset lastMediaPassed and set it with ids and urls from newMedia.
                        lastMediaPassed = newMedia.map((media) => {
                            // Add everything up to '.fileType' to compare via .includes.
                            const cutOffIndex = media.url.lastIndexOf('.');
                            const urlSlug = media.url.slice(0, cutOffIndex);
                            return { id: media.id, urlSlug };
                        });
                    };
                } else {
                    setMedia = onSelect;
                }
            } else {
                setMedia = ([media]) => onSelect(media);
            }
            console.log(files);
            await mediaUpload({
                allowedTypes,
                filesList: files,
                onFileChange: setMedia,
                onError,
            });
            setLoading(false);
        };

    const onUpload = (event) => {
        onFilesUpload(event.target.files);
    };

    const defaultRenderPlaceholder = (content) => {
        let { instructions, title } = labels;

        if (!mediaUpload && !onSelectURL) {
            instructions = __(
                'To edit this block, you need permission to upload media.'
            );
        }

        if (instructions === undefined || title === undefined) {
            const typesAllowed = allowedTypes ?? [];

            const [firstAllowedType] = typesAllowed;
            const isOneType = 1 === typesAllowed.length;
            const isAudio = isOneType && 'audio' === firstAllowedType;
            const isImage = isOneType && 'image' === firstAllowedType;
            const isVideo = isOneType && 'video' === firstAllowedType;

            if (instructions === undefined && mediaUpload) {
                instructions = __(
                    'Upload a media file or pick one from your media library.'
                );

                if (isAudio) {
                    instructions = __(
                        'Upload an audio file, pick one from your media library, or add one with a URL.'
                    );
                } else if (isImage) {
                    instructions = __(
                        'Upload an image file, pick one from your media library, or add one with a URL.'
                    );
                } else if (isVideo) {
                    instructions = __(
                        'Upload a video file, pick one from your media library, or add one with a URL.'
                    );
                }
            }

            if (title === undefined) {
                title = __('Media');

                if (isAudio) {
                    title = __('Audio');
                } else if (isImage) {
                    title = __('Image');
                } else if (isVideo) {
                    title = __('Video');
                }
            }
        }

        return <EmptyTemplate attributes={attributes} >
            {content}
            {children}
        </EmptyTemplate>

    };
    const renderPlaceholder = placeholder ?? defaultRenderPlaceholder;

    const renderDropZone = () => {
        if (disableDropZone) {
            return null;
        }

        return (
            <DropZone onFilesDrop={onFilesUpload} onHTMLDrop={onHTMLDrop} />
        );
    };

    const renderCancelLink = () => {
        return (
            onCancel && (
                <Button
                    className="block-editor-media-placeholder__cancel-button"
                    title={__('Cancel')}
                    variant="link"
                    onClick={onCancel}
                >
                    {__('Cancel')}
                </Button>
            )
        );
    };

    const renderUrlSelectionUI = () => {
        return (
            (onSelectURL && !isLoading) ? (
                <div className="block-editor-media-placeholder__url-input-container">
                    <button
                        onClick={openURLInput}
                        type="button"
                        className="inline-flex text-indigo-600 cursor-pointer hover:underline text-sm"
                    >
                        Insert from URL
                    </button>
                    {isURLInputVisible && (
                        <InsertFromURLPopover
                            src={src}
                            onChange={onChangeSrc}
                            onSubmit={onSubmitSrc}
                            onClose={closeURLInput}
                        />
                    )}
                </div>
            ) : null
        );
    };

    const renderMediaUploadChecked = () => {
        const defaultButton = ({ open }) => {
            return !isLoading ? <div className="flex items-center gap-4">
                <button
                    onClick={() => {
                        open();
                    }}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Upload Image
                </button>
                {renderUrlSelectionUI()}
            </div> : (<><LoadingIcon className="animate-spin" /> Uploading.. </>)
        };
        const libraryButton = mediaLibraryButton ?? defaultButton;
        const uploadMediaLibraryButton = (
            <MediaUpload
                addToGallery={addToGallery}
                gallery={multiple && onlyAllowsImages()}
                multiple={multiple}
                onSelect={onSelect}
                allowedTypes={allowedTypes}
                value={
                    Array.isArray(value)
                        ? value.map(({ id }) => id)
                        : value.id
                }
                render={libraryButton}
            />
        );



        if (mediaUpload || forceRender) {
            const content = (
                <>
                    {renderDropZone()}
                    {/* <FormFileUpload
                        variant="primary"
                        className={classnames(
                            'block-editor-media-placeholder__button',
                            'block-editor-media-placeholder__upload-button'
                        )}
                        onChange={onUpload}
                        accept={accept}
                        multiple={multiple}
                        render={({ openFileDialog }) => {
                            const content = (
                                <>
                                    <Button
                                        variant="primary"
                                        className={classnames(
                                            'block-editor-media-placeholder__button',
                                            'block-editor-media-placeholder__upload-button'
                                        )}
                                        onClick={openFileDialog}
                                    >
                                        {__('Upload')}
                                    </Button>

                                </>
                            );
                            return (content);
                        }}
                    /> */}

                    {uploadMediaLibraryButton}
                    {renderCancelLink()}
                </>
            );
            return renderPlaceholder(content);
        }

        return renderPlaceholder(uploadMediaLibraryButton);
    };

    if (dropZoneUIOnly || disableMediaButtons) {
        if (dropZoneUIOnly) {
            deprecated('wp.blockEditor.MediaPlaceholder dropZoneUIOnly prop', {
                since: '5.4',
                alternative: 'disableMediaButtons',
            });
        }

        return <MediaUploadCheck>{renderDropZone()}</MediaUploadCheck>;
    }

    return (
        <MediaUploadCheck
            forceRender={forceRender}
            fallback={renderPlaceholder(renderUrlSelectionUI())}
        >
            {renderMediaUploadChecked()}
        </MediaUploadCheck>
    );
}

/**
 * @see https://github.com/WordPress/gutenberg/blob/HEAD/packages/block-editor/src/components/media-placeholder/README.md
 */
// export default withFilters('editor.MediaPlaceholder')(MediaPlaceholder);