import { useEffect, useReducer } from 'react';
import coreApi from 'packages/appmaker-core/api/core';
import api from '../../../../api';
import { useProject } from '@appmaker/core/hooks';
import { useFormData, CONSTANT } from '../../../../store';
import BuildState, { ContactSupport, BuildingApp } from './components/';

const initialState = {
	"isLoading": false,
	"step": "app-build",
	"status": CONSTANT.APP_STATUS.APP_STATUS_BUILDING,
	"success": false,
	building: false,
	showContactSupportPage: false,
	alreadyUploaded: false,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {

		case 'SET_IS_LOADING':
			return { ...state, isLoading: action.isLoading };

		case 'SET_STEP':
			return { ...state, step: action.step };

		case 'SET_STATUS':
			return { ...state, status: action.status };
		case 'SET_APP_BUILD_STATUS': {
			return {
				...state,
				status: action.status,
				isLoading: action.isLoading,
				building: action.isLoading
			}
		}
		case 'SET_ERROR': {
			return {
				...state,
				showContactSupportPage: true,
				isLoading: false,
				building: false
			}
		}
		case 'SET_BUILD_IPA': {
			return { ...state, building: action.building }
		}
		case 'IPA_ALREADY_UPLOADED': {
			return {
				...state,
				step: 'ipa-upload',
				status: CONSTANT.APP_STATUS.APP_STATUS_SUCCESS,
				isLoading: false,
				building: false,
				alreadyUploaded: true
			}
		}
		case 'IPA_UPLOAD_START': {
			return {
				...state,
				step: 'ipa-upload',
				status: CONSTANT.APP_STATUS.APP_STATUS_BUILDING,
				building: true,
				isLoading: true
			}
		}
		case 'STOP_BUILDING': {
			return {
				...state,
				isLoading: false,
				building: false
			}
		}
		default:
			return state
	}
}

let statusCheck = false;


const IPAUpload = ({ showAuthKeyUpload = () => { } }) => {
	const { projectId } = useProject();
	const { iosAppData = {} } = useFormData();
	const [state, dispatch] = useReducer(reducer, initialState);
	const { isLoading, success, building, step, status, showContactSupportPage, alreadyUploaded } = state;
	const { id: appId, token } = iosAppData;
	// check build status
	const appBuildStatusCheck = async (statusType) => {
		const statusChecker = async () => {
			try {
				let responseStatus
				if (statusType === 'app-build') {
					responseStatus = await api.appBuildStatus({ projectId });
					responseStatus = responseStatus.status[appId];
					dispatch({
						type: 'SET_APP_BUILD_STATUS',
						status: responseStatus, isLoading: false
					})
				}
				if (statusType === 'ipa-upload') {
					responseStatus = await api.ipaUploadStatus({ projectId, appId });
					responseStatus = responseStatus.status;
					dispatch({
						type: 'SET_APP_BUILD_STATUS',
						status: responseStatus, isLoading: false
					})
				}
				if (parseInt(responseStatus) !== parseInt(CONSTANT.APP_STATUS.APP_STATUS_BUILDING)) {
					clearInterval(statusCheck);
				}

			} catch (error) {
				dispatch({ type: 'SET_ERROR' })
			}
		}
		statusCheck = setInterval(statusChecker, 8000);
		statusChecker();
	}
	// initial
	const buildIpa = async () => {
		dispatch({ type: 'SET_BUILD_IPA', building: true });
		await api.buildIpa({ projectId, appId });
		appBuildStatusCheck('app-build');
	}
	// ipa download
	const downloadIpa = () => {
		return coreApi.downloadApp({ projectId, preview: false, token: token, platform: 'ios' });
	}
	// upload ipa
	const uploadIpa = async () => {
		dispatch({ type: 'SET_IS_LOADING', isLoading: true });
		try {
			const response = await api.uploadIpa({ projectId, appId });
			if (response && response.already_uploaded) {
				dispatch({ type: 'IPA_ALREADY_UPLOADED' });
			} else {
				if (response
					&& response.certificate_create_status
					&& (parseInt(response.certificate_create_status) !== parseInt(CONSTANT.APP_STATUS.APP_STATUS_SUCCESS))) {
					// show auth key upload
					showAuthKeyUpload();
				} else {
					// check status ipa upload
					dispatch({ type: 'IPA_UPLOAD_START' });
					await appBuildStatusCheck('ipa-upload');
					dispatch({ type: 'STOP_BUILDING' });
				}
			}
		} catch (error) {
			dispatch({ type: 'SET_ERROR' })
		}
	}
	useEffect(() => {
		buildIpa();
	}, [])
	if (showContactSupportPage) {
		return (<ContactSupport />)
	}
	if (building || isLoading) {
		return <BuildingApp />
	}
	return (
		<div className="py-16 flex flex-col justify-center items-center gap-6">
			{/* IPA building */}
			{step === 'app-build' && parseInt(status) === parseInt(CONSTANT.APP_STATUS.APP_STATUS_BUILDING) && (
				<BuildState building />
			)}
			{/* Error */}
			{parseInt(status) === parseInt(CONSTANT.APP_STATUS.APP_STATUS_FAILED) && (
				<BuildState buildFailed />
			)}
			{/* Success */}
			{step === 'app-build' && parseInt(status) === parseInt(CONSTANT.APP_STATUS.APP_STATUS_SUCCESS) && (
				<BuildState ipaSuccess otherProps={
					{
						downloadIpaLink: downloadIpa(),
						uploadIpa: uploadIpa
					}
				} />
			)}
			{/* Ipa already uploaded */}
			{step === 'ipa-upload' && parseInt(status) === parseInt(CONSTANT.APP_STATUS.APP_STATUS_SUCCESS) && (
				<BuildState alreadyUploaded />
			)}
			{/* Upload ipa */}
			{step === 'ipa-upload' && parseInt(status) === parseInt(CONSTANT.APP_STATUS.APP_STATUS_BUILDING) && (
				<BuildState uploading />
			)}

		</div>
	);
}
export default IPAUpload;
