import { CloudDownloadIcon } from '@heroicons/react/solid';

const StepSix = () => {
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			{/* <li>
				Roll out the app
				<ol className="list-outside ml-4 list-decimal flex flex-col gap-3">
					<li>
						App release⇒ Production ( Manage){' '}
						<img
							className="w-full rounded-lg border border-gray-100 mt-1"
							src="/images/publish/android/step-6-1-1.png"
							alt="step one"
						/>
					</li>
					<li>
						When you Click on the Manage, a new page appears for roll out. Click
						on Edit Release
						<img
							className="w-full rounded-lg border border-gray-100 mt-1"
							src="/images/publish/android/step-6-1-2.png"
							alt="step one"
						/>
					</li>
					<li>
						You will get another window. click on Start Roll out to production
						<img
							className="w-full rounded-lg border border-gray-100 mt-1"
							src="/images/publish/android/step-6-1-3.png"
							alt="step one"
						/>
					</li>
					<li>
						another window appears, click on "CONFIRM"
						<img
							className="w-full rounded-lg border border-gray-100 mt-1"
							src="/images/publish/android/step-6-1-4.png"
							alt="step one"
						/>
					</li>
				</ol>
			</li> */}
			<li >
				Update the Graphics elements of your app such as:
				<ol className="list-outside ml-4 list-decimal flex flex-col gap-3">
					<li>
						App icon: click here to generate App Icon using Appmaker Tools
					</li>
					<li>
						Feature graphic: click here to create feature graphics
					</li>
					<li>
						Screenshots: click here to create screenshots
						<ul type='i' className="list-outside ml-4 list-decimal flex flex-col gap-3">
							<li>
								Phone screenshots:
							</li>
							<li>
								7-inch tablet screenshots
							</li>
							<li>
								10-inch tablet screenshots
							</li>
						</ul>

					</li>
				</ol>
				<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-6-1.png"
					alt="step one"
				/>
				
			</li>
			<li>
				After uploading all the Graphic elements, click Save
				<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-6-2.png"
					alt="step one"
				/>
			</li>
		</ul>
	);
};

export default StepSix;
