import { CloudDownloadIcon } from '@heroicons/react/solid';
import { useProject } from '@appmaker/core/hooks';
import { downloadAppBundleLink } from '../../../store'
import useProjectData from '@appmaker/core/hooks/useProjectData';
import { Spinner } from '@wordpress/components';
const StepOne = () => {
	const { projectId } = useProject();
	const { loading, projectData } = useProjectData({ projectId });
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			<li>
				Download Android App Bundle(.aab)
				{loading ? (<div>
					<button
						className="mt-1 flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
						disabled={loading}>
						<Spinner />
					</button>
				</div>) : (<a
					href={downloadAppBundleLink({ projectId, apps: projectData.apps })}
					type="button"
					className="mt-1 flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"

				>
					<CloudDownloadIcon
						className="-ml-1 mr-2 h-5 w-5"
						aria-hidden="true"
					/>
					Download (.aab)
				</a>)}
			</li>
			<li>
				Log in to your Google Play developer account{' '}
				<a
					href="https://play.google.com/apps/publish"
					target="_blank"
					rel="noopener noreferrer"
					className="text-blue-600"
				>
					{' '}
					https://play.google.com/apps/publish
				</a>
			</li>
			<li>
				Click on "Creating An Application" button.
				<img
					className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
					src="/images/publish/android/step-1.png"
					alt="step one"
				/>
			</li>
		</ul>
	);
};

export default StepOne;
