import { MinusSmIcon, PlusIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import React, { useState } from 'react';

const ProductCounter = ({ attributes = {}, coreDispatch }) => {
	const [quantity, setQuantity] = useState(1);
	const increment = () => setQuantity(quantity + 1);
	const decrement = () => setQuantity(quantity - 1);
	console.log('attributes.fullWidth', attributes.fullWidth);
	// useEffect(() => {
	//   coreDispatch({
	//     type: "SET_PAGE_VAR",
	//     name: "quantity",
	//     value: quantity,
	//   });
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [quantity]);
	return (
		<div className="px-4 py-2 mt-2 bg-white shadow-sm flex justify-between items-center">
			<p className="text-base font-medium">{attributes.title}</p>
			<div
				className={clsx(
					'border-2 rounded-lg overflow-hidden border-green-500 flex justify-between items-center',

					attributes.fullWidth == 1 ? 'flex-1' : null
				)}
				style={{
					borderColor: attributes.themeColor
						? attributes.themeColor
						: '#069668',
				}}
			>
				<button
					className="bg-gray-100 p-1 text-green-500"
					style={{
						color: attributes.themeColor ? attributes.themeColor : '#069668',
					}}
					onClick={decrement}
				>
					<MinusSmIcon className="h-6 w-6" />
				</button>
				<span
					className={clsx(
						'px-3',
						attributes.fullWidth == 1 ? 'flex-1 text-center' : null
					)}
				>
					{' '}
					{quantity}{' '}
				</span>
				<button
					className="bg-gray-100 p-1 text-green-500"
					style={{
						color: attributes.themeColor ? attributes.themeColor : '#069668',
					}}
					onClick={increment}
				>
					<PlusIcon className="h-6 w-6" />
				</button>
			</div>
		</div>
	);
};
ProductCounter.attributesSchema = {
	type: 'object',
	properties: {
		title: { type: 'string', label: 'Select Product Count', default: '' },
		counter: { type: 'string', label: 'Counter', default: '' },
		themeColor: { type: 'string', label: 'Theme Color', default: '' },
		min_value: { type: 'string', label: 'Min Value', default: '' },
		max_value: { type: 'string', label: 'Max Value', default: '' },
		fullWidth: {
			type: 'string',
			label: 'Full width stepper',
			default: '',
			uiType: 'Switch',
		},
		__appmakerStylesClassName: {
			type: 'string',
			label: 'Appmaker Styles Class Name',
			default: '',
		},
		// 'counterBlockCustomStyle'
		// attributes: {
		// 	type: 'object',
		// 	properties: {
		// 		title: { type: 'string', label: 'Select Product Count', default: '' },
		// 		counter: { type: 'string', label: 'Counter', default: '' },
		// 		// qtyValue: { type: "string", default: "{{blockItem.name}}" },
		// 		// qtyField: { type: 'string', default: 'qty' },
		// 	},
		// },
	},
};

export default ProductCounter;
