import { createSlotFill } from '@wordpress/components';
import {
    __experimentalStyleProvider as StyleProvider,
} from '@wordpress/components';

const { Fill, Slot } = createSlotFill('AppmakerAction');

export const ActionSlot = (props) => (
    <Slot {...props} bubblesVirtually={true} />
);
export const ActionFill = ({ children }) => <StyleProvider document={document}>
    <Fill>{children}</Fill>
</StyleProvider>;

const { Fill: DataSourceFill, Slot: DataSourceSlot } = createSlotFill('DataSource');

export const DataSourceTabSlot = (props) => (
    <DataSourceSlot {...props} bubblesVirtually={true} />
);
export const DataSourceTabFill = ({ children }) => <StyleProvider document={document}>
    <DataSourceFill>{children}</DataSourceFill>
</StyleProvider>;
