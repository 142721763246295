// @flow strict

import React from 'react';
function FieldGroup({ properties, FormItem, onChange, store, ...props }) {
    console.log({ properties });
    const propertiesRoot = properties.properties
    // return "Null"
    // const { onChange, formData, schema, registry, uiSchema, } = props
    const fieldKeys = Object.keys(propertiesRoot);
    // const onChangeLocal = field => (value) => {
    //     onChange({ ...formData, [field]: value })
    // }
    return (
        <div className="mt-4 p-3 bg-blue-50 rounded-lg">
            <div className="flex gap-2">
                {fieldKeys.map(field => {
                    try {
                        const item = propertiesRoot[field]
                        // return "123"
                        function onSubChange(value, internal) {
                            onChange(value, internal, field)
                        }
                        return <FormItem
                            {...props}
                            name={field}
                            key={field}
                            properties={item}
                            onChange={onSubChange}
                            defaultValue={store.formData[field]}
                            _internalValue={store._internal[field]}

                        />
                    } catch (error) {
                        console.log(error);
                    }
                    return null
                })}

            </div>
        </div>
    );
};

export default FieldGroup;