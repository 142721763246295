import clsx from 'clsx';

const DashboardCard = ({ attributes = {}, children }) => {
	const {
		className,
		title,
		cta,
		postTitle,
		disabled = false,
		loading = false,
		disabledMessage = 'This feature is disabled',
		disabledUi,
		enableButton,
		contactButton,
		customButton,
	} = attributes;
	return (
		<div
			className={clsx(
				'p-4 bg-gray-100 rounded-lg relative',
				(loading || disabled) && 'overflow-hidden min-h-24 py-12',
				className
			)}
		>
			{loading ? (
				<div className="absolute inset-0 z-30 rounded-lg flex">
					<div className="absolute inset-0 z-40 bg-gray-500 bg-opacity-30 backdrop-filter backdrop-blur-md" />
					<div className="z-50 flex flex-col items-center justify-center space-y-3 w-full">
						<svg
							className="animate-spin h-5 w-5 text-blue-600"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							></circle>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
					</div>
				</div>
			) : null}
			{disabled ? (
				<div className="absolute inset-0 z-30 rounded-lg flex min-h-32">
					<div className="absolute inset-0 z-40 bg-gray-300 bg-opacity-30 backdrop-filter backdrop-blur" />
					<div className="z-50 flex flex-col items-center justify-center space-y-3 w-full">
						<h5 className="text-lg">{disabledMessage}</h5>
						<div className="flex items-center space-x-2">
							{customButton ? customButton : null}
							{enableButton ? (
								<button
									className="px-4 py-2 bg-purple-600 text-white rounded-md text-sm"
									onClick={enableButton.onClick}
								>
									{enableButton.label}
								</button>
							) : null}
							{contactButton ? (
								<button
									className="px-4 py-2 bg-purple-100 text-purple-700 rounded-md text-sm"
									onClick={contactButton.onClick}
								>
									{contactButton.label}
								</button>
							) : null}
						</div>
					</div>
				</div>
			) : null}
			{(title || cta) && !disabled ? (
				<div className="flex items-center justify-between mb-2">
					<span className="flex items-center divide-x">
						<h3 className="text-lg font-medium">{title}</h3>
						{postTitle ? (
							<p className="text-sm text-indigo-500 pl-2 ml-2">{postTitle}</p>
						) : null}
					</span>
					<span>{cta}</span>
				</div>
			) : null}
			{!disabled ? children : disabledUi ? disabledUi : null}
		</div>
	);
};

export default DashboardCard;
