import React from 'react';
import {
	InnerBlocks,
	InspectorControls,
	RichText,
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { extractTextFromHTML } from '../../helper';
import { BlockCard } from '../../../../new-blocks/components';
import { PlusIcon } from '@heroicons/react/solid';
// from
import { dispatch, select } from '@wordpress/data';
import { createBlock } from '@wordpress/blocks';
function addSubBlock(clientId) {
	try {
		const block = createBlock('appmaker/sub-menu-item', {});
		const blocksCount = select('core/block-editor').getBlock(clientId).innerBlocks.length;
		dispatch('core/block-editor').insertBlocks(block, blocksCount, clientId);
	} catch (error) {
		console.log(error);
	}
}

const Edit = (props) => {
	const { setAttributes, className, attributes, clientId } = props;
	const { showTitle, title } = attributes;

	return (
		<BlockCard
			attributes={{ showTitle }}
			className={className}
			titleEditor={
				<RichText
					tagName="h4"
					className={'text-md font-medium flex-1'}
					allowedFormats={[]}
					value={title}
					withoutInteractiveFormatting
					onChange={(text) => {
						setAttributes({ title: extractTextFromHTML(text) });
					}}
					placeholder="Menu title"
				/>
			}
		>
			<InnerBlocks
				allowedBlocks={[
					'appmaker/sub-menu-item',
					// 'appmaker/menu'
				]}
				renderAppender={() => <Appender clientId={clientId} />}
			/>

			<InspectorControls>
				<PanelBody>
					<ToggleControl
						label={'Show Title'}
						checked={showTitle}
						onChange={() => {
							setAttributes({ showTitle: !showTitle });
						}}
					/>
				</PanelBody>
			</InspectorControls>
		</BlockCard>
	);
};

export default Edit;

const Appender = ({ clientId }) => {
	return (
		<button
			onClick={() => {
				console.log('add sub menu item', clientId);
				addSubBlock(clientId);
			}}
			rootClientId={clientId}
			className="text-base font-semibold flex items-center justify-center gap-2 w-full text-white bg-gray-800 mt-2 py-3 rounded-md"
		>
			<PlusIcon className="h-5 w-5" /> Add Menu Item
		</button>
	);
};
