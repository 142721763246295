// @flow strict

import React, { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import coreApi from '@appmaker/core/api/core';
import LoadingIndicator from '../components/LoadingIndicator';
import FormSteps from './FormSteps';
import { setformSchema } from '../store/formSchema';
import { useProject } from '@appmaker/core/store/project';
import { setFormData } from '../store/formData';
const DownloadApp = {
	'app-icon': {
		fullWidth: true,
		path: '/',
		title: 'Download Apps',
		description: 'You can download and test your apps from here.',
		schema: {
			properties: {
				apps: {
					type: 'string',
					inputType: 'text',
					label: 'App Name',
					name: 'appname',
					placeholder: 'My Store',
					required: true,
				},
			},
		},
		uiSchema: {
			apps: {
				'ui:field': 'DownloadAppCards',
			},
		},
	},
};
function DownloadApps() {
	const { projectId } = useProject();

	const [{ value, loading }, doFetch] = useAsyncFn(
		async () => await coreApi.getCustomizeForm(projectId, 'update'),
		[]
	);
	useEffect(() => {
		doFetch();
	}, []);
	useEffect(() => {
		if (value) setformSchema({ template: DownloadApp });
		setFormData({ ...value?.body?.formData, ___isUpdateForm: true });
	}, [value]);
	return value === undefined || loading ? (
		<LoadingIndicator />
	) : (
		<FormSteps template={DownloadApp} />
	);
}

export default DownloadApps;
