import React from 'react'
import BlockItem from './BlockItem';
// import { freeGift } from './samplePage'
// import { usePageState } from './state/PageState';
// const inAppPage = freeGift;
export default function BlocksView({inAppPage={}}) {
    // const formData = usePageState((state) => state);
    const { blocks } = inAppPage;
    const blockItems = blocks?.map((block, index) => {
        return <BlockItem key={block.clientId} block={block} />
    });
    return (
        <div>
            {/* <pre>{JSON.stringify(formData,null,2)}</pre> */}
            {blockItems}
        </div>
    )
}
