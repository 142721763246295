import React, { useEffect } from 'react'
import Input from '@appmaker/components/Form/fields/TextInput';

import {
    useRouteMatch,
} from '@appmaker/core/routes';
import { useLanguageFormData, setLanguageValue, setCurrentTab } from './store/formData';


const EditorTable = () => {
    let { params } = useRouteMatch();
    const { languageTab, languageId } = params;
    const translationSchema = useLanguageFormData(data => data.translationSchema)
    const isLanguageValueFetching = useLanguageFormData(data => data.isLanguageValueFetching);
    const translationData = useLanguageFormData(data => data.languageValues[languageId]?.translation)
    const translations = translationSchema.properties[languageTab] ? translationSchema.properties[languageTab].properties : {};
    useEffect(() => {
        setCurrentTab(languageTab)
    }, [languageTab]);
    if (isLanguageValueFetching) {
        return <div>Loading....</div>
    }
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Word
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Translation
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {translations && Object.keys(translations).map((translation) => (
                                    <tr key={translation}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {translation}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <Input
                                                schema={{
                                                    className: 'mt-0',
                                                    inputProps: {
                                                        defaultValue: translationData && translationData[translation],
                                                        onChange: (event) => {
                                                            setLanguageValue(languageId, {
                                                                ...translationData,
                                                                [translation]: event.target.value
                                                            })
                                                        }
                                                    },
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditorTable;