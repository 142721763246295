import { useState } from 'react';

const ICON_NAMES = [
	'activity',
	'airplay',
	'alert-circle',
	'alert-octagon',
	'alert-triangle',
	'align-center',
	'align-justify',
	'align-left',
	'align-right',
	'anchor',
	'aperture',
	'archive',
	'arrow-down-circle',
	'arrow-down-left',
	'arrow-down-right',
	'arrow-down',
	'arrow-left-circle',
	'arrow-left',
	'arrow-right-circle',
	'arrow-right',
	'arrow-up-circle',
	'arrow-up-left',
	'arrow-up-right',
	'arrow-up',
	'at-sign',
	'award',
	'bar-chart-2',
	'bar-chart',
	'battery-charging',
	'battery',
	'bell-off',
	'bell',
	'bluetooth',
	'bold',
	'book-open',
	'book',
	'bookmark',
	'box',
	'briefcase',
	'calendar',
	'camera-off',
	'camera',
	'cast',
	'check-circle',
	'check-square',
	'check',
	'chevron-down',
	'chevron-left',
	'chevron-right',
	'chevron-up',
	'chevrons-down',
	'chevrons-left',
	'chevrons-right',
	'chevrons-up',
	'chrome',
	'circle',
	'clipboard',
	'clock',
	'cloud-drizzle',
	'cloud-lightning',
	'cloud-off',
	'cloud-rain',
	'cloud-snow',
	'cloud',
	'code',
	'codepen',
	'codesandbox',
	'coffee',
	'columns',
	'command',
	'compass',
	'copy',
	'corner-down-left',
	'corner-down-right',
	'corner-left-down',
	'corner-left-up',
	'corner-right-down',
	'corner-right-up',
	'corner-up-left',
	'corner-up-right',
	'cpu',
	'credit-card',
	'crop',
	'crosshair',
	'database',
	'delete',
	'disc',
	'divide-circle',
	'divide-square',
	'divide',
	'dollar-sign',
	'download-cloud',
	'download',
	'dribbble',
	'droplet',
	'edit-2',
	'edit-3',
	'edit',
	'external-link',
	'eye-off',
	'eye',
	'facebook',
	'fast-forward',
	'feather',
	'figma',
	'file-minus',
	'file-plus',
	'file-text',
	'file',
	'film',
	'filter',
	'flag',
	'folder-minus',
	'folder-plus',
	'folder',
	'framer',
	'frown',
	'gift',
	'git-branch',
	'git-commit',
	'git-merge',
	'git-pull-request',
	'github',
	'gitlab',
	'globe',
	'grid',
	'hard-drive',
	'hash',
	'headphones',
	'heart',
	'help-circle',
	'hexagon',
	'home',
	'image',
	'inbox',
	'info',
	'instagram',
	'italic',
	'key',
	'layers',
	'layout',
	'life-buoy',
	'link-2',
	'link',
	'linkedin',
	'list',
	'loader',
	'lock',
	'log-in',
	'log-out',
	'mail',
	'map-pin',
	'map',
	'maximize-2',
	'maximize',
	'meh',
	'menu',
	'message-circle',
	'message-square',
	'mic-off',
	'mic',
	'minimize-2',
	'minimize',
	'minus-circle',
	'minus-square',
	'minus',
	'monitor',
	'moon',
	'more-horizontal',
	'more-vertical',
	'mouse-pointer',
	'move',
	'music',
	'navigation-2',
	'navigation',
	'octagon',
	'package',
	'paperclip',
	'pause-circle',
	'pause',
	'pen-tool',
	'percent',
	'phone-call',
	'phone-forwarded',
	'phone-incoming',
	'phone-missed',
	'phone-off',
	'phone-outgoing',
	'phone',
	'pie-chart',
	'play-circle',
	'play',
	'plus-circle',
	'plus-square',
	'plus',
	'pocket',
	'power',
	'printer',
	'radio',
	'refresh-ccw',
	'refresh-cw',
	'repeat',
	'rewind',
	'rotate-ccw',
	'rotate-cw',
	'rss',
	'save',
	'scissors',
	'search',
	'send',
	'server',
	'settings',
	'share-2',
	'share',
	'shield-off',
	'shield',
	'shopping-bag',
	'shopping-cart',
	'shuffle',
	'sidebar',
	'skip-back',
	'skip-forward',
	'slack',
	'slash',
	'sliders',
	'smartphone',
	'smile',
	'speaker',
	'square',
	'star',
	'stop-circle',
	'sun',
	'sunrise',
	'sunset',
	'table',
	'tablet',
	'tag',
	'target',
	'terminal',
	'thermometer',
	'thumbs-down',
	'thumbs-up',
	'toggle-left',
	'toggle-right',
	'tool',
	'trash-2',
	'trash',
	'trello',
	'trending-down',
	'trending-up',
	'triangle',
	'truck',
	'tv',
	'twitch',
	'twitter',
	'type',
	'umbrella',
	'underline',
	'unlock',
	'upload-cloud',
	'upload',
	'user-check',
	'user-minus',
	'user-plus',
	'user-x',
	'user',
	'users',
	'video-off',
	'video',
	'voicemail',
	'volume-1',
	'volume-2',
	'volume-x',
	'volume',
	'watch',
	'wifi-off',
	'wifi',
	'wind',
	'x-circle',
	'x-octagon',
	'x-square',
	'x',
	'youtube',
	'zap-off',
	'zap',
	'zoom-in',
	'zoom-out',
];

const FeatherIcons = () => {
	//Search
	const [search, setSearch] = useState('');
	const [filteredIcons, setFilteredIcons] = useState(ICON_NAMES);
	const handleSearch = (e) => {
		setSearch(e.target.value);
		setFilteredIcons(
			ICON_NAMES.filter((icon) => icon.includes(e.target.value))
		);
	};

	//Select Icon
	const [selectedIcon, setSelectedIcon] = useState('');
	const handleSelectIcon = (icon) => {
		setSelectedIcon(icon);
	};

	return (
		<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
				<h1 className="text-2xl font-semibold text-gray-900">
					{`Feather Icons${': ' + selectedIcon}`}
				</h1>
				{/* search box */}
				<div className="mt-4">
					<input
						type="text"
						className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
						placeholder="Search"
						value={search}
						onChange={handleSearch}
					/>
				</div>
				<div className="flex flex-wrap gap-4 mt-6 justify-between">
					{filteredIcons.map((icon) => (
						<button
							key={icon}
							onClick={() => handleSelectIcon(icon)}
							className="bg-white relative rounded-lg h-28 shadow-sm hover:shadow-lg flex flex-col items-center justify-center space-y-2 overflow-hidden"
							style={{
								width: 'calc(100% / 9)',
							}}
						>
							<svg
								width="24"
								height="24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<use href={`/feather-sprite.svg#${icon}`} />
							</svg>
							<span className="text-xs text-gray-500 absolute inset-x-0 bottom-0 py-1 bg-gray-50">
								{icon}
							</span>
						</button>
					))}
				</div>
			</div>
		</main>
	);
};

export default FeatherIcons;
