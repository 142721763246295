import React from 'react';
import {
	InnerBlocks,
	InspectorControls,
	RichText,
} from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
// Panel body component
import PanelComponent from './panel';
import { extractTextFromHTML } from '../../helper';
import { dispatch } from '@wordpress/data';
import { createBlock } from '@wordpress/blocks';
import { useSelect } from '@wordpress/data';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import { ViewGridIcon } from '@heroicons/react/outline';
import PanelBodyComponent from './panel';

// To set the attributes given from panel body component
const onChangeAttribute = (value, setAttributes) => {
	setAttributes(value);
};
export default function ImageScroller(props) {
	const { setAttributes, attributes, clientId, isSelected, metaData } = props;
	const { showViewMoreButton, showTitle } = attributes;
	function addItem(data) {
		try {
			const block = createBlock('appmaker/imagescroller-item', {
				image: {
					url: data.url,
					fileName: data.filename,
					id: data.id,
					title: data.title,
				},
				imageContainerStyle: {
					height: data.meta !== undefined ? data.meta.height : '100',
					width: data.meta !== undefined ? data.meta.width : '100',
				},
				thumbnail_meta: {
					height: data.meta !== undefined ? data.meta.height : undefined,
					width: data.meta !== undefined ? data.meta.width : undefined,
				},
				text: '',
				showText: false,
				appmakerAction: {},
				hasAnalytics: false,
				customBlockHeight: 10,
			});
			dispatch('core/block-editor').insertBlocks(block, 0, clientId);
		} catch (error) {
			console.log(error);
		}
	}
	const blockSelected = useSelect((select) => {
		const childSelected =
			select('core/block-editor').hasSelectedInnerBlock(clientId);
		return childSelected || isSelected;
	});
	return (
		<div style={{ minHeight: 40 }}>
			<PanelBodyComponent
				{...props}
				onChangeAttribute={(value) => {
					onChangeAttribute(value, setAttributes);
				}}
			/>
			{blockSelected && (
				<MediaPlaceholder
					attributes={{
						maxHeight: metaData?.image?.maxHeight,
						maxWidth: metaData?.image?.maxWidth,
						disableImageResize: attributes?.disableImageResize,
						icon: ViewGridIcon,
						title: 'Images Scroller',
						description:
							'Add images to create a horizontal scroller and highlight different categories in your store. You can redirect users to specific sections using custom actions.',
					}}
					onSelect={(images) => {
						// condition in meta because each api having different response of the meta
						// setState({
						// 	images
						// });
						images.map(addItem);

						// images.map(({ url }) => addItem({ url }))
					}}
					allowedTypes={['image']}
					multiple={true}
					labels={{ title: 'Images' }}
					// value={{ id: image && image.id, src: image && image.url }}
				/>
			)}
			<div className={props.className}>
				{(showTitle || showViewMoreButton) && (
					<div className="block-title-part flex items-center justify-between px-4 py-2">
						<div className="flex-auto">
							{showTitle && (
								<RichText
									allowedFormats={[]}
									value={attributes.title}
									withoutInteractiveFormatting
									onChange={(title) => {
										setAttributes({ title: extractTextFromHTML(title) });
									}}
									placeholder="Image scroller title"
								/>
							)}
						</div>
						<div className="view-more-button">
							<div className="text-blue-600">
								{showViewMoreButton && (
									<RichText
										allowedFormats={[]}
										value={attributes.ctaText}
										withoutInteractiveFormatting
										onChange={(ctaText) => {
											setAttributes({ ctaText: extractTextFromHTML(ctaText) });
										}}
										placeholder="View More Button"
									/>
								)}
							</div>
						</div>
					</div>
				)}
				<InnerBlocks allowedBlocks={['appmaker/imagescroller-item']} />
			</div>
		</div>
	);
}
