import React from 'react';
import { useStorefrontApi } from '../../hooks/useStorefront';
import { PRODUCT_QUERY } from './queries/product';
import ProductPreview from './MetaObjectPreview';

// const metafieldsItems = [
// 	{
// 		"key": "ingredient_science_new",
// 		"namespace": "custom"
// 	},
// 	{
// 		"namespace": "custom",
// 		"key": "key_benefits"
// 	},
// 	{
// 		"namespace": "custom",
// 		"key": "suggested_rituals_sections"
// 	},
// 	{
// 		"namespace": "custom",
// 		"key": "all_skin_types"
// 	},
// 	{
// 		"namespace": "custom",
// 		"key": "results_in_new"
// 	},
// 	{
// 		"namespace": "custom",
// 		"key": "tried_test_love_section_latest_"
// 	}
// ];

// const handle = 'dot-key-vitamin-c-e-spf-50-pa-face-sunscreen-for-glowing-skin-uv-protection-for-dull-skin';

export default function Preview({ formData }) {
	const handle = formData?.handle || '';
	const ids = formData?.ids || [];
	const type = formData?.type || 'product';

	const { data, loading, error } = useStorefrontApi({
		variables: {
			handle,
			ids,
		},
		query: PRODUCT_QUERY,
	});

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;
	let metafields = [];
	if(type === 'product') {
		metafields = data?.product?.metafields ?? [];
	} else {
		metafields = data?.collection?.metafields ?? [];
	}
	return metafields && metafields?.length > 0 ? <ProductPreview metafields={metafields} /> : <p>No {type} found</p>;
}