import { V3_BASE_URL } from './core';
import axios from '../axios';
import { getProjectId } from '../store/project';
import { user } from '@appmaker/core/store/user';

export async function sendAppmakerEvent(event, metaData) {
    try {
        const url = `${V3_BASE_URL}/events/`;
        const data = {
            eventName: event,
            meta: metaData
        }
        try {
            data.projectId = parseInt(getProjectId())
            data.userId = parseInt(user.get().id);

        } catch (error) {

        }
        const response = await axios.post(url, data, {
            credentials: 'include',
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}