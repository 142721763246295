import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InitialPage from './components/InitialPage';
import HireAgency from './components/HireAgency';
import AppmakerExpertBooking from './components/AppmakerExpert';
import FormSuccess from './components/FormSuccess';
import { useParams, useModal } from './hooks';
import { useQueryParam, BooleanParam } from 'use-query-params';

// import Provider, { useStore } from './Provider';


const GettingStartedModel = ({ forceClose = false }) => {
	const [step] = useParams();
	const [showInitialForm] = useQueryParam('showInitialForm', BooleanParam);
	const [openModal, setIsOpen] = useModal(showInitialForm ? true : false);
	console.log('openModal', openModal, step);
	// useEffect(() => {
	// 	if (showInitialForm) {
	// 		setIsOpen(true);
	// 	}
	// }, [])
	// let [] = useState(true);
	function closeModal() {
		if (forceClose) {
			setIsOpen(false);
		}
	}

	const steps = {
		'initial': <InitialPage />,
		'hire-agency': <HireAgency />,
		'appmaker-expert': <AppmakerExpertBooking />,
		'completed': <FormSuccess />,
		'completed-do-it-yourself': <FormSuccess selfServe={true} />,
	};
	let currentStep;
	if (step) {
		currentStep = steps[step];
	} else {
		currentStep = steps['initial'];
	}

	return (
		<>
			<Transition appear show={openModal} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-20 backdrop-filter backdrop-blur-sm" />
						</Transition.Child>
						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-4xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								{currentStep}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);

}


export {
	useModal
}
export default GettingStartedModel;