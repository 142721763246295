import Notification, {
    sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';
import { useState } from 'react';
import CRUD from '../components/CRUD';
import * as yup from 'yup';
import { useProject } from '@appmaker/core/hooks';
import api from '../api';


const updateForm = {
    "properties": {
        heading: {
            name: "string",
            label: 'Create staging project',
            type: "Heading",
        },
        projectName: {
            type: "string",
            label: 'Project name',
            description: "Project name",
            required: true
        },
        createNewProject: {
            type: "string",
            uiType: "switch",
            label: "Create even if staging already exist",
            description: 'This will create a new staging project even if already there is a staging',
        },
        changeDefaultUser: {
            type: 'string',
            uiType: 'RadioGroup',
            options: [
                {
                    label: 'Assign to Customer',
                    value: 'customer',
                },
                {
                    label: 'Assign to you',
                    value: 'admin',
                }
            ],
            title: 'Change default user',
            description: 'Set the project to customer or admin',
        },
    }
}
const Update = ({
    form,
    onClickSave,
    isSaving,
    formData
}) => {
    return (
        <CRUD
            schema={form}
            type="update"
            properties={
                {
                    onSave: onClickSave,
                    buttonLoading: isSaving,
                    buttonLabel: 'create staging',
                    formData: formData
                }
            }
        />
    )
}


const Staging = () => {
    const [isLoading, setSaving] = useState(false);
    const [url, setUrl] = useState(false);

    const { projectId } = useProject();
    const onSave = async (data) => {
        let validatedData;
        let schema = yup.object().shape({
            projectName: yup.string(),
            createNewProject: yup.boolean().required('Please choose the toggle'),
            changeDefaultUser: yup.string().required('Please choose default user')
        });
        try {
            const validity = await schema.validate(data, {
                abortEarly: false,
            });
            validatedData = validity;
        } catch (error) {
            console.log(error.errors);
            if (error && error.errors) {
                try {
                    error.errors.map((item) => {
                        return sendNotification(item, 'error');
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            return;
        }
        setSaving(true);
        try {
            if (!validatedData) {
                throw new Error('Invalid data');
            }
            const response = await api.createStaging({
                projectId,
                data: validatedData,
            });
            setUrl(`https://v3.appmaker.xyz/apps/${response.newProjectId}`)
            sendNotification('Staging created', 'success');
        } catch (error) {
            sendNotification('Error creating', 'error');
        }
        setSaving(false);
    }
    console.log(url);
    if (url) {
        return (
            <div className='m-4 mt-10 flex flex-rows align-center gap-4'>
                <div className="flex flex-col items-center" >
                    <label className="text-gray-500 text-xs font-medium uppercase tracking-wide">created project</label>
                    <div className='mt-2'><a href={url} rel="noreferrer" target="_blank">{url}</a></div>
                </div>
            </div>
        )
    }
    return (
        <Notification>
            {/* <div className='m-4 mt-10 flex flex-rows align-center gap-4'>
                <div className="flex flex-col items-center" >
                    <label className="text-gray-500 text-xs font-medium uppercase tracking-wide">Create staging project</label>
                    
                </div>
            </div> */}
            <Update
                form={updateForm}
                onClickSave={(data) => { onSave(data) }}
                isSaving={isLoading}
                formData={{}}
            />
        </Notification>
    )
}

export default Staging