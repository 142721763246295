/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { SparklesIcon } from '@heroicons/react/outline';

const SimpleAlert = ({
	openModal = false,
	attributes = {},
	onClose = false,
	children,
}) => {
	const { title, message, icon, ctaMain, ctaSecondary } = attributes;
	const [open, setOpen] = useState(openModal);
	const cancelButtonRef = useRef(null);
	const closeWidget = onClose ? onClose : setOpen;
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				onClose={closeWidget}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
									{icon ? (
										icon
									) : (
										<SparklesIcon
											className="h-6 w-6 text-blue-600"
											aria-hidden="true"
										/>
									)}
								</div>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex-1">
									<Dialog.Title
										as="h3"
										className="text-lg leading-6 font-medium text-gray-900"
									>
										{title}
									</Dialog.Title>
									{message ? (
										<div className="mt-2">
											<p className="text-sm text-gray-500">{message}</p>
										</div>
									) : null}
									{children}
								</div>
							</div>
							<div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
								<button
									type="button"
									disabled={ctaMain.disabled || false}
									className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
									onClick={ctaMain.action}
								>
									{ctaMain.label}
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 bg-white text-base font-medium text-blue-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={ctaSecondary.action}
									ref={cancelButtonRef}
								>
									{ctaSecondary.label}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default SimpleAlert;
