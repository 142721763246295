import { useParams } from '@appmaker/core/routes';
import { useDataSource } from '../store'
import AppHeader from '../components/AppHeader';
import TabGroupContainer from '../components/TabContainer';
import FormSchemaWrapper from '../components/FormSchemaWrapper';
import hooks from '../hooks';
import Loader from '@appmaker/components/Loading';

const DataSourceDetails = () => {
    const { dataSourceId } = useParams();
    const storeData = useDataSource(data => data.list);
    const dataSource = storeData.find(dataSource => dataSource.id === dataSourceId) || {};
    const { Component, formSchema } = dataSource || {};
    const { isLoading } = hooks.useDataSourceList({ refetch: false });
    if (isLoading) {
        return (
            <Loader />
        )
    }
    return (
        <div className="bg-gray-50 h-screen overflow-y-auto">
            <div className="w-full mx-auto bg-white ">
                <AppHeader extension={dataSource} />
                <TabGroupContainer extension={dataSource}>
                    <FormSchemaWrapper schema={formSchema} />
                </TabGroupContainer>
            </div>

        </div>)
}

export default DataSourceDetails