import { PlayIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

const VideoModal = ({ attributes = {} }) => {
	let [isOpen, setIsOpen] = useState(false);
	const {
		label = 'Watch video',
		videoId = 'M0zm_QhNtcg',
		startTime = '0',
		endTime = '0',
	} = attributes;

	function toggle() {
		setIsOpen(!isOpen);
	}

	return (
		<>
			<button
				type="button"
				onClick={toggle}
				className="flex items-center space-x-0.5 text-blue-600"
			>
				<PlayIcon className=" h-4 w-4" />
				<span className="text-xs font-medium">{label}</span>
			</button>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={() => setIsOpen(false)}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay
								className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
								onClick={toggle}
							/>
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-6xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<iframe
									height="652"
									className="w-full aspect-video md:aspect-square"
									src={`https://www.youtube.com/embed/${videoId}?start=${startTime}&end=${endTime};`}
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default VideoModal;
