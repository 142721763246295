import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

const SimplePopover = ({ attributes, children }) => {
	const { className, button, contentContainerClassName, arrow, placement } =
		attributes;
	return (
		<Popover className={'relative'}>
			<Popover.Button className={clsx('focus:outline-none', className)}>
				{button}
			</Popover.Button>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-150"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<Popover.Panel
					className={clsx(
						'absolute z-50 lg:max-w-3xl bg-white rounded-md shadow-xl border overflow-hidden',
						placement === 'center'
							? 'left-1/2 -translate-x-1/2 transform'
							: null,
						placement === 'right' ? 'right-1/2' : null,
						contentContainerClassName
					)}
				>
					{arrow ? (
						<div className="h-4 w-4 bg-white absolute -top-1 left-4 transform rotate-45" />
					) : null}
					{children}
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

export default SimplePopover;
