/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow strict

import React from 'react';
import classnames from 'classnames';

function Card({ attributes = {}, onPress, onAction }) {
	const { appmakerAction } = attributes;
	// console.log("card", attributes);
	let onPressHandle = onPress;
	if (appmakerAction && onAction) {
		onPressHandle = () => onAction(appmakerAction);
	}
	if (attributes.type === 'blog') {
		if (attributes.set === 'type-1') {
			return (
				<div className={attributes.reverse ? 'flex' : 'flex flex-row-reverse'}>
					<div className="mr-4 flex-shrink-0">
						<img
							src={attributes.uri}
							alt=""
							className="w-20 h-full object-cover rounded-sm"
						/>
					</div>
					<div>
						<h4 className="text-lg font-bold">{attributes.title}</h4>
						<p className="mt-1">{attributes.excert}</p>
					</div>
				</div>
			);
		}
		return (
			<div className="flex flex-col rounded-lg border border-gray-100 overflow-hidden">
				<div className="flex-shrink-0">
					<img
						className="h-24 w-full object-cover"
						src={attributes.uri}
						alt=""
					/>
				</div>
				<div className="flex-1 bg-white p-2 flex flex-col justify-between">
					<div className="flex-1">
						<a href="" className="block">
							<p className="text-lg font-semibold text-gray-900">
								{attributes.title}
							</p>
							<p className="mt-1 text-sm text-gray-500">{attributes.excert}</p>
						</a>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div
			onClick={onPressHandle}
			className={classnames('mb-2 border border-gray-100 rounded-lg', {
				'w-2/3': attributes.horizontal,
			})}
		>
			<div className="aspect-w-3 aspect-h-2">
				<img className="object-cover rounded-lg" src={attributes.uri} alt="" />
			</div>
			<div className="px-2 py-1">
				<div className="leading-6 font-medium border-gray-100 border-b pb-1">
					<h3
						className="text-md whitespace-normal line-clamp-3"
						style={{ lineClamp: 2 }}
					>
						{typeof attributes.title === 'string' ? attributes.title : ''}
					</h3>
					<p
						className="text-indigo-600 text-lg"
						dangerouslySetInnerHTML={{ __html: attributes.price }}
					></p>
				</div>
				<button className="mx-auto block border border-gray-300 px-1 rounded mt-2 text-sm">
					Add +
				</button>
			</div>
		</div>
	);
}
Card.attributesSchema = {
	type: 'object',
	properties: {
		attributes: {
			type: 'object',
			properties: {
				type: {
					type: 'string',
					enum: ['blog', 'product'],
				},
				set: {
					type: 'string',
					enum: ['type-1', 'type-2'],
				},
				title: { type: 'string' },
				savedItemIds: { type: 'string' },
				id: { type: 'string' },
				uri: { type: 'string' },
				in_stock: { type: 'boolean' },
				hasPages: { type: 'boolean' },
				wishList: { type: 'boolean' },
				regularPrice: { type: 'string' },
				salePrice: { type: 'string' },
				onSale: { type: 'string' },
				price: { type: 'string' },
				horizontal: {
					type: 'boolean',
				},
				numColumns: { type: 'string' },
			},
		},
	},
};
export default Card;
