import React, { useState } from 'react';
import {
	createStore,
	Provider,
} from '@appmaker/core/components/AppmakerForm/store';
import SendPushApp from '../components/send';


const SendPush = (props) => <Provider
	createStore={createStore({
		formData: {},
	})}
><SendPushApp {...props} />
</Provider>

export default SendPush;
