import axios from 'axios';
import { V3_BASE_URL } from '@appmaker/core/api/core';
const BASE_URL = V3_BASE_URL + '/projects';

const api = {
	getList: async ({ projectId }) => {
		try {
			const response = await axios.get(`${BASE_URL}/${projectId}/data-source/available`, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getActiveList: async ({ projectId }) => {
		try {
			const response = await axios.get(`${BASE_URL}/${projectId}/data-source`, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	activateDataSource: async ({ projectId, dataSourceId, name }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/data-source/create`,
				{
					dataSourceId,
					name
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	saveSettings: async ({ projectId, dataSourceId, settings }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/data-source/settings`,
				{
					dataSourceId,
					metaValue: settings
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	deactivateDataSource: async ({ projectId, dataSourceId, projectDataSourceId }) => {
		try {
			const response = await axios.post(
				`${BASE_URL}/${projectId}/data-source/delete`,
				{
					dataSourceId,
					status: 'delete',
					projectDataSourceId
				},
				{
					credentials: 'include',
					withCredentials: true,
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getValues: async ({ projectId, projectDataSourceId }) => {
		try {
			const response = await axios.get(`${BASE_URL}/${projectId}/data-source/settings?projectDataSourceId=${projectDataSourceId}`, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	}
}

export default api;