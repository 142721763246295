import AndroidHome from './AndroidHome';
import { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from '@appmaker/core/routes';
import PlayStoreSteps from './steps';
import { useQueryParam,BooleanParam } from 'use-query-params';

const Home = () => {
	let { path, params } = useRouteMatch();
	const [alreadyHaveAccount, setAlreadyHaveAccount] = useQueryParam('alreadyHaveAccount', BooleanParam);
	// const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);

	useEffect(()=>{
		if(!alreadyHaveAccount){
			setAlreadyHaveAccount(false)
		}else{
			setAlreadyHaveAccount(true);
		}
	},[])
	
	return (
		<>
			{!alreadyHaveAccount && <AndroidHome alreadyHaveAccount={() => {
				setAlreadyHaveAccount(true);
			}} />}
			{alreadyHaveAccount && <PlayStoreSteps />}
		</>
	);
};

export default Home;
