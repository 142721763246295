import Loader from '@appmaker/components/Loading';
import { useFormDataValue } from '@appmaker/core';
import { useCollections } from '@appmaker/shopify/hooks';
import { useProducts } from '@appmaker/shopify/hooks';
import { SearchIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import FormModal from '../components/FormModal';
import {
	MultiSelectContextProvider, useMultiSelectWithProvider,
} from "use-multiselect";
function ProductCard({ attributes, product, onChang, isSelected, setSelected }) {
	return (
		<div className="flex items-center mb-3">
			<label
				htmlFor={product.id}
				className="flex-auto flex items-center space-x-2"
			>
				<img
					src={product.imageUrl}
					alt={product.label}
					className="h-14 w-14 rounded overflow-hidden"
				/>
				<div className="flex-col flex">
					<span className="text-gray-900">{product.label}</span>
					<span className="text-gray-500 text-sm">
						{product.priceMeta.maxVariantPrice.currencyCode}{' '}
						{product.priceMeta.maxVariantPrice.amount} -{' '}
						{product.priceMeta.minVariantPrice.currencyCode}{' '}
						{product.priceMeta.minVariantPrice.amount}
					</span>
				</div>
			</label>
			<input
				id={product.id}
				name="product"
				type="checkbox"
				checked={isSelected(product)}
				onChange={(e) => {
					setSelected(product, e.target.checked);
				}}
				className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
			/>
		</div>
	);
}

function CollectionItem(props) {
	return (
		<div className="flex items-center mb-4 space-x-2">
			<input
				id={props.collection.id}
				name="collection"
				type="checkbox"
				className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
			/>
			<label htmlFor={props.collection.id} className="text-gray-900">
				{props.collection.label}
			</label>
		</div>
	);
}

function ModalComponent({ children, ...props }) {
	const { isOpen, setIsOpen, closeModal, loading, onSearch = () => { },setValue } = props;
	const { getSelectedCount,getSelectionState } =
		useMultiSelectWithProvider();
	function onSubmit() {
		closeModal();
		setValue(getSelectionState()?.exceptions);

	}
	return (
		<FormModal open={isOpen} setOpen={setIsOpen} title="Choose Products">
			<div className="relative mt-0.5 rounded-md shadow-sm mb-3 mx-4">
				<input
					type="search"
					className={clsx(
						'block w-full rounded-md pr-10 focus:outline-none sm:text-sm bg-white border-gray-300 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500'
					)}
					placeholder="Search products"
					onChange={(e) => onSearch(e.target.value)}
				/>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
					<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
				</div>
			</div>
			<div className="px-4 py-2 h-96 border-t border-gray-50 overflow-y-auto">
				{loading ? (
					<>
						<Loader single small />
						<Loader single small />
						<Loader single small />
						<Loader single small />
					</>
				) : (
					children
				)}
			</div>
			<div className="bg-gray-50 flex items-center p-4">
				<p className="flex-auto">{getSelectedCount()} selected</p>
				<button
					type="button"
					className="inline-flex mr-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
					onClick={closeModal}
				>
					Cancel
				</button>
				<button
					onClick={onSubmit}
					type="button"
					className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-12 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
				>
					Add
				</button>
			</div>
		</FormModal>
	);
}

const ProductChooser = ({ attributes = {}, clientId }) => {
	// const { label = 'Choose Products' } = attributes;
	const { label = 'Choose Products', description, name, type = 'single' } = attributes;
	const [value, setValue] = useFormDataValue({
		clientId,
		name,
	});
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};
	const { loading, products, onSearch } = useProducts();
	return (
		<MultiSelectContextProvider initialValue={value}>
			<div className="mb-4">
				<button
					onClick={openModal}
					className="px-4 py-2 bg-blue-600 text-white block w-full rounded-md"
				>
					{label}
				</button>
				<ModalComponent
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					closeModal={closeModal}
					onSearch={onSearch}
					loading={loading}
					// onSubmit={onSubmit}
					setValue={setValue}
				>

					<ProductList products={products} setValue={setValue} />

				</ModalComponent>

			</div>
		</MultiSelectContextProvider>
	);
};
function ProductList({
	products,
	setValue
}) {
	const { isSelected, setSelected, getSelectionState } =
		useMultiSelectWithProvider();
	const selectItem = (item, selection) => {
		// if (!isMultiSelectActive) deSelectAll();
		setSelected(item, selection);
	};
	return <>
		<pre>{JSON.stringify(getSelectionState(),null,2)}</pre>
		{products.map((item) => (
			<ProductCard product={item}
				isSelected={isSelected}
				setSelected={selectItem} />
		))}

	</>
}

const CollectionChooser = ({ attributes = {} }) => {
	const { label = 'Choose Collections' } = attributes;
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const { loading, collections, onSearch } = useCollections();
	// console.log(loading, collections, onSearch, ' useCollections');

	return (
		<div className="mb-4">
			<button
				onClick={openModal}
				className="px-4 py-2 bg-blue-600 text-white block w-full rounded-md"
			>
				{label}
			</button>
			<ModalComponent
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				closeModal={closeModal}
				onSearch={onSearch}
				loading={loading}
				
			>
				{collections.map((item) => (
					<CollectionItem collection={item} />
				))}
			</ModalComponent>
		</div>
	);
};

export { ProductChooser, CollectionChooser };
