import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Redirect } from '@shopify/app-bridge/actions';
import { Redirect as RouterRedirect } from 'react-router-dom';
import apiFunction, { BASE_URL } from '@appmaker/core/api/core';
import DashboardLoading from 'components/skeltonLoading/Dashboard';
import { useLocalStorage } from 'react-use';
import appmaker from '@appmaker/core/index';

const { user: getUser } = apiFunction;
// import { BallBeat } from 'react-pure-loaders';

// import * as ROUTES from '../../constants/routes';
/* eslint-disable */
export default function ShopifyInstall(props) {
	const [user, setUser] = useState(false);
	const [dashboardRedirect, setRedirectPath] = useState('/blockeditor/home');
	const queryParams = qs.parse(window.location.search);
	const shop = queryParams.shop || queryParams['?shop'];
	const shopifyQueryRedirect = queryParams['redirect_to'];
	const [initialStepCompleted, setInitialStepCompleted] = useLocalStorage(
		'appmaker-initial-step-completed',
		false
	);

	function redirectToShopifyLogin() {
		console.log('Redirecting to Shopify Login');
		appmaker.doAction('appmaker-analytics-event', {
			event: 'shopify-install-redirect-to-shopify-login',
			params: { shop, insideShopify: !!window.shopifyApp },
		});
		const shopifyLoginUrl = `${BASE_URL}/user/shopify/login?shop=${shop}`;
		if (!window.shopifyApp) {
			window.location.href = shopifyLoginUrl;
		} else if (window.shopifyApp) {
			const redirect = Redirect.create(window.shopifyApp);
			redirect.dispatch(Redirect.Action.REMOTE, shopifyLoginUrl);
		}
	}
	useEffect(() => {
		if (shopifyQueryRedirect && shopifyQueryRedirect !== null) {
			setRedirectPath(shopifyQueryRedirect);
		}
	}, [shopifyQueryRedirect]);

	console.log('ShopifyInstall', {
		shop,
		insideShopify: !!window.shopifyApp,
	});

	useEffect(() => {
		if (shop) {
			console.log('Get User');
			getUser({ withCredentials: false })
				.then((userResponse) => {
					if (!userResponse.logged_in) {
						console.log('Not Logged In');
						redirectToShopifyLogin();
					} else {
						if (userResponse.id && userResponse.projectId) {
							console.log('Logged In');
							appmaker.doAction('appmaker-analytics-event', {
								event: 'shopify-install-logged-in',
								params: { shop, insideShopify: !!window.shopifyApp },
							});
							setUser(userResponse);
						} else {
							console.log('Not Logged In.');
							redirectToShopifyLogin();
						}
					}
				})
				.catch((err) => {
					console.log('User Error');
					console.error(err);
				});
		} else {
			console.log('No Shop');
		}
	}, []);

	// if (!shop) return <RouterRedirect push to={`/`} />
	if (!user) return <DashboardLoading />;
	// if (user.projectId > 151416) {
	const redirectPath =
		!initialStepCompleted && user.projectId > 167260
			? `/apps/${user.projectId}/onboarding/initial-customize`
			: `/apps/${user.projectId}${dashboardRedirect}`;
	return <RouterRedirect push to={redirectPath} />;
}
