import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    generatePath,
} from '@appmaker/core/routes';
import ContentTabs from './ContentTabs'
import { useLanguageFormData } from './store/formData';

export default function LanguageContent({ availableLanguages }) {
    let { path, params } = useRouteMatch();
    const selectedLanguages = useLanguageFormData(data => data.selectedLanguages);
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect
                    to={`${generatePath(path, params)}/${selectedLanguages[0] ? selectedLanguages[0] : 'en'}`}
                />
            </Route>
            <Route path={`${path}/:languageId`}>
                <ContentTabs />
            </Route>
        </Switch>
    );
}
