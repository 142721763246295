import { useFormDataValue } from '@appmaker/core';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const TextInput = (props) => {
	const { attributes } = props;
	const {
		className,
		label,
		placeholder,
		required,
		type = 'text',
		name,
		error,
		info,
	} = attributes;
	const [value, setValue] = useFormDataValue({
		clientId: props.clientId,
		name,
	});

	return (
		<div className={clsx(className, 'mb-4')}>
			<label htmlFor={name} className="block text-sm font-medium text-gray-700">
				{label}
			</label>
			<div className="relative mt-0.5 rounded-md shadow-sm">
				<input
					type={type}
					name={name}
					id={name}
					required={required}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={clsx(
						'block w-full rounded-md pr-10 focus:outline-none sm:text-sm bg-white',
						error
							? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
							: 'border-gray-300 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500'
					)}
					placeholder={placeholder}
					aria-invalid={error ? 'true' : 'false'}
					aria-describedby={`${name}-error`}
				/>
				{error ? (
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
						<ExclamationCircleIcon
							className="h-5 w-5 text-red-500"
							aria-hidden="true"
						/>
					</div>
				) : null}
			</div>
			<p
				className={clsx('text-xs', {
					'mt-1 text-red-600': error,
					'mt-1 text-gray-500': info,
				})}
				id={`${name}-error`}
			>
				{error?.message || info?.message}
			</p>
		</div>
	);
};

export default TextInput;
