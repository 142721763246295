import { useEffect, useState } from 'react';
import { useData } from '../store';
import clsx from 'clsx';
import DashboardCard from 'packages/dashboard/components/components/DashboardCard';
import DataBlock from 'packages/dashboard/components/components/blocks/DataBlock';
import loadash from 'lodash';

function FilterButton(props) {
	return (
		<button
			type="button"
			className={clsx(
				'relative inline-flex items-center px-4 py-2 text-sm font-medium hover:bg-opacity-80 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
				props.filterDate === props.filterDateValue
					? 'bg-indigo-500 text-white'
					: 'bg-gray-200 text-gray-700'
			)}
			onClick={props.onClick}
		>
			{props.label}
		</button>
	);
}

const Card = () => {
	const data = useData();
    const {
        cardData
    } = data || {};

	const [filterDate, setFilterDate] = useState(30);
    const slicedArray = [{
        title: 'Total Users',
        data: 0,
        dataChange: 1,
        status: 'increase',
        date: 'Since last week',
    }];
    // const dataCards ={}
	const filters = [
		{
			label: 'Last 7 days',
			value: 7,
			startDate: '7daysAgo',
			endDate: 'today',
		},
		{
			label: 'Last 14 hours',
			value: 14,
			startDate: '14daysAgo',
			endDate: 'today',
		},
		{
			label: 'Last 30 days',
			value: 30,
			startDate: '30daysAgo',
			endDate: 'today',
		},
	];

	return (
		<div className="p-4">
			<div className="flex items-center justify-between mb-2">
				<h3 className="text-lg font-medium">Analytics</h3>
				<span className="inline-flex rounded-md shadow-sm overflow-hidden justify-self-end">
					{filters.map((filter) => (
						<FilterButton
							filterDate={filterDate}
							label={filter.label}
							filterDateValue={filter.value}
							onClick={() => {
								// return onChangeCardFilter({
								// 	startDate: filter.startDate,
								// 	endDate: filter.endDate,
								// 	value: filter.value,
								// });
							}}
						/>
					))}
				</span>
			</div>
            <DashboardCard
					attributes={{
						// disabled: data ? false : true,
						className:
							'col-span-6 flex items-center justify-between flex-wrap space-y-3 sm:space-y-0',
					}}
				>
                    {
                        cardData && cardData[0] && Object.keys(cardData[0]).map((key, index) => {
                            return (
                              <DataBlock 
                              attributes={{
                                title: loadash.upperFirst(loadash.lowerCase(key)),
                                data: loadash.round(cardData[0][key], 2),
                                date: `Last ${filterDate} days`,
                              }}

                              />
                            )
                        })
                    }
                    	{/* {slicedArray.map((card) => (
						<DataBlock
							attributes={{
								title: loadash.upperFirst(loadash.lowerCase(card.name)),
								data: loadash.round(card.total, 2),
								dataChange:
									loadash.round(card.maximum, 2) -
									loadash.round(card.minimum, 2),
								date: `Last ${filterDate} days`,
							}}
						/>
					))} */}

                </DashboardCard>
		</div>
	);
};

export default Card;
