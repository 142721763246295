import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [
	{
		attributes: {
			appmakerAction: {},
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-1.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 508,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53220',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-2.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 508,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53221',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-3.png',
					},
					imageContainerStyle: {
						width: 1080,
						height: 508,
					},
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'widget_key_53222',
				isValid: true,
			},
		],
		clientId: 'widget_key_5322',
		name: 'appmaker/slider',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Categories',
			itemsPerLine: '4',
			showViewMoreButton: false,
			showTitle: true,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-4.png',
					},
					imageContainerStyle: {
						width: 310,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: '1hnzSY',
							id: '1hnzSY',
							label: 'South Indian',
						},
						params: {
							value: '1hnzSY',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_684520',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-22.png',
					},
					imageContainerStyle: {
						width: 311,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'ZEJoer',
							id: 'ZEJoer',
							label: 'North Indian',
						},
						params: {
							value: 'ZEJoer',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_684521',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-6.png',
					},
					imageContainerStyle: {
						width: 311,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'NZwydJ',
							id: 'NZwydJ',
							label: 'Chinese',
						},
						params: {
							value: 'NZwydJ',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_684522',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-34.png',
					},
					imageContainerStyle: {
						width: 311,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_INAPP_PAGE',
						OPEN_INAPP_PAGE_id: {
							value: 'd8iaVR',
							id: 'd8iaVR',
							label: 'Categories',
						},
						params: {
							value: 'd8iaVR',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: 'widget_key_684523',
				isValid: true,
			},
		],
		clientId: 'widget_key_68452',
		isValid: true,
		name: 'appmaker/grid',
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Near to you',
			itemsPerLine: '4',
			showViewMoreButton: false,
			showTitle: true,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-9.png',
					},
					imageContainerStyle: {
						width: 310,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '237fc472-ca25-4b89-9ef4-b98a673495b80',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-10.png',
					},
					imageContainerStyle: {
						width: 310,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '237fc472-ca25-4b89-9ef4-b98a673495b81',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-11.png',
					},
					imageContainerStyle: {
						width: 311,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '237fc472-ca25-4b89-9ef4-b98a673495b82',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-12.png',
					},
					imageContainerStyle: {
						width: 311,
						height: 310,
					},
					text: '',
					showText: false,
					appmakerAction: {
						action: 'OPEN_PRODUCT_BY_CATEGORY',
						OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
							value: '',
							id: '',
							label: 'All',
						},
						params: {
							value: '',
						},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/grid-item',
				clientId: '237fc472-ca25-4b89-9ef4-b98a673495b83',
				isValid: true,
			},
		],
		clientId: '237fc472-ca25-4b89-9ef4-b98a673495b8',
		isValid: true,
		name: 'appmaker/grid',
	},
	{
		attributes: {
			image: {
				url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-8.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 332,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: '',
					id: '',
					label: 'All',
				},
				params: {
					value: '',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: 'widget_key_29792',
		name: 'appmaker/banner',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Best Selling',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					id: 546,
					labelId: 'Plain Roast Dosa',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'e3e19de3-b35f-4aef-aa33-1731cfc8486d0',
				isValid: true,
			},
			{
				attributes: {
					id: 552,
					labelId: 'Chapati (2 Pcs) with Korma',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'e3e19de3-b35f-4aef-aa33-1731cfc8486d1',
				isValid: true,
			},
			{
				attributes: {
					id: 548,
					labelId: 'Ghee Roast Dosa',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'e3e19de3-b35f-4aef-aa33-1731cfc8486d2',
				isValid: true,
			},
			{
				attributes: {
					id: 550,
					labelId: 'Ghee Masala Roast Dosa',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: 'e3e19de3-b35f-4aef-aa33-1731cfc8486d3',
				isValid: true,
			},
		],
		clientId: 'e3e19de3-b35f-4aef-aa33-1731cfc8486d',
		isValid: true,
		name: 'appmaker/productscroller',
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'On demand',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			id: 'category',
			labelId: 'Open Products in a category',
			hasChild: true,
			childId: {
				id: 16,
				label: 'Uncategorized',
			},
		},
		innerBlocks: [],
		clientId: 'fbeaac8d-5dd3-4533-a673-93c93e4bf6f1',
		isValid: true,
		name: 'appmaker/prebuildproductscroller',
	},
	{
		attributes: {
			appmakerAction: {},
		},
		innerBlocks: [
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-29-1.png',
					},
					imageContainerStyle: {
						width: 862,
						height: 411,
					},
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'f13193b2-b48d-4a18-a76b-4dadfedf80f00',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-30-1.png',
					},
					imageContainerStyle: {
						width: 862,
						height: 411,
					},
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'f13193b2-b48d-4a18-a76b-4dadfedf80f01',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-31-1.png',
					},
					imageContainerStyle: {
						width: 862,
						height: 411,
					},
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'f13193b2-b48d-4a18-a76b-4dadfedf80f02',
				isValid: true,
			},
			{
				attributes: {
					image: {
						url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-32-1.png',
					},
					imageContainerStyle: {
						width: 862,
						height: 411,
					},
					appmakerAction: {
						action: 'NO_ACTION',
						params: {},
					},
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/slider-item',
				clientId: 'f13193b2-b48d-4a18-a76b-4dadfedf80f03',
				isValid: true,
			},
		],
		clientId: 'f13193b2-b48d-4a18-a76b-4dadfedf80f0',
		name: 'appmaker/slider',
		isValid: true,
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'Veg for you',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			hasAnalytics: false,
		},
		innerBlocks: [
			{
				attributes: {
					id: 546,
					labelId: 'Plain Roast Dosa',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '092578ca-baab-4c60-94eb-fbaacaf0d84a0',
				isValid: true,
			},
			{
				attributes: {
					id: 552,
					labelId: 'Chapati (2 Pcs) with Korma',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '092578ca-baab-4c60-94eb-fbaacaf0d84a1',
				isValid: true,
			},
			{
				attributes: {
					id: 548,
					labelId: 'Ghee Roast Dosa',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '092578ca-baab-4c60-94eb-fbaacaf0d84a2',
				isValid: true,
			},
			{
				attributes: {
					id: 550,
					labelId: 'Ghee Masala Roast Dosa',
					hasAnalytics: false,
				},
				innerBlocks: [],
				name: 'appmaker/productscroller-item',
				clientId: '092578ca-baab-4c60-94eb-fbaacaf0d84a3',
				isValid: true,
			},
		],
		clientId: '092578ca-baab-4c60-94eb-fbaacaf0d84a',
		isValid: true,
		name: 'appmaker/productscroller',
	},
	{
		attributes: {
			appmakerAction: {},
			title: 'For you',
			showTitle: true,
			showViewMoreButton: false,
			ctaText: 'View more',
			id: 'category',
			labelId: 'Open Products in a category',
			hasChild: true,
			childId: {
				id: 16,
				label: 'Uncategorized',
			},
		},
		innerBlocks: [],
		clientId: '345b544b-3d6c-4867-834f-f75487709c70',
		isValid: true,
		name: 'appmaker/prebuildproductscroller',
	},
	{
		attributes: {
			image: {
				url: 'https://food-bus.site.shopilder.com/wp-content/uploads/2021/01/Artboard-–-33.png',
			},
			imageContainerStyle: {
				width: 1080,
				height: 332,
			},
			appmakerAction: {
				action: 'OPEN_PRODUCT_BY_CATEGORY',
				OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID: {
					value: '',
					id: '',
					label: 'All',
				},
				params: {
					value: '',
				},
			},
			hasAnalytics: false,
		},
		innerBlocks: [],
		clientId: '6fb9c1f4-6e15-495b-84f7-df3d37ddbd35',
		name: 'appmaker/banner',
		isValid: true,
	},
];
// function generate() {

// }
const template = {
	blocks,
	id: 3,
	name: 'Food Delivery',
	category: 'Theme',
	price: 'Free',
	imageSrc: '/images/themes/food.png',
	imageAlt: 'Mobile app food theme',
};
export default template;
