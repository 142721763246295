import React from 'react';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import appmaker from '@appmaker/core/index';
const StateView = ({ json }) => {
	return (
		<pre className="mt-2 border-2 p-2 bg-white">
			{JSON.stringify(json, null, 2)}
		</pre>
	);
};
export default function ActionSelector({ action, setAction }) {
	const actions = appmaker.actions.getAllActions();
	const schema = actions;
	return (
		<div>
			{/* <StateView json={action} /> */}
			<label className="text-sm text-gray-700">Choose an action</label>
			<AppmakerForm
				options={actions}
				value={{ ...action, schema }}
				optionKey={'action'}
				setValue={setAction}
				debug={false}
			/>
		</div>
	);
}
