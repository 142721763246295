import { useEffect, useState } from 'react';
import { useFormData } from '../../../store';
import AuthKeyUpload from './AuthKeyUpload';
import IPAUpload from './IPAUpload';

const IOS = () => {
    const { iosAppData = {} } = useFormData();
    const [showAuthKeyUpload, setAuthKeyUpload] = useState(false)
    const [showIpaUpload, setIpaUpload] = useState(false);

    useEffect(() => {
        if (iosAppData.iosCertUploaded !== '0' && iosAppData.iosCertUploaded !== 0) {
            setIpaUpload(true);
        } else {
            setAuthKeyUpload(true);
        }
    }, [iosAppData])

    const showAuthKeyUploadTab = () => {
        setAuthKeyUpload(true);
    }
    const showIpaUploadTab = () => {
        setIpaUpload(true);
        setAuthKeyUpload(false);

    }
    if (showAuthKeyUpload) {
        return (<AuthKeyUpload showIpaUpload={showIpaUploadTab} />)
    }
    if (showIpaUpload) {
        return (<IPAUpload showAuthKeyUpload={showAuthKeyUploadTab} />)
    }
    return null
}

export default IOS