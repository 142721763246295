import api from '../api';
import { useEffect } from 'react';
import { useProject } from '@appmaker/core/store/project';
import { Route, Switch, useRouteMatch, generatePath, Redirect } from '@appmaker/core/routes';
import { useInAppPage, setLoading, setInAppPageList } from '../store';
import InAppPageEditor from './in-app-page-editor';
import MenuItem from './menu-item';

const Pages = () => {
    const { projectId } = useProject();
    const { inAppPageList, isLoading } = useInAppPage();
    let { path, params } = useRouteMatch();
    const basePath = generatePath(path, params);
    const fetchInAppPageList = async () => {
        try {
            setLoading(true);
            const inAppPageListResponse = await api.getInAppPageList(projectId);
            setInAppPageList(inAppPageListResponse);
        } catch (error) {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchInAppPageList();
    }, [])

    if (isLoading) {
        return <div>Loading...</div>
    }
    return <div>
        {/* <pre>{JSON.stringify(inAppPageList, null, 2)}</pre> */}
        <MenuItem inAppPageList={inAppPageList} basePath={basePath} />
        
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to={`${basePath}/home`} />
            </Route>
            <Route path={`${path}/:inAppPageId`}>
                <InAppPageEditor />
            </Route>
        </Switch>
        
    </div>
}

export default Pages;