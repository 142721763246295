import clsx from 'clsx';
import { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ attributes = {} }) => {
	const { label, defaultColor, onChangeComplete, singleItem } = attributes;

	const [showPicker, setShowPicker] = useState(false);

	return (
		<div className={clsx('relative w-full', singleItem ? null : 'mt-4')}>
			<label className="font-regular text-sm mb-1 block">{label}</label>
			<button
				type="button"
				className="relative flex gap-2 items-center w-full border bg-gray-50 border-gray-300 rounded-lg p-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				onClick={() => setShowPicker(true)}
			>
				<span
					className="w-8 h-8 inline-flex rounded"
					style={{ backgroundColor: defaultColor }}
				/>
				{defaultColor}
			</button>
			{showPicker && (
				<div className="absolute top-24 left-0 z-10">
					<div onClick={() => setShowPicker(false)} className="fixed inset-0" />
					<SketchPicker
						disableAlpha={true}
						presetColors={definedColors}
						color={defaultColor}
						onChangeComplete={onChangeComplete}
					/>
				</div>
			)}
		</div>
	);
};

export default ColorPicker;

const definedColors = [
	'#EF4444',
	'#F59E0B',
	'#10B981',
	'#3B82F6',
	'#6366F1',
	'#8B5CF6',
	'#EC4899',
	'#14B8A6',
	'#10B981',
	'#111827',
	'#4A4A4A',
	'#9B9B9B',
	'#FFFFFF',
	'transparent',
];
