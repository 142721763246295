import api from 'packages/blockEditor/api';
import { useEffect, useState } from 'react';

const { getInAppPageList } = api;
const useInappPages = ({ type } = {}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [pages, setPages] = useState([]);
	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await getInAppPageList(type);
				setPages(response);
			} catch (error) {}
			setIsLoading(false);
		})();
	}, []);
	const search = (query) => {
		// search from pages
		setPages(
			pages.filter((page) =>
				page?.label.toLowerCase().includes(query.toLowerCase())
			)
		);
	};
	return { loading: isLoading, pages, onSearch: search };
};

export default useInappPages;
