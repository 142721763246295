import appmaker from '@appmaker/core/index';
import { LightBulbIcon, CreditCardIcon } from '@heroicons/react/solid';
import PublishApp from './routes/PublishApp';
import Subscription from './routes/Subscription';

// const BlockEditor = lazy(() => import('./routes/Editor'));
const Plugin = {
	id: '@appmaker/publishapp',
	name: 'Publish App',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/publishApp',
			component: PublishApp,
		});
		appmaker.registerRoute({
			routeName: '/subscription-choose',
			component: Subscription,
		});
		/**
		 * Menu Items
		 */


		// appmaker.registerSideBarMenu(
		// 	{
		// 		name: 'Publish App',
		// 		routeName: '/publishApp',
		// 		icon: LightBulbIcon,
		// 	},
		// 	5
		// );

		// appmaker.doAction('register-onboarding-steps', {
		// 	title: 'Publish App',
		// 	id: 'download-app',
		// 	route: '/publishApp',
		// 	// onClick: () => setIsOpen(true),
		// 	order: 10
		// })

		// appmaker.doAction('register-onboarding-steps', {
		//     title: 'Configure Push Notification',
		//     id: 'pushNotification/configure',
		//     route: '/pushNotification/configure',
		//     order: 6
		// })


		// },
		// appmaker.registerSideBarMenu(
		// 	{
		// 		name: 'Publish App',
		// 		routeName: '/publishApp',
		// 		icon: LightBulbIcon,
		// 	},
		// 	5
		// );
		// appmaker.registerSideBarMenu(
		// 	{
		// 		name: 'Subscription',
		// 		routeName: '/subscription-choose',
		// 		icon: CreditCardIcon,
		// 	},
		// 	5
		// );
		// appmaker.doAction('register-onboarding-steps', {
		//     title: 'Configure Push Notification',
		//     id: 'pushNotification/configure',
		//     route: '/pushNotification/configure',
		//     order: 6
		// })
	},
};

export default Plugin;
