import React, { useState,useEffect } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const JsonTextField = ({ properties = {}, defaultValue, value, onChange }) => {
	const {
		name,
		label,
		placeholder,
		required,
		error,
		description,
		className,
		rows = 3,
		valueType,
	} = properties;
	const _val = defaultValue || value ;
	const [_inputValue,setInputValue] = useState('');
	try {
		// inputValue = valueType ==='json' ? JSON.stringify(inputValue) : inputValue;
	} catch (error) {
		console.log(error);
		
	}
	function updateValue(_value) {
		let value = _value
		try {
			value =  JSON.parse(_value);
			onChange(value) 
		} catch (error) {
			
		}
		  

	}
	useEffect(() => {
		updateValue(_inputValue)
	}, [_inputValue])
	useEffect(()=>{
		try {
			const value =  JSON.stringify(_val,null,2);
			setInputValue(value) 
		} catch (error) {
			
		}
		  
	},[_val])
	
	return (
		<div className={className ? className : 'mt-4'}>
			<label htmlFor={name} className="block text-sm font-base text-gray-700">
				{label}
			</label>
			<div className="mt-1 relative">
					<textarea
						name={name}
						id={name}
						required={required}
						value={_inputValue}

						className={clsx(
							'shadow-sm block w-full sm:text-sm border-gray-300 rounded-md',
							error
								? 'border-red-400 text-red-900 focus:ring-red-500 focus:border-red-500'
								: 'focus:ring-indigo-500 focus:border-indigo-500'
						)}
						placeholder={placeholder}
						onChange={e => setInputValue(e.target.value)}

						rows={rows}
					/>
				
				{error && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon
							className="h-5 w-5 text-red-500"
							aria-hidden="true"
						/>
					</div>
				)}
			</div>
			<div className="flex items-center gap-1">
				{description && (
					<p className="mt-1 text-sm text-gray-500" id={`${name}-description`}>
						{description}
					</p>
				)}
				{description && error && <span className="text-gray-500">&bull;</span>}
				{error && (
					<p className="mt-0.5 text-sm text-red-600" id={`${name}-error`}>
						{error}
					</p>
				)}
			</div>
		</div>
	);
};

export default JsonTextField;
