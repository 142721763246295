import DashboardCard from './components/DashboardCard';
import AppCard from './components/blocks/AppCard';
import { getProjectData } from '@appmaker/core/store/project';
import { useState, useEffect } from 'react';
import graphQlApi from '@appmaker/core/api/graphql';
import SimplePopover from '@appmaker/components/templates/SimplePopover';
import { InformationCircleIcon } from '@heroicons/react/outline';

const AppDownloadCard = () => {
	const [apps, setApps] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const { loading, projectId, projectData } = getProjectData();
	let buildCheckIntervalId = false;
	const getBuildStatus = async () => {
		const apps = await graphQlApi.appBuildStatus();
		setApps(apps);
		if (
			apps &&
			apps.filter((app) => app.buildInProgress === true).length === 0
		) {
			clearInterval(buildCheckIntervalId);
		}
	};

	const startBuildStatusCheck = async () => {
		setLoading(true);
		await getBuildStatus();
		setLoading(false);
		buildCheckIntervalId = setInterval(() => {
			getBuildStatus();
		}, 5000);

		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(buildCheckIntervalId);
	};

	useEffect(() => {
		return startBuildStatusCheck();
	}, [projectId]);

	return (
		<DashboardCard
			attributes={{
				className:
					'md:col-span-6 lg:col-span-3 col-span-6 flex flex-col justify-between',
				title: 'Download Apps',
				loading: loading || isLoading,
				cta: (
					<div className="border border-gray-200 px-2 rounded text-sm text-gray-600">
						{`#${projectData?.id}`}
					</div>
				),
			}}
		>
			<div className="pb-6 flex flex-col flex-1 justify-evenly space-y-4">
				{apps &&
					apps.map((app, index) => {
						const previewBuild = app.jobs
							? app.jobs.find(
									(job) =>
										job.track === 'preview_build' && job.type === 'app_build'
							  )
							: undefined;
						return (
							<>
								<AppCard
									key={app.platform}
									attributes={{
										jobId: previewBuild?.jobId,
										appName: projectData.name,
										icon: projectData.icon,
										type: app.platform,
										version: app.version,
										projectId,
										token: app.token,
										platformId: app.platform === 'ios' ? 3 : 1,
										platform: app.platform,
										status: previewBuild ? previewBuild.status : 'PENDING',
										appBuildStatus: {
											status: {},
										},
									}}
								/>
								{index !== apps.length - 1 && (
									<div className="border-b border-gray-200" />
								)}
							</>
						);
					})}
			</div>
			<SimplePopover
				attributes={{
					arrow: true,
					button: (
						<div className="bg-indigo-200 text-indigo-600 flex items-center space-x-1 px-2 py-0.5 rounded-md focus:outline-none">
							<InformationCircleIcon className="h-5 w-5" />
							<span className="text-base">Instructions to test</span>
						</div>
					),
				}}
			>
				<div className="p-4">
					<ul class="ml-4 list-outside list-disc">
						<li class="mb-1">Download your app file file (Android/iOS)</li>
						<li class="mb-1">
							Go to
							<a
								href="https://appetize.io/upload"
								class="text-blue-700 underline px-1 mx-1 hover:text-white hover:bg-blue-600"
								target="_blank"
								rel="noreferrer"
								tabindex="0"
							>
								https://appetize.io/upload
							</a>
							and upload the app file, follow the steps mentioned in appetize.io
						</li>
					</ul>
					<div class="mt-4 text-blue-900 font-medium mx-4">
						<small class="bg-blue-100 px-2 py-0.5 rounded-full mr-2">
							Note
						</small>
						<span>
							If you have an android phone, you can install the app on your
							phone and test.
						</span>
					</div>
				</div>
			</SimplePopover>
		</DashboardCard>
	);
};
export default AppDownloadCard;
