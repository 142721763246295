
// import { BASE_URL } from '../constants';
import { BASE_URL } from '@appmaker/core/api/core';
import axios from 'axios';

const apiFunction = {
    postAccessKey: async (projectId, data) => {
        try {
            const url = `${BASE_URL}/projects/${projectId}/update-config`;
            const response = await axios.post(url, data, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            return response.data
        } catch (error) {
            throw error;
        }
    },
    updateStoreUrl: async (projectId, url) => {
        try {
            const data = {
                accessToken: btoa(JSON.stringify({
                    url,
                    key: 'UPDATE_URL_TEMP',
                    secret: 'UPDATE_URL_API'
                }))
            }
            const postUrl = `${BASE_URL}/projects/${projectId}/update-config`;
            const response = await axios.post(postUrl, data, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            return response.data
        } catch (error) {
            throw error;
        }
    },
    validateUrl: async (projectId) => {
        try {
            const response = await axios.get(`${BASE_URL}/projects/${projectId}/validate-url`, {
                credentials: 'include',
                withCredentials: true
            });
            return response.data
        } catch (error) {
            throw error;
        }
    },
    pluginStatus: async (projectId) => {
        try {
            const response = await axios.get(`${BASE_URL}/projects/${projectId}/plugin-status`, {
                credentials: 'include',
                withCredentials: true
            });
            return response.data
        } catch (error) {
            throw error
        }
    }
}

export default apiFunction