
import React from 'react';
import appmaker from '@appmaker/core/index';
import { Switch, Route, useRouteMatch } from '@appmaker/core/routes';
import DashboardLayout from 'components/layout/dashboard';
import SidebarNavigation, { SideNavFooter } from 'components/SidebarNavigation';
import { useProject } from '@appmaker/core/store/project';

function PluginRoute(props) {
    const items = appmaker.applyFilters('onboarding-menu-item', []);
    const routes = appmaker.applyFilters('onboarding-routes', []);
    let { path } = useRouteMatch();
    const { projectData } = useProject()
    return (
        <DashboardLayout
            appIcon={projectData.icon}
            appName={`${projectData.name}`}
            templateName={projectData.template}
            sideNav={<SidebarNavigation navigation={items} basePath='/onboarding' />}
        // sideNavFooter={<SideNavFooter />}
        >            <div>
                <Switch>
                    {routes.map((item) => {
                        return (
                            <Route key={item.routeName} path={`${path}${item.routeName}`}>
                                <item.component />
                            </Route>
                        );
                    })}
                </Switch>
            </div>
        </DashboardLayout>
    );
};
PluginRoute.fullLayout = true;
export default PluginRoute;