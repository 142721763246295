const StepSeven = () => {
    return (
        <div>
            After setting up the app, you have to prepare your app for production release. Follow the on-screen instructions to prepare your release:
            <ul className="list-outside ml-4 list-disc flex flex-col gap-3">
                <li>
                    Under Release, select Production

                    <img
                        className="w-full rounded-lg border border-gray-100 mt-1"
                        src="/images/publish/android/new/step-7-1.png"
                        alt="step one"
                    />
                </li>
                <li>
                    Select the Countries in which you want the app to be available
                    <img
                        className="w-full rounded-lg border border-gray-100 mt-1"
                        src="/images/publish/android/new/step-7-2.png"
                        alt="step one"
                    />
                </li>

            </ul>
        </div>
    )
}

export default StepSeven