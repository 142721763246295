import { entity } from 'simpler-state';


const themesList = entity({
    originalList: [],
    list: [],
    setValue: false,
    activeTheme: {}
})

const useThemeList = (args) => themesList.use(args);

const setThemeList = (data) => themesList.set(value => ({ ...value, ...data }));


export {
    useThemeList,
    setThemeList
}