import { CheckIcon } from '@heroicons/react/outline';
import api from '../../../api';
import { useProject } from '@appmaker/core/hooks';
import { useEffect, useState } from 'react';
import { sendNotification } from '../../../store';
import { useQuery } from '@apollo/client';

import LoadingIcon from 'packages/mobile-app/components/LoadingIcon';

const StepFive = () => {
	const { projectId } = useProject();
	const [isLoading, setLoading] = useState(false);

	const { loading, error, data } = useQuery(api.appDataQuery(), {
		variables: {
			templateVersionsWhere: {
				name: {
					equals: 'stable',
				},
			},
			take: 1,
			orderBy: {
				createdAt: 'desc',
			},
			where: {
				project: {
					remoteProjectId: {
						equals: parseInt(projectId, 10),
					},
				},
			},
		},
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	const updateApp = async () => {
		try {
			setLoading(true);
			// const response = await api.buildApp(projectId);
			// if (response.status) {
			// 	// TODO message or redirection
			// sendNotification('App build started', {
			// 	appearance: 'success',
			// });
			// setInterval(() => {
			// 	window.location.reload();
			// }, 3000);
			// }
			const response = await api.triggerBuildGql(projectId, data);
			if (response?.data?.data?.createAppBuilds) {
				sendNotification('App build started', {
					appearance: 'success',
				});
			}
		} catch (error) {
			console.log(error);
			sendNotification('Please rebuild your app', {
				appearance: 'error',
			});
		} finally {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
			setLoading(false);
		}
	};
	if (loading) {
		return <LoadingIcon className="text-blue-600 animate-spin h-16 w-16" />;
	}
	return (
		<div className="flex flex-col flex-auto items-center justify-center">
			<CheckIcon className="h-16 w-16 p-4 bg-green-100 text-green-600 rounded-full" />
			<h3 className="text-green-600 font-medium text-lg mt-2 mb-6">
				Configuration succesfully completed
			</h3>
			<h5 className="text-xl">
				Please <span className="font-bold">Update the app</span> to start
				sending push notifications
			</h5>
			<p className="text-sm text-gray-600 mb-6">
				Update the app and test push notification on your updated app.
			</p>
			<button
				type="button"
				className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				onClick={updateApp}
				disabled={isLoading}
			>
				{isLoading ? 'Updating App...' : 'Update App'}
			</button>
		</div>
	);
};

export default StepFive;
