import { addFilter } from "@wordpress/hooks";
import { collectionApi } from '@appmaker/core/api';
import axios from '@appmaker/core/axios';
import appmaker from '@appmaker/core/';


const ImageSelect = ({ onChange }) => {
    return <div>Image Select<button onClick={() => {
        onChange({ name: 'saleeh', world: 'hello' })
    }}>Changes</button></div>
}


let actions = {}
actions = actions || {};
actions.OPEN_IN_WEBVIEW = {
    label: "Open webview",
    properties: {
        url: {
            label: "URL",
            type: 'string'
        }
    }
}
actions.OPEN_INAPP_PAGE = {
    label: "Open InApp Page",
    properties: {
        paegId: {
            type: 'promise-select',
            label: "Page Id",
            getOptions: async (inputValue, { projectId = 5731 }) => {
                const pageList = await collectionApi(`inAppPages`, projectId).list()
                const finalList = pageList.map(item => ({ label: item.title, value: item._id }))
                return finalList;
            }
        },

    }
}
actions.SELECT_IMAGE = {
    label: 'Image Select',
    properties: {
        hasImage: {
            type: 'switch'
        },
        image: {
            type: 'component',
            Component: ImageSelect
        }
    }
}
actions.OPEN_PRE_BUILD = {
    label: 'Open pre-build products',
    properties: {
        type: {
            type: 'select',
            action: {
                label: 'Choose options',
                value: 'OPEN_PRE_BUILD_OPTION_ID'
            },
            // options:[]
            getOptions: () => {
                return [
                    {
                        value: 'recent',
                        label: 'Recent Products'
                    },
                    {
                        value: 'best_selling',
                        label: 'Best Selling Products'
                    },
                    {
                        value: 'top_rated',
                        label: 'Top Rated Products'
                    },
                    {
                        value: 'featured',
                        label: 'Featured Products'
                    },
                    {
                        value: 'sale',
                        label: 'Sale Products'
                    }
                ]
            }
        }
    }

}
actions.OPEN_PRODUCT = {
    label: "Open Product",
    properties: {
        productId: {
            type: 'promise-select',
            label: "Product Id",
            getOptions: async (inputValue, { projectId }) => {
                // const shopifyDataSource = getCurrentShopifyDataSource();
                try {
                    const resp = await axios(`http://localhost:8080/https://babyjalebi.com/search/suggest.json?q=${inputValue}&resources[type]=product`, {
                    })
                    const finalList = resp.data.resources.results.products
                        .map(item => ({ label: item.title, value: item.id }))
                    return finalList;
                } catch (error) {
                    console.log(error);
                }

            }
        },

    }
}
// Object.keys(actions).map((action) =>
//     appmaker.actions.registerAction(action, actions[action])
// );