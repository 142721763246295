import { entity } from 'simpler-state';
import coreApi from 'packages/appmaker-core/api/core';

export const CONSTANT = {
    PLATFORM_ANDROID: '1',
    PLATFORM_IOS: '3',
    APP_STATUS: {
        APP_STATUS_NOT_STARTED: '0',
        APP_STATUS_BUILDING: '1',
        APP_STATUS_SUCCESS: '2',
        APP_STATUS_FAILED: '-1',
        APP_STATUS_DELETED: '-2',
        APP_STATUS_CERT_NOT_UPLOADED: '-3',
    }
}
export const formData = entity({
    androidAppData: undefined,
    iosAppData: undefined
})

export const useFormData = (args) => formData.use(args);
export const setFormData = data => { formData.set(data) };

export const downloadAppBundleLink = ({ projectId,apps={} }) => {
    let app = apps.find(item=>item.platformId == 1)
    const token = app && app.token ? app.token : undefined;

    // return `https://appmaker.xyz/dashboard/apps/download/?preview=false&bundle=true&project_id=${projectId}`;
    return (coreApi.downloadApp({
        projectId,
        preview: false,
        bundle: true,
        token,
        platform: 'android'
    }))
};