import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { DotsVerticalIcon } from '@heroicons/react/outline';

export default function DropDownMenu({ options, button }) {
	return (
		<Menu as="div" className="relative inline-flex text-left">
			<>
				<Menu.Button className="inline-flex justify-center">
					{button ? (
						button
					) : (
						<DotsVerticalIcon className="h-8 w-8 p-1.5 hover:bg-gray-100 rounded-md text-gray-600 self-center" />
					)}
				</Menu.Button>
			</>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 top-2 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					{options.map((option) => (
						<div className="py-1" key={option.title}>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={option.onClick}
										disabled={option?.disabled}
										className={clsx(
											active ? 'bg-gray-50 text-gray-700' : 'text-gray-600',
											'block px-4 py-2 text-sm w-full text-left'
										)}
									>
										{option?.loading ? option?.isLoadingText : option.label}
									</button>
								)}
							</Menu.Item>
						</div>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
