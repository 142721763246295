import {
    createStore,
    AppmakerFormProvider,
    useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';




const FormSchema = ({ schema, showFormValue = () => { }, buttonLabel = 'Save' }) => {
    const storeApi = useAppmakerFormStoreApi();

    const onClickSave = () => {
        const { formData } = storeApi.getState();
        console.log(formData)
        showFormValue(formData);
    }
    return (
        <div className="bg-gray-50 h-full p-6">
            <div className="max-w-3xl">
                <AppmakerForm
                    formVersion={2}
                    // value={{ formData: flatten(formData) }}
                    schema={schema}
                />
                <button
                    className='my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    onClick={() => { onClickSave() }}
                >
                    {buttonLabel}
                </button>
            </div>
        </div>
    );
}


const EditForm = ({
    formValue = {},
    schema = {},
    showFormValue = () => { },
    buttonLabel = 'Save',
}) => {
    return (
        <AppmakerFormProvider
            createStore={createStore({
                formData: formValue,
            })}
        >
            <FormSchema
                schema={schema}
                showFormValue={showFormValue}
                buttonLabel={buttonLabel}
            />
        </AppmakerFormProvider>
    )
}

export default EditForm;