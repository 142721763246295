import appmaker from '@appmaker/core/index';
import { PresentationChartLineIcon } from '@heroicons/react/solid';
import WorkflowTrigger from './WorkflowTrigger';
const AppmakerWorkflow = {
	id: 'appmaker-workflow',
	name: 'Appmaker Workflow',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/appmaker-workflow',
			component: WorkflowTrigger,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Workflow',
			routeName: '/appmaker-workflow',
			icon: PresentationChartLineIcon,
		});
	}
}

export default AppmakerWorkflow