import React from 'react'
import clsx from 'clsx';
import MultiSelect from './MultiSelect';
import { addLanguage, useLanguageFormData } from './store/formData'
import {
    useRouteMatch,
    generatePath,
    Link,
    matchPath,
    useLocation
} from '@appmaker/core/routes';
import { useState } from 'react';
import { setLanguageToDefault } from './helper';

export default function LanguageSidebar({ availableLanguages =[]}) {
    let { path, params, } = useRouteMatch();
    const { projectId } = params;
    let location = useLocation();
    const [isLoading, setLoading] = useState(false);
    const selectedLanguages = useLanguageFormData(data => data.selectedLanguages);
    const defaultLanguage = useLanguageFormData(data => data.defaultLanguage);
    const languageValues = useLanguageFormData(data => data.languageValues);
    const match = matchPath(location.pathname, {
        path: `${path}/:languageId`,
    })
    const languageId = match?.params?.languageId;

    const addNewLanguage = async (languageCode) => {
        // to fix repeated language array.
        if (Array.isArray(selectedLanguages) && !selectedLanguages.includes(languageCode)) {
            // To get the default language values if not in global
            if (!languageValues[languageCode]) {
                await setLanguageToDefault(projectId, languageCode, setLoading);
            }
            addLanguage(languageCode);
        }
    }
    return (
        <div className="bg-gray-900 text-white p-3 w-64">
            {!isLoading && <MultiSelect
                className="border-b border-gray-600 pb-2 mb-2 text-black"
                otherProps={{ placeholder: 'Search languages' }}
                options={availableLanguages}
                onChange={(value) => {
                    addNewLanguage(value.value)
                }}
            />}
            {isLoading && <span>Loading ....</span>}
            <nav className="py-2 flex flex-col gap-1">
                {selectedLanguages && Array.isArray(selectedLanguages) && selectedLanguages.map((language) => {
                    const currentLanguage = availableLanguages.find(item => item.value === language) || {};
                    return (
                        <Link
                            to={`${generatePath(path, { ...params, })}/${language}`}
                            className={clsx(
                                'py-1 px-2 rounded-md font-medium flex items-center justify-between cursor-pointer hover:bg-gray-800',
                                { ' bg-gray-700': language === languageId }
                            )}
                        >
                            <span>{currentLanguage.label}</span>
                            {language === defaultLanguage && (
                                <span className="px-1 bg-blue-500 text-white inline-flex text-xs font-semibold rounded-full">
                                    Default
                                </span>
                            )}
                        </Link>
                    )
                })}
            </nav>
        </div>
    )
}
