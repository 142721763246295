import DropDownMenu from '@appmaker/components/DropDownMenu';
import { isAdmin } from '@appmaker/core/store/user';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export default function ListItem(props) {
	const { extension, getRouterPath } = props;
	let bgColor = 'bg-white';
	if (extension?.type === 'admin' || extension?.type === 'private') {
		bgColor = 'bg-blue-50';
	}
	if (extension?.type === 'unlisted') {
		bgColor = 'bg-red-50';
	}
	return (
		<Link
			to={getRouterPath('/extension/' + extension?.handle)}
			className={clsx(
				'p-2 group rounded-xl border hover:shadow relative border-gray-300',
				bgColor
			)}
		>
			<div className="relative group flex items-center justify-between space-x-3">
				<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-9 w-9">
					<img
						src={
							extension?.icon ||
							'https://storage.googleapis.com/appilder_cdn/extension-default.png'
						}
						alt={extension?.imageAlt}
						className="object-contain object-center h-9 w-9"
					/>
				</div>
				<div className="flex-1">
					<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
						{extension?.name}
					</h3>
					{(isAdmin() && extension?.packageVersion) &&(
						<p className="text-xs capitalize text-gray-500">
							Version: {extension?.packageVersion}
						</p>
					)}
				</div>
				{/* TODO - Extension List Dropdown */}
				<DropDownMenu
					options={[
						{
							label: 'Settings',
							onClick: null,
						},
					]}
				/>
			</div>
		</Link>
	);
}
