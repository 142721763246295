import {
	ApolloClient,
	InMemoryCache,
	HttpLink,
	ApolloLink,
} from '@apollo/client';
import { APPMAKER_GQL_URL } from '@appmaker/core/api/core';
import { setContext } from '@apollo/client/link/context';
import { getSessionToken } from '@shopify/app-bridge/utilities';

const httpLink = new HttpLink({
	uri: APPMAKER_GQL_URL,
	fetchOptions: {
		credentials: 'include',
	},
});

const customHeaderMiddleware = setContext(async (_, { headers }) => {
	let customHeader = {};
	// Return the headers to the context so httpLink can read them
	if (window?.shopifyApp) {
		try {
			const token = await getSessionToken(window?.shopifyApp);
			if (token) {
				customHeader['Authorization'] = `Bearer ${token}`;
			}
		} catch (error) {}
	}
	return {
		headers: {
			...headers,
			...customHeader,
		},
	};
});

const link = ApolloLink.from([customHeaderMiddleware, httpLink]);
const client = new ApolloClient({
	link: link,
	credentials: 'include',
	cache: new InMemoryCache(),
});

export default client;
