// @flow strict

import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import coreApi from '@appmaker/core/api/core';
import LoadingIndicator from '../components/LoadingIndicator';
import FormSteps from './FormSteps';
import { setformSchema } from '../store/formSchema';
import { setFormData } from '../store/formData';
import { useProject } from '@appmaker/core/store/project';
import { createStore, Provider } from '@appmaker/core/components/AppmakerForm/store'
import CreateMethodModal, { useModal } from '@appmaker/components/templates/getting-started-forms';
import { getPublicMeta } from '@appmaker/core/store/project';
import appmaker from '@appmaker/core/';
import { Redirect } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';

function InitialCustomize() {
    const { projectId } = useProject()
    const [{ value, loading }, doFetch] = useAsyncFn(async () => await coreApi.getCustomizeForm(projectId, 'create'), []);
    const [showInitialModal, setInitialModal] = useModal();
    const publicMeta = getPublicMeta() || {};
    const routerPath = useRouterPath();

    const {
        initial_form_setup_completed,
        onboarding_completed = false,
    } = publicMeta;
    const GetStartedForm = appmaker.applyFilters(
        'getting-started-form',
        () => null
    );
    // const TemplateChooser = appmaker.applyFilters(
    //     'template-chooser',
    //     () => null
    // );
    
    
    useEffect(() => {
        if (!loading && !value) {
            doFetch();
        } else if (value) {
            setformSchema(value.body)
            setFormData({ ...value.body.formData, ___isUpdateForm: false });
        }
    }, [value]);

    useEffect(() => {
        setInitialModal(initial_form_setup_completed !== true);
    }, [initial_form_setup_completed]);

    if (onboarding_completed) {
        // setInitialStepCompleted(true);
        if(Window?.localStorage) { //  local storage is not available in incognito mode and when 3rd party cookies are blocked
            Window.localStorage.setItem('appmaker-initial-step-completed', true);
        }
        return <Redirect to={routerPath('/blockEditor/home')} />
    }


    return (value === undefined || loading) ? <LoadingIndicator /> : (
        <Provider createStore={createStore({
            formData: value?.body?.formData
        })}>
            {GetStartedForm}
            {/* {TemplateChooser} */}
            <FormSteps template={value?.body?.template} />
        </Provider>
    );
};

export default InitialCustomize;