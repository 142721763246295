import Loader from '@appmaker/components/Loading';
import { useParams, Link } from '@appmaker/core/routes';
import { useState, useEffect } from 'react';
import { getExtension } from '../api/extension';

const TableItem = ({ project, extensionId }) => {
	const { id, name } = project;
	return (
		<tr className="py-2 px-4">
			<td className="py-2 px-4 border border-gray-200 text-center">{id}</td>
			<td className="py-2 px-4 border border-gray-200 text-center">{name}</td>
			<td className="py-2 px-4 border border-gray-200 text-center">
				<a
					href={
						extensionId
							? `https://v3.appmaker.xyz/apps/${id}/extensions/${extensionId}`
							: `https://v3.appmaker.xyz/apps/${id}`
					}
					target="_blank"
					rel="noopener noreferrer"
					className="text-blue-500 hover:underline text-center"
				>
					Link
				</a>
			</td>
		</tr>
	);
};

const ExtensionProject = () => {
	const { extensionId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [limit, setLimit] = useState(20);
	const [offset, setOffset] = useState(0);
	const [orderBy, setOrderBy] = useState('asc');
	const [searchTerm, setSearchTerm] = useState('');
	const [projects, setProjects] = useState([]);
	const handlePreviousClick = () => {
		if (offset === 0) return;
		setOffset(offset - limit);
	};

	const handleNextClick = () => {
		setOffset(offset + limit);
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};
	const fetchData = async () => {
		setIsLoading(true);
		try {
			const response = await getExtension({
				extensionId: extensionId,
				limit: limit,
				offset: offset,
				orderBy: orderBy,
				search: searchTerm,
			});
			setProjects(response?.extension?.projects ?? []);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			fetchData();
		}, 1000);
		return () => clearTimeout(delayDebounceFn);
	}, [searchTerm]);

	useEffect(() => {
		fetchData();
	}, [limit, offset, extensionId, orderBy]);
	return (
		<div className="w-full max-w-screen-xl m-4 p-2">
			<div className="flex justify-between items-center mb-4">
				<div>
					<input
						type="text"
						placeholder="Search projects"
						className="py-2 px-4 rounded-md border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400"
						// value={searchTerm}
						onChange={handleSearchChange}
						disabled={isLoading}
					/>
				</div>
				<div className="flex items-center">
					<button
						className={`${
							isLoading
								? 'bg-gray-300 cursor-not-allowed'
								: 'bg-blue-500 hover:bg-blue-600'
						} py-2 px-4 text-white rounded-md mr-2`}
						disabled={isLoading}
						onClick={handlePreviousClick}
					>
						Previous
					</button>
					<button
						className={`${
							isLoading
								? 'bg-gray-300 cursor-not-allowed'
								: 'bg-blue-500 hover:bg-blue-600'
						} py-2 px-4 text-white rounded-md`}
						disabled={isLoading}
						onClick={handleNextClick}
					>
						Next
					</button>
				</div>
			</div>
			{isLoading && <Loader />}
			{!isLoading && (
				<>
					{/* Heading */}
					<div className="flex justify-between items-center mb-4">
						<h1 className="text-2xl font-bold">Extension: {extensionId?.toUpperCase()}</h1>
					</div>

					<table className="w-full">
						<thead>
							<tr>
								<th className="py-2 px-4 border border-gray-200">Project ID</th>
								<th className="py-2 px-4 border border-gray-200">Name</th>
								<th className="py-2 px-4 border border-gray-200">
									Link to Extension
								</th>
							</tr>
						</thead>
						<tbody>
							{projects?.map((project) => (
								<TableItem
									key={project.id}
									project={project}
									extensionId={extensionId}
								/>
							))}
						</tbody>
					</table>
				</>
			)}
			{/* <div className="mt-4 flex justify-center items-center">
            <p>
              Page {currentPage} of {totalPages}
            </p>
          </div> */}
		</div>
	);
};

export default ExtensionProject;
