/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useShowSidebar, useSmallSidebar } from '@appmaker/core/store/settings';
import clsx from 'clsx';
import appmaker from '@appmaker/core/';

export default function DashboardLayout({
	children,
	appIcon,
	appName,
	templateName,
	sideNav,
	sideNavFooter,
	fullLayout = false,
}) {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	// const [smallSideBar, SetSmallSideBar] = useState(true);
	// const [hideSideBa, setSidebarOpen] = useState(false);
	const showSideBar = useShowSidebar();
	const smallSideBar = useSmallSidebar();


	const SideBarTop = appmaker.applyFilters(
		'appmaker-dashboard-sidebar-top',
		() => null
	);

	return fullLayout ? (
		children
	) : (
		<div className="h-screen flex overflow-hidden bg-white ">
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 flex z-40"
					onClose={setSidebarOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="absolute top-0 right-0 -mr-12 pt-2">
									<button
										className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
										onClick={() => setSidebarOpen(false)}
									>
										<span className="sr-only">Close sidebar</span>
										<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
									</button>
								</div>
							</Transition.Child>
							<div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
								<div className="flex-shrink-0 flex items-center px-4">
									<img className="h-12 w-auto" src={appIcon} alt={appName} />
									<div>
										<h5
											className="font-semibold text-lg text-gray-900 line-clamp-1"
											title={appName}
										>
											{appName}
										</h5>
										<h6 className="text-xs text-blue-700">{templateName}</h6>
									</div>
								</div>
								{sideNav}
							</div>
							{sideNavFooter && (
								<div className="flex-shrink-0 flex border-t border-gray-200 px-4 py-2">
									{sideNavFooter}
								</div>
							)}
						</div>
					</Transition.Child>
					<div className="flex-shrink-0 w-14">
						{/* Force sidebar to shrink to fit close icon */}
					</div>
				</Dialog>
			</Transition.Root>
			{/* Static sidebar for desktop */}
			<div
				className={clsx({
					hidden: !showSideBar,
					'md:flex md:flex-shrink-0 hidden': showSideBar,
				})}
			>
				<div className={clsx('flex flex-col', smallSideBar ? 'w-16' : 'w-48')}>
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
						{/* back to shopify button */}
						{SideBarTop}
						{/* End of back to shopify button */}
						<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto overflow-x-hidden">
							<div
								className={clsx('flex items-center flex-shrink-0 px-4 gap-3', {
									'flex-col': smallSideBar,
								})}
							>
								<img
									className={clsx('w-auto', smallSideBar ? 'h-8' : 'h-11')}
									src={appIcon}
									alt={appName}
									title={appName}
								/>
								{!smallSideBar && (
									<div>
										<h5
											className="font-semibold text-lg text-gray-900 line-clamp-1"
											title={appName}
										>
											{appName}
										</h5>
										<h6 className="text-xs text-blue-700">{templateName}</h6>
									</div>
								)}
							</div>
							{sideNav}
						</div>
						{sideNavFooter && (
							<div className="flex-shrink-0 flex border-t border-gray-200 px-4 py-2">
								{sideNavFooter}
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="flex flex-col w-0 flex-1 overflow-hidden">
				<div
					className={clsx(
						{
							'md:hidden': showSideBar,
						},
						'pl-1 pt-1 sm:pl-3 sm:pt-3'
					)}
				>
					<button
						className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
						onClick={() => setSidebarOpen(true)}
					>
						<span className="sr-only">Open sidebar</span>
						<MenuIcon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white scrollbar-hide">
					{children}
				</main>
			</div>
		</div>
	);
}
