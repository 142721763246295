import appmaker from '@appmaker/core/index';
import ExtensionHome from './routes/ExtensionHome';
import ExtensionDetailOld from './routes/ExtensionDetailOld';
import { CubeIcon } from '@heroicons/react/solid';
import { addNewPlugin } from '@appmaker/core/store/project';
import ExtensionInstall from './routes/ExtensionInstall';
import ExtensionDetail from './routes/ExtensionDetails';
import Extensions from './routes/Extensions';
import ExtensionDetailRoute from './routes/ExtensionDetailsRoute';
import ExtensionList from './routes/ExtensionList';

const Plugin = {
	id: '@appmaker/extensions',
	name: 'Extensions',
	activate: () => {
		// appmaker.registerRoute({
		// 	routeName: '/extensions/:extensionId',
		// 	component: ExtensionDetailOld,
		// });

		// appmaker.registerRoute({
		// 	routeName: '/extensions',
		// 	component: ExtensionHome,
		// });
		
		appmaker.registerRoute({
			routeName: '/extension/install/:handle',
			component: ExtensionInstall,
		});
		appmaker.registerRoute({
			routeName: '/extension/:handle/:route',
			component: ExtensionDetailRoute,
		});
		appmaker.registerRoute({
			routeName: '/extension/:handle',
			component: ExtensionDetail,
		});
		appmaker.registerRoute({
			routeName: '/extension',
			component: Extensions
		})
		appmaker.registerRoute({
			routeName: '/extension-list',
			component: ExtensionList,
		})
		
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Extensions',
			routeName: '/extension',
			icon: CubeIcon,
		});
		// appmaker.registerSideBarMenu(
		// 	{
		// 		name: 'Extension List',
		// 		routeName: '/extension-list',
		// 		adminOnly: true,
		// 		icon: CubeIcon,
		// 	},
		// 	20
		// );
		// hooks called when any of the extension activate
		appmaker.addAction(
			'extension-activate',
			'appmaker-extension',
			(newPlugin) => {
				// update the newly added extension to project store. REASON: Project api is only called initially
				addNewPlugin(newPlugin);
				appmaker.activatePlugins([newPlugin]);
			}
		);
		// hooks called when any of the extension deactivate
		appmaker.addAction('extension-deactivate', 'appmaker-extension', (args) => {
			// console.log(args, 'yy', 'in-deactivate')
		});
	},
};

export default Plugin;
