import { CloudDownloadIcon } from '@heroicons/react/solid';

const StepThree = () => {
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			<li>
				To start setting up your app, select Dashboard on the left menu

				The below list of tasks need to be completed in to finish the Set up process.
				<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-3.png"
					alt="step one"
				/>
			</li>
		</ul>
	);
};

export default StepThree;
