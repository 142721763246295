import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { BASE_URL, V3_BASE_URL } from '@appmaker/core/api/core';

export const uploadMedia = async (files) => {
	const projectId = getProjectId();
	const url = `${BASE_URL}/projects/${projectId}/images/upload`;
	const data = new FormData();
	data.append('file', files);
	try {
		const option = {
			url,
			withCredentials: true,
			credentials: 'include',
			method: 'POST',
			data,
		};
		const response = await axios(option);
		return response.data;
	} catch (error) {
		return Promise.reject(new Error('Error when calling upload media'));
	}
};

export const generateStagedUpload = async ({ files }) => {
	const projectId = getProjectId();
	const url = `${V3_BASE_URL}/projects/${projectId}/media/shopify/generate-staged-upload`;

	console.log(files, 'files');
	const filesMeta = Array.from(files).map((file) => {
		return {
			fileName: file.name,
			fileSize: file.size,
			fileType: file.type,
			resourceType: file?.resourceType,
		};
	});
	// const data = new FormData();
	// data.append('filesMeta', filesMeta);

	try {
		const option = {
			url,
			withCredentials: true,
			credentials: 'include',
			method: 'POST',
			data: { filesMeta },
		};
		const response = await axios(option);
		return response.data;
	} catch (error) {
		return Promise.reject(new Error('Error when calling upload media'));
	}
};

export const uploadFileToS3 = async ({ stagedTarget, file }) => {
	const { url, parameters, resourceUrl } = stagedTarget;

	const { type: fileType, name: fileName } = file;

	const formData = new FormData();
	parameters.forEach(({ name, value }) => {
		formData.append(name, value);
	});
	formData.append('file', file);

	try {
		const response = await fetch(url, {
			headers: {
				Accept: 'application/json',
			},
			method: 'POST',
			body: formData,
		});
		if (!response.ok) {
			console.error('File could not be uploaded.');
		}
		// console.log("RESPONSE", response)
		const key = parameters.find((p) => p.name === 'key');
		// console.log({ fileUrl: `${url}/${key.value}`, fileType, fileName })
		return { fileUrl: `${url}/${key.value}`, fileType, fileName, resourceUrl };
	} catch (err) {
		console.error(err);
	}
};

export const createFiles = async ({ files }) => {
	const projectId = getProjectId();
	const url = `${V3_BASE_URL}/projects/${projectId}/media/shopify/create-files`;

	//fileUrl , fileName, fileType

	try {
		const option = {
			url,
			withCredentials: true,
			credentials: 'include',
			method: 'POST',
			data: { filesData: files },
		};
		const response = await axios(option);
		return response.data;
	} catch (error) {
		return Promise.reject(new Error('Error when calling upload media'));
	}
};

export const listMedia = async ({ search, mediaTypes = [] } = {}) => {
	const projectId = getProjectId();
	let url = `${V3_BASE_URL}/projects/${projectId}/media/shopify?per_page=12${
		search ? `&search=${search}` : ''
	}`;
	mediaTypes?.forEach((media) => {
		if (media) {
			url += `&content_type=${media}`;
		}
	});
	try {
		const option = {
			url,
			withCredentials: true,
			credentials: 'include',
		};
		const response = await axios(option);
		// console.log(response.data.files)
		return response.data.files;
	} catch (error) {
		return Promise.reject(new Error('Error when loading upload media'));
	}
};

// export  api;
