// @flow strict

import {
	ExternalLinkIcon,
	InformationCircleIcon,
} from '@heroicons/react/solid';
import * as React from 'react';
import { useProject } from '@appmaker/core/store/project';

const PluginInstallInstructions = () => {
	const { projectData } = useProject();
	const { meta: { URL } = {} } = projectData;
	const url = URL.replace(/\/$/, "")
	const installURL = `${url}/wp-admin/plugin-install.php?s=appmaker-wc&tab=search&type=term`;
	return (
		<div>
			{/* <p className="font-semibold text-lg">
				Install & activate Appmaker Woocommerce plugin
			</p> */}
			<p className="text-base text-gray-700 mt-1">
				Click the “connect automatically” button below. You will be redirected to your WordPress plugin directory, where you can install and activate the plugin (See below screenshots for reference)

			</p>
			<div className="my-6">
				<a
					target="_blank"
					rel="noreferrer"
					href={installURL}
					className=" inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				>
					Connect Automatically
				</a>
			</div>
			{/* <div className="mt-2 flex gap-2 items-center">
				<div>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://wordpress.org/plugins/appmaker-woocommerce-mobile-app-manager/"
						className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					>
						Take me there
					</a>
				</div>
			</div> */}
			{/* <div className="rounded-md bg-purple-50 p-4">
				<div className="flex">
					<div className="flex-shrink-0">
						<InformationCircleIcon
							className="h-5 w-5 text-purple-700"
							aria-hidden="true"
						/>
					</div>
					<div className="ml-3 flex-1 md:flex md:justify-between">
						<p className="text-sm text-purple-700">
							Alternatively, you can search for{' '}
							<b className="font-medium">Appmaker - Woocommerce plugin</b> from
							your WordPress plugin directory.
						</p>
						<p className="mt-3 text-sm md:mt-0 md:ml-6">
							<a
								href={installURL}
								target="_blank"
								rel="noreferrer"
								className="whitespace-nowrap font-medium text-purple-700 hover:text-purple-600"
							>
								Take me<span aria-hidden="true">&rarr;</span>
							</a>
						</p>
					</div>
				</div>
			</div> */}
		</div>
	);
};
export default PluginInstallInstructions;
