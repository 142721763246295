import axios from 'axios';

const getMetaField = ({ version, url, token, type, handle, ids } = {}) => {
	let query = `
        fragment MetafieldData on Metafield {
            type
            __typename
            namespace
            key
            reference {
            ...MetaobjectFields
            }
            references(first: 1) {
            nodes {
                __typename
                ...MetaobjectFields
            }
            }
        }
        
        fragment MetaobjectFields on Metaobject {
            fields {
            type
            key
            reference {
                __typename
            }
            references(first: 1) {
                nodes {
                __typename
                }
            }
            }
        }
    `;
	switch (type) {
		case 'collection': {
			query += `
            query GenerateQueryHelper($handle: String, $ids: [HasMetafieldsIdentifier!]!) {
                collection(handle: $handle) {
                metafields(identifiers: $ids) {
                    ...MetafieldData
                }
                }
            }
            `;
			break;
		}
		case 'product': {
			query += `
            query GenerateQueryHelper($handle: String, $ids: [HasMetafieldsIdentifier!]!) {
                product(handle: $handle) {
                metafields(identifiers: $ids) {
                    ...MetafieldData
                }
                }
            }
            `;
			break;
		}
		default: {
			return false;
		}
	}
	const endPoint = `${url}/api/${version}/graphql.json`;
	return axios.post(
		endPoint,
		{
			query,
			variables: {
				handle,
				ids,
			},
		},
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-shopify-storefront-access-token': token,
			},
		}
	);
};

export { getMetaField };
