import React from 'react';

const Icon =  () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.7">
			<path d="M23.2482 4.25407L18.1842 7.18307L13.1182 4.25407C13.2219 4.07415 13.3699 3.92372 13.5482 3.81707L17.5682 1.49607C17.756 1.39013 17.968 1.33447 18.1837 1.33447C18.3993 1.33447 18.6113 1.39013 18.7992 1.49607L22.8192 3.81707C22.9978 3.92359 23.1461 4.07402 23.2502 4.25407H23.2482Z" fill="#1A1A1A" />
			<path d="M18.1841 7.18415V13.0312C17.9901 13.0309 17.7987 12.9868 17.6241 12.9022L13.5681 10.5572C13.3812 10.4491 13.2259 10.2939 13.1178 10.107C13.0097 9.92007 12.9526 9.70806 12.9521 9.49215V4.87615C12.9531 4.65799 13.0102 4.44375 13.1181 4.25415L18.1841 7.18415Z" fill="#323236" />
			<path d="M23.4161 4.87615V9.49215C23.4157 9.70806 23.3585 9.92007 23.2504 10.107C23.1423 10.2939 22.987 10.4491 22.8001 10.5572L18.7251 12.9082C18.5555 12.9881 18.3706 13.0301 18.1831 13.0312V7.18415L23.2491 4.25415C23.3574 4.44366 23.4149 4.6579 23.4161 4.87615V4.87615Z" fill="black" />
		</g>
		<path d="M16.5359 9.35327L8.93594 13.7533L1.33594 9.35327C1.49213 9.083 1.71485 8.85718 1.98294 8.69727L8.00894 5.21427C8.29093 5.05524 8.60919 4.97168 8.93294 4.97168C9.25668 4.97168 9.57495 5.05524 9.85694 5.21427L15.8889 8.69727C16.157 8.85718 16.3797 9.083 16.5359 9.35327V9.35327Z" fill="#1A1A1A" />
		<path d="M8.93308 13.7513V22.5283C8.64169 22.5279 8.35417 22.4616 8.09208 22.3343L2.00408 18.8133C1.72341 18.6509 1.49033 18.4176 1.32817 18.1368C1.16601 17.856 1.08045 17.5375 1.08008 17.2133V10.2863C1.08134 9.95902 1.16711 9.63764 1.32908 9.35327L8.93308 13.7513Z" fill="#323236" />
		<path d="M16.7861 10.2863V17.2133C16.7857 17.5376 16.7002 17.8561 16.538 18.1369C16.3759 18.4177 16.1428 18.6509 15.8621 18.8133L9.74611 22.3433C9.49178 22.4634 9.21433 22.5265 8.93311 22.5283V13.7513L16.5331 9.35132C16.6968 9.63589 16.784 9.95803 16.7861 10.2863V10.2863Z" fill="black" />
	</svg>

);

export default Icon;