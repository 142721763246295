import appmaker from '@appmaker/core/index';
import Home from './routes/HomeWrapper';
import { BeakerIcon } from '@heroicons/react/solid';

const plugin = {
	id: '@appmaker/app-builds',
	name: 'App Builds',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/app-builds',
			component: Home,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu(
			{
				name: 'App Builds',
				routeName: '/app-builds',
				icon: BeakerIcon,
			},
			8
		);
	},
};

export default plugin;
