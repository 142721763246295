import ColorPicker from '../components/ColorPicker';
export const ColorPickerField = ({
	properties = {},
	defaultValue,
	onChange,
}) => {
	const { title, label, singleItem } = properties;

	return (
		<ColorPicker
			attributes={{
				label: title || label,
				defaultColor: defaultValue || properties.default,
				singleItem: singleItem,
				onChangeComplete: (color) => {
					onChange(color.hex);
				},
			}}
		/>
	);
};
const ColorsGroup = (props) => {
	const { onChange, properties, store } = props;
	const fieldKeys = Object.keys(properties.properties);
	const fields = properties.properties;
	const onChangeLocal = (value, internal, field) => {
		onChange(value, internal, field);
	};
	return (
		<div className="mt-4 p-3 bg-blue-50 rounded-lg">
			<div className="flex gap-2">
				{fieldKeys.map((field) => {
					return (
						<ColorPicker
							key={field}
							attributes={{
								label: fields[field]?.title,
								singleItem: true,
								defaultColor: store.formData[field] || fields[field]?.default,
								onChangeComplete: (color) =>
									onChangeLocal(color.hex, null, field),
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};
export default ColorsGroup;
