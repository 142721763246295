import create from 'zustand';
import createContext from 'zustand/context';
import produce from 'immer';
import { devtools } from 'zustand/middleware';
// import { useContext,createContext as createContextReact } from "react";

export const {
	Provider: MediaProvider,
	useStore: useMediaStore,
	useStoreApi: useMediaStoreApi,
} = createContext();

export const createStore = () =>
	create(
		devtools((set) => ({
			modalVisible: false,
			selectedFile: {},
			searchText: null,
			toggleModal: () =>
				set(produce((state) => ({ modalVisible: !state?.modalVisible }))),
			setModalVisible: (value) =>
				set(
					produce((state) => ({
						modalVisible: value,
						searchText: value === false ? null : state?.searchText,
					}))
				),
			setSelectedFile: (value) =>
				set(produce((state) => ({ selectedFile: value }))),
			setSearchText: (searchText) => set(produce((state) => ({ searchText }))),
		}))
	);

// const MediaContext = createContextReact()

// export const useMediaStore = (selector) => useStore(useContext(MediaContext), selector)
