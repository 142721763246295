import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useState } from 'react';

const FormBlockCard = ({
	attributes = {},
	className,
	children,
	name,
	formData,
	setFormData,
	value,
	setValue,
	internalValue,
	setInternalValue,
}) => {
	const { title, optionComponent, errorMessage } = attributes;
	const [hasOptions, setHasOptions] = useState(false);
	return (
		<div className="relative mb-6 w-full">
			<div
				className={clsx(
					'flex flex-col bg-white px-4 py-5 rounded-lg shadow-sm relative z-20',
					className
				)}
			>
				{title ? <h3 className="text-md font-medium mb-2">{title}</h3> : null}
				<div className="-mb-4">{children}</div>

			</div>
			{optionComponent ? (
				<Transition
					show={hasOptions}
					enter="transition ease-out duration-150"
					enterFrom="opacity-0 transform -translate-y-4"
					enterTo="opacity-100 transform translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 transform translate-y-0"
					leaveTo="opacity-0 transform -translate-y-4"
				>
					<div
						className={clsx(
							'bg-blue-100 p-4 rounded-b-lg border pt-8 relative -top-4 z-10',
							errorMessage ? 'border-red-600' : 'border-gray-200'
						)}
					>
						{optionComponent}
					</div>
					{errorMessage ? (
						<span className="text-sm text-red-500 relative -top-4">
							{errorMessage}
						</span>
					) : null}
				</Transition>
			) : null}
		</div>
	);
};

export default FormBlockCard;
