import IosHome from './IosHome';
import { useProject } from '@appmaker/core/hooks';
import { getProjectData } from '@appmaker/core/store/project';
import { useEffect } from 'react';
import { CONSTANT, setFormData } from '../../store';
import { useState } from 'react';
import Loading from '@appmaker/components/Loading';
import IOS from './components';
import { useQueryParam, BooleanParam } from 'use-query-params';
const Home = () => {
	const project = useProject();
	const { projectId } = project;
	const { projectData, loading } = getProjectData({ projectId });
	// const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);
	const [alreadyHaveAccount, setAlreadyHaveAccount] = useQueryParam(
		'alreadyHaveAccount',
		BooleanParam
	);
	useEffect(() => {
		if (Object.keys(projectData).length > 0) {
			const { apps = [] } = projectData;
			const iosAppData = apps.find(
				(app) => app.platformId == CONSTANT.PLATFORM_IOS
			);
			const androidAppData = apps.find(
				(app) => app.platformId == CONSTANT.PLATFORM_IOS
			);
			setFormData({
				androidAppData,
				iosAppData,
			});
		}
	}, [projectData]);
	useEffect(() => {
		if (!alreadyHaveAccount) {
			setAlreadyHaveAccount(false);
		} else {
			setAlreadyHaveAccount(true);
		}
	}, []);
	if (loading) {
		return (
			<div>
				<Loading />
			</div>
		);
	}
	return (
		<>
			{!alreadyHaveAccount && (
				<IosHome
					alreadyHaveAccount={() => {
						setAlreadyHaveAccount(true);
					}}
				/>
			)}
			{alreadyHaveAccount && <IOS />}
		</>
	);
};

export default Home;
