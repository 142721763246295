import Loader from '@appmaker/components/Loading';
import { Tab } from '@headlessui/react';
import { NewspaperIcon, UsersIcon, CloudIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Suspense, lazy } from 'react';
const ProjectDetails = lazy(() => import('../components/ProjectDetails'));
const Permissions = lazy(() => import('../components/Permissions'));
const Cache = lazy(() => import('../components/Cache'));

const navigation = [
	{ name: 'Project details', icon: NewspaperIcon },
	{ name: 'Permissions', icon: UsersIcon },
	{ name: 'Cache', icon: CloudIcon },
];

export default function Home() {
	return (
		<Tab.Group as="div" className="grid grid-cols-12 divide-x divide-gray-100">
			<nav
				className="col-span-2 flex flex-1 flex-col p-4 h-screen overflow-y-auto"
				aria-label="Sidebar"
			>
				<Tab.List as="ul" className="-mx-2 space-y-1">
					{navigation.map((item) => (
						<Tab as="li" key={item.name}>
							{({ selected }) => (
								<button
									className={clsx(
										selected
											? 'bg-gray-100 text-gray-800'
											: 'text-gray-700 hover:text-gray-600 hover:bg-gray-50',
										'group w-full flex items-center justify-between gap-x-2 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
									)}
								>
									<span className="line-clamp-1">{item.name}</span>
									<item.icon
										className={clsx(
											selected
												? 'text-gray-400'
												: 'text-gray-300 group-hover:text-gray-500',
											'flex-shrink-0 -ml-1 h-5 w-5'
										)}
										aria-hidden="true"
									/>
								</button>
							)}
						</Tab>
					))}
				</Tab.List>
			</nav>

			<Tab.Panels
				as="div"
				className="col-span-10 h-screen overflow-y-auto bg-gray-50"
			>
				<Tab.Panel key={'Project details'}>
					<Suspense fallback={<Loader single />}>
						<ProjectDetails />
					</Suspense>
				</Tab.Panel>
				<Tab.Panel key={'Permissions'}>
					<Suspense fallback={<Loader single />}>
						<Permissions />
					</Suspense>
				</Tab.Panel>
				<Tab.Panel key={'Cache'}>
					<Suspense fallback={<Loader single />}>
						<Cache />
					</Suspense>
				</Tab.Panel>
			</Tab.Panels>
		</Tab.Group>
	);
}
