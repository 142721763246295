import React from 'react';
import {
	// MediaPlaceholder,
	RichText,
	InspectorControls,
} from '@wordpress/block-editor';
import { ToggleControl, PanelBody, TextControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import DeleteActionButton from '../../components/Toolbar';

import BannerImage from '../../components/BannerImage';
import { extractTextFromHTML } from '../../helper';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import { PhotographIcon } from '@heroicons/react/outline';
const Edit = (props) => {
	const { attributes, setAttributes, className, metaData ={ } } = props;
	const { image, showText, __display = true } = attributes;
	const maxHeight = metaData?.image?.maxHeight
	const maxWidth = metaData?.image?.maxWidth
	return (
		<div className='pb-2'>
			<DeleteActionButton
				action={() => {
					setAttributes({ image: undefined });
				}}
			/>
			<InspectorControls>
				<PanelBody title="Banner settings" initialOpen>
					{/* <ToggleControl
						label={__('Show image title', 'newspack-blocks')}
						checked={showText}
						onChange={() => setAttributes({ showText: !showText })}
					/> */}
					{image && (
						<>
							<p className="mb-1 text-gray-500 font-semibold text-sm">
								Banner Image
							</p>
							<div className="flex items-center gap-3">
								<img
									src={image.url}
									alt="thumbnail"
									className="h-16 w-16 border border-gray-200 object-contain p-0.5"
								/>
								<button
									onClick={() => setAttributes({
										image: undefined
									})}
									type="button"
									className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
								>
									Replace image
								</button>
							</div>
						</>
					)}
				</PanelBody>
				<InspectorControls>
					<PanelBody title="Advanced" initialOpen={false}>
						<TextControl
							label={'Display'}
							value={__display}
							onChange={(val) => {
								setAttributes({ __display: val });
							}}
						/>

					</PanelBody>
				</InspectorControls>
			</InspectorControls>
			<div className={className}>
				{image === undefined && (
					<MediaPlaceholder
						onSelectURL={(url) => {
							setAttributes({
								image: {
									url,
								},
							});
						}}
						onSelect={(selectedData) => {
							let data = Array.isArray(selectedData) ? selectedData[0] : selectedData
							// condition in meta because each api having different response of the meta
							setAttributes({
								image: {
									url: data.url,
									fileName: data.filename,
									id: data.id,
									title: data.title,
								},
								imageContainerStyle: {
									height: data.meta !== undefined ? data.meta.height : '100',
									width: data.meta !== undefined ? data.meta.width : '100',
								},
								thumbnail_meta: {
									height:
										data.meta !== undefined ? data.meta.height : undefined,
									width: data.meta !== undefined ? data.meta.width : undefined,
								},
							});
						}}
						allowedTypes={['image']}
						multiple={false}
						attributes={{
							icon: PhotographIcon,
							title: 'Banner',
							description: 'Showcase a product that’s on sale or highlight your best offer by adding an image as a banner. ',
							maxHeight,
							maxWidth,
							disableImageResize: attributes?.disableImageResize
						}}
						value={{ id: image && image.id, src: image && image.url }}

					/>
				)}
				{image && (
					<BannerImage imageSrc={image.url}>
						{showText && (
							<RichText
								allowedFormats={[]}
								value={attributes.text}
								withoutInteractiveFormatting
								onChange={(text) => {
									setAttributes({ text: extractTextFromHTML(text) });
								}}
								placeholder="Image title"
							/>
						)}
					</BannerImage>
				)}
			</div>
		</div>
	);
};

export default Edit;
