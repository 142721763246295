import { Suspense, useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import PushPreview from './Preview';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { useStoreApi } from '@appmaker/core/components/AppmakerForm/store';
import ActionSelect from './ActionSelect';
import { sendNotification } from '../../store';
import api from '../../api';
import { useProject } from '@appmaker/core/hooks';
import PushImage from './PushImage';
// import useProjectData from "@appmaker/core/hooks/useProjectData";
import { getProjectData } from '@appmaker/core/store/project';
import Loader from '@appmaker/components/Loading';
import { useEffect, lazy } from 'react';
import Schedule from './Schedule';
import LogTab from './LogTab';
import moment from 'moment';
import AdminTestPush from './AdminTest';
import appmaker from '@appmaker/core/index';
import { isAdmin } from '@appmaker/core/store/user';
import AppmakerSwitch from 'packages/appmaker-core/components/AppmakerForm/fields/AppmakerSwitch';

const appmakerSchema = {
	properties: {
		title: {
			type: 'string',
			inputType: 'text',
			label: 'Notification title',
			name: 'title',
			placeholder: 'Awesome deal is here!!',
			required: true,
			uiType: 'Input',
		},
		message: {
			type: 'string',
			inputType: 'textArea',
			label: 'Message',
			name: 'message',
			placeholder: 'Notification message here',
			required: true,
			uiType: 'Input',
		},
	},
};

const SendPushApp = (props) => {
	const [action, setAction] = useState({});
	const [image, setImage] = useState(undefined);
	const [scheduleTime, setScheduleTime] = useState(undefined);
	const [onEnableSchedule, setEnableSchedule] = useState(false);
	const [productionSend, setProductionSend] = useState(false);
	const storeApi = useStoreApi();
	const { projectId } = useProject();
	const onFileUpload = () => {};
	const [sending, setSending] = useState(false);
	const [sendingTest, setSendingTest] = useState(false);
	const [feature, setFeature] = useState({});
	const { loading, projectData } = getProjectData();
	/**
	 * To set feature
	 */
	useEffect(() => {
		if (Object.keys(projectData).length > 0) {
			const { feature: projectFeature } = projectData;
			// enablePushWithImageNotification: true
			setFeature({ ...projectFeature });
		}
	}, [projectData]);

	const sendPushNotification = async ({ topic }) => {
		let confirmMessage = `This action will send push notification to ${
			topic === 'appmaker-test' ? 'appmaker dev' : 'all installed mobile apps'
		}. Are you sure ?`;
		const isTest = topic === 'appmaker-test';
		const confirm = window.confirm(confirmMessage);
		if (!confirm) {
			return false;
		}
		const { formData = {} } = storeApi.getState();
		const { title, message } = formData;
		const convertedData = {
			title,
			message,
			action,
			image,
			scheduleTime:
				scheduleTime && onEnableSchedule
					? moment(scheduleTime).format()
					: undefined,
		};
		if (!title || !message || !action) {
			// show error message
			return sendNotification('Please fill the form', { appearance: 'error' });
		}
		if (isTest) {
			setSendingTest(true);
		} else {
			setSending(true);
		}

		try {
			const response = await api.sendPushNotification({
				projectId,
				data: convertedData,
				topic,
			});
			appmaker.doAction('appmaker-analytics-event', {
				event: 'push_notification_send',
				params: {
					project_id: projectId,
					is_scheduled: scheduleTime && onEnableSchedule,
				},
			});
			return sendNotification(
				onEnableSchedule
					? 'Push notification scheduled'
					: 'Push notification send',
				{
					appearance: 'success',
				}
			);
		} catch (error) {
			appmaker.doAction('appmaker-analytics-event', {
				event: 'push_notification_send_error',
				params: {
					project_id: projectId,
					is_scheduled: scheduleTime && onEnableSchedule ? true : false,
				},
			});
			return sendNotification(error.message || 'Something went wrong', {
				appearance: 'error',
			});
		} finally {
			if (isTest) {
				setSendingTest(false);
			} else {
				setSending(false);
			}
		}
	};
	if (loading) {
		return <Loader />;
	}
	const notificationMessage = onEnableSchedule
		? 'Schedule Notification'
		: 'Send Notification';
	return (
		<div className="bg-gray-50 h-full py-3">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="p-6 shadow-lg rounded-lg bg-white flex gap-4">
					<div className="flex-1">
						<h2 className="text-xl font-semibold text-gray-900">
							Send push notification
						</h2>
						<p className="text-gray-500 text-sm font-normal mt-1">
							Send your customized message with action to all your app users.
						</p>
						<AppmakerForm
							formVersion={2}
							// value={{ formData: flatten(formData) }}
							schema={appmakerSchema}
							onFileUpload={onFileUpload}
						/>
						<span className="block text-sm font-medium text-gray-700 mt-4">
							Choose Action
						</span>
						<ActionSelect action={{}} setAction={setAction} />
						<PushImage
							src={image}
							enablePushImage={feature.enablePushWithImageNotification}
							setImage={setImage}
						/>
						<Schedule
							setEnableSchedule={setEnableSchedule}
							setScheduleTime={setScheduleTime}
							enablePushSchedule={feature.enablePushSchedule}
							onEnableSchedule={onEnableSchedule}
						/>
						{isAdmin() && (
							<div>
								<AppmakerSwitch
									onChange={(data) => {
										setProductionSend(data === 1 || data === '1');
									}}
									defaultValue={false}
									properties={{
										title: 'Toggle Production Mode',
									}}
								/>
							</div>
						)}
						<div className="flex items-center">
							{(productionSend || !isAdmin()) && (
								<button
									type="button"
									disabled={sending || sendingTest}
									className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									onClick={sendPushNotification}
								>
									{sending ? (
										<LoadingIcon className="animate-spin -ml-1 mr-3 h-5 w-5" />
									) : (
										<PaperAirplaneIcon
											className="-ml-1 mr-3 h-5 w-5 transform rotate-45 -translate-y-0.5"
											aria-hidden="true"
										/>
									)}
									{sending ? 'Sending...' : notificationMessage}
								</button>
							)}
							<div className="ml-5">
								<AdminTestPush
									disabled={sending || sendingTest}
									sending={sendingTest}
									onClick={() => {
										sendPushNotification({ topic: 'appmaker-test' });
									}}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-1">
						{/* <PushPreview image={image} /> */}
						<Suspense fallback={Loader}>
							<LogTab image={image} />
						</Suspense>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SendPushApp;
