import React, { useState, useEffect } from 'react';
import FileUpload from '@appmaker/components/Form/fields/FileUpload';
import Gallery from 'packages/MediaLibrary/components/MediaUpload/Gallery';
import { useMediaStore } from 'packages/MediaLibrary/store/media';
import isEmpty from 'lodash/isEmpty';
import { listMedia, generateStagedUpload, uploadFileToS3, createFiles } from '../../api/media';
import { uploadToServer } from '../../helper/upload';
// const { isSelected, setSelected, deSelectAll, isMultiSelectActive } = useMultiSelectWithProvider();
import { useMultiSelectWithProvider } from "use-multiselect";

export default function ShopifyMediaLibrary() {
	const searchText = useMediaStore(state => state.searchText)
	const onSelect = useMediaStore(state => state.onSelect)
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);
	const loadImages = async ({ updateImages = true, doSearch = true }) => {
		try {
			if (updateImages) {
				setLoading(true);
			}
			const search = !doSearch || isEmpty(searchText) ? undefined : searchText;
			const resp = await listMedia({ search });

			const newImages = resp.map((file) => ({
				url: file.url,
				id: file.id,
				imageUrl: file.url,
				title: file.alt,
				fileName: file.alt,
				meta: {
					height: file.height,
					width: file.width,
				}
			}));
			console.log(newImages)
			if (updateImages) {
				setImages(newImages);
				setLoading(false);
			}
			return newImages;
		} catch (error) { }
	};
	const { isMultiSelectActive } = useMultiSelectWithProvider();
	useEffect(() => {
		loadImages({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);
	// console.log("isMultiSelectActive", isMultiSelectActive)
	return (
		<>
			<FileUpload
				name={'icon'}
				onChange={console.log}
				formContext={{
					onFileUpload: async (item, file) => {
						await uploadToServer({
							filesList: file, onFileChange: (filesUplaoded) => {
								if (filesUplaoded.length > 0) {
									loadImages({});
									onSelect(filesUplaoded[0]);
									// onSelect(isMultiSelectActive ? filesUplaoded : filesUplaoded[0]);
								} else {
									loadImages({});
								}
							}
						})
					}
				}}
				schema={{ helperText: 'PNG, JPG, GIF up to 2MB', inner: true }}
			/>
			{loading ? (
				<GalleryLoading n={10} />
			) : (
				<Gallery schema={{ files: images }} />
			)}
		</>
	);
}
ShopifyMediaLibrary.media = {
	title: 'Media Library',
};

const GalleryLoading = ({ n = 8 }) => {
	return (
		<ul className="grid grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 animate-pulse my-4">
			{[...Array(n)].map((e, i) => (
				<li key={i} className="bg-gray-200 w-full h-28 rounded-md" />
			))}
		</ul>
	);
};
