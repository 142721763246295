import { useState, useEffect } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Progress } from './Progress';
import { CompleteStatus } from './CompleteStatus';
import { StepHeader } from './StepHeader';
import { NextStep } from './NextStep';
import { useStore } from '../../store';
import shallow from 'zustand/shallow'

function CloseButton(props) {
	return (
		<>
			<button
				type="button"
				className="p-2 absolute top-0 right-0 sm:relative"
				onClick={() => props.setShow(!props.show)}
			>
				<XCircleIcon className="h-6 w-6 text-gray-400" />
			</button>
			<button
				type="button"
				className="p-2 hidden"
				onClick={() => props.setShow(!props.show)}
			>
				<XCircleIcon className="h-6 w-6 text-gray-400" />
			</button>
		</>
	);
}
function Container({ children, show }) {
	return (
		<div
			aria-label="Progress"
			className={clsx('fixed transition-all left-0 w-full bg-gray-800 z-50', {
				'-bottom-96': !show,
				'bottom-0': show,
			})}
		>
			<div className="space-y-4 md:flex md:space-y-0 md:space-x-8">
				<div className="md:flex-1 text-white">{children}</div>
			</div>
			{/* <div className={clsx("m-2 p-2 text-lg transition-all", "h-auto")}>
            <img src="https://picsum.photos/400/200" alt="" />
            <h5 className="text-lg text-white">Hello there</h5>
        </div> */}
		</div>
	);
}

const OnBoardingProgress = () => {
	const [show, setShow] = useState(true);
	const loadStore = useStore(state => state.loadStore);
	const saveStore = useStore(state => state.saveStore);
	const loadedFromServer = useStore(state => state.loadedFromServer);
	const serverConfig = useStore(state => ({
		currentStep: state.currentStep,
		stepStatus: state.stepStatus,
		completedStepsCount: state.completedStepsCount
	}), shallow);
	useEffect(() => {
		loadStore()
	}, [])
	useEffect(() => {
		// loadStore()
		saveStore(serverConfig)
	}, [serverConfig])
	return !loadedFromServer ? null : (
		<Container show={show}>
			<Progress />
			<div className="px-4 py-2 flex flex-col gap-4 items-start relative sm:flex-row">
				<CloseButton show={show} setShow={setShow} />
				<CompleteStatus />
				<StepHeader />
				<NextStep />
			</div>
		</Container>

	)
}

export default OnBoardingProgress;
