import axios from 'axios';
import { ROOT_URL, V3_BASE_URL } from '@appmaker/core/api/core';

const BASE_URL = V3_BASE_URL + '/projects';

const api = {
	createNewExtension: async ({ projectId, data }) => {
		const url = `${BASE_URL}/${projectId}/extension/create`
		// const url = 'https://9c0caa63c9.to.intercept.rest';
		const response = await axios.post(
			url,
			data,
			{
				credentials: 'include',
				withCredentials: true,
			});
		return response.data;
	},
	getExtensionList: async ({ projectId }) => {
		const response = await axios.get(`${BASE_URL}/${projectId}/extension`, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	purgeCache: async ({ projectId }) => {
		const response = await axios.get(`${BASE_URL}/${projectId}/purge-everything`, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	getExtensionData: async ({ projectId, extensionId }) => {
		const response = await axios.get(`${BASE_URL}/${projectId}/extension/admin/${extensionId}`, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	updateExtension: async ({ projectId, data, extensionId }) => {
		const url = `${BASE_URL}/${projectId}/extension/update/${extensionId}`
		// const url = 'https://9c0caa63c9.to.intercept.rest';
		const response = await axios.post(
			url,
			data,
			{
				credentials: 'include',
				withCredentials: true,
			});
		return response.data;
	},
	createStaging: async ({ projectId, data }) => {
		const url = `${BASE_URL}/${projectId}/staging`;
		const response = await axios.post(
			url,
			data,
			{
				credentials: 'include',
				withCredentials: true,
			}
		)
		return response.data;
	},
	regenerateIosCert: async ({ projectId, appId }) => {
		const url = `${ROOT_URL}/v2/projects/${projectId}/regenerate-ios-cert?appId=${appId}`;
		const response = await axios.get(
			url,
			{
				credentials: 'include',
				withCredentials: true,
			}
		)
		return response.data
	}
}

export default api