import { addFilter, applyFilters, addAction, doAction } from '@wordpress/hooks';
// import { registerBlockType } from '@wordpress/blocks';
import { NotificationManager } from 'components/Notification';
import { emitEvent, onEvent } from './event';
import * as Router from 'react-router-dom';
import extensions from './base/extensions';
import { registerAppmakerBlockType, registerAppmakerFrontEndBlock } from './blockType';
import {
	createPageStateStore, 
	PageStateProvider,
	usePageState,
	usePageStateApi
} from './components/BlocksView/state/PageState';
import { useFormData, useFormDataValue } from './components/BlocksView/hooks/useBlockItem';
import { useBlockParentAttributes } from './blockType/hooks/useParentAttribures';
import useRouterPath from './hooks/useRouterPath';
const KEYS = {
	ACTION: 'appmaker-actions',
	APP_PREVIEW: 'appmaker-manage-preview',
};

const registerPlugin = (plugin) => {
	addFilter('appmaker-plugins', 'plugins-add', (plugins) => {
		plugins[plugin.id] = plugin;
		return plugins;
	});
};

const listAllPlugins = () => applyFilters('appmaker-plugins', {});
const activateAllPlugins = () => {
	const allPlugins = listAllPlugins();
	const pluginsArray = Object.values(allPlugins);
	console.log(allPlugins, pluginsArray);
	pluginsArray.map((plugin) => {
		return plugin.activate();
	});
};

const activatePlugins = (pluginList) => {
	const allPlugins = listAllPlugins();
	pluginList.map((plugin) => {
		try {
			allPlugins[plugin.id].activate();
			console.log(`${plugin.id}=>Active`);
		} catch (error) {
			console.log(error);
		}
	});
};

const registerSideBarMenu = (menuItem, priority) => {
	addFilter(
		'appmaker-side-bar-menu',
		'side-bar-menu-add',
		(menuItems = []) => {
			menuItems.push(menuItem);
			return menuItems;
		},
		priority
	);
};
const allMenuItems = () => applyFilters('appmaker-side-bar-menu', []);

const registerRoute = (route) => {
	addFilter('appmaker-routes', 'route-add', (plugins) => {
		plugins[route.routeName] = route;
		return plugins;
	});
};

const registerRootRoute = (route) => {
	addFilter('appmaker-root-routes', 'route-add', (plugins) => {
		plugins[route.routeName] = route;
		return plugins;
	});
};

const allRoutes = () => applyFilters('appmaker-routes', {});
const allRoutesArray = () => Object.values(allRoutes());

const allRootRoutes = () => applyFilters('appmaker-root-routes', {});
const allRootRoutesArray = () => Object.values(allRootRoutes());

const registerDataSource = ({ _id, name, icon, factoryClass }) => {
	addFilter('appmaker-data-sources', 'data-source', (dataSources) => {
		dataSources[_id] = { _id, name, icon, factoryClass };
		return dataSources;
	});
};
const getAllDataSources = () => applyFilters('appmaker-data-sources', {});

const registerPreview = (itemName, params) => {
	addFilter(KEYS.APP_PREVIEW, 'preview', (items) => {
		items[itemName] = params;
		return items;
	});
};
const getAllPreview = () => applyFilters(KEYS.APP_PREVIEW, {});

// c
const preview = {
	registerPreview,
	getAllPreview,
};

const registerAction = (action, params) => {
	addFilter(KEYS.ACTION, 'actions', (actions) => {
		actions[action] = params;
		return actions;
	});
};
const getAllActions = () => applyFilters(KEYS.ACTION, {});
const track = (event, params) => {
	appmaker.doAction('ap_event_track', { event, params });
};
const onTrack = (cb) => {
	appmaker.addAction('ap_event_track', 'core/track', cb);
};
// c
const actions = {
	registerAction,
	getAllActions,
};
const appmaker = {
	track,
	onTrack,
	registerPlugin,
	listAllPlugins,
	registerRoute,
	registerRootRoute,
	allRoutes,
	allRoutesArray,
	allRootRoutesArray,
	activateAllPlugins,
	activatePlugins,
	registerSideBarMenu,
	allMenuItems,
	registerDataSource,
	getAllDataSources,
	actions,
	preview,
	emitEvent,
	onEvent,
	addFilter,
	applyFilters,
	addAction,
	doAction,
	extensions,
	registerAppmakerBlockType,
	registerAppmakerFrontEndBlock,
};

export default appmaker;
export { Router, NotificationManager as Notify, };
export {
	createPageStateStore,
	PageStateProvider,
	usePageState,
	usePageStateApi,
};
export {
	useFormDataValue,
	useFormData,
	useBlockParentAttributes,
	useRouterPath
}
