const status = {
    5: {
        key: 'PROJECT_PAYMENT_PAID',
        label: 'Paid'
    },
    9: {
        key: 'PROJECT_PAYMENT_TRIAL',
        label: 'Trial'
    },
    6:{
        key:'PROJECT_PAYMENT_EXPIRED',
        label:'Expired'
    },
    7:{
        key:'PROJECT_PAYMENT_EXPIRED_COMPLETE',
        label:'Expired'
    },
    1:{
        key:'PAYMENT_TYPE_FREE',
        label:'Free'
    },
    2:{
        key:'PAYMENT_TYPE_ONETIME',
        label:'Onetime'
    },
    3:{
        key:'PAYMENT_TYPE_SAAS',
        label:'SAAS'
    }
}

export default status;
