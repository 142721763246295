import { useEffect } from 'react';
import { prebuildProductScroller } from '../../api';
import { useAsyncFn } from 'react-use';
import BlockCard from 'packages/e-commerce-blocks/components/BlockCard';
import ProductsScrollerItem from 'packages/oldBlocks/new-blocks/components/ProductsScrollerItem';

export default function ProductListView({
	collectionId,
	layout,
	numberOfProducts = 10,
	backgroundColor = '#fff',
}) {
	const [{ loading, value: _products }, doFetch] = useAsyncFn(
		async (collectionId) => {
			try {
				const response = await prebuildProductScroller({
					search: collectionId,
				});
				return response;
			} catch (error) {
				console.log(error);
			}
		},
		[]
	);
	useEffect(() => {
		doFetch(collectionId);
	}, [collectionId]);

	return (
		<BlockCard attributes={{ backgroundColor: backgroundColor }}>
			{/* Product grid */}
			<div
				className={
					layout === 'grid'
						? 'grid grid-cols-2 gap-4 px-4 pb-4'
						: 'overflow-x-auto whitespace-nowrap flex gap-4 px-4 pb-4'
				}
			>
				{!loading &&
					_products &&
					_products.map((product) => (
						<ProductsScrollerItem
							key={product.id}
							attributes={{
								product: product,
								groceryMode: true,
								quickAdd: false,
								width: layout === 'grid' ? 'w-full' : 'w-32',
							}}
						/>
					))}
			</div>
		</BlockCard>
	);
}
