import { useState } from 'react';
import { getProductList } from '../../../api/products';
const GetProductList = () => {
    const [isLoading, setLoading] = useState(false);

    const fetchProducts = async (searchElement) => {
        try {
            const response = await getProductList({search:searchElement});
            // setProduct(response);
            return response;
        } catch (error) {
            return []
        }
    };
    // useEffect(() => {
    //     const loadData = async () => {
    //         setLoading(true);
    //         fetchProducts()
    //         setLoading(false);
    //     }
    //     loadData();
    // }, [])
    return {
        isLoading,
        fetchProducts: (searchElement)=> fetchProducts(searchElement)
    }
}

export default GetProductList