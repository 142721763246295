
import Select from 'react-select';

const MultiSelect = ({ options, value, onChange = () => { }, className, isMulti, otherProps = {} }) => {
    if (isMulti) {
        return (
            <Select
                defaultValue={value}
                isMulti
                options={options}
                className={className}
                classNamePrefix="select"
                onChange={value => {
                    onChange(value);
                }}
            />
        );
    }
    else {
        return <Select
            {...otherProps}
            options={options}
            className={className}
            onChange={(event) => { onChange(event) }}
        />
    }

};

export default MultiSelect;
