import appmaker from '@appmaker/core/index';
import {
	AdjustmentsIcon,
	CheckIcon,
	ChevronUpIcon,
	ClipboardListIcon,
	DatabaseIcon,
	EmojiHappyIcon,
	MinusIcon,
	SwitchVerticalIcon,
	TableIcon,
	ViewGridIcon,
	DocumentTextIcon
} from '@heroicons/react/solid';
import {
	ProductImage,
	ProductData,
	ProductVariation,
	ExpandableTextBlock,
	Buttons,
} from './uikit/components/product-detail';
import ButtonsTwo from './uikit/components/product-detail/ButtonsTwo';
import ShopifyProductList, {
	ProductListSlots,
} from './blocks/ShopifyProductList';
import TabBlock, { TabItem } from './blocks/TabBlock';
import {
	NewspaperIcon,
	PhotographIcon,
	ViewBoardsIcon,
} from '@heroicons/react/outline';
import BottomTabBlock, { BottomTabItem } from './blocks/BottomTabBlock';
// import ToolBarIcons, { ToolBarIcon } from './blocks/ToolBar/ToolBarIcons';
import ToolbarPage from './blocks/Toolbar/index';
import { ToolBarIcon } from './blocks/Toolbar/ToolBarIcons';
import MediaCard from './components/MediaCard';
import { AppmakerRowEdit, AppmakerRow } from './blocks/Row';
import LayoutIcon from './blocks/LayoutIcon';
import clsx from 'clsx';
import { isAdmin } from '@appmaker/core/store/user';
import { getProjectFeature } from '@appmaker/core/store/project';
import { InnerBlocks } from '@wordpress/block-editor';
import LayoutBlock from './blocks/LayoutBlock/index';
import RichText from './blocks/RichText';
// import CollectionImport from './blocks/CollectionImport';
function DummyBlock({ name, description }) {
	return (
		<div className="relative w-full flex flex-col py-3 bg-gray-100 justify-center items-center">
			<p className="text-sm font-medium text-gray-900">{name}</p>
			<small className="text-xs block">{description}</small>
		</div>
	);
}
DummyBlock.attributesSchema = {
	properties: {
		fixedFooter: {
			type: 'string',
			label: 'Fix when scrolling',
			default: '',
			uiType: 'Switch',
		},
		title: {
			type: 'string',
			label: 'Title',
		},
	},
};
function FeatureCard({ attributes }) {
	return (
		<div className="relative border border-gray-50 bg-white px-6 py-5 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				<p className="text-sm font-medium text-gray-900">{attributes.title}</p>
				<p className="text-xs font-medium text-gray-900">
					{attributes.subTitle}
				</p>
			</div>
		</div>
	);
}
FeatureCard.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
		subTitle: {
			type: 'string',
			label: 'Sub title',
		},
		iconName: {
			type: 'string',
			label: 'Icon Name',
		},
		iconSize: {
			type: 'string',
			label: 'Icon Size',
		},
		__display: {
			type: 'string',
			label: 'Display',
		},
	},
};
function CustomiseShirtWidget({ attributes }) {
	return (
		<div className="flex items-center justify-between py-2 px-4 bg-gray-100 rounded-md m-2">
			<div className="flex-1">Your size is saved with us</div>
			<div className="border border-gray-900 px-4 py-2 text-sm text-gray-900 rounded-full">
				View/Edit
			</div>
		</div>
	);
}

function ShopifyRelatedProducts({ attributes }) {
	return <DummyBlock name={'ShopifyRelatedProducts'} />;
}
function NostoProductRecommendations({ attributes }) {
	return <DummyBlock name={'Nosto '} />;
}
function CustomizeOrder({ attributes }) {
	return (
		<div className="flex items-center justify-between py-2 px-4 bg-gray-100 rounded-md m-2">
			<div className="flex-1">Customise your order</div>
			<div className="border border-gray-900 px-4 py-2 text-sm text-gray-900 rounded-full">
				Customise
			</div>
		</div>
	);
}

function Divider({ attributes }) {
	return (
		<div style={{ padding: parseInt(attributes?.gap) }}>
			<div
				style={{
					height: parseInt(attributes?.borderWidth) || 1,
					backgroundColor: attributes?.borderColor || '#f5f5f5',
				}}
			/>
		</div>
	);
}
Divider.attributesSchema = {
	properties: {
		borderWidth: {
			type: 'string',
			label: 'Border Width',
		},
		borderColor: {
			type: 'string',
			label: 'Border Color',
		},
		gap: {
			type: 'string',
			label: 'Gap',
		},
	},
};
function TextList({ attributes }) {
	return (
		<p className="mb-2 px-4 pb-2 pt-1 text-sm flex items-center space-x-2">
			<CheckIcon className="text-gray-800 h-4 w-4" />
			<span>{attributes.text}</span>
		</p>
	);
}
TextList.attributesSchema = {
	properties: {
		text: {
			type: 'string',
			label: 'Content',
		},
		iconName: {
			type: 'string',
			label: 'Icon Name',
			default: 'check',
		},
		iconColor: {
			type: 'string',
			label: 'Icon Color',
		},
	},
};

const Badge = ({ attributes }) => {
	const backgroundColor = attributes.backgroundColor || 'blue';
	return (
		<div
			className={`flex m-4 items-center justify-center px-2 py-1 text-sm text-white bg-${backgroundColor}-500 rounded-full`}
		>
			{attributes.text}
		</div>
	);
};
Badge.attributesSchema = {
	properties: {
		text: {
			type: 'string',
			label: 'Content',
			default: '',
		},
		iconName: {
			type: 'string',
			label: 'Icon Name',
			default: '',
		},
		status: {
			type: 'string',
			label: 'Status',
			default: '',
		},
		__display: {
			type: 'string',
			label: '__display',
		},
	},
};

const ReusableBlock = ({ attributes }) => {
	const backgroundColor = attributes.backgroundColor || 'blue';
	return (
		<div
			className={`flex m-4 items-center justify-center px-2 py-1 text-sm text-white bg-${backgroundColor}-500 rounded-full`}
		>
			ReusableBlock
		</div>
	);
};
ReusableBlock.attributesSchema = {
	properties: {
		blockId: {
			type: 'string',
			label: 'blockId',
		},
	},
};
const RecentlyViewed = ({ attributes }) => (
	<DummyBlock name={'RecentlyViewed'} />
);
RecentlyViewed.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
	},
};
function Accordion({ attributes, ...props }) {
	return (
		<div
			style={attributes?.containerStyle}
			className="p-2 border border-gray-100"
		>
			<div
				className="flex items-center justify-between"
				style={attributes?.titleContainerStyle}
			>
				<p style={attributes?.titleTextStyle}>{attributes.title}</p>
				<ChevronUpIcon
					className="h-4 w-4 flex-shrink-0"
					style={attributes?.iconStyle}
				/>
			</div>
			<div style={attributes?.dataContainerStyle}>
				<p className="text-sm text-left" style={attributes?.dataTextStyle}>
					{attributes.data}
				</p>
				<InnerBlocks />
			</div>
		</div>
	);
}
Accordion.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
		data: {
			type: 'string',
			label: 'Data',
		},
		containerStyle: {
			type: 'string',
			label: 'Container Style',
			uiType: 'layout-styles',
		},
		titleContainerStyle: {
			type: 'string',
			label: 'Title Container Style',
			uiType: 'layout-styles',
		},
		titleTextStyle: {
			type: 'string',
			label: 'Title Text Style',
			uiType: 'text-styles',
		},
		iconStyle: {
			type: 'string',
			label: 'Icon Style',
			uiType: 'layout-styles',
		},
		dataContainerStyle: {
			type: 'string',
			label: 'Data Container Style',
			uiType: 'layout-styles',
		},
		dataTextStyle: {
			type: 'string',
			label: 'Data Text Style',
			uiType: 'text-styles',
		},
		icons: {
			type: 'json',
			label: 'Icons',
			description: (
				<>
					Use{' '}
					<a
						href="https://feathericons.com/"
						target="_blank"
						rel="noreferrer"
						className="text-blue-400 underline"
					>
						FeatherIcon
					</a>{' '}
					Names, Eg:["plus", "minus"]
				</>
			),
		},
		type: {
			type: 'string',
			label: 'Type',
			description: 'eg: "html"',
		},
		obeyShowOnlyIf: {
			type: 'string',
			label: 'Apply display condition',
			uiType: 'Switch',
		},
		showOnlyIf: {
			type: 'string',
			label: 'Display condition',
			description: 'eg: "{{blockItem.item}}"',
		},
	},
};

const CollapsibleMenu = ({ attributes }) => (
	<DummyBlock name={'CollapsibleMenu'} />
);

CollapsibleMenu.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
	},
};
const BreadCrumbs = ({ attributes }) => <DummyBlock name={'BreadCrumbs'} />;
BreadCrumbs.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Title',
		},
	},
};
function ImageComponent({ attributes }) {
	return (
		<div
			style={{
				width: attributes?.width || 50,
				height: attributes?.height || 50,
				overflow: 'hidden',
			}}
		>
			{attributes.uri ? (
				<img
					src={attributes.uri}
					alt={'component'}
					style={{ ...attributes?.advancedStyle }}
					className={clsx('h-full w-full', {
						'object-cover': attributes?.resizeMode === 'cover',
						'object-contain': attributes?.resizeMode === 'contain',
					})}
				/>
			) : (
				<button className="bg-gray-200 rounded">Add Image</button>
			)}
		</div>
	);
}
ImageComponent.attributesSchema = {
	properties: {
		uri: {
			type: 'string',
			label: 'Image URL',
		},
		height: {
			type: 'string',
			uiType: 'RangeSlider',
			label: 'Height',
			default: 50,
			min: 1,
			max: 500,
			className: 'mt-4',
		},
		width: {
			type: 'string',
			uiType: 'RangeSlider',
			label: 'Width',
			default: 50,
			min: 1,
			max: 500,
		},
		aspectRatio: {
			type: 'string',
			label: 'Aspect Ratio',
			description: 'eg: width/height',
		},
		resizeMode: {
			type: 'string',
			uiType: 'RadioGroup',
			options: [
				{
					label: 'Contain',
					value: 'contain',
				},
				{
					label: 'Cover',
					value: 'cover',
				},
			],
			title: 'Resize Mode',
			description: 'eg (public,private,admin)',
		},
		advancedStyle: {
			type: 'json',
			label: 'Advanced Style',
			description: 'Advanced Styles for the block',
		},
	},
};
export function registerShopifyCustomBlockTypes() {
	const adminAccess = isAdmin();
	const features = getProjectFeature();
	const blocksHavingAccess = features?.enableBlockAccess;
	const blockList = {
		'appmaker/shopify-product-image': {
			title: 'shopify product image',
			icon: <DatabaseIcon />,
			Block: ProductImage,
		},
		'appmaker/shopify-recently-viewed-products': {
			title: 'Shopify recently viewed products',
			icon: <DatabaseIcon />,
			Block: RecentlyViewed,
		},
		'appmaker/reusable-block': {
			title: 'Reusable Block',
			icon: <DatabaseIcon />,
			Block: ReusableBlock,
		},
		'appmaker/shopify-product-list': {
			title: 'shopify product list',
			icon: <DatabaseIcon />,
			Block: ShopifyProductList,
		},
		'appmaker/shopify-product-list-slot': {
			title: 'Shopify product list slot ',
			icon: <DatabaseIcon />,
			Block: ProductListSlots,
			attributes: ProductListSlots.attributesSchema.properties,
		},
		'appmaker/badge': {
			title: 'Badge',
			icon: <DatabaseIcon />,
			Block: Badge,
		},
		'appmaker/shopify-product-data': {
			title: 'shopify product data',
			icon: <DatabaseIcon />,
			Block: ProductData,
		},
		'appmaker/shopify-product-variation': {
			title: 'shopify product variation',
			icon: <DatabaseIcon />,
			Block: ProductVariation,
		},
		'appmaker/shopify-product-description': {
			title: 'shopify product description',
			icon: <DatabaseIcon />,
			Block: ExpandableTextBlock,
		},
		'appmaker/shopify-product-pbp-buttons': {
			title: 'shopify product buttons',
			icon: <DatabaseIcon />,
			Block: Buttons,
		},
		'appmaker/shopify-product-customize-buttons': {
			title: 'shopify product customize buttons',
			icon: <DatabaseIcon />,
			Block: ButtonsTwo,
		},
		'appmaker/shopify-related-product-scroller': {
			title: 'Shopify Related Product scroller',
			icon: <DatabaseIcon />,
			Block: ShopifyRelatedProducts,
		},
		'appmaker/feature-card': {
			title: 'Feature Card',
			icon: <DatabaseIcon />,
			Block: FeatureCard,
		},
		'appmaker/customise-shirt-widget': {
			title: 'Customise Shirt Widget',
			icon: <AdjustmentsIcon />,
			Block: CustomiseShirtWidget,
		},
		'appmaker/order-customization-widget': {
			title: 'Customize order',
			icon: <AdjustmentsIcon />,
			Block: CustomizeOrder,
		},
		'appmaker/divider': {
			title: 'Divider',
			icon: <MinusIcon />,
			Block: Divider,
		},
		'appmaker/list': {
			title: 'List',
			icon: <ClipboardListIcon />,
			Block: TextList,
		},
		'appmaker/tab-block': {
			title: 'Tab Block',
			icon: <SwitchVerticalIcon />,
			Block: TabBlock,
		},
		'appmaker/tab-item': {
			title: 'Tab Item',
			icon: <SwitchVerticalIcon />,
			Block: TabItem,
			parent: ['appmaker/tab-block'],
		},
		'appmaker/bottom-tab-block': {
			title: 'Bottom Tab',
			icon: <SwitchVerticalIcon />,
			Block: BottomTabBlock,
			showToAll: true,
		},
		'appmaker/bottom-tab-item': {
			title: 'Bottom Tab Item',
			icon: <SwitchVerticalIcon />,
			Block: BottomTabItem,
			parent: ['appmaker/bottom-tab-block'],
			showToAll: true,
		},
		'appmaker/accordion': {
			title: 'Accordion',
			icon: <AdjustmentsIcon />,
			Block: Accordion,
		},
		'appmaker/media-card': {
			title: 'Media Card',
			icon: <NewspaperIcon />,
			Block: MediaCard,
		},
		'appmaker/collapsible-drawer-menu': {
			title: 'Collapsible Menu',
			icon: <SwitchVerticalIcon />,
			Block: CollapsibleMenu,
		},
		'appmaker/bread-crumbs': {
			title: 'Bread Crumbs',
			icon: <SwitchVerticalIcon />,
			Block: BreadCrumbs,
		},
		'appmaker/toolbar-icon': {
			title: 'Tool bar icon',
			icon: <SwitchVerticalIcon />,
			Block: ToolBarIcon,
			parent: ['appmaker/toolbar-icon-set'],
		},

		'appmaker/native-json': {
			title: 'Native View',
			icon: <TableIcon />,
			Block: AppmakerRowEdit,
		},
		'appmaker/image': {
			title: 'Image Component',
			icon: <PhotographIcon />,
			Block: ImageComponent,
		},
		'appmaker/toolbar-icon-set': {
			title: 'Tool Icon set',
			icon: <SwitchVerticalIcon />,
			Block: ToolbarPage,
		},
		'appmaker/layout-block': {
			title: 'Layout Block',
			icon: <ViewGridIcon />,
			Block: LayoutBlock,
		},
		'appmaker/rich-text':{
			title: 'Rich Text',
			icon: <DocumentTextIcon />,
			Block: RichText,
		}
	};
	// register blocks
	Object.keys(blockList)?.map((blockId) => {
		const showBlockToCustomer =
			adminAccess ||
			blockList[blockId]?.showToAll === true ||
			blocksHavingAccess?.find((item) => item?.id === blockId) !== undefined;
		if (showBlockToCustomer) {
			appmaker.registerAppmakerBlockType(blockId, blockList[blockId]);
		}
	});

	// appmaker.registerAppmakerBlockType('nosto/nosto-product-recommendation', {
	// 	title: 'Nosto',
	// 	icon: <SwitchVerticalIcon icon="screenoptions" />,
	// 	Block: NostoProductRecommendations,
	// });
	// appmaker.registerAppmakerBlockType('appmaker/collection-import', {
	// 	title: 'CollectionImport',
	// 	icon: <SwitchVerticalIcon icon="screenoptions" />,
	// 	Block: CollectionImport,
	// });

	// appmaker.registerAppmakerBlockType('appmaker/toolbar-icon-set', {
	// 	title: 'Tool bar icon set',
	// 	icon: <SwitchVerticalIcon />,
	// 	Block: ToolBarIcons,
	// 	parent: ['appmaker/toolbar-config-page'],
	// });
}

export function registerShopifyCustomBlockTypesPublic() {
	appmaker.registerAppmakerBlockType('appmaker/row', {
		title: 'Row',
		icon: <ViewBoardsIcon />,
		Block: AppmakerRowEdit,
		View: AppmakerRow,
	});

	appmaker.registerAppmakerBlockType('appmaker/layout-icon', {
		title: 'Layout Icon',
		icon: <EmojiHappyIcon />,
		Block: LayoutIcon,
	});
}
//
