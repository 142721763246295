import appmaker from '@appmaker/core/index';
import extension from 'packages/extensions/extensions';


const registerExtensions = ()=>{
    extension.forEach(ext => {
        appmaker.registerPlugin(ext);
    })
};

registerExtensions();

