import axios from "axios";
import { V3_BASE_URL } from '@appmaker/core/api/core';

const BASE_URL = V3_BASE_URL + '/projects';

const api = {
    getMeta: async (projectId) => {
        const response = await axios.get(`${BASE_URL}/${projectId}/meta`, {
            credentials: 'include',
            withCredentials: true,
        });
        return response.data;
    },
    updateMeta: async (projectId, data) => {
        const response = await axios.post(`${BASE_URL}/${projectId}/meta`, data, {
            credentials: 'include',
            withCredentials: true,
        })
        return response.data;
    }
}

export default api;