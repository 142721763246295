import React from 'react';
import { InnerBlocks } from '@wordpress/block-editor';
import clsx from 'clsx';
import './style.css';

const LayoutBlock = ({ attributes }) => {
	const { displayType } = attributes;
	const horizontal = displayType?.type === 'horizontal';
	const flatListContentContainerStyle = horizontal
		? displayType?.attributes?.horizontalStyles
		: null;
	const contentContainerStyle = horizontal
		? null
		: displayType?.attributes?.stackedStyles;
	return (
		<div
			style={contentContainerStyle || flatListContentContainerStyle}
			className={clsx('border border-gray-400 p-1', {
				'horizontal-display': attributes?.displayType?.type === 'horizontal',
			})}
		>
			<InnerBlocks />
		</div>
	);
};

export default LayoutBlock;

LayoutBlock.attributesSchema = {
	properties: [
		{
			id: 'displayType',
			type: 'conditional',
			label: 'Display type',
			placeholder: 'Choose options',
			default: {
				type: 'stacked',
			},
			options: [
				{
					id: 'stacked',
					label: 'Stacked',
					properties: [
						{
							id: 'stackedStyles',
							type: 'string',
							label: 'Stacked styles',
							uiType: 'layout-styles',
							className: 'mb-4 mt-2',
						},
						{
							id: 'numColumns',
							type: 'string',
							label: 'Number of columns',
							uiType: 'RangeSlider',
							default: 1,
							min: 1,
							max: 8,
						},
					],
				},
				{
					id: 'horizontal',
					label: 'Horizontal',
					properties: [
						{
							id: 'horizontalStyles',
							type: 'string',
							label: 'Horizontal styles',
							uiType: 'layout-styles',
						},
					],
				},
			],
		},
	],
};
