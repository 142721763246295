import appmaker from '@appmaker/core/index';
import { DatabaseIcon } from '@heroicons/react/solid';
import { isAdmin } from '@appmaker/core/store/user';
import {
	FormBlockCard,
	FormBlockCardContent,
	FormBlockCardContentEdit,
	FormBlockCardEdit,
	FormBlockCardFooter,
	FormBlockCardFooterEdit,
} from './components/FormBlockCard';
import TextInput from '@appmaker/core/components/AppmakerForm/blocks/formItems/TextInput';
import CheckBox from '@appmaker/core/components/AppmakerForm/blocks/formItems/CheckBox';
import RadioItem from '@appmaker/core/components/AppmakerForm/blocks/formItems/RadioItem';
import FormSwitch from '@appmaker/core/components/AppmakerForm/blocks/formItems/FormSwitch';
import SelectableInput from '@appmaker/core/components/AppmakerForm/blocks/formItems/SelectableInput';
import {
	CollectionChooser,
	ProductChooser,
} from '@appmaker/core/components/AppmakerForm/blocks/formItems/ProductChooser';
import RadioBlock, { RadioBlockView } from './components/RadioBlock';
import ConditionBlock, {
	ConditionBlockItem,
	ConditionBlockItemView,
	ConditionBlockView,
} from './components/ConditionBlock';
import SelectMenu from './components/SelectMenu';
import Button from './components/Button';
import InfiniteForm, { InfiniteFormView } from './components/InfiniteForm';

TextInput.attributesSchema = {
	properties: {
		className: {
			type: 'string',
			default: 'mb-4',
			label: 'Class Name',
		},
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		placeholder: {
			type: 'string',
			default: 'Placeholder',
			label: 'Placeholder',
		},
		required: {
			type: 'switch',
			default: false,
			label: 'Required',
		},
		type: {
			type: 'string',
			default: 'text',
			label: 'Type',
		},
		name: {
			type: 'string',
			default: 'name',
			label: 'Name',
		},
		error: {
			type: 'string',
			default: '',
			label: 'Error',
		},
		info: {
			type: 'string',
			default: '',
			label: 'Info',
		},
		value: {
			type: 'string',
			default: '',
			label: 'Value',
		},
	},
};

CheckBox.attributesSchema = {
	properties: {
		id: {
			type: 'string',
			default: 'id',
			label: 'ID',
		},
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		description: {
			type: 'string',
			default: 'Description',
			label: 'Description',
		},
		name: {
			type: 'string',
			default: 'name',
			label: 'Name',
		},
	},
};
// RadioBlock.attributesSchema = {
// 	properties: {
// 		name: {
// 			type: 'string',
// 			default: 'name',
// 			label: 'Name',
// 		},
// 	},
// };
RadioItem.attributesSchema = {
	properties: {
		id: {
			type: 'string',
			default: 'id',
			label: 'ID',
		},
		value: {
			type: 'string',
			label: 'Value',
		},
		name: {
			type: 'string',
			default: 'name',
			label: 'Name',
		},
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		description: {
			type: 'string',
			default: 'Description',
			label: 'Description',
		},
	},
};

FormSwitch.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		description: {
			type: 'string',
			default: 'Description',
			label: 'Description',
		},
	},
};

ProductChooser.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
	},
};
CollectionChooser.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
	},
};
SelectableInput.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			default: 'Label',
			label: 'Label',
		},
		placeholder: {
			type: 'string',
			default: 'Placeholder',
			label: 'Placeholder',
		},
	},
};

const registerBlockTypes = () => {
	appmaker.registerAppmakerBlockType('appmaker/web-block-card', {
		title: 'Web Block Card',
		icon: <DatabaseIcon />,
		Block: FormBlockCardEdit,
		View: FormBlockCard,
	});
	appmaker.registerAppmakerBlockType('appmaker/web-block-card-content', {
		title: 'Web Block Card Content',
		parent: ['appmaker/web-block-card'],
		icon: <DatabaseIcon />,
		Block: FormBlockCardContentEdit,
		View: FormBlockCardContent,
	});
	appmaker.registerAppmakerBlockType('appmaker/web-block-card-footer', {
		title: 'Web Block Card Footer',
		parent: ['appmaker/web-block-card'],
		icon: <DatabaseIcon />,
		Block: FormBlockCardFooterEdit,
		View: FormBlockCardFooter,
	});
	appmaker.registerAppmakerBlockType('appmaker/text-input', {
		title: 'Text Input',
		icon: <DatabaseIcon />,
		Block: TextInput,
	});

	appmaker.registerAppmakerBlockType('appmaker/select-menu', {
		title: 'Select Menu',
		icon: <DatabaseIcon />,
		Block: SelectMenu,
	});
	appmaker.registerAppmakerBlockType('appmaker/form-checkbox', {
		title: 'Checkbox',
		icon: <DatabaseIcon />,
		Block: CheckBox,
	});
	appmaker.registerAppmakerBlockType('appmaker/form-radio', {
		title: 'Radio',
		icon: <DatabaseIcon />,
		Block: RadioBlock,
		View: RadioBlockView,
	});
	appmaker.registerAppmakerBlockType('appmaker/form-radio-item', {
		title: 'Radio Item',
		icon: <DatabaseIcon />,
		Block: RadioItem,
		parent: 'appmaker/form-radio',
	});
	appmaker.registerAppmakerBlockType('appmaker/product-chooser', {
		title: 'Product Chooser',
		icon: <DatabaseIcon />,
		Block: ProductChooser,
	});
	appmaker.registerAppmakerBlockType('appmaker/collection-chooser', {
		title: 'Collection Chooser',
		icon: <DatabaseIcon />,
		Block: CollectionChooser,
	});
	appmaker.registerAppmakerBlockType('appmaker/form-switch', {
		title: 'Switch',
		icon: <DatabaseIcon />,
		Block: FormSwitch,
	});
	appmaker.registerAppmakerBlockType('appmaker/form-selectable-input', {
		title: 'Selectable Input',
		icon: <DatabaseIcon />,
		Block: SelectableInput,
	});
	appmaker.registerAppmakerBlockType('appmaker/condition-block', {
		title: 'Condition',
		icon: <DatabaseIcon />,
		Block: ConditionBlock,
		View: ConditionBlockView,
	});
	appmaker.registerAppmakerBlockType('appmaker/condition-block-item', {
		title: 'Condition Item',
		icon: <DatabaseIcon />,
		Block: ConditionBlockItem,
		parent: ['appmaker/condition-block'],
		View: ConditionBlockItemView,
	});
	appmaker.registerAppmakerBlockType('appmaker/component-button', {
		title: 'Button Component',
		icon: <DatabaseIcon />,
		Block: Button,
	});
	appmaker.registerAppmakerBlockType('appmaker/infinite-form', {
		title: 'Infinite Form',
		icon: <DatabaseIcon />,
		Block: InfiniteForm,
		View: InfiniteFormView,
	});
	// appmaker.registerAppmakerBlockType('appmaker/infinite-form-item', {
	// 	title: 'Infinite Form Item',
	// 	icon: <DatabaseIcon />,
	// 	Block: InfiniteFormItem,
	// 	parent: ['appmaker/infinite-form'],
	// });
};
function registerBlocks() {
	appmaker.registerAppmakerFrontEndBlock('appmaker/text-input', {
		Block: TextInput,
	});
	
	appmaker.registerAppmakerFrontEndBlock('appmaker/select-menu', {
		Block: SelectMenu,
	});
	appmaker.registerAppmakerFrontEndBlock('appmaker/infinite-form', {
		Block: InfiniteFormView,
	});
	
}
function registerWebBlockTypes() {
	if (isAdmin()) {
		registerBlockTypes();
	}
	// alert('registerWebBlockTypes');
	registerBlocks();
}

export { registerWebBlockTypes };
