import { useFormDataValue } from '@appmaker/core';

function RadioItem(props) {
	const { attributes, parentAttributes } = props;
	const { id, label, description } = attributes;
	const name = parentAttributes?.name || attributes.name;
	const [value, setValue] = useFormDataValue({
		clientId: props.clientId,
		name,
	});
	return (
		<div className="relative flex items-start mb-5">
			<div className="flex h-5 items-center">
				<input
					id={id}
					aria-describedby={`${id}-description`}
					name={name}
					type="radio"
					className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
					checked={value===attributes.value}
					onChange={(e) => setValue(attributes.value)}
				/>
			</div>
			<div className="ml-3 text-sm">
				<label htmlFor={id} className="font-medium text-gray-700">
					{label}
				</label>
				<p id={`${id}-description`} className="text-gray-500 text-sm">
					{description}
				</p>
			</div>
		</div>
	);
}

export default RadioItem;
