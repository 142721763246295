import appmaker from '@appmaker/core/index';
import { lazy } from 'react';
import MainDashboard from './routes/Dashboard';
import { HomeIcon } from '@heroicons/react/solid';

const plugin = {
	id: '@appmaker/dashboard',
	name: 'Dashboard',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/',
			component: MainDashboard,
			exact: true,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu(
			{
				name: 'Dashboard',
				routeName: '/',
				icon: HomeIcon,
			},
			1
		);
	},
};

export default plugin;
