// @flow strict

import SimpleBlockEditor from '@appmaker/components/SimpleBlockEditor';
import * as React from 'react';

// import ReactJson from 'react-json-view';

function CustomBlockEditor() {

	return (
		<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			<div className="py-6">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
				</div>
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					{/* Replace with your content */}
					<div className="py-4">
						{/* <IconChooser /> */}
						<SimpleBlockEditor
							components={{
								'appmaker/toolbar-icon': () => <div>Hello</div>
							}}
							insertBlockAttributes={{
								newText: 'Add Block Item',
							}} />
						{/* <ReactJson
							src={actions}
							onSelect={console.log}
							displayDataTypes={false}
							displayObjectSize={false}
						/> */}

						{/* <AppmakerForm options={actions} debug={true} optionKey={'action'} /> */}
						{/* <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" /> */}
					</div>
					{/* /End replace */}
				</div>
			</div>
		</main>
	);
}

export default CustomBlockEditor;
