import { PlusIcon } from '@heroicons/react/outline';
import { CloudDownloadIcon } from '@heroicons/react/solid';

const StepOne = () => {
	return (
		<ol className="list-outside ml-4 list-decimal flex flex-col space-y-3 mt-2">
			<li>
				<b>Generate access API key</b>
				<ul className="list-outside ml-4 list-roman flex flex-col space-y-3 mt-2">
					<li>
						Go to
						<a
							href="https://appstoreconnect.apple.com/access/api"
							className="p-1 mx-1 text-blue-600"
							target="_blank"
							rel="noreferrer"
						>
							https://appstoreconnect.apple.com/access/api
						</a>
						and log with your Apple ID.
					</li>
					<li>
						<b>App Store Connect API</b> ⇒ Click{' '}
						<PlusIcon className="inline-flex h-6 w-6 rounded-full text-white p-1 bg-blue-600" />
						<img
							className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
							src="/images/publish/ios/appstore-api-step-1.png"
							alt="step one"
						/>
					</li>
					<li>
						<ul className="list-outside ml-4 list-square flex flex-col space-y-3 mt-2">
							<li>
								Type API key name <i>(Eg: "Appmaker auth key")</i>
							</li>
							<li>
								Give access as <b>Admin</b>
							</li>
						</ul>
						<img
							className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
							src="/images/publish/ios/appstore-api-step-2.png"
							alt="step one"
						/>
					</li>
				</ul>
			</li>
			<li>
				Click <b>Generate</b>
				<img
					className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
					src="/images/publish/ios/appstore-api-step-3.png"
					alt="step one"
				/>
			</li>
			<li>
				<ul className="list-outside ml-4 list-roman flex flex-col space-y-3 mt-2">
					<li>
						<b>Download API Key</b>{' '}
						<code className="font-mono text-red-600 bg-red-50 py-1 px-2">
							.p8 file
						</code>{' '}
						(Marked 1)
					</li>
					<li>
						Copy <b>Key ID</b> (Marked 2)
					</li>
					<li>
						Copy <b>Issuer ID</b> (Marked 3)
					</li>
				</ul>
				<img
					className="w-full rounded-lg border border-gray-100 mt-1 overflow-hidden"
					src="/images/publish/ios/appstore-api-step-4.png"
					alt="step one"
				/>
				<p>Proceed to next step with details collected</p>
			</li>
		</ol>
	);
};

export default StepOne;
