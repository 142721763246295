/* eslint-disable react/jsx-indent-props */
import React from 'react';
import {
	MediaUpload,
	RichText,
	InspectorControls,
} from '@wordpress/block-editor';
import {
	Button,
	PanelBody,
	RangeControl,
	ToggleControl,
} from '@wordpress/components';
import { withSelect } from '@wordpress/data';
import { __ } from '@wordpress/i18n';
import ImageScrollerImage from '../../../components/ImageScrollerImage';
// Panel body
import PanelBodyComponent from '../panel';
import DeleteActionButton from '../../../components/Toolbar';
import { extractTextFromHTML } from '../../../helper';

const AddImage = () => {
	return (
		<span className="flex flex-col space-y-2 px-4">
			<svg className="m-auto" width="24" height="24" viewBox="0 0 24 24">
				<g transform="translate(-740 -56)">
					<rect
						width="24"
						height="24"
						transform="translate(740 56)"
						fill="none"
					/>
					<g transform="translate(740.899 58.167)">
						<g transform="translate(0 0)">
							<circle
								cx="1.522"
								cy="1.522"
								r="1.522"
								transform="translate(6.83 4.842)"
								fill="#4b6efc"
							/>
							<path
								d="M20.849,35.968a4.688,4.688,0,0,0-2.622-1.248V26.981a3.044,3.044,0,0,0-.867-2.115A2.955,2.955,0,0,0,15.246,24H2.981a3.044,3.044,0,0,0-2.115.867A2.955,2.955,0,0,0,0,26.981V39.605A3.044,3.044,0,0,0,.867,41.72a2.955,2.955,0,0,0,2.115.867H14.844a4.474,4.474,0,0,0,6.005-6.618ZM1.121,26.981a1.877,1.877,0,0,1,1.861-1.861H15.246a1.877,1.877,0,0,1,1.861,1.861v6.6l-3.13-3.108a.568.568,0,0,0-.8,0L8.458,35.207,5.265,31.993a.568.568,0,0,0-.8,0L1.121,35.376ZM2.96,41.487v-.021a1.855,1.855,0,0,1-1.311-.55,1.93,1.93,0,0,1-.529-1.311V36.962L4.885,33.2l3.193,3.193a.588.588,0,0,0,.8,0L13.6,31.655l3.087,3.108-.19.063a1.964,1.964,0,0,0-.275.085c-.085.021-.169.063-.254.085a.633.633,0,0,0-.169.085,1.964,1.964,0,0,0-.211.106l-.317.19c-.063.042-.106.063-.169.106-.042.021-.063.042-.106.063a2.551,2.551,0,0,0-.507.444,4.473,4.473,0,0,0-1.311,3.172,4.649,4.649,0,0,0,.106.93c.021.085.042.148.063.233.063.211.127.423.211.634v.021a3.879,3.879,0,0,0,.275.507Zm17.064.021a3.345,3.345,0,0,1-4.673.042c-.085-.085-.169-.19-.254-.275-.063-.063-.127-.148-.19-.211A2.008,2.008,0,0,1,14.7,40.7c-.042-.085-.085-.148-.127-.233a1.786,1.786,0,0,1-.106-.359c-.021-.085-.063-.19-.085-.275a3.5,3.5,0,0,1-.063-.677,3.394,3.394,0,0,1,.973-2.368,3.305,3.305,0,0,1,2.368-.973,3.394,3.394,0,0,1,2.368.973A3.269,3.269,0,0,1,21,39.161,3.382,3.382,0,0,1,20.025,41.508Z"
								transform="translate(0 -24)"
								fill="#4b6efc"
							/>
							<path
								d="M291.893,266.261H290.6v-1.29a.571.571,0,0,0-1.142,0v1.29h-1.29a.571.571,0,0,0,0,1.142h1.29v1.29a.571.571,0,0,0,1.142,0V267.4h1.29a.571.571,0,0,0,0-1.142Z"
								transform="translate(-272.397 -251.692)"
								fill="#4b6efc"
							/>
						</g>
					</g>
				</g>
			</svg>
			<small className="text-sm">Add image</small>
		</span>
	);
};
const ImageScroller = (props) => {
	const {
		attributes,
		setAttributes,
		isSelected,
		parentAttributes,
		parentId,
		onChangeParent,
		metaData={}
	} = props;
	const { image, text } = attributes;
	const maxHeight = metaData?.image?.maxHeight
	const maxWidth = metaData?.image?.maxWidth
	const onSelect = (data) => {
		let newUrl = new URL(data?.url);
		if(attributes?.disableImageResize){
			if (newUrl?.searchParams?.has('height')) {
				newUrl?.searchParams?.delete('height');
			}
			if (newUrl?.searchParams?.has('width')) {
				newUrl?.searchParams?.delete('width');
			}
		}else{
			if(data?.meta?.width > maxWidth){
				// newUrl += attributes?.maxHeight ? `&height=${attributes?.maxHeight}` : '';
				newUrl?.searchParams?.set('width',maxWidth)
			}else if(data?.meta?.height > maxHeight ){
				// newUrl += attributes?.maxWidth ? `&width=${attributes?.maxWidth}` : '';
				newUrl?.searchParams?.set('height',maxHeight);
			}
		}
		setAttributes({
			image: {
				url: newUrl,
				fileName: data.filename,
				id: data.id,
				title: data.title,
			},
			imageContainerStyle: {
				height: data.meta !== undefined ? data.meta.height : '100',
				width: data.meta !== undefined ? data.meta.width : '100',
			},
			thumbnail_meta: {
				height: data.meta !== undefined ? data.meta.height : undefined,
				width: data.meta !== undefined ? data.meta.width : undefined,
			},
		});
	};
	return (
		<div className={props.className}>
			<DeleteActionButton
				action={() => {
					setAttributes({ image: undefined });
				}}
			/>
			<InspectorControls>
				<PanelBody title="Image Settings" initialOpen>
					{image && (
						<>
							<p className="mb-1 text-gray-500 font-semibold text-sm">Image</p>
							<div className="flex items-center gap-3">
								<img
									src={image.url}
									alt="thumbnail"
									className="h-16 w-16 border border-gray-200 object-contain p-0.5"
								/>
								<button
									onClick={() =>
										setAttributes({
											image: undefined,
										})
									}
									type="button"
									className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
								>
									Replace image
								</button>
							</div>
						</>
					)}
				</PanelBody>
			</InspectorControls>
			{image === undefined && (
				<MediaUpload
					onSelect={onSelect}
					render={({ open }) => {
						return (
							<div
								className="hover-pointer bg-light border py-5 mx-1"
								onClick={(event) => {
									event.stopPropagation();
									open();
								}}
							>
								<span className="d-flex justify-content-center">
									<AddImage />
								</span>
							</div>
						);
					}}
				/>
			)}
			<PanelBodyComponent
				attributes={parentAttributes}
				onChangeAttribute={(value) => {
					onChangeParent(value);
				}}
			/>
			{image && (
				<div
					style={{
						height: (parentAttributes.customBlockHeight || 130) + 16,
						width: 'max-content',
						marginLeft: parentAttributes.enableGap ? 8 : 0,
					}}
				>
					<img
						src={image.url}
						className="image-uploaded"
						style={{ height: 'inherit' }}
					/>
				</div>
			)}
		</div>
	);
};

export default withSelect((select, props, dispatch) => {
	const parentId = select('core/block-editor').getBlockRootClientId(
		props.clientId
	);
	const parentAttributes =
		select('core/block-editor').getBlockAttributes(parentId);
	// change the value of the parent attributes
	const onChangeParent = (value) => {
		dispatch
			.dispatch('core/block-editor')
			.updateBlockAttributes(parentId, value);
	};
	return { parentAttributes, parentId, onChangeParent };
})(ImageScroller);
