import { useSelect } from '@wordpress/data';
import { store as blockEditorStore } from '@wordpress/block-editor';
export function useBlockParentAttributes(clientId) {
	const parentId = useSelect((select) => {
		return select(blockEditorStore).getBlockRootClientId(clientId);
	}, [clientId]);
	const parentAttributes = useSelect((select) => {
		return select(blockEditorStore).getBlockAttributes(parentId);
	}, [parentId]);
	return [parentAttributes, parentId ];
}
