import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

export default function Modal({
	open = false,
	onClose,
	className,
	size = 'lg',
	children,
}) {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className={clsx(
									'relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full',
									{
										'sm:max-w-4xl': size === '4xl',
										'sm:max-w-3xl': size === '3xl',
										'sm:max-w-2xl': size === '2xl',
										'sm:max-w-xl': size === 'xl',
										'sm:max-w-lg': size === 'lg',
										'sm:max-w-md': size === 'md',
										'sm:max-w-sm': size === 'sm',
										'sm:max-w-xs': size === 'xs',
									}
								)}
							>
								<div className={className}>{children}</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
