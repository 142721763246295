// import { addFilter, applyFilters } from '@wordpress/hooks';
import appmaker from '@appmaker/core/index';
import ShopifyInstall from './components/ShopifyInstall'
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import qs from 'qs';
import { sendAppmakerEvent } from '@appmaker/core/api/events';
const queryParams = qs.parse(window.location.search);

if (queryParams.host && window.self !== window.top && !window.shopifyApp) {
	const shopifyConfig = {
		apiKey: 'd16097e6c4d94b41c3dde379ce2490fb',
		host: queryParams.host,
	};
	if (process.env.NODE_ENV === 'development') {
		shopifyConfig['apiKey'] = '45b133425d617958c21b795c3ed903fd';
	}
	console.log("Creating shopify bridge", shopifyConfig)
	window.shopifyApp = createApp(shopifyConfig);
	
	window.shopifyHost = queryParams.host;
	console.log("Created shopify bridge", window.shopifyHost)
	getSessionToken(window.shopifyApp) // requires an App Bridge instance
		.then(token => {
			window.shopifySessionToken = token;
		}).catch(err => {
			console.log("Unable to get shopify token", err)
		});
}

const CoreApp = {
	id: '@appmaker/core-app',
	name: 'Core app',
	activate: () => {
		appmaker.registerRootRoute({ routeName: '/shopify-install', component: ShopifyInstall });
		appmaker.onTrack(({ event, params }) => {
			sendAppmakerEvent(event, params)
		})

	},
};

export default CoreApp;
