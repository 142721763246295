import React, { Suspense } from 'react';

import clsx from 'clsx';
// import AppPreview from './AppPreview';
import CardBlock, { CardTitle } from './CardBlock';
import LoadingIcon from './LoadingIcon';
const AppPreview = React.lazy(() => import('./AppPreview'));

const EditBlock = ({
	children,
	title,
	video,
	template,
	description,
	onClickSave = () => {},
	isSaving = false,
}) => {
	return (
		<CardBlock>
			<div
				className={clsx('flex-1 flex flex-col', {
					'max-w-xl': !template.fullWidth,
				})}
			>
				{/* main contents of update app */}
				<div>
					<CardTitle title={title} description={description} video={video} />
					{children}
				</div>
				{/* end of main contents of update app */}

				{/* action elements */}
				<div className="mt-12 flex">
					{/* <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Cancel
                    </button> */}
					{/* <div className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"></div> */}
					<button
						disabled={isSaving}
						type="button"
						onClick={onClickSave}
						className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					>
						{isSaving ? (
							<>
								<LoadingIcon className="-ml-1 mr-2 h-6 w-6 animate-spin" />
								Saving...
							</>
						) : (
							'Next'
						)}
					</button>
				</div>
				{/* end of action elements */}
			</div>
			{template.previewId && (
				<div className="mx-auto">
					<div className="sticky top-0">
						<Suspense fallback={<div>Loading...</div>}>
							<AppPreview previewId={template.previewId} />
						</Suspense>
					</div>
				</div>
			)}
			{/* realtime app preview with tabs */}
			{/* end of realtime app preview with tabs */}
		</CardBlock>
	);
};

export default EditBlock;
