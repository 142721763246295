import { BASE_URL } from '@appmaker/core/api/core';
import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';

const proxy = async (path, params) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/${path}`;
    //  const url = `https://920dbeae33.to.intercept.rest/`;
    const response = await axios.get(url, {
        params,
        credentials: 'include',
        withCredentials: true,
    });
    return response.data
}
const products = (params = {}) => proxy('products', params)
export {
    proxy,
    products
}