import { UploadIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import api from '../../../api';
const { uploadService } = api;

const ServiceUpload = ({
	id,
	accept = '.json',
	addToast = () => {},
	afterUpload = () => {},
}) => {
	const [isUploading, setUploading] = useState(false);
	const onDrop = async (acceptedFiles, rejectedFiles) => {
		try {
			setUploading(true);
			const response = await uploadService({
				projectId: id,
				file: acceptedFiles[0],
			});
			if (response.status === true) {
				addToast('Upload completed', {
					appearance: 'success',
					autoDismiss: true,
				});
				afterUpload();
				setUploading(false);
			} else {
				throw new Error('File upload failed');
			}
		} catch (error) {
			addToast('Something went wrong', {
				appearance: 'error',
				autoDismiss: true,
			});
			setUploading(false);
		}
	};

	return (
		<div className="mt-4">
			{!isUploading ? (
				<Dropzone
					onDrop={onDrop}
					accept={accept}
					disabled={isUploading}
					className="dropZone"
				>
					{({ getRootProps, getInputProps, isDragActive }) => {
						return (
							<div
								{...getRootProps()}
								className="p-8 text-center bg-light rounded-lg cursor-pointer upload-json bg-blue-100 border border-blue-400 border-dashed"
							>
								<input {...getInputProps()} />
								{isDragActive ? (
									<p className="py-4">Drop files here...</p>
								) : (
									<div className="flex flex-col items-center space-y-4">
										<UploadIcon className="text-blue-700 h-6 w-6" />
										<label htmlFor="file">
											<strong>Choose a file</strong>
											<span className="box_dragndrop"> or drag it here</span>
										</label>
									</div>
								)}
							</div>
						);
					}}
				</Dropzone>
			) : (
				<div className="p-8 text-center bg-light rounded-lg cursor-pointer upload-json bg-blue-100 border border-blue-400 border-dashed">
					<div className="flex flex-col items-center space-y-4">
						<UploadIcon className="text-blue-700 animate-bounce h-6 w-6" />
						<span className="text-gray-500">Uploading...</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default ServiceUpload;
