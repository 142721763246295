import AppmakerWorkflow from "./appmaker-workflow";
import MetaFieldV2 from "./meta-field-v2";
import DeepLinking from "./deep-linking";
import Nosto from "./nosto";
const extensions = [
    AppmakerWorkflow,
    MetaFieldV2,
    DeepLinking,
    Nosto
]

export default extensions;