import { ArrowRightIcon } from '@heroicons/react/solid';
import useProjectGraphQl from '@appmaker/core/hooks/useProjectGraphql';
import { NavLink } from '@appmaker/core/routes';
import { getProjectData } from '@appmaker/core/store/project';
import { isAdmin } from '@appmaker/core/store/user';

const IosHome = ({ alreadyHaveAccount = () => {} }) => {
	const { projectId, projectData } = getProjectData();
	const { data } = useProjectGraphQl();
	return (
		<div className="p-4">
			<h4 className="text-gray-900 mb-2 text-xl font-bold">
				Upload to App Store
			</h4>
			<h6 className="text-semibold mb-1">
				Generating Release Build for App Store
			</h6>
			<p className="text-gray-600 mb-4 text-base">
				You need to register as an iOS developer to get permission for
				publishing apps on AppStore. Enrolling in Apple developer program
				requires you to pay Apple an annual $99 fee. Activation may take up to 2
				business days.
			</p>
			<span className="relative z-0 inline-flex gap-4 rounded-md">
				<button
					type="button"
					className="relative inline-flex items-center px-4 py-2 rounded-md bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
					onClick={() => {
						window.open('https://developer.apple.com/programs/enroll/');
					}}
				>
					Get developer account
				</button>
				{isAdmin() ||
				projectData?.publicMeta?.subscription_override_ipa_build === true ||
				data?.subscription?.status.toLowerCase() === 'active' ? (
					<button
						type="button"
						className="relative group inline-flex gap-1 items-center px-4 py-2 rounded-r-md text-base font-medium text-blue-500 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
						onClick={() => {
							alreadyHaveAccount();
						}}
					>
						I already have an account
						<ArrowRightIcon className="h-3 w-3 group-hover:translate-x-1 transform transition-all" />
					</button>
				) : (
					<NavLink
						to={`/apps/${projectId}/subscription`}
						className="relative group inline-flex gap-1 items-center px-4 py-2 rounded-r-md text-base font-medium text-blue-500 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
					>
						I already have an account
						<ArrowRightIcon className="h-3 w-3 group-hover:translate-x-1 transform transition-all" />
					</NavLink>
				)}
			</span>
		</div>
	);
};

export default IosHome;
