import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { useFormDataValue } from '@appmaker/core';

const FormSwitch = ({ attributes = {}, clientId }) => {
	const { label, description, name } = attributes;
	const [enabled, setEnabled] = useFormDataValue({
		clientId,
		name,
	});
	return (
		<Switch.Group as="div" className="flex items-start justify-between mb-4">
			<Switch
				checked={enabled}
				onChange={setEnabled}
				className={clsx(
					enabled ? 'bg-indigo-600' : 'bg-gray-200',
					'relative inline-flex h-5 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
				)}
			>
				<span
					aria-hidden="true"
					className={clsx(
						enabled ? 'translate-x-3' : 'translate-x-0',
						'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
					)}
				/>
			</Switch>
			<span className="flex flex-grow flex-col ml-3">
				<Switch.Label
					as="span"
					className="text-sm font-medium text-gray-900"
					passive
				>
					{label}
				</Switch.Label>
				<Switch.Description as="span" className="text-sm text-gray-500">
					{description}
				</Switch.Description>
			</span>
		</Switch.Group>
	);
};

export default FormSwitch;
