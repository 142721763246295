import {  SearchIcon } from '@heroicons/react/outline';
import AddTheme from './AddTheme';
import useThemeStore from '../store/list';
const Header = () => {
	const search = useThemeStore(store=>store.searchTheme);
	return (
		<div className="bg-white  shadow-sm grid grid-cols-2 sm:grid-cols-3 gap-4 px-4 py-2 items-center">
			<h2 className="text-md font-medium">Themes</h2>
			<div className="relative border border-gray-100 rounded-md text-gray-400 focus-within:text-gray-600">
				<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
					<SearchIcon className="h-5 w-5" aria-hidden="true" />
				</div>
				<input
					id="search"
					className="block w-full bg-gray-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
					placeholder="Search theme"
					type="search"
					name="search"
					onChange={(event) => {
						// searchExtension(event.target.value)
						search(event.target.value)
					}}
				/>
			</div>
			<AddTheme />
		</div>
	);
};

export default Header;
