import appmaker from '@appmaker/core/index';
import UploadStore from './routes/UploadStore';
import { CloudUploadIcon } from '@heroicons/react/solid';

const plugin = {
	id: '@appmaker/upload-store',
	name: 'Upload to Store',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/upload-to-store',
			component: UploadStore,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Upload to Store',
			routeName: '/upload-to-store',
			icon: CloudUploadIcon,
		});
	},
};

export default plugin;
