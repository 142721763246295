import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { BASE_URL } from '@appmaker/core/api/core';

export const getProductList = async ({ search } = {}) => {
    const projectId = getProjectId()
    let url = `${BASE_URL}/projects/${projectId}/api-proxy/products`;
    // let url = `http://localhost:3000/v2/projects/${projectId}/api-proxy/products`;
    if (search) {
        url += `?search=${search}`
    }
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
}