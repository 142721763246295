import { useEffect, useState } from 'react';
import api from '../api';
import { useProject } from '@appmaker/core/hooks';
import { setTransformList,useTransformList } from '../store';

const useTransform = ({ refetch = true } = {}) => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    const transformList = useTransformList(data => data.list);
    const isValueSet = useTransformList(data => data.setList);
    
    useEffect(() => {
        const fetchTransformList = async () => {
            setLoading(true);
            try {
                const response = await api.getTransformList({ projectId });
                setTransformList({ list: response.transforms, setList: true });
            } catch (error) {

            }
            finally {
                setLoading(false);
            }
        }
        if (!isValueSet || refetch) {
            fetchTransformList();
        }

    }, []);
    return {
        isLoading,
        transformList
    }
}

export {
    useTransform
}