import { useEffect, useState } from 'react';
import api from '../api';
import { useProject } from '@appmaker/core/hooks';
import { setActionsList, useActionsList as useActions } from '../store';

const useActionList = ({ refetch = true } = {}) => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    const actionList = useActions(data => data.list);
    const isValueSet = useActions(data => data.setList)
    useEffect(() => {
        const fetchActionList = async () => {
            setLoading(true);
            try {
                const response = await api.getActionList({ projectId });
                setActionsList({ list: response.actions, setList: true });

            } catch (error) {

            }
            finally {
                setLoading(false);
            }
        }
        if (!isValueSet || refetch) {
            fetchActionList();
        }

    }, []);
    return {
        isLoading,
        actionList
    }
}

export {
    useActionList
}