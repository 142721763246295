import React from 'react'
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import appmaker from '@appmaker/core/index';
const StateView = ({ json }) => {
    return <pre className="mt-2 border-2 p-2 bg-white">{JSON.stringify(json, null, 2)}</pre>
}
export default function ActionSelector(
    { setAction }
) {
    const actions = appmaker.actions.getAllActions();
    const schema = actions;
    const sendActionParams = (actionObject) => {
        console.log(actionObject)
        if (actionObject && actionObject.action) {
            const actionProperties = actions[actionObject.action];
            if (!actionProperties.properties) {
                return setAction(actionObject);
            }
            // to get the sub depended field name. different name for different action
            const subProperties = Object.keys(actions[actionObject.action].properties);
            setAction({ ...actionObject, params: actionObject[subProperties[0]] })
        }
        // setAction(formData)
    }
    return (
        <div>
            {/* <StateView json={action} /> */}
            <AppmakerForm
                options={actions}
                value={{ schema }}
                optionKey={'action'}
                setValue={({ formData }) => sendActionParams(formData)}
                debug={false}
            />
        </div>
    )
}
