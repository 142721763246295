import React from 'react';
import appmaker from '@appmaker/core/index';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import clsx from 'clsx';

const AppmakerActionFormItem = (props) => {
	const { onChange = () => {}, defaultValue, properties = {} } = props || {};
	const { className, title, label } = properties;
	const actions = appmaker.actions.getAllActions();
	const schema = actions;
	const setAction = (action) => {
		onChange(action);
	};
	return (
		<div
			className={clsx(
				'relative',
				className
					? className
					: 'border border-gray-200 p-2 rounded-md relative mt-2'
			)}
		>
			<span
				className={clsx(
					'font-regular text-lg mb-1 flex items-center space-x-1'
				)}
			>
				{title ?? label}
			</span>
			<AppmakerForm
				options={actions}
				value={{
					formData: defaultValue?.appmakerAction ?? {},
					_internal: defaultValue?.___internal_appmakerAction ?? {},
					schema,
				}}
				optionKey={'action'}
				setValue={({ formData, _internal }) => {
					setAction({
						appmakerAction: formData,
						___internal_appmakerAction: _internal,
					});
				}}
				debug={false}
			/>
		</div>
	);
};

export default AppmakerActionFormItem;
