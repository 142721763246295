import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project';
import api from '../api'
export async function registerServerActions() {
    const projectId = getProjectId();
    const resp = await api.getActionList({ projectId: projectId });
    resp.actions.forEach(action => {
        appmaker.actions.registerAction(
            action.actionId,
            {
                label: action.name,
            }
        )
    })
}