import { useState } from 'react';
import PricingModal from 'packages/Subscription/components/PricingModal';
import AppmakerSwitch from 'packages/appmaker-core/components/AppmakerForm/fields/AppmakerSwitch';
import Switch from '@appmaker/components/Form/fields/AppmakerSwitch';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import './style.css';
import { CalendarIcon } from '@heroicons/react/outline';

const Schedule = ({
	onEnableSchedule = false,
	enablePushSchedule = false,
	setScheduleTime = () => { },
	setEnableSchedule = () => { }
}) => {
	const [pricingModal, setPricingModal] = useState(false);
	const [value, onChange] = useState(undefined);
	// const [onEnableSchedule, setEnableSchedule] = useState(false);
	if (!enablePushSchedule) {
		return (
			<div>
				<AppmakerSwitch
					onChange={(data) => { }}
					defaultValue={false}
					properties={{
						title: 'Schedule Push Notification',
						label: '',
						className: 'mt-10',
						disable: true,
						addOnTag: 'Pro Plan Feature',
						upgradePlanAction: () => {
							setPricingModal(!pricingModal);
						},
					}}
					schema={{}}
				/>
				<PricingModal isOpen={pricingModal} setIsOpen={setPricingModal} />
			</div>
		);
	}
	return (
		<div className="mt-5 mb-5">
			<div className='flex items-center space-x-3'>
				<div className="mb-3">Schedule your notification</div>
				<Switch
					formData={onEnableSchedule}
					onChange={(data) => {
						setEnableSchedule(data ? data : false);
					}}
					schema={{
						className: 'mb-1',
					}}
				/>
			</div>
			{onEnableSchedule && (<DateTimePicker
				minDate={new Date()}
				maxDate={new Date(moment().add(30, 'days'))}
				label="DateTimePicker"
				className="p-1 border border-gray-300 rounded-md"
				value={value}
				calendarIcon={
					<CalendarIcon className="h-8 w-8 bg-gray-200 p-1 rounded-md" />
				}
				onChange={(newValue) => {
					onChange(newValue);
					setScheduleTime(newValue);
				}}
			/>)}
		</div>
	);
};

export default Schedule;
