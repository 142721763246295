function Spacer({ attributes }) {
	return (
		<div
			className="flex overflow-hidden  items-center justify-center"
			style={{
				height: parseInt(attributes.height) || 25,
				backgroundColor: attributes.backgroundColor || '#f5f5f5',
			}}
		>
			<span className="text-xs font-medium text-center p-1 select-none ">
				SPACER
			</span>
		</div>
	);
}
Spacer.attributesSchema = {
	properties: {
		height: {
			type: 'string',
			label: 'Height',
			default: '30',
		},
		backgroundColor: {
			type: 'string',
			label: 'Background Color',
			defaultColor: '#f5f5f5',
			source: 'attribute',
			uiType: 'ColorPicker',
		},
	},
};

export { Spacer };
