// @flow strict

import clsx from 'clsx';
import * as React from 'react';

const Loader = ({ single = false, small = false, fullWidth = false }) => {
	return (
		<div className={clsx("flex-1 overflow-hidden animate-pulse gap-4 flex flex-col", fullWidth ? 'm-0' : 'm-6')}>
			<div className={clsx("w-full bg-gray-200 rounded-lg", small ? 'h-12' : 'h-48')} />
			{!single && (
				<>
					<div className="h-48 w-full bg-gray-100 rounded-lg" />
					<div className="h-48 w-full bg-gray-50 rounded-lg" />
				</>
			)}
		</div>
	);
};

export default Loader;
