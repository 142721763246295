import { InspectorControls } from '@wordpress/block-editor';
import ReactSelect from 'react-select';

const LayoutIcon = ({ attributes, setAttributes }) => {
	if (attributes.svgXml) {
		return <div dangerouslySetInnerHTML={{ __html: attributes.svgXml }} />;
	}
	return (
		<>
			{attributes.iconName ? (
				<span
					style={{
						color: attributes.iconColor,
						fontSize: 14,
						padding: attributes.tabBar ? 0 : 6,
					}}
					className="text-center block"
				>
					<svg
						width={attributes.iconSize || 24}
						height={attributes.iconSize || 24}
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<use href={`/feather-sprite.svg#${attributes.iconName}`} />
					</svg>
					{/* {attributes.iconName} */}
				</span>
			) : (
				<span className="text-xs font-medium">Select icon</span>
			)}
			<InspectorControls>
				<label htmlFor="iconName" className="text-sm font-medium">
					Select Icon
				</label>
				<ReactSelect
					name="iconName"
					options={ICON_NAMES}
					isSearchable={true}
					value={ICON_NAMES.find(
						(value) => value.value === attributes.iconName
					)}
					onChange={(value) => {
						setAttributes({ iconName: value.value });
					}}
				/>
			</InspectorControls>
		</>
	);
};
LayoutIcon.attributesSchema = {
	properties: {
		iconColor: {
			type: 'ColorPicker',
			label: 'Icon Color',
			title: 'Icon Color',
			default: '#212121',
		},
		iconSize: {
			type: 'string',
			uiType: 'RangeSlider',
			label: 'Icon Size',
			default: 24,
			className: 'mt-4',
		},
		svgXml: {
			type: 'string',
			label: 'Xml SVG',
			default: '',
			inputType: 'textArea',
		},
		tabBar: {
			type: 'string',
			label: 'Disable Padding',
			default: '',
			uiType: 'Switch',
		},
	},
};

export default LayoutIcon;

const ICON_NAMES = [
	{ value: 'activity', label: 'activity' },
	{ value: 'airplay', label: 'airplay' },
	{ value: 'alert-circle', label: 'alert-circle' },
	{ value: 'alert-octagon', label: 'alert-octagon' },
	{ value: 'alert-triangle', label: 'alert-triangle' },
	{ value: 'align-center', label: 'align-center' },
	{ value: 'align-justify', label: 'align-justify' },
	{ value: 'align-left', label: 'align-left' },
	{ value: 'align-right', label: 'align-right' },
	{ value: 'anchor', label: 'anchor' },
	{ value: 'aperture', label: 'aperture' },
	{ value: 'archive', label: 'archive' },
	{ value: 'arrow-down-circle', label: 'arrow-down-circle' },
	{ value: 'arrow-down-left', label: 'arrow-down-left' },
	{ value: 'arrow-down-right', label: 'arrow-down-right' },
	{ value: 'arrow-down', label: 'arrow-down' },
	{ value: 'arrow-left-circle', label: 'arrow-left-circle' },
	{ value: 'arrow-left', label: 'arrow-left' },
	{ value: 'arrow-right-circle', label: 'arrow-right-circle' },
	{ value: 'arrow-right', label: 'arrow-right' },
	{ value: 'arrow-up-circle', label: 'arrow-up-circle' },
	{ value: 'arrow-up-left', label: 'arrow-up-left' },
	{ value: 'arrow-up-right', label: 'arrow-up-right' },
	{ value: 'arrow-up', label: 'arrow-up' },
	{ value: 'at-sign', label: 'at-sign' },
	{ value: 'award', label: 'award' },
	{ value: 'bar-chart-2', label: 'bar-chart-2' },
	{ value: 'bar-chart', label: 'bar-chart' },
	{ value: 'battery-charging', label: 'battery-charging' },
	{ value: 'battery', label: 'battery' },
	{ value: 'bell-off', label: 'bell-off' },
	{ value: 'bell', label: 'bell' },
	{ value: 'bluetooth', label: 'bluetooth' },
	{ value: 'bold', label: 'bold' },
	{ value: 'book-open', label: 'book-open' },
	{ value: 'book', label: 'book' },
	{ value: 'bookmark', label: 'bookmark' },
	{ value: 'box', label: 'box' },
	{ value: 'briefcase', label: 'briefcase' },
	{ value: 'calendar', label: 'calendar' },
	{ value: 'camera-off', label: 'camera-off' },
	{ value: 'camera', label: 'camera' },
	{ value: 'cast', label: 'cast' },
	{ value: 'check-circle', label: 'check-circle' },
	{ value: 'check-square', label: 'check-square' },
	{ value: 'check', label: 'check' },
	{ value: 'chevron-down', label: 'chevron-down' },
	{ value: 'chevron-left', label: 'chevron-left' },
	{ value: 'chevron-right', label: 'chevron-right' },
	{ value: 'chevron-up', label: 'chevron-up' },
	{ value: 'chevrons-down', label: 'chevrons-down' },
	{ value: 'chevrons-left', label: 'chevrons-left' },
	{ value: 'chevrons-right', label: 'chevrons-right' },
	{ value: 'chevrons-up', label: 'chevrons-up' },
	{ value: 'chrome', label: 'chrome' },
	{ value: 'circle', label: 'circle' },
	{ value: 'clipboard', label: 'clipboard' },
	{ value: 'clock', label: 'clock' },
	{ value: 'cloud-drizzle', label: 'cloud-drizzle' },
	{ value: 'cloud-lightning', label: 'cloud-lightning' },
	{ value: 'cloud-off', label: 'cloud-off' },
	{ value: 'cloud-rain', label: 'cloud-rain' },
	{ value: 'cloud-snow', label: 'cloud-snow' },
	{ value: 'cloud', label: 'cloud' },
	{ value: 'code', label: 'code' },
	{ value: 'codepen', label: 'codepen' },
	{ value: 'codesandbox', label: 'codesandbox' },
	{ value: 'coffee', label: 'coffee' },
	{ value: 'columns', label: 'columns' },
	{ value: 'command', label: 'command' },
	{ value: 'compass', label: 'compass' },
	{ value: 'copy', label: 'copy' },
	{ value: 'corner-down-left', label: 'corner-down-left' },
	{ value: 'corner-down-right', label: 'corner-down-right' },
	{ value: 'corner-left-down', label: 'corner-left-down' },
	{ value: 'corner-left-up', label: 'corner-left-up' },
	{ value: 'corner-right-down', label: 'corner-right-down' },
	{ value: 'corner-right-up', label: 'corner-right-up' },
	{ value: 'corner-up-left', label: 'corner-up-left' },
	{ value: 'corner-up-right', label: 'corner-up-right' },
	{ value: 'cpu', label: 'cpu' },
	{ value: 'credit-card', label: 'credit-card' },
	{ value: 'crop', label: 'crop' },
	{ value: 'crosshair', label: 'crosshair' },
	{ value: 'database', label: 'database' },
	{ value: 'delete', label: 'delete' },
	{ value: 'disc', label: 'disc' },
	{ value: 'divide-circle', label: 'divide-circle' },
	{ value: 'divide-square', label: 'divide-square' },
	{ value: 'divide', label: 'divide' },
	{ value: 'dollar-sign', label: 'dollar-sign' },
	{ value: 'download-cloud', label: 'download-cloud' },
	{ value: 'download', label: 'download' },
	{ value: 'dribbble', label: 'dribbble' },
	{ value: 'droplet', label: 'droplet' },
	{ value: 'edit-2', label: 'edit-2' },
	{ value: 'edit-3', label: 'edit-3' },
	{ value: 'edit', label: 'edit' },
	{ value: 'external-link', label: 'external-link' },
	{ value: 'eye-off', label: 'eye-off' },
	{ value: 'eye', label: 'eye' },
	{ value: 'facebook', label: 'facebook' },
	{ value: 'fast-forward', label: 'fast-forward' },
	{ value: 'feather', label: 'feather' },
	{ value: 'figma', label: 'figma' },
	{ value: 'file-minus', label: 'file-minus' },
	{ value: 'file-plus', label: 'file-plus' },
	{ value: 'file-text', label: 'file-text' },
	{ value: 'file', label: 'file' },
	{ value: 'film', label: 'film' },
	{ value: 'filter', label: 'filter' },
	{ value: 'flag', label: 'flag' },
	{ value: 'folder-minus', label: 'folder-minus' },
	{ value: 'folder-plus', label: 'folder-plus' },
	{ value: 'folder', label: 'folder' },
	{ value: 'framer', label: 'framer' },
	{ value: 'frown', label: 'frown' },
	{ value: 'gift', label: 'gift' },
	{ value: 'git-branch', label: 'git-branch' },
	{ value: 'git-commit', label: 'git-commit' },
	{ value: 'git-merge', label: 'git-merge' },
	{ value: 'git-pull-request', label: 'git-pull-request' },
	{ value: 'github', label: 'github' },
	{ value: 'gitlab', label: 'gitlab' },
	{ value: 'globe', label: 'globe' },
	{ value: 'grid', label: 'grid' },
	{ value: 'hard-drive', label: 'hard-drive' },
	{ value: 'hash', label: 'hash' },
	{ value: 'headphones', label: 'headphones' },
	{ value: 'heart', label: 'heart' },
	{ value: 'help-circle', label: 'help-circle' },
	{ value: 'hexagon', label: 'hexagon' },
	{ value: 'home', label: 'home' },
	{ value: 'image', label: 'image' },
	{ value: 'inbox', label: 'inbox' },
	{ value: 'info', label: 'info' },
	{ value: 'instagram', label: 'instagram' },
	{ value: 'italic', label: 'italic' },
	{ value: 'key', label: 'key' },
	{ value: 'layers', label: 'layers' },
	{ value: 'layout', label: 'layout' },
	{ value: 'life-buoy', label: 'life-buoy' },
	{ value: 'link-2', label: 'link-2' },
	{ value: 'link', label: 'link' },
	{ value: 'linkedin', label: 'linkedin' },
	{ value: 'list', label: 'list' },
	{ value: 'loader', label: 'loader' },
	{ value: 'lock', label: 'lock' },
	{ value: 'log-in', label: 'log-in' },
	{ value: 'log-out', label: 'log-out' },
	{ value: 'mail', label: 'mail' },
	{ value: 'map-pin', label: 'map-pin' },
	{ value: 'map', label: 'map' },
	{ value: 'maximize-2', label: 'maximize-2' },
	{ value: 'maximize', label: 'maximize' },
	{ value: 'meh', label: 'meh' },
	{ value: 'menu', label: 'menu' },
	{ value: 'message-circle', label: 'message-circle' },
	{ value: 'message-square', label: 'message-square' },
	{ value: 'mic-off', label: 'mic-off' },
	{ value: 'mic', label: 'mic' },
	{ value: 'minimize-2', label: 'minimize-2' },
	{ value: 'minimize', label: 'minimize' },
	{ value: 'minus-circle', label: 'minus-circle' },
	{ value: 'minus-square', label: 'minus-square' },
	{ value: 'minus', label: 'minus' },
	{ value: 'monitor', label: 'monitor' },
	{ value: 'moon', label: 'moon' },
	{ value: 'more-horizontal', label: 'more-horizontal' },
	{ value: 'more-vertical', label: 'more-vertical' },
	{ value: 'mouse-pointer', label: 'mouse-pointer' },
	{ value: 'move', label: 'move' },
	{ value: 'music', label: 'music' },
	{ value: 'navigation-2', label: 'navigation-2' },
	{ value: 'navigation', label: 'navigation' },
	{ value: 'octagon', label: 'octagon' },
	{ value: 'package', label: 'package' },
	{ value: 'paperclip', label: 'paperclip' },
	{ value: 'pause-circle', label: 'pause-circle' },
	{ value: 'pause', label: 'pause' },
	{ value: 'pen-tool', label: 'pen-tool' },
	{ value: 'percent', label: 'percent' },
	{ value: 'phone-call', label: 'phone-call' },
	{ value: 'phone-forwarded', label: 'phone-forwarded' },
	{ value: 'phone-incoming', label: 'phone-incoming' },
	{ value: 'phone-missed', label: 'phone-missed' },
	{ value: 'phone-off', label: 'phone-off' },
	{ value: 'phone-outgoing', label: 'phone-outgoing' },
	{ value: 'phone', label: 'phone' },
	{ value: 'pie-chart', label: 'pie-chart' },
	{ value: 'play-circle', label: 'play-circle' },
	{ value: 'play', label: 'play' },
	{ value: 'plus-circle', label: 'plus-circle' },
	{ value: 'plus-square', label: 'plus-square' },
	{ value: 'plus', label: 'plus' },
	{ value: 'pocket', label: 'pocket' },
	{ value: 'power', label: 'power' },
	{ value: 'printer', label: 'printer' },
	{ value: 'radio', label: 'radio' },
	{ value: 'refresh-ccw', label: 'refresh-ccw' },
	{ value: 'refresh-cw', label: 'refresh-cw' },
	{ value: 'repeat', label: 'repeat' },
	{ value: 'rewind', label: 'rewind' },
	{ value: 'rotate-ccw', label: 'rotate-ccw' },
	{ value: 'rotate-cw', label: 'rotate-cw' },
	{ value: 'rss', label: 'rss' },
	{ value: 'save', label: 'save' },
	{ value: 'scissors', label: 'scissors' },
	{ value: 'search', label: 'search' },
	{ value: 'send', label: 'send' },
	{ value: 'server', label: 'server' },
	{ value: 'settings', label: 'settings' },
	{ value: 'share-2', label: 'share-2' },
	{ value: 'share', label: 'share' },
	{ value: 'shield-off', label: 'shield-off' },
	{ value: 'shield', label: 'shield' },
	{ value: 'shopping-bag', label: 'shopping-bag' },
	{ value: 'shopping-cart', label: 'shopping-cart' },
	{ value: 'shuffle', label: 'shuffle' },
	{ value: 'sidebar', label: 'sidebar' },
	{ value: 'skip-back', label: 'skip-back' },
	{ value: 'skip-forward', label: 'skip-forward' },
	{ value: 'slack', label: 'slack' },
	{ value: 'slash', label: 'slash' },
	{ value: 'sliders', label: 'sliders' },
	{ value: 'smartphone', label: 'smartphone' },
	{ value: 'smile', label: 'smile' },
	{ value: 'speaker', label: 'speaker' },
	{ value: 'square', label: 'square' },
	{ value: 'star', label: 'star' },
	{ value: 'stop-circle', label: 'stop-circle' },
	{ value: 'sun', label: 'sun' },
	{ value: 'sunrise', label: 'sunrise' },
	{ value: 'sunset', label: 'sunset' },
	{ value: 'table', label: 'table' },
	{ value: 'tablet', label: 'tablet' },
	{ value: 'tag', label: 'tag' },
	{ value: 'target', label: 'target' },
	{ value: 'terminal', label: 'terminal' },
	{ value: 'thermometer', label: 'thermometer' },
	{ value: 'thumbs-down', label: 'thumbs-down' },
	{ value: 'thumbs-up', label: 'thumbs-up' },
	{ value: 'toggle-left', label: 'toggle-left' },
	{ value: 'toggle-right', label: 'toggle-right' },
	{ value: 'tool', label: 'tool' },
	{ value: 'trash-2', label: 'trash-2' },
	{ value: 'trash', label: 'trash' },
	{ value: 'trello', label: 'trello' },
	{ value: 'trending-down', label: 'trending-down' },
	{ value: 'trending-up', label: 'trending-up' },
	{ value: 'triangle', label: 'triangle' },
	{ value: 'truck', label: 'truck' },
	{ value: 'tv', label: 'tv' },
	{ value: 'twitch', label: 'twitch' },
	{ value: 'twitter', label: 'twitter' },
	{ value: 'type', label: 'type' },
	{ value: 'umbrella', label: 'umbrella' },
	{ value: 'underline', label: 'underline' },
	{ value: 'unlock', label: 'unlock' },
	{ value: 'upload-cloud', label: 'upload-cloud' },
	{ value: 'upload', label: 'upload' },
	{ value: 'user-check', label: 'user-check' },
	{ value: 'user-minus', label: 'user-minus' },
	{ value: 'user-plus', label: 'user-plus' },
	{ value: 'user-x', label: 'user-x' },
	{ value: 'user', label: 'user' },
	{ value: 'users', label: 'users' },
	{ value: 'video-off', label: 'video-off' },
	{ value: 'video', label: 'video' },
	{ value: 'voicemail', label: 'voicemail' },
	{ value: 'volume-1', label: 'volume-1' },
	{ value: 'volume-2', label: 'volume-2' },
	{ value: 'volume-x', label: 'volume-x' },
	{ value: 'volume', label: 'volume' },
	{ value: 'watch', label: 'watch' },
	{ value: 'wifi-off', label: 'wifi-off' },
	{ value: 'wifi', label: 'wifi' },
	{ value: 'wind', label: 'wind' },
	{ value: 'x-circle', label: 'x-circle' },
	{ value: 'x-octagon', label: 'x-octagon' },
	{ value: 'x-square', label: 'x-square' },
	{ value: 'x', label: 'x' },
	{ value: 'youtube', label: 'youtube' },
	{ value: 'zap-off', label: 'zap-off' },
	{ value: 'zap', label: 'zap' },
	{ value: 'zoom-in', label: 'zoom-in' },
	{ value: 'zoom-out', label: 'zoom-out' },
];
