import {
	createStore,
	AppmakerFormProvider,
	useStore,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import clsx from 'clsx';

const FormSchema = ({ schema, onChange = () => {} }) => {
	const storeData = useStore();

	useEffect(() => {
		onChange(storeData.formData);
	}, [storeData.formData]);

	return (
		<div className="-mt-2">
			<AppmakerForm formVersion={2} schema={schema} />
		</div>
	);
};

const EditForm = ({ schema = {}, onChange = () => {} }) => {
	return <FormSchema schema={schema} onChange={onChange} />;
};

const Group = ({ properties = {}, defaultValue = {}, onChange = () => {} }) => {
	const {
		options: formOptions = [],
		id,
		label,
		description,
		openByDefault = false,
	} = properties;
	const [isOpen, setIsOpen] = useState(openByDefault === true);

	const store = createStore({
		formData: {
			...defaultValue,
		},
	});

	return (
		<div
			className={clsx('rounded-md mt-3 border border-transparent', {
				'border-gray-300': isOpen,
			})}
		>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className={clsx(
					'px-3 py-1.5 bg-gray-100 flex items-center justify-between w-full',
					isOpen ? 'rounded-t-md' : 'rounded-md'
				)}
			>
				<div className="flex-1 text-left">
					<p className="text-gray-800 font-medium">{label}</p>
					<p className="text-gray-500 text-xs">{description}</p>
				</div>
				{isOpen ? (
					<ChevronUpIcon className="h-5 w-5 text-gray-600" />
				) : (
					<ChevronDownIcon className="h-5 w-5 text-gray-600" />
				)}
			</button>
			{isOpen ? (
				<div className="p-2">
					<AppmakerFormProvider createStore={store}>
						<EditForm schema={properties} onChange={onChange} />
					</AppmakerFormProvider>
				</div>
			) : null}
		</div>
	);
};

export default Group;
