import { registerBlockTypes } from './blocks';

const EcommerceBlocks = {
    id: '@appmaker/old-blocks',
    name: "Old UI blocks",
    activate: () => {
        registerBlockTypes()
    }
}

export default EcommerceBlocks;