import React from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useMediaProps } from '../../context/MediaLibrary';

export default function Tabs({ items }) {
	const mediaProps = useMediaProps();
	return (
		<div className="block">
			<Tab.Group>
				<Tab.List
					className="-mb-px flex space-x-8 bg-gray-50 px-4"
					aria-label="Tabs"
				>
					{items.map((MediaLibraryItem) => (
						<Tab
							key={MediaLibraryItem.media.title}
							className={({ selected }) =>
								classNames(
									'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm',
									selected
										? 'border-indigo-500 text-indigo-600'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
								)
							}
						>
							{MediaLibraryItem.media.title}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="p-4" aria-label="Tabs">
					{items.map((MediaLibraryItem) => (
						<Tab.Panel key={MediaLibraryItem.media.title}>
							<MediaLibraryItem {...mediaProps} />
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
}
