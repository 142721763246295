import { useProject } from "@appmaker/core/hooks";
import { isAdmin } from "@appmaker/core/store/user";
import { useEffect } from "react";

const AdminTab = () => {
    const { projectId } = useProject();

    useEffect(() => {
        if (isAdmin()) {
            window.location.href = `https://manage-v2.appmaker.xyz/admin/${projectId}`
        }
    }, [])
    return null
}


export default AdminTab;