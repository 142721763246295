import { entity } from 'simpler-state';

const transforms = entity({
    list: [],
    setList: false
})
 


const useTransformList = (args) => transforms.use(args);
const setTransformList = (data) => transforms.set(value => ({ ...value, ...data }));

export {
    useTransformList,
    setTransformList
}