import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import {
  createStore,
  AppmakerFormProvider,
  useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';

const WorkingTest = () => {
  const [num, setNum] = useQueryParam('x', NumberParam);
  const [foo, setFoo] = useQueryParam('foo', StringParam);

  return (
    <div>
      <h1>num is {num}</h1>
      <button onClick={() => setNum(Math.random())}>Change</button>
      <h1>foo is {foo}</h1>
      <button onClick={() => setFoo(`str${Math.random()}`)}>Change</button>
    </div>
  )
}
const schema1 = {
  "properties": {
    "show_free_gift_banner": {
      "type": "string",
      "label": "Show Free gift banner",
      "uiType": "switch"
    },
    "free_gifts": {
      "type": "array",
      "uiType": "infinite-form",
      "label": "Configure free gift",
      "properties": {
        "status": {
          "type": "string",
          "label": "Enable",
          "uiType": "switch"
        },
        "name": {
          "type": "string",
          "label": "Name "
        },
        "type": {
          "type": "string",
          "title": "Type ",
          "uiType": "RadioGroup",
          "options": [
            {
              "label": "Always",
              "value": "always"
            },
            {
              "label": "Cart Value",
              "value": "cart_value"
            }
          ]
        },
        "cart_value_amount": {
          "type": "string",
          "label": "Amount"
        },
        "products": {
          "type": "array",
          "uiType": "infinite-form",
          "label": "Products",
          "properties": {
            "product_gid": {
              "type": "string",
              "label": "Product Id"
            },
            "variant_gid": {
              "type": "string",
              "label": "Variant Id"
            },
            "productId": {
              "destructuring": true,
              "type": "shopify-custom-product-variant",
              "label": "Products",
              "description": "Choose an product",
            }
          }
        }
      }
    }
  }
}
const schema = {
  properties: {
    "name": {
      "type": "string",
      "label": "Name "
    },
    "product_gid": {
      "type": "shopify-custom-product-variant",
      "showProductOnly": true,
      "label": "Products",
      "description": "Choose an product"
    },
    "variant_gid": {
      "type": "shopify-custom-product-variant",
      "showVariantOnly": true,
      "label": "Products",
      "description": "Choose an product",
      "parentKey": "product_gid",
      "dependentErrorMessage": "Please choose the product to select the variant"
    }
  }
}
const SaveButton = () => {
  const storeApi = useAppmakerFormStoreApi();

  const onClick = () => {
    const { formData } = storeApi.getState();
    console.log({ formData }, 'xxxx')
  }
  return (
    <button
      onClick={onClick}
      className='my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
    >
      Save
    </button>
  )
}
const FormSchema = ({ schema, projectId, metaKey }) => {

  return (
    <div className="bg-gray-50 h-full p-6">
      <div className="max-w-3xl">
        <AppmakerForm
          formVersion={2}
          // value={{ formData: flatten(formData) }}
          schema={schema}
        />
        <SaveButton metaKey={metaKey} />
      </div>
    </div>
  );
}


const Forms = () => {
  const data = {
    name:'test_name',
    product_gid: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3OTMyOTUxNjc2NTA=',
    variant_gid: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDAxODE4NDczMjgzNA=='
  };


  return <div
    className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
  ><AppmakerFormProvider
    createStore={createStore({
      formData: data,
    })}
  >
      <FormSchema schema={schema} />
    </AppmakerFormProvider></div>
}

export default Forms;