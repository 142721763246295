import React, { Fragment, useEffect, useState } from 'react';
import { ArrowLeftIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import { LoaderIcon } from 'react-hot-toast';
import { useParams, useHistory } from '@appmaker/core/routes';
import { useQuery, useMutation } from '@apollo/client';
import { GET_EXTENSION_INSTALLS, POST_EXTENSION_INSTALL } from '../api/graphql';
import Loader from '@appmaker/components/Loading';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import Notification, {
	sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';
import moment from 'moment';
import useProjectData from '@appmaker/core/hooks/useProjectData';

const ActivateButton = ({ isLoading, isComponentLoading = () => {} }) => {
	const { handle, projectId } = useParams();
	const history = useHistory();
	const getRouterPath = useRouterPath();
	const [installAndActivate, { data, error, loading }] = useMutation(
		POST_EXTENSION_INSTALL,
		{
			context: {
				headers: {
					'x-appmaker-project-id': projectId,
				},
			},
		}
	);

	const disableButtons = isLoading || loading;
	const onClickInstall = async () => {
		try {
			await installAndActivate({
				variables: {
					data: {
						status: 'active',
						extension: {
							connect: {
								handle: handle,
							},
						},
						project: {
							connect: {
								remoteProjectId: parseInt(projectId, 10),
							},
						},
					},
				},
			});
		} catch (error) {
			sendNotification('Something went wrong', 'error');
		}
	};
	useEffect(() => {
		if (data?.createExtensionInstall?.id) {
			history.push(getRouterPath(`/extension/${handle}`));
		}
	}, [data]);

	useEffect(() => {
		isComponentLoading(loading);
	}, [loading]);
	return (
		<>
			<button
				className="bg-gray-100 hover:bg-gray-200 rounded-md px-4 py-2 text-sm text-gray-600"
				onClick={() => {}}
				disabled={disableButtons}
			>
				Cancel
			</button>
			<button
				className="bg-gray-900 hover:bg-gray-800 rounded-md px-4 py-2 text-sm text-white"
				onClick={() => onClickInstall()}
				disabled={disableButtons}
			>
				{loading ? (
					<LoaderIcon className="h-5 w-5 animate-spin mx-11" />
				) : (
					'Install & Activate'
				)}
			</button>
		</>
	);
};

const ExtensionInstall = () => {
	const [isInstalling, setInstalling] = useState(false);
	const { handle, projectId } = useParams();
	const history = useHistory();
	const getRouterPath = useRouterPath();
	const { projectData } = useProjectData({ projectId });
	const { loading, data, error } = useQuery(GET_EXTENSION_INSTALLS, {
		variables: {
			installWhere: {
				project: {
					remoteProjectId: {
						equals: parseInt(projectId, 10),
					},
				},
				extension: {
					handle: {
						equals: handle,
					},
				},
			},
			extensionWhere: {
				handle: handle,
			},
		},
		context: {
			headers: {
				'x-appmaker-project-id': projectId,
			},
		},
	});
	const [
		installExtension,
		{ data: installingData, loading: isInstallingOnly },
	] = useMutation(POST_EXTENSION_INSTALL);

	const isLoading = loading;
	const extension = data?.extension;

	const skipToDetailPage = data?.extensionInstalls?.length > 0;

	const onClickInstallOnly = async () => {
		try {
			await installExtension({
				variables: {
					data: {
						status: 'in_active',
						extension: {
							connect: {
								handle: handle,
							},
						},
						project: {
							connect: {
								remoteProjectId: parseInt(projectId, 10),
							},
						},
					},
				},
				context: {
					headers: {
						'x-appmaker-project-id': projectId,
					},
				},
			});
		} catch (error) {
			sendNotification('Something went wrong', 'error');
		}
	};
	/**
	 * Already installed
	 */
	useEffect(() => {
		if (skipToDetailPage) {
			history.push(getRouterPath(`/extension/${handle}`));
		}
	}, [skipToDetailPage]);
	/**
	 * Installing only
	 */
	useEffect(() => {
		if (installingData?.createExtensionInstall?.id) {
			history.push(getRouterPath(`/extension/${handle}`));
		}
	}, [installingData]);
	return (
		<Notification>
			<div className="max-w-xl mx-auto py-6">
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center space-x-2">
						<button
							className="p-2 rounded hover:bg-gray-300"
							onClick={() => {
								// back button
								history?.goBack();
							}}
						>
							<ArrowLeftIcon className="h-5 w-5 text-gray-500" />
						</button>
						<p>Activate Extension</p>
					</div>
					<div className="flex items-center space-x-1">
						<ActivateButton
							isLoading={isLoading || isInstalling}
							isComponentLoading={setInstalling}
						/>
						<Menu as="div" className="relative inline-block text-left">
							<Menu.Button
								className="p-2 rounded hover:bg-gray-300"
								disabled={isLoading || isInstalling}
							>
								<DotsVerticalIcon className="h-5 w-5 text-gray-500" />
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
									<div className="py-1">
										<Menu.Item>
											{({ active }) => (
												<button
													className={`${
														active ? 'bg-gray-100' : ''
													} block px-4 py-2 text-sm text-gray-700 w-full text-left`}
													disabled={isInstalling || isInstallingOnly}
													onClick={() => {
														onClickInstallOnly();
													}}
												>
													Install
												</button>
											)}
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
				{isLoading && (
					<div>
						<Loader single />
					</div>
				)}
				{!isLoading && (
					<div className="bg-white p-4 rounded-md border border-gray-200">
						<h1 className="text-xl">{extension?.name}</h1>
						<p className="text-xs text-gray-500">by Appmaker</p>
						<div className="mx-auto flex items-center justify-center my-6">
							<img
								className="w-24 h-24 rounded-lg"
								src={extension?.icon}
								alt={extension?.name}
							/>
							<div className="flex items-center mx-2">
								<div className="h-2 w-2 rounded-full bg-gray-300 ring-1 ring-gray-300" />
								<div className="w-24 h-0.5 bg-gray-300" />
								<div className="h-2 w-2 rounded-full bg-gray-300 ring-1 ring-gray-300" />
							</div>
							<img
								className="w-24 h-24 rounded-lg"
								src={
									projectData?.icon ||
									'https://storage.googleapis.com/appilder_cdn/appmaker-logo-color%201%20(1)%20(1).png'
								}
								alt="appmaker"
							/>
						</div>
						<div className="border-t border-b border-gray-100 py-4 mb-6">
							<h4>About Extension</h4>
							<p className="text-sm text-gray-500">{extension?.description}</p>
						</div>
						<div className="grid grid-cols-4 gap-4">
							<div className="bg-gray-100 p-2 rounded-md">
								<p className="text-xs text-gray-500">Version</p>
								<p className="text-sm text-gray-900">
									{extension?.packageVersion ?? 'latest'}
								</p>
							</div>

							<div className="bg-gray-100 p-2 rounded-md">
								<p className="text-xs text-gray-500">Last Updated</p>
								<p className="text-sm text-gray-900">
									{extension?.updatedAt
										? moment(extension?.updatedAt)?.fromNow()
										: ''}
								</p>
							</div>

							{/* <div className="bg-gray-100 p-2 rounded-md">
								<p className="text-xs text-gray-500">Requires</p>
								<p className="text-sm text-gray-900">Appmaker 5.0+</p>
							</div> */}

							{/* <div className="bg-gray-100 p-2 rounded-md">
								<p className="text-xs text-gray-500">Active Installs</p>
								<p className="text-sm text-gray-900">100</p>
							</div> */}

							{/* <div className="bg-gray-100 p-2 rounded-md col-span-2">
								<p className="text-xs text-gray-500">Tags</p>
								<p className="text-sm text-gray-900">Sales, Marketing,</p>
							</div> */}

							{/* <div className="bg-gray-100 p-2 rounded-md">
								<p className="text-xs text-gray-500">Homepage</p>
								<p className="text-sm text-gray-900">
									<a
										href="https://appmaker.xyz"
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600"
									>
										App Name
									</a>
								</p>
							</div> */}
						</div>
					</div>
				)}
				<div className="flex items-center space-x-1 justify-end mt-4">
					<ActivateButton
						isLoading={isLoading || isInstalling || isInstallingOnly}
						isComponentLoading={setInstalling}
					/>
				</div>
			</div>
		</Notification>
	);
};

export default ExtensionInstall;
