// import './wdyr'; // <--- first import
import './loadPlugins'; // <--- first import
import './loadExtension';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// import { CaptureConsole } from '@sentry/integrations';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './graphql';

if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
	// Sentry.init({
	// 	dsn: 'https://984f95ecb49b4472b393b530d2880e91@o419376.ingest.sentry.io/6420782',
	// 	integrations: [
	// 		// new Integrations.BrowserTracing(),
	// 		// new CaptureConsole({
	// 		// 	levels: ['error'],
	// 		// }),
	// 	],
	// 	// Set tracesSampleRate to 1.0 to capture 100%
	// 	// of transactions for performance monitoring.
	// 	// We recommend adjusting this value in production
	// 	tracesSampleRate: 0.4,
	// });
}

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={apolloClient}>
			<App />
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
