import { useFormData } from '@appmaker/core/index';
import { InnerBlocks } from '@wordpress/block-editor';
import loadash from 'lodash';

const ConditionBlock = ({ attributes: { name } }) => {
	return (
		<div className="w-full border border-purple-500 relative flex flex-col">
			<p className="text-center bg-purple-500 text-white text-sm inline-flex self-start px-2 rounded-br-md">
				{name ? name : 'Condition Block'}
			</p>
			<div className="p-1">
				<InnerBlocks allowedBlocks={['appmaker/app-condition-block-item']} />
			</div>
		</div>
	);
};

const ConditionBlockItem = ({
	attributes: { name, conditions, condition_join_type },
}) => {
	return (
		<div className="w-full border border-purple-800 relative flex flex-col">
			<p className="text-center bg-purple-800 text-white inline-flex text-xs self-start px-2 rounded-br-md">
				{name ? name : 'Condition'}
			</p>
			<div className="p-1">
				<InnerBlocks />
			</div>
		</div>
	);
};

ConditionBlock.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			label: 'Name',
		},
	},
};

ConditionBlockItem.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			label: 'Name',
		},
		condition_type: {
			type: 'string',
			label: 'When',
			uiType: 'select',
			options: [
				{
					label: 'Guest User',
					value: 'guest_user',
				},
				{
					label: 'User Logged In',
					value: 'user_logged_in',
				},
				{
					label: 'Dark Mode',
					value: 'dark_mode',
				},
				{
					label: 'Light Mode',
					value: 'light_mode',
				},
				{
					label: 'User Tags Contain',
					value: 'use_user_tags_contain',
				},
				{
					label: 'User Tags Do Not Contain',
					value: 'user_tags_not_contain',
				},
				{
					label: 'Metafields',
					value: 'use_metafields',
				},
			],
		},
		user_tags: {
			type: 'string',
			label: 'User Tags',
			description: 'Comma separated list of tags to check',
			inputType: 'textArea',
			rows: 4,
		},
		has_all_tags: {
			type: 'switch',
			label: 'User must have all tags',
		},
		metafields: {
			type: 'array',
			uiType: 'infinite-form',
			label: 'Metafields',
			properties: {
				namespace: {
					type: 'string',
					label: 'Namespace',
				},
				key: {
					type: 'string',
					label: 'Key',
				},
				value: {
					type: 'string',
					label: 'Value',
				},
				compare: {
					id: 'comparator',
					type: 'string',
					uiType: 'select',
					label: 'Comparator',
					options: [
						{
							label: 'Greater Than',
							value: 'gt',
						},
						{
							label: 'Less Than',
							value: 'lt',
						},
						{
							label: 'Equal To',
							value: 'eq',
						},
						{
							label: 'Not Equal To',
							value: 'neq',
						},
						{
							label: 'Greater Than or Equal To',
							value: 'gteq',
						},
						{
							label: 'Less Than or Equal To',
							value: 'lteq',
						},
					],
				},
			},
		},
		raw_condition: {
			type: 'string',
			label: 'Raw Condition',
		},
	},
};

// export const ConditionBlockView = (props) => {
// 	const { block, children } = props;
// 	return children;
// 	return (
// 		<div>
// 			<p className="text-center bg-gray-100">Condition Block View</p>
// 			{/* <pre>{JSON.stringify(innerBlocks,null,2)}</pre> */}
// 			{children}
// 		</div>
// 	);
// };
// const sampleConditionAttr = {
// 	condition: 'condition',
// 	conditions: [
// 		{
// 			condition_type: 'equal',
// 			condition_item: 'giftType',
// 			value: 'cart_value',
// 		},
// 	],
// 	condition_join_type: 'or',
// };
function isMatchConditionItem(condition, formData) {
	const { condition_type, condition_item, value } = condition;
	const formValue = loadash.get(formData, condition_item);
	if (condition_type === 'equal') {
		return formValue === value;
	}
	return formValue !== value;
}
function isMatchCondition(condition, formData) {
	const { condition_join_type, conditions } = condition;
	if (condition_join_type === 'or') {
		return conditions.some((condition) =>
			isMatchConditionItem(condition, formData)
		);
	}
	return conditions.every((condition) =>
		isMatchConditionItem(condition, formData)
	);
}
export const ConditionBlockItemView = (props) => {
	const { block, children, attributes } = props;
	const formData = useFormData();
	const isMatch = isMatchCondition(attributes, formData);
	if (!isMatch) {
		return null;
	}
	return children;
};
// ConditionBlockItemView.canRenderInnerBlocks = true;
export { ConditionBlockItem, ConditionBlock };
