import { entity } from 'simpler-state'

export const inAppPages = entity({
    isLoading: false,
    isLoadingInAppPage: false,
    inAppPageList: [],
    inAppPageData: {},
})
export const useInAppPage = () => inAppPages.use();
export const setInAppPageList = (_inAppPageList) => inAppPages.set(value => ({ ...value, inAppPageList: _inAppPageList, isLoading: false }));
// to set in-app page list loading
export const setLoading = (_isLoading) => inAppPages.set(value => ({ ...value, isLoading: _isLoading }))
// to set in-app page loading
export const setInAppPageLoading = (_isLoadingInAppPage) => inAppPages.set(value => ({ ...value, isLoadingInAppPage: _isLoadingInAppPage }));
export const setInAppPageData = (_inAppPageData) => inAppPages.set(value => ({ ...value, inAppPageData: _inAppPageData, isLoadingInAppPage: false }))
