import { getProjectData } from '@appmaker/core/store/project';
import {lazy,Suspense} from 'react'


const HomeOld = lazy(() => import('./Home'));
const HomeNew = lazy(()=> import('../new/routes/Home'))
const HomeWrapper = () => {
    const { loading, projectId, projectData = {} } = getProjectData();
    const {
        migration:{
            showOldAppBuild = false
        }={}
    } = projectData;
	return (
		<Suspense>
			{showOldAppBuild && <HomeOld />}
            {!showOldAppBuild && <HomeNew />}
		</Suspense>
	);
};

export default HomeWrapper;
