import { entity, persistence } from 'simpler-state'

export const languageEditorData = entity({
    selectedLanguages: [],
    defaultLanguage: "",
    languageValues: {},
    availableLanguages: [],
    translationSchema: null,
    currentLanguage: null,
    currentTab: null,
    isLanguageValueFetching: false
})

export const useLanguageFormData = (args) => languageEditorData.use(args)

export const setTranslationSchema = translationSchema => languageEditorData.set(value => ({ ...value, translationSchema }));

export const setDefaultLanguage = language => languageEditorData.set(value => ({ ...value, defaultLanguage: language }))

export const setCurrentAsDefaultLanguage = () => languageEditorData.set(value => ({ ...value, defaultLanguage: value.currentLanguage }))

export const setCurrentLanguage = currentLanguage => languageEditorData.set(value => ({ ...value, currentLanguage }))

export const setCurrentTab = currentTab => languageEditorData.set(value => ({ ...value, currentTab }))


export const setLanguageEditorData = data => {
    languageEditorData.set(value => ({ ...value, ...data }))
};

export const addLanguage = language => {
    languageEditorData.set(value => ({ ...value, selectedLanguages: [language, ...value.selectedLanguages] }))
}
export const setLanguageValue = (language, translation) => {
    languageEditorData.set(value => ({ ...value, languageValues: { ...value.languageValues, [language]: { translation } } }))
}

// export const deleteLanguage = () => languageEditorData.set(value => ({ ...value, selectedLanguages: value.selectedLanguages.filter(lan => lan !== value.currentLanguage) }))

export const deleteLanguage = () => languageEditorData.set(value => {
    delete value.languageValues[value.currentLanguage];
    return { ...value, selectedLanguages: value.selectedLanguages.filter(lan => lan !== value.currentLanguage) }
})

export const setLoaderForFetchLanguage = (loadingValue) => languageEditorData.set(value => ({ ...value, isLanguageValueFetching: loadingValue }));