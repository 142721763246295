import appmaker from '@appmaker/core/index';
import Language from './routes';
import MenuItem from './components/MenuItem';
import { TranslateIcon } from '@heroicons/react/solid';

const plugin = {
    id: '@appmaker/language',
    name: 'Language',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/language',
            component: Language,
        });
        appmaker.registerSideBarMenu({
            name: 'Languages',
            routeName: `/language`,
            icon: TranslateIcon,
            MenuItemComponent: MenuItem,
        });
    }
}

export default plugin;