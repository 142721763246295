import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,

    generatePath,
} from '@appmaker/core/routes';
import FormItem from './FormItem';

const FormSteps = ({ template = {} }) => {
    let { path, params } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route exact path={`${path}`}>
                    <Redirect
                        to={`${generatePath(path, params)}/${Object.keys(template)[0]}`}
                    />
                </Route>
                <Route path={`${path}/:tabId`}>
                    <FormItem
                        wholeSchema={template}
                        basePath={generatePath(path, params)}
                    />
                </Route>
            </Switch>
        </>
    );
};

export default FormSteps;
