import { CloudDownloadIcon } from '@heroicons/react/solid';

const StepFour = () => {
	return (
		<ul className="list-outside ml-4 list-disc flex flex-col gap-3">
			<li>
			Select an app category from the drop-down list to help users discover your app and click on Manage Tags and a new pop-up to add tags opens. Select the tags and click Apply
			<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-4-1.png"
					alt="step one"
				/>
				<img
					className="w-full rounded-lg border border-gray-100 mt-1"
					src="/images/publish/android/new/step-4-2.png"
					alt="step one"
				/>
			</li>
		</ul>
	);
};

export default StepFour;
