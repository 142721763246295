import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const EmptyTemplate = ({ attributes = {}, children }) => {
	const { title, description, color, icon: Icon, templates } = attributes;
	return (
		<div className="mb-1 px-2 pt-2">
			<div
				className={clsx(
					'text-center flex flex-col items-center px-2 py-4 rounded-md',
					color ? 'bg-' + color + '-200' : 'bg-blue-200'
				)}
			>
				<div className="flex items-center gap-2">
					{attributes.icon && <Icon className="h-6 w-6 mb-1" />}
					<h3 className="text-lg font-medium">{title}</h3>
				</div>
				<p className="text-sm text-gray-600 mb-3">{description}</p>

				{/* <div className="flex items-center gap-4">
					<button
						type="button"
						className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Upload Image
					</button>

				</div> */}

				{children}
				{templates && (
					<div className="mt-3">
						<Dropdown />
					</div>
				)}
			</div>
		</div>
	);
};

const items = [{ name: 'Add Category', href: '#' }];

function Dropdown() {
	return (
		<span className="relative z-50 inline-flex shadow-sm rounded-md">
			<Menu as="span" className="-ml-px relative block">
				<Menu.Button className="relative bg-opacity-60 inline-flex items-center pl-3 pr-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-50 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
					<span className="mr-2">Templates</span>
					<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
				</Menu.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="origin-top absolute z-50 inset-x-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="py-1">
							{items.map((item) => (
								<Menu.Item key={item.name}>
									{({ active }) => (
										<a
											href={item.href}
											className={clsx(
												active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
												'block px-4 py-2 text-sm'
											)}
										>
											{item.name}
										</a>
									)}
								</Menu.Item>
							))}
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</span>
	);
}

export default EmptyTemplate;
