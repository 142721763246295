import appmaker from '@appmaker/core/index';
import { CursorClickIcon } from '@heroicons/react/solid';
import Actions from './routes/Actions';
import EditActions from './routes/EditActions';
import { registerServerActions } from './store/serverActions';

const plugin = {
	id: '@appmaker/actions',
	name: 'Actions',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/actions/edit/:actionId?',
			component: EditActions,
		});

		appmaker.registerRoute({
			routeName: '/actions',
			component: Actions,
		});

		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Actions',
			routeName: '/actions',
			icon: CursorClickIcon,
			adminOnly: true,
		});
		registerServerActions();
	},
};

export default plugin;
