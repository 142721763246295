import { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
function getValue(type, item) {
	if (typeof item === 'string') return item;
	return type === 'string' ? item?.value : item;
}

const AppmakerRadioGroup = ({ schema = {}, onChange, formData }) => {
	const { title, options = [], type } = schema;
	const [selected, setSelected] = useState(formData);
	useEffect(() => {
		console.log(getValue(type, selected), type, selected);
		onChange(getValue(type, selected));
	}, [selected]);

	return (
		<RadioGroup value={selected} onChange={setSelected} className="mt-4">
			<RadioGroup.Label className="font-regular text-sm mb-1 block">
				{title}
			</RadioGroup.Label>
			<div className="bg-white rounded-md -space-y-px">
				{options.map((item, itemIdx) => (
					<RadioGroup.Option
						key={item.label}
						value={getValue(type, item)}
						className={({ checked }) =>
							clsx(
								itemIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
								itemIdx === options.length - 1
									? 'rounded-bl-md rounded-br-md'
									: '',
								checked ? 'bg-gray-50 border-blue-200 z-10' : 'border-gray-200',
								'relative border p-4 flex cursor-pointer focus:outline-none'
							)
						}
					>
						{({ active, checked }) => (
							<>
								<span
									className={clsx(
										checked
											? 'bg-blue-600 border-transparent'
											: 'bg-white border-gray-300',
										active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
										'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
									)}
									aria-hidden="true"
								>
									<span className="rounded-full bg-white w-1.5 h-1.5" />
								</span>
								<div className="ml-3 flex flex-col">
									<RadioGroup.Label
										as="span"
										className={clsx(
											checked ? 'text-blue-900' : 'text-gray-900',
											'block text-sm font-medium'
										)}
									>
										{item.label}
									</RadioGroup.Label>
									<RadioGroup.Description
										as="span"
										className={clsx(
											checked ? 'text-blue-700' : 'text-gray-500',
											'block text-sm'
										)}
									>
										{item.description}
									</RadioGroup.Description>
								</div>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
};

export default AppmakerRadioGroup;
