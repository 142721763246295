/**
 * WordPress dependencies
 */
import { useEffect } from '@wordpress/element';
import {
    useShortcut,
    store as keyboardShortcutsStore,
} from '@wordpress/keyboard-shortcuts';
import { useDispatch } from '@wordpress/data';
import { __ } from '@wordpress/i18n';

function KeyboardShortcuts({ save }) {


    useShortcut(
        'appmaker/app-design/save',
        (event) => {
            event.preventDefault();
            save();
        },
        { bindGlobal: true }
    );

    return null;
}

function KeyboardShortcutsRegister() {
    const { registerShortcut, unregisterShortcut } = useDispatch(
        keyboardShortcutsStore
    );

    useEffect(() => {


        registerShortcut({
            name: 'appmaker/app-design/save',
            category: 'global',
            description: __('Save your changes.'),
            keyCombination: {
                modifier: 'primary',
                character: 's',
            },
        });

        return () => {
            unregisterShortcut('appmaker/app-design/save');
        };
    }, [registerShortcut]);

    return null;
}

KeyboardShortcuts.Register = KeyboardShortcutsRegister;
export default KeyboardShortcuts;
