import React from 'react';

const MenuImage = ({ imageSrc, children }) => {
  return (
    <div className="rounded-full h-8 w-8  mr-2">
      <img src={imageSrc} className="rounded-full h-8 w-8  mr-2" />
      {children && <span className="image-title">{children}</span>}
    </div>
  );
};
export default MenuImage;
