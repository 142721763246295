import { useExtensionDetail } from '../store/details';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { Prompt } from '@appmaker/core/routes';
import { useBeforeunload } from 'react-beforeunload';

const UnSavedChange = () => {
	const storeApi = useExtensionDetail();

	useBeforeunload((event) => {
		if (storeApi?.unSaved) {
			event.preventDefault();
		}
	});

	if (!storeApi?.unSaved ) return null;
	return (
		<p className="ml-3 font-medium  truncate flex gap-1 items-center text-yellow-500">
			<Prompt
				when={storeApi?.unSaved}
				message={(location) =>
					`You have unsaved changes. Are you sure you want to leave?`
				}
			/>
			<span className="flex h-3 w-3 relative items-center ml-2">
				<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
				<ExclamationCircleIcon className="h-4 w-4" />
			</span>
			<span className=" md:inline">Unsaved Changes</span>
		</p>
	);
};

export default UnSavedChange;
