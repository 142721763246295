import { useProject } from '@appmaker/core/store/project'
import React from 'react'
import { getShopifyCollection, gql } from '../api/shopify-api';
import { saveAs } from 'file-saver';
import { useAsyncFn } from 'react-use';

async function exportShopifyCollection({ project }) {
    const response = await getShopifyCollection();
    const { data } = response;
    const { collections } = data;
    const collectionsList = [];
    collections.edges.forEach(({ node }) => {
        const { id, title, image, products } = node;
        const { edges } = products;
        const [product1, product2, product3, product4] = edges;

        const collectionItem = {
            id,
            title,
            collectionImage: image?.url || ' ',
            productImage: product1?.node?.featuredImage?.url || ' ',
            product2Image: product2?.node?.featuredImage?.url || ' ',
            product3Image: product3?.node?.featuredImage?.url || ' ',
            product4Image: product4?.node?.featuredImage?.url || ' ',

        }
        collectionsList.push(collectionItem);
    })
    console.log(collectionsList);
    var blob = new Blob([JSON.stringify(collectionsList)], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${project.projectData.name}-${project.projectId}.json`);
}
export default function ShopifyCollectionExport() {
    const project = useProject();
    const [state, doFetch] = useAsyncFn(async (params) => {
        await exportShopifyCollection(params)
        // return result
    }, [project]);
    console.log(project);
    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <div className="sm:flex sm:items-start sm:justify-between">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Collections JSON</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            {state.loading
                                ? <div>Loading...</div>
                                : state.error
                                    ? <div>Error: {state.error.message}</div>
                                    : <div>Value: {state.value}</div>
                            }
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                        <button
                            onClick={() => {
                                doFetch({ project });

                            }}
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        >
                            Downlaod
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}
