// @flow strict

import SimpleBlockEditor from '@appmaker/components/SimpleBlockEditor';
import DataSourceSelector from 'packages/data-source/components/DataSourceForm';
import * as React from 'react';

// import ReactJson from 'react-json-view';

function CustomBlockEditor() {
	const [attributes, setAttributes] = React.useState({});
	return (
		<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			<div className="py-6">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
				</div>
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					{/* Replace with your content */}
					<div className="py-4 pb-5">
						{/* <IconChooser /> */}
						<DataSourceSelector
							formData={{ formData: attributes?.dataSource, _internal: attributes?.___internal_datasource }}
							setFormData={({ formData, _internal }) => {
								setAttributes({ dataSource: formData, ___internal_datasource: _internal });
							}}
						/>
						<pre>{JSON.stringify(attributes,null,2)}</pre>
					
					</div>
					{/* /End replace */}
				</div>
			</div>
		</main>
	);
}

export default CustomBlockEditor;
