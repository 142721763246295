import * as React from 'react';
const StateContext = React.createContext();
const DispatchContext = React.createContext();

function MediaLibraryContextProvider({ children, initialState }) {
    const [state, dispatch] = React.useState(initialState);
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
}
function useMediaProps() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useMediaState must be used within a ContextProvider');
    }
    return context;
}
function useContextDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useContextDispatch must be used within a ContextProvider');
    }
    return context;
}
export { MediaLibraryContextProvider, useMediaProps, useContextDispatch };
