import axios from 'axios';
import { BASE_URL } from '@appmaker/core/api/core';
const apiFunction = {
  getInAppPageList: async (projectId) => {
    try {
      let url = `${BASE_URL}/projects/${projectId}/in-app-pages`;
      // `${BASE_URL}/projects/${projectId}/in-app-pages`
      const response = await axios.get(url, {
        credentials: 'include',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getInAppPage: async (projectId, inAppPageId) => {
    try {
      const response = await axios.get(`${BASE_URL}/projects/${projectId}/in-app-pages/${inAppPageId}`, {
        credentials: 'include',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getMenu: async (projectId, menuKey = 'mainmenu') => {
    try {
      let url = `${BASE_URL}/projects/${projectId}/navigationMenu/${menuKey}`;
      // `${BASE_URL}/projects/${projectId}/in-app-pages`
      const response = await axios.get(url, {
        credentials: 'include',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default apiFunction;