
import clsx from 'clsx';
import { useState } from 'react'
import { useData, useModal, setInAppPageToTemplate, getBlockCount } from '../hooks'
import SimpleAlert from '@appmaker/components/templates/SimpleAlert';
import { getProjectId } from '@appmaker/core/store/project';
import appmaker from '@appmaker/core/index';


const CategoryListing = () => {
    const { selectedThemeCategory, setPreviewTemplate, templates = [], resetTemplate = () => { }, isTemplateSetting } = useData()
    const [selectedId, setSelectedId] = useState(false);
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const [alertModal, setAlertModal] = useState(false);
    const [, setModal] = useModal();
    const projectId = getProjectId();

    const templateButtonClick = (theme) => {
        appmaker.doAction('appmaker-analytics-event', {
            event: `template_chooser_use_template_button_clicked_${theme.id}`,
            params: {
                projectId,
                insideShopify: !!window.shopifyApp
            }
        });
        if (getBlockCount() === 0) {
            setInAppPageToTemplate(theme.blocks);
            setModal(false);
        } else {
            setSelectedBlocks(theme.blocks);
            setAlertModal(true)
        }
    }
    return (
        <div className="bg-white mt-3">
            {alertModal && (
                <SimpleAlert
                    openModal={alertModal}
                    onClose={setAlertModal}
                    attributes={{
                        title: '',
                        message:
                            'This action will replace your current in-app page. Are you sure you want to continue?',
                        ctaMain: {
                            action: () => {
                                setInAppPageToTemplate(selectedBlocks);
                                setModal(false);
                                setAlertModal(false);
                                setSelectedBlocks([])
                            },
                            label: 'Continue',
                        },
                        ctaSecondary: {
                            action: () => {
                                setAlertModal(false);
                                setSelectedBlocks([])
                            },
                            label: 'Cancel',
                        },
                    }}
                />
            )}
            <div className="flex items-center justify-between space-x-4">
                <h2 className="text-2xl mb-1 text-gray-800">{selectedThemeCategory ? selectedThemeCategory : 'All'}</h2>
                {selectedThemeCategory && <button
                    className="whitespace-nowrap text-sm font-medium text-blue-600 hover:text-blue-500"
                    onClick={() => {
                        resetTemplate(undefined);
                    }}
                >
                    View all<span aria-hidden="true"> &rarr;</span>
                </button>}
            </div>
            <div className="mt-2 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
                {templates && templates.map((theme) => {
                    const selected = selectedId === theme.id;
                    return (
                        <button
                            key={theme.id}
                            className={clsx(
                                'relative group rounded-xl focus:outline-none focus:ring focus:ring-blue-600 ring-offset-2'
                                // selected &&
                                // 	'ring ring-blue-600 ring-opacity-70 ring-offset-4 rounded-md'
                            )}
                            onClick={() => {
                                // setSelectedId(theme.id);
                                // selectItem(theme);
                            }}
                        >
                            {/* {selected && (
								<CheckCircleIcon className="text-blue-600 h-6 w-6 absolute left-1 top-1 z-10 bg-white rounded-full" />
							)} */}
                            <div className="aspect-w-4 aspect-h-3 rounded-xl overflow-hidden bg-gray-100">
                                <img
                                    src={theme.imageSrc}
                                    alt={theme.imageAlt}
                                    className="object-center object-cover"
                                />
                                <div
                                    className="flex flex-col space-y-2 justify-end opacity-0 p-4 group-hover:opacity-100 bg-gradient-to-t from-gray-500"
                                    aria-hidden="true"
                                >
                                    <button
                                        className="shadow-2xl w-full border border-white text-white bg-opacity-80 backdrop-filter backdrop-blur hover:text-gray-100 py-2 px-4 rounded-md text-sm font-medium text-center"
                                        onClick={() => {

                                            setPreviewTemplate(theme.id)
                                        }}
                                    >
                                        Preview
                                    </button>
                                    <button
                                        disabled={isTemplateSetting}
                                        onClick={() => {
                                            templateButtonClick(theme)
                                        }}
                                        className="shadow-2xl w-full bg-white text-gray-900 py-2 px-4 rounded-md text-sm font-medium text-center hover:bg-gray-100">
                                        {isTemplateSetting ? 'Loading...' : 'Use Template'}
                                    </button>
                                </div>
                            </div>
                            <div className="px-1 pb-1">
                                <div className="mt-2 flex items-center justify-between text-base font-medium text-gray-900 space-x-8">
                                    <h3 className="text-left flex-1 line-clamp-1 text-lg">
                                        {theme.name}
                                    </h3>
                                    <p className="text-green-600">{theme.price}</p>
                                </div>
                                <p className="text-xs text-gray-500 text-left">
                                    {theme.category}
                                </p>
                            </div>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};




export default CategoryListing