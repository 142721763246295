import {
    MailIcon,
    XIcon,
} from '@heroicons/react/solid';
const BuildFailed = () => {
    return (
        <>
            <div className="inline-flex items-center gap-2 text-xl font-medium">
                <XIcon className="h-10 w-10 p-2 bg-red-100 text-red-600 rounded-full" />
                IPA Build Failed
            </div>
            <div className="flex gap-4">
                <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                    onClick={() => {
                        if (window && window.Intercom)
                            window.Intercom('showNewMessage')
                    }}
                >

                    <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Contact Support
                </button>
            </div>
        </>
    )
}

export default BuildFailed