import { ChevronRightIcon } from '@heroicons/react/outline';

const MenuItem = ({ attributes, titleEditor, imageEditor }) => {
	const {
		type,
		badgeText,
		badgeColor,
		badgeTextColor,
		dotIndicator,
		dotIndicatorColor,
		dotSize,
	} = attributes;
	if (type === 'title') {
		return <div>{titleEditor}</div>;
	}
	return (
		<div className="flex items-center justify-between focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 py-1 focus-within:ring-indigo-500 hover:opacity-80">
			<div className="flex items-center flex-1">
				{imageEditor}
				{titleEditor}
				{badgeText ? (
					<span
						className="px-1 py-0.5  ml-2 text-xs font-medium rounded"
						style={{ backgroundColor: badgeColor, color: badgeTextColor }}
					>
						{badgeText}
					</span>
				) : null}
				{dotIndicator ? (
					<div
						className="ml-2 rounded-full"
						style={{
							backgroundColor: dotIndicatorColor,
							width: dotSize,
							height: dotSize,
						}}
					/>
				) : null}
			</div>
			<ChevronRightIcon className="h-4 w-4 text-gray-600" />
		</div>
	);
};

export default MenuItem;
