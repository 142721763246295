import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [{
    "clientId": "567509f0-a666-4456-9162-2c7956b2482b",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_0b9dbf1d-9f64-4857-8d72-14d17da4f328.png?v=1665578800",
            "id": "gid://shopify/MediaImage/31628484444417",
            "title": "a.png"
        },
        "imageContainerStyle": {
            "height": 157,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 157,
            "width": 1080
        },
        "appmakerAction": {
            "action": "OPEN_SEARCH"
        },
        "___internal_appmakerAction": {
            "action": {
                "value": "OPEN_SEARCH",
                "label": "Open search page"
            }
        }
    },
    "innerBlocks": []
}, {
    "clientId": "afe95ebc-0b1b-48dc-a4a9-ac13d5155d1d",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_056de1a0-0d40-4553-b727-d28a60d2d291.png?v=1665578810",
            "id": "gid://shopify/MediaImage/31628485263617",
            "title": "b.png"
        },
        "imageContainerStyle": {
            "height": 550,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 550,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "59d0841e-0e65-42a5-b885-47fa3198aa50",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_d044600f-7d94-4698-9c54-d1ad0f7ee176.png?v=1665578842",
            "id": "gid://shopify/MediaImage/31628488704257",
            "title": "c.png"
        },
        "imageContainerStyle": {
            "height": 116,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 116,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "fad74300-c287-491d-9588-b7330ab7bdee",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 5,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "enableSplit": false,
        "enableGap": false
    },
    "innerBlocks": [{
        "clientId": "c4bfcd18-c36e-4f4a-9e4b-929fc53fbfaf",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d_3d4c7418-398b-4089-a53d-5eed7e6b45ac.png?v=1665578858",
                "id": "gid://shopify/MediaImage/31628489949441",
                "title": "d.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "1ff6b40c-c057-4492-aa7c-65bf68728efe",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-1_18e92271-7b84-4e50-bb31-93571584e9d5.png?v=1665578868",
                "id": "gid://shopify/MediaImage/31628490572033",
                "title": "d-1.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "4c5240cd-5d24-403c-b08d-123171435c9f",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-2_a7041233-c97e-4ea2-93d5-718f73b2d590.png?v=1665578876",
                "id": "gid://shopify/MediaImage/31628491358465",
                "title": "d-2.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "aae90fc9-c5cb-4fde-9766-693fe2626ad1",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-3_e2cf79d4-ae50-4487-a97c-17e9a178391c.png?v=1665578887",
                "id": "gid://shopify/MediaImage/31628492046593",
                "title": "d-3.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "0a11b374-b5c6-48de-b943-35d315453f66",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d-4_abdf5bd4-162b-4a45-a043-3299524db61e.png?v=1665578896",
                "id": "gid://shopify/MediaImage/31628492734721",
                "title": "d-4.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "0e9c1eb6-8a21-40cd-b2f8-687a305f7007",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e_6843ae0b-41ab-493b-b49b-432d39b829f5.png?v=1665578972",
            "id": "gid://shopify/MediaImage/31628500009217",
            "title": "e.png"
        },
        "imageContainerStyle": {
            "height": 145,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 145,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "a1c289d8-adea-4075-b359-7d64f63e24ce",
    "name": "appmaker/imagescroller",
    "isValid": true,
    "attributes": {
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "appmakerAction": {},
        "___internal_appmakerAction": {},
        "customImageWidth": true,
        "parentWidth": "170"
    },
    "innerBlocks": [{
        "clientId": "9577a2d7-5c71-415f-be85-7174390a0556",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f_69fa0f88-abe0-495e-9d6f-ca346edd9fe2.png?v=1665578992",
                "id": "gid://shopify/MediaImage/31628502237441",
                "title": "f.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 230,
                "width": 445
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "f0f0a177-9aa2-47f3-8d6f-7ef06cc20a7a",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-1_7fb0edfe-ae11-443a-86cf-92ee1e64dcb1.png?v=1665578992",
                "id": "gid://shopify/MediaImage/31628502270209",
                "title": "f-1.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 230,
                "width": 445
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "325c9d38-f2ba-4dc3-a8a1-a476f0103524",
        "name": "appmaker/imagescroller-item",
        "isValid": true,
        "attributes": {
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/f-2_6852c148-4fe7-48b5-9843-9b9a8581e344.png?v=1665578992",
                "id": "gid://shopify/MediaImage/31628502204673",
                "title": "f-2.png"
            },
            "text": "",
            "imageContainerStyle": {
                "height": 230,
                "width": 445
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "86216533-5ff8-4266-ae2c-8c817d6d13f9",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/g_4b1ccb5f-1ea6-4b02-971c-372846bd2d2e.png?v=1665579030",
            "id": "gid://shopify/MediaImage/31628505448705",
            "title": "g.png"
        },
        "imageContainerStyle": {
            "height": 523,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 523,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "dcbfce72-3902-4316-997f-96b5454dfbd6",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 5,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "enableSplit": false,
        "enableGap": false
    },
    "innerBlocks": [{
        "clientId": "a8376376-bf61-4718-b40d-8b45175db4ea",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h_d42ff20a-a7c8-4688-aa0a-fad28aeb008d.png?v=1665579060",
                "id": "gid://shopify/MediaImage/31628508496129",
                "title": "h.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "28528dbd-5d56-47fb-9350-09fb738f733b",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-1_73b62a7d-16a1-4ed1-b2ef-496c30e90f64.png?v=1665579068",
                "id": "gid://shopify/MediaImage/31628509085953",
                "title": "h-1.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "13ed0a25-df4d-4351-a359-fe6ea6f9fbbe",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-2_ea133d8d-fe7d-4990-83d3-ec57cc48df3c.png?v=1665579077",
                "id": "gid://shopify/MediaImage/31628509806849",
                "title": "h-2.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "45e236ee-66ca-4dfb-8b41-89ab46bd4d5f",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-3_7870a64f-216b-4d40-a4fd-99967f5c9e0d.png?v=1665579088",
                "id": "gid://shopify/MediaImage/31628510822657",
                "title": "h-3.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "7296d768-de95-4d1c-917c-1f0719d9bb2e",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/h-4_79aced6d-8721-4f63-aeac-96c315f3fba0.png?v=1665579097",
                "id": "gid://shopify/MediaImage/31628511871233",
                "title": "h-4.png"
            },
            "imageContainerStyle": {
                "height": 271,
                "width": 180
            },
            "thumbnail_meta": {
                "height": 271,
                "width": 180
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "a1b75a22-8f31-4d4c-9c31-e41c6e275a31",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/i_b481cb91-22db-4b30-be4c-cf2e663301d7.png?v=1665579109",
            "id": "gid://shopify/MediaImage/31628512329985",
            "title": "i.png"
        },
        "imageContainerStyle": {
            "height": 291,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 291,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "8b1a1254-b2aa-4b63-b400-515789d75360",
    "name": "appmaker/grid",
    "isValid": true,
    "attributes": {
        "title": "sample title",
        "itemsPerLine": 3,
        "ctaText": "View more button",
        "showViewMoreButton": false,
        "showTitle": false,
        "enableSplit": false,
        "enableGap": false
    },
    "innerBlocks": [{
        "clientId": "9ad74b2c-bdbe-474f-9602-90775f3701e5",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j_f31a7c1c-be8a-4253-97e5-c855e3c70316.png?v=1665579123",
                "id": "gid://shopify/MediaImage/31628513149185",
                "title": "j.png"
            },
            "imageContainerStyle": {
                "height": 657,
                "width": 360
            },
            "thumbnail_meta": {
                "height": 657,
                "width": 360
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "c20e962c-19c8-4173-833e-721ec184e6bb",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-1_ba87c4e6-10eb-4f2e-a8bb-fb49a5a0d031.png?v=1665579132",
                "id": "gid://shopify/MediaImage/31628513739009",
                "title": "j-1.png"
            },
            "imageContainerStyle": {
                "height": 657,
                "width": 360
            },
            "thumbnail_meta": {
                "height": 657,
                "width": 360
            }
        },
        "innerBlocks": []
    }, {
        "clientId": "5bf3f860-db88-4c88-8f0a-15a28e958741",
        "name": "appmaker/grid-item",
        "isValid": true,
        "attributes": {
            "showText": false,
            "image": {
                "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/j-2_75fa9832-004d-4354-bd0c-cb4d97d7d69a.png?v=1665579142",
                "id": "gid://shopify/MediaImage/31628514492673",
                "title": "j-2.png"
            },
            "imageContainerStyle": {
                "height": 657,
                "width": 360
            },
            "thumbnail_meta": {
                "height": 657,
                "width": 360
            }
        },
        "innerBlocks": []
    }]
}, {
    "clientId": "dcfdbabf-99ea-47c4-bd7b-21120bb27e12",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/k_230c3c69-6b44-40fd-b345-da84a9c7e8a4.png?v=1665579223",
            "id": "gid://shopify/MediaImage/31628523110657",
            "title": "k.png"
        },
        "imageContainerStyle": {
            "height": 827,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 827,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "f71ddc0d-4f41-40d2-a578-8b0ee13bc3e1",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/l_3b2b2784-7c0a-41e0-a6d0-dc82a1404192.png?v=1665579239",
            "id": "gid://shopify/MediaImage/31628525764865",
            "title": "l.png"
        },
        "imageContainerStyle": {
            "height": 193,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 193,
            "width": 1080
        }
    },
    "innerBlocks": []
}, {
    "clientId": "fc94745b-7fd2-4281-bc22-76785b915f73",
    "name": "appmaker/banner",
    "isValid": true,
    "attributes": {
        "image": {
            "url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/m_7fb3754f-c725-4d4b-a3f0-5da970a52cd5.png?v=1665579246",
            "id": "gid://shopify/MediaImage/31628526485761",
            "title": "m.png"
        },
        "imageContainerStyle": {
            "height": 1512,
            "width": 1080
        },
        "thumbnail_meta": {
            "height": 1512,
            "width": 1080
        }
    },
    "innerBlocks": []
}]
// function generate() {

// }
const template = {
    blocks,
    id: 'electronics',
    name: 'Mattenoir',
    category: 'Electronics',
    price: 'Free',
    imageSrc: '/images/themes/electronics.png',
    previewSrc: '/images/preview/templates/electronics.png',
    imageAlt: 'Mobile app electronic store theme',
    description: "The electronic store theme is a sleek and modern template perfect for online stores selling electronic goods. It features a dark color scheme and a clean, minimal design that is easy to navigate.",
    figmaSrc: 'https://www.figma.com/community/file/1164553998414800086'
};
export default template;
