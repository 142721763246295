import { getProjectTheme } from "@appmaker/core/store/project"
import { getBlocks } from './blocks';
const registerCustomThemes = () => {
    const { allowed_blocks: allowedBlocks = [] } = getProjectTheme() || {};
    allowedBlocks && allowedBlocks.map(blocks => {
        const {name} = blocks || {};
        if(name){
            getBlocks(name);
        }
    })
}

export {
    registerCustomThemes
}