import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const Input = ({ properties = {}, defaultValue, value, onChange }) => {
	const {
		inputType = 'text',
		name,
		label,
		placeholder,
		required,
		error,
		description,
		className,
		inputProps,
	} = properties;
	return (
		<div className={className ? className : 'mt-4'}>
			<label htmlFor={name} className="block text-sm font-base text-gray-700">
				{label}
			</label>
			<div className="mt-1 relative">
				<input
					{...inputProps}
					type={inputType}
					value={defaultValue || value}
					name={name}
					id={name}
					required={required}
					onChange={e => onChange(e.target.value)}
					className={clsx(
						'shadow-sm block w-full sm:text-sm border-gray-300 rounded-md',
						error
							? 'border-red-400 text-red-900 focus:ring-red-500 focus:border-red-500'
							: 'focus:ring-indigo-500 focus:border-indigo-500'
					)}
					placeholder={placeholder}
				/>
				{error && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon
							className="h-5 w-5 text-red-500"
							aria-hidden="true"
						/>
					</div>
				)}
			</div>
			<div className="flex items-center gap-1">
				{description && (
					<p className="mt-1 text-sm text-gray-500" id={`${name}-description`}>
						{description}
					</p>
				)}
				{description && error && <span className="text-gray-500">&bull;</span>}
				{error && (
					<p className="mt-0.5 text-sm text-red-600" id={`${name}-error`}>
						{error}
					</p>
				)}
			</div>
		</div>
	);
};

export default Input;
