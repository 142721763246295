import { InnerBlocks } from '@wordpress/block-editor';

const BasicTabBlock = ({ attributes }) => {
	return (
		<div
			className="relative border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
			style={attributes?.blockContainerStyle}
		>
			<div className="flex-1 min-w-0 text-center">
				<p
					className="text-sm font-medium text-gray-900 text-left"
					style={attributes?.blockTitleTextStyle}
				>
					{attributes?.title || 'Basic Tabs'}
				</p>
				<div style={attributes?.tabContainerStyle}>
					{attributes?.allowCustomData ? (
						<InnerBlocks />
					) : (
						<InnerBlocks allowedBlocks={['appmaker/basic-tab-item']} />
					)}
				</div>
			</div>
		</div>
	);
};

const BasicTabItem = ({ attributes }) => {
	return (
		<div className="relative border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
			<div className="flex-1 min-w-0 text-center">
				<p className="text-sm font-medium text-gray-900">
					{attributes?.title || 'Tab Item'}
				</p>
				<InnerBlocks />
			</div>
		</div>
	);
};

BasicTabBlock.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Block Title',
		},
		hideTitle: {
			type: 'string',
			label: 'Hide Title',
			uiType: 'switch',
			className: 'mb-3 mt-2',
		},
		gap: {
			type: 'string',
			uiType: 'RangeSlider',
			label: 'Gap between tabs',
			default: 6,
			min: 0,
			max: 20,
		},
		blockContainerStyle: {
			type: 'string',
			uiType: 'layout-styles',
			label: 'Block Container Style',
		},
		blockTitleTextStyle: {
			type: 'string',
			uiType: 'text-styles',
			label: 'Block Title Text Style',
		},
		tabContainerStyle: {
			type: 'string',
			uiType: 'layout-styles',
			label: 'Tab Container Style',
		},
		dataContainerStyle: {
			type: 'string',
			uiType: 'layout-styles',
			label: 'Content Container Style',
		},
		activeTabStyle: {
			type: 'string',
			uiType: 'layout-styles',
			label: 'Active Tab Style',
		},
		activeTabTextStyle: {
			type: 'string',
			uiType: 'text-styles',
			label: 'Active Tab Text Style',
		},
		defaultTabStyle: {
			type: 'string',
			uiType: 'layout-styles',
			label: 'Default Tab Style',
		},
		defaultTabTextStyle: {
			type: 'string',
			uiType: 'text-styles',
			label: 'Default Tab Text Style',
		},
		customData: {
			type: 'string',
			label: 'Custom Data',
		},
		customDataTabTitle: {
			type: 'string',
			label: 'Custom Data Tab Title',
		},
		allowCustomData: {
			type: 'string',
			uiType: 'switch',
			label: 'Allow Custom Data',
			className: 'mb-3 mt-2',
		},
	},
};

BasicTabItem.attributesSchema = {
	properties: {
		title: {
			type: 'string',
			label: 'Tab Title',
		},
	},
};

export { BasicTabItem };
export default BasicTabBlock;
