import React, { Fragment } from 'react';
import '../style.css';
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { PhotographIcon } from '@heroicons/react/outline';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';

const ProductQualityCard = (props) => {
	const { attributes = {}, setAttributes = () => {}, className } = props || {};

	const {
		title,
		image,
		subTitle,
		hasAction,
		actionLabel = 'Learn more',
	} = attributes;
	return (
		<>
			<div className="quality-card ml-2 py-4">
				<div className={className}>
					{image === undefined && (
						<MediaPlaceholder
							// onSelectURL={(url) => {
							// 	setAttributes({
							// 		image: {
							// 			url,
							// 		},
							// 	});
							// }}
							onSelect={(selectedData) => {
								let data = Array.isArray(selectedData)
									? selectedData[0]
									: selectedData;
								// condition in meta because each api having different response of the meta
								setAttributes({
									image: {
										url: data.url,
										fileName: data.filename,
										id: data.id,
										title: data.title,
									},
									imageContainerStyle: {
										height: data.meta !== undefined ? data.meta.height : '100',
										width: data.meta !== undefined ? data.meta.width : '100',
									},
									thumbnail_meta: {
										height:
											data.meta !== undefined ? data.meta.height : undefined,
										width:
											data.meta !== undefined ? data.meta.width : undefined,
									},
								});
							}}
							allowedTypes={['image']}
							multiple={false}
							attributes={{
								icon: PhotographIcon,
								// title: 'Banner',
								// description: 'Showcase a product that’s on sale or highlight your best offer by adding an image as a banner. '
							}}
							value={{ id: image && image.id, src: image && image.url }}
						/>
					)}
					{image && (
						<img
							src={image.url}
							alt="thumbnail"
							className="rounded-xl h-36 object-cover w-full"
						/>
					)}
				</div>
				<h4>{title}</h4>
				<p className="text-xs text-gray-500 w-full block">{subTitle}</p>
				{hasAction ? (
					<small className="text-blue-600">{actionLabel}</small>
				) : null}
			</div>
			<Fragment>
				<InspectorControls>
					<PanelBody title="Edit Block">
						{image && (
							<div className="mb-2">
								<p className="mb-1 text-gray-500 font-semibold text-sm">
									Image
								</p>
								<div className="flex items-center gap-3">
									<img
										src={image.url}
										alt="thumbnail"
										className="h-16 w-16 border border-gray-200 object-contain p-0.5"
									/>

									<button
										onClick={() =>
											setAttributes({
												image: undefined,
											})
										}
										type="button"
										className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
									>
										Replace image
									</button>
								</div>
							</div>
						)}
						<div className="mb-2">
							<label className="text-sm mb-0.5 text-gray-600">Title</label>
							<input
								type="text"
								value={title}
								className="w-full rounded border-gray-300 text-sm"
								onChange={(event) => {
									setAttributes({
										title: event.target.value,
									});
								}}
								placeholder="Title"
							/>
						</div>
						<div className="mb-2">
							<label className="text-sm mb-0.5 text-gray-600">Sub title</label>
							<input
								type="text"
								value={subTitle}
								className="w-full rounded border-gray-300 text-sm"
								onChange={(event) => {
									setAttributes({
										subTitle: event.target.value,
									});
								}}
								placeholder="Sub Title"
							/>
						</div>
						<div className="mb-2">
							<label className="text-sm mb-0.5 text-gray-600">Has Action</label>
							<ToggleControl
								label="Learn More Button"
								checked={hasAction}
								onChange={() => {
									setAttributes({
										hasAction: !hasAction,
									});
								}}
							/>
						</div>
						{hasAction ? (
							<div className="mb-2">
								<label className="text-sm mb-0.5 text-gray-600">
									Action label
								</label>
								<input
									type="text"
									value={actionLabel}
									className="w-full rounded border-gray-300 text-sm"
									onChange={(event) => {
										setAttributes({
											actionLabel: event.target.value,
										});
									}}
									placeholder="Learn more"
								/>
							</div>
						) : null}
					</PanelBody>
				</InspectorControls>
			</Fragment>
		</>
	);
};

export default ProductQualityCard;
