import {
    createStore,
    AppmakerFormProvider,
    useAppmakerFormStore,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import useThemeStore from '../store/theme';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import { SaveIcon } from '@heroicons/react/solid';
import { useParams } from '@appmaker/core/routes';
import { useMutation } from '@apollo/client';
import { UPDATE_THEME_INSTALL } from '../api/graphql';
import { toast } from '@appmaker/components/ReactHotToastIntegrator';
import { useEffect } from 'react';
import { isEqual, size } from 'lodash';


const SaveButton = ({ loading, onSaveSchema = () => { } }) => {
    return (
        <button
            type="button"
            disabled={loading}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => {
                onSaveSchema();
            }}
        >
            {loading ? (
                <>
                    <LoadingIcon
                        className="-ml-0.5 h-6 w-6 animate-spin"
                        aria-hidden="true"
                    />
                    Saving...
                </>
            ) : (
                <>
                    <SaveIcon className="-ml-0.5 h-6 w-6" aria-hidden="true" />
                    Save Changes
                </>
            )}
        </button>
    );
};

const Form = () => {
    let { handle, projectId } = useParams();
    const installData = useThemeStore((store) => store.installedData);
    const unsaved = useThemeStore((state) => state.unSaved);
    const setUnsaved = useThemeStore((state) => state.setUnsaved);
    const syncPlatformVersion = useThemeStore((state) => state.syncPlatformVersion);
    const { formData } = useAppmakerFormStore();
    const currentInstallation = installData?.find(
        (item) => item?.theme?.handle === handle
    );
    const [updateSettings, { loading }] = useMutation(UPDATE_THEME_INSTALL, {
        context: {
            headers: {
                'x-appmaker-project-id': projectId,
            },
        },
    });

    const onSaveSchema = async () => {
        const data = JSON.parse(JSON.stringify(formData));
        toast.promise(
            (async () => {
                await updateSettings({
                    variables: {
                        where: {
                            id: currentInstallation?.id,
                        },
                        data: {
                            androidVersion: data?.androidVersion,
                            iosVersion: data?.iosVersion
                        },
                    },
                });
                syncPlatformVersion(currentInstallation?.id, {
                    androidVersion: data?.androidVersion,
                    iosVersion: data?.iosVersion
                });
            })(),
            {
                loading: 'Saving...',
                success: <b>Settings saved!</b>,
                error: <b>Unable to save</b>,
            }
        );
    };

    const schema = {
        "properties": {
            androidVersion: {
                "type": "string",
                "label": "Android version",
                "description": "Version like '<20 AND >10'"
            },
            iosVersion: {
                "type": "string",
                "label": "iOS version",
                "description": "Version like '>20'"
            }
        }
    }
    useEffect(() => {
        if (formData && currentInstallation &&
            !isEqual(
                JSON.parse(JSON.stringify(formData)),
                { androidVersion: currentInstallation?.androidVersion, iosVersion: currentInstallation?.iosVersion }
            )
        ) {
            setUnsaved(true);
        } else {
            setUnsaved(false)
        }
    }, [formData, currentInstallation, setUnsaved]);

    if (!currentInstallation) return null;

    return (
        <div className="m-4 bg-white max-w-3xl p-4 shadow-sm rounded-lg mx-auto">
            <div className="mb-4 pb-4 border-b flex items-end justify-between">
                <div>
                    <h1 className="text-xl font-semibold">Version</h1>
                    <p className="text-gray-500 text-sm">
                        Configure{' '}
                        <span className="lowercase">{currentInstallation?.theme?.name}</span>{' '}
                        theme version.
                    </p>
                </div>
                {unsaved && (
                    <SaveButton loading={loading} onSaveSchema={onSaveSchema} />
                )}
            </div>
            {currentInstallation && (
                <>
                    <AppmakerForm
                        formVersion={2}
                        // value={{ formData: flatten(formData) }}
                        schema={schema ?? {}}
                    />
                    <div className="pt-3">
                        <SaveButton loading={loading} onSaveSchema={onSaveSchema} />
                    </div>
                </>
            )}
        </div>
    )
}

const FormWrapper = (props) => {
    const themeData = useThemeStore((store) => store.theme);
    const isLoading = useThemeStore((store) => store.isLoading);
    const installData = useThemeStore((store) => store.installedData);
    const currentTheme = installData?.find(
        (item) => item?.theme?.handle === themeData?.handle
    );
    const isThemeInstalled = installData?.length > 0;
    if (isLoading || !isThemeInstalled) {
        return null;
    }
    return (
        <AppmakerFormProvider
            createStore={createStore({
                formData: {
                    androidVersion: currentTheme?.androidVersion ?? '*',
                    iosVersion: currentTheme?.iosVersion ?? '*'
                },
            })}
        >
            <Form {...props} />
        </AppmakerFormProvider>
    );
};

export default FormWrapper