export default function Buttons({ attributes }) {
	return (
		<div className="relative">
			<span className="z-0 flex shadow-sm rounded-md items-center justify-between">
				<button
					type="button"
					className="w-full items-center py-3 border border-transparent text-base font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
				>
					{attributes.addCartText || "Add To cart"}
				</button>
				{attributes.buyNowText ? (
					<button
						type="button"
						className="w-full items-center py-3 border border-transparent text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						{attributes.buyNowText || "Buy Now"}
					</button>
				) : null}
			</span>
		</div>
	);
}

Buttons.attributesSchema = {
	type: 'object',
	properties: {
		in_stock: {
			type: 'string',
			label: 'In stock',
			default: '',
		},
		addCartText: {
			type: 'string',
			label: 'In stock',
			default: 'Add to cart',
		},
		buyNowText: {
			type: 'string',
			label: 'Buy Now Text',
			default: 'Buy now',
		},
		fixedFooter: {
			type: 'string',
			label: 'Fix when scrolling',
			default: '',
			uiType: 'Switch',
		},
		addCartButtonStyle: {
			type: 'json',
			label: 'Add to cart button style',
			default: '',
		},
		addCartFontColor: {
			type: 'string',
			label: 'Add to cart font color',
			default: '',
		},
		buyNowButtonStyle: {
			type: 'json',
			label: 'Buy Now Button Style',
			default: '',
		},

		buyNowFontColor: {
			type: 'string',
			label: 'Buy Now Font Color',
			default: '',
		},
		enableNotifyMe: {
			type: 'string',
			label: 'Enable Notify Me',
			default: '',
			uiType: 'Switch',
		},
		__appmakerStylesClassName: {
			type: 'string',
			label: 'Custom styles class name',
			default: '',
		},
		// showInReverseOrder: {
		// 	type: 'string',
		// 	label: 'Show In Reverse Order',
		// 	default: '',
		// },
		// in_stock: '',
		// addCartText: 'Add to Cart',
		// buyNowText: 'Buy',
	},
};
