const StepTen = () => {
    return (
        <div>
            <p className="border border-red-200 mb-10">
                NOTE: There might be  warnings, minor issues, or a combination of the two, then you can still publish your app, but we recommend addressing them before publishing. click Show more to review the details and resolve any problems.

                Now your app is all set to publish.
            </p>
            <ul className="list-outside ml-4 list-disc flex flex-col gap-3">
                <li>
                    Start rollout to production

                    <img
                        className="w-full rounded-lg border border-gray-100 mt-1"
                        src="/images/publish/android/new/step-10-1.png"
                        alt="step one"
                    />
                </li>
                <li>
                    Click Rollout to confirm. This will publish your app to all Google Play users in the countries you selected.
                    <img
                        className="w-full rounded-lg border border-gray-100 mt-1"
                        src="/images/publish/android/new/step-10-2.png"
                        alt="step one"
                    />
                </li>

            </ul>
        </div>
    )
}

export default StepTen