import { useQueryParam, StringParam, BooleanParam } from 'use-query-params';
import { useEffect, useState } from 'react';
import create from 'zustand';

const useParams = () => {
    const [step, setStep] = useQueryParam('step', StringParam);
    useState(() => {
        if (step === undefined) {
            setStep('initial')
        }
    }, [step]);

    return [
        step,
        setStep
    ]

}
const modal = create((set) => ({
    isOpen: false,
    setOpen: (value) => set({ isOpen: value }),
}))
const useModal = () => {
    const set = modal(state => state.setOpen);
    const get = modal(state => state.isOpen)
    return [get, set]
}
export {
    useParams,
    useModal
}