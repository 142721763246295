import Editor from "@monaco-editor/react";
import { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "react-use";
import Twig from "../helper/twig";
function getOutPut(template, jsonconfig) {
  try {
    var template = Twig.twig({
      data: template
    });
    const vars = JSON.parse(jsonconfig);
    const outVar = vars?.app?.data ? vars?.app?.data : vars;
    const out = template.render(outVar);
    return out;
  } catch (error) {
    return error.message;
  }
  // return "Output is good" + template;
}
export default function CodeEditor() {
  const [fileContent, setFileContent] = useLocalStorage("twig-file-content");
  const [jsonConfig, setJsonConfig] = useLocalStorage("twig-json-config");
  const [output, setOutput] = useState("");
  const [language, setLanguage] = useState("twig");

  const fileContentEditor = useRef(null);
  const jsonConfigEditor = useRef(null);
  const outputEditor = useRef(null);
  useEffect(() => {
    const templateOutput = getOutPut(fileContent, jsonConfig);
    // output.current.set
    setOutput(templateOutput);
  }, [fileContent, jsonConfig]);
  useEffect(() => {
    if (outputEditor && outputEditor.current)
      outputEditor.current.setValue(output);
  }, [output]);

  return (
    <div className="grid h-screen grid-cols-2 overflow-hidden p-2">
      <div className="flex flex-col bg-green-800">
        <div className="flex-1 border-2 overflow-auto ">
          fileContent
          <Editor
            onMount={(editor) => {
              fileContentEditor.current = editor;
            }}
            height="49vh"
            defaultLanguage="twig"
            defaultValue={fileContent}
            onChange={setFileContent}
          />
        </div>
        <div className="flex-1 overflow-auto ">
          <div className="flex-1 overflow-auto">
            <div className="flex items-center justify-between p-2">
              <p>Config</p>
              <button
                onClick={() => {
                  jsonConfigEditor.current
                    .getAction("editor.action.formatDocument")
                    .run();
                }}
                className="rounded bg-blue-600 px-3 py-1 text-white"
              >
                Format
              </button>
            </div>
          </div>
          <Editor
            onMount={(editor) => {
              jsonConfigEditor.current = editor;
            }}
            height="49vh"
            defaultLanguage="json"
            defaultValue={jsonConfig}
            onChange={setJsonConfig}
          />
        </div>
      </div>
      <div className="overflow-auto bg-yellow-400">
        Output
        <Editor
          onMount={(editor) => {
            outputEditor.current = editor;
          }}
          height="100vh"
          defaultLanguage="text"
          defaultValue={output}
        />
      </div>
    </div>
  );
}
