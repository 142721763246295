import React, { useState } from 'react';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
// import {useInstalledDataSourceList} from '../hooks';
import { useInstalledDataSourceList } from '../hooks/dataSourceInstalled';
import AppmakerRadioGroup from '@appmaker/core/components/AppmakerForm/fields/RadioGroup';
import { RefreshIcon } from '@heroicons/react/solid';
const variableDataSource = {
	label: 'Variables',
	properties: {
		repeatable: {
			type: 'string',
			label: 'repeatable',
			uiType: 'Switch',
		},
		params: {
			label: 'Params',
			type: 'string',
		},
		transformKey: {
			label: 'Transform Key',
			type: 'string',
		},
	},
};
export default function DataSourceSelector({ formData, setFormData }) {
	// const [formData, setFormData] = React.useState({});
	const { isLoading, list } = useInstalledDataSourceList();
	// const list = useDataSource(data => data.installedList);
	const sourceTypeOptions = [
		{ label: 'Data Source', value: 'DataSource' },
		{ label: 'Data Variable', value: 'DataVariable' },
	];

	const [sourceType, setSourceType] = useState('DataSource');

	const options = {
		// 'variables':
	};
	if (sourceType === 'DataSource') {
		list.map((item) => {
			options[item.id] = {
				label: item.dataSourceName || item.name,
				// properties: dataSourceProperties,
				properties: item.dataSourceSchema?.properties || {},
			};
		});
	} else {
		options['variables'] = variableDataSource;
	}
	const onSubmit = (data) => {
		// console.log('onSubmit', data);
		setFormData({
			formData: {
				...data.formData,
				repeatItem: sourceType,
				attributes: {
					...data.formData,
					mapping: data.formData.mapping? {
						items: data.formData.mapping,
					}:null,
					attributes: {},
				},
			},
			_internal: {
				...data._internal,
			},
		});
	};
	if (isLoading) {
		return <div className="h-12 bg-gray-200 rounded-lg animate-pulse" />;
	}
	console.log(options);
	return (
		<>
			{/* <StateView json={options} /> */}
			<div className="flex justify-end">
				<button
					onClick={() => setFormData({})}
					type="button"
					className="inline-flex items-center space-x-1 px-1 -mb-4 text-xs font-medium rounded text-red-900 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-500"
				>
					<RefreshIcon className="h-4 w-4" />
					<span>Reset Data Source</span>
				</button>
			</div>
			<AppmakerRadioGroup
				onChange={setSourceType}
				defaultValue={sourceType}
				properties={{
					type: 'string',
					title: 'Source type',
					options: sourceTypeOptions,
					className: 'mb-4',
				}}
			/>
			{sourceType}
			<AppmakerForm
				formVersion={2}
				options={options}
				value={{ ...formData, schema: options }}
				optionKey={'source'}
				setValue={onSubmit}
				debug={true}
			/>
			{/* <pre>{JSON.stringify(formData,null,2)}</pre> */}
		</>
	);
}
