import { useEffect } from 'react'
import { useParams } from '@appmaker/core/routes';
import { GET_EXTENSION_INSTALLS } from '../api/graphql';
import { useQuery } from '@apollo/client';
import {
    DetailPageProvider,
    createStore,
    useExtensionDetail,
} from '../store/details';
import Loader from '@appmaker/components/Loading';
import Notification from '@appmaker/components/ReactHotToastIntegrator';
import VersionForm from './VersionForm';
import DetailHeader from './DetailHeader';

const Version = () => {
    const { handle, projectId } = useParams();
    const setExtension = useExtensionDetail((state) => state.setDetails);
    const setLoading = useExtensionDetail((state) => state.setLoading);
    const { data, loading, error } = useQuery(GET_EXTENSION_INSTALLS, {
        fetchPolicy: 'no-cache',
        variables: {
            extensionWhere: {
                handle: handle,
            },
            installWhere: {
                "project": {
                    "remoteProjectId": {
                        "equals": parseInt(projectId)
                    }
                },
                extension: {
                    handle: {
                        equals: handle,
                    },
                },
            },
        },
        context: {
            headers: {
                'x-appmaker-project-id': projectId,
            },
        },
    });
    useEffect(() => {
        setLoading(loading);
    }, [loading]);
    useEffect(() => {
        if (data?.extension?.id) {
            setExtension(data, data?.extensionInstalls);

        }
    }, [data?.extension]);
    if (loading) return <Loader />;
    return (
        <div className="bg-gray-50 h-screen overflow-y-auto">
        <div className="w-full mx-auto relative">
            <DetailHeader />
            <VersionForm />
        </div>
    </div>
    )
}

const VersionWrapper = () => {
    return (
        <Notification>
            <DetailPageProvider createStore={createStore}>
                <Version />
            </DetailPageProvider>
        </Notification>
    )
}

export default VersionWrapper;