import appmaker from '@appmaker/core/index';
import { Spacer } from './Spacer';
import { SwitchVerticalIcon, ClockIcon } from '@heroicons/react/solid';
import CountDownWidget from './CountDownWidget';
const registerCustomBlocks = () => {
	appmaker.registerAppmakerBlockType('appmaker/spacer', {
		title: 'Spacer',
		icon: <SwitchVerticalIcon />,
		Block: Spacer,
	});
	appmaker.registerAppmakerBlockType('appmaker/count-down-timer', {
		title: 'Countdown Timer',
		icon: <ClockIcon />,
		Block: CountDownWidget,
	});
};

export { registerCustomBlocks };
