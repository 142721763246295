import { createBlock } from '@wordpress/blocks';
window.createBlock = createBlock;
const blocks = [{
	"clientId": "1d709b05-56b9-46dd-842d-aec20c847c1e",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {
			"action": "OPEN_SEARCH"
		},
		"___internal_appmakerAction": {
			"action": {
				"value": "OPEN_SEARCH",
				"label": "Open search page"
			}
		},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-15.png?v=1658818649",
			"id": "gid://shopify/MediaImage/31011567763713",
			"title": "a-15.png"
		},
		"imageContainerStyle": {
			"height": 157,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 157,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "7620e176-9e5e-42f9-a7d2-166cf2c3a44b",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 4,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"enableSplit": "No"
	},
	"innerBlocks": [{
		"clientId": "8969d873-a6df-4df6-8a98-4b4222fdd183",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-16.png?v=1658818886",
				"id": "gid://shopify/MediaImage/31011608068353",
				"title": "a-16.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "4b94bd88-1099-4144-9ad4-d1210e69bdcb",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-3_3372056e-a8fc-4f32-9550-cfdfd364eaa8.png?v=1658818890",
				"id": "gid://shopify/MediaImage/31011609280769",
				"title": "a-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "c5b8d5ba-805d-459b-9e2e-547af81cd7f0",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-4.png?v=1658818888",
				"id": "gid://shopify/MediaImage/31011609149697",
				"title": "a-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "3fac1fb4-bdb3-4791-9317-f5c4d3e9c053",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-5.png?v=1658818886",
				"id": "gid://shopify/MediaImage/31011608101121",
				"title": "a-5.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 407,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "24e1232b-f23c-4300-8fb6-54443f9b7974",
	"name": "appmaker/slider",
	"isValid": true,
	"attributes": {
		"autoPlay": false,
		"delay": 1,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "7854c0c6-1597-4b50-a5f1-f44a37ae8582",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-6_4051eeae-0683-4caa-8064-6a4e56a299ac.png?v=1658818957",
				"id": "gid://shopify/MediaImage/31011619143937",
				"title": "a-6.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 546,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "6e9e7244-ddb6-4040-baab-108ff48e28dd",
		"name": "appmaker/slider-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-7_acbe7df9-8181-447a-ad9f-10446b011dce.png?v=1658818957",
				"id": "gid://shopify/MediaImage/31011619012865",
				"title": "a-7.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 546,
				"width": 1080
			},
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "1ba1966f-1c02-4351-a83a-d3ae8e2cae2e",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 3,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "c832e065-76cf-4a2c-a69f-d3bc9c442432",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a_b3434161-0a96-41a1-922f-53348a65e8d3.png?v=1658819024",
				"id": "gid://shopify/MediaImage/31011629728001",
				"title": "a.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 464,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "f5cbb77c-cdb6-435d-8813-d5142524dc7f",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-1_7e022fe3-14cd-482e-8d8d-2630b7859d8d.png?v=1658819022",
				"id": "gid://shopify/MediaImage/31011628974337",
				"title": "a-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 464,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "e60dffac-723e-4c38-9c22-d9a54288d016",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-2_cb7edd9f-d134-4b87-aaf5-8de5ca5dc8e1.png?v=1658819022",
				"id": "gid://shopify/MediaImage/31011628941569",
				"title": "a-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 464,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "bb66dcd8-5411-4eac-84bd-6496db73c3c1",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z-1_c542f47e-6a3b-4f1c-87be-e19c1efb4bbe.png?v=1658819243",
			"id": "gid://shopify/MediaImage/31011654795521",
			"title": "z-1.png"
		},
		"imageContainerStyle": {
			"height": 172,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 172,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "153af00e-4579-4577-87a8-94c70174914e",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "5e5cae1d-83fe-419e-bc06-610de3d7b7e0",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Frame_11946.png?v=1658819264",
				"id": "gid://shopify/MediaImage/31011657515265",
				"title": "Frame 11946.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 503,
				"width": 437
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "2a0807d6-6ebc-44e9-912e-b43383436e48",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/Frame_11945.png?v=1658819265",
				"id": "gid://shopify/MediaImage/31011657482497",
				"title": "Frame 11945.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 503,
				"width": 437
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "758022b7-346a-4df5-a955-3b8fcd0764c4",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/c_809bd9d8-a5c9-4f8d-892a-8b3839c47e2d.png?v=1658819303",
			"id": "gid://shopify/MediaImage/31011662692609",
			"title": "c.png"
		},
		"imageContainerStyle": {
			"height": 546,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 546,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "59fbdbef-7c64-465a-9ccb-a28a7c8bf8b4",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z-2_ab158b25-dd44-4bfd-91c0-d1e602368670.png?v=1658819370",
			"id": "gid://shopify/MediaImage/31011670032641",
			"title": "z-2.png"
		},
		"imageContainerStyle": {
			"height": 118,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 118,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "bb9afddb-2eda-4981-9cc6-52f338048f98",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "7f607d39-6224-40d4-aae3-e464b1f428f4",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-2_d19c15b4-6a74-4cc6-8292-25372b9c21f0.png?v=1658819411",
				"id": "gid://shopify/MediaImage/31011673866497",
				"title": "b-2.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 387,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "cb1ee344-0bd2-4b03-a38f-9235f6a2b13a",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-6.png?v=1658819413",
				"id": "gid://shopify/MediaImage/31011674325249",
				"title": "b-6.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 387,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "fc1bc609-bb50-419a-883d-3f61c191331b",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-3.png?v=1658819415",
				"id": "gid://shopify/MediaImage/31011674358017",
				"title": "b-3.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 384,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "c826c2dc-86d5-4d11-9ae0-744b220dc85f",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-7.png?v=1658819413",
				"id": "gid://shopify/MediaImage/31011674292481",
				"title": "b-7.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 384,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "807635fd-0bd3-452d-8b05-0fb539939b33",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/e_28f647d2-01d3-4539-8b07-078cf289d852.png?v=1658819477",
			"id": "gid://shopify/MediaImage/31011680715009",
			"title": "e.png"
		},
		"imageContainerStyle": {
			"height": 546,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 546,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "51d10e7a-469a-493a-ac9b-a815dff994ea",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z-3.png?v=1658819497",
			"id": "gid://shopify/MediaImage/31011682386177",
			"title": "z-3.png"
		},
		"imageContainerStyle": {
			"height": 118,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 118,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "ec36fe34-35fa-4f34-a4e6-a12c4bf1e49e",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-10.png?v=1658819510",
			"id": "gid://shopify/MediaImage/31011683696897",
			"title": "a-10.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "b9627f0c-6a74-4829-a91a-c53520e6d25c",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-11.png?v=1658819511",
			"id": "gid://shopify/MediaImage/31011683729665",
			"title": "a-11.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "dfcd3e24-5997-425a-85b0-9f03ed07a816",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-12.png?v=1658819513",
			"id": "gid://shopify/MediaImage/31011683893505",
			"title": "a-12.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "82e709ca-72c0-429e-bcb3-5e4cefc78b7e",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-8.png?v=1658819499",
			"id": "gid://shopify/MediaImage/31011682910465",
			"title": "a-8.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "524822d4-fc08-4c06-a000-d9239263c7d7",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-9.png?v=1658819502",
			"id": "gid://shopify/MediaImage/31011683172609",
			"title": "a-9.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "67173ec9-b9eb-4048-ac3c-338f5bce37b9",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-13.png?v=1658819517",
			"id": "gid://shopify/MediaImage/31011684221185",
			"title": "a-13.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "68024384-3c9a-4ef3-a079-4e77438cb3df",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/a-14.png?v=1658819518",
			"id": "gid://shopify/MediaImage/31011684450561",
			"title": "a-14.png"
		},
		"imageContainerStyle": {
			"height": 384,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 384,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "e167a6bb-e138-423c-80b9-15f773e9c115",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/z_cc3d68e9-7b07-4af1-8cc8-2474be9cdff0.png?v=1658819573",
			"id": "gid://shopify/MediaImage/31011690545409",
			"title": "z.png"
		},
		"imageContainerStyle": {
			"height": 118,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 118,
			"width": 1080
		}
	},
	"innerBlocks": []
}, {
	"clientId": "9ed86f04-0453-43e8-9bc0-0397b0e7787a",
	"name": "appmaker/grid",
	"isValid": true,
	"attributes": {
		"title": "sample title",
		"itemsPerLine": 2,
		"ctaText": "View more button",
		"showViewMoreButton": false,
		"showTitle": false,
		"appmakerAction": {},
		"___internal_appmakerAction": {}
	},
	"innerBlocks": [{
		"clientId": "de4174ed-1e5c-4f56-ac9e-e6f569d462de",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b_eb19f78d-5480-4c4f-abb8-c6ded9ecdfd5.png?v=1658819600",
				"id": "gid://shopify/MediaImage/31011692609793",
				"title": "b.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 387,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "a5d48812-9668-447b-9a24-e94e6782b025",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-4.png?v=1658819608",
				"id": "gid://shopify/MediaImage/31011693035777",
				"title": "b-4.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 387,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "e6387c04-418b-4bc9-8682-e2707bdab15b",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-1_3d40f5ef-5b43-48d9-b36f-52a2fc0f67d9.png?v=1658819608",
				"id": "gid://shopify/MediaImage/31011693003009",
				"title": "b-1.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 384,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}, {
		"clientId": "2c37c77a-5562-4d34-a3cf-996303667109",
		"name": "appmaker/grid-item",
		"isValid": true,
		"attributes": {
			"image": {
				"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/b-5.png?v=1658819610",
				"id": "gid://shopify/MediaImage/31011693134081",
				"title": "b-5.png"
			},
			"text": "",
			"imageContainerStyle": {
				"height": 384,
				"width": 407
			},
			"showText": false,
			"appmakerAction": {},
			"___internal_appmakerAction": {}
		},
		"innerBlocks": []
	}]
}, {
	"clientId": "b5adc57d-2e12-4ecb-996e-17a9137b14e7",
	"name": "appmaker/banner",
	"isValid": true,
	"attributes": {
		"appmakerAction": {},
		"___internal_appmakerAction": {},
		"image": {
			"url": "https://cdn.shopify.com/s/files/1/0606/0258/1249/files/d_0390bec6-6f20-495e-b1a1-453a058d4521.png?v=1658819652",
			"id": "gid://shopify/MediaImage/31011696607489",
			"title": "d.png"
		},
		"imageContainerStyle": {
			"height": 546,
			"width": 1080
		},
		"thumbnail_meta": {
			"height": 546,
			"width": 1080
		}
	},
	"innerBlocks": []
}]
const template = {
	blocks,
	id: 'retro',
	name: 'Retro Kicks',
	category: 'Retro',
	price: 'Free',
	imageSrc: '/images/themes/theme-shoe.png',
	previewSrc: '/images/preview/templates/theme-shoe.png',
	imageAlt: 'Mobile app retro theme',
	description: "Our NewKicks theme is inspired by flat retro designs and is perfect for your new age fashion and accessories brands. Get your refreshed look in the click of a button!"
};
export default template;
