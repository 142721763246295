import create from 'zustand'
import produce from 'immer'
import { devtools, persist } from 'zustand/middleware'
import appmaker from '../appmaker-core/index';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/countBy';
import { getAppmakerMeta, setAppmakerMeta } from '../appmaker-core/api/meta';
import { getProjectId } from '../appmaker-core/store/project';
const exampleSteps = [
    {
        id: 'app-create',
        title: "Create App",
        order: 0,
        status: '',
        steps: [
            {
                id: 'appIcon',
                title: "Change App icon",
                status: '',
            }
        ]
    }
]
appmaker.doAction('onboarding-step-status', { stepId: 'app-create', status: 'complete' })
appmaker.doAction('onboarding-step-set-current-step', { stepId: 'app-create', })


const useStore = create(
    // persist(
    devtools(
        (set) => (
            {
                // showNext: true,
                loadedFromServer: false,
                steps: [],
                currentSubStep: 0,
                stepLoading: false,
                stepData: {},
                currentStep: 0,
                stepStatus: {},
                completedStepsCount: 0,
                setStepLoading: stepLoading => set({ stepLoading }),
                addStep: step => set(produce(state => {
                    if (!state.stepData[step.id]) {
                        state.steps.push(step.id)
                        state.stepData[step.id] = step;
                        state.steps = sortBy(state.steps, (id) => { return state.stepData[id].order || 1 })
                    }
                })),
                setStepStatus: (stepId, stepStatus = true) => set(produce(state => {
                    if (state.stepData[stepId]) {
                        state.stepStatus[stepId] = stepStatus;
                        console.log("CALLL", sumBy(Object.keys(state.stepStatus), (key) => state.stepStatus[key] ? 1 : 0))
                        state.completedStepsCount = sumBy(Object.keys(state.stepStatus), ((key) => state.stepStatus[key] ? 1 : 0))[1]
                    }
                })),
                setSteps: steps => set({ steps }),
                nextStep: () => set(state => {
                    if (state.steps.length > state.currentStep + 1) {
                        state.currentStep = state.currentStep + 1;
                    }
                }),
                prevStep: () => set(state => {
                    if (state.currentStep > 0) {
                        state.currentStep = state.currentStep - 1;
                    }
                }),
                loadStore: async () => {
                    const data = await getAppmakerMeta(getProjectId(), 'onboarding_step');
                    set({ ...data, loadedFromServer: true })
                },
                saveStore: async (state) => {
                    const data = await setAppmakerMeta(getProjectId(), 'onboarding_step', state);
                    // set({ ...data, loadedFromServer: true })
                }
                // prevStep: () => set(state => ({ currentStep: state.currentStep - 1 }))
            }
        ),
        { name: "On boarding " }
    ),
    // {
    //     name: 'steps-v3',
    //     partialize: ({ stepStatus,
    //         completedStepsCount,
    //         currentStep }) => {
    //         // return state
    //         return {
    //             stepStatus,
    //             completedStepsCount,
    //             currentStep
    //         }
    //     }
    // }
    // )

)
export {
    useStore,
    exampleSteps
}
appmaker.addAction('register-onboarding-steps', 'appmaker', (item) => {
    useStore.getState().addStep(item)
})
appmaker.addAction('onboarding-step-status', 'appmaker', (stepId, stepStatus) => {
    useStore.getState().setStepStatus(stepId, stepStatus)
})
