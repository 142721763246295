import React from 'react';
import Android from '../components/android';
import IOS from '../components/ios';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import {
	Switch,
	Route,
	useRouteMatch,
	NavLink,
	generatePath,
} from '@appmaker/core/routes';
import appmaker from '@appmaker/core/index';
import { getProjectId } from '@appmaker/core/store/project'
const UploadStore = () => {
	let { path, params } = useRouteMatch();
	const projectId = getProjectId();
	return (
		<div className="bg-gray-50 h-screen py-8 overflow-y-auto">
			<div className="max-w-4xl mx-auto bg-white border border-gray-100 rounded-lg shadow-lg overflow-hidden">
				<div className="flex items-center gap-4 p-4 border-b border-gray-100">
					<NavLink
						to={`${generatePath(path, { ...params })}/android`}
						className="px-4 py-2 rounded-md text-md font-medium flex items-center gap-1 border bg-gray-100 text-gray-500"
						activeClassName="border-green-500 bg-green-50 text-gray-900"
						onClick={() => {
							appmaker.doAction('appmaker-analytics-event', {
								event: 'upload_to_store_android_click',
								params: {
									project_id: projectId
								}
							})
						}}
					>
						<img
							src="/images/misc/playstore-logo.png"
							className={'h-6 w-6'}
							alt="playstore icon"
						/>
						Play Store
					</NavLink>
					<NavLink
						to={`${generatePath(path, { ...params })}/ios`}
						className="px-4 py-2 rounded-md text-md font-medium flex items-center gap-1 border bg-gray-100 text-gray-500"
						activeClassName="border-blue-500 bg-blue-50 text-gray-900"
						onClick={() => {
							appmaker.doAction('appmaker-analytics-event', {
								event: 'upload_to_store_ios_click',
								params: {
									project_id: projectId
								}
							})
						}}
					>
						<img
							src="/images/misc/appstore-logo.png"
							className={'h-6 w-6'}
							alt="appstore icon"
						/>
						App Store
					</NavLink>
				</div>
				<Switch>
					<Route exact path={`${path}/android`}>
						<Android />
					</Route>
					<Route exact path={`${path}/ios`}>
						<IOS />
					</Route>
				</Switch>
			</div>
		</div>
	);
};

export default UploadStore;
