import {Link} from '@appmaker/core/routes';

const MenuItem = ({inAppPageList=[],basePath})=>{
    return <div>
        {inAppPageList.map(listItem=>{
            return <div>
                <Link to={`${basePath}/${listItem.id}`}>{listItem.label}</Link>
            </div>
        })}
    </div>
}

export default MenuItem