import coreApi from '@appmaker/core/api/core';
import { useReducer, useEffect } from 'react';
import useProjectGraphQl from '../store/project-graphql';

const initialState = {
	loading: true,
	error: '',
	projectData: {},
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_START':
			return initialState;
		case 'FETCH_SUCCESS':
			return {
				loading: false,
				projectData: action.payload,
				error: '',
			};
		case 'FETCH_ERROR':
			return {
				loading: false,
				projectData: {},
				error: action.error,
			};
		default:
			return state;
	}
};

function useProjectData(config) {
	const { projectId } = config;
	const [state, dispatch] = useReducer(reducer, initialState);
	const { setProject } = useProjectGraphQl();
	useEffect(() => {
		async function start() {
			dispatch({
				type: 'FETCH_START',
			});
			const data = await coreApi.getProject(projectId);
			try {
				const graphqlData = await coreApi.getProjectGraphQl(projectId);
				setProject(graphqlData);
			} catch (error) {}
			dispatch({
				type: 'FETCH_SUCCESS',
				payload: data,
			});
		}
		start();
	}, [projectId]);
	return state;
}

export default useProjectData;
