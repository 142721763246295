import React, { useState } from 'react';
import { useBlockProps } from '@wordpress/block-editor';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import {
  ToolbarGroup
} from '@wordpress/components';
import {
  BlockControls,
} from '@wordpress/block-editor';
import { PencilIcon } from '@heroicons/react/solid';
import { collection } from '../../../../api';

import ProductListView from './ProductListView'

const options = {
  PRODUCTS_BY_COLLECTION: {
    label: "Open By collection",
    properties: {
      collectionId: {
        type: 'promise-select',
        label: "Choose collection",
        getOptions: async (inputValue, { projectId }) => {
          const collections = await collection();
          const finalList = collections.map(item => ({ label: item.label, value: item.id }))
          return finalList;
        }
      },
    }
  }
};
function ActionSelector(
  { action, setAction }
) {
  const schema = options
  return (
    <div>

      {/* <StateView json={action} /> */}
      <AppmakerForm
        options={options}
        value={{ ...action, schema }}
        optionKey={'type'}
        setValue={setAction}
        debug={false}
      />
    </div>
  )
}
export default function ProductScroller({ attributes, setAttributes }) {
  const { config = {}, ___internal } = attributes
  const { collectionId } = config
  const [isEditing, setIsEditing] = useState(true)
  const getBlockControls = () => {
    return (
      <BlockControls>
        <ToolbarGroup
          controls={[
            {
              icon: <PencilIcon />,
              title: 'Edit',
              onClick: () => setIsEditing(!isEditing),
              isActive: isEditing,
            },
          ]}
        />
      </BlockControls>
    );
  };
  return (
    <div  {...useBlockProps()}>
      {/* ProductDetail : - ${JSON.stringify(___internal)} */}
      <ProductListView collectionId={collectionId} />
      {getBlockControls()}
      <div className="p-6">
        {isEditing &&
          <ActionSelector
            action={{ formData: config, _internal: ___internal }}
            setAction={({ formData, _internal }) => {
              setAttributes({ config: formData, ___internal: _internal });
            }}
          />}

      </div>
    </div>
  )
}
