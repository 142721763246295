import {
	createStore,
	AppmakerFormProvider,
	useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import Notification, {
	sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';
import { getProjectData } from '@appmaker/core/store/project';
import { getMetaField } from './api';
import { generateQuery } from './helper';
import { versions } from 'packages/admin-tools/routes/ShopifyStorefrontGraphqlExplorer';
import useStore from './store';
import { useState, useEffect } from 'react';
import Preview from './Preview';

const getSchema = () => {
	let schema = {
		properties: [
			{
				id: 'version',
				type: 'string',
				label: 'Version',
				uiType: 'select',
				options: versions,
			},
			{
				id: 'type',
				type: 'string',
				uiType: 'select',
				label: 'Choose type',
				multi: false,
				options: [
					{
						label: 'Collection',
						value: 'collection',
					},
					{
						label: 'Product',
						value: 'product',
					},
				],
			},
			{
				id: 'handle',
				type: 'string',
				label: 'Handle',
				description: 'Handle for collection/product',
			},
			{
				id: 'ids',
				type: 'array',
				uiType: 'infinite-form',
				label: 'Key and namespace',
				properties: {
					namespace: {
						type: 'string',
						label: 'Namespace',
					},
					key: {
						type: 'string',
						label: 'Key',
					},
				},
			},
		],
	};
	return schema;
};

const SaveButton = ({ setSavedFormData }) => {
	const storeApi = useAppmakerFormStoreApi();
	const { loading, projectData } = getProjectData();
	const { SHOPIFY_ACCESS_TOKEN, URL } = projectData.meta || {};
	const setQuery = useStore((store) => store.setQuery);

	const onClickSave = async () => {
		const { formData } = storeApi.getState();
		// if (!formData?.version || formData?.version?.length === 0) {
		// 	sendNotification('Please select version', 'error');
		// 	return;
		// }
		try {
			setSavedFormData(formData);
			const response = await getMetaField({
				version: formData?.version || versions[0].value,
				url: URL,
				token: SHOPIFY_ACCESS_TOKEN,
				type: formData?.type,
				handle: formData?.handle,
				ids: formData?.ids,
			});
			setQuery(
				generateQuery(formData?.type, {
					metafields: response?.data?.data[formData?.type]?.metafields,
				})
			);
		} catch (error) {
			console.log(error);
			sendNotification('Something went wrong', 'error');
		}
	};

	return (
		<button
			className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
			onClick={onClickSave}
		>
			Generate Query
		</button>
	);
};

const FormSchema = ({ schema, setSavedFormData }) => {
	return (
		<>
			<AppmakerForm
				formVersion={2}
				// value={{ formData: flatten(formData) }}
				schema={schema}
			/>
			<SaveButton setSavedFormData={setSavedFormData} />
		</>
	);
};

const Form = ({ setSavedFormData }) => {
	return (
		<AppmakerFormProvider
			createStore={createStore({
				formData: {},
				savedFormData: {},
			})}
		>
		<FormSchema schema={getSchema()} setSavedFormData={setSavedFormData} />
		</AppmakerFormProvider>
	);
};

const MetaFields = () => {
	const [isCopied, setIsCopied] = useState(false);
	const [savedFormData, setSavedFormData] = useState(null);
	const query = useStore((store) => store.query);
	const formData = useStore((store) => store.formData);

	const handleCopyClick = () => {
		navigator.clipboard.writeText(query);
		setIsCopied(true);
	};
	console.log('savedFormData', savedFormData);

	useEffect(() => {
		if (isCopied) {
			sendNotification('Copied to clipboard', undefined);
			setTimeout(() => {
				setIsCopied(false);
			}, 5000);
		}
	}, [isCopied]);

	return (
		<Notification>
			<div className="flex h-screen bg-gray-50">
				<div className="flex-1 overflow-y-auto">
					<div className="w-full rounded resize-none">
						<p className="text-xl py-4 px-6 border-b border-gray-200">
							Shopify Metafield Query Generator
						</p>
						<div className="p-6 bg-gray-50">
							<Form setSavedFormData={setSavedFormData} />
						</div>
						<div className="p-6 bg-gray-50">
						{savedFormData?.handle ?
							<Preview formData={savedFormData} /> : null}

						</div>
					</div>
				</div>
				<div className="flex-2 bg-gray-100 overflow-y-auto max-w-xl">
					<div className="flex items-center justify-between mx-6 my-4">
						<h1 className="text-xl font-bold">Output JSON:</h1>
						<button
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
							onClick={handleCopyClick}
						>
							{isCopied ? 'Copied!' : 'Copy to Clipboard'}
						</button>
					</div>
					<div className="bg-gray-900 m-6 px-4 py-2 overflow-auto rounded-xl">
						<pre className="break-all text-white">
							{query ? query : 'No output'}
						</pre>
					</div>				
				</div>
			</div>
		</Notification>
	);
};

export default MetaFields;
