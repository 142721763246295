import { DatabaseIcon, XIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

function Badge({ attributes = {} }) {
	const { text } = attributes;
	return (
		<div className="inline-flex ml-2 rounded-sm">
			<span className="text-sm bg-gray-600 text-white px-1 rounded-l">
				{text}
			</span>
			<button className="bg-gray-700 text-white px-0.5 rounded-r">
				<XIcon className="h-3 w-3" />
			</button>
		</div>
	);
}

const SelectableInput = ({ attributes = {} }) => {
	const { label, placeholder } = attributes;
	return (
		<div className="mb-4">
			<label
				htmlFor="price"
				className="block text-sm font-medium text-gray-700"
			>
				{label}
			</label>
			<div className="relative mt-1 flex items-center border border-gray-300 rounded-md overflow-hidden">
				<Badge attributes={{ text: 'Amount' }} />
				<input
					type="text"
					name="price"
					id="price"
					className="flex-auto text-sm border-0"
					placeholder={placeholder}
				/>
				<Menu as="div" className="relative">
					<Menu.Button className="px-2 flex items-center">
						<DatabaseIcon className="h-5 w-5 text-gray-400" />
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items
							as="div"
							className="absolute z-10 top-6 right-0 bg-white shadow-2xl w-48 border border-gray-100 rounded-md"
						>
							<Menu.Item>
								<button
									className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem"
								>
									Product Price (USD)
								</button>
							</Menu.Item>
							<Menu.Item>
								<button
									className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem"
								>
									Product Price (CAD)
								</button>
							</Menu.Item>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>
		</div>
	);
};

export default SelectableInput;
