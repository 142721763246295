// @flow strict

import * as React from 'react';
import '../actions';
import appmaker from '@appmaker/core/index';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import { runDataSource } from '@appmaker/core/dataSource';
import Form from '@rjsf/core';
import FormPlay from './components/FormPlay'
function Play() {
	async function exec() {
		try {
			const dataSource = {
				source: 'shopify',
				attributes: {
					methodName: 'products',
				},
			};
			const resp = await runDataSource({ projectId: '5731' }, dataSource, {});
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div>
			<button onClick={exec}>Start</button>
		</div>
	);
}
const schema = {
	type: 'object',
	properties: {
		actionName: {
			type: 'string',
		},
		actionId: {
			type: 'string',
		},
	},
};
function ActionPlayground() {
	// const actions = applyFilters("appmaker-actions-testing");
	const [actionConfig, setActionConfig] = React.useState({});
	const [action, setAction] = React.useState({});
	const actions = appmaker.actions.getAllActions();

	return (
		<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			<div className="py-6">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
				</div>
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					{/* Replace with your content */}

					<div className="py-4">
						<AppmakerForm
							options={actions}
							value={{ schema: actions }}
							debug={true}
							optionKey={'action'}
							setValue={setAction}
						/>
						{/* <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" /> */}
					</div>
					{/* /End replace */}
					<pre className="mt-5 pt-5">
						{JSON.stringify({ action, actionConfig }, null, 2)}
					</pre>
				</div>
			</div>
			<Play />
		</main>
	);
}

export default ActionPlayground;
