import Select from 'react-select';
import { useEffect, useState } from 'react';
import AppmakerForm from './Form';
import {
	createStore,
	AppmakerFormProvider,
	useAppmakerFormStore,
	useStore,
} from '@appmaker/core/components/AppmakerForm/store';
import { isObject, isArray } from 'lodash';

const returnProperties = (rootProperties) => {
	if (isObject(rootProperties)) {
		if (isArray(rootProperties)) {
			return rootProperties.map((item) => item.id);
		} else {
			return Object.keys(rootProperties);
		}
	}
};

const returnSelectedValue = (value, rootProperties) => {
	const propertiesArray = returnProperties(rootProperties) ?? [];
	let returnValue = {};
	if (value && isObject(value)) {
		for (const key in value) {
			if (propertiesArray?.includes(key)) {
				returnValue[key] = value[key];
			}
		}
	}
	return returnValue;
};
/**
 * 
 * @param {*} param0 
 * @returns
 * 
 * 
 * {
	"properties": [
		{
			"id": "Conditional Block",
			"type": "conditional",
			"options": [
				{
					"id": "option-1",
					"label": "Option 1",
					"properties": [
						{
							"id": "option-1-sub",
							"type": "string",
							"label": "Option 1 sub"
						}
					]
				},
				{
					"id": "option-2",
					"label": "Option 2",
					"properties": [
						{
							"id": "option-2-sub",
							"type": "string",
							"label": "Option 2 sub"
						}
					]
				}
			]
		}
	]
}
 */
const Conditional = ({
	properties = {},
	defaultValue = [],
	value,
	onChange,
}) => {
	const formData = useAppmakerFormStore((state) => state.formData);
	const { options: formOptions = [], id, label, placeholder } = properties;
	const options = formOptions?.map((option) => ({
		value: option.id,
		...option,
	}));
	const defaultSelectedOption =
		options?.find((item) => item.value === defaultValue?.type) ?? null;
	const [rootSelectValue, setRootSelectValue] = useState(
		defaultSelectedOption ?? null
	);
	// handle select of root
	const onChangeRootSelect = (rootValue) => {
		// keys from rootSelectValue Properties
		setRootSelectValue(rootValue);
		onChange({
			type: rootValue?.id,
			attributes: defaultValue?.attributes,
		});
	};

	const onChangeForm = (value) => {
		// select only value that is in rooSelectValue.properties pass that to onChange
		onChange({
			type: rootSelectValue?.id,
			attributes: returnSelectedValue(value, rootSelectValue?.properties) ?? {},
		});
	};

	useEffect(() => {
		onChangeForm(formData);
	}, [formData]);

	return (
		<div className={label && 'mt-4'}>
			{label && (
				<label className="block text-sm font-base text-gray-700 mb-1">
					{label}
				</label>
			)}
			<Select
				placeholder={placeholder}
				value={
					options?.filter((item) => item.value === defaultValue?.type) ?? null
				}
				options={options}
				onChange={onChangeRootSelect}
			/>
			{/* defaultValue?.attributes ?? {} */}
			{(rootSelectValue?.properties || defaultSelectedOption?.properties) && (
				<AppmakerForm
					onChange={(value) => {
						//...defaultValue.attributes,
						onChangeForm(value);
					}}
					schema={{
						properties:
							rootSelectValue?.properties ?? defaultSelectedOption?.properties,
					}}
				/>
			)}
		</div>
	);
};

const ConditionalWrapper = (props) => {
	const store = createStore({
		formData: {
			...props?.defaultValue?.attributes,
		},
	});

	return (
		<AppmakerFormProvider createStore={store}>
			<Conditional {...props} />
		</AppmakerFormProvider>
	);
};
export default ConditionalWrapper;
