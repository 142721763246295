import { useEffect, useState } from 'react';
import { GraphiQL } from 'graphiql';
import 'graphiql/graphiql.css';
import axios from 'axios';
import { getProjectData } from '@appmaker/core/store/project';
import Loader from '@appmaker/components/Loading';
import Select from 'react-select';
export const versions = [
	{
		value: 'unstable',
		label: 'Unstable',
	},
	{ value: '2024-07', label: '2024-07 (Release candidate)' },
	{ value: '2024-04', label: '2024-04 (Latest)' },
	{ value: '2024-01', label: '2024-01' },
	{ value: '2023-10', label: '2023-10' },
	{ value: '2023-07', label: '2023-07' },
];

const GraphqlExplorer = () => {
	const { loading, projectData } = getProjectData();

	const [currentVersion, setCurrentVersion] = useState(versions[2]);

	const { SHOPIFY_ACCESS_TOKEN, URL } = projectData.meta || {};
	const [endPoint, setEndPoint] = useState(
		`${URL}/api/${currentVersion.value}/graphql.json`
	);
	const fetcher = async (graphQLParams, header) => {
		try {
			const response = await axios.post(
				endPoint,
				JSON.stringify(graphQLParams),
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						...header?.headers,
						'x-shopify-storefront-access-token': SHOPIFY_ACCESS_TOKEN,
					},
				}
			);
			return response.data;
		} catch (error) {}
		return null;
	};
	useEffect(() => {
		setEndPoint(`${URL}/api/${currentVersion.value}/graphql.json`);
	}, [projectData, currentVersion]);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<div className="m-4 flex space-x-4">
				<div className="w-40">
					<Select
						value={currentVersion}
						options={versions}
						onChange={(value) => {
							setCurrentVersion(value);
						}}
					/>
				</div>
				<span className="border border-gray-300 rounded-md px-2 py-1 w-96">
					{endPoint}
				</span>
				{/* <button
					className="px-2 py-1 bg-blue-700 text-white rounded-md border border-blue-600"
					onClick={() => {
						setEndPoint(newValue);
					}}
				>
					Update
				</button> */}
			</div>
			<div className="h-full">
				<GraphiQL key="store-front-expolrer" fetcher={fetcher} />
			</div>
		</>
	);
};

export default GraphqlExplorer;
