import { ChevronDoubleRightIcon } from '@heroicons/react/outline';
import { DatabaseIcon, HomeIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Modal from '../../components/Modal';

const pages = [
	{ name: 'Collection', href: '#', current: false },
	{ name: 'Items', href: '#', current: true },
];

function Breadcrumb() {
	return (
		<nav
			className="flex border-b border-gray-200 bg-white overflow-y-auto"
			aria-label="Breadcrumb"
		>
			<ol
				role="list"
				className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
			>
				<li className="flex">
					<div className="flex items-center">
						<a href="#" className="text-gray-400 hover:text-gray-500">
							<HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
							<span className="sr-only">Home</span>
						</a>
					</div>
				</li>
				{pages.map((page) => (
					<li key={page.name} className="flex">
						<div className="flex items-center">
							<svg
								className="h-full w-6 flex-shrink-0 text-gray-200"
								viewBox="0 0 24 44"
								preserveAspectRatio="none"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
								aria-hidden="true"
							>
								<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
							</svg>
							<a
								href={page.href}
								className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
								aria-current={page.current ? 'page' : undefined}
							>
								{page.name}
							</a>
						</div>
					</li>
				))}
			</ol>
		</nav>
	);
}
//shopify data dummy
const jsonData = [
	{
		title: 'Product 1',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 2',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 3',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 4',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 5',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 6',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 7',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 8',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	{
		title: 'Product 9',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
];

function List() {
	return (
		<ul
			role="list"
			className="divide-y divide-gray-200 h-full overflow-y-auto sm:pb-40"
		>
			{jsonData.map((item, idx) => (
				<li
					key={idx}
					className="relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50 text-left"
				>
					<div className="flex justify-between space-x-3">
						<div className="min-w-0 flex-1">
							<a href="#" className="block focus:outline-none">
								<span className="absolute inset-0" aria-hidden="true" />
								<p className="truncate text-sm font-medium text-gray-900">
									{item.title}
								</p>
							</a>
						</div>
						<ChevronDoubleRightIcon className="h-4 w-4 text-gray-800" />
					</div>
					<div className="mt-1">
						<p className="text-sm text-gray-600 line-clamp-2">
							{item.description}
						</p>
					</div>
				</li>
			))}
		</ul>
	);
}

const DataSelector = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="mb-4">
			<span className="flex w-full items-center justify-between">
				<label
					htmlFor="email"
					className="block text-sm font-medium text-gray-700"
				>
					Dummy
				</label>
				<button
					type="button"
					className="bg-gray-200 p-1 rounded"
					onClick={() => setIsOpen(true)}
				>
					<DatabaseIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
				</button>
			</span>
			<div className="mt-1">
				<input
					type="email"
					name="email"
					id="email"
					className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
					placeholder="you@example.com"
				/>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				size="4xl"
				className="sm:-my-8 sm:py-8 h-screen relative"
			>
				<div>
					<p className="text-left px-6 py-3 border-b border-gray-200 font-medium">
						Choose from data
					</p>
					<Breadcrumb />
				</div>
				<div className="grid grid-cols-3 divide-x divide-gray-300 h-full">
					<List />
					<List />
					<List />
				</div>
				<div className="sticky bottom-0 w-full bg-white px-4 py-2 border-t border-gray-200 text-right">
					<button className="px-4 py-2 bg-indigo-700 text-white rounded-md">
						Apply Data
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default DataSelector;
