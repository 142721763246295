import SimpleAlert from '@appmaker/components/templates/SimpleAlert';
import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { getVersion } from '../../api/app-build';
import Loader from '@appmaker/components/Loading';
const generateVersions = (currentVersion, limit = 10) => {
	const options = [];
	for (let i = 1; i < limit + 1; i++) {
		let version = currentVersion - i;
		options.push({ value: version, label: version });
	}
	return options;
};

const convertVersion = (data = [], currentVersion) => {
	return data
		?.map((item) => {
			if (currentVersion === item?.version) {
				return undefined;
			}
			return {
				value: item?.version,
				label: `${item?.name} (${item?.version})`,
			};
		})
		?.filter((item) => item !== undefined);
};
const CompareVersion = ({
	isOpen,
	setIsOpen = () => {},
	isLoading,
	currentVersion,
	onSelectVersion = () => {},
	platform,
	projectId,
}) => {
	const [loading, setLoading] = useState(false);
	// const options = generateVersions(currentVersion);
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				const response = await getVersion(projectId, platform);
				const convertedOptions = convertVersion(
					response?.data?.data?.appBuilds,
					currentVersion
				);
				setOptions(convertedOptions);
				setLoading(false);
			} catch (error) {}
		})();
	}, [isOpen]);
	return (
		<SimpleAlert
			openModal={isOpen}
			onClose={() => setIsOpen(false)}
			attributes={{
				title: 'Choose version to compare',
				message: '',
				icon: (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="text-blue-600"
					>
						<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
						<polyline points="14 2 14 8 20 8" />
						<line x1="12" y1="18" x2="12" y2="12" />
						<line x1="9" y1="15" x2="15" y2="15" />
					</svg>
				),
				ctaMain: {
					label: loading || isLoading ? 'Loading...' : 'Select this version',
					action: () => {
						onSelectVersion(selectedOption);
					},
					disabled: loading || isLoading,
				},
				ctaSecondary: {
					label: 'Cancel',
					action: () => setIsOpen(false),
					disabled: loading || isLoading,
				},
			}}
		>
			{loading && (
				<div className="my-4">
					<Loader single={true} />
				</div>
			)}
			{!loading && (
				<div className="my-4">
					<div className="mb-2">
						<label
							htmlFor="handle-name"
							className="block text-sm font-medium text-gray-700"
						>
							Select the version
						</label>
						<div className="mt-1">
							<CreatableSelect
								className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
								isClearable={true}
								onChange={(value) => {
									if (value) {
										setSelectedOption(value?.value);
									}
								}}
								options={options}
							/>
							{/* <input
							required={true}
							type="text"
							name="handle-name"
							id="handle-name"
							className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
							placeholder="main-menu"
							onChange={(event) => {
								// setHandle(event?.target?.value);
							}}
						/> */}
						</div>
					</div>
				</div>
			)}
		</SimpleAlert>
	);
};

export default CompareVersion;
